import React, { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import Client from './Client';
import Partner from './Partner';
import { Tooltip } from 'primereact/tooltip';



const DOCUMENTATION_MUTATION = gql`
  mutation callDocumentationMutation($id: String) {
    documentationMutation(input: {id: $id}) {
        clientMutationId
        info
    }
  }
`
const DocumentationContent = (props) => {
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [mutationData, setMutationData] = useState(false);
    const [cId, setCId] = useState(false);
    // read post id from url.
    let postId = props.match.params.postId ? props.match.params.postId.split('?') : props.match.params.postId;
    postId = postId ? postId[0] : postId;

    const [DOCUMENTATION_MUTATION_CONTAINER, { loading: mutationLoading, data }] = useMutation(
        DOCUMENTATION_MUTATION,
        {
            onCompleted(data) {
                confirm(data);
            }
        }
    );

    useEffect(() => {
        const search = window.location.search; // returns the URL query String
        const params = new URLSearchParams(search);
        setCId(params.get('cid'));
        DOCUMENTATION_MUTATION_CONTAINER({
            variables: { id: postId }
        });
    }, [postId]);

    const confirm = data => {
        const temp_data = JSON.parse(data.documentationMutation.info);
        setMutationData(temp_data);
        setMutationDataCompleted(true);
    }

    return (
        <>
            {localStorage.getItem('role') === 'client' ? <Client {...props} /> : <Partner {...props} />}
            <div className="layout-main">
                <Tooltip target=".adpositiontooltip" />
                {mutationDataCompleted && mutationData !== null && mutationData.map((x, i) => {
                    return (
                        <div className="dialog-demo" key={x.id}>
                            <h2><span style={{ fontWeight: 600 }}>{x.title}</span></h2>
                            <div dangerouslySetInnerHTML={{ __html: x.content }} />
                        </div>
                    )

                })}
            </div>
        </>
    );

}

export default DocumentationContent
