import React from 'react'
import AssetInputOrUploadSection from './AssetInputOrUploadSection'
import { CREATIVE_NAMES, MOBILE_STICKY_VIDEO_AD_FIELDS } from './definitions'
import BaseCreativeControls from './BaseCreativeControls'

const CREATIVE_TYPE = CREATIVE_NAMES.MOBILE_STICKY_VIDEO

export default function MobileStickyVideo({
    bannerUrl,
    videoCreativeUrl,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={bannerUrl}
                    inputLabel='Static banner URL'
                    inputName={MOBILE_STICKY_VIDEO_AD_FIELDS.bannerUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={videoCreativeUrl}
                    inputLabel='Video creative URL'
                    inputName={MOBILE_STICKY_VIDEO_AD_FIELDS.videoCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="video/*"
                    defaultExtension="mp4"
                />
            </BaseCreativeControls>

        </>
    )
}