/** @jsxImportSource @emotion/react */
import {gql, useMutation} from "@apollo/client";
import { css } from "@emotion/react";
import {faBars, faBarsProgress, faChartLine, faCheck, faCircleNotch, faCog, faExternalLink, faListCheck, faPlus, faRefresh, faSpinner, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DomainsCloudflareCacheClearContext } from "../context/buildContext";
import { addDomain, addDomainsToBuildList, clearBuildList, registerCustomerBuildInfo, removeDomain, setBuildMessage, setBuildingState, setModifiedDomainsList, setSortedDomains } from "../reducers/multiBuildSystemReducer";
import { resetCurrentPreset, setCurrentDomain } from "../reducers/userLoginReducer";
import LayoutWrapper from "./LayoutWrapper";
import { COMMON_CUSTOMER_DATA_MUTATION } from "./common/creatives/definitions";
import Cta from "./common/marketplace/CTA";
import { DOMAIN_MUTATION } from "./common/multiBuild/constants";
import {Button} from "primereact/button";
import {pushMessage} from "../reducers/messagingReducer";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import {InputNumber} from "primereact/inputnumber";

const flatInputStyles = css`
    box-shadow: none;
    border: none;
    border-radius: 0;
    width: 50%;
    border-bottom: 1px solid #666;
    &:active,
    &:hover,
    &:focus,
    &:focus-within {
        box-shadow: none !important;
        border: none;
        border-color: #000 !important;
        border-bottom: 1px solid #000;
    }
`

const defaultSet = new Set();

const DOMAIN_DELETE_MUTATION = gql`
    mutation callDomainDeleteMutation($id: String) {
        domainDeleteMutation(input: { id: $id }) {
            clientMutationId
            deleted
        }
    }
`;

const SAVE_NEW_DOMAIN_SETTINGS_MUTATION = gql`
    mutation saveNewDomainSettingsMutation(
        $adClientId: String
        $adDomainName: String
        $advPreset: String
        $industryOptions: String
        $networkId: String
        $ads_ActiveViewTime: String
        $adRefreshTime: String
        $adRefreshLimit: String
        $shortDomainName: String
        $childnetworkId: String
        $stagingURL: String
        $adEnableSandboxMode: Boolean
        $adEnableAdhesionIcon: Boolean
        $adInactive: Boolean
        $adDisableHBGlobally: Boolean
    ) {
        saveNewDomainSettingsMutation(
            input: {
                adClientId: $adClientId
                adDomainName: $adDomainName
                advPreset: $advPreset
                industryOptions: $industryOptions
                networkId: $networkId
                ads_ActiveViewTime: $ads_ActiveViewTime
                adRefreshTime: $adRefreshTime
                childnetworkId: $childnetworkId
                stagingURL: $stagingURL
                adRefreshLimit: $adRefreshLimit
                shortDomainName: $shortDomainName
                adEnableSandboxMode: $adEnableSandboxMode
                adEnableAdhesionIcon: $adEnableAdhesionIcon
                adInactive: $adInactive
                adDisableHBGlobally: $adDisableHBGlobally
            }
        ) {
            networkId
            clientMutationId
            success
            message
        }
    }
`;

const DEFAULT_DOMAINS_INFO = { ads_active_view_time: 2, ad_refresh_time: 30 };

const MultiBuildSystem = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [domainListingMutation] = useMutation(DOMAIN_MUTATION)
    const [commonCustomerDataMutation] = useMutation(COMMON_CUSTOMER_DATA_MUTATION)
    const { currentCustomer, userID, isAdmin, currentPreset } = useSelector((state) => state.userInformation);
    const buildState = useSelector(state => state.multiBuildSystemInfo)
    const [isLoadingDomains, setIsLoadingDomains] = useState(true)
    const [logMessage, setLogMessage] = useState('')
    const [isDialogVisible, setIsDialogVisible] = useState(false)
    const [isBulkBuild, setIsBulkBuild] = useState(false)
    const [haveDomainsBeenPreselected, setHaveDomainsBeenPreselected] = useState(false)
    const [skipPresetDomainsAddition, setSkipPresetDomainAddition] = useState(false)
    const [deleteProductTitle, setDeleteProductTitle] = useState(null);
    const [deleteProductID, setDeleteProductID] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [updateState, setUpdateStatus] = useState({ updated: false });
    const [newDomainFormDialog, setNewDomainFormDialog] = useState(false);
    const [newDomainInfo, setNewDomainInfo] = useState(DEFAULT_DOMAINS_INFO);
    const [advPresetError, setAdvPresetError] = useState(false);
    const [showSaveLoader, setShowSaveLoader] = useState(false);
    const [presetOptions, setPresetOptions] = useState(null);
    const [industryOptions, setIndustryOptions] = useState(null);


    const cloudflareCacheClear = useContext(DomainsCloudflareCacheClearContext)

    const [DOMAIN_DELETE_MUTATION_CONTAINER] = useMutation(DOMAIN_DELETE_MUTATION, {
        onCompleted(dataDelete) {
            confirmDelete(dataDelete);
        },
    });

    const [SAVE_NEW_DOMAIN_SETTINGS_MUTATION_CONTAINER] = useMutation(SAVE_NEW_DOMAIN_SETTINGS_MUTATION, {
        onCompleted(dataUpdate) {
            setNewDomainFormDialog(false)
            setNewDomainInfo(DEFAULT_DOMAINS_INFO);
            setShowSaveLoader(false);
            confirmUpdate(dataUpdate);
        },
    });

    const confirmDelete = () => {
        dispatch(pushMessage("Domains List", "Domain was deleted"));
        setUpdateStatus({ updated: true });
    };

    const confirmUpdate = async (data) => {
        if (data["saveNewDomainSettingsMutation"]["success"] === "true") {
            dispatch(pushMessage("Domains List", "New domain was added"));
            setUpdateStatus({ updated: true });
        } else {
            let sms = data["saveNewDomainSettingsMutation"]["message"];
            dispatch(pushMessage("Domains List", `New domain info update failed: ${sms}`, "error"));
        }
    };

    function sortDomains(allDomains, modifiedDomainsSet) {
        let [domainsToBuild, restDomains] = allDomains.reduce((acc, curr) => {
            if (modifiedDomainsSet.has(curr.domain_id)) {
                acc[0].push(curr)
            } else {
                acc[1].push(curr)
            }
            return acc
        }, [[], []])

        return [domainsToBuild, restDomains]
    }

    async function requestModifiedDomains() {
        let response = await commonCustomerDataMutation({ variables: { customerId: String(currentCustomer) } })
        let domains = JSON.parse(response.data.customerCommonInfoMutation.customerInfo)
        return domains?.modifiedDomains || []
    }

    useEffect(async () => {
        if (currentCustomer && userID) {

            let response = await domainListingMutation({ variables: { id: currentCustomer, userId: userID, adminRole: true } });
            setPresetOptions(JSON.parse(response.data.domainInfoMutation.presetInfo) || false);
            setIndustryOptions(JSON.parse(response.data.domainInfoMutation.industryInfo) || false);

            if (updateState || !buildState[currentCustomer] || !buildState[currentCustomer].domains) {
                try {
                    setIsLoadingDomains(true)
                    let customerInfo = JSON.parse(response.data.domainInfoMutation.customerInfo)
                    let buildNonceKey = response.data.domainInfoMutation.stBuildNonceKey
                    let pds = customerInfo
                        .reduce((acc, curr) => {
                            acc.push({
                                domain: curr.title,
                                domain_id: curr.id,
                                presetId: curr.presetId,
                                presetName: curr.presetName,
                                lastBuildId: curr.lastBuildNumber,
                                domainSlug: curr.domainSlug,
                                currentWrapperVersion: curr.currentWrapperVersion,
                                lastBuildWrapperVersion: curr.lastBuildWrapperVersion,
                                logMessage: curr.logList?.[0]?.log_message || 'NA'
                            })
                            return acc
                        }, []);
                    let modifiedDomains = await requestModifiedDomains()

                    dispatch(setModifiedDomainsList({
                        customerId: currentCustomer,
                        domainsIds: modifiedDomains
                    }))

                    let [domainsToBuild, domainsToSort] = sortDomains(pds, new Set(modifiedDomains))

                    domainsToSort = domainsToSort.sort((da, db) => {
                        return da.domain.localeCompare(db.domain)
                    })

                    dispatch(registerCustomerBuildInfo({
                        customerId: currentCustomer,
                        domains: [...domainsToBuild, ...domainsToSort],
                        buildNonceKey
                    }))

                    setIsLoadingDomains(false)

                } catch (err) {
                    setIsLoadingDomains(false)
                }
            } else if (buildState[currentCustomer]?.domains) {
                let modifiedDomains = await requestModifiedDomains()
                dispatch(setModifiedDomainsList({
                    customerId: currentCustomer,
                    domainsIds: modifiedDomains
                }))
                let [domainsToBuild, domainsToSort] = sortDomains(buildState[currentCustomer]?.domains, new Set(modifiedDomains))
                domainsToSort = domainsToSort.sort((da, db) => {
                    return da.domain.localeCompare(db.domain)
                })
                dispatch(setSortedDomains({customerId: currentCustomer, domains: [...domainsToBuild, ...domainsToSort]}))
                setIsLoadingDomains(false)
            } else {
                setIsLoadingDomains(false)
            }
        }
    }, [currentCustomer, userID, updateState])

    useEffect(() => {
        try {
            if (haveDomainsBeenPreselected || buildState[currentCustomer]?.isBuilding || skipPresetDomainsAddition) {
                setSkipPresetDomainAddition(true)
                return
            }

            if (currentPreset && buildState[currentCustomer]?.domains?.length) {
                setHaveDomainsBeenPreselected(true)
                dispatch(addDomainsToBuildList({ customerId: currentCustomer, presetId: parseInt(currentPreset) }))
            }
        } catch (err) {
            console.log(err)
        }
    }, [currentPreset, currentCustomer, buildState[currentCustomer]])

    useEffect(() => {
        return () => {
            dispatch(resetCurrentPreset())
        }
    }, [])

    function toggleDomain(domainId, isAddOp) {
        isAddOp
            ? dispatch(addDomain({ domainId, customerId: currentCustomer }))
            : dispatch(removeDomain({ domainId, customerId: currentCustomer }))
    }

    const buildNumberColumn = o => {
        let isBuildingDomain = buildState[currentCustomer]?.isBuilding
        let domainStatus = buildState[currentCustomer]?.domainsMappings?.[o.domain_id]
        let href = '';

        if (o.lastBuildWrapperVersion) {
            href = o.lastBuildWrapperVersion === 'v4'
                ? `http://${process.env.REACT_APP_SERVER}/adgrid-build/ad-grid-${o.domainSlug}.js`
                : `https://${process.env.REACT_APP_SERVER}/adgrid-build/${o.domainSlug}/revenue-grid.js`
        } else {
            href = o.currentWrapperVersion === 'v4'
                ? `http://${process.env.REACT_APP_SERVER}/adgrid-build/ad-grid-${o.domainSlug}.js`
                : `https://${process.env.REACT_APP_SERVER}/adgrid-build/${o.domainSlug}/revenue-grid.js`
        }

        let buildHtml = (
            <a
                css={css`
                    pointer-events: ${domainStatus === 'Building' ? 'none' : 'auto'};
                    color: ${domainStatus === 'Building' ? '#666' : 'auto'};
                    display: inline-block;
                    min-width: 50px;
                `}
                href={href}
                target="_blank"
            >
                {
                    o.latestBuildId || o.lastBuildId
                }
            </a>
        )

        return (
            <div css={css`
                .underline:hover {
                    text-decoration: underline !important;
                }
                .restore {
                    color: #2196F3;
                    cursor: pointer;
                    &.building {
                        cursor: not-allowed;
                    }
                    &.btn {
                        color: #2196f3;
                        border-radius: 3px;
                        padding: 1px 10px;
                        &.building {
                            background-color: #efefef;
                            color: #333;
                        }
                    }
                    .history {
                        display: inline-block;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
            `}>
                <span className={`restore ${isBuildingDomain ? 'building' : ''}`}>
                    {buildHtml}
                </span>
                <b>|</b>
                <span
                    className={`underline history restore btn ${domainStatus === 'Building' ? 'building' : ''}`}
                    onClick={() => {
                        if (isBuildingDomain) {
                            return;
                        }
                        dispatch(setCurrentDomain(o.domain_id))
                        history.push('/domainrestoresystem')
                    }}
                >
                    Build History
                </span>
            </div>
        )
    }

    const buildStatusColumn = o => {
        let domainStatusMappings = buildState[currentCustomer].domainsMappings
        if (o.domain_id in domainStatusMappings) {
            if (domainStatusMappings[o.domain_id] === 'Building') {
                return (
                    <span className="status queued">
                        <FontAwesomeIcon icon={faCircleNotch} spin={true} color={'#2196F3'} />
                        &nbsp;
                        {domainStatusMappings[o.domain_id]}
                    </span>
                )
            } else {
                return (
                    <span className="status queued">{domainStatusMappings[o.domain_id]}</span>
                )
            }
        }
        return (
            <span className="status idle">{domainStatusMappings[o.domain_id] || 'Idle'}</span>
        )
    }

    const buildControlColumn = o => {
        let domainStatusMappings = buildState[currentCustomer].domainsMappings
        let isChecked = o.domain_id in domainStatusMappings && domainStatusMappings[o.domain_id] !== 'Finished'
        //top-menu=yes
        let urlHeckText = history.location.search;

        if (urlHeckText === "?top-menu=yes") {
            if (isChecked && buildState.queued_a_domain) {
                dispatch(removeDomain({ domainId: o.domain_id, customerId: currentCustomer }))
                //history.push('domains');
            }

            if (!buildState.queued_a_domain) {
                history.push('domains');
            }
        }

        return (
            <div>
                <InputSwitch
                    disabled={!!buildState[currentCustomer]?.isBuilding || !!buildState[currentCustomer]?.isClearingCache}
                    checked={isChecked}
                    onChange={() => toggleDomain(o.domain_id, !isChecked)}
                />
            </div>
        )
    }

    const domainColumn = o => {
        let modifiedDomains = buildState[currentCustomer]?.modifiedDomains || defaultSet
        if (modifiedDomains.has(o.domain_id)) {
            return (
                <p>
                    {o.domain}
                    &nbsp;&nbsp;
                    <span className="p-tag p-tag-success">Changes available</span>
                </p>
            )
        }
        return <p>{o.domain}</p>
    }

    function triggerBuild() {
        setIsDialogVisible(false)
        dispatch(setBuildMessage({
            customerId: currentCustomer,
            logMessage
        }))
        dispatch(setBuildingState({
            customerId: currentCustomer,
            isBuilding: true
        }))
    }

    const actionColumn = o => {
        if (isAdmin) {
            return (
                <div className="actions">
                    <div></div>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-3" onClick={() => editProduct(o)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(o)} />
                </div>
            );
        } else {
            return (
                <div className="actions p-text-right">
                    <div></div>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-3" onClick={() => editProduct(o)} />
                </div>
            );
        }
    }

    const editProduct = (product) => {
        dispatch(setCurrentDomain(product.domain_id));
        history.push(`/adsettings`);
    };

    const confirmDeleteProduct = (product) => {
        setDeleteProductTitle(product.domain);
        setDeleteProductID(product.domain_id);
        setDeleteProductDialog(true);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const deleteProduct = () => {
        setDeleteProductDialog(false);
        DOMAIN_DELETE_MUTATION_CONTAINER({
            variables: { id: deleteProductID.toString() },
        });
    };

    const handleChange = (param) => {
        //console.log({a:param.target})
        if (param.target !== undefined && param.target.name !== undefined) {
            let name = param.target.name,
                value = param.target.value,
                temp = Object.assign({}, newDomainInfo);
            temp[name] = value;
            setNewDomainInfo(temp);
            if (name === "adv_preset") setAdvPresetError(false);
        } else {
            let name = param.originalEvent.target.name,
                value = param.value,
                temp = Object.assign({}, newDomainInfo);
            temp[name] = value;
            setNewDomainInfo(temp);
        }
    };

    const saveNewDomainInfo = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let msg_error = null;
        let validate = null;
        if (typeof newDomainInfo["domain_name"] !== "undefined") {
            validate = domainNameValidate(newDomainInfo["domain_name"]);
            if (!validate) dispatch(pushMessage("Domains List", "Domain name is invalid", "error"));
        }
        if (validate === null || validate === true) {
            if (newDomainInfo.length === 0) {
                msg_error = "No data";
                dispatch(pushMessage("Domains List", msg_error, "error"));
            } else if (!newDomainInfo["domain_name"]) {
                dispatch(pushMessage("Domains List", "Please enter Domain Name", "error"));
            } else if (!newDomainInfo["adv_preset"]) {
                msg_error = "Please enter Adv preset";
                dispatch(pushMessage("Domains List", msg_error, "error"));
                setAdvPresetError(true);
            } else if (!newDomainInfo["network_id"]) {
                dispatch(pushMessage("Domains List", "Please enter Network ID", "error"));
            } else if (!newDomainInfo["ads_active_view_time"]) {
                dispatch(pushMessage("Domains List", "Please enter Ads Active View Time", "error"));
            } else if (!newDomainInfo["ad_refresh_time"]) {
                dispatch(pushMessage("Domains List", "Please enter Ads Refresh Time", "error"));
            } else if (newDomainInfo.ad_refresh_time < -1) {
                dispatch(pushMessage("Domains List", "Ad refresh time can't be less than -1", "error"));
            } else {
                setShowSaveLoader(true);
                SAVE_NEW_DOMAIN_SETTINGS_MUTATION_CONTAINER({
                    variables: {
                        adClientId: currentCustomer,
                        adDomainName: newDomainInfo.domain_name,
                        networkId: newDomainInfo.network_id,

                        //advPreset: newDomainInfo.adv_preset.code.toString(),
                        advPreset: JSON.stringify({ID: Number(newDomainInfo.adv_preset.code), post_title: newDomainInfo.adv_preset.name.toString()}),
                        industryOptions: newDomainInfo.industry_options ? newDomainInfo.industry_options.code.toString() : "",
                        ads_ActiveViewTime: newDomainInfo.ads_active_view_time.toString(),
                        //adRefreshTime: newDomainInfo.ad_refresh_time.toString(),
                        //adRefreshLimit: newDomainInfo.ad_refresh_limit ? newDomainInfo.ad_refresh_limit.toString() : "",
                        childnetworkId: newDomainInfo.child_network_id,
                        shortDomainName: newDomainInfo.short_domain_name,
                        stagingURL: newDomainInfo.staging_url,
                        adEnableSandboxMode: newDomainInfo.enable_sandbox_mode,
                        adEnableAdhesionIcon: newDomainInfo.enable_adhesion_close_icon,
                        adInactive: newDomainInfo.inactive,
                        adPrebidConflict: newDomainInfo.prebid_conflict,
                        adDisableHBGlobally: newDomainInfo.disable_hb_globally,
                        adIframeLazyload: newDomainInfo.iframe_lazyload,
                        adOverridePrebidTimeout: newDomainInfo.override_prebid_timeout,
                        adPrebidTimeout: newDomainInfo.prebid_timeout,
                    },
                });
            }
        }
    };

    const domainNameValidate = (domainName) => {
        let regexDomain = /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}$/i;
        if (domainName.match(regexDomain)) {
            return true;
        } else {
            return false;
        }
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const newDomainFormDialogFooter = (
        <>
            {showSaveLoader && <FontAwesomeIcon size="2x" icon={faSpinner} spin/>}
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>setNewDomainFormDialog(false)} />
            <Button label="Save" icon="pi pi-save" className="p-button-text" onClick={saveNewDomainInfo} />
        </>
    );

    const recycleBin = () => {
        history.push("/domainsrecyclebin");
    };

    return (
        <LayoutWrapper
            renderOpsBar={true}
            hideNavigationDropdown={false}
            showBuildNotification={false}
            buildControls={
                <>
                    <Cta
                        ctaText="Build domains"
                        icon={faBarsProgress}
                        disabled={
                            !buildState[currentCustomer]?.buildList?.length ||
                            buildState[currentCustomer]?.isBuilding ||
                            buildState[currentCustomer]?.isClearingCache
                        }
                        themeClasses="marketplace-cta outlined success"
                        onClick={async () => {
                            setIsDialogVisible(true)
                        }}
                    />
                    <Cta
                        ctaText="Clear domain(s) cache"
                        icon={faRefresh}
                        showLoader={!!buildState[currentCustomer]?.isClearingCache}
                        disabled={
                            !buildState[currentCustomer]?.buildList?.length ||
                            buildState[currentCustomer]?.isBuilding ||
                            buildState[currentCustomer]?.isClearingCache
                        }
                        themeClasses="marketplace-cta outlined default"
                        onClick={async () => {
                            cloudflareCacheClear(currentCustomer)
                        }}
                    />
                </>
            }
        >
            <Dialog visible={newDomainFormDialog} style={{ width: "60rem" }} header="Add New Domain" modal footer={newDomainFormDialogFooter} onHide={()=>setNewDomainFormDialog(false)}>
                <div className="confirmation-content">

                    {/**/}

                    <div className="">
                        <div className="p-panel-content p-fluid">
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="Example: example.com or sub.example.com" htmlFor="domain_name">
                                        Domain URL
                                    </label>
                                    <label style={{ color: "red" }}>*</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText id="domain_name" name="domain_name" value={newDomainInfo["domain_name"] || ""} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label htmlFor="adv_preset">Adv preset</label>
                                    <label style={{ color: "red" }}>*</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <Dropdown
                                        className={advPresetError ? "border-color-red" : ""}
                                        value={newDomainInfo["adv_preset"]}
                                        name="adv_preset"
                                        options={presetOptions}
                                        onChange={handleChange}
                                        optionLabel="name"
                                        placeholder={presetOptions ? "Select Adv Preset" : "No preset Available for this User"}
                                    />
                                </div>
                            </div>
                            {industryOptions && (
                                <div className="p-grid small-margin-bottom-10">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label htmlFor="industry_options">Industry</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <Dropdown value={newDomainInfo["industry_options"]} name="industry_options" options={industryOptions} onChange={handleChange} optionLabel="name" placeholder="Select an Industry" />
                                    </div>
                                </div>
                            )}
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <Tooltip target=".adpositiontooltip" />
                                    <label className="adpositiontooltip after" data-pr-tooltip="Example: /123456 or /123456/home" htmlFor="network_id" htmlFor="network_id">
                                        Network Id
                                    </label>
                                    <label style={{ color: "red" }}>*</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText id="network_id" name="network_id" value={newDomainInfo["network_id"]} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="p-grid small-margin-bottom-10" style={{ display: "none" }}>
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label htmlFor="ads_active_view_time">Ads Active View Time</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputNumber inputId="ads_active_view_time" name="ads_active_view_time" value={newDomainInfo["ads_active_view_time"]} onChange={handleChange} />
                                </div>
                            </div>
                            {/*<div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="Time in seconds to take an Ad refresh. Integer number example 30." htmlFor="ad_refresh_time" htmlFor="ad_refresh_time">
                                        Ad Refresh Time
                                    </label>
                                    <label style={{ color: "red" }}>*</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputNumber inputId="ad_refresh_time" name="ad_refresh_time" value={newDomainInfo["ad_refresh_time"]} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen." htmlFor="ad_refresh_limit">
                                        Ad Refresh Limit
                                    </label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputNumber inputId="ad_refresh_limit" name="ad_refresh_limit" value={newDomainInfo["ad_refresh_limit"]} onChange={handleChange} />
                                </div>
                            </div>*/}

                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="Time in seconds to take an Ad refresh. Integer number example 30." htmlFor="child_network_id">
                                        Child Network Id
                                    </label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText inputId="child_network_id" name="child_network_id" value={newDomainInfo["child_network_id"]} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="Alias or short name of the domain." htmlFor="short_domain_name">
                                        Short Domain Name
                                    </label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText id="short_domain_name" name="short_domain_name" value={newDomainInfo["short_domain_name"]} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="p-grid small-margin-bottom-10">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="adpositiontooltip after" data-pr-tooltip="The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen." htmlFor="staging_url">
                                        Staging URL
                                    </label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText inputId="staging_url" name="staging_url" value={newDomainInfo["staging_url"]} onChange={handleChange} />
                                </div>
                            </div>

                            {/*<div className="p-grid p-mt-2">
                                <div className="p-col-12">
                                    <div className="p-d-flex p-ai-center">
                                        <InputSwitch name="enable_sandbox_mode" checked={newDomainInfo["enable_sandbox_mode"]} onChange={handleChange} />
                                        <label className="p-ml-2 adpositiontooltip after" data-pr-tooltip="Check to enable Sandbox Mode.">
                                            Enable Sandbox Mode
                                        </label>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-d-flex p-ai-center">
                                        <InputSwitch name="enable_adhesion_close_icon" checked={newDomainInfo["enable_adhesion_close_icon"]} onChange={handleChange} />
                                        <label className="p-ml-2 adpositiontooltip after" data-pr-tooltip="Check to enable Adhesion's Close Icon.">
                                            Enable Adhesion's Close Icon
                                        </label>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-d-flex p-ai-center">
                                        <InputSwitch name="inactive" checked={newDomainInfo["inactive"]} onChange={handleChange} />
                                        <label className="p-ml-2 adpositiontooltip after" data-pr-tooltip="Check to disable Ad for this Publisher.">
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-d-flex p-ai-center">
                                        <InputSwitch name="disable_hb_globally" checked={newDomainInfo["disable_hb_globally"]} onChange={handleChange} />
                                        <label className="p-ml-2 adpositiontooltip after" data-pr-tooltip="If enabled, Ad will trigger without Prebid.">
                                            Disable HB Globally
                                        </label>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    {/**/}


                </div>
            </Dialog>
            <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    {
                        <span>
                            Are you sure you want to delete <b>{deleteProductTitle}</b>?
                        </span>
                    }
                </div>
            </Dialog>

            <Dialog
                header="Build Log Message"
                visible={isDialogVisible}
                css={css`
                    width: 50vw;
                    .dialog-body {
                        display: flex;
                        justify-content: space-between;
                    }
                `}
                onHide={() => {
                    setIsDialogVisible(false)
                    setLogMessage('')
                }}
            >
                <div className="dialog-body">
                    <InputText
                        placeholder="Enter a build log message"
                        value={logMessage}
                        css={flatInputStyles}
                        onKeyDown={ev => {
                            if (ev.key === 'Enter') {
                                if (logMessage.trim().length) {
                                    triggerBuild()
                                }
                            }
                        }}
                        onChange={ev => {
                            setLogMessage(ev.target.value)
                        }}
                    />
                    <Cta

                        ctaText="OK"
                        icon={faCheck}
                        themeClasses="marketplace-cta outlined success"
                        disabled={!logMessage.trim().length}
                        onClick={triggerBuild}
                    />
                </div>
            </Dialog>
            <div>
                <div className="screen-top flex" css={css`
                    justify-content: space-between;
                `}>
                    <div className="screen-name">
                        <h3>Domains</h3>
                    </div>

                    <div className="screen-name">
                        <div css={css`
                          display: flex;
                        `}>
                            <Cta
                                ctaText="Add New Domain"
                                icon={faPlus}
                                showLoader={!!buildState[currentCustomer]?.isClearingCache}
                                themeClasses="marketplace-cta outlined success p-mr-2"
                                onClick={() => {
                                    setNewDomainFormDialog(true)
                                }}
                            />

                            {localStorage.getItem("admin_role") === "true" && <Cta
                                ctaText="Trash"
                                icon={faTrash}
                                themeClasses="marketplace-cta outlined danger"
                                onClick={(e) => recycleBin(e)}
                            />}
                        </div>
                    </div>
                </div>
                {
                    isLoadingDomains
                        ? (
                            <div
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    position: fixed;
                                    bottom: 10em;
                                    right: 1em;
                                    z-index: 9999;
                                    background-color: #2196f3;
                                    border-radius: 3px;
                                    padding: 1em;
                                    color: #fff;
                                    .message {
                                        margin-left: 1em;
                                    }
                                `}
                            >
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3em", color: "#fff" }}></i>
                                <p className="message">Retrieving domains data</p>
                            </div>
                        )
                        : (
                            <DataTable
                                className="p-datatable-striped"
                                value={buildState?.[currentCustomer]?.domains || []}
                                rowClassName={d => {
                                    return { 'domain-selected': d.domain_id in buildState[currentCustomer]?.domainsMappings }
                                }}
                                css={css`
                                    .status {
                                        display: block;
                                        width: 100px;
                                        text-align: center;
                                        border-radius: 3px;
                                        padding: 1px 3px;
                                        color: #333;
                                        &.idle {
                                            background-color: #efefef;
                                        }
                                        &.queued {
                                            background-color: #d5ffb0;
                                        }
                                    }
                                `}
                            >
                                <Column
                                    bodyClassName="domain-name"
                                    body={domainColumn}
                                    filter={true}
                                    field="domain"
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search domain"
                                    header={<p><FontAwesomeIcon icon={faExternalLink} />&nbsp;&nbsp;Domain</p>}
                                />
                                <Column
                                    field="presetName"
                                    filter={true}
                                    filterMatchMode="contains"
                                    filterPlaceholder="Filter preset"
                                    header={<p><FontAwesomeIcon icon={faCog} /> &nbsp;&nbsp;Preset</p>}
                                />
                                <Column
                                    header={<p>&nbsp;&nbsp;Latest build&nbsp;<b>|</b>&nbsp;History</p>}
                                    body={buildNumberColumn}
                                />
                                <Column
                                    header={<p>&nbsp;&nbsp;Log Message</p>}
                                    field="logMessage"
                                />
                                <Column
                                    header={<p><FontAwesomeIcon icon={faChartLine} />&nbsp;&nbsp;Build Status</p>}
                                    body={buildStatusColumn}
                                />
                                <Column
                                    header={
                                        <div css={css`
                                            display: flex;
                                            align-items: center;
                                        `}>
                                            <FontAwesomeIcon icon={faListCheck} />
                                            &nbsp;&nbsp;
                                            Select all domains
                                            &nbsp;&nbsp;
                                            <InputSwitch
                                                disabled={!!buildState[currentCustomer]?.isBuilding || !!buildState[currentCustomer]?.isClearingCache}
                                                checked={isBulkBuild}
                                                onChange={() => {
                                                    setIsBulkBuild(!isBulkBuild)
                                                    if (!isBulkBuild) {
                                                        dispatch(
                                                            addDomainsToBuildList({
                                                                customerId: currentCustomer,
                                                                presetId: currentPreset
                                                                    ? parseInt(currentPreset)
                                                                    : null
                                                            })
                                                        )
                                                    } else {
                                                        dispatch(clearBuildList({ customerId: currentCustomer }))
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    body={buildControlColumn}
                                />
                                <Column
                                    header={<p><FontAwesomeIcon icon={faBars} />&nbsp;&nbsp;Action</p>}
                                    body={actionColumn}
                                />
                            </DataTable>
                        )
                }
            </div>
        </LayoutWrapper>
    );
};

export default MultiBuildSystem;
