import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'


export default function DropdownSection({ label, name, value, options, changeHandler, adtype, optionLabel, optionValue, multiSelect = false, display = 'chip', placeholder = '', disabled = false }) {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-bottom: 10px;
                .p-multiselect.p-component {
                    max-width: 260px;
                    width: 100%;
                }
           `}
        >
            <label 
                htmlFor={name} 
                className="p-checkbox-label"
                css={css`
                    font-weight: 600;
                    margin-bottom: 5px;
                `}
            >
                    {label}
            </label>
            {
                multiSelect
                    ? <MultiSelect 
                        name={name}
                        value={value}
                        options={options}
                        optionLabel={optionLabel || "label"}
                        optionValue={optionValue || "value"}
                        onChange={ev => changeHandler(ev, ev.target.value, adtype)}
                        display={display}
                    />
                    : <Dropdown
                        disabled={disabled}
                        name={name}
                        value={value}
                        options={options}
                        optionLabel={optionLabel || "label"}
                        optionValue={optionValue || "value"}
                        placeholder={placeholder}
                        onChange={ev => changeHandler(ev, ev.target.value, adtype)}
                    />
            }
            
        </div>
    )
}