/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPlusCircle, faCopy } from "@fortawesome/free-solid-svg-icons";
import Cta from "./common/marketplace/CTA";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { BROWSERS, CONDITIONS, COUNTRIES, DEFAULT_CONFIG, DEVICES, OPERATING_SYSTEMS, OPERATORS, TIME_RANGES, styles } from "./common/trafficShaping/constants";
import { noop } from "../utilities/utils";
import { InputText } from 'primereact/inputtext';
import deepcopy from "deepcopy";

const propMappings = {
    devices: DEVICES,
    os: OPERATING_SYSTEMS,
    countries: COUNTRIES,
    browsers: BROWSERS,
    time_range: TIME_RANGES
}

const RuleControls = ({
    rule,
    handleSelection,
    handleChange,
    addCondition,
    removeCondition,
    idx,
    disableControls
}) => {
    let conditionMappings = rule.conditionMappings
    let ruleConditionKeys = Object
        .keys(rule)
        .filter(rulename => {
            return rulename.startsWith('condition_') && !(rulename in conditionMappings)
        })
    let keys = Object.keys(conditionMappings || {})
    let length = keys.length

    return length
        ? (
            <div className="container values-container flex">
                <p>
                    <strong>Values</strong>
                </p>
                {
                    keys.map((condition, i) => {
                        let props = rule.conditionMappings[condition]
                        let isLastRow = i + 1 === length

                        if (props.type === 'dropdown') {
                            if (props.name === 'time_range') {
                                delete props.key
                            }

                            return (
                                <div key={i} className="flex">
                                    <div className="user-response">
                                        {
                                            props.name === 'time_range'
                                                ? (
                                                    <div className="time-selectors">
                                                        <Dropdown
                                                            {...props}
                                                            placeholder="From"
                                                            value={rule.begin}
                                                            name="begin"
                                                            options={propMappings[props.name]}
                                                            optionLabel="name"
                                                            optionValue="value"
                                                            disabled={disableControls}
                                                            onChange={ev => {
                                                                handleSelection(ev, idx)
                                                            }}
                                                        />

                                                        <Dropdown
                                                            {...props}
                                                            placeholder="To"
                                                            value={rule.end}
                                                            name="end"
                                                            options={propMappings[props.name]}
                                                            optionLabel="name"
                                                            optionValue="value"
                                                            disabled={disableControls}
                                                            onChange={ev => {
                                                                handleSelection(ev, idx)
                                                            }}
                                                        />
                                                    </div>
                                                )
                                                : (
                                                    <MultiSelect
                                                        {...props}
                                                        filter={props.name === 'countries'}
                                                        value={rule[props.name]}
                                                        options={propMappings[props.name]}
                                                        optionLabel="name"
                                                        optionValue="value"
                                                        disabled={disableControls}
                                                        onChange={ev => {
                                                            handleSelection(ev, idx)
                                                        }}
                                                    />
                                                )
                                        }
                                    </div>
                                    <div className="controls-container add-rem-controls">
                                        <span
                                            className={`add-rule ${disableControls ? 'disabled-control' : ''}`}
                                            onClick={() => {
                                                if (isLastRow) {
                                                    addCondition(idx)
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color={isLastRow ? "#2196F3" : '#ccc'}
                                                fontSize={'1.5em'}
                                            />
                                        </span>
                                        <span
                                            className={`rem-rule ${disableControls ? 'disabled-control' : ''}`}
                                            onClick={() => removeCondition(condition, idx)}
                                        >
                                            <FontAwesomeIcon icon={faCircleXmark} color="#ff0000" fontSize={'1.5em'} />
                                        </span>
                                    </div>
                                </div>
                            )
                        } else if (props.type === 'input') {
                            return (
                                <div key={i} className="flex">
                                    <div className="user-response">
                                            <InputText
                                                {...props}
                                                value={rule[props.name]}
                                                disabled={disableControls}
                                                onChange={ev => {
                                                    handleChange(ev, idx)
                                                }}
                                            />
                                    </div>
                                    <div className="controls-container add-rem-controls">
                                        <span
                                            className={`add-rule ${disableControls ? 'disabled-control' : ''}`}
                                            onClick={() => {
                                                if (isLastRow) {
                                                    addCondition(idx)
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color={isLastRow ? "#2196F3" : '#ccc'}
                                                fontSize={'1.5em'}
                                            />
                                        </span>
                                        <span
                                            className={`rem-rule ${disableControls ? 'disabled-control' : ''}`}
                                            onClick={() => removeCondition(condition, idx)}
                                        >
                                            <FontAwesomeIcon icon={faCircleXmark} color="#ff0000" fontSize={'1.5em'} />
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                {
                    ruleConditionKeys.map((condition, idx) => {
                        return (
                            <div className="flex" key={idx}>
                                <div className="user-response"></div>
                                <div className="controls-container add-rem-controls">
                                        <span
                                            className="rem-rule"
                                            onClick={() => removeCondition(condition, idx)}
                                        >
                                            <FontAwesomeIcon icon={faCircleXmark} color="#ff0000" fontSize={'1.5em'} />
                                        </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
        : null
}

const TrafficShaping = ({
    bidders = [],
    rules = [],
    setRules,
    disableControls = false
}) => {

    function removeRule(idx) {
        if (disableControls) {
            return;
        }
        setRules(
            rules.filter((_, i) => idx !== i)
        )
    }

    function handleSelection(ev, idx) {
        let {target: {name, value}} = ev

        setRules(
            rules.map((rule, i) => {
                if (idx === i) {
                    let ruleCopy = {...rule}
                    if (name.startsWith('condition_')) {
                        ruleCopy.conditionMappings = Object.keys(ruleCopy.conditionMappings).length
                            ? ruleCopy.conditionMappings
                            : {}
                        if (name in ruleCopy.conditionMappings) {
                            let kname = ruleCopy.conditionMappings[name].name
                            if (Array.isArray(ruleCopy[kname])) {
                                ruleCopy[kname] = []
                            } else {
                                ruleCopy[kname] = ''
                            }
                            delete ruleCopy.conditionMappings[name]
                            delete ruleCopy[name]
                        }
                        switch (value) {
                            case 'Device':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    placeholder: 'Select device',
                                    name: 'devices',
                                    type: 'dropdown',
                                    tooltip: "Select device(s) to target"
                                }
                                break
                            case 'Country':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    placeholder: 'Select location',
                                    name: 'countries',
                                    type: 'dropdown',
                                    tooltip: "Select location(s) to block"
                                }
                                break
                            case 'OS':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    placeholder: 'Select OS',
                                    name: 'os',
                                    type: 'dropdown',
                                    tooltip: "Select operating system(s) to target"
                                }
                                break;
                            case 'Browser':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    placeholder: 'Select a browser',
                                    name: 'browsers',
                                    type: 'dropdown',
                                    tooltip: "Select browser(s)"
                                }
                                break;
                            case 'Idle Time':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    className: 'user-input',
                                    placeholder: 'Enter idle time',
                                    name: 'idle_time',
                                    type: 'input',
                                    tooltip: "Enter an idle time in seconds"
                                }
                                break
                            case 'Postal Code':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    className: 'user-input',
                                    placeholder: 'Enter postal code',
                                    name: 'postal_code',
                                    type: 'input',
                                    tooltip: "Enter comma-separated postal codes e.g. 36513,98435"
                                }
                                break
                            case 'Time':
                                ruleCopy.conditionMappings[name] = {
                                    key: name,
                                    className: 'user-input',
                                    placeholder: 'Select Time Ranges',
                                    name: 'time_range',
                                    type: 'dropdown',
                                    tooltip: "Select a start and end time range"
                                }
                            default:
                                noop()
                        }
                    }
                    ruleCopy[name] = value
                    return ruleCopy
                } else {
                    return rule
                }
            })
        )
    }

    function handleChange(ev, idx) {
        let {target: {name, value}} = ev

        setRules(
            rules.map((rule, i) => {
                if (i === idx) {
                    return {...rule, [name]: value}
                }
                else {
                    return rule
                }
            })
        )
    }

    function listConditionRules(rule) {
        let conditions = Object
            .keys(rule)
            .filter(cond => cond.startsWith('condition_'))
            .sort((ca, cb) => {
                let [, ia] = ca.split('_')
                let [, ib] = cb.split('_')
                return parseInt(ia) - parseInt(ib)
            })
        return conditions
    }

    function addCondition(idx) {
        if (disableControls) {
            return;
        }
        let clone = {...rules[idx]}
        let conditions = listConditionRules(clone)
        let [, i] = conditions.pop().split('_')
        i = parseInt(i)
        clone['condition_' + (i + 1)] = ''
        setRules(
            rules.map((rule, ruleIdx) => {
                if (ruleIdx === idx)
                    return clone
                else
                    return rule
            })
        )
    }

    function removeCondition(cond, idx) {
        if (disableControls) {
            return;
        }
        let clone = deepcopy(rules[idx])
        let conditions = listConditionRules(clone)

        if (conditions.length === 1) {
            removeRule(idx)
            return;
        }

        try {
            let prop = clone.conditionMappings[cond]?.name
            if (prop) {
                clone[prop] = Array.isArray(prop) ? [] : ''
                delete clone.conditionMappings[cond]
            }
            delete clone[cond]
            setRules(rules.map((rule, i) => {
                if (i === idx)
                    return clone
                else
                    return rule
            }))
        } catch (err) {
            console.log(err)
        }
    }

    function duplicateRow(idx) {
        if (disableControls) {
            return;
        }
        let clone = deepcopy(rules[idx])
        setRules([...rules, clone])
    }

    return (
        <div>
            <section
                className="rule-controls"
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <div>
                    <Cta
                        ctaText={'Add New Rule'}
                        icon={faPlusCircle}
                        themeClasses="marketplace-cta outlined default"
                        disabled={disableControls}
                        onClick={() => {
                            setRules([...rules, {...DEFAULT_CONFIG}])
                        }}
                    />
                </div>
            </section>
            <section className="rules">
                {
                    rules.map((rule, idx) => {
                        return (
                            <div
                                key={idx}
                                css={styles}
                            >
                                <div className="controls-container flex">
                                    <div className="container">
                                        <p><strong>Bidder</strong></p>
                                        <Dropdown
                                            className="bidder-dropdown"
                                            placeholder="Select a bidder"
                                            value={rule.bidder}
                                            options={bidders}
                                            optionLabel="name"
                                            optionValue="value"
                                            name="bidder"
                                            disabled={disableControls}
                                            onChange={(ev) => handleSelection(ev, idx)}
                                        />
                                    </div>
                                    <div className="container">
                                        <p><strong>Operator</strong></p>
                                        <Dropdown
                                            className="condition-dropdown"
                                            placeholder="Select operator"
                                            value={rule.operator}
                                            options={OPERATORS}
                                            optionLabel="name"
                                            optionValue="value"
                                            name="operator"
                                            disabled={disableControls}
                                            onChange={ev => handleSelection(ev, idx)}
                                        />
                                    </div>
                                    <div className="container conditions-container flex">
                                        <p><strong>Conditions</strong></p>
                                        {
                                            Object.keys(rule)
                                                .filter(k => k.startsWith('condition_'))
                                                .map((cond) => {
                                                    let value = rule[cond]

                                                    return (
                                                        <div key={cond}>
                                                            <Dropdown
                                                                className="condition-dropdown"
                                                                placeholder="Select condition"
                                                                value={value}
                                                                options={CONDITIONS}
                                                                optionLabel="name"
                                                                optionValue="value"
                                                                name={cond}
                                                                disabled={disableControls}
                                                                onChange={ev => handleSelection(ev, idx)}
                                                            />
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                    <RuleControls
                                        rule={rule}
                                        handleChange={handleChange}
                                        handleSelection={handleSelection}
                                        addCondition={addCondition}
                                        removeCondition={(cond, idx) => removeCondition(cond, idx)}
                                        idx={idx}
                                        disableControls={disableControls}
                                    />
                                </div>
                                    <div className="control-ctas">
                                        <span className={`copy-cta ${disableControls ? 'disabled-control': ''}`} onClick={() => duplicateRow(idx)}>
                                            <FontAwesomeIcon icon={faCopy} fontSize={'1.5em'} color={disableControls ? '#ccc' : '#000'} />
                                        </span>
                                        <span className={`remove-cta ${disableControls ? 'disabled-control': ''}`} onClick={() => removeRule(idx)}>
                                            <FontAwesomeIcon icon={faCircleXmark} fontSize='1.5em' color={disableControls ? '#ccc' : '#000'} />
                                        </span>
                                    </div>

                            </div>
                        )
                    })
                }

            </section>
        </div>
    );
};

export default TrafficShaping;
