const DEFAULT_STATE = {};
const ACTIONS = {
    SET_DOMAIN_INFO: "SET_DOMAIN_INFO",
    GET_DOMAIN_INFO: "GET_DOMAIN_INFO",
};

export default function commonDomainDataReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case ACTIONS.SET_DOMAIN_INFO:
            return { ...state, [action.data.domainId]: { ...state[action.data.domainId], ...action.data.domainData } };
        case ACTIONS.GET_DOMAIN_INFO:
            return state[action.data.domainId];
        default:
            return state;
    }
}

export function setCommonDomainData(domainId, domainData) {
    return {
        type: ACTIONS.SET_DOMAIN_INFO,
        data: { domainId, domainData },
    };
}

export function getCommonDomainData(domainId) {
    return {
        type: ACTIONS.GET_DOMAIN_INFO,
        data: { domainId },
    };
}
