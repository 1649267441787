import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Client from './Client';
import Partner from './Partner';


const DOCUMENTATION_MUTATION = gql`
  mutation callDocumentationMutation($id: String) {
    documentationMutation(input: {id: $id}) {
        clientMutationId
        info
    }
  }
`
const Documentation = (props) => {
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [mutationData, setMutationData] = useState(false);
    const customerId = props.match.params.customerId;
    const [DOCUMENTATION_MUTATION_CONTAINER, { loading: mutationLoading, data }] = useMutation(
        DOCUMENTATION_MUTATION,
        {
            onCompleted(data) {
                confirm(data);
            }
        }
    );

    useEffect(() => {
        DOCUMENTATION_MUTATION_CONTAINER({
            variables: { id: '' }
        });
    }, []);

    const confirm = data => {
        const temp_data = JSON.parse(data.documentationMutation.info);
        setMutationData(temp_data);
        setMutationDataCompleted(true);
    }

    return (
        <>
            {localStorage.getItem('role') === 'client' ? <Client {...props} /> : <Partner {...props} />}
            <div className="layout-main">
                {mutationDataCompleted && (
                    <h2><span style={{ fontWeight: 1000 }}>Documentation List</span></h2>

                )}
                {mutationDataCompleted && mutationData !== null && mutationData.map((x, i) => {
                    return (
                        <div className="dialog-demo">
                            <li style={{ color: 'blue', fontWeight: 600 }} onClick={localStorage.setItem('documentation_title'+x.id, x.title)} ><NavLink to={'/documentationcontent/' + x.id + '?cid=' + customerId} style={{ color: 'blue', fontWeight: 600 }} activeClassName="active" >{x.title} </NavLink> </li>
                            
                        </div>
                    )

                })}
            </div>
        </>
    );

}

export default Documentation