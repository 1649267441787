/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { accordionTabHeaderStyles, DEVICE_TARGETING_OPTIONS, marketplaceOrderOperationStyle, rowNoticeStyle } from "./constants";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { MarketplaceGamDataContext } from "../../../context/marketplaceGamDataContext";

const TotalSpend = ({ marketplaceOrderStats, lineItemId }) => {
    try {
        if (marketplaceOrderStats[lineItemId].impressionsDelivered) {
            return (
                <p className="info inline-block">
                    <span className="badge">
                        <b>Total: </b>
                    </span>
                    ${
                        parseInt((marketplaceOrderStats[lineItemId].impressionsDelivered / 1e3) * (marketplaceOrderStats[lineItemId].costPerUnit / 1e6)).toLocaleString()
                    }
                </p>
            )
        }
        return null
    } catch (err) {
        console.log(err)
        return null
    }
}

const MarketplaceAccordionHeader = ({
    order,
    render
}) => {
    const { isAdmin }
        = useSelector((state) => state.userInformation);
    const { gamStats: marketplaceOrderStats }
        = useContext(MarketplaceGamDataContext)

    const {
        name,
        description,
        lineItemId,
        creatives,
        thirdPartytagCreatives,
        selectDeviceMode,
        format,
        targetDevices,
        inProgress,
        pausing,
        resuming,
    } = order
    
    const hasStats
        = lineItemId && lineItemId in marketplaceOrderStats
    
    let targetedDevices = targetDevices.length
        ? targetDevices?.map((td) => td.name)?.join(", ")
        : DEVICE_TARGETING_OPTIONS.map((sd) => sd.name)?.join(", ");

    let operationMessage = "";

    if (inProgress) {
        if (lineItemId) {
            if (pausing)
                operationMessage = "Marketplace order is being paused";
            else if (resuming)
                operationMessage = "Marketplace order is being resumed";
            else
                operationMessage = "Marketplace order is being updated";
        } else {
            operationMessage = "Marketplace order is being created";
        }
    }

    return (
        <div css={accordionTabHeaderStyles}>
            <div>
                <p className="info">
                    {name && <span className="order-name">{name}</span>}
                    <span className={`format-${format}`}>
                        <FontAwesomeIcon icon={format === 'video' ? faVideo : faImage} />
                        &nbsp;
                        {format} order
                    </span>
                    <span style={{ fontWeight: 400 }}>{description && [" - [" + description + "]"]}</span>
                    {order.publishPending && (
                        <span
                            css={rowNoticeStyle}
                        >
                            This order has unpublished changes
                        </span>
                    )}
                    {
                        order.orderPausedByAgentScript && <span css={rowNoticeStyle}>Order reached its daily cap</span>
                    }
                </p>

                {isAdmin && order.GAMorderId && (
                    <p className="info inline-block">
                        <span>
                            <b>GAM Order: </b>
                            {order.GAMorderId}
                        </span>
                    </p>
                )}
                {isAdmin && lineItemId && (
                    <p className="info inline-block">
                        <span>
                            <b>Line Item ID: </b>
                            {lineItemId}
                        </span>
                    </p>
                )}
                {
                    isAdmin && order.wpMarketplacePostId && (
                        <p className="info inline-block">
                            <span>
                                <b>Console Order ID: </b>
                                {order.wpMarketplacePostId}
                            </span>
                        </p>
                    )
                }
                <p className="info inline-block">
                    <span>
                        <b>Associated Creatives: </b>
                        {creatives?.length + thirdPartytagCreatives?.length}
                    </span>
                </p>
                <p className="info inline-block">
                    <span>
                        <b>Devices {selectDeviceMode ? " included: " : " excluded: "}</b>[{targetedDevices}]
                    </span>
                </p>
                {hasStats && (
                    <>
                        <p className="info inline-block">
                            <span className="badge">
                                <b>Impressions:</b>
                            </span>
                            {(marketplaceOrderStats[lineItemId].impressionsDelivered || 0).toLocaleString()}
                        </p>
                        {
                            marketplaceOrderStats[lineItemId].clicksDelivered ? (
                                <p className="info inline-block">
                                    <span className="badge">
                                        <b>Clicks:</b>
                                    </span>
                                    {(marketplaceOrderStats[lineItemId].clicksDelivered).toLocaleString()}
                                </p>
                            ) : null
                        }
                        <TotalSpend marketplaceOrderStats={marketplaceOrderStats} lineItemId={lineItemId} />
                    </>
                )}
            </div>
            {inProgress ? (
                <div css={marketplaceOrderOperationStyle}>
                    {operationMessage}
                    <span className="pi pi-spin pi-spinner"></span>
                </div>
            ) : (
                <div style={{ marginLeft: "auto" }}>{render()}</div>
            )}
        </div>
    );
};

export default MarketplaceAccordionHeader;
