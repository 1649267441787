/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LayoutWrapper from "./LayoutWrapper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDesktop, faMobileScreen, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faDesktop);
library.add(faMobileScreen);
library.add(faRefresh);

const CustomCreativesDemo = () => {
    const [viewType, setViewType] = useState("desktop");
    const desktopIframe = useRef(null);

    const reloadFrame = () => {
        desktopIframe.current.contentWindow.postMessage({ action: "reload" }, "*");
    };

    return (
        <>
            <LayoutWrapper model="creative" renderOpsBar={false}>
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        font-size: 16px;
                        .control {
                            &:hover {
                                cursor: pointer;
                                color: blue;
                            }
                        }
                        .reload {
                            margin-left: 1em;
                            &:hover {
                                color: red;
                                cursor: pointer;
                            }
                        }
                    `}
                >
                    <div
                        className="control"
                        onClick={() => {
                            setViewType(viewType === "desktop" ? "mobile" : "desktop");
                            reloadFrame();
                        }}
                    >
                        <FontAwesomeIcon icon={viewType === "desktop" ? viewType : "mobile-screen"} />
                        {viewType === "desktop" ? " switch to mobile view " : " switch to desktop view "}
                    </div>
                    <div
                        className="reload"
                        onClick={() => {
                            reloadFrame();
                        }}
                    >
                        <FontAwesomeIcon icon="refresh" /> reload creative
                    </div>
                </div>

                <div
                    className="creative"
                    css={css`
                        display: flex;
                        flex-direction: row;
                        margin-top: 1em;
                    `}
                >
                    <div
                        className="creative-preview"
                        css={css`
                            width: 100%;
                            margin-left: 20px;
                        `}
                    >
                        <iframe
                            src={process.env.REACT_APP_CREATIVES_INDEX}
                            title="Creative iframe"
                            ref={desktopIframe}
                            css={css`
                                width: ${viewType === "desktop" ? "100%" : "400px"};
                                height: 100%;
                                border: 1px solid #ccc;
                                border-radius: 6px;
                                min-height: 750px;
                                margin: 0 auto;
                                display: block;
                            `}
                        ></iframe>
                    </div>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default CustomCreativesDemo;
