const DEFAULT_STATE = {
    config: {},
    creativeType: "",
    editMode: false,
    orderId: "",
    lineItemConfig: {},
    orderCreator: "",
    paymentMethod: "",
    stats: {},
    currentCpm: 0,
};

const ACTIONS = {
    REGISTER_CONFIG: "register_config",
    REMOVE_CONFIG: "remove_config",
    SET_ORDER_PAYMENT_METHOD: "SET_ORDER_PAYMENT_METHOD",
};

function customOrdersReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case ACTIONS.REGISTER_CONFIG:
            return {
                config: action.data.config,
                creativeType: action.data.creativeType,
                editMode: true,
                orderId: action.data.orderId,
                lineItemConfig: action.data.lineItemConfig,
                orderCreator: action.data.orderCreator,
                paymentMethod: action.data.paymentMethod,
                stats: action.data.stats,
                currentCpm: action.data.cpm,
            };
        case ACTIONS.REMOVE_CONFIG:
            return DEFAULT_STATE;
        case ACTIONS.SET_ORDER_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.data.paymentMethodId };
        default:
            return state;
    }
}

function editCreative(creativeType, orderId, config, lineItemConfig, orderCreator, paymentMethod, stats, cpm) {
    return {
        type: ACTIONS.REGISTER_CONFIG,
        data: {
            creativeType,
            config,
            orderId,
            lineItemConfig,
            orderCreator,
            paymentMethod,
            stats,
            cpm,
        },
    };
}

function resetEditConfig() {
    return {
        type: ACTIONS.REMOVE_CONFIG,
    };
}

function setOrderPaymentMethod(paymentMethodId) {
    return {
        type: ACTIONS.SET_ORDER_PAYMENT_METHOD,
        data: { paymentMethodId },
    };
}

export default customOrdersReducer;
export { editCreative, resetEditConfig, setOrderPaymentMethod };
