import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useRef, useState } from "react";
import "../../App.scss";
import "../../layout/flags/flags.css";
import "../../layout/layout.scss";
import { useDispatch, useSelector } from "react-redux";
import { setNavigationMode } from "../../reducers/userLoginReducer";
import { addToPresetBuildList } from "../../reducers/buildReducer";
import { pushMessage } from "../../reducers/messagingReducer";
import SectionHeader from "../helper/SectionHeader";
import LayoutWrapper from "../LayoutWrapper";
import { registerScreenChanges } from "../../reducers/screenChangesReducer";

const PRESET_SETTINGS_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        presetAllInfoMutation(input: { id: $id, cid: $cid }) {
            presetInfo
        }
    }
`;

const PRESETSETTINGS_UPDATE_MUTATION = gql`
    mutation callAdPositionUpdateMutation($id: String, $presetName: String, $bannerFloorsPrice: String) {
        updatePresetSettings(input: { id: $id, presetName: $presetName, bannerFloorsPrice: $bannerFloorsPrice }) {
            networkId
        }
    }
`;

const PresetSettings = () => {
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [inputList, setInputList] = useState([{ display_name: "", slot_name: "", slot_name: "", desktop_viewport: "", desktop_adsize: "", tablet_viewport: "", tablet_adsize: "", mobile_viewport: "", mobile_adsize: "" }]);
    const [bannerFloorsPrice, setBannerFloorsPrice] = useState(null);
    const [presetName, setPresetName] = useState(null);
    const [presetList, setPresetList] = useState(null);
    const [originalPresetName, setOriginalPresetName] = useState(null);
    const messages = useRef(null);
    const dispatch = useDispatch();
    const { currentPreset, currentCustomer } = useSelector((state) => {
        return state.userInformation;
    });

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(PRESET_SETTINGS_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    const [PRESET_UPDATE_MUTATION_CONTAINER] = useMutation(PRESETSETTINGS_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(addToPresetBuildList(currentPreset));
            dispatch(pushMessage("Preset settings", "Settings were updated"));
        },
    });

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            dispatch(setNavigationMode("preset"));
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: currentPreset, cid: currentCustomer },
            });
        }
    }, [currentPreset, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo);
        setBannerFloorsPrice(temp_data.banner_floors_price);
        setPresetName(temp_data.adv_preset_name);
        setOriginalPresetName(temp_data.adv_preset_name);
        setMutationDataCompleted(true);
    };

    const handleChange = (param) => {
        let name = param.target.name,
            value = param.target.value;
        if (name === "banner_floors_price") {
            setBannerFloorsPrice(value);
        } else {
            setPresetName(value);
        }
        dispatch(registerScreenChanges("Preset Settings"));
    };

    const savePresetSettings = async () => {
        let saveData = true;

        if (bannerFloorsPrice && isNaN(parseFloat(bannerFloorsPrice))) {
            dispatch(pushMessage("Preset settings", "Banner Floors Price is invalid", "error"));
            saveData = false;
        }

        if (presetName !== originalPresetName) {
            presetList.forEach((list) => {
                if (list.title === presetName) {
                    dispatch(pushMessage("Preset settings", `${presetName} already exist`, "error"));
                    saveData = false;
                } else {
                    if (presetName === "" || presetName === null) {
                        dispatch(pushMessage("Preset settings", "Preset name cannot be empty", "error"));
                        saveData = false;
                    }
                }
            });
        }

        if (!saveData) throw new Error("Error saving preset data");
        if (presetName !== originalPresetName) {
            presetList.forEach((list) => {
                if (list.id === currentPreset) {
                    list.title = presetName;
                }
            });
        }

        let temp = Object.assign({});
        inputList.forEach((list) => {
            if (list.slot_name === "") {
            } else {
                temp[list.slot_name] = Object.assign({});
                if (list.deskAdsize !== null && list.deskAdsize !== "" && list.deskViewPort !== null && list.deskViewPort !== "") {
                    temp[list.slot_name] = {
                        desk: {
                            viewport: list.deskViewPort,
                            adsize: list.deskAdsize,
                        },
                    };
                }
                if (list.tabAdsize !== null && list.tabAdsize !== "" && list.tabViewPort !== null && list.tabViewPort !== "") {
                    temp[list.slot_name] = Object.assign(temp[list.slot_name], {
                        tab: {
                            viewport: list.tabViewPort,
                            adsize: list.tabAdsize,
                        },
                    });
                }
                if (list.mobAdsize !== null && list.mobAdsize !== "" && (list.mobViewPort !== null) & (list.mobViewPort !== "")) {
                    temp[list.slot_name] = Object.assign(temp[list.slot_name], {
                        mob: {
                            viewport: list.mobViewPort,
                            adsize: list.mobAdsize,
                        },
                    });
                }
                if (list.display_name !== "") {
                    temp[list.slot_name] = Object.assign(temp[list.slot_name], { display_name: list.display_name });
                }
                if (list.slot_name !== "") {
                    temp[list.slot_name] = Object.assign(temp[list.slot_name], { slot_name: list.slot_name });
                }
            }
        });
        return PRESET_UPDATE_MUTATION_CONTAINER({
            variables: { id: currentPreset, presetName: presetName, bannerFloorsPrice: bannerFloorsPrice },
        });
    };

    return (
        <LayoutWrapper model="preset" saveCallback={savePresetSettings}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="p-col-12 p-md-10 p-lg-10 contact-form">
                        <Messages ref={messages} />
                        <div className="p-panel p-component">
                            <SectionHeader screenName="Preset settings">
                                <div className="p-text-bold">Preset settings</div>
                            </SectionHeader>
                        </div>
                        <Panel>
                            <div className="p-fluid contact-form">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label for="preset_name">Preset Title</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputText id="preset_name" name="preset_name" value={presetName} onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label for="banner_floors_price">Banner Price Floors</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputText id="banner_floors_price" name="banner_floors_price" value={bannerFloorsPrice} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetSettings;
