import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import ColorPicker from "./ColorPicker";
import { CREATIVE_ASSET_TYPES, CREATIVE_NAMES, DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import { useSelector } from "react-redux";
import VideoAssetCreativeType from "./VideoAssetCreativeType";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.DESKTOP_INTERACTIVE_BILLBOARD;

export default function DesktopInteractiveBillboard({
    bannerUrl,
    videoCreativeUrl,
    container,
    btnText,
    bgColor,
    hoverBgColor,
    color,
    hoverColor,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    left,
    bottom,
    vastUrl,
    vastDuration,
    videoCreativeType,
    adServingType
}) {
    const { isAdmin } = useSelector((state) => state.userInformation);

    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <VideoAssetCreativeType
                    dropdownLabel="Video Creative Type"
                    types={CREATIVE_ASSET_TYPES}
                    videoCreativeTypePropertyName={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.videoCreativeType}
                    videoCreativeType={videoCreativeType || 'video'}
                    handleChange={handleChange}
                    CREATIVE_TYPE={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    videoUrlInputLabel="Video URL"
                    videoUrlInputName={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.videoCreativeUrl}
                    videoUrl={videoCreativeUrl}
                    vastUrl={vastUrl}
                    vastInputName={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.vastUrl}
                    vastDurationInputName={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.vastDuration}
                    vastDuration={vastDuration}
                />
                <AssetInputOrUploadSection
                    inputValue={bannerUrl}
                    inputLabel="Static banner URL"
                    inputName={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.bannerUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <InputTextSection
                    label="Container"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                    uriEncodeValue={false}
                />
                <InputTextSection
                    label="CTA button text"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.btnText}
                    value={btnText}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
                {isAdmin && (
                    <>
                        <InputTextSection
                            label="CTA left coords (optional)"
                            name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.left}
                            value={left}
                            changeHandler={handleChange}
                            adtype={CREATIVE_TYPE}
                            uriEncodeValue={false}
                        />
                        <InputTextSection
                            label="CTA bottom coords (optional)"
                            name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.bottom}
                            value={bottom}
                            changeHandler={handleChange}
                            adtype={CREATIVE_TYPE}
                            uriEncodeValue={false}
                        />
                    </>
                )}
                <ColorPicker
                    label="CTA button text color"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.color}
                    value={color}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <ColorPicker
                    label="CTA button text hover color"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.hoverColor}
                    value={hoverColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <ColorPicker
                    label="CTA button background color"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.bgColor}
                    value={bgColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <ColorPicker
                    label="CTA button hover color"
                    name={DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS.hoverBgColor}
                    value={hoverBgColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
            </BaseCreativeControls>
        </>
    );
}
