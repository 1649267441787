/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Dropdown } from "primereact/dropdown";
import LayoutWrapper from "./LayoutWrapper";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import Cta from "./common/marketplace/CTA";
import { decodeHtml, noop } from "../utilities/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const REPORT_OPTIONS = [
    { label: "Date range", value: "DATE_RANGE" },
    { label: "Last month", value: "LAST_MONTH" },
    { label: "January", value: "0" },
    { label: "February", value: "01" },
    { label: "March", value: "02" },
    { label: "April", value: "03" },
    { label: "May", value: "04" },
    { label: "June", value: "05" },
    { label: "July", value: "06" },
    { label: "August", value: "07" },
    { label: "September", value: "08" },
    { label: "October", value: "09" },
    { label: "November", value: "10" },
    { label: "December", value: "11" },
];

const MARKETPLACE_PAYMENT_EXPORT_MUTATION = gql`
    mutation callGetReportingDataMutation($customerId: String, $timePeriod: String, $startDate: String, $endDate: String) {
        getReportingDataMutation(input: { customerId: $customerId, timePeriod: $timePeriod, startDate: $startDate, endDate: $endDate }) {
            reportData
        }
    }
`;

const ReportTable = ({ dataRows }) => {
    try {
        let [columns, ...data] = dataRows;
        columns = columns.split(",");
        data = data.map((dataRow) => dataRow.split(","));

        return (
            <table
                css={css`
                    width: 100%;
                    margin-top: 2em;
                    border: 1px solid #eee;
                    thead {
                        background-color: #e7e7e7;
                        td {
                            text-transform: uppercase;
                        }
                    }
                    tr {
                        border-bottom: 1px solid #ddd;
                    }
                    tr:nth-child(even) {
                        background-color: #edf4f4;
                    }
                    td {
                        padding: 10px 0;
                    }
                `}
            >
                <thead>
                    <tr>
                        {columns.map((columnName) => (
                            <td key={columnName}>
                                <b>{columnName}</b>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((dataRow, idx) => {
                        let [
                            customerName,
                            domain,
                            campaignTitle,
                            impressions,
                            ctr,
                            ecpm,
                            revenue,
                            adgridFee,
                            ccCharge
                        ] = dataRow

                        campaignTitle = decodeHtml(campaignTitle)
                        impressions = parseInt(impressions).toLocaleString()
                        ecpm = parseFloat(ecpm).toLocaleString()
                        revenue = parseFloat(revenue).toLocaleString()
                        adgridFee = parseFloat(adgridFee).toLocaleString()
                        ccCharge = parseFloat(ccCharge).toLocaleString()

                        return (
                            <tr key={idx}>
                                {[
                                    customerName,
                                    domain,
                                    campaignTitle,
                                    impressions,
                                    ctr,
                                    ecpm,
                                    revenue,
                                    adgridFee,
                                    ccCharge
                                ].map((dataPoint, index) => (
                                    <td key={index}>{dataPoint}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    } catch(err) {
        return null;
    }
};

const MarketplacePaymentDataExport = () => {
    const [reportMutation] = useMutation(MARKETPLACE_PAYMENT_EXPORT_MUTATION);
    const { currentCustomer } = useSelector((state) => state.userInformation);
    const [reportOption, setReportOption] = useState(REPORT_OPTIONS[0].value);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [aggregateReportData, setAggregateReportData] = useState([]);
    const [isPullingStats, setIsPullingStats] = useState(false);
    const [downloadurl, setDownloadUrl] = useState("");
    const [reportName, setReportName] = useState("");

    useEffect(() => {
        if (currentCustomer) {
            setAggregateReportData([]);
            setDownloadUrl("");
            setReportName("");
        }
    }, [currentCustomer]);

    return (
        <LayoutWrapper model="marketplace" renderOpsBar={false}>
            <div
                css={css`
                    width: 90%;
                    margin: 0 auto;
                `}
            >
                <h1>Marketplace Payment Data</h1>
                <div
                    className="report-config"
                    css={css`
                        background-color: #f4f4f4;
                        padding: 1em;
                    `}
                >
                    <p className="bold">Report configuration</p>
                    <div
                        css={css`
                            display: flex;
                            .timeline {
                                flex-basis: 33%;
                            }
                            .date-range {
                                flex-basis: 33%;
                            }
                            .report-cta {
                                flex-basis: 33%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;
                            }
                            .end-date {
                                margin-left: 1em;
                            }
                        `}
                    >
                        <div className="timeline">
                            <p>Select report timeline:</p>
                            <Dropdown
                                options={REPORT_OPTIONS}
                                value={reportOption}
                                onChange={(ev) => {
                                    setReportOption(ev.value);
                                }}
                            />
                        </div>
                        <div className="date-range">
                            {reportOption === REPORT_OPTIONS[0].value && (
                                <>
                                    <p>Select date range for report:</p>
                                    <Calendar
                                        value={startDate}
                                        className="start-date"
                                        onChange={(ev) => {
                                            setStartDate(ev.value);
                                        }}
                                    />
                                    <Calendar
                                        value={endDate}
                                        className="end-date"
                                        onChange={(ev) => {
                                            setEndDate(ev.value);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="report-cta">
                            <Cta
                                ctaText={isPullingStats ? "Pulling GAM report" : "Pull report"}
                                themeClasses="marketplace-cta outlined default"
                                showLoader={isPullingStats}
                                cssStyles={css`
                                    min-width: 150px;
                                    height: 50px;
                                `}
                                onClick={async () => {
                                    try {
                                        setIsPullingStats(true);
                                        let startYear = startDate.getFullYear();
                                        let startMonth = startDate.getMonth() + 1;
                                        let startDay = startDate.getDate();
                                        let endYear = endDate.getFullYear();
                                        let endMonth = endDate.getMonth() + 1;
                                        let endDay = endDate.getDate();
                                        let monthIndex = 0;
                                        let timePeriod = "";

                                        startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
                                        startDay = startDay < 10 ? "0" + startDay : startDay;
                                        endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
                                        endDay = endDay < 10 ? "0" + endDay : endDay;

                                        let startDateFormatted = `${startYear}-${startMonth}-${startDay}`;
                                        let endDateFormatted = `${endYear}-${endMonth}-${endDay}`;

                                        setDownloadUrl("");
                                        setReportName("");
                                        setAggregateReportData([]);

                                        switch (reportOption) {
                                            case "0":
                                            case "02":
                                            case "03":
                                            case "04":
                                            case "05":
                                            case "06":
                                            case "07":
                                            case "08":
                                            case "09":
                                            case "10":
                                            case "11":
                                                monthIndex = parseInt(reportOption);
                                                monthIndex = monthIndex < 10 ? monthIndex + 1 : monthIndex;
                                                startDateFormatted = `${startYear}-${monthIndex}-01`;
                                                endDateFormatted = `${startYear}-${monthIndex}-30`;
                                                timePeriod = REPORT_OPTIONS.find((v) => {
                                                    return v.value === reportOption;
                                                })?.label;
                                                break;
                                            case "01":
                                                startDateFormatted = `${startYear}-02-01`;
                                                endDateFormatted = `${startYear}-02-30`;
                                                timePeriod = "February";
                                                break;
                                            case "LAST_MONTH":
                                                startMonth = new Date().getMonth();
                                                if (startMonth - 1 === -1) {
                                                    timePeriod = "December";
                                                    startDateFormatted = `${startYear - 1}-12-01`;
                                                    endDateFormatted = `${startYear - 1}-12-30`;
                                                } else if (startMonth - 1 === 0) {
                                                    timePeriod = "January";
                                                    startDateFormatted = `${startYear}-01-01`;
                                                    endDateFormatted = `${startYear}-01-30`;
                                                } else if (startMonth - 1 === 1) {
                                                    timePeriod = "February";
                                                    startDateFormatted = `${startYear}-02-01`;
                                                    endDateFormatted = `${startYear}-02-28`;
                                                } else {
                                                    startDateFormatted = `${startYear}-${startMonth}-01`;
                                                    endDateFormatted = `${startYear}-${startMonth}-30`;
                                                    monthIndex = startMonth - 1 < 10 ? "0" + (startMonth - 1) : "" + (startMonth - 1);
                                                    timePeriod = REPORT_OPTIONS.find((v) => {
                                                        return v.value === monthIndex;
                                                    })?.label;
                                                }
                                                break;
                                            default:
                                                noop();
                                        }

                                        let response = await reportMutation({
                                            variables: {
                                                customerId: currentCustomer,
                                                timePeriod,
                                                startDate: startDateFormatted,
                                                endDate: endDateFormatted,
                                            },
                                        });
                                        let data = JSON.parse(response.data.getReportingDataMutation?.reportData);
                                        let aggregateReportContents = data.aggregate_reports?.split("\n");
                                        let reportUrl = data?.report_file_url;
                                        let reportFilename = data?.report_filename;
                                        setDownloadUrl(reportUrl);
                                        setReportName(reportFilename);
                                        setAggregateReportData(aggregateReportContents.filter((row) => row.length));
                                        setIsPullingStats(false);
                                    } catch {
                                        setIsPullingStats(false);
                                        setDownloadUrl("");
                                        setReportName("");
                                        setAggregateReportData([]);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        margin: 1em 0;
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    {downloadurl && (
                        <a href={downloadurl} download={reportName}>
                            <FontAwesomeIcon icon={faDownload} /> Download report
                        </a>
                    )}
                </div>
                <div className="aggregate-report-table">
                    <ReportTable dataRows={aggregateReportData} />
                </div>
            </div>
        </LayoutWrapper>
    );
};

export default MarketplacePaymentDataExport;
