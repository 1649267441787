/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.scss";
import SelectOptionNotice from "../SelectOptionNotice";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { setCommonDomainData } from "../reducers/commonDomainDataReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { decodeHtml, isEmpty, noop } from "../utilities/utils";
import LayoutWrapper from "./LayoutWrapper";

import AdSettingsInput from "./helper/AdSettingsInput";
import CInputSwitch from "./helper/CInputSwitch";
import SectionHeader from "./helper/SectionHeader";

import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import MarketplaceDomainOverview from "./common/marketplace/MarketplaceDomainOverview";
import AdSettingsDropdown from "./helper/AdSettingsDropdown";
import deepcopy from "deepcopy";
import { setNavigationMode } from "../reducers/userLoginReducer";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { useGeneralDomainSettingsSave } from "../hooks/useGeneralDomainSettingsSave";
import { useCommonDomainInfo } from "../hooks/useCommonDomainInfo";


const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        domainEditMutation(input: { id: $id, cid: $cid }) {
            clientMutationId
            customerInfo
        }
    }
`;


const MARKETPLACE_WELCOME_EMAIL_MUTATION = gql`
    mutation callMarketplaceNotificationMutation($from: String, $to: String, $subject: String, $partner_logo_url: String, $action: String, $customerId: String) {
        marketplaceNotificationMutation(input: { from: $from, to: $to, subject: $subject, partner_logo_url: $partner_logo_url, action: $action, customerId: $customerId }) {
            response
        }
    }
`;

const ADVERTISING_ALIGN_OPTIONS = [
    { code: "center", name: "Center" },
    { code: "left", name: "Left" },
    { code: "right", name: "Right" },
];

const ADVERTISING_LABEL_POSITIONS = [
    { code: "before", name: "Before" },
    { code: "after", name: "After" },
];

const DEFAULT_MARKETPLACE_DATA = {
    marketplace_domain_name: "",
    marketplace_domain_url: "",
    marketplace_impressions: "",
    marketplace_domain_description: "",
    marketplace_domain_logo: "",
    enable_safetag: false,
    marketplace_safetag_words: "",
};

const AdSettings = () => {
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [originalSettingsData, setOriginalSettingsData] = useState(null)
    const [settingsData, setSettingsData] = useState(null);
    const [showSettingsInfo, setShowSettingsInfo] = useState(false);
    const { currentDomain, currentCustomer, userID, customerToDomainsMappings } = useSelector((state) => state.userInformation);
    const dispatch = useDispatch();
    const [marketplaceNetworks, setMarketplaceNetworks] = useState([]);
    const [suggestedMarketplaceNetworks, setSuggestedMarketplaceNetworks] = useState([]);
    const [selectedMarketplaceNetworks, setSelectedMarketplaceNetworks] = useState([]);
    const [marketplaceEnabled, setMarketplaceEnabled] = useState(false);
    const [showToC, setShowToC] = useState(false);
    const commonDomainInfoMutation = useCommonDomainInfo()
    let adgridVersionTypes = [
        { label: "Version 4", value: "v4" },
        { label: "Version 5", value: "v5" },
    ];
    let videoPlayerVendors = [
        {label: "JW Player", value: "jw_player"},
        {label: "Brid.TV Player", value: "brid_player"}
    ]

    const [marketplaceData, setMarketplaceData] = useState(DEFAULT_MARKETPLACE_DATA);
    const [wasMarketplaceInitiallyEnabled, setWasMarketplaceInitiallyEnabled] = useState(false);
    const [marketplaceWelcomeEmailMutation] = useMutation(MARKETPLACE_WELCOME_EMAIL_MUTATION);
    const [marketplaceEmailsList, setMarketplaceEmailsList] = useState("");
    const { toolTipText: helpText, toolTipTextWithAttributes: tooltip } = useAdgridToolTipManager('general_ad_settings');

    const handleChange = (param) => {
        let { target: { name, value } } = param;
        let temp = {};

        switch (name) {
            case "marketplace_network":
                setSelectedMarketplaceNetworks(value);
                break;
            case "enable_marketplace":
                setMarketplaceEnabled(!marketplaceEnabled);
                break;
            case "marketplace_domain_name":
            case "marketplace_domain_url":
            case "marketplace_impressions":
            case "marketplace_domain_description":
            case "marketplace_domain_logo":
            case "marketplace_safetag_words":
            case "display_sizes":
                setMarketplaceData({ ...marketplaceData, [name]: value });
                break;
            case "enable_safetag":
            case "allows_video":
                setMarketplaceData({ ...marketplaceData, [name]: !marketplaceData[name] });
                break;
            case "marketplace_mail_list":
                setMarketplaceEmailsList(value);
                break;
            default:

                temp = Object.assign(temp, settingsData);
                temp[name] = value;

                if (name === "disable_hb_globally_by_country") {
                    temp[name] = JSON.stringify(value);
                }

                setSettingsData(temp);
        }
        dispatch(registerScreenChanges("General Ad Settings"));
    };


    useEffect(() => {
        if (currentDomain && !isEmpty(customerToDomainsMappings)) {
            try {
                if (!marketplaceEnabled && !marketplaceData.marketplace_domain_name) {
                    let domainData = customerToDomainsMappings?.[currentCustomer].find(({ value }) => {
                        return value === currentDomain;
                    });
                    let { name } = domainData;
                    let domainName = name.split(".")[0];
                    domainName = domainName[0].toUpperCase() + domainName.slice(1);
                    setMarketplaceData({ ...marketplaceData, marketplace_domain_name: domainName, marketplace_domain_url: "https://" + name });
                }
            } catch {
                noop()
            }
        }
    }, [currentDomain, customerToDomainsMappings, marketplaceData]);

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
            setMarketplaceEmailsList(temp_data.marketplace_mail_list || "");
            setMarketplaceEnabled(!!temp_data.enable_marketplace);
            setWasMarketplaceInitiallyEnabled(!!temp_data.enable_marketplace);
            temp_data.video_player_type = temp_data.video_player_type || "jwp_embed";
            temp_data.player_vendor = temp_data.player_vendor || "jw_player";
            setSettingsData(temp_data);
            setOriginalSettingsData(temp_data);

            dispatch(setNavigationMode('domain'))

            let marketplaceDomainOverview = JSON.parse(temp_data.marketplace_domain_overview || null);

            setMarketplaceData({ ...marketplaceData, ...marketplaceDomainOverview });

            let marketplaceNetworks = (temp_data?.marketplace_networks ?? []).map((network) => {
                network.name = decodeHtml(network.name);
                return network;
            });

            setMarketplaceNetworks(
                marketplaceNetworks.filter((networkObjectInfo) => {
                    let name = networkObjectInfo?.name?.toLowerCase() || "";
                    return !(name.includes("Run-of-network") || name.includes("run-of-network"));
                })
            );
            setSelectedMarketplaceNetworks(temp_data?.associated_networks ?? []);
            setIndustrySuggestion(temp_data.industry_options || []);
            setMutationDataCompleted(true);
        },
    })

    const SAVE_GENERAL_SETTINGS_MUTATION_CONTAINER = useGeneralDomainSettingsSave({
        onCompleted(dataUpdate) {
            confirmUpdate(dataUpdate);
            removeFromBuiltDomains(`${currentDomain}`);
            setSettingsData({ ...settingsData, enable_marketplace: marketplaceEnabled });
        }
    })

    const [industrySuggestion, setIndustrySuggestion] = useState([]);

    const searchIndustries = (event, name) => {
        setTimeout(() => {
            let results = [];
            if (!event.query.trim().length) {
                results = results.concat(name === "current_industry" ? industrySuggestion : marketplaceNetworks);
            } else {
                results = (name === "current_industry" ? industrySuggestion : marketplaceNetworks) || [];
                results = results.filter((items) => items?.name?.includes(event.query));
            }
            
            setSuggestedMarketplaceNetworks(results);
        }, 150);
    };

    useEffect(() => {
        if (currentDomain && currentCustomer) {
            setShowSettingsInfo(true);
            setMarketplaceData(DEFAULT_MARKETPLACE_DATA);
            DOMAIN_SETTINGS_MUTATION_CONTAINER({ domainId: currentDomain, customerId: currentCustomer });
        }
    }, [currentDomain, currentCustomer, userID]);

    const confirmUpdate = () => {
        dispatch(pushMessage("General Ad Settings", "General settings updated"));
    };

    const saveAdSettings = async () => {
        let curatedData = { ...marketplaceData };
        let requiredMarketplaceFields = [
            ["Domain name", "marketplace_domain_name"],
            ["Marketplace domain url", "marketplace_domain_url"],
        ];

        function replaceDoubleQuotes(str) {
            return str.replace(/"/g, '\\"');
        }

        function jsonEscape(str) {
            return str.replace(/\n/g, "\\\n").replace(/\r/g, "\\\r").replace(/\t/g, "\\\t");
        }

        if (marketplaceEnabled) {
            if (isEmpty(selectedMarketplaceNetworks)) {
                throw new Error("To participate in the Marketplace you must select at least one network");
            }

            if (!marketplaceEmailsList.trim()) {
                throw new Error("You must enter at least one email address to receive notifications from the Marketplace");
            }

            for (let [requiredPropertyName, requiredPropertyKey] of requiredMarketplaceFields) {
                let value = curatedData[requiredPropertyKey]?.trim();
                if (!value) {
                    dispatch(pushMessage("Marketplace domain overview", `${requiredPropertyName} missing`, "error"));
                    throw new Error("Error registering marketplace information");
                }
                curatedData[requiredPropertyKey] = replaceDoubleQuotes(value);
            }

            if (curatedData.marketplace_domain_description) {
                curatedData.marketplace_domain_description = jsonEscape(replaceDoubleQuotes((curatedData.marketplace_domain_description || "").trim()));
            }
        }
        if (!settingsData.network_id) {
            dispatch(pushMessage("General Ad Settings", "Please enter Network ID", "error"));
            throw new Error("Please enter network id");
        } else if (!(/\/\d+\/\w+/).test(settingsData.network_id)) {
            dispatch(pushMessage("General Ad Settings", "Network ID must be in format /network_id/ad_unit", "error"))
            throw new Error("Please enter Network ID in correct format");
        } else if (!settingsData?.adv_preset?.code) {
            dispatch(pushMessage("General Ad Settings", "Please select Adv Preset", "error"));
            throw new Error("Please select Adv Preset");
        } else if (settingsData.banner_floors_price && isNaN(parseFloat(settingsData.banner_floors_price))) {
            dispatch(pushMessage("General Ad Settings", "Invalid Banner Price Floors value", "error"));
            throw new Error("Invalid Banner Price Floors value");
        } else {
            await SAVE_GENERAL_SETTINGS_MUTATION_CONTAINER({
                id: String(currentDomain),
                advPreset: String(settingsData.adv_preset.code),
                networkId: settingsData.network_id,
                childnetworkId: settingsData.child_network_id,
                enableCLS: Boolean(settingsData.enable_cls),
                overrideSiteCSS: Boolean(settingsData.override_site_css),
                industryOptions: JSON.stringify(settingsData.current_industry),
                ads_ActiveViewTime: settingsData.ads_active_view_time,
                shortDomainName: settingsData.short_domain_name,
                bannerFloorsPrice: settingsData.banner_floors_price,
                adEnableSandboxMode: Boolean(settingsData.enable_sandbox_mode),
                adEnableAdhesionIcon: Boolean(settingsData.enable_adhesion_close_icon),
                adInactive: settingsData.inactive,
                adPrebidConflict: settingsData.prebid_conflict,
                sendAllBids: settingsData.enable_send_all_bids,
                adDisableHBGlobally: settingsData.disable_hb_globally,
                adDisableHB_GloballyByCountries: settingsData.disable_hb_globally_by_country,
                adIframeLazyload: settingsData.iframe_lazyload,
                adEnableVideoWrapper: settingsData.enable_video_wrapper,
                adgVersion: settingsData.adgrid_version || adgridVersionTypes[0].value,
                stagingURL: settingsData.staging_url,
                domainMarketplaceNetworks: selectedMarketplaceNetworks.map((item) => item.term_id || item.code).join(","),
                enable_marketplace: marketplaceEnabled,
                video_player_type: settingsData.adgrid_version === "v5" ? "jwp_embed" : "",
                advertisement_label_text: settingsData.advertisement_label_text || "Advertisement",
                advertisement_label_align: settingsData.advertisement_label_align || "center",
                advertisement_label_position: settingsData.advertisement_label_position || "after",
                stickyHeaderHeight: settingsData.sticky_header_height,
                stickyHeaderHeightTab: settingsData.sticky_header_height_tab,
                stickyHeaderHeightMob: settingsData.sticky_header_height_mob,
                marketplaceDomainOverviewData: JSON.stringify({ ...curatedData, selected_networks: selectedMarketplaceNetworks }),
                marketplaceEmailsList: marketplaceEmailsList,
                playerVendor: settingsData.player_vendor
            });

            let response = await commonDomainInfoMutation({ domainId: currentDomain });

            let domainData = JSON.parse(response.data?.domainCommonInfoMutation?.domainInfo || null);

            if (domainData) {
                dispatch(setCommonDomainData(currentDomain, domainData));
            }

            if (!wasMarketplaceInitiallyEnabled && marketplaceEnabled) {
                await marketplaceWelcomeEmailMutation({
                    variables: {
                        subject: "Welcome to the Marketplace",
                        from: "",
                        to: "",
                        partner_logo_url: curatedData.marketplace_domain_logo || "",
                        action: "SEND_WELCOME_EMAIL",
                        customerId: String(currentDomain),
                    },
                });
            }
        }

        return {
            originalData: deepcopy(originalSettingsData),
            mutatedData: settingsData,
        }
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveAdSettings}>
            <Dialog visible={showToC} style={{ width: "50vw" }} onHide={() => setShowToC(false)} header="AdGrid Marketplace Terms and Conditions">
                <div className="mb-1">
                    {/* <h4>
                        <strong>AdGrid Marketplace Terms and Conditions</strong>
                    </h4> */}
                    <p>The users that purchase ads on the AdGrid Marketplace are known as the “Buyer”. The domains that sell their inventory on the AdGrid Marketplace are known as the “Seller”.</p>
                    <p>Buyers agree to pay AdGrid the full amount due at the end of each billing period in order to distribute the funds to each Seller in a timely manner.</p>
                    <p>AdGrid charges the Buyer a commission on the final sale of all advertisements placed at the end of each payment period. For normal campaigns this happens at the end of each month, but for large purchase amounts AdGrid has the discretion to charge weekly.</p>
                    <p>
                        On the third of each month, AdGrid will calculate the total amount of the bids delivered within the previous month on the Sellers’ domains. A single check will be issued to the parent Customer within 15 days, along with a report for each of the Seller’s domains that make up
                        the total of the issued check.
                    </p>
                    <p>If a Buyer wants special payment arrangements, such as using an invoice instead of a credit card, each Seller must agree to the payment terms prior to running the ads on the Marketplace.</p>
                    <p>October 1, 2023</p>
                </div>
            </Dialog>

            {!currentDomain || !currentCustomer ? (
                <SelectOptionNotice optionName="customer" />
            ) : (
                <>
                    {mutationDataCompleted && showSettingsInfo && (
                        <div className="p-grid">
                            <div className="small-full-width p-col p-col-content contact-form">
                                <div className="p-panel p-component">
                                    <SectionHeader screenName="General Settings">
                                        <div className="p-text-bold">General Ad settings</div>
                                    </SectionHeader>
                                    <div className="p-panel-content p-fluid">

                                        <AdSettingsDropdown
                                            name="adv_preset"
                                            label="Adv preset"
                                            value={settingsData?.adv_preset}
                                            options={settingsData?.adv_preset_options}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            placeholder="Select Adv Preset"
                                            {...tooltip('adv_preset')}
                                            required={true}
                                        />

                                        <Tooltip target=".adpositiontooltip" />
                                        <AdSettingsInput labelfor="network_id" label="Network Id" value={settingsData.network_id || ""} changeEvent={handleChange} {...tooltip('network_id')} required={true} />
                                        <AdSettingsInput labelfor="child_network_id" label="Child Network Id" value={settingsData.child_network_id || ""} changeEvent={handleChange} {...tooltip('child_network_id')} required={false} />
                                        <AdSettingsInput labelfor="ads_active_view_time" label="Ads Active View Time" display="none" value={settingsData.ads_active_view_time || ""} changeEvent={handleChange} required={false} />

                                        <AdSettingsInput labelfor="short_domain_name" label="Short Domain Name" value={settingsData.short_domain_name || ""} changeEvent={handleChange} {...tooltip('short_domain_name')} required={false} />

                                        <AdSettingsInput labelfor="banner_floors_price" label="Banner Price Floors" value={settingsData.banner_floors_price || ""} changeEvent={handleChange} {...tooltip('banner_price_floors')} required={false} />

                                        <AdSettingsInput labelfor="go_live_date" label="Go-live date" value={settingsData.go_live_date || ""} disabled={true} {...tooltip('go-live_date')} required={false} />

                                        <AdSettingsInput labelfor="staging_url" label="Staging URL" value={settingsData.staging_url || ""} changeEvent={handleChange} {...tooltip('staging_url')} required={false} />

                                        <AdSettingsDropdown
                                            {...tooltip('adgrid_version')}
                                            label="Adgrid Version"
                                            name="adgrid_version"
                                            value={settingsData.adgrid_version}
                                            options={adgridVersionTypes}
                                            onChange={handleChange}
                                            optionLabel="label"
                                            placeholder="Select Adgrid Version"
                                        />

                                        <AdSettingsDropdown
                                            {...tooltip('advertisement_label_position')}
                                            label="Advertisement Label Position"
                                            name="advertisement_label_position"
                                            value={settingsData.advertisement_label_position || ADVERTISING_LABEL_POSITIONS[1].code}
                                            options={ADVERTISING_LABEL_POSITIONS}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            optionValue="code"
                                            placeholder="Advertisment Label Position"
                                        />

                                        <AdSettingsInput {...tooltip('advertisement_text')} labelfor="advertisement_label_text" value={settingsData.advertisement_label_text} label="Advertisement Text" changeEvent={handleChange} />

                                        <AdSettingsDropdown
                                            {...tooltip('advertisement_label_align')}
                                            label="Advertisement Label Align"
                                            name="advertisement_label_align"
                                            value={settingsData.advertisement_label_align || ADVERTISING_ALIGN_OPTIONS[0].code}
                                            options={ADVERTISING_ALIGN_OPTIONS}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            optionValue="code"
                                            placeholder="Select Advertisement Label Alignment"
                                        />

                                        <div className="p-grid p-mt-2">
                                            <CInputSwitch name="enable_sandbox_mode" checked={settingsData.enable_sandbox_mode} changeEvent={handleChange} label_text="Enable Sandbox Mode" {...tooltip('enable_sandbox_mode')} />

                                            <CInputSwitch name="enable_cls" checked={settingsData.enable_cls} changeEvent={handleChange} label_text="Enable CLS" {...tooltip('enable_cls')} />
                                        </div>

                                        {settingsData.enable_cls &&
                                            <>
                                                <Tooltip target=".adpositiontooltip" />
                                                <div class="border-solid border-primary-500">
                                                    <div className="p-grid p-mt-2">
                                                        <CInputSwitch name="override_site_css" checked={settingsData.override_site_css} changeEvent={handleChange} label_text="Override Site CSS" {...tooltip('override_site_css')} />
                                                        {settingsData.override_site_css && <span className="small-notice" style={{ marginBottom: '10px' }}>It may break some styling as it will override the template's CSS.</span>}
                                                    </div>

                                                    <div className="adapter-config">
                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                            <label>Sticky Header Height</label>
                                                        </div>
                                                        <AdSettingsInput placeholder="Desktop" labelfor="sticky_header_height" value={settingsData.sticky_header_height || ""} changeEvent={handleChange} required={false} />
                                                        <AdSettingsInput placeholder="Tablet" labelfor="sticky_header_height_tab" value={settingsData.sticky_header_height_tab || ""} changeEvent={handleChange} required={false} />
                                                        <AdSettingsInput placeholder="Mobile" labelfor="sticky_header_height_mob" value={settingsData.sticky_header_height_mob || ""} changeEvent={handleChange} required={false} />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className="p-grid p-mt-2">
                                            <CInputSwitch name="enable_adhesion_close_icon" checked={settingsData.enable_adhesion_close_icon} changeEvent={handleChange} label_text="Enable Adhesion's Close Icon" {...tooltip('enable_adhesions_close_icon')} />

                                            <CInputSwitch name="inactive" checked={settingsData.inactive} changeEvent={handleChange} label_text="Inactive" {...tooltip('inactive')} />

                                            <CInputSwitch name="enable_send_all_bids" checked={settingsData.enable_send_all_bids} changeEvent={handleChange} label_text="Send all Prebid bidders to GAM" {...tooltip('send_all_prebid_bidders_to_gam')} />


                                            <CInputSwitch name="enable_video_wrapper" checked={settingsData.enable_video_wrapper} changeEvent={handleChange} label_text="Enable Video Wrapper" {...tooltip('enable_video_wrapper')} />

                                            {
                                                settingsData.enable_video_wrapper && (
                                                    <div className="small-full-width p-col p-col-content">
                                                        <div className="p-panel p-component">
                                                            <div className="p-fluid">
                                                                <AdSettingsDropdown
                                                                    label="Player Vendor"
                                                                    name="player_vendor"
                                                                    value={settingsData.player_vendor}
                                                                    options={videoPlayerVendors}
                                                                    onChange={handleChange}
                                                                    optionLabel="label"
                                                                    placeholder="Select Video Player"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <CInputSwitch name="enable_marketplace" checked={marketplaceEnabled} changeEvent={handleChange} label_text=" ">
                                                <p>
                                                    I agree to participate in the AdGrid Marketplace and adhere to the{" "}
                                                    <strong>
                                                        <span
                                                            onClick={() => setShowToC(true)}
                                                            style={{
                                                                color: "#2196f3",
                                                                cursor: "pointer",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            AdGrid Marketplace Terms and Conditions
                                                        </span>
                                                    </strong>
                                                    . This service allows AdGrid Customers to bid on our ad inventory through the Google Ad Manager{" "}
                                                    <b>
                                                        <a href="https://support.google.com/admanager/answer/3721872" target="_blank" rel="noreferrer">
                                                            dynamic allocation
                                                        </a>
                                                    </b>{" "}
                                                    process.
                                                </p>
                                            </CInputSwitch>
                                        </div>
                                    </div>
                                    {marketplaceEnabled && (
                                        <div className="p-panel p-component" style={{ marginTop: "1em" }}>
                                            <SectionHeader screenName="Domain Overview">
                                                <div className="p-text-bold">Domain Overview</div>
                                            </SectionHeader>
                                            <div className="p-panel-content p-fluid">
                                                <MarketplaceDomainOverview
                                                    currentCustomer={currentCustomer}
                                                    selectedMarketplaceNetworks={selectedMarketplaceNetworks}
                                                    onChange={handleChange}
                                                    suggestedMarketplaceNetworks={suggestedMarketplaceNetworks}
                                                    searchIndustries={searchIndustries}
                                                    marketplaceDomainName={marketplaceData.marketplace_domain_name}
                                                    marketplaceDomainUrl={marketplaceData.marketplace_domain_url}
                                                    marketplaceImpressions={marketplaceData.marketplace_impressions}
                                                    marketplaceDomainDescription={marketplaceData.marketplace_domain_description}
                                                    marketplaceLogo={marketplaceData.marketplace_domain_logo}
                                                    allowSafetag={marketplaceData.enable_safetag}
                                                    safetagWords={marketplaceData.marketplace_safetag_words}
                                                    selectedDisplaySizes={marketplaceData.display_sizes}
                                                    marketplaceMailList={marketplaceEmailsList}
                                                    allowVideo={marketplaceData.allows_video}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="small-full-width p-col p-col-sidebar">
                                <div className="p-panel p-component">
                                    <SectionHeader title="Descriptions" />
                                    <div className="p-panel-content p-fluid">
                                        <h6 className="p-text-bold p-mb-0">Network Id</h6>
                                        <p>{helpText('help_text____network_id')}</p>
                                        <h6 className="p-text-bold p-mb-0">Short Domain Name</h6>
                                        <p>{helpText('help_text____short_domain_name')}</p>
                                        <h6 className="p-text-bold p-mb-0">Enable Sandbox Mode</h6>
                                        <p>{helpText('help_text____enable_sandbox_mode')}</p>
                                        <h6 className="p-text-bold p-mb-0">Enable CLS</h6>
                                        <p>{helpText('help_text____enable_cls')}
                                        </p><h6 className="p-text-bold p-mb-0">Override Site CSS</h6>
                                        <p>{helpText('help_text____override_site_css')}</p>
                                        <h6 className="p-text-bold p-mb-0 p-d-none">Enable Adhesion's Close Icon</h6>
                                        <p className="p-d-none">{helpText('')}</p>
                                        <h6 className="p-text-bold p-mb-0">Inactive</h6>
                                        <p>{helpText('help_text____inactive')}</p>
                                        <h6 className="p-text-bold p-mb-0">Disable HB Globally</h6>
                                        <p>{helpText('help_text____disable_hb_globally')}</p>
                                        <h6 className="p-text-bold p-mb-0">Sticky Header Height Position</h6>
                                        <p>{helpText('help_text____sticky_header_height_position')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </LayoutWrapper>
    );
};

export default AdSettings;
