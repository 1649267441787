import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import UserEdit from './UserEdit';
import ReactDOM from 'react-dom'
import ScrollToTop from '../ScrollToTop';
import { BrowserRouter } from 'react-router-dom'

const userInfo = JSON.parse(localStorage.getItem('user-info'))

const Users = () => {

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    }

    const editProduct = (product) => {
        ReactDOM.render(
            <BrowserRouter>
              <ScrollToTop>
                <UserEdit />
              </ScrollToTop>
            </BrowserRouter>,
            document.getElementById('root'),
          )
    }

    return (
        <div className="p-grid crud-demo">
            <DataTable value={userInfo}>
                <Column field="data.user_nicename" header="Last Name"></Column>
                <Column field="data.user_nicename" header="First Name"></Column>
                <Column field="data.user_email" header="Email"></Column>
                <Column field="data.Phone" header="Phone"></Column>
                <Column field="data.user_status" header="Status"></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}

export default Users