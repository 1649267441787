/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Cta from "../marketplace/CTA";
import { faBan, faUpload } from "@fortawesome/free-solid-svg-icons";

const uploadControlsCss = css`
    width: 140px;
    label {
        cursor: pointer;
        background-color: transparent;
        border-radius: 3px;
        border: 1px solid #2196f3;
        padding: 0.55rem 0.5rem;
        font-size: 1rem;
        text-align: center;
        color: #2196f3;
        transition: background 0.35s, color 0.35s, border-color 0.35s;
        &:hover {
            background-color: #2196f3;
        }
    }
    .icon {
        padding-right: 3px;
    }
    input[type="file"] {
        display: none;
    }
    label,
    .icon {
        cursor: pointer;
        &:hover {
            color: #fff;
        }
    }
`;

export const CustomCreativesUploadControls = ({ accept, loadAssetHandler, isAssetSelected, uploadAsset }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <div css={uploadControlsCss}>
                <label>
                    Choose file &nbsp;
                    <input type="file" name="file-upload" accept={accept} onInput={loadAssetHandler} />
                    <span className={`icon pi pi-${accept.startsWith("image") ? "image" : "video"}`}></span>
                </label>
            </div>
            <div>
                <Cta
                    disabled={!isAssetSelected}
                    onClick={uploadAsset}
                    ctaText="Upload"
                    icon={!isAssetSelected ? faBan : faUpload}
                    themeClasses="marketplace-cta outlined success"
                    cssStyles={css`
                        min-width: unset;
                        width: 100px;
                    `}
                />
            </div>
        </div>
    );
};
