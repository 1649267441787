import React, { useState, useEffect, useRef } from "react";
import LayoutWrapper from "./LayoutWrapper";
import { gql, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { getIn, noop } from "../utilities/utils";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "./styles.scss";
import { TabView, TabPanel } from "primereact/tabview";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCreditCard, faCreditCardAlt, faCaretRight, faArrowCircleRight, faSpinner, faCheckCircle, faTrashCan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Accordion, AccordionTab } from "primereact/accordion";
import { registerAccountInfo } from "../reducers/stripeInfoReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { Dialog } from "primereact/dialog";
import {Button} from "primereact/button";
//import {invoices} from "../utilities/invoiceData";

library.add(faCreditCard, faCreditCardAlt, faCaretRight, faArrowCircleRight, faSpinner, faCheckCircle, faTrashCan);

let MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const INVOICES_MUTATION = gql`
    mutation callInvoicingMutation($stripeCustomerId: String, $customerId: String) {
        invoicingMutation(input: { stripeCustomerId: $stripeCustomerId, customerId: $customerId }) {
            invoicesList
        }
    }
`;

const STRIPE_CARD_SETUP_MUTATION = gql`
    mutation callStripeCardSetupMutation($customerId: String, $stripeCustomerId: String, $operation: String, $cardId: String, $customerName: String) {
        stripeCardSetupMutation(input: { customerId: $customerId, stripeCustomerId: $stripeCustomerId, operation: $operation, cardId: $cardId, customerName: $customerName }) {
            setupIntentResult
        }
    }
`;

const STRIPE_CARD_LISTING_MUTATION = gql`
    mutation callStripeCardListMutation($stripeCustomerId: String) {
        stripeCardListMutation(input: { stripeCustomerId: $stripeCustomerId }) {
            cardListingResult
        }
    }
`;

const STRIPE_PENDING_ORDERS_MUTATION = gql`
    mutation callStripePendingOrdersMutation($id: String) {
        stripePendingOrdersMutation(input: { id: $id }) {
            ordersInfo
        }
    }
`;

const spinnerAnimation = css`
    animation: spin infinite 2s linear;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d",
            },
        },
        invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const flexRowVerticallyCentered = css`
    display: flex;
    align-items: center;
`;

const flexRowAllCentered = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const cardRowStyle = css`
    display: flex;
    padding: 5px;
    padding-left: 0.5em;
    align-items: center;
    cursor: pointer;
    position: relative;
    > svg[data-icon="credit-card"] {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
`;

const submitCardInfoBtn = css`
    display: block;
    width: 100%;
    border: none;
    background-color: #3f51b5;
    padding: 10px;
    color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    transition: 0.25s background;
    &:hover {
        background-color: #5c6bc0;
    }
`;

const Billing = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [cardSetupMutation] = useMutation(STRIPE_CARD_SETUP_MUTATION);
    const [cardListingMutation] = useMutation(STRIPE_CARD_LISTING_MUTATION);
    const [pendingOrdersMutation] = useMutation(STRIPE_PENDING_ORDERS_MUTATION);
    const [invoicesMutation] = useMutation(INVOICES_MUTATION);
    const { customerToStripeAccountMappings } = useSelector((state) => state.stripeInfo);
    const { currentCustomer, customers, isAdmin } = useSelector((state) => state.userInformation);
    const [customerName, setCustomerName] = useState("");
    const defaultCardEmail = "";
    const [cardsListing, setCardsListing] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("");
    const [submittingCardInfo, setIsSubmittingCardInfo] = useState(false);
    const [finishedRetrievingPaymentMethods, setFinishedRetrievingPaymentMethods] = useState(false);
    const [invoicingData, setInvoicingData] = useState([]);
    const addCardFormRef = useRef();
    const [dialogHeader, setDialogHeader] = useState("");
    const [visible, setVisibility] = useState(false);
    const [dialogInvoices, setDialogInvoices] = useState([]);
    const [isLoadingStripeData, setIsLoadingStripeData] = useState(false);

    function resetComponentState() {
        setCustomerName("");
        setCardsListing([]);
        setDefaultPaymentMethod("");
        setIsSubmittingCardInfo(false);
        setFinishedRetrievingPaymentMethods(false);
    }

    async function listPaymentMethods(stripeCustomerId) {
        return cardListingMutation({
            variables: {
                stripeCustomerId,
            },
        })
            .then((response) => {
                let listing = getIn(response, "data", "stripeCardListMutation", "cardListingResult");
                setFinishedRetrievingPaymentMethods(true);
                listing = listing ? JSON.parse(listing) : {};
                let data = listing?.cards?.data || [];
                setCardsListing(data);
                setDefaultPaymentMethod(listing.defaultCard || "");
                setFinishedRetrievingPaymentMethods(true);
                return data;
            })
            .catch((err) => {
                console.log("err: ", err.message);
            });
    }

    useEffect(() => {
        if (currentCustomer && customerToStripeAccountMappings[currentCustomer]) {
            setIsLoadingStripeData(true);
            setDialogInvoices([]);
            setDialogHeader("");
            setInvoicingData([]);
            listPaymentMethods(customerToStripeAccountMappings[currentCustomer]);
            invoicesMutation({
                variables: {
                    stripeCustomerId: customerToStripeAccountMappings[currentCustomer],
                    customerId: currentCustomer,
                },
            })
                .then(async (response) => {
                    let invoices = JSON.parse(response.data?.invoicingMutation?.invoicesList || null);
                    if (invoices) {
                        let reversedInvoices = (invoices?.invoices || []).reverse();
                        setInvoicingData(reversedInvoices);
                        setIsLoadingStripeData(false);
                    } else {
                        setInvoicingData([]);
                        setIsLoadingStripeData(false);
                    }
                })
                .catch((err) => {
                    setIsLoadingStripeData(false);
                    setDialogInvoices([]);
                    setDialogHeader("");
                    setInvoicingData([]);
                    console.log(err);
                });
            // listTransactions();
        }
    }, [currentCustomer, customerToStripeAccountMappings]);

    /*setTimeout(()=>{
        setInvoicingData(invoices)
    }, 500)*/

    useEffect(() => {
        if (currentCustomer) {
            resetComponentState();
            const o = (customers || []).filter(({ value }) => value === `${currentCustomer}`);
            const custName = o.length ? getIn(o, 0, "name") : noop();
            setCustomerName(custName);
            pendingOrdersMutation({
                variables: {
                    id: `${currentCustomer}`,
                },
            })
                .then((response) => {
                    let ordersInfo = getIn(response, "data", "stripePendingOrdersMutation", "ordersInfo");
                    ordersInfo = JSON.parse(ordersInfo || {});

                    if (ordersInfo?.postsInfo?.length) {
                        ordersInfo = ordersInfo.postsInfo.reduce(
                            (acc, curr) => {
                                if (curr.status === "succeeded") {
                                    acc.completed.push(curr);
                                } else {
                                    acc.pending.push(curr);
                                }
                                return acc;
                            },
                            { pending: [], completed: [] }
                        );

                        ordersInfo.completed.sort((o1, o2) => {
                            return +new Date(o2.endDate) - +new Date(o1.endDate);
                        });
                        ordersInfo.pending.sort((o1, o2) => {
                            return +new Date(o2.endDate) - +new Date(o1.endDate);
                        });
                    } else {
                        ordersInfo = {};
                    }

                    // setPaymentInformation(ordersInfo);
                })
                .then((r) => {})
                .catch((err) => {
                    dispatch(pushMessage("Billing", err.message, "error"));
                });
        }
    }, [currentCustomer, customers]);

    async function operateOnDefaultPaymentMethod(paymentMethodId, operation, customerId, stripeCustomerId) {
        await cardSetupMutation({
            variables: {
                customerId,
                stripeCustomerId,
                operation,
                cardId: paymentMethodId,
                customerName,
            },
        });
    }

    const creativeTypeColumn = (rowData) => {
        return <span>{rowData?.type === "cust_creative" ? "Creative" : "Marketplace"}</span>;
    };

    const billingPeriodColumn = (rowData) => {
        return (
            <span>
                {rowData.startDate} - {rowData.endDate}
            </span>
        );
    };

    const currentChargeColumn = (rowData) => {
        try {
            let amount = parseInt(rowData.amount).toLocaleString();
            return "$" + amount + ".00";
        } catch (err) {
            return <span>NA</span>;
        }
    };

    const impressionsColumn = (rowData) => {
        try {
            return parseInt(rowData.impressions).toLocaleString();
        } catch (err) {
            return <span>NA</span>;
        }
    };

    const hideDialog = () => {
        setVisibility(false);
        setDialogInvoices([]);
        setDialogHeader("");
    };

    const transactionDateColumn = (rowData) => {
        return <span>{new Date(rowData.created * 1000).toString()}</span>;
    };

    const paymentMethodColumn = (rowData) => {
        if (rowData.last_payment_error) {
            let {
                last_payment_error: {
                    payment_method: {
                        card: { brand, last4 },
                    },
                },
            } = rowData;
            return (
                <span>
                    <strong>Brand: </strong>
                    <span>{brand}</span> / <strong>last 4 digits: </strong>
                    <span>{last4}</span>
                </span>
            );
        } else {
            if (!rowData.payment_method_details) return <span>Payment details could not be retrieved for this transaction</span>;
            let {
                payment_method_details: {
                    card: { brand, last4 },
                },
            } = rowData;
            return (
                <span>
                    <strong>Brand: </strong>
                    <span>{brand}</span> / <strong>last 4 digits: </strong>
                    <span>{last4}</span>
                </span>
            );
        }
    };

    const trasactionStatusColumn = (rowData) => {
        return rowData.status === "succeeded" ? (
            <span style={{ color: "#2acf2d" }}>
                <i className="pi pi-check-circle"></i> Succeeded
            </span>
        ) : (
            <span className="capitalize" style={{ color: "#f44336" }}>
                <i className="pi pi-times-circle"></i> {rowData.status.split("_").join(" ")}
            </span>
        );
    };

    const transactionStatusDetailsColumn = (rowData) => {
        return rowData.status === "succeeded" ? <span></span> : <span>{rowData?.last_payment_error?.message || "N/A"}</span>;
    };

    function jsonToCsv(items) {
        const header = Object.keys(items[0]);
        const headerString = header.join(',');
        // handle null or undefined values here
        const replacer = (key, value) => value ?? '';
        const rowItems = items.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        // join header and body, and break into separate lines
        const csv = [headerString, ...rowItems].join('\r\n');
        return csv;
    }

    const exportTblCSV = (obj) => {

        /**
         * Invoice ID
         * Order Name
         * Billing Period
         * Type
         * Impressions
         * Total Charged
         *
         * revenue_amount
         * additional_fee_amount
         * audience_amount
         * */

        const impressionsColumn = (rowData) => {
            try {
                return parseInt(rowData.impressions).toLocaleString();
            } catch (err) {
                return 'NA';
            }
        };

        const currentChargeColumn = (rowData, field) => {
            try {
                let amount = parseInt(rowData[field] || 0).toLocaleString();
                return "$" + amount + ".00";
            } catch (err) {
                return "NA";
            }
        };

        const mapedData = obj.map((row) => {


            return {
                "Invoice ID": row.invoiceID,
                "Order Name": row.orderName,
                "Billing Period": `${row.startDate} - ${row.endDate}`,
                "Type": row?.type === "cust_creative" ? "Creative" : "Marketplace", //{rowData?.type === "cust_creative" ? "Creative" : "Marketplace"}
                "Impressions": impressionsColumn(row),
                "Total Charged": currentChargeColumn(row, 'amount'),
                "Revenue": "$" + (Number(row.amount) - (Number(row.amount) * 0.1)),
                //"Revenue": currentChargeColumn(row, 'revenue_amount'),
                //"Additional Amount": currentChargeColumn(row, 'additional_fee_amount'),
                "Additional Amount": "$" + Number(row.amount) * 0.1 ,
                "Audience Amount": currentChargeColumn(row, 'audience_amount'),
            }
        });

        const csv = jsonToCsv(mapedData);
        //console.log(csv);

        const blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8;'
        });

        if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, 'file.csv');
        } else {
            const link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);

            if (link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', 'file.csv');
                link.click();
            } else {
                const csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    };

    return (
        <>
            <LayoutWrapper mode="preset" renderOpsBar={false}>
                <h1 style={{ textAlign: "center" }}>BILLING</h1>
                <Dialog header={"Order name: " + dialogHeader} visible={visible} closeOnEscape modal onHide={hideDialog} width={{ width: "50%" }}>
                    <DataTable
                        value={dialogInvoices}
                        css={css`
                            table {
                                table-layout: auto;
                            }
                        `}
                    >
                        <Column header="Transaction ID" field="id"></Column>
                        <Column header="Transaction Date" body={transactionDateColumn}></Column>
                        <Column header="Transaction Description" field=""></Column>
                        <Column header="Payment Method" body={paymentMethodColumn}></Column>
                        <Column header="Transaction Status" body={trasactionStatusColumn}></Column>
                        <Column header="Details On Status" body={transactionStatusDetailsColumn}></Column>
                    </DataTable>
                </Dialog>
                <TabView
                    css={css`
                        .p-tabview-selected > a {
                            border: none !important;
                            background-color: #3f51b5 !important;
                            color: #fff !important;
                            border-radius: 6px;
                            box-shadow: none !important;
                        }
                    `}
                >
                    <TabPanel header="Payment Methods">
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <div>
                                <div
                                    id="add-card-form"
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        width: 35vw;
                                        padding-top: 40px;
                                    `}
                                >
                                    <h3
                                        css={css`
                                            flex-basis: 100%;
                                        `}
                                    >
                                        Add a payment method
                                    </h3>
                                    <form
                                        id="payment-form"
                                        ref={addCardFormRef}
                                        onSubmit={async (ev) => {
                                            try {
                                                ev.preventDefault();
                                                setIsSubmittingCardInfo(true);
                                                let stripeCustomerId = customerToStripeAccountMappings[currentCustomer];
                                                let setupResult = await cardSetupMutation({
                                                    variables: {
                                                        customerId: `${currentCustomer}`,
                                                        stripeCustomerId,
                                                        operation: null,
                                                        cardId: null,
                                                        customerName,
                                                    },
                                                });

                                                setupResult = JSON.parse(getIn(setupResult, "data", "stripeCardSetupMutation", "setupIntentResult"));

                                                if (!setupResult?.intent?.client_secret) {
                                                    dispatch(pushMessage("Billing", setupResult.message, "error"));
                                                    resetComponentState();
                                                    setFinishedRetrievingPaymentMethods(true);
                                                    return;
                                                }

                                                let cardConfirmationResult = await stripe.confirmCardSetup(setupResult.intent.client_secret, {
                                                    payment_method: {
                                                        card: elements.getElement("card"),
                                                        billing_details: { email: defaultCardEmail },
                                                    },
                                                });

                                                if (cardConfirmationResult.error) {
                                                    dispatch(pushMessage("Billing", "There was an error setting up your CC info", "error"));
                                                } else {
                                                    dispatch(pushMessage("Billing", "Your card has been registered as a payment method!"));
                                                    elements.getElement("card").clear();
                                                    dispatch(registerAccountInfo(currentCustomer, setupResult.customerId));
                                                    let cards = await listPaymentMethods(setupResult.customerId);

                                                    if (!cardsListing.length) {
                                                        let cardId = cardConfirmationResult?.setupIntent?.payment_method;
                                                        await operateOnDefaultPaymentMethod(cardId, "update", `${currentCustomer}`, setupResult.customerId);
                                                        setCardsListing(cards);
                                                        setDefaultPaymentMethod(cardId);
                                                    } else {
                                                        setCardsListing(cards);
                                                    }
                                                }
                                                setIsSubmittingCardInfo(false);
                                            } catch (err) {
                                                console.log(err.message);
                                            }
                                        }}
                                    >
                                        <CardElement id="card-element" options={cardStyle} />
                                        <button id="submit" css={submitCardInfoBtn} disabled={submittingCardInfo}>
                                            {submittingCardInfo ? (
                                                <span css={flexRowAllCentered}>
                                                    Registering credit card to your account &nbsp;
                                                    <FontAwesomeIcon icon="spinner" css={spinnerAnimation} color={"#fff"} fontSize={"2em"} />
                                                </span>
                                            ) : (
                                                "Associate card to your account"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div
                                style={{
                                    marginLeft: "3em",
                                    flexBasis: "50%",
                                }}
                            >
                                <h4>
                                    <strong>Payment methods</strong>
                                </h4>
                                {!(currentCustomer && customerToStripeAccountMappings[currentCustomer]) ? (
                                    <p>To register as a customer add a payment method.</p>
                                ) : cardsListing.length ? (
                                    <>
                                        <Accordion
                                            activeIndex={0}
                                            css={css`
                                                .p-accordion-content {
                                                    border: none !important;
                                                }
                                                .p-accordion-header .p-accordion-header-link {
                                                    border: 1px solid #dee2e6 !important;
                                                }
                                            `}
                                        >
                                            {cardsListing.map((cardDetails) => {
                                                let {
                                                    card: { brand, exp_month, exp_year, last4 },
                                                    id,
                                                } = cardDetails;
                                                return (
                                                    <AccordionTab
                                                        key={id}
                                                        header={
                                                            <div css={cardRowStyle}>
                                                                <FontAwesomeIcon icon="credit-card" fontSize={"2em"} color={"#1976d2"} />
                                                                <div className="card-information">
                                                                    <div className="last-4">
                                                                        <strong>
                                                                            <span className="brand">{brand}</span>
                                                                            <span className="last-four"> &#9679;&#9679;&#9679;&#9679; {last4}</span>
                                                                            {id === defaultPaymentMethod && (
                                                                                <span
                                                                                    css={css`
                                                                                        display: inline-block;
                                                                                        margin-left: 2.5em;
                                                                                        padding: 3px 10px;
                                                                                        background-color: rgb(207, 245, 246);
                                                                                        border-radius: 6px;
                                                                                        font-size: 10px;
                                                                                        color: #0055bc;
                                                                                    `}
                                                                                >
                                                                                    Default payment method
                                                                                </span>
                                                                            )}
                                                                        </strong>
                                                                    </div>
                                                                    <span className="exp-date">
                                                                        Expires {MONTHS[exp_month]} {exp_year}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <div
                                                            className="card-opts"
                                                            css={css`
                                                                .opt {
                                                                    margin-bottom: 10px;
                                                                    cursor: pointer;
                                                                    &:hover {
                                                                        font-weight: bold;
                                                                    }
                                                                }
                                                            `}
                                                        >
                                                            {id !== defaultPaymentMethod && (
                                                                <div className="opt">
                                                                    <span
                                                                        className="set-default opt"
                                                                        onClick={async () => {
                                                                            try {
                                                                                await operateOnDefaultPaymentMethod(id, "update", `${currentCustomer}`, customerToStripeAccountMappings[currentCustomer]);
                                                                                setDefaultPaymentMethod(id);
                                                                                dispatch(pushMessage("Billing", "The default payment method has been changed"));
                                                                            } catch (err) {
                                                                                dispatch(pushMessage("Billing", "Could not change the default payment method", "error"));
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon="circle-check" color={"#03a9f4"} />
                                                                        &nbsp;Set as default payment method
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {
                                                                isAdmin
                                                                    ? (
                                                                        <div className="opt">
                                                                            <span
                                                                                className="detach opt"
                                                                                onClick={async () => {
                                                                                    try {
                                                                                        if (cardsListing.length && id === defaultPaymentMethod) {
                                                                                            dispatch(
                                                                                                pushMessage(
                                                                                                    "Billing",
                                                                                                    cardsListing.length === 1
                                                                                                        ? "This is the default payment method. To remove it, add another payment method and set it as the new default"
                                                                                                        : "This is the default payment method. To remove it, set another payment method as the new default",
                                                                                                    "error"
                                                                                                )
                                                                                            );
                                                                                            return;
                                                                                        }
                                                                                        await operateOnDefaultPaymentMethod(id, "detach", `${currentCustomer}`, customerToStripeAccountMappings[currentCustomer]);
                                                                                        setCardsListing(cardsListing.filter((c) => c.id !== id));
                                                                                        dispatch(pushMessage("Billing", "This payment method has been removed"));
                                                                                    } catch (err) {
                                                                                        dispatch(pushMessage("Billing", "Could not remove this payment method", "error"));
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon icon="trash-can" color={"#ff0000"} />
                                                                                &nbsp;Remove this payment method
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                    : (
                                                                        <div>
                                                                            <FontAwesomeIcon icon={faInfoCircle} color={'#ff0000'} />
                                                                            &nbsp;If you want to remove this payment method, please reach out to <a href="mailto:support@adgrid.io">support@adgrid.io</a>
                                                                        </div>
                                                                    )

                                                            }

                                                        </div>
                                                    </AccordionTab>
                                                );
                                            })}
                                        </Accordion>
                                    </>
                                ) : (
                                    <>
                                        {finishedRetrievingPaymentMethods ? (
                                            <p css={flexRowVerticallyCentered}>
                                                <span
                                                    css={css`
                                                        font-size: 2em;
                                                        color: #ff0000;
                                                    `}
                                                    className="pi pi-info-circle"
                                                ></span>
                                                &nbsp; You need to associate a payment method to your billing account
                                            </p>
                                        ) : (
                                            <span css={flexRowVerticallyCentered}>
                                                Retrieving payment methods &nbsp;&nbsp;&nbsp;
                                                <FontAwesomeIcon icon="spinner" css={spinnerAnimation} color={"#1976d2"} fontSize={"3em"} />
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel
                        header={
                            <p>
                                {isLoadingStripeData ? (
                                    <span>
                                        <i className="pi pi-spin pi-spinner"></i> Loading invoicing data...
                                    </span>
                                ) : (
                                    <span>Invoices ({invoicingData.length})</span>
                                )}
                            </p>
                        }
                    >
                        {invoicingData.length !== 0 && <div css={css`
                            width: 100%;
                            display: flex;
                            justify-content: right;
                            margin-bottom: 3px;
                        `}>
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportTblCSV(invoicingData)} data-pr-tooltip="CSV" label="Export CSV"/>
                        </div>}

                        <DataTable
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            tableStyle={{ minWidth: '50rem' }}
                            value={invoicingData}
                            css={css`
                                table {
                                    table-layout: auto;
                                }
                                .order-name {
                                    max-width: 150px;
                                }
                            `}
                        >
                            <Column field="invoiceID" header="Invoice ID"></Column>
                            <Column field="orderName" header="Order Name" className="order-name"></Column>
                            <Column header="Billing Period" body={billingPeriodColumn}></Column>
                            <Column header="Type" body={creativeTypeColumn}></Column>
                            <Column header="Impressions" body={impressionsColumn}></Column>
                            <Column header="Total Charged" body={currentChargeColumn}></Column>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </LayoutWrapper>
        </>
    );
};

export default Billing;
