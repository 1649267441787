import React, {useEffect, useRef, useState} from "react";
import logo from "../media/adgrid-horizontal-logo.png";
import { useSelector } from "react-redux";

const AppLogo = () => {
    const { currentCustomerLogo } = useSelector((state) => state.userInformation);
    const imgRef = useRef(null);

    useEffect(() => {
        const targetNode = document.querySelector("body");
        const config = { childList: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === "childList" && mutation.addedNodes[0]) {
                    if (mutation.addedNodes[0].nodeName === "IFRAME" && !mutation.addedNodes[0].name) {
                        mutation.addedNodes[0].remove();
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);


    }, []);

    return (
        <div className="layout-logo">
            <img src={currentCustomerLogo || logo} alt="adgrid" ref={imgRef} onError={() => {
                imgRef.current.src = logo
            }} />
        </div>
    );
};

export default AppLogo;
