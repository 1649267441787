/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { gql, useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import { Base64 } from 'js-base64';
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputSwitch } from "primereact/inputswitch";
import { Paginator } from "primereact/paginator";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MarketplaceGamDataContext } from "../context/marketplaceGamDataContext";
import { MarketplaceGlobalDataContext } from "../context/marketplaceGlobalDataContext";
import MarketplaceOrderDataContext from "../context/marketplaceOrderDataContext";
import { registerOrderStats } from "../reducers/lineItemStatsReducer";
import {
    decreaseCreateCount,
    decreaseUpdateCount,
    editMarketplaceOrder,
    increaseCreateCount,
    increaseUpdateCount,
    refreshMarketplaceOrder,
    registerMarketplaceOrders,
    removeOrder,
    setMarketplaceNetworkNames as setReducerMarketplaceNetworkNames,
    setMarketplaceNetworkOptions as setReducerMarketplaceNetworkOptions,
    setMarketplaceNetworks as setReducerMarketplaceNetworks,
    updateMarketplaceOrderByProp,
    updateMarketplaceOrderDomainStatus,
} from "../reducers/marketplaceOrdersReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { decodeHtml, first, getIn, isEmpty, noop } from "../utilities/utils";
import { MULTI_FIELD_READ_MUTATION } from "./adapters/utils/constants";
import CampaignReportDownloadButton from "./common/marketplace/CampaignReportDownloadButton";
import CampaignStatsPanel from "./common/marketplace/CampaignStatsPanel";
import CensusTargetingOverlay from "./common/marketplace/CensusTargetingOverlay";
import {
    accordionStyles,
    AUDIENCE_TARGETING_KEYS,
    CAMPAIGN_BREAKDOWN_MUTATION,
    DEFAULT_MARKETPLACE_ORDER,
    DELETE_ASSET_MUTATION,
    DEVICE_TARGETING_OPTIONS,
    GET_GEOTARGETS_INFO_MUTATION,
    LINE_ITEM_STATS_MUTATION,
    LINEITEM_ACTIONS,
    MAIN_AUDIENCE_KEY_GROUP,
    MARKETPLACE_ACTION_MUTATION,
    MARKETPLACE_DOMAIN_STATUS,
    MARKETPLACE_ORDER_SSP_REGISTRATION,
    MARKETPLACE_ORDER_SSP_UPDATE,
    MARKETPLACE_ORDERS_CREATION_MUTATION,
    MARKETPLACE_ORDERS_MUTATION,
    MARKETPLACE_ORDERS_REVIEW_MUTATION,
    MARKETPLACE_VIDEO_ORDER_CREATION_MUTATION,
    SELLER_MARKETPLACE_ORDERS,
    textInputStyle,
    UPDATE_MARKETPLACE_ORDER_MUTATION,
    UPDATE_MARKETPLACE_VIDEO_ORDER_MUTATION,
    WP_MARKETPLACE_ORDERS_MUTATION
} from "./common/marketplace/constants";
import Cta from "./common/marketplace/CTA";
import DeviceTargeting from "./common/marketplace/DeviceTargeting";
import DomainReviewOverlay from "./common/marketplace/DomainReviewOverlay";
import DomainSelectionOverlay from "./common/marketplace/DomainSelectionOverlay";
import DomainStatusTable from "./common/marketplace/DomainStatusTable";
import FormatSection from "./common/marketplace/FormatSection";
import GeoTargeting from "./common/marketplace/GeoTargeting";
import MarketplaceAccordionHeader from "./common/marketplace/MarketplaceAccordionHeader";
import MarketplaceBuyReviewSection from "./common/marketplace/MarketplaceBuyReviewSection";
import MarketplaceOrderDeletionBanner from "./common/marketplace/MarketplaceOrderDeletionBanner";
import MarketplaceRowControls from "./common/marketplace/MarketplaceRowControls";
import MarketplaceSellerAccordionTab from "./common/marketplace/MarketplaceSellerAccordionTab";
import MarketplaceSidebarInfo from "./common/marketplace/MarketplaceSidebarInfo";
import NoticeSection from "./common/marketplace/NoticeSection";
import OrderRow from "./common/marketplace/OrderRow";
import OrderRowDate from "./common/marketplace/OrderRowDate";
import OrderRowInput from "./common/marketplace/OrderRowInput";
import OrderRowNumberInput from "./common/marketplace/OrderRowNumberInput";
import OrderRowUpload from "./common/marketplace/OrderRowUpload";
import OrderSettingsOverlay from "./common/marketplace/OrderSettingsOverlay";

import ThirdPartyCreativesRow from "./common/marketplace/OrderTextAreaRow";
import PaymentMethodOrderRowDropdown from "./common/marketplace/PaymentMethodOrderRowDropdown";
import SaveSection from "./common/marketplace/SaveSection";
import { audienceTargetingRowStyles, gridControlStyles, managementSidebarStyles, marketplaceDataLoadStyle, networkRowStyles, paymentRegistrationBannerBodyStyles, paymentRegistrationBannerHeaderStyles, sidebarInfoStyles, sidebarStyles, statusTableStyles } from "./common/marketplace/styles";
import PaymentRegistrationBanner from "./common/PaymentRegistrationBanner";
import LayoutWrapper from "./LayoutWrapper";
import { categorizeGeographies, generateSspPayload } from "./common/marketplace/functions";

const STRIPE_CARD_LISTING_MUTATION = gql`
    mutation callStripeCardListMutation($stripeCustomerId: String) {
        stripeCardListMutation(input: { stripeCustomerId: $stripeCustomerId }) {
            cardListingResult
        }
    }
`;

const NOW = new Date();

const Marketplace = () => {
    const [deleteAssetMutation] = useMutation(DELETE_ASSET_MUTATION);
    const [retrieveMarketplaceOrdersMutation] = useMutation(MARKETPLACE_ORDERS_MUTATION);
    const [marketplaceOrderCreationMutation] = useMutation(MARKETPLACE_ORDERS_CREATION_MUTATION);
    const [marketplaceVideoOrderCreationMutation] = useMutation(MARKETPLACE_VIDEO_ORDER_CREATION_MUTATION);
    const [updateMarketplaceorderMutation] = useMutation(UPDATE_MARKETPLACE_ORDER_MUTATION);
    const [updateMarketplaceVideoOrderMutation] = useMutation(UPDATE_MARKETPLACE_VIDEO_ORDER_MUTATION);
    const [wpMarketplaceOrderMutation] = useMutation(WP_MARKETPLACE_ORDERS_MUTATION);
    const [geoTargetsInfoMutation] = useMutation(GET_GEOTARGETS_INFO_MUTATION);
    const [lineItemStatsMutation] = useMutation(LINE_ITEM_STATS_MUTATION);
    const [listCreditCardsMutation] = useMutation(STRIPE_CARD_LISTING_MUTATION);
    const [marketplaceOrderActionMutation] = useMutation(MARKETPLACE_ACTION_MUTATION);
    const [updateMarketplaceReviewStatusMutation] = useMutation(MARKETPLACE_ORDERS_REVIEW_MUTATION);
    const [sellerOrdersMutation] = useMutation(SELLER_MARKETPLACE_ORDERS);
    const [breakdownMutation] = useMutation(CAMPAIGN_BREAKDOWN_MUTATION)
    const [multiFieldRetrieveMutation] = useMutation(MULTI_FIELD_READ_MUTATION)
    const [marketplaceOrderSspRegistration] = useMutation(MARKETPLACE_ORDER_SSP_REGISTRATION)
    const [marketplaceOrderSspUpdate] = useMutation(MARKETPLACE_ORDER_SSP_UPDATE)

    const { currentCustomer, customers, isAgency }
        = useSelector((state) => state.userInformation);
    const { customerToStripeAccountMappings, infoAvailable: stripeInfoAvailable }
        = useSelector((state) => state.stripeInfo);
    const marketplaceInfo
        = useSelector((state) => state.marketplaceInfo);

    const [marketplaceOrders, setMarketplaceOrders] = useState([]);
    const [mutationCompleted, setMutationStatus] = useState(false);
    const [networkNames, setNetworkNames] = useState({});
    const [networkOptions, setNetworkOptions] = useState({});
    const [marketplaceNetworks, setMarketplaceNetworks] = useState([]);
    const [rowValidationErrorIndex, setRowValidationErrorIndex] = useState(null);
    const [customerName, setCustomerName] = useState("");
    const [marketplaceOrderStats, setMarketplaceOrderStats] = useState({});
    const [domainToNetworkMappings, setDomainToNetworkMappings] = useState({});
    const [domainToCustomerMappings, setDomainToCustomerMappings] = useState({});
    const [activeTabs, setActiveTabs] = useState([]);
    const [geoTargetsInfo, setGeoTargetsInfo] = useState([]);
    const [hasPaymentMethods, setHasPaymentMethods] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState("");
    const [domainSelectionOverlayState, setDomainSelectionOverlayState] = useState({ isVisible: false, domainsData: [], networkMappings: {}, marketplaceNetworks: [], orderIndex: -1, networkName: "", networkId: "" });
    const dispatch = useDispatch();
    const [showBuyingScreen, setShowBuyingScreen] = useState(true);
    const [currentlyAssignedDomains, setCurrentlyAssignedDomains] = useState({});
    const [ordersToReview, setOrdersToReview] = useState([]);
    const [displayReviewSection, setDisplayReviewSecion] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalSellerOrders, setTotalSellerOrders] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [idxToDelete, setIdxToDelete] = useState(-1);
    const [displayPaymentMissingSection, setDisplayPaymentSection] = useState(false);
    const [customerRate, setCustomerRate] = useState(10);
    const [individualStats, setIndividualStats] = useState({})
    const [sellerPaginationIndex, setSellerPaginationIndex] = useState(0)
    const [buyerPaginationIndex, setBuyerPaginationIndex] = useState(0)
    const [downloadUrl, setDownloadUrl] = useState('')
    const [downloadFileName, setDownloadFileName] = useState('')
    const [orderSetupOverlaySettings, setOrderSetupOverlaySettings] = useState({
        isSettingsOverlayVisible: false,
        isDismissOperation: false,
        isAddOperation: false,
        orderIdx: 0
    })
    const [censusTargetingOverlaySettings, setCensusTargetingOverlaySettings] = useState({
        isVisible: false,
        orderIdx: 0
    })
    const [censusTargetingData, setCensusTargetingData] = useState({})
    const [audienceTargetingPricingData, setAudienceTargetingPricingData] = useState({})
    const downloadRef = useRef(null)

    useEffect(() => {
        let post_id = 'options'
        let fields = [{ field_name: 'audience_targeting_settings', post_id, parse: true }];

        AUDIENCE_TARGETING_KEYS.forEach(key => {
            fields.push({
                field_name: MAIN_AUDIENCE_KEY_GROUP + '_' + key,
                post_id,
                parse: true
            })
        })

        multiFieldRetrieveMutation({
            variables: {
                fieldDefinitions: JSON.stringify(fields)
            }
        }).then(({ data }) => {
            let censusData = JSON.parse(data.multiFieldRetrieveMutation.response)
            let { audience_targeting_settings, ...rest } = censusData

            setAudienceTargetingPricingData(AUDIENCE_TARGETING_KEYS.reduce((acc, curr) => {
                acc[curr] = rest[MAIN_AUDIENCE_KEY_GROUP + "_" + curr]
                return acc
            }, {}))

            setCensusTargetingData(audience_targeting_settings)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (orderSetupOverlaySettings.isDismissOperation) {
            if (!marketplaceOrders[0]?.format) {
                dispatch(removeOrder(
                    orderSetupOverlaySettings.orderIdx,
                    currentCustomer
                ))
            }
        }
        if (orderSetupOverlaySettings.isAddOperation) {
            setOrderSetupOverlaySettings({
                isSettingsOverlayVisible: false,
                isDismissOperation: false,
                isAddOperation: false,
                orderIdx: 0
            })
        }
    }, [orderSetupOverlaySettings])

    useEffect(() => {
        let willDisplayReviewSection = false;
        let cid = parseInt(currentCustomer);
        ordersToReview.forEach((order) => {
            let { networks, networkStatuses } = order;
            for (let domainId of networks) {
                if (domainToCustomerMappings[domainId] === cid && networkStatuses[domainId]?.status === MARKETPLACE_DOMAIN_STATUS.PENDING) {
                    willDisplayReviewSection = true;
                    break;
                }
            }
        });
        setDisplayReviewSecion(willDisplayReviewSection);
    }, [ordersToReview]);

    useEffect(() => {
        if (currentCustomer) {
            let o = customers.find((customer) => customer.value === currentCustomer);
            setCustomerName(o?.name || "");
            if (stripeInfoAvailable) {
                let isStripeCustomer = !!customerToStripeAccountMappings[currentCustomer];
                if (isStripeCustomer) {
                    listCreditCardsMutation({
                        variables: {
                            stripeCustomerId: customerToStripeAccountMappings[currentCustomer],
                        },
                    })
                        .then((response) => {
                            return JSON.parse(getIn(response.data, "stripeCardListMutation", "cardListingResult"));
                        })
                        .then((data) => {
                            let cards = data?.cards?.data || [];
                            setDefaultPaymentMethod(data?.defaultCard || "");
                            setPaymentMethods(
                                cards.map((item) => {
                                    let {
                                        card: { brand, exp_month, exp_year, last4 },
                                        id,
                                    } = item;
                                    return { data: [brand, exp_month, exp_year, last4].join(","), value: id };
                                })
                            );
                            setHasPaymentMethods(!!cards.length);
                        })
                        .catch((err) => {
                            setHasPaymentMethods(false);
                        });
                } else {
                    setHasPaymentMethods(isStripeCustomer);
                }
            } else {
                setHasPaymentMethods(null);
            }
        }
    }, [currentCustomer, customers, customerToStripeAccountMappings, stripeInfoAvailable]);

    useEffect(() => {
        geoTargetsInfoMutation({ variables: {} })
            .then((response) => {
                return JSON.parse(getIn(response.data, "getGeoTargetsInfoMutation", "gamApiResponse") || null) || [];
            })
            .then((geoTargetsData) => {
                /* let usIndex = geoTargetsData.geoTargetsData.findIndex(geoTarget => geoTarget.countryCode === 'US')
                let usGeoTarget = geoTargetsData.geoTargetsData.splice(usIndex, 1)
                console.log({usIndex, usGeoTarget})
                geoTargetsData.geoTargetsData.unshift(usGeoTarget) */
                let dmaMappings = {}

                try {
                    dmaMappings = geoTargetsData.dma_targets.reduce((acc, dmaItem) => {
                        let { canonicalParentId } = dmaItem
                        acc[canonicalParentId] = acc[canonicalParentId] ? acc[canonicalParentId].concat(dmaItem) : [dmaItem]
                        return acc
                    }, {})
                } catch (err) {
                    // do nothing
                }

                setGeoTargetsInfo(
                    geoTargetsData.geoTargetsData.map((i) => {
                        if (geoTargetsData.states[i.countryCode]) {
                            let states = geoTargetsData.states[i.countryCode];
                            states.forEach(state => {
                                state.key = state.id
                                state.children = dmaMappings[state.id]
                            })
                            let otherChildren = dmaMappings[states[0].canonicalParentId] || []
                            i.children = [...states, ...otherChildren]
                        } else {
                            i.children = geoTargetsData.states[i.countryCode];
                        }
                        i.key = i.id;
                        return i;
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (currentCustomer) {
            const { marketplaceOrders = [], networkNames = {}, networkOptions = {}, marketplaceNetworks = [] } = marketplaceInfo?.[currentCustomer] || {};
            setMarketplaceOrders(marketplaceOrders);
            setMarketplaceNetworks(marketplaceNetworks);
            setNetworkNames(networkNames);
            setNetworkOptions(networkOptions);
        }
    }, [marketplaceInfo, currentCustomer]);

    useEffect(() => {
        setShowBuyingScreen(true);
        setActiveTabs([]);
        setMutationStatus(false);
    }, [currentCustomer]);

    async function requestMarketplaceOrder({ currentCustomer, customerName, page = 0 }) {
        try {
            let response = await retrieveMarketplaceOrdersMutation({
                variables: {
                    id: String(currentCustomer),
                    isMarketPlace: true,
                    customerName,
                    page: String(page),
                },
            });
            return response.data.customOrdersMutation;
        } catch (err) {
            console.log(err);
        }
    }

    async function requestLineItemsStats(lineItemIds) {
        let statsResponse = await lineItemStatsMutation({
            variables: {
                lineItemIdsList: JSON.stringify(lineItemIds),
            },
        });

        let stats = JSON.parse(statsResponse.data?.getLineItemStatsMutation.gamApiStats);
        return stats;
    }

    async function getMarketplaceOrders(currentCustomer, customerName) {
        setShowLoader(true);
        let response = await requestMarketplaceOrder({ currentCustomer, customerName });
        let ordersInfo = JSON.parse(response.ordersInfo);
        let customerToDomainMappings = JSON.parse(response.customerToDomainMappings);

        setCustomerRate(parseFloat(response.customerRate || 10))

        try {
            let [{ total_count }] = JSON.parse(response.total_posts);
            setTotal(parseInt(total_count));
            let marketplaceStats = JSON.parse(ordersInfo.marketplace_stats);
            dispatch(registerOrderStats(marketplaceStats));
        } catch {
            console.log("Error retrieving marketplace orders count");
        }

        try {
            let currentCustomerId = parseInt(currentCustomer);
            let domainsData = Object.values(customerToDomainMappings);
            for (let customerDomains of domainsData) {
                if (customerDomains?.[0]?.customerId === currentCustomerId) {
                    setCurrentlyAssignedDomains(
                        customerDomains.reduce((acc, curr) => {
                            acc[curr.domainId] = { domainName: curr.domainName };
                            return acc;
                        }, {}) || {}
                    );
                    break;
                }
            }
            let mappings = {};
            domainsData.forEach((group) => {
                for (let { domainId, customerId } of group) {
                    if (!customerId) break;
                    mappings[domainId] = customerId;
                }
            });
            setDomainToCustomerMappings(mappings);
        } catch {
            noop();
        }

        try {
            let orders = ordersInfo?.posts_info || [];
            let networks = (ordersInfo?.marketplaceNetworks || []).map((network) => {
                network.name = decodeHtml(network.name);
                return network;
            });

            let domainToNetworkMappings = ordersInfo?.domainToNetworkMappings;
            let networkMappings = {};

            function getNetworkMappingInfo(networkString) {
                try {
                    let result = networkString.slice(networkString.startsWith("/") ? 1 : 0).split("/");
                    let networkCode = first(result);
                    let targetAdUnit = result.pop();
                    return { networkCode, targetAdUnit };
                } catch {
                    return { networkCode: "", targetAdUnit: "" };
                }
            }

            for (let domains of Object.values(customerToDomainMappings)) {
                domains.forEach(({ domainId, network, domainName, overview, marketplaceEnabled }) => {
                    try {
                        if (marketplaceEnabled) {
                            let domainOverview = overview ? JSON.parse(overview) : {};
                            networkMappings[domainId] = getNetworkMappingInfo(network);
                            networkMappings[domainId].domainName = domainName;
                            networkMappings[domainId].domainOverview = domainOverview;
                            networkMappings[domainId].marketplaceEnabled = marketplaceEnabled;
                            networkMappings[domainId].selectedNetworks = domainOverview?.selected_networks?.map((n) => n.code || n.term_id || n.term_taxonomy) || [];
                            networkMappings[domainId].sizesSet = new Set(domainOverview.display_sizes || [])
                        }
                    } catch (err) {
                        console.log(err)
                        noop();
                    }
                });
            }

            setDomainToNetworkMappings(networkMappings);
            dispatch(setReducerMarketplaceNetworks(networks, currentCustomer));

            if (Array.isArray(networks)) {
                let allDomains = [];
                let domainIds = new Set();
                let outOfNetworkCode = undefined;
                let outOfNetworkDomains = [];

                domainToNetworkMappings = networks.reduce((acc, { name, code }) => {
                    name = name.toLowerCase();
                    if (name.includes("run-of-network")) {
                        outOfNetworkCode = code;
                    }
                    acc[code] = domainToNetworkMappings[code];
                    networkNames[code] = name;
                    allDomains = allDomains.concat(domainToNetworkMappings[code]);
                    return acc;
                }, {});

                allDomains.forEach((domainInfo) => {
                    let { code } = domainInfo;
                    if (!domainIds.has(code)) {
                        domainIds.add(code);
                        outOfNetworkDomains.push(domainInfo);
                    }
                });

                DEFAULT_MARKETPLACE_ORDER.network = outOfNetworkCode;

                domainToNetworkMappings[outOfNetworkCode] = outOfNetworkDomains;
                dispatch(setReducerMarketplaceNetworkNames(networkNames, currentCustomer));
                dispatch(setReducerMarketplaceNetworkOptions(domainToNetworkMappings, currentCustomer));
            }

            orders = orders
                .map((o) => {
                    let jsonConfig = JSON.parse(o?.json_configuration || null);
                    let draftConfig = JSON.parse(o?.draft_json_configuration || null);
                    let config = null;
                    if (draftConfig) {
                        draftConfig.wpMarketplacePostId = draftConfig.wpMarketplacePostId || o.wp_marketplace_post_id;
                        draftConfig.pristine = true;
                        config = draftConfig;
                    } else if (jsonConfig) {
                        jsonConfig.wpMarketplacePostId = jsonConfig.wpMarketplacePostId || o.wp_marketplace_post_id;
                        jsonConfig.pristine = true;
                        config = jsonConfig;
                    }
                    return config;
                })
                .filter((o) => o);

            dispatch(registerMarketplaceOrders(orders, currentCustomer));
            setMutationStatus(true);

            let lineItemStats = await requestLineItemsStats(orders.filter((order) => order.lineItemId).map((order) => order.lineItemId));
            setMarketplaceOrderStats(lineItemStats);
            setShowLoader(false);
        } catch (err) {
            console.log(err);
        }
    }

    async function getSellerMarketplaceOrders(currentCustomer, offset = 0) {
        let response = await sellerOrdersMutation({ variables: { id: String(currentCustomer), offset } });
        let orders = JSON.parse(response.data.getSellerMarketplaceOrdersMutation.sellerOrders);
        let totalOrders = response.data.getSellerMarketplaceOrdersMutation.total_orders;
        setTotalSellerOrders(totalOrders);
        let custId = parseInt(currentCustomer);
        orders = (orders || []).map((order) => {
            let targetDomains = Object.keys(order.domainToLineItemsMappings || {}).filter((domainId) => domainToCustomerMappings[domainId] === custId);
            let [firstDomain] = targetDomains || [];
            order.networkCode = firstDomain ? domainToNetworkMappings[firstDomain]?.networkCode : "";
            let targetLineItems = (targetDomains || []).map((domainId) => order.domainToLineItemsMappings?.[domainId]);
            order.targetLineItems = targetLineItems;
            return order;
        });
        return orders;
    }

    useEffect(() => {
        if (currentCustomer && customerName) {
            (async () => {
                await getMarketplaceOrders(currentCustomer, customerName);
            })();
        }
    }, [currentCustomer, customerName]);

    useEffect(() => {
        if (currentCustomer && !isEmpty(domainToNetworkMappings) && !isEmpty(domainToCustomerMappings)) {
            (async () => {
                let orders = await getSellerMarketplaceOrders(currentCustomer);
                setOrdersToReview(orders);
            })();
        }
    }, [currentCustomer, domainToNetworkMappings, domainToCustomerMappings]);

    const updateCreativeUrlHandler = (orderIdx, creativeIdx, creativeURL) => {
        let orderToChange = { ...marketplaceOrders[orderIdx] };
        let creativeData = { ...orderToChange.creatives[creativeIdx] };
        creativeData.clickThroughUrl = creativeURL;
        orderToChange.creatives.splice(creativeIdx, 1, creativeData);
        dispatch(editMarketplaceOrder(orderToChange, orderIdx, currentCustomer));
    };

    function hasOrderStarted(marketplaceOrder) {
        if (marketplaceOrder.lineItemId) {
            if (marketplaceOrder.startsImmediately) {
                return true;
            } else {
                let d = new Date(marketplaceOrder.startDate);
                let now = new Date();
                return d.getDate() < now.getDate() && d.getMonth() <= now.getMonth();
            }
        }
        return false;
    }

    const changeHandler = (ev, idx, size, type, clickThroughUrl, fileName, fileSize) => {
        let name = ev?.target?.name;
        let orderToChange = { ...marketplaceOrders[idx] };
        orderToChange.pristine = !orderToChange.lineItemId;
        let newDate = null;
        let orderStartDate = null;
        switch (name) {
            case "creatives":
                let creatives = [...orderToChange.creatives, { creative: ev.target.value, size, type, clickThroughUrl, fileName, fileSize }];
                orderToChange.creatives = creatives;
                orderToChange.draftUpdated = true;
                break;
            case "network":
                orderToChange.network = ev.target.value;
                orderToChange.networks = [];
                break;
            case "startDate":
                newDate = new Date(ev.target.value);
                if (newDate.getMonth() > NOW.getMonth()) {
                    orderToChange[name] = ev.target.value;
                    orderToChange.draftUpdated = true;
                    if (orderToChange.lineItemId) {
                        orderToChange.updateStartDate = true;
                    }
                } else if (newDate.getMonth() === NOW.getMonth()) {
                    if (newDate.getDate() >= NOW.getDate()) {
                        orderToChange[name] = ev.target.value;
                        orderToChange.draftUpdated = true;
                        if (orderToChange.lineItemId) {
                            orderToChange.updateStartDate = true;
                        }
                    } else {
                        dispatch(pushMessage("Marketplace", "The order's start date must be set in the future", "error"));
                        return;
                    }
                } else {
                    dispatch(pushMessage("Marketplace", "The order's start date must be set in the future", "error"));
                    return;
                }
                break;
            case "endDate":
                newDate = new Date(ev.target.value);
                orderStartDate = new Date(orderToChange.startDate);
                if (newDate.getMonth() > orderStartDate.getMonth()) {
                    orderToChange[name] = ev.target.value;
                    orderToChange.draftUpdated = true;
                    if (orderToChange.lineItemId) {
                        orderToChange.updateEndDate = true;
                    }
                } else if (newDate.getMonth() === orderStartDate.getMonth()) {
                    if (newDate.getDate() > orderStartDate.getDate()) {
                        orderToChange[name] = ev.target.value;
                        orderToChange.draftUpdated = true;
                        if (orderToChange.lineItemId) {
                            orderToChange.updateEndDate = true;
                        }
                    } else {
                        dispatch(pushMessage("Marketplace", "The order's end date must be in the future", "error"));
                    }
                } else {
                    dispatch(pushMessage("Marketplace", "The order's end date must be in the future", "error"));
                    return;
                }
                break;
            case "impressionGoals":
            case "CPM":
            case "pav":
                orderToChange[name] = ev.target.value;
                orderToChange.draftUpdated = true;
                break;
            default:
                orderToChange[name] = ev.target.value;
                orderToChange.draftUpdated = true;
                break;
        }

        dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
    };

    const changeOrderActiveTab = (orderIdx, activeTabIdx) => {
        let orderToChange = { ...marketplaceOrders[orderIdx] };
        orderToChange.activeTab = activeTabIdx;
        dispatch(editMarketplaceOrder(orderToChange, orderIdx, currentCustomer));
    };

    const handleDomainSelection = ({ orderIndex, isAdditionOperation, domainId }) => {
        let orderToChange = { ...marketplaceOrders[orderIndex] };
        orderToChange.pristine = !orderToChange.lineItemId;
        orderToChange.draftUpdated = true;
        orderToChange.notifyCustomers = true;

        if (isAdditionOperation) {
            orderToChange.networks.push(domainId);
        } else {
            orderToChange.networks = orderToChange.networks.filter((domain) => domain !== domainId);
        }
        dispatch(editMarketplaceOrder(orderToChange, orderIndex, currentCustomer));
    };

    const handlePaymentMethodSelect = (ev, orderIdx) => {
        let copy = { ...marketplaceOrders[orderIdx] };
        copy.paymentMethod = ev.value;
        copy.pristine = !copy.lineItemId;
        dispatch(editMarketplaceOrder(copy, orderIdx, currentCustomer));
    };

    const deleteHandler = (ev, orderIdx, assetIdx) => {
        let copy = { ...marketplaceOrders[orderIdx] };
        copy.toDelete = copy.toDelete || [];
        copy.creatives = copy.creatives.filter((imageCreative, idx) => {
            if (idx !== assetIdx) {
                return true;
            } else {
                imageCreative.creativeId ? copy.toDelete.push({ ...imageCreative, toDelete: true }) : noop();
                return false;
            }
        });
        copy.pristine = !copy.lineItemId;
        dispatch(editMarketplaceOrder(copy, orderIdx, currentCustomer));
    };

    const areCreativesDefined = (order, i) => {
        let creatives = order.creatives;
        let thirdPartyCreatives = order.thirdPartytagCreatives;
        if (creatives.length || (thirdPartyCreatives.length && thirdPartyCreatives.every((item) => item.code))) {
            setRowValidationErrorIndex(null);
            return true;
        } else {
            setRowValidationErrorIndex(i);
            return false;
        }
    };

    const allThirdPartyCreativesHaveSizes = (order, i) => {
        let thirdPartyCreatives = order.thirdPartytagCreatives;
        if (thirdPartyCreatives.every((creative) => creative.size)) {
            setRowValidationErrorIndex(null);
            return true;
        } else {
            setRowValidationErrorIndex(i);
            return false;
        }
    };

    const allImageCreativesHaveDestinationUrls = (order, i) => {
        let imageCreatives = order.creatives;
        if (imageCreatives.every((creative) => creative.clickThroughUrl)) {
            setRowValidationErrorIndex(null);
            return true;
        } else {
            setRowValidationErrorIndex(i);
            return false;
        }
    };

    const orderHasAssociatedNetworks = (order) => {
        return order?.networks?.length;
    };

    const alterThirdPartyCreativesRow = (orderIndex, creativeIndex, operation, value) => {
        let copy = { ...marketplaceOrders[orderIndex] };
        copy.pristine = !copy.lineItemId;
        if (operation === "remove") {
            copy.toDelete = copy.toDelete || [];
            copy.thirdPartytagCreatives = copy.thirdPartytagCreatives.filter((thirdPartyCreative, idx) => {
                if (idx !== creativeIndex) {
                    return true;
                } else {
                    thirdPartyCreative.creativeId ? copy.toDelete.push({ ...thirdPartyCreative, toDelete: true }) : noop();
                    return false;
                }
            });
            dispatch(editMarketplaceOrder(copy, orderIndex, currentCustomer));
        } else {
            copy.thirdPartytagCreatives =
                operation === "add"
                    ? [...copy.thirdPartytagCreatives, value]
                    : copy.thirdPartytagCreatives.map((thirdPartyCreative, idx) => {
                        if (creativeIndex !== idx) {
                            return thirdPartyCreative;
                        } else {
                            return { ...thirdPartyCreative, ...value, toUpdate: thirdPartyCreative.creativeId ? true : false };
                        }
                    });
            dispatch(editMarketplaceOrder(copy, orderIndex, currentCustomer));
        }
    };

    function isOrderNameDefined(order, i) {
        let orderName = order?.name?.trim() || "";
        if (!!orderName) {
            setRowValidationErrorIndex(null);
            return true;
        } else {
            setRowValidationErrorIndex(i);
            return false;
        }
    }

    function isVastTagDefined(order, i) {
        let vastTag = order.vast;
        if (!!vastTag) {
            setRowValidationErrorIndex(null);
            return true;
        }
        setRowValidationErrorIndex(i);
        return false;
    }

    function isDurationDefined(order, i) {
        let duration = order.vast;
        if (!!duration) {
            setRowValidationErrorIndex(null);
            return true;
        }
        setRowValidationErrorIndex(i);
        return false;
    }

    const addNewThirdPartyTagRow = (orderIndex) => alterThirdPartyCreativesRow(orderIndex, undefined, "add", { code: "", size: "" });
    const changeThirdPartyTagValue = (orderIndex, creativeIndex, value) => alterThirdPartyCreativesRow(orderIndex, creativeIndex, "change", value);
    const removeThirdPartyTagRow = (orderIndex, creativeIndex) => alterThirdPartyCreativesRow(orderIndex, creativeIndex, "remove");

    function splitLineItemsByNetworks(marketplaceOrder) {
        let mappings = Object.entries(marketplaceOrder.networkAssociations ?? {}).reduce((acc, [networkCode, { lineItems }]) => {
            let arr = [];
            lineItems.forEach(([lineItemId, domainId]) => {
                let m = {
                    networkCode: domainToNetworkMappings[domainId].networkCode,
                    targetAdUnit: domainToNetworkMappings[domainId].targetAdUnit,
                    lineItemId,
                };
                arr.push(m);
            });
            acc[networkCode] = arr;
            return acc;
        }, {});
        return mappings;
    }

    function deleteTemporaryProperties(marketplaceOrder) {
        const temporaryProperties = [
            "operation",
            "toDelete",
            "creativeIdsAssocs",
            "toAssocFileCreatives",
            "toAssocThirdPartyCreatives",
            "gamNetworksAssocs",
            "orderIndex",
            "creating",
            "pausing",
            "resuming",
            "updateStartDate",
            "inProgress",
            "updateEndDate",
            "domainMappings",
            "lineItemMappings",
            "networkToLineItemMappings",
            "activeTab",
        ];
        temporaryProperties.forEach((tempProp) => {
            delete marketplaceOrder[tempProp];
        });
        return marketplaceOrder;
    }

    async function saveMarketplaceOrder(order, orderIdx, saveAsDraft = false) {
        if (!saveAsDraft) {
            if (!isOrderNameDefined(order, orderIdx)) {
                dispatch(pushMessage("Marketplace", "A marketplace order is missing its name", "error"));
                throw new Error("Marketplace validation error");
            } else if (!orderHasAssociatedNetworks(order)) {
                dispatch(pushMessage("Marketplace", "Each marketplace order needs to target at least one network ad unit", "error"));
                throw new Error("Marketplace validation error");
            }

            if (order.format === "video") {
                if (!isVastTagDefined(order, orderIdx)) {
                    dispatch(pushMessage("Marketplace", "Vast tag needs to be specified", "error"));
                    throw new Error("Marketplace validation error");
                } else if (!isDurationDefined(order, orderIdx)) {
                    dispatch(pushMessage("Marketplace", "Approximate video duration needs to be defined", "error"));
                    throw new Error("Marketplace validation error");
                }
            } else {
                if (!areCreativesDefined(order, orderIdx)) {
                    dispatch(pushMessage("Marketplace", "A marketplace order is missing creatives", "error"));
                    throw new Error("Marketplace validation error");
                } else if (!allThirdPartyCreativesHaveSizes(order, orderIdx)) {
                    dispatch(pushMessage("Marketplace", "Specify the size of the third-party tag creative", "error"));
                    throw new Error("Marketplace validation error");
                } else if (!allImageCreativesHaveDestinationUrls(order, orderIdx)) {
                    dispatch(pushMessage("Marketplace", "An image creative is missing its destination URL", "error"));
                    throw new Error("Marketplace validation error");
                }
            }

            setActiveTabs([]);
        }

        let gamMarketplaceMutationVariables = { id: String(currentCustomer), customerName: process.env.REACT_APP_IS_DEV ? "dev_" + customerName.toLowerCase() : customerName.toLowerCase() };
        let wpMarketplaceMutationVariables = { ...gamMarketplaceMutationVariables, isDraft: false };

        function processOrderInfo(order) {
            let { creatives, thirdPartytagCreatives, toDelete, networks = [] } = order;
            let allSizes = new Set();
            let toDissoc = new Set();
            order.creativeIdsAssocs = [];
            order.creativeNetworkIdsAssocs = {};
            order.originalCustomer = customerName.toLocaleLowerCase();

            [...creatives, ...thirdPartytagCreatives, ...(toDelete || [])].forEach((creative, i) => {
                let { toDelete, size, creativeId } = creative;
                if (!toDelete && !creativeId) {
                    allSizes.add(size);
                } else if (!toDelete) {
                    allSizes.add(size);
                    order.creativeIdsAssocs.push(creativeId);
                } else {
                    toDissoc.add(size);
                }
            });

            order.networkStatuses = Array.isArray(order.networkStatuses) || !order.networkStatuses ? {} : order.networkStatuses
            order.networkToLineItemMappings = Array.isArray(order.networkToLineItemMappings) || !order.networkToLineItemMappings ? {} : order.networkToLineItemMappings
            order.lineitemIdsToDomainNameMappings = {};
            order.domainToAdUnitMappings = Array.isArray(order.domainToAdUnitMappings) || !order.domainToAdUnitMappings ? {} : order.domainToAdUnitMappings
            let targetedCustomersIds = new Set();

            networks.forEach((domainId) => {
                try {
                    let networkCode = domainToNetworkMappings?.[domainId]?.networkCode;
                    let targetAdUnit = domainToNetworkMappings?.[domainId]?.targetAdUnit;
                    let domainName = domainToNetworkMappings?.[domainId]?.domainName;

                    order.domainToAdUnitMappings[domainId] = targetAdUnit;
                    order.domainIdToDomainNameMappings = Array.isArray(order.domainIdToDomainNameMappings) || !order.domainIdToDomainNameMappings ? {} : order.domainIdToDomainNameMappings
                    order.domainIdToDomainNameMappings[domainId] = domainName;

                    targetedCustomersIds.add(parseInt(typeof domainToCustomerMappings[domainId] === "object" ? domainToCustomerMappings[domainId].ID : domainToCustomerMappings[domainId]));

                    if (networkCode && order.domainToLineItemsMappings[domainId]) {
                        let lineItemId = order.domainToLineItemsMappings[domainId];
                        order.lineitemIdsToDomainNameMappings[lineItemId] = domainName;
                        order.networkToLineItemMappings[networkCode] = order.networkToLineItemMappings[networkCode] ? order.networkToLineItemMappings[networkCode].concat(lineItemId) : [lineItemId];
                    }

                    if (!order.lineItemId || !(domainId in order.networkStatuses)) {
                        order.networkStatuses[domainId] = { status: MARKETPLACE_DOMAIN_STATUS.PENDING };
                    }
                } catch (err) {
                    console.log(err);
                }
            });

            for (let [domainId] of Object.entries(order.networkStatuses)) {
                if (!networks.includes(domainId)) {
                    delete order.networkStatuses[domainId];
                }
            }

            order.targetedCustomersIds = [...targetedCustomersIds];
            order.lineItemSizes = [...allSizes];
            order.inProgress = true;

            if (order.lineItemId) {
                order.gamNetworksAssocs = splitLineItemsByNetworks(order);
            }

            return order;
        }

        function cleanupUpdateState(order, orderIdx) {
            deleteTemporaryProperties(order);
            dispatch(decreaseUpdateCount(currentCustomer));
            dispatch(pushMessage("Marketplace", "There has been an error while trying to update this order", "error"));
            dispatch(refreshMarketplaceOrder(order, orderIdx, currentCustomer));
        }

        function cleanupCreationState(order, orderIdx) {
            order.pristine = true;
            deleteTemporaryProperties(order);
            dispatch(decreaseCreateCount(currentCustomer));
            dispatch(refreshMarketplaceOrder(order, orderIdx, currentCustomer));
        }

        let newDate = new Date(order.startDate);
        let startsImmediately = newDate.getMonth() <= NOW.getMonth() && newDate.getDate() <= NOW.getDate();
        let copy = { ...order };

        copy.startsImmediately = startsImmediately;
        copy = processOrderInfo(copy);

        dispatch(refreshMarketplaceOrder(copy, orderIdx, currentCustomer));

        if (saveAsDraft) {
            if (copy.draftUpdated && copy.lineItemId) {
                copy.draftUpdated = false;
                copy.publishPending = true;
            }
            deleteTemporaryProperties(copy);
            let draft = await wpMarketplaceOrderMutation({
                variables: {
                    ...wpMarketplaceMutationVariables,
                    isDraft: true,
                    marketplaceOrder: JSON.stringify(copy),
                    paymentMethod: copy.paymentMethod || "no payment method",
                },
            });
            dispatch(pushMessage("Marketplace", "Draft saved", "success"));
            let draftOrderData = JSON.parse(draft.data.saveWpMarketplaceOrderMutation.response);
            dispatch(refreshMarketplaceOrder(draftOrderData, orderIdx, currentCustomer));
            return;
        }

        copy.notifyCustomers = false;

        if (copy.lineItemId) {
            try {
                dispatch(increaseUpdateCount(currentCustomer));

                let format = copy.format;
                let isVideo = format === "video";
                copy.draftUpdated = false;
                copy.publishPending = false;
                copy.thirdPartytagCreatives = copy.thirdPartytagCreatives.map(creative => {
                    creative.code = Base64.atob(creative.code)
                    creative.encoded = true
                    return creative
                })
                let prop = isVideo ? "updateMarketplaceVideoOrderMutation" : "updateMarketplaceOrderMutation";
                let mutationVariables = { variables: { marketplaceOrder: JSON.stringify(copy) } };

                let response = isVideo
                    ? await updateMarketplaceVideoOrderMutation(mutationVariables)
                    : await updateMarketplaceorderMutation(mutationVariables);

                let parsedResponse = JSON.parse(response.data[prop].gamApiResponse);
                let errorMessage = parsedResponse.errorMessage;
                let parsedOrder = parsedResponse.order || {};
                parsedOrder.pristine = true;

                deleteTemporaryProperties(parsedOrder);

                if (errorMessage) {
                    cleanupUpdateState(order, orderIdx);
                    return [false, copy];
                }
                parsedOrder.thirdPartytagCreatives = parsedOrder.thirdPartytagCreatives.map(creative => {
                    // creative.code = encode(creative.code)
                    creative.code = Base64.btoa(creative.code)
                    creative.encoded = true
                    return creative
                })
                dispatch(refreshMarketplaceOrder(parsedOrder, orderIdx, currentCustomer));
                parsedOrder.paymentMethod = parsedOrder.paymentMethod || defaultPaymentMethod;
                parsedOrder.thirdPartytagCreatives = parsedOrder
                    .thirdPartytagCreatives
                    .filter((creative) => creative.creativeId);
                let wpmutationResponse = await wpMarketplaceOrderMutation({
                    variables: {
                        ...wpMarketplaceMutationVariables,
                        marketplaceOrder: JSON.stringify(parsedOrder),
                        paymentMethod: parsedOrder.paymentMethod || defaultPaymentMethod,
                    },
                });
                response = JSON.parse(wpmutationResponse?.data?.saveWpMarketplaceOrderMutation?.response || null);

                dispatch(decreaseUpdateCount(currentCustomer));

                if (response?.lineItemId) {
                    dispatch(pushMessage("Marketplace", "Order has been saved. Line item id: " + response.lineItemId));
                }

                let updatePayload = generateSspPayload(geoTargetsInfo, copy, customerName, marketplaceOrderStats)
                
                await marketplaceOrderSspUpdate({variables: {
                    orderId: String(copy.wpMarketplacePostId),
                    data: JSON.stringify(updatePayload)
                }})

                return [true, response];
            } catch (err) {
                cleanupUpdateState(order, orderIdx);
                dispatch(pushMessage("Marketplace", err.message, "error"));
                return [false, order];
            }
        } else {
            dispatch(increaseCreateCount(currentCustomer));
            try {
                copy.draftUpdated = false;
                copy.publishPending = false;
                copy.thirdPartytagCreatives = copy.thirdPartytagCreatives.map(creative => {
                    creative.code = Base64.atob(creative.code)
                    creative.encoded = true
                    return creative
                })
                let mutationVariables = { variables: { ...gamMarketplaceMutationVariables, marketplaceOrder: JSON.stringify(copy) } };
                let responseOrder = copy.format === "video"
                    ? await marketplaceVideoOrderCreationMutation(mutationVariables)
                    : await marketplaceOrderCreationMutation(mutationVariables);
                let gamResponseOrder = JSON.parse(
                    responseOrder.data[copy.format === "video" ? "createMarketplaceVideoOrderMutation" : "createMarketplaceOrderMutation"].gamApiResponse
                );
                if (gamResponseOrder.error) {
                    cleanupCreationState(order, orderIdx);
                    dispatch(pushMessage("Marketplace", gamResponseOrder.errorMessage, "error"));
                    return;
                }
                gamResponseOrder.pristine = true;
                deleteTemporaryProperties(gamResponseOrder);
                dispatch(refreshMarketplaceOrder(gamResponseOrder, orderIdx, currentCustomer));
                gamResponseOrder.paymentMethod = gamResponseOrder.paymentMethod || defaultPaymentMethod;
                gamResponseOrder.thirdPartytagCreatives = gamResponseOrder.thirdPartytagCreatives.map(creative => {
                    creative.code = Base64.btoa(creative.code)
                    creative.encoded = true
                    return creative
                })
                let mutationResponse = await wpMarketplaceOrderMutation({
                    variables: {
                        ...wpMarketplaceMutationVariables,
                        marketplaceOrder: JSON.stringify(gamResponseOrder),
                        paymentMethod: gamResponseOrder.paymentMethod || "no payment method",
                    },
                });
                let response = JSON.parse(mutationResponse?.data?.saveWpMarketplaceOrderMutation?.response || {});
                dispatch(decreaseCreateCount(currentCustomer));
                dispatch(pushMessage("Marketplace", "Order has been created"));

                await marketplaceOrderSspRegistration({
                    variables: {
                        postId: String(response.wpMarketplacePostId),
                        geographies: JSON.stringify(generateSspPayload(geoTargetsInfo, copy, customerName, marketplaceOrderStats))
                    }
                })
                if (response.targetedCustomersIds?.includes(parseInt(currentCustomer))) {
                    setOrdersToReview([response, ...ordersToReview]);
                }
                return [true, response];
            } catch (err) {
                dispatch(refreshMarketplaceOrder(deleteTemporaryProperties(order), orderIdx, currentCustomer));
                dispatch(decreaseCreateCount(currentCustomer));
                dispatch(pushMessage("Marketplace error", err.message, "error"));
                return [false, order];
            }
        }
    }

    function handleSelection({ idx, operation, data, selectionModePropertyName, selectionPropertyName, selectionOptions }) {
        let orderToChange = { ...marketplaceOrders[idx] };
        orderToChange.pristine = !orderToChange.lineItemId;

        switch (operation) {
            case "select":
            case "remove":
                orderToChange[selectionModePropertyName] = operation === "select";
                orderToChange[selectionPropertyName] = orderToChange[selectionPropertyName] ?? [];
                if (orderToChange?.[selectionPropertyName]?.length === selectionOptions.length) return;
                if (orderToChange?.[selectionPropertyName]?.find((td) => td.name === data.name)) return;
                orderToChange[selectionPropertyName] = (orderToChange[selectionPropertyName] || []).concat(data);
                orderToChange.draftUpdated = true;
                dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
                break;
            case "select-all":
            case "remove-all":
                orderToChange[selectionModePropertyName] = operation === "select-all";
                orderToChange[selectionPropertyName] = orderToChange[selectionPropertyName] ?? [];
                if (orderToChange?.[selectionPropertyName]?.length === selectionOptions.length) return;
                orderToChange[selectionPropertyName] = [...selectionOptions];
                orderToChange.draftUpdated = true;
                dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
                break;
            case "clear-all":
                orderToChange[selectionModePropertyName] = true;
                orderToChange[selectionPropertyName] = [];
                orderToChange.draftUpdated = true;
                dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
                break;
            case "clear":
                orderToChange[selectionPropertyName] = (orderToChange[selectionPropertyName] || []).filter((td) => td.name !== data.name);
                orderToChange.draftUpdated = true;
                dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
                break;
            default:
                noop();
        }
    }

    function handleDeviceSelection(order, idx, operation, data) {
        handleSelection({
            order,
            idx,
            operation,
            data,
            selectionModePropertyName: "selectDeviceMode",
            selectionPropertyName: "targetDevices",
            selectionOptions: DEVICE_TARGETING_OPTIONS,
        });
    }

    function handleGeoTargetSelection(order, idx, operation, data) {
        let selectionPropertyName = '';

        if (operation === 'remove') {
            selectionPropertyName = 'excludedGeographies'
        } else if (operation === 'select') {
            selectionPropertyName = 'includedGeographies'
        } else if (isEmpty(order.includedGeographies)) {
            selectionPropertyName = 'excludedGeographies'
        } else {
            selectionPropertyName = 'includedGeographies'
        }

        handleSelection({
            idx,
            operation,
            data,
            selectionModePropertyName: "selectCountryMode",
            selectionPropertyName,
            selectionOptions: geoTargetsInfo,
        });
    }

    function displayNetworkSelectionDialog(orderIndex, networkName, networkId) {
        setDomainSelectionOverlayState({ isVisible: true, domainsData: networkOptions?.[networkId] || [], networkMappings: domainToNetworkMappings, marketplaceNetworks: marketplaceNetworks || [], orderIndex, networkName, networkId });
    }

    async function operateOnLineItem({ action, networks }) {
        return await marketplaceOrderActionMutation({
            variables: {
                networks,
                action,
            },
        });
    }

    async function setDomainReviewStatus(domainId, domainStatus, marketplaceWpOrderId) {
        return await updateMarketplaceReviewStatusMutation({
            variables: {
                domainIds: JSON.stringify([domainId]),
                domainStatus,
                marketplace_order_id: marketplaceWpOrderId,
            },
        });
    }

    async function modifyDomainStatus({ lineItemId, networkCode, marketplaceWpOrderId, action, domainId, domainStatus }) {
        try {
            await operateOnLineItem({ action, networks: JSON.stringify({ [networkCode]: [lineItemId] }) });
            await setDomainReviewStatus(String(domainId), domainStatus, String(marketplaceWpOrderId));
        } catch (err) {
            console.log({ err });
        }
    }

    /**
     * @typedef {{clicksDelivered: number, impressionsDelivered: number, status: string, viewableImpressionsDelivered: number}} DeliveredMetrics
     */

    /**
     * 
     * @param {Object<string,DeliveredMetrics>} stats 
     */

    function updateOrderStats(stats) {
        setMarketplaceOrderStats({ ...marketplaceOrderStats, ...stats });
    }

    async function domainStatusTableHandler({ domainId, order, action, newDomainStatus }) {
        try {
            await modifyDomainStatus({
                lineItemId: order.domainToLineItemsMappings[domainId],
                networkCode: domainToNetworkMappings[domainId]?.networkCode,
                marketplaceWpOrderId: order.wpMarketplacePostId,
                action,
                domainId,
                domainStatus: newDomainStatus,
            });
            dispatch(updateMarketplaceOrderDomainStatus(order, domainId, newDomainStatus, currentCustomer));
        } catch (err) {
            dispatch(pushMessage("Marketplace", "There has been an error while operating on this domain. Try again later", "error"));
        }
    }

    function switchActiveTabHandler(order, orderIdx, currIdx) {
        try {
            let activeTabIndex = order.activeTab || 0;
            if (currIdx === activeTabIndex) return;
            if (activeTabIndex === 0) {
                changeOrderActiveTab(orderIdx, currIdx || activeTabIndex + 1);
            } else if (activeTabIndex === 1) {
                if (currIdx === 0) return changeOrderActiveTab(orderIdx, 0);
                changeOrderActiveTab(orderIdx, activeTabIndex + 1);
            } else if (activeTabIndex === 2) {
                changeOrderActiveTab(orderIdx, currIdx);
            } else if (activeTabIndex === 3) {
                changeOrderActiveTab(orderIdx, currIdx);
            }
        } catch (err) {
            console.log("err: ", err);
        }
    }

    const dismissDeleteDialog = () => {
        setShowDeleteDialog(false);
        setIdxToDelete(-1);
    };

    function toggleSwitchSection(ev, idx) {
        let { target: { name } } = ev;
        let orderToChange = { ...marketplaceOrders[idx] };

        if (name === 'enableDailyCap') {
            orderToChange[name] = !orderToChange[name]
        } else if (name === "pav") {
            orderToChange.pav = !orderToChange.pav;
            orderToChange.gmav = false;
        } else {
            orderToChange.gmav = !orderToChange.gmav;
            orderToChange.pav = false;
        }
        dispatch(editMarketplaceOrder(orderToChange, idx, currentCustomer));
    }

    function processCampaignStats(dataStats) {
        if (Array.isArray(dataStats)) {
            return { impressions: 0, revenue: 0 }
        } else {
            let stats = { impressions: 0, revenue: 0 };
            for (let [_, data] of Object.entries(dataStats)) {
                stats.impressions += data.impressions
                stats.revenue += parseFloat(data.revenue.split(',').join(''))
            }
            return stats
        }
    }

    async function getCampaignStatsBreakdown(lineItemId) {
        let breakdown = [];
        try {
            let breakdownStats = await breakdownMutation({
                variables: {
                    lineItemId: String(lineItemId)
                }
            })
            let data = JSON.parse(breakdownStats.data.getBreakdownDataMutation.breakdownData)

            for (let [date, dataStats] of Object.entries(data[lineItemId])) {
                let [dd, mm, yyyy] = date.split('-').map(i => parseInt(i))
                let formattedDate = mm + '/' + dd + '/' + yyyy
                breakdown.push({
                    formattedDate,
                    timeStamp: Date.parse(formattedDate),
                    stats: processCampaignStats(dataStats)
                })
            }
            breakdown = breakdown.sort((da, db) => {
                return db.timeStamp - da.timeStamp
            })
            return breakdown
        } catch {
            return breakdown
        }
    }



    function acceptOrderSetup(orderData) {
        try {
            setOrderSetupOverlaySettings({
                isSettingsOverlayVisible: true,
                isAddOperation: true,
                isDismissOperation: false,
                orderIdx: orderSetupOverlaySettings.orderIdx
            })
            dispatch(refreshMarketplaceOrder(
                { ...marketplaceOrders[orderSetupOverlaySettings.orderIdx], ...orderData },
                orderSetupOverlaySettings.orderIdx,
                currentCustomer
            ))
            setActiveTabs([0])
        } catch (err) {
            console.log(err)
        }
    }

    function hideOverlay() {
        setOrderSetupOverlaySettings({
            isSettingsOverlayVisible: false,
            isAddOperation: false,
            isDismissOperation: true,
            orderIdx: orderSetupOverlaySettings.orderIdx
        })
    }

    function dismissOverlay() {
        setOrderSetupOverlaySettings({
            isSettingsOverlayVisible: false,
            isDismissOperation: true,
            isAddOperation: false,
            orderIdx: orderSetupOverlaySettings.orderIdx
        })
    }

    function selectSizesHandler(orderIdx) {
        setOrderSetupOverlaySettings({
            isAddOperation: false,
            isDismissOperation: false,
            isSettingsOverlayVisible: true,
            orderIdx
        })
    }

    return (
        <LayoutWrapper model="marketplace" renderOpsBar={false}>
            <PaymentRegistrationBanner
                isVisible={displayPaymentMissingSection}
                onHide={() => setDisplayPaymentSection(false)}
                headerMessage={
                    <div css={paymentRegistrationBannerHeaderStyles}>
                        <div>
                            <i className="pi pi-exclamation-triangle"></i>
                        </div>
                        <p>Register a payment method</p>
                    </div>
                }
            >
                <div css={paymentRegistrationBannerBodyStyles}>
                    <p>
                        In order to publish a Marketplace order you first need to register a payment method under <Link to="/billing">our Billing page</Link>. After registering a payment method you will be able to publish your Marketplace order(s).
                    </p>
                    <p>You can still save your Marketplace Orders as drafts to revisit them at a later time.</p>
                </div>
            </PaymentRegistrationBanner>
            <MarketplaceOrderDeletionBanner
                currentCustomer={currentCustomer}
                dismissDeleteDialog={dismissDeleteDialog}
                idxToDelete={idxToDelete}
                marketplaceOrders={marketplaceOrders}
                showDeleteDialog={showDeleteDialog}
            />
            <CampaignReportDownloadButton
                filename={downloadFileName}
                href={downloadUrl}
                ref={downloadRef}
            />

            {
                orderSetupOverlaySettings.isSettingsOverlayVisible && (
                    <OrderSettingsOverlay
                        isVisible={orderSetupOverlaySettings.isSettingsOverlayVisible}
                        orderIdx={orderSetupOverlaySettings.orderIdx}
                        order={marketplaceOrders[orderSetupOverlaySettings.orderIdx]}
                        hideOverlay={hideOverlay}
                        dismissOrder={dismissOverlay}
                        acceptOrder={acceptOrderSetup}
                        render={props => {
                            let order = marketplaceOrders[orderSetupOverlaySettings.orderIdx]
                            let showChoices = !order || !order.format
                            if (order) {
                                if (order.wpMarketplacePostId || order.lineItemId) {
                                    showChoices = false
                                } else if (order.format === 'display') {
                                    showChoices = false
                                }
                                else if (order.format === 'video') {
                                    showChoices = false
                                } else {
                                    showChoices = true
                                }
                            }
                            return (
                                <FormatSection {...props} showChoices={showChoices} />
                            )
                        }}
                    />
                )
            }

            {
                censusTargetingOverlaySettings.isVisible && (
                    <CensusTargetingOverlay
                        isVisible={true}
                        currentCustomer={currentCustomer}
                        censusData={censusTargetingData}
                        audienceTargetingPricingData={audienceTargetingPricingData}
                        orderIndex={censusTargetingOverlaySettings.orderIdx}
                        marketplaceOrders={marketplaceOrders}
                        saveChanges={(targetingData) => {
                            dispatch(refreshMarketplaceOrder(
                                { ...marketplaceOrders[censusTargetingOverlaySettings.orderIdx], audienceTargeting: targetingData },
                                censusTargetingOverlaySettings.orderIdx,
                                currentCustomer
                            ))
                        }}
                        onHide={() => {
                            setCensusTargetingOverlaySettings({ isVisible: false, orderIdx: 0 })
                        }}
                    />
                )
            }

            <DomainSelectionOverlay
                onHide={() => {
                    setDomainSelectionOverlayState({ isVisible: false, domainsData: [] });
                }}
                isVisible={domainSelectionOverlayState.isVisible}
                domainsData={domainSelectionOverlayState.domainsData}
                marketplaceNetworks={domainSelectionOverlayState.marketplaceNetworks?.map(d => {
                    return { name: d.name, value: d.code }
                })}
                handleDomainSelection={handleDomainSelection}
                orderIndex={domainSelectionOverlayState.orderIndex}
                networkMappings={domainSelectionOverlayState.networkMappings}
                domainToCustomerMappings={domainToCustomerMappings}
                currentCustomer={parseInt(currentCustomer)}
                marketplaceOrders={marketplaceOrders}
            />

            <div>
                {mutationCompleted ? (
                    <div style={{ position: "relative" }}>
                        <MarketplaceBuyReviewSection
                            setOrderSetupOverlaySettings={setOrderSetupOverlaySettings}
                            setShowBuyingScreen={setShowBuyingScreen}
                            showBuyingScreen={showBuyingScreen}
                            isAgency={isAgency}
                            currentCustomer={currentCustomer}
                        />

                        {showBuyingScreen ? (
                            <MarketplaceGamDataContext.Provider value={{
                                geoTargetsInfo: geoTargetsInfo,
                                gamStats: marketplaceOrderStats,
                                individualStats
                            }}>
                                <NoticeSection
                                    visible={displayReviewSection}
                                    text="There are campaigns available to run on your network"
                                    ctaHandler={() => setShowBuyingScreen(false)}
                                />
                                <MarketplaceGlobalDataContext.Provider
                                    value={{
                                        domainToCustomerMappings: domainToCustomerMappings,
                                        marketplaceNetworks: marketplaceNetworks,
                                        domainToNetworkMappings: domainToNetworkMappings,
                                        allOrders: marketplaceOrders
                                    }}
                                >
                                    <Accordion
                                        multiple
                                        activeIndex={activeTabs}
                                        onTabChange={(t) => {
                                            setActiveTabs(t?.index || []);
                                        }}
                                        css={accordionStyles}
                                    >
                                        {marketplaceOrders.map((order, idx) => {
                                            let {
                                                name,
                                                network,
                                                lineItemId,
                                                creatives,
                                                CPM,
                                                thirdPartytagCreatives,
                                                startDate,
                                                endDate,
                                                toArchive,
                                                networks,
                                                inProgress,
                                                pausing,
                                                paymentMethod = "",
                                                activeTab = 0,
                                                wpMarketplacePostId,
                                                vast,
                                                duration,
                                                audienceTargeting,
                                                advertiserUrl
                                            } = order;

                                            audienceTargeting = audienceTargeting || []
                                            let orderHasStarted = hasOrderStarted(order);

                                            return (
                                                <AccordionTab
                                                    headerClassName={`${idx === rowValidationErrorIndex ? "invalid" : toArchive ? "to-archive" : ""} ${inProgress ? "in-progress" : ""} ${lineItemId ? "created" : "not-created"} ${pausing ? "pausing" : ""}`}
                                                    key={wpMarketplacePostId || idx}
                                                    disabled={inProgress}
                                                    header={
                                                        <MarketplaceAccordionHeader
                                                            order={order}
                                                            render={() => (
                                                                <MarketplaceRowControls
                                                                    order={order}
                                                                    idx={idx}
                                                                    currentCustomer={currentCustomer}
                                                                    deleteTemporaryProperties={deleteTemporaryProperties}
                                                                    deleteOrderHandler={() => {
                                                                        setShowDeleteDialog(true);
                                                                        setIdxToDelete(idx);
                                                                        setActiveTabs([]);
                                                                    }}
                                                                    updateOrderStats={updateOrderStats}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                >
                                                    <MarketplaceOrderDataContext.Provider value={{
                                                        currentOrder: order,
                                                        currentOrderIdx: idx,
                                                        allOrders: marketplaceOrders,
                                                        customerRate,
                                                        currentCustomer,
                                                    }}>
                                                        <TabView
                                                            activeIndex={activeTab}
                                                            css={css`
                                                                .management-tab {
                                                                    display: ${order.lineItemId ? "initial !important" : "none !important"};
                                                                }
                                                            `}
                                                            onTabChange={async (e) => {
                                                                if (order.lineItemId) {
                                                                    changeOrderActiveTab(idx, e.index);
                                                                    if (e.index === 3 && !(order.lineItemId in individualStats)) {
                                                                        let breakdown = await getCampaignStatsBreakdown(order.lineItemId)
                                                                        setIndividualStats({ ...individualStats, [order.lineItemId]: breakdown })
                                                                    }
                                                                } else {
                                                                    switchActiveTabHandler(order, idx, e.index);
                                                                }

                                                            }}
                                                        >
                                                            <TabPanel header="CAMPAIGN">
                                                                <div className="marketplace-creative" css={textInputStyle}>
                                                                    <div className="p-grid" css={gridControlStyles}>
                                                                        <div className="m-controls">
                                                                            <OrderRowInput
                                                                                changeHandler={changeHandler}
                                                                                idx={idx}
                                                                                value={order.name}
                                                                                label="Campaign Name"
                                                                                name="name"
                                                                                icon="pi pi-info-circle"
                                                                                description="Name of this Marketplace order. Use a descriptive name"
                                                                                placeholder="Enter the name of your marketplace order"
                                                                            />
                                                                            <OrderRowInput
                                                                                changeHandler={changeHandler}
                                                                                idx={idx}
                                                                                name="description"
                                                                                value={order.description}
                                                                                icon="pi pi-info-circle"
                                                                                label="Order Notes"
                                                                                placeholder="Order notes"
                                                                            />
                                                                            <OrderRowDate
                                                                                name="Campaign start date"
                                                                                icon="pi-calendar-plus"
                                                                                value={startDate}
                                                                                inputName="startDate"
                                                                                changeHandler={changeHandler}
                                                                                disabled={orderHasStarted}
                                                                                idx={idx}
                                                                            />
                                                                            <OrderRowDate
                                                                                name="Campaign end date"
                                                                                icon="pi-calendar-plus"
                                                                                value={endDate}
                                                                                inputName="endDate"
                                                                                idx={idx}
                                                                                changeHandler={changeHandler}
                                                                            />
                                                                            <OrderRowNumberInput
                                                                                changeHandler={changeHandler}
                                                                                idx={idx}
                                                                                value={CPM}
                                                                                name="CPM"
                                                                                label="Bid CPM"
                                                                                icon="pi pi-dollar"
                                                                                mode="currency"
                                                                            />

                                                                            {
                                                                                !order.enableDailyCap && (
                                                                                    <OrderRowNumberInput
                                                                                        changeHandler={changeHandler}
                                                                                        idx={idx}
                                                                                        value={order.impressionGoals}
                                                                                        name="impressionGoals"
                                                                                        label="Impression Goal"
                                                                                        icon="pi pi-eye"
                                                                                        max={100e6}
                                                                                    />
                                                                                )
                                                                            }

                                                                            <OrderRow
                                                                                idx={idx}
                                                                                label="Enable daily cap"
                                                                                name="enableDailyCap"
                                                                                description="Add a daily cap the domains participating in your campaign"
                                                                            >
                                                                                <InputSwitch
                                                                                    name="enableDailyCap"
                                                                                    checked={order.enableDailyCap}
                                                                                    onChange={(ev) => {
                                                                                        toggleSwitchSection(ev, idx)
                                                                                    }}
                                                                                />
                                                                            </OrderRow>

                                                                            {
                                                                                order.enableDailyCap && (
                                                                                    <OrderRowNumberInput
                                                                                        changeHandler={changeHandler}
                                                                                        idx={idx}
                                                                                        value={order.dailyCap}
                                                                                        name="dailyCap"
                                                                                        label="Daily impressions limit" icon="pi pi"
                                                                                    />
                                                                                )
                                                                            }

                                                                            <PaymentMethodOrderRowDropdown
                                                                                idx={idx}
                                                                                name="paymentMethod"
                                                                                value={paymentMethod || defaultPaymentMethod}
                                                                                icon="pi pi-shopping-cart"
                                                                                options={paymentMethods}
                                                                                placeholder="Select a payment method for this Marketplace order"
                                                                                dropdownChangeHandler={handlePaymentMethodSelect}
                                                                            />

                                                                            <OrderRow idx={idx} name="network" label="Networks">
                                                                                <div css={networkRowStyles}>
                                                                                    <Cta
                                                                                        ctaText="Select Domains"
                                                                                        onClick={() => {
                                                                                            displayNetworkSelectionDialog(idx, name, network);
                                                                                        }}
                                                                                        themeClasses="marketplace-cta outlined default"
                                                                                    />
                                                                                    <span className="selected">{networks.length} selected</span>
                                                                                </div>
                                                                            </OrderRow>

                                                                            <DeviceTargeting
                                                                                handleSelection={handleDeviceSelection}
                                                                                orderRowName="deviceTargeting"
                                                                                orderRowLabel="Device Targeting"
                                                                                orderRowDescription="Device Targeting"
                                                                            />

                                                                            <GeoTargeting
                                                                                handleSelection={handleGeoTargetSelection}
                                                                                orderRowName="geoTargeting"
                                                                                orderRowLabel="Geo Targeting"
                                                                                orderRowDescription="Geo Targeting"
                                                                            />

                                                                            <OrderRow idx={idx} name="census" label="Audience Targeting">
                                                                                <div css={audienceTargetingRowStyles}>
                                                                                    <Cta
                                                                                        disabled={
                                                                                            marketplaceOrderStats?.lineItemId?.impressionsDelivered ||
                                                                                            !censusTargetingData
                                                                                        }
                                                                                        ctaText="Choose Audience"
                                                                                        onClick={() => {
                                                                                            setCensusTargetingOverlaySettings({
                                                                                                ...censusTargetingOverlaySettings,
                                                                                                isVisible: true,
                                                                                                orderIdx: idx
                                                                                            })
                                                                                        }}
                                                                                        themeClasses="marketplace-cta outlined default"
                                                                                    />
                                                                                    <span className="selected">
                                                                                        {
                                                                                            audienceTargeting.length
                                                                                                ? `${audienceTargeting.length} demographic(s) targeted`
                                                                                                : 'No targeting selected'
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </OrderRow>

                                                                            <OrderRowInput idx={idx} label="Advertiser URL" name="advertiserUrl" changeHandler={changeHandler} icon="pi pi-external-link" value={advertiserUrl}></OrderRowInput>

                                                                            {/* <OrderRow label="IAB Category" name="iabCategories" description="IAB Categories">
                                                                            </OrderRow> */}

                                                                            <OrderRow
                                                                                idx={idx}
                                                                                label="IAB 70% viewability targeting"
                                                                                name="pav"
                                                                                description="IAB 70% viewability targeting"
                                                                            >
                                                                                <InputSwitch
                                                                                    name="pav"
                                                                                    checked={order.pav}
                                                                                    onChange={(ev) => toggleSwitchSection(ev, idx)}
                                                                                />
                                                                            </OrderRow>
                                                                            <OrderRow
                                                                                idx={idx}
                                                                                label="GroupM 100% Viewability"
                                                                                name="gmav"
                                                                                description="GroupM 100% Viewability"
                                                                            >
                                                                                <InputSwitch
                                                                                    name="gmav"
                                                                                    checked={order.gmav}
                                                                                    onChange={(ev) => toggleSwitchSection(ev, idx)}
                                                                                />
                                                                            </OrderRow>
                                                                            <SaveSection
                                                                                selectSizesHandler={() => selectSizesHandler(idx)}
                                                                                order={order}
                                                                                customerName={customerName}
                                                                                domainIdMappings={domainToNetworkMappings}
                                                                                saveMarketplaceOrderHandler={async (isDraftOrder) => {
                                                                                    if (isDraftOrder) {
                                                                                        return await saveMarketplaceOrder(order, idx, isDraftOrder);
                                                                                    }
                                                                                    if (!hasPaymentMethods) {
                                                                                        setDisplayPaymentSection(true);
                                                                                        return;
                                                                                    }
                                                                                    return await saveMarketplaceOrder(order, idx, isDraftOrder);
                                                                                }}
                                                                                setMarketplaceOrderStats={updateOrderStats}
                                                                                switchTabHandler={() => {
                                                                                    switchActiveTabHandler(order, idx);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="sidebar-info"
                                                                            css={css`
                                                                                margin-left: 1em;
                                                                                flex-grow: 1;
                                                                                background-color: #f8f9fa;
                                                                            `}
                                                                        >
                                                                            <MarketplaceSidebarInfo
                                                                                sidebarStyles={sidebarStyles}
                                                                                audienceTargeting={order.audienceTargeting}
                                                                                audienceTargetingRates={audienceTargetingPricingData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel header="CREATIVES">
                                                                <div>
                                                                    {order.format === "video" ? (
                                                                        <>
                                                                            <div className="p-grid" css={gridControlStyles}>
                                                                                <div className="m-controls">
                                                                                    <OrderRowInput
                                                                                        changeHandler={changeHandler}
                                                                                        idx={idx}
                                                                                        name="vast"
                                                                                        value={vast}
                                                                                        icon="pi pi-info-circle"
                                                                                        placeholder="Enter your vast tag url"
                                                                                    />
                                                                                    <OrderRowNumberInput
                                                                                        name="duration"
                                                                                        changeHandler={changeHandler}
                                                                                        idx={idx}
                                                                                        label="Approximate duration in seconds"
                                                                                        value={duration}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ThirdPartyCreativesRow
                                                                                idx={idx}
                                                                                name="thirdPartytagCreatives"
                                                                                label="Third Party Creative Type"
                                                                                icon="pi pi-google"
                                                                                creativesCollection={thirdPartytagCreatives}
                                                                                changeHandler={changeThirdPartyTagValue}
                                                                                addNewThirdPartyTagRow={addNewThirdPartyTagRow}
                                                                                removeThirdPartyTagRow={removeThirdPartyTagRow}
                                                                                allowedSizes={order.displaySizes}
                                                                            />
                                                                            <OrderRowUpload
                                                                                idx={idx}
                                                                                allowedSizes={new Set(order.displaySizes)}
                                                                                name="creatives"
                                                                                label="Image Creative Type"
                                                                                icon="pi pi-images"
                                                                                creatives={creatives}
                                                                                currentCustomer={currentCustomer}
                                                                                onUploadComplete={changeHandler}
                                                                                deleteHandler={deleteHandler}
                                                                                deleteMutation={deleteAssetMutation}
                                                                                updateCreativeUrlHandler={updateCreativeUrlHandler}
                                                                            />
                                                                        </>
                                                                    )}
                                                                    <SaveSection
                                                                        selectSizesHandler={() => selectSizesHandler(idx)}
                                                                        order={order}
                                                                        customerName={customerName}
                                                                        domainIdMappings={domainToNetworkMappings}
                                                                        saveMarketplaceOrderHandler={async (isDraftOrder) => {
                                                                            if (isDraftOrder) {
                                                                                return await saveMarketplaceOrder(order, idx, isDraftOrder);
                                                                            }
                                                                            if (!hasPaymentMethods) {
                                                                                setDisplayPaymentSection(true);
                                                                                return false;
                                                                            }
                                                                            return await saveMarketplaceOrder(order, idx, isDraftOrder);
                                                                        }}
                                                                        setMarketplaceOrderStats={updateOrderStats}
                                                                        switchTabHandler={() => {
                                                                            switchActiveTabHandler(order, idx);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel header="MANAGEMENT" headerClassName="management-tab" contentClassName="management-tab">
                                                                <div>
                                                                    <div css={statusTableStyles}>
                                                                        <div className="status-table">
                                                                            {lineItemId ? (
                                                                                <DomainStatusTable
                                                                                    domainList={networks}
                                                                                    domainToNetworkMappings={domainToNetworkMappings}
                                                                                    marketplaceOrder={order}
                                                                                    currentCustomer={currentCustomer}
                                                                                    orderIdx={idx}
                                                                                    isBuyer={true}
                                                                                    pauseDomainLineItem={async (domainId) => {
                                                                                        await domainStatusTableHandler({ domainId, order, action: LINEITEM_ACTIONS.PAUSE, newDomainStatus: MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_BUYER });
                                                                                    }}
                                                                                    resumeDomainLineItem={async (domainId) => {
                                                                                        await domainStatusTableHandler({ domainId, order, action: LINEITEM_ACTIONS.RESUME, newDomainStatus: MARKETPLACE_DOMAIN_STATUS.RESUMED });
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <div className="management-notice">
                                                                                    <h3>Please save your order and your managed domains will be listed here</h3>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="sidebar-info" css={sidebarInfoStyles}>
                                                                            <MarketplaceSidebarInfo sidebarStyles={managementSidebarStyles} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel
                                                                header="CAMPAIGN STATS (last 7 days)"
                                                                headerClassName="breakdown-tab"
                                                                contentClassName="breakdown-tab"
                                                            >
                                                                <CampaignStatsPanel
                                                                    setDownloadFileName={setDownloadFileName}
                                                                    setDownloadUrl={setDownloadUrl}
                                                                    ref={downloadRef}
                                                                />
                                                            </TabPanel>

                                                        </TabView>
                                                    </MarketplaceOrderDataContext.Provider>
                                                </AccordionTab>
                                            );
                                        })}
                                    </Accordion>
                                </MarketplaceGlobalDataContext.Provider>
                                {total ? (
                                    <Paginator
                                        css={css`
                                                button {
                                                    background-color: transparent !important;
                                                    &.p-paginator-page:nth-of-type(${buyerPaginationIndex + 1}) {
                                                        color: white !important;
                                                        background-color: #2196f3 !important;
                                                    }
                                                }
                                            `}
                                        rows={10}
                                        first={0}
                                        totalRecords={total}
                                        onPageChange={async (ev) => {
                                            setActiveTabs([]);
                                            setShowLoader(true);
                                            let page = String(ev.page);
                                            let response = await requestMarketplaceOrder({ currentCustomer, customerName, page });
                                            let mutationData = JSON.parse(response.ordersInfo);
                                            let orders = mutationData.posts_info.map((o) => {
                                                let jsonConfig = JSON.parse(o?.json_configuration);
                                                jsonConfig.wpMarketplacePostId = jsonConfig.wpMarketplacePostId || o.wp_marketplace_post_id;
                                                jsonConfig.pristine = true;
                                                return jsonConfig;
                                            });
                                            dispatch(registerMarketplaceOrders(orders, currentCustomer));
                                            setBuyerPaginationIndex(ev.page)
                                            let stats = await requestLineItemsStats(orders.filter((o) => o.lineItemId).map((o) => o.lineItemId));
                                            setMarketplaceOrderStats(stats);
                                            setShowLoader(false);
                                        }}
                                    />
                                ) : null}
                            </MarketplaceGamDataContext.Provider>
                        ) : (
                            <div
                                css={css`
                                    .p-accordion-tab {
                                        margin-bottom: 0.75em;
                                        border: 1px solid #ccc;
                                    }
                                `}
                            >
                                {ordersToReview.length ? (
                                    <Accordion>
                                        {ordersToReview.map((order, orderIdx) => {
                                            return (
                                                <AccordionTab
                                                    key={orderIdx}
                                                    header={<MarketplaceSellerAccordionTab
                                                        order={order}
                                                        currentCustomer={currentCustomer}
                                                        domainToCustomerMappings={domainToCustomerMappings}
                                                    />}
                                                >
                                                    <DomainReviewOverlay
                                                        key={orderIdx}
                                                        marketplaceOrder={order}
                                                        currentlyAssignedDomains={currentlyAssignedDomains}
                                                        customerId={currentCustomer}
                                                        customerName={customerName}
                                                        domainToNetworkMappings={domainToNetworkMappings}
                                                        submitHandler={(order) => {
                                                            setOrdersToReview(
                                                                ordersToReview.map((orderToReview, i) => {
                                                                    return i === orderIdx ? order : orderToReview;
                                                                })
                                                            );
                                                            dispatch(updateMarketplaceOrderByProp(order, "wpMarketplacePostId", order.wpMarketplacePostId, currentCustomer));
                                                        }}
                                                    />
                                                </AccordionTab>
                                            );
                                        })}
                                    </Accordion>
                                ) : (
                                    <h3>There are no orders to review</h3>
                                )}
                                {totalSellerOrders ? (
                                    <Paginator
                                        css={css`
                                            button {
                                                background-color: transparent !important;
                                                &.p-paginator-page:nth-of-type(${sellerPaginationIndex + 1}) {
                                                    color: white !important;
                                                    background-color: #2196f3 !important;
                                                }
                                            }
                                        `}
                                        rows={10}
                                        first={0}
                                        totalRecords={totalSellerOrders}
                                        onPageChange={async (ev) => {
                                            let orders = await getSellerMarketplaceOrders(currentCustomer, ev.page)
                                            setOrdersToReview(orders)
                                            setSellerPaginationIndex(ev.page)
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                ) : null}
                <div css={marketplaceDataLoadStyle} className={showLoader ? 'flex' : 'd-none'}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "3em", color: "#fff" }}></i>
                    <p className="message">Retrieving marketplace data</p>
                </div>
            </div>
        </LayoutWrapper>
    );
};

export default Marketplace;
