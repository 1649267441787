import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, DISPLAY_ADHESION_AD_FIELDS } from "./definitions";
import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";
const CREATIVE_TYPE = CREATIVE_NAMES.DISPLAY_ADHESION;

const COLLAPSE_AD_OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export default function DisplayAdhesion({
    desktopCreativeURL,
    mobileCollapsedCreativeURL,
    desktopCollapsedCreativeURL,
    closeBtnBg,
    collapseDelay,
    collapseBtnWording,
    autoCollapseAd,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                creativeType={CREATIVE_TYPE}
                impressionTag={impressionTag}
            >
                <AssetInputOrUploadSection
                    inputValue={desktopCreativeURL}
                    inputLabel="Desktop Ad Image Url"
                    inputName={DISPLAY_ADHESION_AD_FIELDS.desktopCreativeURL}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileCollapsedCreativeURL}
                    inputLabel="Mobile Ad Image Url"
                    inputName={DISPLAY_ADHESION_AD_FIELDS.mobileCollapsedCreativeURL}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={desktopCollapsedCreativeURL}
                    inputLabel="Collapsed Ad Image Url"
                    inputName={DISPLAY_ADHESION_AD_FIELDS.desktopCollapsedCreativeURL}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />

                <DropdownSection
                    adtype={CREATIVE_TYPE}
                    label="Auto collapse ad?"
                    changeHandler={handleChange}
                    name={DISPLAY_ADHESION_AD_FIELDS.autoCollapseAd}
                    value={autoCollapseAd}
                    options={COLLAPSE_AD_OPTIONS}
                />
                <InputTextSection
                    label={"Collapse delay (in milliseconds)"}
                    name={DISPLAY_ADHESION_AD_FIELDS.collapseDelay}
                    value={collapseDelay}
                    changeHandler={handleChange}
                    type={"input"}
                    adtype={CREATIVE_TYPE}
                    disabled={!autoCollapseAd}
                />
                <InputTextSection
                    label={"Collapse button wording"}
                    name={DISPLAY_ADHESION_AD_FIELDS.collapseBtnWording}
                    value={collapseBtnWording}
                    changeHandler={handleChange}
                    type={"input"}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
            </BaseCreativeControls>
        </>
    );
}