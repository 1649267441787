import { v4 as uuidv4 } from 'uuid'

const DEFAULT_STATE = {
    messages: []
}

const ACTIONS = {
    PUSH_MESSAGE: 'push_message',
    CLEAR_MESSAGES: 'clear_messages'
}

export default function messagingReducer(state = DEFAULT_STATE, action ) {
    switch (action.type) {
        case ACTIONS.PUSH_MESSAGE:
            return {messages: [...state.messages, action.data]}
        case ACTIONS.CLEAR_MESSAGES:
            return {messages: []}
        default:
            return state
    }
}

function pushMessage(screenName, message, severity = 'success') {
    let id = uuidv4()
    return {
        type: ACTIONS.PUSH_MESSAGE,
        data: {
            detail: screenName + ': ' + message,
            life: 6000,
            severity,
            id
        }
    }
}

function clearMessages() {
    return {
        type: ACTIONS.CLEAR_MESSAGES
    }
}

export {
    pushMessage,
    clearMessages
}
