/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const styles = css`
    display: flex;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 0.75em;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    .p-dropdown-item,
    .p-dropdown-label {
        text-transform: capitalize;
    }
    .container {
        margin-right: 1em;
    }
    .control-ctas {
        margin-left: auto;
        margin-top: 0;
        align-self: flex-start;
        .copy-cta,
        .remove-cta {
            margin-left: 1em;
            > svg {
                cursor: pointer;
            }
        }
        .copy-cta:not(.disabled-control) > svg:hover {
            color: #2196F3;
        }
        .remove-cta:not(.disabled-control) > svg:hover {
            color: #ff0000;
        }
    }
    .controls-container,
    .conditions-container {
        display: flex;
    }
    .conditions-container,
    .values-container {
        flex-direction: column;
        .subcondition-dropdown,
        .device-dropdown {
            margin-top: 0.25em;
        }
    }
    .add-rem-controls {
        align-items: flex-end;
        margin-bottom: 0.5em;
        .add-rule,
        .rem-rule {
            margin-left: 0.5em;
            cursor: pointer;
            &.disabled-control > svg {
              color: #ccc;
            }
        }
    }
    .bidder-dropdown,
    .rules-dropdown {
        align-self: start;
    }
    .bidder-dropdown {
        width: 200px;
    }
    .rules-dropdown,
    .condition-dropdown,
    .sub-condition-dropdown {
        width: 150px;
    }
    .user-response {
        width: 250px;
        .time-selectors {
          display: flex;
          justify-content: space-between;
          .p-dropdown {
            width: 100%;
          }
        }
        > .p-component {
            width: 100%;
        }
    }
    .condition-dropdown,
    .user-response {
        margin-bottom: 5px;
    }
    transition: background 0.15s ease-in-out;
    &:hover {
        background-color: #e8e8e863;
    }
`

export const OPERATORS = [
    { name: 'Allow', value: 'Allow' },
    { name: 'Block', value: 'Block' }
]

export const CONDITIONS = [
    { name: 'Country', value: 'Country' },
    { name: 'Device', value: 'Device' },
    { name: 'Browser', value: 'Browser' },
    { name: 'OS', value: 'OS' },
    // {name: 'Postal Code', value: 'Postal Code'},
    { name: 'Idle Time', value: 'Idle Time' },
    { name: 'Time', value: 'Time' },
]

export const DEVICES = [
    { name: 'Desktop', value: 'desk' },
    { name: 'Tablet', value: 'tab' },
    { name: 'Mobile', value: 'mob' }
]

export const COUNTRIES = [
    {
        "name": "United States",
        "value": "us"
    },
    {
        "name": "Afghanistan",
        "value": "af"
    },
    {
        "name": "Albania",
        "value": "al"
    },
    {
        "name": "Algeria",
        "value": "dz"
    },
    {
        "name": "American Samoa",
        "value": "as"
    },
    {
        "name": "Andorra",
        "value": "ad"
    },
    {
        "name": "Angola",
        "value": "ao"
    },
    {
        "name": "Antarctica",
        "value": "aq"
    },
    {
        "name": "Antigua and Barbuda",
        "value": "ag"
    },
    {
        "name": "Argentina",
        "value": "ar"
    },
    {
        "name": "Armenia",
        "value": "am"
    },
    {
        "name": "Australia",
        "value": "au"
    },
    {
        "name": "Austria",
        "value": "at"
    },
    {
        "name": "Azerbaijan",
        "value": "az"
    },
    {
        "name": "Bahrain",
        "value": "bh"
    },
    {
        "name": "Bangladesh",
        "value": "bd"
    },
    {
        "name": "Barbados",
        "value": "bb"
    },
    {
        "name": "Belarus",
        "value": "by"
    },
    {
        "name": "Belgium",
        "value": "be"
    },
    {
        "name": "Belize",
        "value": "bz"
    },
    {
        "name": "Benin",
        "value": "bj"
    },
    {
        "name": "Bhutan",
        "value": "bt"
    },
    {
        "name": "Bolivia",
        "value": "bo"
    },
    {
        "name": "Bosnia and Herzegovina",
        "value": "ba"
    },
    {
        "name": "Botswana",
        "value": "bw"
    },
    {
        "name": "Brazil",
        "value": "br"
    },
    {
        "name": "Brunei",
        "value": "bn"
    },
    {
        "name": "Bulgaria",
        "value": "bg"
    },
    {
        "name": "Burkina Faso",
        "value": "bf"
    },
    {
        "name": "Burundi",
        "value": "bi"
    },
    {
        "name": "Cabo Verde",
        "value": "cv"
    },
    {
        "name": "Cambodia",
        "value": "kh"
    },
    {
        "name": "Cameroon",
        "value": "cm"
    },
    {
        "name": "Canada",
        "value": "ca"
    },
    {
        "name": "Caribbean Netherlands",
        "value": "bq"
    },
    {
        "name": "Central African Republic",
        "value": "cf"
    },
    {
        "name": "Chad",
        "value": "td"
    },
    {
        "name": "Chile",
        "value": "cl"
    },
    {
        "name": "China",
        "value": "cn"
    },
    {
        "name": "Christmas Island",
        "value": "cx"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "value": "cc"
    },
    {
        "name": "Colombia",
        "value": "co"
    },
    {
        "name": "Comoros",
        "value": "km"
    },
    {
        "name": "Cook Islands",
        "value": "ck"
    },
    {
        "name": "Costa Rica",
        "value": "cr"
    },
    {
        "name": "Cote d'Ivoire",
        "value": "ci"
    },
    {
        "name": "Croatia",
        "value": "hr"
    },
    {
        "name": "Curacao",
        "value": "cw"
    },
    {
        "name": "Cyprus",
        "value": "cy"
    },
    {
        "name": "Czechia",
        "value": "cz"
    },
    {
        "name": "Democratic Republic of the Congo",
        "value": "cd"
    },
    {
        "name": "Denmark",
        "value": "dk"
    },
    {
        "name": "Djibouti",
        "value": "dj"
    },
    {
        "name": "Dominica",
        "value": "dm"
    },
    {
        "name": "Dominican Republic",
        "value": "do"
    },
    {
        "name": "Ecuador",
        "value": "ec"
    },
    {
        "name": "Egypt",
        "value": "eg"
    },
    {
        "name": "El Salvador",
        "value": "sv"
    },
    {
        "name": "Equatorial Guinea",
        "value": "gq"
    },
    {
        "name": "Eritrea",
        "value": "er"
    },
    {
        "name": "Estonia",
        "value": "ee"
    },
    {
        "name": "Eswatini",
        "value": "sz"
    },
    {
        "name": "Ethiopia",
        "value": "et"
    },
    {
        "name": "Fiji",
        "value": "fj"
    },
    {
        "name": "Finland",
        "value": "fi"
    },
    {
        "name": "France",
        "value": "fr"
    },
    {
        "name": "French Polynesia",
        "value": "pf"
    },
    {
        "name": "French Southern and Antarctic Lands",
        "value": "tf"
    },
    {
        "name": "Gabon",
        "value": "ga"
    },
    {
        "name": "Georgia",
        "value": "ge"
    },
    {
        "name": "Germany",
        "value": "de"
    },
    {
        "name": "Ghana",
        "value": "gh"
    },
    {
        "name": "Greece",
        "value": "gr"
    },
    {
        "name": "Grenada",
        "value": "gd"
    },
    {
        "name": "Guam",
        "value": "gu"
    },
    {
        "name": "Guatemala",
        "value": "gt"
    },
    {
        "name": "Guernsey",
        "value": "gg"
    },
    {
        "name": "Guinea",
        "value": "gn"
    },
    {
        "name": "Guinea-Bissau",
        "value": "gw"
    },
    {
        "name": "Guyana",
        "value": "gy"
    },
    {
        "name": "Haiti",
        "value": "ht"
    },
    {
        "name": "Heard Island and McDonald Islands",
        "value": "hm"
    },
    {
        "name": "Honduras",
        "value": "hn"
    },
    {
        "name": "Hungary",
        "value": "hu"
    },
    {
        "name": "Iceland",
        "value": "is"
    },
    {
        "name": "India",
        "value": "in"
    },
    {
        "name": "Indonesia",
        "value": "id"
    },
    {
        "name": "Iraq",
        "value": "iq"
    },
    {
        "name": "Ireland",
        "value": "ie"
    },
    {
        "name": "Isle of Man",
        "value": "im"
    },
    {
        "name": "Israel",
        "value": "il"
    },
    {
        "name": "Italy",
        "value": "it"
    },
    {
        "name": "Jamaica",
        "value": "jm"
    },
    {
        "name": "Japan",
        "value": "jp"
    },
    {
        "name": "Jersey",
        "value": "je"
    },
    {
        "name": "Jordan",
        "value": "jo"
    },
    {
        "name": "Kazakhstan",
        "value": "kz"
    },
    {
        "name": "Kenya",
        "value": "ke"
    },
    {
        "name": "Kiribati",
        "value": "ki"
    },
    {
        "name": "Kuwait",
        "value": "kw"
    },
    {
        "name": "Kyrgyzstan",
        "value": "kg"
    },
    {
        "name": "Laos",
        "value": "la"
    },
    {
        "name": "Latvia",
        "value": "lv"
    },
    {
        "name": "Lebanon",
        "value": "lb"
    },
    {
        "name": "Lesotho",
        "value": "ls"
    },
    {
        "name": "Liberia",
        "value": "lr"
    },
    {
        "name": "Libya",
        "value": "ly"
    },
    {
        "name": "Liechtenstein",
        "value": "li"
    },
    {
        "name": "Lithuania",
        "value": "lt"
    },
    {
        "name": "Luxembourg",
        "value": "lu"
    },
    {
        "name": "Madagascar",
        "value": "mg"
    },
    {
        "name": "Malawi",
        "value": "mw"
    },
    {
        "name": "Malaysia",
        "value": "my"
    },
    {
        "name": "Maldives",
        "value": "mv"
    },
    {
        "name": "Mali",
        "value": "ml"
    },
    {
        "name": "Malta",
        "value": "mt"
    },
    {
        "name": "Marshall Islands",
        "value": "mh"
    },
    {
        "name": "Mauritania",
        "value": "mr"
    },
    {
        "name": "Mauritius",
        "value": "mu"
    },
    {
        "name": "Mexico",
        "value": "mx"
    },
    {
        "name": "Micronesia",
        "value": "fm"
    },
    {
        "name": "Moldova",
        "value": "md"
    },
    {
        "name": "Monaco",
        "value": "mc"
    },
    {
        "name": "Mongolia",
        "value": "mn"
    },
    {
        "name": "Montenegro",
        "value": "me"
    },
    {
        "name": "Morocco",
        "value": "ma"
    },
    {
        "name": "Mozambique",
        "value": "mz"
    },
    {
        "name": "Myanmar (Burma)",
        "value": "mm"
    },
    {
        "name": "Namibia",
        "value": "na"
    },
    {
        "name": "Nauru",
        "value": "nr"
    },
    {
        "name": "Nepal",
        "value": "np"
    },
    {
        "name": "Netherlands",
        "value": "nl"
    },
    {
        "name": "New Caledonia",
        "value": "nc"
    },
    {
        "name": "New Zealand",
        "value": "nz"
    },
    {
        "name": "Nicaragua",
        "value": "ni"
    },
    {
        "name": "Niger",
        "value": "ne"
    },
    {
        "name": "Nigeria",
        "value": "ng"
    },
    {
        "name": "Niue",
        "value": "nu"
    },
    {
        "name": "Norfolk Island",
        "value": "nf"
    },
    {
        "name": "North Macedonia",
        "value": "mk"
    },
    {
        "name": "Northern Mariana Islands",
        "value": "mp"
    },
    {
        "name": "Norway",
        "value": "no"
    },
    {
        "name": "Oman",
        "value": "om"
    },
    {
        "name": "Pakistan",
        "value": "pk"
    },
    {
        "name": "Palau",
        "value": "pw"
    },
    {
        "name": "Panama",
        "value": "pa"
    },
    {
        "name": "Papua New Guinea",
        "value": "pg"
    },
    {
        "name": "Paraguay",
        "value": "py"
    },
    {
        "name": "Peru",
        "value": "pe"
    },
    {
        "name": "Philippines",
        "value": "ph"
    },
    {
        "name": "Pitcairn Islands",
        "value": "pn"
    },
    {
        "name": "Poland",
        "value": "pl"
    },
    {
        "name": "Portugal",
        "value": "pt"
    },
    {
        "name": "Qatar",
        "value": "qa"
    },
    {
        "name": "Republic of the Congo",
        "value": "cg"
    },
    {
        "name": "Romania",
        "value": "ro"
    },
    {
        "name": "Russia",
        "value": "ru"
    },
    {
        "name": "Rwanda",
        "value": "rw"
    },
    {
        "name": "Saint Barthelemy",
        "value": "bl"
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "sh"
    },
    {
        "name": "Saint Kitts and Nevis",
        "value": "kn"
    },
    {
        "name": "Saint Lucia",
        "value": "lc"
    },
    {
        "name": "Saint Martin",
        "value": "mf"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "value": "pm"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "value": "vc"
    },
    {
        "name": "Samoa",
        "value": "ws"
    },
    {
        "name": "San Marino",
        "value": "sm"
    },
    {
        "name": "Sao Tome and Principe",
        "value": "st"
    },
    {
        "name": "Saudi Arabia",
        "value": "sa"
    },
    {
        "name": "Senegal",
        "value": "sn"
    },
    {
        "name": "Serbia",
        "value": "rs"
    },
    {
        "name": "Seychelles",
        "value": "sc"
    },
    {
        "name": "Sierra Leone",
        "value": "sl"
    },
    {
        "name": "Singapore",
        "value": "sg"
    },
    {
        "name": "Sint Maarten",
        "value": "sx"
    },
    {
        "name": "Slovakia",
        "value": "sk"
    },
    {
        "name": "Slovenia",
        "value": "si"
    },
    {
        "name": "Solomon Islands",
        "value": "sb"
    },
    {
        "name": "Somalia",
        "value": "so"
    },
    {
        "name": "South Africa",
        "value": "za"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "value": "gs"
    },
    {
        "name": "South Korea",
        "value": "kr"
    },
    {
        "name": "South Sudan",
        "value": "ss"
    },
    {
        "name": "Spain",
        "value": "es"
    },
    {
        "name": "Sri Lanka",
        "value": "lk"
    },
    {
        "name": "Sudan",
        "value": "sd"
    },
    {
        "name": "Suriname",
        "value": "sr"
    },
    {
        "name": "Sweden",
        "value": "se"
    },
    {
        "name": "Switzerland",
        "value": "ch"
    },
    {
        "name": "Tajikistan",
        "value": "tj"
    },
    {
        "name": "Tanzania",
        "value": "tz"
    },
    {
        "name": "Thailand",
        "value": "th"
    },
    {
        "name": "The Bahamas",
        "value": "bs"
    },
    {
        "name": "The Gambia",
        "value": "gm"
    },
    {
        "name": "Timor-Leste",
        "value": "tl"
    },
    {
        "name": "Togo",
        "value": "tg"
    },
    {
        "name": "Tokelau",
        "value": "tk"
    },
    {
        "name": "Tonga",
        "value": "to"
    },
    {
        "name": "Trinidad and Tobago",
        "value": "tt"
    },
    {
        "name": "Tunisia",
        "value": "tn"
    },
    {
        "name": "Turkiye",
        "value": "tr"
    },
    {
        "name": "Turkmenistan",
        "value": "tm"
    },
    {
        "name": "Tuvalu",
        "value": "tv"
    },
    {
        "name": "Uganda",
        "value": "ug"
    },
    {
        "name": "Ukraine",
        "value": "ua"
    },
    {
        "name": "United Arab Emirates",
        "value": "ae"
    },
    {
        "name": "United Kingdom",
        "value": "gb"
    },
    {
        "name": "United States Minor Outlying Islands",
        "value": "um"
    },
    {
        "name": "Uruguay",
        "value": "uy"
    },
    {
        "name": "Uzbekistan",
        "value": "uz"
    },
    {
        "name": "Vanuatu",
        "value": "vu"
    },
    {
        "name": "Vatican City",
        "value": "va"
    },
    {
        "name": "Venezuela",
        "value": "ve"
    },
    {
        "name": "Vietnam",
        "value": "vn"
    },
    {
        "name": "Wallis and Futuna",
        "value": "wf"
    },
    {
        "name": "Yemen",
        "value": "ye"
    },
    {
        "name": "Zambia",
        "value": "zm"
    },
    {
        "name": "Zimbabwe",
        "value": "zw"
    }
]

export const OPERATING_SYSTEMS = [
    { name: 'Windows', value: 'windows' },
    { name: 'Mac', value: 'mac' },
    { name: 'Android', value: 'android' }
]

export const BROWSERS = [
    { name: 'Chrome', value: 'chrome' },
    { name: 'Brave', value: 'brave' },
    { name: 'Microsoft Edge', value: 'edge' },
    { name: 'Safari', value: 'safari' },
    { name: 'Firefox', value: 'firefox' },
    { name: 'Opera', value: 'opera' }
]

export const TIME_RANGES = [
    { name: '00:00am', value: '0000' },
    { name: '00:30am', value: '0030' },
    { name: '01:00am', value: '0100' },
    { name: '01:30am', value: '0130' },
    { name: '02:00am', value: '0200' },
    { name: '02:30am', value: '0230' },
    { name: '03:00am', value: '0300' },
    { name: '03:30am', value: '0330' },
    { name: '04:00am', value: '0400' },
    { name: '04:30am', value: '0430' },
    { name: '05:00am', value: '0500' },
    { name: '05:30am', value: '0530' },
    { name: '06:00am', value: '0600' },
    { name: '06:30am', value: '0630' },
    { name: '07:00am', value: '0700' },
    { name: '07:30am', value: '0730' },
    { name: '08:00am', value: '0800' },
    { name: '08:30am', value: '0830' },
    { name: '09:00am', value: '0900' },
    { name: '09:30am', value: '0930' },
    { name: '10:00am', value: '1000' },
    { name: '10:30am', value: '1030' },
    { name: '11:00am', value: '1100' },
    { name: '11:30am', value: '1130' },
    { name: '12:00am', value: '1200' },
    { name: '12:30am', value: '1230' },
    { name: '01:00pm', value: '1300' },
    { name: '01:30pm', value: '1330' },
    { name: '02:00pm', value: '1400' },
    { name: '02:30pm', value: '1430' },
    { name: '03:00pm', value: '1500' },
    { name: '03:30pm', value: '1530' },
    { name: '04:00pm', value: '1600' },
    { name: '04:30pm', value: '1630' },
    { name: '05:00pm', value: '1700' },
    { name: '05:30pm', value: '1730' },
    { name: '06:00pm', value: '1800' },
    { name: '06:30pm', value: '1830' },
    { name: '07:00pm', value: '1900' },
    { name: '07:30pm', value: '1930' },
    { name: '08:00pm', value: '2000' },
    { name: '08:30pm', value: '2030' },
    { name: '09:00pm', value: '2100' },
    { name: '09:30pm', value: '2130' },
    { name: '10:00pm', value: '2200' },
    { name: '10:30pm', value: '2230' },
    { name: '11:00pm', value: '2300' },
    { name: '11:30pm', value: '2330' },
    { name: '12:00pm', value: '2400' },
]

export const DEFAULT_CONFIG = {
    bidder: '',
    operator: 'Block',
    condition_1: '',
    countries: [],
    devices: [],
    os: [],
    browsers: [],
    conditionMappings: {},
    idle_time: '',
    postal_code: '',
    begin: '',
    end: ''
}