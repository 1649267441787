function adapterErrorsReducer (state = [], action) {
    const { type } = action
    if (type === 'REGISTER_ERROR') {
        return [...state, action.data]
    } else if (type === 'POP_ERROR') {
        return state.filter(adapterError => adapterError.adapterName !== action.data.adapterName)
    }
    return state
}

function registerError(adapterName, errorMessage) {
    return {
        type: 'REGISTER_ERROR',
        data: {
            adapterName,
            errorMessage
        }
    }
}

function removeError(adapterName) {
    return {
        type: 'POP_ERROR',
        data: {
            adapterName
        }
    }
}

export default adapterErrorsReducer

export {
    registerError,
    removeError
}