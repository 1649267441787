import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, MOBILE_EXPANDABLE_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.MOBILE_EXPANDABLE;

export default function MobileExpandable({
    container,
    smBanner,
    mdBanner,
    expandedBanner,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={smBanner}
                    inputLabel="Static collapsed banner (320x50)"
                    inputName={MOBILE_EXPANDABLE_AD_FIELDS.smBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mdBanner}
                    inputLabel="Static semi-expanded banner (320x100)"
                    inputName={MOBILE_EXPANDABLE_AD_FIELDS.mdBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={expandedBanner}
                    inputLabel="Static expanded banner (720x1280)"
                    inputName={MOBILE_EXPANDABLE_AD_FIELDS.expandedBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <InputTextSection
                    label="Container selector (optional)"
                    name={MOBILE_EXPANDABLE_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                    uriEncodeValue={false}
                />
            </BaseCreativeControls>

        </>
    );
}

