import React from 'react'
import AdNativeInjection from './AdNativeInjection'

const VideoPlayerInjections = () => {
    return (
        <AdNativeInjection
            mode="video"
        />
    )
}

export default VideoPlayerInjections