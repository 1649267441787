import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import classNames from "classnames";

export const ADSIZE_SUGGESTIONS = ["1024x50", "970x250", "970x90", "970x66", "980x120", "728x90", "320x100", "336x280", "300x250", "300x600", "320x50", "300x50", "160x600", "120x600", "1x1", "2x2", "3x3", "4x4", "fluid"];

export const AUTH_TOKEN = "auth-token";
export const USER_NAME = "user-name";
export const AVATAR_LINK = "avatar-link";
export const USERINFO = "user-info";
export const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": false,
    "layout-static": true,
    "layout-static-sidebar-inactive": false,
});

export const displayAdapters = {
    beachfront: {
        adapterParametersNames: [
            ["appId", "string", "App Id"],
            ["bidfloor", "number", "BidFloor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    mediafuse: {
        adapterPrettyName: "MediaFuse",
        adapterParametersNames: [["placementId", "number", "PlacementID"]],
    },
    conversant: {
        adapterPrettyName: "Conversant",
        adapterParametersNames: [["site_id", "string", "site_id"]],
    },
    nativo: {
        adapterPrettyName: "Nativo",
        adapterParametersNames: [["placementId", "string", "Placement Id"]],
    },
    bcmssp: {
        adapterPrettyName: "Brightcom SSP",
        adapterParametersNames: [["publisherId", "string", "Publisher ID"]],
    },
    brightcom: {
        adapterParametersNames: [["publisherId", "string", "Publisher ID"]],
    },
    concert: {
        globalParameters: [["partnerId", "string", "Partner ID"]],
        adapterParametersNames: [["placementId", "number", "Placement ID"]],
        optionalParameters: ["placementId"],
    },
    gourmetads: {
        adapterPrettyName: "Gourmet Ads",
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    amx: {
        adapterParametersNames: [["tagId", "string", "Tag ID"]],
    },
    ix: {
        adapterPrettyName: "Index Exchange",
        adapterParametersNames: [["siteId", "string", "Site ID"]],
    },
    "33across": {
        adapterParametersNames: [
            ["siteId", "string", "Site ID"],
            ["productId", "string", "Product ID"],
        ],
    },
    pulsepoint: {
        sizeKey: "cf",
        adapterParametersNames: [["ct", "string", "CT"]],
        globalParameters: [["cp", "number", "CP"]],
    },
    sovrn: {
        adapterParametersNames: [["tagid", "string", "Tag ID"]],
    },
    appnexus: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    onemobile: {
        adapterParametersNames: [
            ["dcn", "string", "DCN"],
            ["pos", "string", "POS"],
        ],
    },
    pubmatic: {
        adapterParametersNames: [
            ["publisherId", "string", "Publisher Id"],
            ["adSlot", "string", "Ad slot"],
        ],
        optionalParameters: ["adSlot"],
    },
    triplelift: {
        adapterParametersNames: [["inventoryCode", "string", "Inventory Code"]],
    },
    kargo: {
        // allowRefresh={adtype !== 'video'}
        allowRefresh: true,
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    consumable: {
        adapterParametersNames: [
            ["unitId", "number", "Unit ID"],
            ["unitName", "string", "Unit Name"],
        ],
        globalParameters: [
            ["siteId", "number", "Site ID"],
            ["networkId", "number", "Network Id"],
        ],
    },
    undertone: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["publisherId", "number", "Publisher Id"],
        ],
    },
    nobid: {
        adapterParametersNames: [["siteId", "number", "Site ID"]],
    },
    unruly: {
        adapterParametersNames: [["siteId", "number", "Site ID"]],
    },
    openx: {
        adapterParametersNames: [
            ["delDomain", "string", "delDomain"],
            ["unit", "string", "Unit"],
        ],
    },
    imds: {
        adapterParametersNames: [
            ["seatId", "string", "Seat Id"],
            ["tagId", "string", "Tag Id"],
        ],
    },
    rubicon: {
        /* adtype === "video"
                    ? [
                          ["zoneId", "number", "Zone Id"],
                          ["size_id", "number", "Size ID"],
                      ]
                    : [["zoneId", "number", "Zone Id"]]*/
        adapterParametersNames: [["zoneId", "number", "Zone Id"]],
        globalParameters: [
            ["siteId", "number", "Site ID"],
            ["accountId", "number", "Account ID"],
        ],
    },
    shemedia: {
        adapterParametersNames: [["zoneId", "number", "Zone Id"]],
        globalParameters: [
            ["siteId", "number", "Site ID"],
            ["accountId", "number", "Account ID"],
        ],
    },
    ias: {
        adapterParametersNames: [
            ["pubId", "string", "Pub Id"],
            ["adUnitPath", "string", "Ad Unit Path"],
        ],
    },
    cadent_aperture_mx: {
        adapterPrettyName: "Cadent Aperture MX",
        adapterParametersNames: [
            ["tagid", "string", "Tag Id"],
            ["bidfloor", "string", "BidFloor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    rhythmone: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    colossusssp: {
        adapterName: "colossusssp",
        adapterPrettyName: "Colossus",
        adapterParametersNames: [["placement_id", "number", "Placement ID"]],
        globalParameters: [],
        // globalParameters={adtype === "video" ? [["traffic", "string", "Traffic"]] : []}
    },
    mantis: {
        adapterParametersNames: [
            ["property", "string", "Property"],
            ["zone", "string", "Zone"],
        ],
    },
    grid: {
        adapterPrettyName: "TheMediaGrid",
        adapterParametersNames: [["uid", "string", "UID"]],
    },
    gumgum: {
        allowRefresh: true,
        adapterParametersNames: [
            ["zone", "string", "Zone"],
            ["pubId", "number", "PubID"],
            ["slot", "number", "Slot"],
            ["product", "string", "Product"],
        ],
        optionalParameters: ["product"],
    },
    adyoulike: {
        adapterParametersNames: [["placement", "string", "Placement ID"]],
    },
    medianet: {
        adapterParametersNames: [
            ["cid", "string", "CID"],
            ["crid", "string", "CRID"],
        ],
    },
    mediaforce: {
        adapterParametersNames: [
            ["placement_id", "string", "Placement Id"],
            ["publisher_id", "string", "Publisher Id"],
        ],
    },
    pixfuture: {
        adapterParametersNames: [["pix_id", "string", "Pix Id"]],
    },
    improvedigital: {
        allowRefresh: true,
        adapterParametersNames: [["placementId", "number", "Placement ID"]],
        globalParameters: [["publisherId", "number", "PublisherId ID"]],
    },
    resetdigital: {
        adapterName: "resetdigital",
        adapterPrettyName: "Reset Digital",
        adapterParametersNames: [["pubId", "string", "Pub ID"], ["placementId", "string", "Placement ID"]],
        nestedParameters: {
            'placementId': 'zoneId'
        }
    },
    teads: {
        adapterParametersNames: [
            ["pageId", "number", "Page Id"],
            ["placementId", "number", "Placement Id"],
        ],
    },
    yieldmo: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["bidFloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    sharethrough: {
        adapterParametersNames: [
            ["pkey", "string", "Pkey"],
            ["bidfloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    inskin: {
        adapterParametersNames: [
            ["siteId", "number", "Site ID"],
            ["networkId", "number", "Network ID"],
        ],
    },
    justpremium: {
        adapterParametersNames: [["zone", "string", "Zone ID"]],
        /* allowRefresh={adtype !== "video"} */
        allowRefresh: true,
    },
    ogury: {
        adapterParametersNames: [
            ["assetKey", "string", "Asset Key"],
            ["adUnitId", "string", "Ad Unit ID"],
        ],
        rowLevelParameters: [["skipSizeCheck", "boolean", "Skip size check"]],
    },
    seedtag: {
        adapterParametersNames: [
            ["publisherId", "string", "Publisher Id"],
            ["adUnitId", "string", "AdUnit ID"],
            ["placement", "string", "Placement"],
        ],
    },
    smartadserver: {
        adapterParametersNames: [
            ["networkId", "number", "Network Id"],
            ["siteId", "number", "Site Id"],
            ["pageId", "number", "Page ID"],
            ["formatId", "number", "Format ID"],
        ],
    },
    mobfoxpb: {
        adapterParametersNames: [["placementId", "string", "Placement Id"]],
    },
    rise: {
        adapterParametersNames: [["org", "string", "Org"]],
    },
    onetag: {
        adapterParametersNames: [["pubId", "string", "Pub ID"]],
    },
    adagio: {
        adapterParametersNames: [
            ["organizationId", "string", "Organization Id"],
            ["site", "string", "Site"],
            ["placement", "string", "Placement"],
        ],
    },
    yahoossp: {
        adapterParametersNames: [["pubId", "string", "Pub ID"]],
    },
    minutemedia: {
        adapterParametersNames: [["org", "string", "Org"]],
    },
    iqzone: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["endpointId", "string", "Endpoint Id"],
        ],
        optionalParameters: ["placementId", "endpointId"],
    },
    nextMillennium: {
        adapterParametersNames: [["placement_id", "string", "Placement ID"]],
    },
    adkernel: {
        adapterParametersNames: [
            ["host", "string", "Host"],
            ["zoneId", "number", "Zone ID"],
        ],
    },
    projectagora: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    bcovery_ad: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    smilewanted: {
        adapterParametersNames: [["zoneId", "string", "Zone ID"]],
    },
    sonobi: {
        adapterParametersNames: [
            ["placement_id", "string", "Placement ID"],
            ["ad_unit", "string", "Ad Unit ID"],
        ],
        optionalParameters: ["placement_id", "ad_unit"],
    },
    adasta: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    kueezrtb: {
        adapterParametersNames: [
            ["cId", "string", "CId"],
            ["pId", "string", "PId"],
            ["bidFloor", "number", "Bid Floor"],
        ],
    },
    tappx: {
        adapterParametersNames: [
            ["tappxkey", "string", "Tappx key"],
            ["endpoint", "string", "End point"],
            ["host", "string", "Host"],
        ],
    },
    smartyads: {
        adapterParametersNames: [
            ["sourceid", "string", "Source ID"],
            ["host", "string", "Host"],
            ["accountid", "string", "Account Id"],
            ["traffic", "string", "Traffic"],
        ],
    },
    globalsun: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
        optionalParameters: ["placementId"],
    },
    connectad: {
        adapterParametersNames: [
            ["siteId", "number", "Site ID"],
            ["networkId", "number", "Network ID"],
            ["bidfloor", "number", "BidFloor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    adtelligent: {
        adapterParametersNames: [["aid", "number", "AID"]],
    },
    vidazoo: {
        adapterParametersNames: [
            ["cId", "string", "CID"],
            ["pId", "string", "PID"],
        ],
        globalParameters: [
            ["subDomain", "string", "Sub Domain"],
        ],
    },
    silverpush: {
        adapterParametersNames: [
            ["publisherId", "string", "Publisher Id"],
            ["bidFloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidFloor"],
    },
    datablocks: {
        adapterParametersNames: [
            ["source_id", "number", "Source Id"],
            ["host", "string", "Host"],
            ["tagid", "string", "Tag Id"],
        ],
        optionalParameters: ["host","tagid"],
    },
    oftmedia: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["member", "string", "Member"],
            ["randomKey", "string", "Random Key"],
            ["invCode", "string", "Inv Code"],
        ],
        optionalParameters: ["randomKey", "invCode"],
    },
    richaudience: {
        adapterParametersNames: [
            ["pid", "string", "Placement Id"],
            ["supplyType", "string", "Supply Type"],
        ],
    },
    criteo: {
        adapterParametersNames: [
            ["networkId", "number", "Network Id"],
            ["pubid", "string", "Pub ID"],
        ],
    },
    nexx360: {
        adapterParametersNames: [["tagId", "string", "Tag ID"]],
    },
};

export const videoAdaptersConfig = {
    appnexus: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    pubmatic: {
        adapterParametersNames: [
            ["publisherId", "string", "Publisher Id"],
            ["adSlot", "string", "Ad slot"],
        ],
        optionalParameters: ["adSlot"],
    },
    "33across": {
        adapterParametersNames: [
            ["siteId", "string", "Site ID"],
            ["productId", "string", "Product ID"],
        ],
    },
    adyoulike: {
        adapterParametersNames: [["placement", "string", "Placement ID"]],
    },
    amx: {
        adapterParametersNames: [["tagId", "string", "Tag ID"]],
    },
    beachfront: {
        adapterParametersNames: [
            ["appId", "string", "App Id"],
            ["bidfloor", "number", "BidFloor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    colossusssp: {
        adapterName: "colossusssp",
        adapterPrettyName: "Colossus",
        adapterParametersNames: [["placement_id", "number", "Placement ID"]],
        // globalParameters: [],
        globalParameters: [["traffic", "string", "Traffic"]],
    },
    conversant: {
        adapterPrettyName: "Conversant",
        adapterParametersNames: [["site_id", "string", "site_id"]],
    },
    cadent_aperture_mx: {
        adapterPrettyName: "Cadent Aperture MX",
        adapterParametersNames: [
            ["tagid", "string", "Tag Id"],
            ["bidfloor", "string", "BidFloor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    grid: {
        adapterPrettyName: "TheMediaGrid",
        adapterParametersNames: [["uid", "string", "UID"]],
    },
    gumgum: {
        allowRefresh: false,
        adapterParametersNames: [
            ["zone", "string", "Zone"],
            ["pubId", "number", "PubID"],
            ["slot", "number", "Slot"],
            ["product", "string", "Product"],
        ],
        optionalParameters: ["product"],
    },
    improvedigital: {
        allowRefresh: false,
        adapterParametersNames: [["placementId", "number", "Placement ID"]],
        globalParameters: [["publisherId", "number", "Placement ID"]],
    },
    ix: {
        adapterPrettyName: "Index Exchange",
        adapterParametersNames: [["siteId", "string", "Site ID"]],
    },
    kargo: {
        allowRefresh: false,
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    mantis: {
        adapterParametersNames: [
            ["property", "string", "Property"],
            ["zone", "string", "Zone"],
        ],
    },
    medianet: {
        adapterParametersNames: [
            ["cid", "string", "CID"],
            ["crid", "string", "CRID"],
        ],
    },
    nobid: {
        adapterParametersNames: [["siteId", "number", "Site ID"]],
    },
    openx: {
        adapterParametersNames: [
            ["delDomain", "string", "delDomain"],
            ["unit", "string", "Unit"],
        ],
    },
    pulsepoint: {
        sizeKey: "cf",
        adapterParametersNames: [["ct", "string", "CT"]],
        globalParameters: [["cp", "number", "CP"]],
    },
    rhythmone: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
    },
    rubicon: {
        adapterParametersNames: [
            ["zoneId", "number", "Zone Id"],
            ["size_id", "number", "Size ID"],
        ],
        globalParameters: [
            ["siteId", "number", "Site ID"],
            ["accountId", "number", "Account ID"],
        ],
    },
    shemedia: {
        adapterParametersNames: [
            ["zoneId", "number", "Zone Id"],
            ["size_id", "number", "Size ID"],
        ],
        globalParameters: [
            ["siteId", "number", "Site ID"],
            ["accountId", "number", "Account ID"],
        ],
    },
    imds: {
        adapterParametersNames: [
            ["seatId", "string", "Seat Id"],
            ["tagId", "string", "Tag Id"],
        ],
    },
    teads: {
        adapterParametersNames: [
            ["pageId", "number", "Page Id"],
            ["placementId", "number", "Placement Id"],
        ],
    },
    triplelift: {
        adapterParametersNames: [["inventoryCode", "string", "Inventory Code"]],
    },
    undertone: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["publisherId", "number", "Publisher Id"],
        ],
    },
    unruly: {
        adapterParametersNames: [["siteId", "number", "Site ID"]],
    },
    yieldmo: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["bidFloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    resetdigital: {
        adapterName: "resetdigital",
        adapterPrettyName: "Reset Digital",
        adapterParametersNames: [["pubId", "string", "Pub ID"]],
        nestedParameters: {
            'placementId': 'zoneId'
        }
    },
    sovrn: {
        adapterParametersNames: [["tagid", "string", "Tag ID"]],
    },
    spotx: {
        adapterParametersNames: [
            ["channel_id", "string", "Channel ID"],
            ["ad_unit", "string", "Ad Unit"],
        ],
    },
    sharethrough: {
        adapterParametersNames: [
            ["pkey", "string", "Pkey"],
            ["bidfloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidfloor"],
    },
    rise: {
        adapterParametersNames: [["org", "string", "Org"]],
    },
    yahoossp: {
        adapterParametersNames: [["pubId", "string", "Pub ID"]],
    },
    minutemedia: {
        adapterParametersNames: [["org", "string", "Org"]],
    },
    iqzone: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["endpointId", "string", "Endpoint Id"],
        ],
        optionalParameters: ["placementId", "endpointId"],
    },
    nextMillennium: {
        adapterParametersNames: [["placement_id", "string", "Placement ID"]],
    },
    adkernel: {
        adapterParametersNames: [
            ["host", "string", "Host"],
            ["zoneId", "number", "Zone ID"],
        ],
    },
    sonobi: {
        adapterParametersNames: [
            ["placement_id", "string", "Placement ID"],
            ["ad_unit", "string", "Ad Unit ID"],
        ],
        optionalParameters: ["placement_id", "ad_unit"],
    },
    kueezrtb: {
        adapterParametersNames: [
            ["cId", "string", "CId"],
            ["pId", "string", "PId"],
            ["bidFloor", "number", "Bid Floor"],
        ],
    },
    tappx: {
        adapterParametersNames: [
            ["tappxkey", "string", "Tappx key"],
            ["endpoint", "string", "End point"],
            ["host", "string", "Host"],
        ],
    },
    smartyads: {
        adapterParametersNames: [
            ["sourceid", "string", "Source ID"],
            ["host", "string", "Host"],
            ["accountid", "string", "Account Id"],
            ["traffic", "string", "Traffic"],
        ],
    },
    globalsun: {
        adapterParametersNames: [["placementId", "string", "Placement ID"]],
        optionalParameters: ["placementId"],
    },
    adtelligent: {
        adapterParametersNames: [["aid", "number", "AID"]],
    },
    vidazoo: {
        adapterParametersNames: [
            ["cId", "string", "CID"],
            ["pId", "string", "PID"],
        ],
    },
    silverpush: {
        adapterParametersNames: [
            ["publisherId", "string", "Publisher Id"],
            ["bidFloor", "number", "Bid Floor"],
        ],
        optionalParameters: ["bidFloor"],
    },
    oftmedia: {
        adapterParametersNames: [
            ["placementId", "string", "Placement Id"],
            ["member", "string", "Member"],
            ["randomKey", "string", "Random Key"],
            ["invCode", "string", "Inv Code"],
        ],
        optionalParameters: ["randomKey", "invCode"],
    },
    richaudience: {
        adapterParametersNames: [
            ["pid", "string", "Placement Id"],
            ["supplyType", "string", "Supply Type"],
        ],
    },
};

export const ADP_SIZES = [{ name: "970X250" }, { name: "970X90" }, { name: "970X66" }, { name: "728X90" }, { name: "320X100" }, { name: "300X250" }, { name: "300X600" }, { name: "320X50" }, { name: "300X50" }];
export const DOMAINS_URL = ["/adsettings", "/adpositions", "/keyvalue", "/nativeads", "/adapter", "/lazyload", "/adcpminfo", "/domainrestoresystem", "/adexclusion", "/slotplacementid", "/identityhub", "/supplychain"];
export const DOCUMENTATION_URL = ["documentation"];
export const DOCUMENTATIONCONTENT_URL = {
    documentation: "documentationcontent",
};
export const DOMAINS_URL_LABEL = {
    "/adsettings": "Ad Settings",
    "/adpositions": "Ad Positions",
    "/keyvalue": "Key Value Pair",
    "/nativeads": "Native Ad Injection",
    "/adapter": "Adapter Selection",
    "/lazyload": "Lazy Load",
    "/adcpminfo": "CMP",
    "/domainrestoresystem": "Domain Build System",
    "/adexclusion": "Ad Exclusion",
    "/slotplacementid": "Slot Placement Ids",
    "/identityhub": "Pubmatic Identity Hub",
    "/supplychain": "Supply Chain",
};
export const PRESET_URL = ["/presetsettings", "/presetadapter", "/presetadpositions", "/presetlazyload", "/presetadexclusion", "/presetslotplacementid", "/presetidentityhub"];
export const PRESET_URL_LABEL = {
    "/presetsettings": "Ad Settings",
    "/presetadpositions": "Ad Positions",
    "/presetadapter": "Adapter Selection",
    "/presetlazyload": "Lazy Load",
    "/presetadexclusion": "Ad Exclusion",
    "/presetslotplacementid": "Slot Placement Ids",
    "/presetidentityhub": "Preset Pubmatic Identity Hub",
};
export const ADP_SIZES_ALL = [{ name: "All Sizes" }];

export const sidebarClassName = classNames("layout-sidebar", {
    "layout-sidebar-dark": true,
});
const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URI}`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("auth-token");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export const ADMIN_SELECT_FROM_DROPDOWN = "Please select a customer from the left navigation dropdown.";

export const CLIENT_SELECT_FROM_DROPDOWN = "Please select a customer from the left navigation dropdown.";
