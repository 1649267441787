import { useMutation } from "@apollo/client";
import { DOMAIN_SETTINGS_MUTATION } from "../mutations/mutations";

export function useDomainSettings(options = {}) {
    const [container] = useMutation(DOMAIN_SETTINGS_MUTATION, options)

    return ({ domainId, customerId }) => {
        /* 
            Enforcing data types below.
            TODO: write a wrapper class so that this validation happens on a per-need basis 
        */

        let variables = {
            id: domainId && String(domainId),
            cid: customerId && String(customerId)
        }

        for (let p in variables) {
            let v = variables[p]
            if (v === undefined || v === null) {
                delete variables[p]
            }
        }

        return container({ variables })
    }
}