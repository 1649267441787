import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const userInfo = JSON.parse(localStorage.getItem('user-info'))

const UserEdit = () => {
    return (
        <div className="p-grid crud-demo">
            <DataTable value={userInfo}>
                <Column field="data.user_nicename" header="h h"></Column>
                <Column field="data.user_nicename" header="a a"></Column>
                <Column field="data.user_email" header="e e"></Column>
                <Column field="data.Phone" header="p p"></Column>
                <Column field="data.user_status" header="s s"></Column>
            </DataTable>
        </div>
    );
}

export default UserEdit