import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { pushMessage } from "../../reducers/messagingReducer";
import { addDomain, clearBuildList } from "../../reducers/multiBuildSystemReducer";
import { clearScreenChanges } from "../../reducers/screenChangesReducer";
import Cta from "./marketplace/CTA";

const BuildDomainCta = ({
    url,
    notificationText,
    ctaText,
    enabledTooltipText,
    disabledTooltipText,
    icon,
    tooltipPosition
}) => {
    const { currentDomain, currentCustomer } = useSelector((state) => state.userInformation);
    const screenState = useSelector((state) => state.screenStateWatcher);
    const buildState = useSelector(state => state.multiBuildSystemInfo)
    const [isModified, setIsModified] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        
        if (currentCustomer && currentDomain) {
            let modifiedDomains = buildState[currentCustomer]?.modifiedDomains || new Set()            
            let domainId = parseInt(currentDomain)
            setIsModified(
                 modifiedDomains.has(domainId)
            );
        }
    }, [currentCustomer, currentDomain, buildState]);

    function handleClick() {
        if (buildState[currentCustomer]?.isClearingCache) {
            dispatch(pushMessage('Cloudflare', "Cache-clearing process must finish for this domain before you can build again", "error"))
            return;
        }
        if (buildState[currentCustomer]?.isBuilding) {
            dispatch(pushMessage('Build', "Build process must finish before you can build another domain", "error"))
            return;
        }
        if (screenState.isModified) {
            let response = window.confirm("You haven't saved your changes. Do you want to leave without saving?");
            if (response) {
                dispatch(addDomain({customerId: currentCustomer, domainId: parseInt(currentDomain)}))
                dispatch(clearScreenChanges());
                if (notificationText) {
                    dispatch(pushMessage('Build', notificationText, 'success'))
                    setTimeout(() => {
                        history.push(url);
                    }, 1000)
                } else {
                    history.push(url);
                }
            }
        } else {
            dispatch(clearBuildList({customerId: currentCustomer}))
            dispatch(addDomain({
                customerId: currentCustomer,
                domainId: parseInt(currentDomain)
            }))
            
            if (notificationText) {
                dispatch(pushMessage('Build', notificationText, 'success'))
                setTimeout(() => {
                    history.push(url);
                }, 1000)
            } else {
                history.push(url);
            }
        }
    }

    return (
        currentDomain && (
            <Cta 
                disabled={!currentCustomer}
                ctaText={ctaText} 
                icon={icon} 
                themeClasses={`marketplace-cta ${isModified ? "danger" : "default"} outlined`} 
                tooltip={isModified ? enabledTooltipText : disabledTooltipText}
                onClick={handleClick}
                tooltipPosition={tooltipPosition}
            />
        )
    );
};

export default BuildDomainCta;
