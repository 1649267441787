/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState } from "react";
import OrderRow from "./OrderRow";
import MarketplaceOrderDataContext from "../../../context/marketplaceOrderDataContext";

const accordionStyles = css`
    .targeting-tab > a {
        background-color: #fff !important;
        padding-left: 0.5em;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        min-height: 36px !important;
        border-top: 1px solid rgb(222, 226, 230) !important;
    }
    .targeting-tab > a > .p-accordion-header-text {
        font-weight: 400;
        color: #1b1e20;
    }
`;

const accordionTabStyles = css`
    display: flex;
    .selection-column,
    .results-column {
        flex-basis: 50%;
        padding: 1em;
        min-height: 150px;
        max-height: 300px;
        overflow-y: scroll;
    }
    .selection-column {
        border-right: 1px inset #ccc;
    }
    .top-controls {
        display: flex;
        justify-content: space-between;
        .control {
            color: #2196f3;
            cursor: pointer;
        }
    }
`;

const OrderRowPanelSelector = ({
    sourceData = [],
    handleSelection,
    orderRowName,
    orderRowLabel,
    orderRowDescription,
    ItemTemplate,
    allowSearch = false,
    disabled = false
}) => {
    const { currentOrder: order, currentOrderIdx: idx } = useContext(MarketplaceOrderDataContext)
    const { targetDevices: targetData, selectDeviceMode: isSelectionMode } = order
    const [searchText, setSearchText] = useState("")

    let totalTargetedDevices = targetData.length
    let header = totalTargetedDevices === 0
        ? "Device category (all devices selected by default)"
        : `Device category (${totalTargetedDevices} ${isSelectionMode ? " devices included" : " devices excluded"})`;

    function filterSourceData(sourceData, filterData = false) {
        return filterData
            ? sourceData.filter((sd) => {
                return sd.name.toLowerCase().includes(searchText.toLowerCase());
            })
            : sourceData;
    }

    return (
        <OrderRow idx={idx} name={orderRowName} label={orderRowLabel} description={orderRowDescription}>
            <Accordion css={accordionStyles}>
                <AccordionTab headerClassName="targeting-tab" header={header} disabled={disabled}>
                    <div css={accordionTabStyles}>
                        <div className="selection-column">
                            {allowSearch && (
                                <div className="mb-1">
                                    <InputText value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                                </div>
                            )}
                            <div className="top-controls">
                                {allowSearch && searchText.length >= 3 ? <span>Search results:</span> : <span>{sourceData?.length} items</span>}
                                {allowSearch && searchText.length < 3 && (
                                    <span
                                        className="control"
                                        onClick={() => {
                                            handleSelection(order, idx, isSelectionMode ? "select-all" : "remove-all");
                                        }}
                                    >
                                        {isSelectionMode || !targetData.length ? "Include all" : "Exclude all"}
                                    </span>
                                )}
                            </div>
                            {filterSourceData(sourceData, searchText.length >= 3).map((sd, i) => {
                                return (
                                    <ItemTemplate
                                        renderSelectControl={!targetData.length || isSelectionMode}
                                        renderRemoveControl={!targetData.length || !isSelectionMode}
                                        deviceObject={sd}
                                        handleSelect={() => {
                                            handleSelection(order, idx, "select", sd);
                                        }}
                                        handleRemove={() => {
                                            handleSelection(order, idx, "remove", sd);
                                        }}
                                        key={i}
                                    />
                                );
                            })}
                        </div>
                        <div className="results-column">
                            <div className="top-controls" style={{ marginTop: allowSearch ? "4em" : 0 }}>
                                <span>{targetData.length} items selected</span>
                                <span
                                    className="control"
                                    onClick={() => {
                                        handleSelection(order, idx, "clear-all");
                                    }}
                                >
                                    Clear all
                                </span>
                            </div>
                            {targetData.map((td, i) => (
                                <ItemTemplate
                                    deviceObject={td}
                                    renderSelectControl={false}
                                    renderRemoveControl={true}
                                    handleRemove={() => {
                                        handleSelection(order, idx, "clear", td);
                                    }}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </OrderRow>
    );
};

export default OrderRowPanelSelector;
