import { gql, useMutation } from "@apollo/client";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayAdapters } from "../../constants";
import { pushMessage } from "../../reducers/messagingReducer";
import ADP_amazon from "../adapters/ADP_amazon";
import BaseMultiparameterAdapter from "../adapters/components/BaseMultiparameterAdapter";
import MultiparameterInputs from "../adapters/components/MultiparameterInputs";
import LayoutWrapper from "../LayoutWrapper";
import { AMAZON_ADAPTER_UPDATE, CUSTOM_FIELD_UPDATE_MUTATION, SSP_UPDATE_MUTATION } from "../adapters/utils/constants";
import { enforceGlobalParameters, getSspConfigFromAdunits, mergeUpdatedAdapterData, shapeSspData } from "../adapters/utils/functions";
import { clearAdapterMutations } from "../../reducers/adapterMutationReducer";
import { addToPresetBuildList } from "../../reducers/buildReducer";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String) {
        presetAllInfoMutation(input: { id: $id }) {
            clientMutationId
            presetInfo
        }
    }
`;

const CUSTOM_ADAPTER_SETTINGS_MUTATION = gql`
    mutation callAdapterCustomSettingsMutation($id: String, $customAdapterSettings: String, $isPreset: Boolean, $customAdapterBids: String) {
        adapterCustomSettingsMutation(input: { id: $id, customAdapterSettings: $customAdapterSettings, isPreset: $isPreset, customAdapterBids: $customAdapterBids }) {
            presetInfo
        }
    }
`;

const PresetSlotPlacementID = () => {
    const dispatch = useDispatch();
    const [mutationData, setMutationData] = useState(false);
    const [adposition, setAdposition] = useState(false);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [userAvailableAdapters, setUserAvailableAdapters] = useState([]);
    const [availableAdapters, setAvailableAdapters] = useState(null);
    const [amazonPublisherID, setAmazonPublisherID] = useState(null);
    const [amazonA9, setAmazonA9] = useState(false);
    const [rawAdPositions, setRawAdPositions] = useState({});
    const { currentPreset, currentCustomer } = useSelector((state) => state.userInformation);
    const [customAdapterSettings, setCustomAdapterSettings] = useState({});
    const [customAdapterSettingsMutation] = useMutation(CUSTOM_ADAPTER_SETTINGS_MUTATION);
    const [adaptersBidAdjustment, setAdaptersBidAdjustments] = useState({});
    const {global, ...adaptersState} = useSelector(state => state.adapterStateMutationReducer)
    const [fieldUpdateMutation] = useMutation(CUSTOM_FIELD_UPDATE_MUTATION)
    const [domainLevelAdapterListUpdateMutation] = useMutation(SSP_UPDATE_MUTATION)
    const [amazonAdapterUpdateMutation] = useMutation(AMAZON_ADAPTER_UPDATE)

    function resetState() {
        setMutationData(false)
        setAdposition(false)
        setMutationDataCompleted(false)
        setUserAvailableAdapters([])
        setAvailableAdapters([])
        setAmazonPublisherID(null)
        setAmazonA9(false)
        setRawAdPositions({})
        setCustomAdapterSettings({})
        setAdaptersBidAdjustments({})
        dispatch(clearAdapterMutations())
    }

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            resetState()
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentPreset}` },
            });
        }
        return () => {
            dispatch(clearAdapterMutations())
        }
    }, [currentPreset, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo);

        try {
            setAdaptersBidAdjustments(JSON.parse(temp_data.adjust_bid_price) || {});
        } catch {
            setAdaptersBidAdjustments({});
        }

        setCustomAdapterSettings(temp_data.custom_adapter_settings || {});
        setRawAdPositions(temp_data.ad_positions || {});
        setAmazonA9(temp_data.amazon_a9_checkbox);
        setAmazonPublisherID(temp_data.amazon_publisher_id);
        setAdposition(temp_data.ad_positions_for_adapters || []);
        setUserAvailableAdapters(temp_data.adapter_list || []);
        setAvailableAdapters(temp_data.adapter_list_choices.sort());

        const sspConfig = !temp_data.hbIdList ? {} : JSON.parse(temp_data.hbIdList) || {}
        setMutationData(getSspConfigFromAdunits(sspConfig))
        setMutationDataCompleted(true);
    };

    const toggleAdapterSelection = (adapterName) => {
        setUserAvailableAdapters(
            userAvailableAdapters.includes(adapterName)
                ? userAvailableAdapters.filter(adapter => adapter !== adapterName)
                : [...userAvailableAdapters, adapterName]
        )
    }

    const saveCustomAdapterSettings = async () => {
        const response = await customAdapterSettingsMutation({
            variables: {
                id: currentPreset,
                customAdapterSettings: JSON.stringify(customAdapterSettings),
                customAdapterBids: JSON.stringify(adaptersBidAdjustment),
            },
        });
        return response
    }

    const saveAdapterSelectionList = async () => {
        let enabledAdapters = JSON.stringify(userAvailableAdapters)
        try {
            await fieldUpdateMutation({
                variables: {
                    post_id: String(currentPreset),
                    field_name: 'global_prebid_adapter_selection',
                    value: enabledAdapters,
                    parse_value: true
                }
            })
            await domainLevelAdapterListUpdateMutation({
                variables: {
                    customer_id: String(currentCustomer),
                    preset_id: String(currentPreset),
                    enabled_adapters_list: enabledAdapters
                }
            })
        } catch (err) {
            console.log(err)
        }
    }


    const updateSSPConfiguration = async (adaptersConf) => {
        try {
            let adapterData = mergeUpdatedAdapterData(mutationData, adaptersConf)
            adapterData = enforceGlobalParameters(adapterData, global)
            let shapedData = shapeSspData(adapterData, adposition)

            await fieldUpdateMutation({
                variables: {
                    post_id: String(currentPreset),
                    field_name: 'global_header_bidder_configuration',
                    value: JSON.stringify(shapedData)
                }
            })
        } catch (err) {
            return mutationData
        }
    }

    return (
        <LayoutWrapper model="preset" saveCallback={async () => {
            dispatch(pushMessage("Preset Slotplacement IDs", "Saving preset configuration"));
            dispatch(addToPresetBuildList(currentPreset))
            await saveCustomAdapterSettings()
            await saveAdapterSelectionList()
            let {amazon: {publisherID, isEnabled}, ...remainingAdapterState} = adaptersState
            await updateSSPConfiguration(remainingAdapterState)
            await amazonAdapterUpdateMutation({
                variables: {
                    id: String(currentPreset),
                    enableA9: isEnabled,
                    publisherID
                }
            })
        }}>
            {mutationDataCompleted && (
                <div>
                    <div className="child-margin-1">
                        <ADP_amazon publisherID={amazonPublisherID} enableA9={amazonA9} postId={currentPreset} />
                        {availableAdapters.map((adapterName) => {
                            let adapterConfig = displayAdapters[adapterName];
                            return (
                                adapterConfig && (
                                    <BaseMultiparameterAdapter
                                        adapterName={adapterName}
                                        key={adapterName}
                                        settings={mutationData[adapterName] || {}}
                                        adposition={adposition}
                                        adapterList={userAvailableAdapters}
                                        postId={currentPreset}
                                        isPresetAdapter={true}
                                        rawPositionsInfo={rawAdPositions}
                                        customAdapterSettings={customAdapterSettings}
                                        updateCustomAdapterSettings={setCustomAdapterSettings}
                                        adaptersBidAdjustment={adaptersBidAdjustment}
                                        setAdaptersBidAdjustments={setAdaptersBidAdjustments}
                                        render={(props) => <MultiparameterInputs {...props} />}
                                        isAdapterEnabled={(userAvailableAdapters || []).includes(adapterName)}
                                        toggleAdapterSelection={toggleAdapterSelection}
                                        {...adapterConfig}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetSlotPlacementID;
