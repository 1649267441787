/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import LayoutWrapper from "./LayoutWrapper";
import AdSettingsInput from "./helper/AdSettingsInput";
import SectionHeader from "./helper/SectionHeader";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { KEY_VALUE_UPDATE_MUTATION } from "../mutations/mutations";

const KeyValues = () => {
    const { currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const dispatch = useDispatch();
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const [inputList, setInputList] = useState(null);
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('key_values_pair');
    const DOMAIN_SETTINGS_MUTATION = useDomainSettings({
        onCompleted(data) {
            confirm(data);
        },
    })

    const [KEY_VALUE_UPDATE_MUTATION_CONTAINER] = useMutation(KEY_VALUE_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(pushMessage("Key-Value Pairs", "Key-value pairs updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    useEffect(() => {
        if (currentDomain && currentCustomer) {
            DOMAIN_SETTINGS_MUTATION({
                customerId: currentCustomer,
                domainId: currentDomain
            })
        }
    }, [currentDomain, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);

        if (temp_data.key_values === null || typeof temp_data.key_values === "undefined") {
            setInputList([
                {
                    row: 0,
                    name: "",
                    pageurl: "",
                    keyvalue: "",
                    excluded: "",
                },
            ]);
        } else {
            const kvs = [];
            const mappings = {};
            let idx = 0;
            temp_data.key_values.forEach((kvMapping) => {
                const { name, keyvalue, pageurl } = kvMapping;
                if (`${name}-${keyvalue}` in mappings) {
                    const i = mappings[`${name}-${keyvalue}`];

                    kvs[i] = {
                        ...kvs[i],
                        pageurl: `${kvs[i].pageurl}\n${pageurl}`,
                    };
                } else {
                    mappings[`${name}-${keyvalue}`] = idx;
                    kvs.push({ ...kvMapping, row: idx });
                    ++idx;
                }
            });
            setInputList(kvs);
        }

        setMutationDataCompleted(true);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        dispatch(registerScreenChanges("Key-Value Pairs"));
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { name: "", pageurl: "", keyvalue: "", excluded: "" }]);
        dispatch(registerScreenChanges("Key-Value Pairs"));
    };

    const handleChange = (index, inputName, inputVal) => {
        let temp = [...inputList];
        temp[index][inputName] = inputVal;
        setInputList(temp);
        dispatch(registerScreenChanges("Key-Value Pairs"));
    };

    const saveKeyValuList = async () => {
        let lastIndex = 0;
        let kvs = [];
        let mappings = {};
        inputList.forEach((kvMapping) => {
            const { name, keyvalue, pageurl } = kvMapping;
            if (pageurl.includes("\n")) {
                pageurl.split("\n").forEach((page) => {
                    const nameKv = `${name}-${keyvalue}`;
                    const idx = mappings[nameKv];
                    if (nameKv in mappings) {
                        kvs[idx] = {
                            ...kvMapping,
                            pageurl: [...kvs[idx].pageurl, page],
                            row: lastIndex,
                        };
                    } else {
                        mappings[nameKv] = lastIndex;
                        kvs.push({ ...kvMapping, pageurl: [page], row: lastIndex });
                        lastIndex++;
                    }
                });
            } else {
                kvs.push({ ...kvMapping, pageurl: [pageurl], row: lastIndex++ });
            }
        });
        return KEY_VALUE_UPDATE_MUTATION_CONTAINER({
            variables: { id: `${currentDomain}`, keyValueList: JSON.stringify(kvs) },
        });
    };

    const dialogFuncMap = {
        displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, index) => {
        setPositionFinal(index);
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => {
                        onHide(name);
                        handleRemoveClick(positionFinal);
                    }}
                    autoFocus
                />
            </div>
        );
    };

    return (
        <LayoutWrapper model="domain" saveCallback={async () => saveKeyValuList()}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content contact-form">
                        <div className="p-panel p-component">
                            <SectionHeader screenName="Key-values pair">
                                <div className="p-text-bold">Key Values Pair</div>
                            </SectionHeader>
                        </div>
                        <div>
                            <div className="p-datatable p-component p-datatable-gridlines">
                                <div className="p-panel p-component">
                                    <div className="p-panel-content">
                                        <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter("displayConfirmation")} onHide={() => onHide("displayConfirmation")}>
                                            <div className="confirmation-content p-d-flex p-ai-center">
                                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                                <span>Are you sure you want to delete this item?</span>
                                            </div>
                                        </Dialog>
                                        <Accordion className="">
                                            {inputList.map((x, i) => {
                                                return (
                                                    <AccordionTab
                                                        key={i}
                                                        header={
                                                            <React.Fragment>
                                                                <div className="p-d-flex p-jc-between">
                                                                    <div className="p-field-checkbox p-mb-0">
                                                                        <div className="p-text-bold">{inputList[i].name}</div>
                                                                    </div>
                                                                    <Button
                                                                        type="button"
                                                                        icon="pi pi-times"
                                                                        className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                                        tooltip="Remove row"
                                                                        tooltipOptions={{ position: "top" }}
                                                                        onClick={() => onClick("displayConfirmation", i)}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <table>
                                                            <tbody className="p-fluid">
                                                                <tr>
                                                                    <td>
                                                                        <Tooltip target=".adpositiontooltip" />
                                                                        <AdSettingsInput labelfor={"key_values_name" + i} label="Name" value={inputList[i].name} changeEvent={(e) => handleChange(i, "name", e.target.value)} />
                                                                        <div className="p-grid small-margin-bottom-10">
                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                                <label {...tooltip('ad_page_url', true)} for={"key_values_url" + i}>
                                                                                    Ad Page URL
                                                                                </label>
                                                                            </div>
                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                <InputTextarea
                                                                                    id={"key_values_url" + i}
                                                                                    rows={8}
                                                                                    cols={30}
                                                                                    value={inputList[i].pageurl}
                                                                                    onChange={(e) => {
                                                                                        handleChange(i, "pageurl", e.target.value);
                                                                                    }}
                                                                                    autoResize
                                                                                ></InputTextarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-grid small-margin-bottom-10">
                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                                <label {...tooltip('excluded_pages', true)} for={"excluded_values_url" + i}>
                                                                                    Excluded pages
                                                                                </label>
                                                                            </div>
                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                <InputTextarea
                                                                                    id={"excluded_values_url" + i}
                                                                                    rows={8}
                                                                                    cols={30}
                                                                                    value={inputList[i].excluded}
                                                                                    onChange={(e) => {
                                                                                        handleChange(i, "excluded", e.target.value);
                                                                                    }}
                                                                                    autoResize
                                                                                ></InputTextarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-grid small-margin-bottom-10">
                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                                <label {...tooltip('ad_key_and_value', true)} for={"key_values_adKey" + i}>
                                                                                    Ad Key and Value
                                                                                </label>
                                                                            </div>
                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                <InputTextarea id={"key_values_adKey" + i} rows={8} cols={30} value={inputList[i].keyvalue} onChange={(e) => handleChange(i, "keyvalue", e.target.value)} autoResize></InputTextarea>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </AccordionTab>
                                                );
                                            })}
                                        </Accordion>
                                        <div className="p-d-flex p-jc-end p-mt-3">
                                            <Button type="button" label="Add Row" icon="pi pi-plus" tooltip="Add row" tooltipOptions={{ position: "top" }} onClick={handleAddClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader title="Description" />
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Ad Page URL</h6>
                                <p>{helpText('help_text____ad_page_url')}</p>
                                <h6 className="p-text-bold p-mb-0">Ad Key and Value</h6>
                                <p>{helpText('help_text____ad_key_and_value')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default KeyValues;
