import { gql, useMutation } from "@apollo/client";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setCommonDomainData } from "./reducers/commonDomainDataReducer";
import { setStripeAccountInfo } from "./reducers/stripeInfoReducer";
import {
    resetCurrentDomain,
    resetCurrentPreset,
    setAvailableCustomers,
    setAvailableDomains,
    setAvailablePresets,
    setCurrentCustomer,
    setCurrentDomain,
    setCurrentPreset,
    setCustomerLogo,
    setCustomerRole,
    setCustomerToAdminsMappings,
    setCustomerToDomainsMapppings,
    setNavigationMode,
    setUserType,
} from "./reducers/userLoginReducer";
import { getIn } from "./utilities/utils";
import { useCommonDomainInfo } from "./hooks/useCommonDomainInfo";

const PARTNER_MUTATION = gql`
    mutation callpartnerInfoMutation($id: String, $role: String) {
        partnerInfoMutation(input: { id: $id, role: $role }) {
            role
            partnerName
            customerInfo
        }
    }
`;

const DOMAIN_MUTATION = gql`
    mutation callDomainInfoMutation($id: String, $userId: String, $adminRole: Boolean) {
        domainInfoMutation(input: { id: $id, userId: $userId, adminRole: $adminRole }) {
            clientMutationId
            customerInfo
            presetInfo
            industryInfo
        }
    }
`;

const PRESET_MUTATION = gql`
    mutation callPresetInfoMutation($id: String) {
        presetInfoMutation(input: { id: $id }) {
            presetInfo
        }
    }
`;

const presetUrlExclusionList = [
    "/presetadpositions",
    "/presetslotplacementid",
    "/presetadexclusion",
    "/presetlazyload",
    "/presetidentityhub",
    "/preset-video-ad-settings",
    "/presetinfo",
    "/presetsettings"
];

const domainUrlExclusionList = [
    "/adsettings",
    "/adstxt",
    "/adpositions",
    "/slotplacementid",
    "/adexclusion",
    "/keyvalue",
    "/lazyload",
    "/adcpminfo",
    "/nativeads",
    "/customads",
    "/identityhub",
    "/supplychain",
    "/reports",
    "/domainrestoresystem",
    "/video-ad-settings",
    "/performance-preferences",
    "/video-adpositions",
    "/domain-videoplacementids",
    "/video-player-positions",
    "/video-player-injections",
    "/extensions",
    '/header-bidding-settings',
    "/dashboard",
    "/",
    "/domains"
];

const saveToLocalStorage = (k, v) => localStorage.setItem(k, v);
const getFromLocalStorage = (i) => localStorage.getItem(i);
const clearFromLocalStorage = (i) => localStorage.removeItem(i);
const saveCurrentDomain = (domainId) => saveToLocalStorage("currentDomain", domainId);
const saveCurrentCustomer = (customerId) => saveToLocalStorage("currentCustomer", customerId);
const saveCurrentPreset = (presetId) => saveToLocalStorage("currentPreset", presetId);
const getCurrentDomain = () => getFromLocalStorage("currentDomain");
const getCurrentCustomer = () => getFromLocalStorage("currentCustomer");
const getCurrentPreset = () => getFromLocalStorage("currentPreset");

export const DomainsDropdown = ({ hideDropdown }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { domains: domainsInfo, currentDomain: domain, currentPreset: preset, mode, presets, userID, isAdmin, customers, currentCustomer, customerToDomainsMappings, customerToAdminsMappings } = useSelector((state) => state.userInformation);

    const [partnerInfoMutation] = useMutation(PARTNER_MUTATION);
    const [domainInfoMutation] = useMutation(DOMAIN_MUTATION);
    const [presetInfoMutation] = useMutation(PRESET_MUTATION);
    const commonDomainInfoMutation = useCommonDomainInfo()
    const [assignedClients, setAssignedClients] = useState([]);
    const [assignedCustomers, setAssignedCustomers] = useState({});
    const [logos, setLogos] = useState({});

    useEffect(() => {
        let savedDomain = getCurrentDomain();
        let savedCustomer = getCurrentCustomer();
        let savedPreset = getCurrentPreset();
        let isDomainMode = domainUrlExclusionList.includes(window.location.pathname);

        if (isDomainMode) {
            if (currentCustomer && domain) {
                saveCurrentCustomer(currentCustomer);
                saveCurrentDomain(domain);
            } else if (currentCustomer && !domain) {
                dispatch(setCurrentCustomer(currentCustomer));
                //return history.push("/domains");
            } else if (!domain || !currentCustomer) {
                dispatch(setCurrentDomain(savedDomain));
                dispatch(setCurrentCustomer(savedCustomer));
            }
        } else {
            if (currentCustomer && preset) {
                dispatch(setNavigationMode("preset"));
                saveCurrentCustomer(currentCustomer);
                saveCurrentPreset(preset);
                clearFromLocalStorage("currentDomain");
            } else if (currentCustomer && !preset) {
                if (presetUrlExclusionList.includes(window.location.pathname)) {
                    dispatch(setCurrentPreset(savedPreset));
                    return history.push("/presetinfo");
                }
            } else if (!currentCustomer && !preset) {
                dispatch(setNavigationMode("preset"));
                dispatch(setCurrentPreset(savedPreset));
                dispatch(setCurrentCustomer(savedCustomer));
            }
        }
    }, [domain, preset, currentCustomer, mode]);

    useEffect(() => {
        if (!userID) return;
        const isAdmin = localStorage.getItem("role") === "administrator";
        let assignedClients;
        try {
            assignedClients = JSON.parse(localStorage.getItem("assignedClients"));
            assignedClients = assignedClients ? assignedClients : [];
        } catch (err) {
            assignedClients = [];
        }
        setAssignedClients(assignedClients);
        dispatch(setUserType(isAdmin));
        partnerInfoMutation({ variables: { id: userID, role: "administrator" } })
            .then((data) => {
                let customers = JSON.parse(getIn(data, "data", "partnerInfoMutation", "customerInfo"));
                let userRole = JSON.parse(data.data?.partnerInfoMutation?.role || null);

                customers.sort((c1, c2) => c1.title.localeCompare(c2.title));

                if (userRole.role !== "administrator") {
                    if (assignedClients.length >= 2) {
                        customers = customers.filter(({ id }) => assignedClients.includes(id));
                    }
                }

                setLogos(
                    customers?.reduce((acc, curr) => {
                        let { id, image } = curr;
                        acc[id] = image?.url;
                        return acc;
                    }, {})
                );
                setAssignedCustomers(
                    customers?.reduce((acc, curr) => {
                        let { type, id } = curr;
                        acc[id] = type;
                        return acc;
                    }, {})
                );
                dispatch(
                    setStripeAccountInfo(
                        customers.reduce((acc, { id, stripeCustomerId }) => {
                            acc[id] = stripeCustomerId;
                            return acc;
                        }, Object.create(null))
                    )
                );
                dispatch(
                    setAvailableCustomers(
                        customers.reduce((acc, curr) => {
                            const { title: name, id: value } = curr;
                            acc.push({ name, value });
                            return acc;
                        }, [])
                    )
                );

                dispatch(
                    setCustomerToAdminsMappings(
                        customers.reduce((acc, curr) => {
                            acc[curr.id] = new Set(curr.admins || []);
                            return acc;
                        }, {})
                    )
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [userID]);

    function reduceMutationInfo(res, acc) {
        const { title: name, id: value } = acc;
        res.push({ name, value: `${value}` });
        return res;
    }

    useEffect(() => {
        if (domain) {

            commonDomainInfoMutation({ domainId: String(domain) })
                .then((response) => {
                    let domainData = JSON.parse(response.data?.domainCommonInfoMutation?.domainInfo || null);
                    if (domainData) {
                        dispatch(setCommonDomainData(domain, domainData));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [domain]);

    useEffect(() => {
        if (currentCustomer) {
            let agency = assignedCustomers[currentCustomer] === "agency";
            dispatch(setCustomerRole(agency));
            dispatch(setCustomerLogo(logos[currentCustomer]));
            if  (isAdmin) {
                return;
            }
            if (agency) {
                let url = new URL(window.location.href);
                let path = url.pathname.slice(1);
                let paths = {
                    marketplace: true,
                    "creative-orders": true,
                    partners: true,
                    billing: true,
                    creatives: true,
                    "custom-creatives": true,
                };
                if (path in paths) {
                    return;
                }
                history.push("/marketplace");
            }
        }
    }, [assignedCustomers, currentCustomer]);

    useEffect(() => {
        if (!customerToAdminsMappings[currentCustomer]) return;
        if (mode === "domain" && currentCustomer) {
            if (currentCustomer in customerToDomainsMappings) {
                dispatch(setAvailableDomains(customerToDomainsMappings[currentCustomer]));
                return;
            }

            domainInfoMutation({
                variables: {
                    id: currentCustomer,
                    userId: userID,
                    adminRole: localStorage.getItem("admin_role") === "true",
                },
            })
                .then((response) => {
                    let customerInfo = JSON.parse(getIn(response, "data", "domainInfoMutation", "customerInfo"));
                    const domainIds = new Set();
                    customerInfo = [...customerInfo].filter((c) => {
                        if (domainIds.has(c.id)) return false;
                        domainIds.add(c.id);
                        return true;
                    });
                    const availableDomains = customerInfo
                        .filter((domainData) => {
                            if (isAdmin || customerToAdminsMappings[currentCustomer]?.has(parseInt(userID))) {
                                return true;
                            }
                            if (Array.isArray(domainData.user_mapping_list)) {
                                try {
                                    let allowedUsers = new Set(domainData.user_mapping_list.map((data) => data?.user_name_with_domain?.ID));
                                    return allowedUsers.has(parseInt(userID));
                                } catch (err) {
                                    return false;
                                }
                            }
                            return false;
                        })
                        .reduce(reduceMutationInfo, [])
                        .sort((d1, d2) => d1.name.localeCompare(d2.name));

                    dispatch(setAvailableDomains(availableDomains));
                    dispatch(setCustomerToDomainsMapppings(currentCustomer, availableDomains));
                })
                .catch((err) => {
                    dispatch(setAvailableDomains([]));
                });
        } else if (mode === "preset" && currentCustomer) {
            presetInfoMutation({
                variables: { id: currentCustomer },
            }).then((response) => {
                const data = JSON.parse(getIn(response, "data", "presetInfoMutation", "presetInfo"));
                const availablePresets = data?.presetInfo?.reduce(reduceMutationInfo, []);
                dispatch(setAvailablePresets(availablePresets));
            });
        }
    }, [currentCustomer, mode, domain, customerToAdminsMappings]);

    const renderDropdowns = (mode, presets, domains, currentPreset, currentDomain) => {
        /* URLs that are not related to domains nor presets */

        let excludedUrls = [
            "/creatives",
            "/creative-orders",
            "/custom-creatives",
            "/marketplace",
            "/marketplace-orders",
            "/invoices",
        ];


        return excludedUrls.includes(window.location.pathname) ? null : (
            <Dropdown
                className="domains-dropdown"
                dropdownIcon="pi pi-caret-down"
                name="domains-dropdown"
                options={mode === "domain" ? domains : presets}
                value={mode === "domain" ? `${currentDomain}` : currentPreset}
                onChange={(e) => {
                    if (mode === "preset") {
                        dispatch(setCurrentPreset(e.value));
                        history.push(`/presetsettings`);
                    } else {
                        dispatch(setCurrentDomain(e.value));
                        history.push(`/adsettings`);
                    }
                }}
                optionLabel="name"
                placeholder={mode === "preset" ? "Choose a preset" : "Choose a domain"}
            />
        );
    };

    return (
        <div className="domains-dropdown-container">
            {isAdmin || assignedClients.length >= 2 ? (
                <div className="admin-dropdowns">
                    <Dropdown
                        className="customers-dropdown"
                        dropdownIcon="pi pi-caret-down"
                        name="customers-dropdown"
                        options={customers}
                        value={currentCustomer || ""}
                        onChange={(e) => {
                            dispatch(setCurrentCustomer(e.value));
                            dispatch(resetCurrentPreset());
                            dispatch(resetCurrentDomain());
                        }}
                        optionLabel="name"
                        placeholder="Choose a customer"
                    />
                    {currentCustomer && !hideDropdown && renderDropdowns(mode, presets, domainsInfo, preset, domain)}
                </div>
            ) : (
                renderDropdowns(mode, presets, domainsInfo, preset, domain)
            )}
        </div>
    );
};
