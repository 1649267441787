import React from 'react'
import AdNativeInjection from './AdNativeInjection'

const DisplayAdsInjection = () => {
    return (
        <AdNativeInjection 
            mode="display"
        />
    )
}

export default DisplayAdsInjection