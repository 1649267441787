import deepcopy from 'deepcopy'

/**
 * 
 * @param {Array} rules - An array traffic shaping rules
 * @returns {any} - Result of folding the rules passed as an argument. 
 */

export function foldRules(rules) {
    let clone = deepcopy(rules)
    return clone.reduce((acc, curr) => {
        acc[curr.bidder] = acc[curr.bidder] ? acc[curr.bidder] : {conditions: []}
        acc[curr.bidder].conditions.push(curr)

        // re-shape 'begin' and 'end' properties into a timeRange POJO

        if (curr.begin || curr.end) {
            curr.timeRange = {
                begin: curr.begin,
                end: curr.end
            }
            delete curr.begin
            delete curr.end
        }
        
        // deletes entries
        Object.keys(curr.conditionMappings).forEach(cond => {
            delete curr[cond]
        })
        
        // deletes entries that have empty values
        for (let p in curr) {
            if (Array.isArray(curr[p]) && !curr[p].length) {
                delete curr[p]
            } else if (typeof curr[p] === 'string' && !curr[p].length) {
                delete curr[p]
            } else if (typeof curr[p] === 'object' && !Object.keys(curr[p]).length) {
                delete curr[p]
            }
        }
        
        delete curr.conditionMappings
        delete curr.bidder

        if (curr.postal_code) {
            curr.postal_code = curr.postal_code.split(',').map(pc => {
                return pc.trim()
            })
        }
        
        return acc
    }, Object.create(null))
}