import React, { useState, useRef } from "react";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import "primereact/resources/themes/saga-blue/theme.css";
import SectionHeader from "./helper/SectionHeader";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import { InputText } from "primereact/inputtext";

import { gql, useMutation } from "@apollo/client";
import { pushMessage } from "../reducers/messagingReducer";
import { useDispatch } from "react-redux";
import LayoutWrapper from "./LayoutWrapper";

const SAVE_USER_SETTINGS_MUTATION = gql`
    mutation saveUserSettingsMutation($userData: [String]) {
        saveUserSettingsMutation(input: { userData: $userData }) {
            logout
            clientMutationId
            success
            message
        }
    }
`;

const UserProfile = () => {
    const messages = useRef(null);
    const dispatch = useDispatch();
    const [settingsData, setSettingsData] = useState({ userId: localStorage.getItem("userID"), first_name: localStorage.getItem("userFname"), last_name: localStorage.getItem("userLname"), actual_user_name: localStorage.getItem("actualUserName") });

    const handleChange = (param) => {
        let name = param.target.name,
            value = param.target.value,
            temp = Object.assign({}, settingsData);
        temp[name] = value;
        setSettingsData(temp);
    };

    const updateUserDetails = async () => {
        if (settingsData.length === 0) {
            dispatch(pushMessage("User profile", "No data was entered", "error"));
            throw new Error("No data was entered");
        } else if (!settingsData["first_name"]) {
            dispatch(pushMessage("User profile", "Please enter First Name", "error"));
            throw new Error("Please enter First Name");
        } else if (!settingsData["last_name"]) {
            dispatch(pushMessage("User profile", "Please enter Last name", "error"));
            throw new Error("Please enter Last name");
        } else {
            if (settingsData["old_password"] && (!settingsData["new_password"] || !settingsData["confirm_new_password"])) {
                dispatch(pushMessage("User profile", "If you want to update password then fill all password fields. Or clear Old Password.", "error"));
                throw new Error("If you want to update password then fill all password fields. Or clear Old Password.");
            } else if ((settingsData["new_password"] || settingsData["confirm_new_password"]) && !settingsData["old_password"]) {
                dispatch(pushMessage("User profile", "Enter Old Pasword or Clear new Passwords", "error"));
                throw new Error("Enter Old Pasword or Clear new Passwords");
            } else if (settingsData["new_password"] != settingsData["confirm_new_password"]) {
                dispatch(pushMessage("User profile", "Passwords do not Match", "error"));
                throw new Error("Passwords do not match");
            } else if (settingsData["old_password"] && settingsData["new_password"] && settingsData["old_password"] === settingsData["new_password"]) {
                dispatch(pushMessage("User profile", "Old and new passwords are the same", "error"));
                throw new Error("Old and new passwords are the same");
            } else {
                return SAVE_USER_SETTINGS_MUTATION_CONTAINER({
                    variables: {
                        userData: JSON.stringify(settingsData),
                    },
                });
            }
        }
    };

    const [SAVE_USER_SETTINGS_MUTATION_CONTAINER, { dataUpdate }] = useMutation(SAVE_USER_SETTINGS_MUTATION, {
        onCompleted(dataUpdate) {
            confirmUpdate(dataUpdate);
        },
    });

    const confirmUpdate = /*async*/ (data) => {
        let sms = data["saveUserSettingsMutation"]["message"];
        if (data["saveUserSettingsMutation"]["success"] === "true") {
            dispatch(pushMessage("User profile", "User information was successfully updated"));
            if (data["saveUserSettingsMutation"]["logout"] === "logout") {
                setTimeout(logOut, 3000);
            } else if (data["saveUserSettingsMutation"]["logout"] === "refresh") {
                localStorage.setItem("userFname", settingsData.first_name);
                localStorage.setItem("userLname", settingsData.last_name);
                localStorage.setItem("stUserAvatar", settingsData.user_avatar ? settingsData.user_avatar : "");
            } else {
                alert("Something went wrong!!!");
            }
        } else {
            dispatch(pushMessage("User profile", `User info update failed: ${sms}`, "error"));
        }
    };

    const logOut = () => {
        localStorage.clear();
        window.location.href = "./login";
    };

    return (
        <>
            <LayoutWrapper model="domain">
                <Messages ref={messages} />
                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-8 contact-form">
                        <div className="p-panel p-component">
                            <SectionHeader title="User Profile" screenName="User Profile" saveCallback={updateUserDetails}>
                                <div className="p-text-bold">User Settings</div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <div className="p-grid small-margin-bottom-10">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label htmlFor={"user_name"}>Username</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputText disabled id={"user_name"} name="user_name" value={settingsData.actual_user_name} />
                                    </div>
                                </div>
                                <div className="p-grid small-margin-bottom-10">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label htmlFor={"first_name"}>First Name*</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputText id={"first_name"} name="first_name" value={settingsData.first_name} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                                <div className="p-grid small-margin-bottom-10">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label htmlFor={"last_name"}>Last Name*</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputText id={"last_name"} name="last_name" value={settingsData.last_name} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                                <div className="p-grid small-margin-bottom-10">
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label htmlFor={"old_password"}>Old Password</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <Password id={"old_password"} name="old_password" value={settingsData.old_password} onChange={(e) => handleChange(e)} feedback={false} />
                                    </div>
                                </div>
                                <>
                                    <div className="p-grid small-margin-bottom-10">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                            <label htmlFor={"new_password"}>New Password</label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                            <Password id={"new_password"} name="new_password" value={settingsData.new_password} onChange={(e) => handleChange(e)} promptLabel={"Enter New Password"} />
                                        </div>
                                    </div>
                                    <div className="p-grid small-margin-bottom-10">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                            <label htmlFor={"confirm_new_password"}>Confirm New Password</label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                            <Password id={"confirm_new_password"} name="confirm_new_password" value={settingsData.confirm_new_password} onChange={(e) => handleChange(e)} promptLabel={"Confirm New Password"} feedback={false} />
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default UserProfile;
