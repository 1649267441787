/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { MarketplaceGamDataContext } from "../../../context/marketplaceGamDataContext";
import MarketplaceOrderDataContext from "../../../context/marketplaceOrderDataContext";

const MarketplaceSidebarInfo = ({
    sidebarStyles,
    audienceTargeting,
    audienceTargetingRates
}) => {
    const { currentOrder: order, customerRate: rate } = useContext(MarketplaceOrderDataContext)
    const { impressionGoals, lineItemId, CPM } = order
    const { gamStats } = useContext(MarketplaceGamDataContext)
    const hasStats = lineItemId && lineItemId in gamStats
    const impressionsDelivered = hasStats
        ? gamStats[lineItemId].impressionsDelivered
        : 0
    
    let lineItemCpm = hasStats
        ? gamStats[lineItemId].costPerUnit / 1e6
        : CPM || 0
    let decimalRate = rate / 100
    let currentSpend = ((impressionsDelivered || 0) * lineItemCpm) / 1e3
    let estimatedSpend = (impressionGoals * CPM) / 1e3

    let estimatedCensusTargetingSpend = null;
    let censusTargetingSpend = 0

    if (audienceTargeting && audienceTargeting.length) {
        let rate = audienceTargetingRates[audienceTargeting[0].key]
        
        let estimate = hasStats
            ? (impressionsDelivered * rate / 1e3).toFixed(2)
            : (impressionGoals * rate / 1e3).toFixed(2)

        
        censusTargetingSpend = Number(estimate)

        estimatedCensusTargetingSpend = (
            <div>
                <p>Advanced Targeting (${rate} CPM)</p>
                <p className="calc">${Number(estimate).toLocaleString()}</p>
            </div>
        )
    }

    let total = hasStats
        ? (currentSpend * (1 + decimalRate) + censusTargetingSpend).toFixed(2)
        : (estimatedSpend * (1 + decimalRate) + censusTargetingSpend).toFixed(2)

    return (
        <div>
            <div className="marketplace-sidebar-info" css={sidebarStyles}>
                {hasStats
                    ? (
                        <section className="current-spend">
                            <div>
                                <p>Impressions Delivered</p>
                                <p className="calc">{(impressionsDelivered || 0).toLocaleString()}</p>
                            </div>
                            <div>
                                <p>Current Spend</p>
                                <p className="calc">${(currentSpend).toFixed(2).toLocaleString()}</p>
                            </div>
                            <div>
                                <p>Placement Fee {rate}%</p>
                                <p className="calc">${(currentSpend * decimalRate).toFixed(2).toLocaleString()}</p>
                            </div>
                            {
                                estimatedCensusTargetingSpend
                            }
                            <div>
                                <p>Total</p>
                                <p className="calc">${Number(total).toLocaleString()}</p>
                            </div>
                        </section>
                    )
                    : (
                        <section className="estimated-spend">
                            <div>
                                <p>Estimated Spend</p>
                                <p className="calc">${(estimatedSpend).toLocaleString()}</p>
                            </div>
                            <div>
                                <p>Placement Fee {rate}%</p>
                                <p className="calc">${(estimatedSpend * decimalRate).toLocaleString()}</p>
                            </div>
                            {
                                estimatedCensusTargetingSpend
                            }
                            <div>
                                <p>Total</p>
                                <p className="calc">${Number(total).toLocaleString()}</p>
                            </div>
                        </section>
                    )
                }
            </div>
        </div>
    );
};

export default MarketplaceSidebarInfo;
