import React, { useState, useEffect, useRef } from 'react';
import 'react-list-editable/lib/react-list-editable.css';
import { CSSTransition } from 'react-transition-group';
import {InputSwitch} from 'primereact/inputswitch';
import { AppTopbar } from '../AppTopbar';
import { AppMenu } from '../AppMenu';
import { DomainsDropdown } from '../DomainsDropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '../layout/flags/flags.css';
import '../layout/layout.scss';
import '../App.scss';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { wrapperClass, sidebarClassName } from '../constants'
import { gql, useMutation } from '@apollo/client';
import AppLogo from './AppLogo';

const DOMAIN_MUTATION = gql`
  mutation callDomainInfoMutation($id: String, $customerID: String) {
    presetDomainsInfoMutation(input: {id: $id, customerID: $customerID}) {
        clientMutationId
    }
  }
`
const PRESET_UPDATE_MUTATION = gql`
  mutation callUpdatePresetConfMutation($id: String, $presetList: String) {
    updatePresetConfMutation(input: {id: $id, presetList: $presetList}) {
        networkId
    }
  }
`


const PresetDomainsInfo = (props) => {

    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [listDataOptions, setListDataOptions] = useState(null);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);

    const messages = useRef(null);
    let i =0;

    const handleChange = (param) => {
        let temp = [...listDataOptions];
        temp[param.target.id].checked = !temp[param.target.id].checked
        setListDataOptions(temp)
    };

    const addMessages = (message) => {
        messages.current.show([
          {
            severity: "success",
            summary: "Success.",
            detail: message,
            sticky: false
          }
        ]);
    };


    const sidebar = useRef();

    const [DOMAIN_MUTATION_CONTAINER, { loading: mutationLoading, error: mutationError, data }] = useMutation(
        DOMAIN_MUTATION,
        {
            onCompleted(data) {
              confirm(data);
            }
          }
        );

    const [PRESET_UPDATE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
        PRESET_UPDATE_MUTATION,
        {
            onCompleted(dataUpdate) {
                addMessages("Preset Updated.")
            }
            }
        );

    useEffect(() => {
        DOMAIN_MUTATION_CONTAINER({
            variables: { id: localStorage.getItem('presetID'), customerID: localStorage.getItem('clientID') }
        });
    }, []);

    const confirm =  data => {
        setListDataOptions(data[0].presetInfo)
        setMutationDataCompleted(true)
    }

    const onMenuItemClick = () => {

    }


    const savePresetConf = () => {
        PRESET_UPDATE_MUTATION_CONTAINER({
            variables: { id: localStorage.getItem('presetID'), presetList: JSON.stringify(listDataOptions)}
        });
    }


    return (
        <div className="center w85">
            {mutationDataCompleted && (
                <div className={wrapperClass} >
                <AppTopbar  />

                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} >
                        <AppLogo />
                        <DomainsDropdown />
                        <AppMenu model={'preset'} onMenuItemClick={onMenuItemClick} clientInfo={props.clientInfo} sourceData={props.sourceData}/>
                    </div>
                </CSSTransition>

                <div className="layout-main layout-main p-panel p-component">
                    <Messages ref={messages} />
                    <div className="p-panel-header">
                      <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => savePresetConf()} />
                    </div>
                        <div className="p-panel-content">
                        <div>Domains</div>
                          <div className="p-grid">
                              
                            {
                                listDataOptions.map((item) => {
                                    return (
                                        <div key={item.id} className="p-field-checkbox p-col-4">
                                            {/*<Checkbox
                                                inputId={item}
                                                name={item} value={item}
                                                onChange={onListChange}
                                                checked={domainData.some((itm) => itm === item)}
                                                disabled={item === 'R'} />
                                            <label htmlFor={item}>{item}</label>*/}
                                            <div className="p-col-12">
                                                <div className="p-d-flex p-ai-center">
                                                    <InputSwitch
                                                        id={i++}
                                                        name={item.title}
                                                        checked={ item.checked }
                                                        onChange={ handleChange }/>
                                                    <label className="p-ml-2">{item.title}</label>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                      </div>
                </div>
            </div>
            )}

        </div>
    );

}

export default PresetDomainsInfo;
