/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { gql } from "@apollo/client";

export const colors = {
    salmon: '#F08819',
    lightBlue: '#2196f3',
    successGreen: '#4caf50',
    white: '#fff'
}

export const DELETE_ASSET_MUTATION = gql`
    mutation callCreativeAssetDeleteMutation($id: String, $filename: String, $isMarketPlace: Boolean) {
        creativeAssetDeleteMutation(input: { id: $id, filename: $filename, isMarketPlace: $isMarketPlace }) {
            mutationResult
        }
    }
`;

export const MARKETPLACE_ORDERS_MUTATION = gql`
    mutation callCustomOrdersMutation($id: String, $isMarketPlace: Boolean, $customerName: String, $page: String) {
        customOrdersMutation(input: { id: $id, isMarketPlace: $isMarketPlace, customerName: $customerName, page: $page }) {
            ordersInfo
            customerToDomainMappings
            total_posts
            customerRate
        }
    }
`;

export const MARKETPLACE_ORDERS_CREATION_MUTATION = gql`
    mutation callCreateMarketplaceOrderMutation($id: String, $customerName: String, $marketplaceOrder: String) {
        createMarketplaceOrderMutation(input: { id: $id, customerName: $customerName, marketplaceOrder: $marketplaceOrder }) {
            gamApiResponse
        }
    }
`;

export const MARKETPLACE_VIDEO_ORDER_CREATION_MUTATION = gql`
    mutation callCreateMarketplaceVideoOrderMutation($id: String, $customerName: String, $marketplaceOrder: String) {
        createMarketplaceVideoOrderMutation(input: { id: $id, customerName: $customerName, marketplaceOrder: $marketplaceOrder }) {
            gamApiResponse
        }
    }
`;

export const UPDATE_MARKETPLACE_ORDER_MUTATION = gql`
    mutation callUpdateMarketplaceOrderMutation($marketplaceOrder: String) {
        updateMarketplaceOrderMutation(input: { marketplaceOrder: $marketplaceOrder }) {
            gamApiResponse
        }
    }
`;

export const UPDATE_MARKETPLACE_VIDEO_ORDER_MUTATION = gql`
    mutation callUpdateMarketplaceVideoOrderMutation($marketplaceOrder: String) {
        updateMarketplaceVideoOrderMutation(input: { marketplaceOrder: $marketplaceOrder }) {
            gamApiResponse
        }
    }
`;

export const WP_MARKETPLACE_ORDERS_MUTATION = gql`
    mutation callSaveWpMarketplaceOrderMutation($id: String, $customerName: String, $marketplaceOrder: String, $paymentMethod: String, $isDraft: Boolean) {
        saveWpMarketplaceOrderMutation(input: { id: $id, customerName: $customerName, marketplaceOrder: $marketplaceOrder, paymentMethod: $paymentMethod, isDraft: $isDraft }) {
            response
        }
    }
`;

export const LINE_ITEM_STATS_MUTATION = gql`
    mutation callGetLineItemStatsMutation($lineItemIdsList: String) {
        getLineItemStatsMutation(input: { lineItemIdsList: $lineItemIdsList }) {
            gamApiStats
        }
    }
`;

export const CUSTOMER_LINE_ITEM_STATS_MUTATION = gql`
    mutation callGetLineItemStatsMutation($lineItemIdsList: String, $isCustomerNetwork: Boolean, $gamNetworkCode: String) {
        getLineItemStatsMutation(input: { lineItemIdsList: $lineItemIdsList, isCustomerNetwork: $isCustomerNetwork, gamNetworkCode: $gamNetworkCode }) {
            gamApiStats
        }
    }
`;

export const GET_GEOTARGETS_INFO_MUTATION = gql`
    mutation callGetGeoTargetsInfoMutation {
        getGeoTargetsInfoMutation(input: {}) {
            gamApiResponse
        }
    }
`;

export const MARKETPLACE_ACTION_MUTATION = gql`
    mutation callSetMarketplaceOrderActionMutation($networks: String, $action: String) {
        setMarketplaceOrderActionMutation(input: { networks: $networks, action: $action }) {
            gamApiResponse
        }
    }
`;

export const MARKETPLACE_ORDERS_REVIEW_MUTATION = gql`
    mutation callCreateCustomerMarketplaceDomainReviewMutation($domainIds: String, $domainStatus: String, $marketplace_order_id: String) {
        createCustomerMarketplaceDomainReviewMutation(input: { domainIds: $domainIds, domainStatus: $domainStatus, marketplace_order_id: $marketplace_order_id }) {
            domainReviewStatus
        }
    }
`;

export const CUSTOMER_MARKETPLACE_ORDER_CREATION = gql`
    mutation callCreateCustomerMarketplaceOrderMutation($id: String, $marketplacePostID: String, $domainId: String) {
        createCustomerMarketplaceOrderMutation(input: { id: $id, marketplacePostID: $marketplacePostID, domainId: $domainId }) {
            gamApiResponse
        }
    }
`;

export const CUSTOMER_MARKETPLACE_VIDEO_ORDER_CREATION = gql`
    mutation callCreateCustomerMarketplaceVideoOrderMutation($id: String, $marketplacePostID: String, $domainId: String) {
        createCustomerMarketplaceVideoOrderMutation(input: { id: $id, marketplacePostID: $marketplacePostID, domainId: $domainId }) {
            gamApiResponse
        }
    }
`;

export const SET_MARKETPLACE_ORDER_AS_DRAFT = gql`
    mutation callUpdateWpMarketplaceOrderMutation($marketplaceOrder: String, $action: String) {
        updateWpMarketplaceOrderMutation(input: { marketplaceOrder: $marketplaceOrder, action: $action }) {
            response
        }
    }
`;

export const SELLER_MARKETPLACE_ORDERS = gql`
    mutation callGetSellerMarketplaceOrdersMutation($id: String, $offset: Int) {
        getSellerMarketplaceOrdersMutation(input: { id: $id, offset: $offset }) {
            sellerOrders
            total_orders
        }
    }
`;

export const CSV_DATA_DOWNLOAD = gql`
    mutation callDownloadCsvDataMutation($csv: String, $filename: String) {
        downloadCsvDataMutation(input: { csv: $csv, filename: $filename }) {
            downloadUrl
        }
    }
`;

export const CAMPAIGN_BREAKDOWN_MUTATION = gql`
    mutation callGetBreakdownDataMutation($lineItemId: String) {
        getBreakdownDataMutation(input: { lineItemId: $lineItemId }) {
            breakdownData
        }
    }
`;

export const MARKETPLACE_ORDER_SSP_REGISTRATION = gql`
    mutation callMarketplaceOrderRegistrationMutation($postId: String, $geographies: String) {
        marketplaceOrderRegistrationMutation(input: { postId: $postId, geographies: $geographies }) {
            response
        }
    }
`

export const MARKETPLACE_ORDER_SSP_UPDATE = gql`
    mutation callMarketplaceOrderSSPUpdateMutation($orderId: String, $data: String) {
        marketplaceOrderSSPUpdateMutation(input: { orderId: $orderId, data: $data }) {
            response
        }
    }
`

export const LINEITEM_STATUS = {
    DELIVERY_EXTENDED: "DELIVERY_EXTENDED",
    DELIVERING: "DELIVERING",
    READY: "READY",
    PAUSED: "PAUSED",
    INACTIVE: "INACTIVE",
    PAUSED_INVENTORY_RELEASED: "PAUSED_INVENTORY_RELEASED",
    PENDING_APPROVAL: "PENDING_APPROVAL",
    COMPLETED: "COMPLETED",
    DISAPPROVED: "DISAPPROVED",
    DRAFT: "DRAFT",
    CANCELED: "CANCELED",
};

export const textInputStyle = css`
    input,
    .p-inputtext {
        display: flex;
        align-items: center;
        min-height: 3em;
        &:focus {
            border-color: #666;
            border: 2px solid #000;
            border-radius: 3px;
            box-shadow: initial !important;
        }
        &:enabled:hover {
            border-color: #666;
        }
    }
    .switch-container {
        display: flex;
        align-items: center;
        margin-top: 1em;
        margin-left: 0.5em;
        .p-inputswitch {
            margin-right: 1em;
        }
    }
    .network-selectables {
        .p-selectbutton[role="group"] {
            flex-wrap: wrap;
            > div {
                margin-left: 6px;
                margin-right: 6px;
                margin-bottom: 6px;
                border: none;
                flex-basis: 150px;
                flex-grow: inherit;
                border-radius: 9px;
                .p-button-label.p-c {
                    font-size: 14px;
                    color: #495057;
                }
                &:not(.p-highlight) {
                    background-color: #e91e63;
                    .p-button-label.p-c {
                        color: #fff;
                    }
                }
                &.p-highlight {
                    background-color: #9e9e9e;
                    background-color: #fff;
                    border-radius: 9px;
                    border: 1px solid #666;
                }
                &:hover {
                    background-color: #e9ecef;
                    border-color: #ced4da;
                    color: #495057;
                    .p-button-label.p-c {
                        color: #000;
                    }
                }
            }
        }
    }
`;

export const accordionTabHeaderStyles = css`
    display: flex;
    align-items: center;
    .remove-row-btn {
        margin-left: auto;
    }
    .info {
        margin-bottom: 0;
        margin-right: 1em;
        font-size: 12px;
        span {
            font-weight: normal;
        }
        &:first-of-type {
            font-size: 14px;
            margin-bottom: 6px;
        }
        .order-name {
            margin-right: 0.5em;
        }
        .format-display,
        .format-video {
            padding: 0.15em 0.5em;
            color: #fff;
            border-radius: 3px;
            text-transform: capitalize;
        }
        .format-display {
            background-color: ${colors.lightBlue};
        }
        .format-video {
            background-color: ${colors.salmon};
        }
    }
    .inline-block {
        display: inline-block;
    }
    .badge {
        color: #689f38;
        padding: 3px;
        border-radius: 3px;
    }
    .pi-undo {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .top-badge {
        padding: 1px;
        border-radius: 3px;
        background-color: #3dca42;
        color: #fff;
        display: inline-block;
        margin-left: 1em;
    }
    .editing-disabled {
        background-color: #ffc062 !important;
        color: #000;
    }
    .editing-enabled {
        background-color: #ffc062 !important;
        color: #000;
    }
`;

export const accordionStyles = css`
    .invalid > a {
        background-color: #fca9a3 !important;
        color: #fff !important;
    }
    .to-archive > a {
        background-color: #ffecb3 !important;
        &:focus {
            box-shadow: 0 0 0 0.2rem #ffc107 !important;
        }
    }
    .in-progress > a {
        background-color: #c2eac3 !important;
        color: #000 !important;
    }
    .in-progress.created > a {
        background-color: #b3e5fc !important;
    }
    .in-progress.pausing > a {
        background-color: #fce6b3 !important;
    }
    .p-accordion-tab {
        margin-bottom: 0.75em;
        border: 1px solid #ccc;
    }
`;

export const marketplaceSidebarInfoStyle = css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 2em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 6px;
    section {
        margin-bottom: 1em;
        padding: 0 1em;
        &.current-spend,
        &.estimated-spend {
            padding: 1em 0;
        }
        &.current-spend {
            border-bottom: 2px inset #ccc;
        }
        p {
            text-align: center;
            font-size: 18px;
            margin-bottom: 0;
            color: #666;
            &.calc {
                font-size: 21px;
                font-weight: bold;
            }
        }
        &.selected-domains {
            .selected-domain {
                padding: 0.25em;
                display: inline-block;
                margin: 0.25em;
                border-radius: 6px;
                font-weight: 600;
                .pi {
                    padding-right: 0.75em;
                }
                &.pending {
                    background-color: yellow;
                }
            }
        }
    }
`;

export const marketplaceOrderOperationStyle = css`
    background-color: #000;
    color: #fff;
    padding: 6px 21px;
    border-radius: 6px;
    margin-left: auto;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        display: inline-block;
        margin-left: 1em;
    }
`;

export const rowNoticeBlockStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const rowNoticeStyle = css`
    display: inline-block;
    margin-left: 0.5em;
    background-color: rgb(33, 150, 243);
    color: #fff;
    border-radius: 3px;
    padding: 0.15em 0.5em;
    font-weight: 400;
    font-size: 14px;
`

export const MARKETPLACE_DOMAIN_STATUS = {
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
    PAUSED_BY_SELLER: "PAUSED_BY_SELLER",
    PAUSED_BY_BUYER: "PAUSED_BY_BUYER",
    DELIVERING: "DELIVERING",
    RESUMED: "RESUMED",
    RESUMED_BY_SELLER: "RESUMED_BY_SELLER",
    RESUMED_BY_BUYER: "RESUMED_BY_BUYER",
};

export const MARKETPLACE_DOMAIN_STATUS_WORDINGS = {
    PENDING: "Pending approval",
    REJECTED: "Rejected by seller",
    APPROVED: "Approved by seller",
    PAUSED_BY_SELLER: "Paused by seller",
    PAUSED_BY_BUYER: "Paused by buyer",
    DELIVERING: "Delivering",
    RESUMED: "Resumed",
    RESUMED_BY_SELLER: "Resumed by seller",
    RESUMED_BY_BUYER: "Resumed by buyer",
};

export const LINEITEM_ACTIONS = {
    PAUSE: "PAUSE",
    RESUME: "RESUME",
};

export const MARKETPLACE_ORDER_ACTIONS = {
    ARCHIVE: "ARCHIVE",
};

export const ADGRID_GAM_NETWORK_CODE = 92510019;

export const TABLE_STYLES = css`
    width: 100%;
    border-collapse: collapse;
    .domain-ctas {
        display: flex;
        justify-content: end;
        button {
            margin-left: 1em;
        }
    }
    thead {
        background-color: #000;
        color: #eee;
        tr {
            text-transform: uppercase;
            text-align: left;
            th {
                padding: 0.5em 1em;
                border: none;
            }
        }
    }
    tr {
        border-bottom: 1px solid #000;
        td {
            padding: 0.5em 1em;
            text-transform: uppercase;
        }
        td:first-of-type {
            padding-left: 0;
        }
        td:last-of-type {
            padding-right: 0;
        }
        &:even {
            background-color: #efefef;
        }
        &:last-of-type {
            border-bottom: none;
        }
        .PENDING,
        .PAUSED_BY_SELLER,
        .PAUSED_BY_BUYER {
            color: #fbc02d;
        }
        .REJECTED {
            color: #ff5062;
        }
        .APPROVED {
            color: #5ba457 !important;
        }
        .RESUMED,
        .RESUMED_BY_BUYER,
        .RESUMED_BY_SELLER {
            color: #00aeef !important;
        }
    }
    tbody {
        tr {
            cursor: pointer;
            &:hover {
                background-color: #e6f6e6;
            }
            td {
                font-weight: 600;
            }
        }
    }
`;

const A_MONTH_FROM_NOW = new Date();
A_MONTH_FROM_NOW.setDate(A_MONTH_FROM_NOW.getDate() + 30);

export const DEFAULT_MARKETPLACE_ORDER = {
    name: "",
    description: "",
    network: null,
    creatives: [],
    thirdPartytagCreatives: [],
    domainToLineItemsMappings: {},
    domainIdToDomainNameMappings: {},
    toDelete: [],
    CPM: 1,
    impressionGoals: 500_000,
    startDate: +new Date(),
    endDate: +A_MONTH_FROM_NOW,
    lineItemId: "",
    GAMorderId: "",
    lineItemSizes: [],
    displaySizes: [],
    pristine: true,
    toArchive: false,
    networks: [],
    selectDeviceMode: true,
    targetDevices: [],
    selectCountryMode: true,
    includedGeographies: [],
    excludedGeographies: [],
    paymentMethod: "",
    networkStatuses: {},
    draftUpdated: false,
    publishPending: false,
    notifyCustomers: false,
    format: "" /* "display" | "video" */,
    vast: "",
    duration: 0,
    pav: false,
    gmav: false,
    lineitemIdsToDomainNameMappings: {},
    enableDailyCap: false,
    dailyCap: 1000,
    orderPausedByAgentScript: false,
    lineItemsPausedByAgentScript: [],
    audienceTargeting: [],
    advertiserUrl: '',
    iabCategory: null
};

export const DISPLAY_SIZES = [
    { name: '1x1 (Custom)', value: '1x1' },
    { name: '88x31 (Micro Bar)', value: '88x31' },
    { name: '120x20 (MMA Small Banner)', value: '120x20' },
    { name: '120x30 (MMA Small Banner)', value: '120x30' },
    { name: '120x60 (Button 2)', value: '120x60' },
    { name: '120x90 (Button 1)', value: '120x90' },
    { name: '120x240 (Vertical Banner)', value: '120x240' },
    { name: '120x600 (Skyscraper)', value: '120x600' },
    { name: '125x125 (Button)', value: '125x125' },
    { name: '160x360 (Custom)', value: '160x360' },
    { name: '160x600 (Wide Skyscraper)', value: '160x600' },
    { name: '168x28 (MMA Medium Banner)', value: '168x28' },
    { name: '168x42 (MMA Medium Banner)', value: '168x42' },
    { name: '180x150 (Rectangle)', value: '180x150' },
    { name: '200x200 (Small Square)', value: '200x200' },
    { name: '200x446 (Custom)', value: '200x446' },
    { name: '216x36 (MMA Large Banner)', value: '216x36' },
    { name: '216x54 (MMA Large Banner)', value: '216x54' },
    { name: '220x90 (Custom)', value: '220x90' },
    { name: '234x60 (Half Banner)', value: '234x60' },
    { name: '240x133 (Custom)', value: '240x133' },
    { name: '240x400 (Vertical Rectangle)', value: '240x400' },
    { name: '250x250 (Square)', value: '250x250' },
    { name: '250x360 (Custom)', value: '250x360' },
    { name: '292x30 (Custom)', value: '292x30' },
    { name: '300x31 (Custom)', value: '300x31' },
    { name: '300x50 (MMA Extra Large Banner)', value: '300x50' },
    { name: '300x75 (MMA Extra Large Banner)', value: '300x75' },
    { name: '300x100 (3:1 Rectangle)', value: '300x100' },
    { name: '300x250 (Medium Rectangle)', value: '300x250' },
    { name: '300x600 (Half Page Ad)', value: '300x600' },
    { name: '300x1050 (Custom)', value: '300x1050' },
    { name: '320x50 (Mobile Leaderboard)', value: '320x50' },
    { name: '320x100 (Custom)', value: '320x100' },
    { name: '320x480 (Mobile Portrait Full Screen)', value: '320x480' },
    { name: '336x280 (Large Rectangle)', value: '336x280' },
    { name: '468x60 (Full Banner)', value: '468x60' },
    { name: '480x320 (Mobile Landscape Full Screen)', value: '480x320' },
    { name: '580x400 (Custom)', value: '580x400' },
    { name: '640x480 (Custom)', value: '640x480' },
    { name: '728x90 (Leaderboard)', value: '728x90' },
    { name: '750x100 (Custom)', value: '750x100' },
    { name: '750x200 (Custom)', value: '750x200' },
    { name: '750x300 (Custom)', value: '750x300' },
    { name: '768x1024 (Tablet Portrait Full Screen)', value: '768x1024' },
    { name: '930x180 (Custom)', value: '930x180' },
    { name: '950x90 (Custom)', value: '950x90' },
    { name: '960x90 (Custom)', value: '960x90' },
    { name: '970x66 (Custom)', value: '970x66' },
    { name: '970x90 (Large Leaderboard)', value: '970x90' },
    { name: '970x250 (Custom)', value: '970x250' },
    { name: '980x90 (Custom)', value: '980x90' },
    { name: '980x120 (Custom)', value: '980x120' },
    {
        name: '1024x768 (Tablet Landscape Full Screen)',
        value: '1024x768'
    }
]

export const marketplaceTargetingDemographics = [
    { name: "Black", machineName: "blk" },
    { name: "White", machineName: "wte" },
    { name: "Native", machineName: "ntv" },
    { name: "Asian", machineName: "asn" },
    { name: "Hispanic", machineName: "hpn" },
    { name: "Mixed", machineName: "mxd" }
]

export const marketplaceTargetingEducationLevel = [
    { name: "High School", machineName: "hs" },
    { name: "Bachelors", machineName: "bch" },
    { name: "Graduate", machineName: "grd" }
]

export const marketplaceTargetingPolitical = [
    { name: "Democrats", machineName: "d" },
    { name: "Republicans", machineName: "r" }
]

export const marketplaceTargetingReligion = [
    { name: "Jewish", machineName: "j" }
]

export const marketplaceTargetingIncomeLevel = [
    { name: "Income", machineName: "median_household_income" }
]

export const MAIN_AUDIENCE_KEY_GROUP = "targeting_pricing"

export const AUDIENCE_TARGETING_KEYS = ["adgt_eth", "adgt_edu", "adgt_ppol", "adgt_rlg", "adgt_inc"]

export const DEVICE_TARGETING_OPTIONS = [
    { name: "Desktop", value: 30_000, icon: "pi-desktop" },
    { name: "Tablet", value: 30_002, icon: "pi-tablet" },
    { name: "Mobile", value: 30_001, icon: "pi-mobile" },
];

export const stateAbbreviations = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY"
};
