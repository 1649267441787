import CrossScreenTakeover from "./CrossScreenTakeover";
import Cube from "./Cube";
import DesktopFooter from "./DesktopFooter";
import DesktopInteractiveBillboard from "./DesktopInteractiveBillboard";
import DesktopShoppableBillboard from "./DesktopShoppableBillboard";
import DisplayAdhesion from "./DisplayAdhesion";
import DisplayInterstitial from "./DisplayInterstitial";
import DisplaySkybox from "./DisplaySkybox";
import FooterGallery from "./FooterGallery";
import InBannerVideo from "./InBannerVideo";
import LeaderboardParallax from "./LeaderboardParallax";
import MobileExpandable from "./MobileExpandable";
import MobileFooter from "./MobileFooter";
import MobileStickyVideo from "./MobileStickyVideo";
import MobileTakeover from "./MobileTakeover";
import OneSyndicationCreative from "./OneSyndication";
import ParallaxDisplay from "./ParallaxDisplay";
import ParallaxVideo from "./ParallaxVideo";
import SkinCreative from "./Skin";
import SyndicationCreative from "./SyndicationCreative";
import VideoAdhesion from "./VideoAdhesion";
import VideoInterstitial from "./VideoInterstitial";
import { CREATIVE_NAMES } from "./definitions";

const creativesMappings = {
    [CREATIVE_NAMES.DISPLAY_ADHESION]: DisplayAdhesion,
    [CREATIVE_NAMES.DISPLAY_INTERSTITIAL]: DisplayInterstitial,
    [CREATIVE_NAMES.DISPLAY_PARALLAX]: ParallaxDisplay,
    [CREATIVE_NAMES.VIDEO_PARALLAX]: ParallaxVideo,
    [CREATIVE_NAMES.VIDEO_INTERSTITIAL]: VideoInterstitial,
    [CREATIVE_NAMES.VIDEO_ADHESION]: VideoAdhesion,
    [CREATIVE_NAMES.IN_BANNER_VIDEO]: InBannerVideo,
    [CREATIVE_NAMES.MOBILE_STICKY_VIDEO]: MobileStickyVideo,
    [CREATIVE_NAMES.CROSS_SCREEN_TAKEOVER]: CrossScreenTakeover,
    [CREATIVE_NAMES.CUBE]: Cube,
    [CREATIVE_NAMES.MOBILE_EXPANDABLE]: MobileExpandable,
    [CREATIVE_NAMES.DESKTOP_INTERACTIVE_BILLBOARD]: DesktopInteractiveBillboard,
    [CREATIVE_NAMES.DESKTOP_SHOPPABLE_BILLBOARD]: DesktopShoppableBillboard,
    [CREATIVE_NAMES.DESKTOP_FOOTER]: DesktopFooter,
    [CREATIVE_NAMES.MOBILE_FOOTER]: MobileFooter,
    [CREATIVE_NAMES.DISPLAY_SKYBOX]: DisplaySkybox,
    [CREATIVE_NAMES.SYNDICATION_CREATIVE]: SyndicationCreative,
    [CREATIVE_NAMES.ONE_SYNDICATION]: OneSyndicationCreative,
    [CREATIVE_NAMES.FOOTER_GALLERY]: FooterGallery,
    [CREATIVE_NAMES.SKIN_CREATIVE]: SkinCreative,
    [CREATIVE_NAMES.LEADERBOARD_PARALLAX]: LeaderboardParallax,
    [CREATIVE_NAMES.MOBILE_TAKEOVER]: MobileTakeover
};

export default creativesMappings;
