const DEFAULT_STATE = {};

const ACTIONS = {
    REGISTER_TOOLTIPS: "REGISTER_TOOLTIPS",
};

function tooltipTextReducer (state = {}, action) {
    //console.log({state}, action)
    //alert(state);
    switch (action.type) {
        case ACTIONS.REGISTER_TOOLTIPS:
            return { ...state, ...action.data };
        default:
            return state;
    }
}


export default tooltipTextReducer

export function registerTooltipTextStats(state) {
    //console.log({state})
    return {
        type: ACTIONS.REGISTER_TOOLTIPS,
        data: state || {},
    };
}
