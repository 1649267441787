import { useMutation } from "@apollo/client";
import { ADS_TXT_MUTATION } from "../mutations/mutations";

export function useAdsTxt(options = {}) {
    const [container] = useMutation(ADS_TXT_MUTATION, options)

    return ({domainId, operation, data, sellersData}) => {
        return container({
            variables: {
                domainId: String(domainId),
                operation,
                data,
                sellersData
            }
        })
    }
}