/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../App.scss";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { setCurrentPreset } from "../reducers/userLoginReducer";
import { getIn } from "../utilities/utils";
import LayoutWrapper from "./LayoutWrapper";
import SectionHeader from "./helper/SectionHeader";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { LAZY_LOAD_UPDATE_MUTATION } from "../mutations/mutations";

let default_values = {
    enable_lazyload: false,
    lazy_load_viewport_buffer: "",
    ad_refresh_time: "",
    ad_refresh_limit: "",
    fetch_margin_percent: "",
    render_margin_percent: "",
    mobile_scaling: "",
};

const LazyLoad = () => {
    const history = useHistory();
    const { currentDomain, currentCustomer } = useSelector((state) => state.userInformation);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displayConfirmation2, setDisplayConfirmation2] = useState(false);
    const [positionFinal2, setPositionFinal2] = useState(null);
    const dispatch = useDispatch();

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            confirm(data);
        },
    })

    const [LAZY_LOAD_UPDATE_MUTATION_CONTAINER] = useMutation(LAZY_LOAD_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(pushMessage("Lazyload settings", "Lazyload & Refresh Settings Updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    const [inputList, setInputList] = useState(null);
    const [inputList3, setInputList3] = useState(null);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [showCheckBox2, setShowCheckBox2] = useState(false);
    const [domainData, setDomainData] = useState({});
    const [domainOverrides, setDomainOverrides] = useState([]);
    const [presetData, setPresetData] = useState({});
    const [presetOverrides, setPresetOverrides] = useState([]);
    const [dataFetchComplete, setDataFetchComplete] = useState(false);
    const [isRenderMarginPercentValid, validateMarginPercent] = useState(true);
    const [isLazyFetchPercentValid, validateFetchPercent] = useState(true);
    const [presetInfo, setPresetInfo] = useState({});
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('lazyload_n_Refresh');


    useEffect(() => {
        if (currentDomain && currentCustomer) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            })
        }
    }, [currentDomain, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
        setPresetInfo(temp_data.adv_preset);

        const getUrlOverridesDefaultValues = () => {
            let overrides = [];
            overrides.push({ url: "", exact_match: false, enable_lazyload: false, scroll_delay: 100, fetch_margin_percent: 100, render_margin_percent: 100, mobile_scaling: 1, ad_refresh_time: 30, lazy_load_viewport_buffer: 30, ad_refresh_limit: 999999, disable_refresh: false });
            return overrides;
        };

        setShowCheckBox(temp_data.lazyload["override_lazyload_refresh"]);
        setShowCheckBox2(temp_data.lazyload["enable_override_by_url"]);
        setDomainData(temp_data?.lazyload?.lazyload_refresh_settings || temp_data?.preset_lazyload?.lazyload_refresh_settings || default_values);
        setDomainOverrides(temp_data.lazyload["override_refresh_url"] || temp_data?.preset_lazyload?.override_by_url || getUrlOverridesDefaultValues());
        setPresetData(temp_data?.preset_lazyload?.lazyload_refresh_settings || default_values);
        setPresetOverrides(temp_data?.preset_lazyload?.override_by_url || getUrlOverridesDefaultValues());
        setDataFetchComplete(true);
    };

    useEffect(() => {
        if (dataFetchComplete) {
            setInputList(showCheckBox ? domainData : presetData);
            setInputList3(showCheckBox2 ? domainOverrides : presetOverrides);
            setMutationDataCompleted(true);
        }
    }, [dataFetchComplete, showCheckBox, showCheckBox2]);

    // handle click event of the Remove button(Override refresh)
    const handleRemoveClick2 = (index) => {
        const list2 = [...inputList3];
        list2.splice(index, 1);
        setInputList3(list2);
        dispatch(registerScreenChanges("Lazyload"));
    };

    // handle click event of the Add button(Override refresh)
    const handleAddClick2 = () => {
        setInputList3([...inputList3, { url: "", exact_match: false, enable_lazyload: false, scroll_delay: 100, fetch_margin_percent: 100, render_margin_percent: 100, mobile_scaling: 1, ad_refresh_time: 30, lazy_load_viewport_buffer: 30, ad_refresh_limit: 999999, disable_refresh: false }]);
        dispatch(registerScreenChanges("Lazyload"));
    };

    const handleChange = (index, param, targetName = null) => {
        if (param.target !== undefined && param.target.name !== undefined) {
            let name = param.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            setInputList(temp);
            dispatch(registerScreenChanges("Lazyload"));
        } else {
            let name = targetName !== null ? targetName : param.originalEvent.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            setInputList(temp);
            dispatch(registerScreenChanges("Lazyload"));
        }
    };

    const handleChange2 = (index, param, targetName = null) => {
        if (index !== null) {
            let temp2 = [...inputList3];
            if (param.target !== undefined && param.target.name !== undefined) {
                temp2[index][param.target.name] = param.target.value;
            } else {
                if (targetName !== null) {
                    temp2[index][targetName] = param.value;
                } else {
                    temp2[index][param.originalEvent.target.name] = param.value;
                }
            }
            setInputList3(temp2);
            dispatch(registerScreenChanges("Lazyload"));
        }
    };

    const saveLazyLoadData = () => {
        return LAZY_LOAD_UPDATE_MUTATION_CONTAINER({
            variables: {
                id: `${currentDomain}`,
                showCheckBox,
                showCheckBox2,
                lazyLoadList: JSON.stringify(inputList),
                lazyLoadRefreshOverrideList: JSON.stringify(showCheckBox2 ? inputList3 : domainOverrides),
            },
        });
    };

    const dialogFuncMap2 = {
        displayConfirmation2: setDisplayConfirmation2,
    };

    const onClick2 = (name, index) => {
        setPositionFinal2(index);
        dialogFuncMap2[`${name}`](true);
    };

    const onHide2 = (name) => {
        dialogFuncMap2[`${name}`](false);
    };

    const renderFooter = (name) => {
        if (name === "displayConfirmation2") {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide2(name)} className="p-button-text" />
                    <Button
                        label="Yes"
                        icon="pi pi-check"
                        onClick={() => {
                            onHide2(name);
                            handleRemoveClick2(positionFinal2);
                        }}
                        autoFocus
                    />
                </div>
            );
        }
    };

    useEffect(() => {
        if (mutationDataCompleted && inputList) {
            const lazyRenderMarginPercent = inputList.render_margin_percent ? parseFloat(inputList.render_margin_percent) : 0;
            const lazyFetchMarginPercent = inputList.fetch_margin_percent ? parseFloat(inputList.fetch_margin_percent) : 0;
            const refreshRenderMarginPercent = inputList.lazy_load_viewport_buffer ? parseFloat(inputList.lazy_load_viewport_buffer) : 0;
            validateMarginPercent(lazyRenderMarginPercent >= refreshRenderMarginPercent);
            validateFetchPercent(lazyFetchMarginPercent >= lazyRenderMarginPercent);
        }
    }, [mutationDataCompleted, inputList]);

    const saveCallback = async () => {
        let urlOverridesWithoutValidationErrors = true;
        if (inputList3 && inputList3.length) {
            urlOverridesWithoutValidationErrors = inputList3
                .filter((item) => item.enable_lazyload)
                .every(({ lazy_load_viewport_buffer, render_margin_percent }) => {
                    return parseInt(render_margin_percent || 0) >= parseInt(lazy_load_viewport_buffer || 0);
                });
        }
        if (inputList && inputList.length) {
            urlOverridesWithoutValidationErrors = inputList
                .filter((item) => item.enable_lazyload)
                .every(({ fetch_margin_percent, render_margin_percent }) => {
                    return parseInt(fetch_margin_percent || 0) >= parseInt(render_margin_percent || 0);
                });
        }
        if (!urlOverridesWithoutValidationErrors) {
            dispatch(pushMessage("Lazyload settings", "The refresh render margin percent cannot be greater than lazyload render margin percent", "error"));
            throw new Error("The refresh render margin percent cannot be greater than lazyload render margin percent");
        }
        if (!urlOverridesWithoutValidationErrors) {
            dispatch(pushMessage("Lazyload settings", "The Fetch margin percent cannot be smaller than lazyload render margin percent", "error"));
            throw new Error("The Fetch margin percent cannot be smaller than lazyload render margin percent");
        }
        if (inputList.enable_lazyload) {
            if (isRenderMarginPercentValid && isLazyFetchPercentValid) {
                saveLazyLoadData();
                setDomainData(inputList);
                setDomainOverrides(inputList3);
            } else if(isRenderMarginPercentValid) {
                dispatch(pushMessage("Lazyload settings", "The refresh render margin percent cannot be greater than lazyload render margin percent", "error"));
                throw new Error("The refresh render margin percent cannot be greater than lazyload render margin percent");
            } else if(isLazyFetchPercentValid) {
                dispatch(pushMessage("Lazyload settings", "The Fetch margin percent cannot be smaller than lazyload render margin percent", "error"));
                throw new Error("The Lazyload Fetch margin percent cannot be smaller than lazyload render margin percent");
            }
        } else {
            saveLazyLoadData();
            setDomainData(inputList);
            setDomainOverrides(inputList3);
        }
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveCallback}>
            <>
                {mutationDataCompleted && (
                    <div className="p-grid">
                        <div className="small-full-width p-col p-col-content">
                            <div className="card">
                                <div className="p-panel p-component mb-1">
                                    <SectionHeader screenName="Lazyload settings">
                                        <div className="p-d-flex p-ai-center f-width">
                                            <InputSwitch
                                                checked={showCheckBox}
                                                onChange={() => {
                                                    setShowCheckBox(!showCheckBox);
                                                    dispatch(registerScreenChanges("Lazyload"));
                                                }}
                                            />
                                            <strong>
                                                &nbsp;Override Lazyload and Refresh Settings Preset&nbsp;
                                                <span
                                                    style={{
                                                        paddingBottom: "2px",
                                                        color: "#0388e5",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        dispatch(setCurrentPreset(presetInfo.code));
                                                        history.push("/presetlazyload");
                                                    }}
                                                >
                                                    [{presetInfo.name}]
                                                </span>
                                            </strong>
                                        </div>
                                    </SectionHeader>
                                </div>
                                <div className="p-panel p-component">
                                    <SectionHeader>
                                        <div className="p-text-bold">Lazy Settings</div>
                                    </SectionHeader>
                                    <Tooltip target=".adpositiontooltip" />
                                    <div className="p-panel-content">
                                        <div className="p-grid small-margin-top-10">
                                            <div className="p-col-3 p-sm-none small-hide">
                                                <label
                                                    htmlFor="enable_lazyload"
                                                    {...tooltip('enable_lazyload', true)}
                                                >
                                                    <strong>Enable Lazyload</strong>
                                                </label>
                                            </div>
                                            <div className="p-col-9 p-fluid">
                                                <div className="p-d-flex p-ai-center p-mb-2">
                                                    <InputSwitch name="enable_lazyload" checked={inputList.enable_lazyload} onChange={(e) => handleChange(null, e)} disabled={!showCheckBox} />
                                                    <label
                                                        htmlFor="enable_lazyload"
                                                        className="adpositiontooltip after p-ml-2 p-d-none small-show"
                                                        data-pr-tooltip="Turning the toogle on will enabled Lazyloading."
                                                    >
                                                        <strong>Enable Lazyload</strong>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('fetch_margin_percent', true)}
                                                    htmlFor="fetch_margin_percent"
                                                >
                                                    <strong>
                                                        Fetch Margin Percent <span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="fetch_margin_percent" name="fetch_margin_percent" value={inputList.fetch_margin_percent} onChange={(e) => handleChange(null, e, "fetch_margin_percent")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                    <span className="p-inputgroup-addon">%</span>
                                                </div>
                                                {inputList.fetch_margin_percent && !isLazyFetchPercentValid && <span className="small-notice">Lazyload Fetch margin Percent must be greater than lazyload Render Margin Percent when lazyload is enabled</span>}
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('render_margin_percent_', true)}

                                                    htmlFor="lazy_load_viewport_buffer"
                                                >
                                                    <strong>
                                                        Render Margin Percent <span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="render_margin_percent" name="render_margin_percent" value={inputList.render_margin_percent} onChange={(e) => handleChange(null, e, "render_margin_percent")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                    <span className="p-inputgroup-addon">%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('mobile_scale', true)}
                                                    htmlFor="mobile_scaling"
                                                >
                                                    <strong>
                                                        Mobile Scale<span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="mobile_scaling" name="mobile_scaling" value={inputList.mobile_scaling} onChange={(e) => handleChange(null, e, "mobile_scaling")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="p-panel p-component">
                                    <SectionHeader>
                                        <div className="p-text-bold">Refresh Settings</div>
                                    </SectionHeader>
                                    <Tooltip target=".adpositiontooltip" />
                                    <div className="p-panel-content">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('ad_refresh_time', true)}
                                                    htmlFor="ad_refresh_time"
                                                >
                                                    <strong>
                                                        Ad Refresh Time <span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="ad_refresh_time" name="ad_refresh_time" value={inputList.ad_refresh_time} onChange={(e) => handleChange(null, e, "ad_refresh_time")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('ad_refresh_limit', true)}
                                                    htmlFor="ad_refresh_limit"
                                                >
                                                    <strong>Ad Refresh Limit </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="ad_refresh_limit" name="ad_refresh_limit" value={inputList.ad_refresh_limit} onChange={(e) => handleChange(null, e, "ad_refresh_limit")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('refresh_render_margin_percent', true)}
                                                    htmlFor="lazy_load_viewport_buffer"
                                                >
                                                    <strong>
                                                        Render Margin Percent <span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber
                                                        inputId="lazy_load_viewport_buffer"
                                                        name="lazy_load_viewport_buffer"
                                                        value={inputList.lazy_load_viewport_buffer}
                                                        onChange={(e) => handleChange(null, e, "lazy_load_viewport_buffer")}
                                                        mode="decimal"
                                                        min={0}
                                                        style={{
                                                            backgroundColor: inputList.enable_lazyload ? "#ccc" : "#fff",
                                                            borderRadius: "4px",
                                                            border: `2px solid ${inputList.enable_lazyload ? (isRenderMarginPercentValid ? "#ccc" : "#ff0000") : "#ccc"} `,
                                                        }}
                                                        max={99999999}
                                                        disabled={!showCheckBox}
                                                    />
                                                    <span className="p-inputgroup-addon">%</span>
                                                </div>
                                                {inputList.enable_lazyload && !isRenderMarginPercentValid && <span className="small-notice">This value cannot be greater than lazyload render margin percent when lazyload is enabled</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {inputList3 && (
                                <div className="card">
                                    <div className="p-datatable p-component p-datatable-gridlines">
                                        <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                            <div className="p-d-flex p-ai-center f-width">

                                                <Tooltip target=".adpositiontooltip" />

                                                <InputSwitch
                                                    id="enable_override_by_url"
                                                    checked={showCheckBox2}
                                                    onChange={() => {
                                                        setShowCheckBox2(!showCheckBox2);
                                                        dispatch(registerScreenChanges("Lazyload"));
                                                    }}
                                                />

                                                <label
                                                    {...tooltip('enable_override_by_url', true)}
                                                    htmlFor="enable_override_by_url"
                                                >
                                                    <strong>&ensp;Enable Override by URL</strong>

                                                </label>

                                            </div>
                                        </div>
                                        <div className="p-panel p-component">
                                            <div className="p-panel-content">
                                                <Dialog header="Confirmation" visible={displayConfirmation2} modal style={{ width: "350px" }} footer={renderFooter("displayConfirmation2")} onHide={() => onHide2("displayConfirmation2")}>
                                                    <div className="confirmation-content p-d-flex p-ai-center">
                                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                                        <span>Are you sure you want to delete this item?</span>
                                                    </div>
                                                </Dialog>

                                                <Accordion className="">
                                                    {inputList3.map((value, index) => {
                                                        return (
                                                            <AccordionTab
                                                                key={index}
                                                                header={
                                                                    <React.Fragment>
                                                                        <div className="p-d-flex p-jc-between">
                                                                            <div className="p-field-checkbox p-mb-0">
                                                                                <div className="p-text-bold">{value.url.length > 100 ? value.url.substring(0, 100) + "..." : value.url}</div>
                                                                            </div>
                                                                            <Button
                                                                                type="button"
                                                                                icon="pi pi-times"
                                                                                className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                                                tooltip="Remove row"
                                                                                tooltipOptions={{ position: "top" }}
                                                                                onClick={() => onClick2("displayConfirmation2", index)}
                                                                                disabled={!showCheckBox2}
                                                                            />
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <table>
                                                                    <tbody className="">
                                                                        <tr>
                                                                            <td>
                                                                                <div className="p-panel p-component">
                                                                                    <SectionHeader>
                                                                                        <div className="p-text-bold">URL Override</div>
                                                                                    </SectionHeader>
                                                                                    <div className="p-panel-content">
                                                                                        <Tooltip target=".adpositiontooltip" />
                                                                                        <div className="p-grid p-mb-2 small-margin-bottom-10">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                                                <label
                                                                                                    {...tooltip('override_url', true)}
                                                                                                    htmlFor={"url" + index}
                                                                                                >
                                                                                                    <strong>URL</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <InputTextarea rows={15} cols={30} id={"url" + index} name="url" value={value.url} onChange={(e) => handleChange2(index, e, "url")} disabled={!showCheckBox2} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid small-margin-top-10">
                                                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                                                <label
                                                                                                    {...tooltip('exact_match', true)}
                                                                                                    htmlFor={"exact_match" + index}
                                                                                                >
                                                                                                    <strong>Exact Match</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-9 p-fluid">
                                                                                                <div className="p-d-flex p-ai-center p-mb-2">
                                                                                                    <InputSwitch id={"exact_match" + index} name={"exact_match"} checked={value.exact_match} onChange={(e) => handleChange2(index, e, "exact_match")} disabled={!showCheckBox2} />
                                                                                                    <label htmlFor={"exact_match" + index} className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="If checked then will check site's current URL with query string">
                                                                                                        <strong>Exact Match</strong>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br></br>

                                                                                <div className="p-panel p-component">
                                                                                    <SectionHeader>
                                                                                        <div className="p-text-bold">Lazy Settings</div>
                                                                                    </SectionHeader>
                                                                                    <Tooltip target=".adpositiontooltip" />
                                                                                    <div className="p-panel-content">
                                                                                        <div className="p-grid small-margin-top-10">
                                                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                                                <label className="adpositiontooltip after" data-pr-tooltip="Check to enable lazyload for this Publisher." htmlFor="enable_lazyload">
                                                                                                    <strong>Enable Lazyload</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-9 p-fluid">
                                                                                                <div className="p-d-flex p-ai-center p-mb-2">
                                                                                                    <InputSwitch id={"enable_lazyload" + index} name={"enable_lazyload"} checked={value.enable_lazyload} onChange={(e) => handleChange2(index, e, "enable_lazyload")} disabled={!showCheckBox2} />
                                                                                                    <label htmlFor="enable_lazyload" className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="Check to enable lazyload for this Publisher.">
                                                                                                        <strong>Enable Lazyload</strong>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label
                                                                                                    className="adpositiontooltip after"
                                                                                                    data-pr-tooltip="The minimum distance from the current viewport a slot must be before we fetch the ad as a percentage of viewport size. A value of 0 means 'when the slot enters the viewport', 100 means 'when the ad is 1 viewport away', and so on.
                          "
                                                                                                    htmlFor="fetch_margin_percent"
                                                                                                >
                                                                                                    <strong>
                                                                                                        Fetch Margin Percent <span className="color-red">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"fetch_margin_percent" + index}
                                                                                                        name="fetch_margin_percent"
                                                                                                        value={value.fetch_margin_percent}
                                                                                                        onChange={(e) => handleChange2(index, e, "fetch_margin_percent")}
                                                                                                        mode="decimal"
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                    <span className="p-inputgroup-addon">%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label
                                                                                                    className="adpositiontooltip after"
                                                                                                    data-pr-tooltip="The minimum distance from the current viewport a slot must be before we render an ad. This allows for prefetching the ad, but waiting to render and download other subresources. The value works just like fetchMarginPercent as a percentage of viewport.
                          "
                                                                                                    htmlFor="render_margin_percent"
                                                                                                >
                                                                                                    <strong>
                                                                                                        Render Margin Percent <span className="color-red">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"render_margin_percent" + index}
                                                                                                        name="render_margin_percent"
                                                                                                        value={value.render_margin_percent}
                                                                                                        onChange={(e) => handleChange2(index, e, "render_margin_percent")}
                                                                                                        mode="decimal"
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                    <span className="p-inputgroup-addon">%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label
                                                                                                    className="adpositiontooltip after"
                                                                                                    data-pr-tooltip="A multiplier applied to margins on mobile devices. This allows varying margins on mobile vs. desktop. For example, a value of 2.0 will multiply all margins by 2 on mobile devices, increasing the minimum distance a slot can be before fetching and rendering.
                          "
                                                                                                    htmlFor="mobile_scaling"
                                                                                                >
                                                                                                    <strong>
                                                                                                        Mobile Scale<span className="color-red">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"mobile_scaling" + index}
                                                                                                        name="mobile_scaling"
                                                                                                        value={value.mobile_scaling}
                                                                                                        onChange={(e) => handleChange2(index, e, "mobile_scaling")}
                                                                                                        mode="decimal"
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <br></br>
                                                                                <div className="p-panel p-component">
                                                                                    <SectionHeader>
                                                                                        <div className="p-text-bold">Refresh Settings</div>
                                                                                    </SectionHeader>
                                                                                    <Tooltip target=".adpositiontooltip" />
                                                                                    <div className="p-panel-content">
                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label className="adpositiontooltip after" data-pr-tooltip="AdRefresh Time." htmlFor="ad_refresh_time">
                                                                                                    <strong>Ad Refresh TIme </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"ad_refresh_time" + index}
                                                                                                        name="ad_refresh_time"
                                                                                                        value={value.ad_refresh_time}
                                                                                                        onChange={(e) => handleChange2(index, e, "ad_refresh_time")}
                                                                                                        mode="decimal"
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label className="adpositiontooltip after" data-pr-tooltip="The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen." htmlFor="ad_refresh_limit">
                                                                                                    <strong>Ad Refresh Limit </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"ad_refresh_limit" + index}
                                                                                                        name="ad_refresh_limit"
                                                                                                        value={value.ad_refresh_limit}
                                                                                                        onChange={(e) => handleChange2(index, e, "ad_refresh_limit")}
                                                                                                        mode="decimal"
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                                <label
                                                                                                    className="adpositiontooltip after"
                                                                                                    data-pr-tooltip="The minimum distance from the current viewport a slot must be before we refresh an ad. The value works just like fetchMarginPercent as a percentage of viewport."
                                                                                                    htmlFor="lazy_load_viewport_buffer"
                                                                                                >
                                                                                                    <strong>
                                                                                                        Render Margin Percent <span className="color-red">*</span>
                                                                                                    </strong>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                                <div className="p-inputgroup p-fluid">
                                                                                                    <InputNumber
                                                                                                        inputId={"lazy_load_viewport_buffer" + index}
                                                                                                        name="lazy_load_viewport_buffer"
                                                                                                        value={value.lazy_load_viewport_buffer}
                                                                                                        onChange={(e) => handleChange2(index, e, "lazy_load_viewport_buffer")}
                                                                                                        mode="decimal"
                                                                                                        min={0}
                                                                                                        style={{
                                                                                                            backgroundColor: value.enable_lazyload ? "#ccc" : "#fff",
                                                                                                            borderRadius: "4px",
                                                                                                            border: `2px solid ${value.enable_lazyload ? (isRenderMarginPercentValid ? "#ccc" : "#ff0000") : "#ccc"} `,
                                                                                                        }}
                                                                                                        max={99999999}
                                                                                                        disabled={!showCheckBox2}
                                                                                                    />
                                                                                                    <span className="p-inputgroup-addon">%</span>
                                                                                                </div>
                                                                                                {getIn(inputList3, index, "enable_lazyload") && parseInt(getIn(inputList3, index, "lazy_load_viewport_buffer") || 0) > parseInt(getIn(inputList3, index, "render_margin_percent") || 0) && (
                                                                                                    <span className="small-notice">This value cannot be greater than lazyload render margin percent when lazyload is enabled</span>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </AccordionTab>
                                                        );
                                                    })}
                                                </Accordion>
                                                <div className="p-d-flex p-jc-end p-mt-3">
                                                    <Button type="button" label="Add Row" icon="pi pi-plus" tooltip="Add row" tooltipOptions={{ position: "top" }} onClick={handleAddClick2} disabled={!showCheckBox2} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="small-full-width p-col p-col-sidebar">
                            <div className="p-panel p-component">
                                <SectionHeader>
                                    <div className="p-text-bold">Description</div>
                                </SectionHeader>
                                <div className="p-panel-content p-fluid">
                                    <h6 className="p-text-bold p-mb-0">Enable Lazyload</h6>
                                    <p>{helpText('help_text____enable_lazyload')}</p>
                                    <h6 className="p-text-bold p-mb-0">Fetch Margin Percent</h6>
                                    <p>{helpText('help_text____fetch_margin_percent')}</p>
                                    <h6 className="p-text-bold p-mb-0">Render Margin Percent</h6>
                                    <p>{helpText('help_text____render_margin_percent')}</p>
                                    <h6 className="p-text-bold p-mb-0">Mobile Scale</h6>
                                    <p>{helpText('help_text____mobile_scale')}</p>
                                    <h6 className="p-text-bold p-mb-0">Ad Refresh Time</h6>
                                    <p>{helpText('help_text____ad_refresh_time')}</p>
                                    <h6 className="p-text-bold p-mb-0">Ad Refresh Limit</h6>
                                    <p>{helpText('help_text____ad_refresh_limit')}</p>
                                    <hr />

                                    {/*<h6 className="p-text-bold p-mb-0 m-mt-0">URL</h6>
                                    <p>{helpText('help_text____url')}</p>
                                    <h6 className="p-text-bold p-mb-0">Buffer</h6>
                                    <p>{helpText('help_text____buffer')}</p>
                                    <h6 className="p-text-bold p-mb-0">Disable lazyload</h6>
                                    <p>{helpText('help_text____disable_lazyload')}</p>
                                    <hr />
                                    <h6 className="p-text-bold p-mb-0 m-mt-0">Override by URL</h6>*/}

                                    <h6 className="p-text-bold p-mb-0 m-mt-0">URL</h6>
                                    <p>{helpText('help_text____url')}</p>
                                    {/*<h6 className="p-text-bold p-mb-0">Refresh Time</h6>
                                    <p>{helpText('help_text____refresh_time')}</p>
                                    <h6 className="p-text-bold p-mb-0">Disable refresh</h6>
                                    <p>{helpText('help_text____disable_refresh')}</p>*/}
                                    <h6 className="p-text-bold p-mb-0">Exact Match</h6>
                                    <p>{helpText('help_text____exact_match')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </LayoutWrapper>
    );
};

export default LazyLoad;
