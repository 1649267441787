import {useEffect, useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {useDispatch, useSelector} from "react-redux";
import {registerTooltipTextStats} from "../reducers/tooltipTextReducer";

const TOOLTIP_TEXT = gql`
    mutation callTooltipText($tooltipText: String) {
        tooltipTextMutation(input: { tooltipText: $tooltipText }) {
            allTooltipText
        }
    }
`;

export const useAdgridToolTipManager = (objectName) => {

    const dispatch = useDispatch();
    const tooltipTextRedux = useSelector(state => state.tooltipTextReducer);
    const [tooltipTextObject, setTooltipTextsObject] = useState(tooltipTextRedux);
    const [isTooltipTextBlank, setIsTooltipTextBlank] = useState(Object.keys(tooltipTextRedux).length === 0);
    const [TOOLTIP_TEXT_CONTAINER] = useMutation(TOOLTIP_TEXT, {
        onCompleted(data){
            const {tooltipText} = JSON.parse(data.tooltipTextMutation.allTooltipText);
            dispatch(registerTooltipTextStats(tooltipText));
            //console.log(data.tooltipTextMutation);
            //console.log('tooltipText:::: ', tooltipText);
            setIsTooltipTextBlank(Object.keys(tooltipText).length === 0);
            setTooltipTextsObject(tooltipText);
        }
    });

    useEffect(() => {
        if (isTooltipTextBlank) {
            TOOLTIP_TEXT_CONTAINER({variables: {domainId: ''}});
        }
    }, [isTooltipTextBlank]);

    const toolTipText = field => {
        if (!field || isTooltipTextBlank) return false;
        if (objectName) return tooltipTextObject[objectName] ? tooltipTextObject[objectName][field]: "";
        return tooltipTextObject[field];
    }

    const toolTipTextWithAttributes = (field, dataPrimeTooltip) => {
        if (!toolTipText(field)) return false;
        if (dataPrimeTooltip) return {"data-pr-tooltip": toolTipText(field), className: "adpositiontooltip after" };
        return {tooltip: toolTipText(field)};
    }

    return {toolTipText, toolTipTextWithAttributes};

}

export default useAdgridToolTipManager;
