/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { faCirclePlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../marketplace/constants";

const uploadControlsCss = css`
    cursor: pointer;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 6px;
    position: relative;
    transition: 0.25s background linear;
    width: 200px;
    label {
        cursor: pointer;
        color: #2196f3;
        display: block;
        padding: 1em;
    }
    &:hover {
        background-color: #f4faff !important;
        color: #2196f3;
        label,
        .pi {
            color: #2196f3;
        }
    }
    .icon {
        padding-right: 3px;
        font-size: 5em;
    }
    input[type="file"] {
        display: none;
    }
    .click-url {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const MarketplaceUploadControls = ({ accept, filename, extension, base64Data, uploadAsset, loadAssetHandler, disabled = false, label = "BROWSE", validSizes }) => {
    useEffect(() => {
        if (base64Data && filename && extension) {
            uploadAsset();
        }
    }, [base64Data, filename, extension]);

    return (
        <div>
            {!disabled && (
                <>
                    <div css={uploadControlsCss}>
                        <label>
                            <span>
                                <FontAwesomeIcon icon={faCirclePlus} fontSize={"1em"} /> &nbsp;
                            </span>
                            {label}
                            <input
                                type="file"
                                name="file-upload"
                                accept={accept}
                                onInput={(ev) => {
                                    loadAssetHandler(ev);
                                }}
                            />
                        </label>
                    </div>
                    <div
                        className="allowed-sizes"
                        css={css`
                            margin-top: 1em;
                            .size {
                                display: inline-block;
                                margin: 0 6px;
                                padding: 0.15em;
                                border-radius: 3px;
                                color: ${colors.white};
                                background-color: ${colors.lightBlue};
                            }
                        `}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} fontSize={"1em"} color="#000" /> Allowed sizes for display creatives: &nbsp;
                        {[...validSizes].map((size, idx) => (
                            <span className="size" key={idx}>
                                {size}
                            </span>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
