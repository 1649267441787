import { decodeHtml } from "../../../utilities/utils";
import { stateAbbreviations } from "./constants";

export async function getPartnersInfo({
    currentCustomer,
    customerName,
    dataMutation
}) {
    let response = await dataMutation({
        variables: { id: String(currentCustomer), isMarketPlace: true, customerName, page: String(1) }
    });
    let data = response.data.customOrdersMutation
    let customerToDomainMappings = JSON.parse(data.customerToDomainMappings);
    let ordersInfo = JSON.parse(data.ordersInfo);
    let networks = ordersInfo.marketplaceNetworks.map(network => ({ name: decodeHtml(network.name), value: network.code }))
    let domainsInfo = []
    for (let [k, domainObjects] of Object.entries(customerToDomainMappings)) {
        if (k) {
            domainObjects.forEach((domainInfo) => {
                if (domainInfo.overview && domainInfo.marketplaceEnabled) {
                    let domainOverview = JSON.parse(domainInfo.overview);
                    if (domainOverview.marketplace_domain_name && domainOverview.marketplace_domain_url) {
                        domainOverview.marketplace_domain_url = domainInfo.domainName;
                        domainsInfo.push(domainOverview);
                    }
                }
            });
        }
    }
    domainsInfo.sort((a, b) => a.marketplace_domain_name.localeCompare(b.marketplace_domain_name));

    return {
        networks,
        domainsInfo
    }
}

export async function requestLineItemsStats(lineItemIds, lineItemStatsMutation) {
    let statsResponse = await lineItemStatsMutation({
        variables: {
            lineItemIdsList: JSON.stringify(lineItemIds),
        },
    });

    let stats = JSON.parse(statsResponse.data?.getLineItemStatsMutation.gamApiStats);
    return stats;
}

/**
     * 
     * @param {Array<any>} geoTargets 
     * @param {Set<string>} excludedGeographies 
     * @returns 
     */

export function categorizeGeographies(geoTargets, excludedGeographies) {
    let countries = []
    let states = []
    let dmas = []
    let includedCountries = []
    let includedStates = []
    let includedDmas = []
    let excludedCountries = []
    let excludedStates = []
    let excludedDmas = []


    /**
     * 
     * @param {Array<any>} targets
     */

    function helper(targets) {
        if (targets.length) {
            let [first, ...rest] = targets
            if (first.countryCode) {
                let { id, countryCode } = first

                if (!excludedGeographies.has(id)) {
                    countries.push(first.countryCode)
                    includedCountries.push({ id, abbr: countryCode })
                    helper(first.children || [])
                } else {
                    excludedCountries.push({ id, abbr: countryCode })
                }
                helper(rest)
            } else if (first.dma) {
                let { id, name, canonicalParentId } = first
                let data = { name, id, parentId: canonicalParentId }

                if (!excludedGeographies.has(id)) {
                    dmas.push(data)
                    includedDmas.push({ id, name })
                } else {
                    excludedDmas.push({ id, name })
                }

                helper(rest)
            } else {
                let { id, name } = first
                let data = { id, name, abbrev: stateAbbreviations[name] }

                if (!excludedGeographies.has(id)) {
                    states.push(data)
                    includedStates.push({ id, abbr: stateAbbreviations[name] })
                } else {
                    excludedStates.push({ id, abbr: stateAbbreviations[name] })
                }
                helper(rest)
            }
        }
    }

    helper(geoTargets)

    return {
        countries,
        states,
        dmas,
        includedCountries,
        includedDmas,
        includedStates,
        excludedCountries,
        excludedDmas,
        excludedStates
    }
}

export function generateSspPayload(allGeoTargets, order, customerName, stats) {
    let geoTargets = {}
    let devices = ['desktop', 'tablet', 'mobile']

    if (order.excludedGeographies?.length) {
        let excludedIds = new Set(order.excludedGeographies.map(geo => geo.id))
        geoTargets = categorizeGeographies(allGeoTargets, excludedIds)
    } else {
        geoTargets = categorizeGeographies(order.includedGeographies?.length ? order.includedGeographies : allGeoTargets, new Set())
    }

    if (order.selectDeviceMode) {
        let includedDevices = order.targetDevices.map(item => {
            return item.name.toLowerCase()
        })
        devices = includedDevices.join(',') || devices.join(',')
    } else {
        let excludedDevices = new Set(
            order.targetDevices.map(item => {
                return item.name.toLowerCase()
            })
        );
        devices = devices
            .filter(item => !excludedDevices.has(item))
            .join(',')
    }

    let updatePayload = {
        customer_name: customerName,
        marketplace_id: order.wpMarketplacePostId,
        devices,
        included_countries: geoTargets
            .includedCountries
            .map(item => item.abbr)
            .join(','),
        excluded_countries: geoTargets
            .excludedCountries
            .map(item => item.abbr)
            .join(','),
        included_states: geoTargets
            .includedStates
            .map(item => item.abbr)
            .join(','),
        excluded_states: geoTargets
            .excludedStates
            .map(item => item.abbr)
            .join(','),
        included_dma_name: geoTargets
            .includedDmas
            .map(item => item.name)
            .join(','),
        included_dma_code: geoTargets
            .includedDmas
            .map(item => item.id)
            .join(','),
        excluded_dma_name: geoTargets
            .excludedDmas
            .map(item => item.name)
            .join(','),
        excluded_dma_code: geoTargets
            .excludedDmas
            .map(item => item.id)
            .join(','),
        status: stats[order.lineItemId].status
    }

    return updatePayload
}