/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSV_DATA_DOWNLOAD, MARKETPLACE_ORDERS_MUTATION } from "./common/marketplace/constants";
import { decodeHtml } from "../utilities/utils";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import safetagLogo from "../media_assets/safetag_icon.png";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { pushMessage } from "../reducers/messagingReducer";
import { useMutation } from "@apollo/client";
import { getPartnersInfo } from "./common/marketplace/functions";
import {Checkbox} from 'primereact/checkbox';

const DomainGrid = ({ domainsColl, mapper, filter = "" }) => {
    try {
        if (filter) {
            let filtered = domainsColl.filter((domainData) => {
                let domainUrl = domainData?.marketplace_domain_url?.toLowerCase()
                return domainUrl?.includes(filter)
            });
            return filtered.length ? mapper([...filtered, null, null, null, null, null, null]) : null
        }
        return mapper([...domainsColl, null, null, null, null, null, null])
    } catch (err) {
        console.log(err)
        return null;
    }
};

const MarketplacePartners = ({
    allowDownloadCsvLink = true,
    allowLayoutSelection = true,
    availableNetworks = null,
    participatingDomains = null,
    hasStaticData = false,
    initialLayout = null,
    enableDomainSelection = true,
    domainSelectionHandler = () => {},
    selectedDomainsSet = new Set()
}) => {

    const { currentCustomer } = useSelector((state) => state.userInformation);
    const customerName = "Ebony";
    const [retrieveMarketplaceOrdersMutation] = useMutation(MARKETPLACE_ORDERS_MUTATION);
    const [csvDataDownloadMutation] = useMutation(CSV_DATA_DOWNLOAD);
    const [allDomains, setAllDomainsInfo] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]); /* these are the options that were picked by the used of the network selection dropdown */
    const [activeNetworks, setActiveNetworks] = useState([]); /* these are all the options available to the network selection dropdown */
    const [layout, setLayout] = useState(initialLayout || "column");
    const [usesSafetag, setUsesSafetag] = useState(false);
    const [search, setSearch] = useState("");
    const [downloadUrl, setDownloadUrl] = useState("");
    const linkRef = useRef(null);
    const dispatch = useDispatch();

    async function downloadCSVHandler() {
        try {
            let filtered = selectedFilters.length ? selectedDomains : allDomains;

            if (search && usesSafetag) {
                filtered = filtered.filter((domainData) => {
                    return domainData?.marketplace_domain_url?.includes(search) && domainData?.enable_safetag
                });
            } else if (search) {
                filtered = filtered.filter((domainData) => {
                    return domainData?.marketplace_domain_url?.includes(search)
                });
            } else if (usesSafetag) {
                filtered = filtered.filter((domainData) => {
                    return domainData?.enable_safetag
                });
            }
            
            if (filtered.length) {
                let csv = "Domain,URL,Categories,Impressions,Safetag,Description\n";
                filtered.forEach((domainData) => {
                    let { marketplace_domain_name, marketplace_domain_url, selected_networks, marketplace_impressions, enable_safetag, marketplace_domain_description } = domainData;
                    selected_networks = selected_networks.map((n) => decodeHtml(n.name));
                    csv += `${marketplace_domain_name},${marketplace_domain_url},"${selected_networks.join(", ")}","${marketplace_impressions}",${enable_safetag ? "Yes" : "No"},"${marketplace_domain_description}"\n`;
                });
                
                let response = await csvDataDownloadMutation({
                    variables: {
                        csv,
                        filename: 'marketplace_partners_'
                    },
                });
                
                let downloadLink = JSON.parse(response.data.downloadCsvDataMutation.downloadUrl);
                setDownloadUrl(downloadLink.url);
                setTimeout(() => {
                    linkRef.current.click();
                }, 50);
                return;
            }
            dispatch(pushMessage("Marketplace partners", "Nothing to download", "error"));
        } catch (err) {
            console.log(err)
            dispatch(pushMessage("Marketplace partners", "Nothing to download", "error"));
        }
    }

    const toggleSafetagHandler = () => {
        let filters = {};
        let filtering = false;
        if (selectedFilters.length) {
            selectedFilters.forEach((f) => {
                filters[f] = true;
            });
            filtering = true;
        }
        setUsesSafetag(!usesSafetag);
        if (!usesSafetag) {
            let filteredDomains = allDomains.filter((domainData) => {
                if (filtering && domainData.enable_safetag) {
                    return domainData.selected_networks.some((n) => {
                        return n.code in filters || n.term_id in filters;
                    });
                } else {
                    return domainData.enable_safetag;
                }
            });
            setSelectedDomains(filteredDomains)
        } else {
            if (filtering) {
                let filteredDomains = allDomains.filter((domainData) => {
                    return domainData.selected_networks.some((n) => n.code in filters || n.term_id in filters);
                });
                setSelectedDomains(filteredDomains)
            } else {
                setSelectedDomains([]);
            }
        }
    };

    useEffect(() => {
        if (selectedFilters.length) {
            let selected = selectedFilters.reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            }, {});

            let filteredDomains = allDomains.filter((domainData) => {
                let selectedNetworks = domainData.selected_networks;
                let isSelected = selectedNetworks.some((networkObject) => {
                    if (usesSafetag) {
                        return domainData.enable_safetag && (networkObject.code in selected || networkObject.term_id in selected);
                    } else {
                        return networkObject.code in selected || networkObject.term_id in selected;
                    }
                });
                return isSelected;
            });
            if (search) {
                filteredDomains = filteredDomains.filter((domainData) => domainData?.marketplace_domain_url?.includes(search));
            }
            setSelectedDomains(filteredDomains)
        } else {
            setSelectedDomains([]);
        }
    }, [selectedFilters, usesSafetag]);


    useEffect(() => {
        if (hasStaticData) {
            if (participatingDomains?.length && availableNetworks?.length) {
                setAllDomainsInfo(participatingDomains)
                setActiveNetworks(availableNetworks)
            }
        }
    }, [hasStaticData])

    useEffect(() => {
        if (customerName && currentCustomer && !hasStaticData) {
            getPartnersInfo({
                currentCustomer, 
                customerName, 
                dataMutation: retrieveMarketplaceOrdersMutation
            })
                .then(response => {
                    let {networks, domainsInfo} = response;
                    setActiveNetworks(networks)
                    setAllDomainsInfo(domainsInfo)
                })
        }
        
    }, [customerName, currentCustomer]);

    function renderDomains(domains) {
        return (
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: ${layout === "column" ? "1em" : ""};
                    flex-direction: ${layout === "column" ? "row" : "column"};
                    .item {
                        margin-bottom: 0px;
                        &.domain-name {
                            font-size: 16px;
                            margin-top: 0.5em;
                        }
                        &.domain-url,
                        &.impressions {
                            font-size: 12px;
                        }
                        .networks {
                            font-size: 11px;
                            text-transform: uppercase;
                            display: inline-block;
                            margin-right: 6px;
                        }
                    }
                    .domain-info {
                        width: 100%;
                        max-width: ${layout === "column" ? "220px" : "100%"};
                        display: ${layout === "column" ? "block" : "flex"};
                        margin-bottom: ${layout === "row" ? (enableDomainSelection ? "5em" : "2em") : "0px"};
                        .domain-name {
                            margin-top: ${layout === "row" ? "0em" : "0.5em"};
                        }
                        .domain {
                            margin-right: ${layout === "row" ? "1em" : "0px"};
                        }
                        .item {
                            margin-bottom: ${layout === "row" ? "0.5em" : "0.25em"};
                        }
                    }
                `}
            >
                {domains.map((domainInfo, idx) => {
                    if (!domainInfo) {
                        return (
                            <div className="domain-info" style={{ visibility: "hidden", display: layout === "row" ? "none" : "block" }} key={idx}>
                                <div className="domain">
                                    <div className="domain-logo"></div>
                                </div>
                            </div>
                        );
                    }

                    let { marketplace_domain_logo: logo, marketplace_domain_name: domainName, marketplace_domain_url: domainUrl = "", marketplace_impressions: impressions, selected_networks: networks = [], marketplace_domain_description: description = "", enable_safetag: safetagEnabled, id } = domainInfo;

                    if (usesSafetag && !safetagEnabled) {
                        return null;
                    }

                    return (
                        <div 
                            className={`domain-info ${enableDomainSelection && layout === 'column' ? 'column-selection' : ''}`}
                            key={domainName + "_" + idx}
                            css={css`
                                &.column-selection {
                                    margin-bottom: 1em;
                                }
                            `}
                        >
                            <div className="domain" css={css`
                                position: relative;
                                .domain-selection-section {
                                    position: absolute;
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    bottom: -2em;
                                    .p-checkbox {
                                        margin-right: 0.5em;
                                    }
                                }
                            `}>
                                <div className="domain-logo">{logo && <img src={logo} alt={`${domainName} logo`} />}</div>
                                {safetagEnabled && (
                                    <div
                                        css={css`
                                            position: absolute;
                                            bottom: 1em;
                                            right: 1em;
                                            &:hover .p-button-label {
                                                display: none;
                                            }
                                            .uses-safetag {
                                                img {
                                                    width: 25px;
                                                    height: auto;
                                                }
                                            }
                                        `}
                                    >
                                        <Button className="p-button p-component p-button-link uses-safetag domain-notice" tooltip="Uses Safetag">
                                            <img src={safetagLogo} alt="Safetag logo" />
                                        </Button>
                                    </div>
                                )}
                                {
                                    enableDomainSelection  && layout === 'row' && (
                                        <div className="domain-selection-section">
                                            <Checkbox 
                                                checked={selectedDomainsSet.has(id)} 
                                                onChange={() => {
                                                    domainSelectionHandler({domainId: id})
                                                }}
                                            >
                                            </Checkbox>
                                            <span>Add to campaign</span>
                                        </div>
                                    )
                                }
                                
                            </div>
                            <div className="info" css={css`
                                & + .domain-selection-section {
                                    .p-checkbox {
                                        margin-right: 0.5em;
                                    }
                                }
                                .categories {
                                    height: 50px;
                                }
                            `}>
                                <p className="item domain-name">
                                    <strong>{domainName}</strong>
                                </p>
                                <p className="item domain-url">
                                    <a href={domainUrl.startsWith("http") ? domainUrl : "https://" + domainUrl} rel="noreferrer" target="_blank">
                                        {domainUrl}
                                    </a>
                                </p>
                                {layout === "row" && <p className="item description">{description}</p>}
                                <p className="item impressions">
                                    <strong>Impressions:</strong> {impressions || "n/a"}
                                </p>
                                <p className="item categories">
                                    {networks.map((o, i) => (
                                        <span key={i} className="bold networks">
                                            {decodeHtml(o.name)}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            {
                                enableDomainSelection  && layout === 'column' && (
                                    <div className="domain-selection-section">
                                        <Checkbox 
                                            checked={selectedDomainsSet.has(id)} 
                                            onChange={() => {
                                                domainSelectionHandler({domainId: id})
                                            }}
                                        >
                                        </Checkbox>
                                        <span>Add to campaign</span>
                                    </div>
                                )
                            }
                        </div>
                    );
                })}
            </div>
        );
    }

    const selectLayout = (layout) => setLayout(layout);

    return (
        
            <div
                css={css`
                    width: 90%;
                    max-width: 1400px;
                    margin: 0 auto;
                `}
            >
                <div
                    className="control-bar"
                    css={css`
                        margin-bottom: 1em;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .heading {
                            margin-bottom: 0;
                        }
                        .filters {
                            margin-left: auto;
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .layouts {
                            margin-right: 2em;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            .pi {
                                display: inline-block;
                                margin: 0 0.25em;
                                cursor: pointer;
                            }
                            .domain-notice {
                                padding: 0;
                                &:hover .p-button-label {
                                    opacity: 0;
                                }
                                &:focus,
                                &:enabled {
                                    box-shadow: none;
                                }
                            }
                        }
                        .category-selector {
                            width: 250px;
                        }
                    `}
                >
                    <div className="title">
                        <h1 className="heading">Participating Domains</h1>
                    </div>
                    <div className="filters">
                        {
                            allowDownloadCsvLink && (
                                <div>
                                    <Button className="p-button p-button-link" label="DOWNLOAD CSV" onClick={async () => await downloadCSVHandler()} />
                                </div>
                            )
                        }
                        
                        <div
                            className="layouts"
                            css={css`
                                .lock-icon {
                                    width: 14px;
                                    height: auto;
                                    filter: grayscale(${usesSafetag ? "0" : "1"});
                                }
                                .active {
                                    font-weight: bold;
                                }
                                .safetag-switch-section {
                                    display: flex;
                                    align-items: center;
                                    margin-right: 4em;
                                }
                                .label,
                                .p-component,
                                .lock-icon {
                                    margin: 0 0.5em;
                                }
                            `}
                        >
                            <div className="safetag-switch-section">
                                <span className="label">
                                    <b>Safetag</b>
                                </span>
                                <InputSwitch checked={usesSafetag} onChange={toggleSafetagHandler} />
                                <img src={safetagLogo} alt={""} className="lock-icon" />
                            </div>
                            {
                                allowLayoutSelection && (
                                    <>
                                        <span className={`pi pi-bars ${layout === "row" ? "active" : ""}`} onClick={() => selectLayout("row")}></span>
                                        <span className={`pi pi-th-large ${layout === "column" ? "active" : ""}`} onClick={() => selectLayout("column")}></span>
                                    </>
                                )
                            }
                        </div>
                        <div
                            className="filter"
                            css={css`
                                margin-right: 1em;
                                width: 250px;
                                input {
                                    width: 100%;
                                }`
                            }
                        >
                            <InputText placeholder="Search" value={search} onChange={(ev) => setSearch(ev.target.value)} />
                        </div>
                        <div className="dropdowns">
                            <div className="network-selection-controls">
                                <MultiSelect
                                    className="category-selector"
                                    optionLabel="name"
                                    value={selectedFilters}
                                    optionValue="value"
                                    options={activeNetworks}
                                    display="chip"
                                    placeholder="All Categories"
                                    onChange={(ev) => {
                                        setSelectedFilters(ev.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`domains-list ${layout}`}
                    css={css`
                        &.column {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .domain-logo {
                            width: 220px;
                            height: 220px;
                            border: 1px solid #eee;
                            display: flex;
                            align-items: center;
                            img {
                                width: 80%;
                                margin: 0 auto;
                            }
                        }
                    `}
                >
                    <DomainGrid domainsColl={selectedFilters.length ? selectedDomains : allDomains} mapper={renderDomains} filter={search.trim()} />
                    <a style={{ visibility: 0, position: "absolute", left: -90000000 }} rel="noreferrer" download="domains_list.csv" href={downloadUrl} ref={linkRef}>
                        Download link
                    </a>
                </div>
            </div>
    );
};

export default MarketplacePartners;
