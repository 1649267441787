import React, { useState, useEffect, useRef } from 'react';
import 'react-list-editable/lib/react-list-editable.css';
import { CSSTransition } from 'react-transition-group';
import {InputSwitch} from 'primereact/inputswitch';
import { AppTopbar } from '../../AppTopbar';
import { AppMenu } from '../../AppMenu';
import { DomainsDropdown } from '../../DomainsDropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '../../layout/flags/flags.css';
import '../../layout/layout.scss';
import '../../App.scss';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { wrapperClass, sidebarClassName } from '../../constants'
import { gql, useMutation } from '@apollo/client';
import AppLogo from '../AppLogo';


const DOMAIN_Edit_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    presetAllInfoMutation(input: {id: $id, cid: $cid}) {
        presetInfo
    }
  }
`
const AD_ADAPTERSELECTION_UPDATE_MUTATION = gql`
  mutation callAdAdapterselectionListUpdateMutation($id: String, $adapterselectionList: String) {
    updatePresetAdapterSelectionMutation(input: {id: $id, adapterselectionList: $adapterselectionList}) {
        clientMutationId
        networkId
    }
  }
`


const PresetAdapterSelection = (props) => {
    
    let presetId = props.match.params.presetId?props.match.params.presetId.split('?'):props.match.params.presetId;
    presetId = presetId?presetId[0]:presetId;

    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [listData, setListData] = useState(null);
    const [listDataOptions, setListDataOptions] = useState(null);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displaNameForAdapters, setDisplaNameForAdapters] = useState(false);
    const [cId, setCId] = useState(false);
    const messages = useRef(null);

    const handleChange = (param) => {
        let temp = [...listData];
        var index = temp.indexOf(param.target.name);
        if (index !== -1) {
            temp.splice(index, 1);
        }else{
            temp.push(param.target.name)
        }
        setListData(temp)
    };

    const handleCheck = (item) => {
        return listData.includes(item)
    };


    const addMessages = (message) => {
        messages.current.show([
          {
            severity: "success",
            summary: "Success.",
            detail: message,
            sticky: false
          }
        ]);
    };


    const sidebar = useRef();

    const [DOMAIN_EDIT_MUTATION_CONTAINER, { loading: mutationLoading, error: mutationError, data }] = useMutation(
        DOMAIN_Edit_MUTATION,
        {
            onCompleted(data) {
              confirm(data);
            }
          }
        );

    const [AD_ADAPTERSELECTION_UPDATE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
        AD_ADAPTERSELECTION_UPDATE_MUTATION,
        {
            onCompleted(dataUpdate) {
                addMessages("Adapter Selections Updated.");
                setTimeout(refreshPage, 3000);
            }
            }
        );

    useEffect(() => {
        const search = window.location.search; // returns the URL query String
        const params = new URLSearchParams(search);
        setCId(params.get('cid'));
        DOMAIN_EDIT_MUTATION_CONTAINER({
            variables: { id: presetId, cid: params.get('cid')}
        });
    }, []);

    const refreshPage = ()=>{
        window.location.reload();
    }
    
    const confirm = data => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo)
        setDisplaNameForAdapters(temp_data.display_name_adapters)
        setListData(temp_data.adapter_list.map(function(item) {
            return item;
        }))
        setListDataOptions(temp_data.adapter_list_choices.map(function(item) {
            return item;
        }))
        setMutationDataCompleted(true)
    }

    const onMenuItemClick = () => {

    }


    const saveAdapterselectionList = () => {
        AD_ADAPTERSELECTION_UPDATE_MUTATION_CONTAINER({
            variables: { id: presetId, adapterselectionList: JSON.stringify(listData)}
        });
    }


    return (
        <div className="center w85">
            {mutationDataCompleted && (
                <div className={wrapperClass} >
                    {cId !== false && (
                        <AppTopbar {...props} domain_name={false} preset_name={true}  />
                    )}
                

                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} >
                        <AppLogo />
                        <DomainsDropdown />
                            {cId !== false && (
                                <AppMenu {...props} model={'preset'} onMenuItemClick={onMenuItemClick}  clientInfo={props.clientInfo} sourceData={props.sourceData}/>
                            )}
                        
                    </div>
                </CSSTransition>

                <div className="layout-main layout-main p-panel p-component">
                    <Messages ref={messages} />
                    <div className="p-panel-header">
                      <div className="p-d-flex p-ai-center">
                        <label htmlFor="cb1" className="p-checkbox-label p-pl-2 p-text-bold" >Preset Adapter Selection</label>
                      </div>
                      <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => saveAdapterselectionList()} />
                    </div>
                        <div className="p-panel-content">
                          <div className="p-grid">
                            {
                                listDataOptions.map((item) => {
                                    return (
                                        <div key={item} className="p-field-checkbox p-col-12 p-sm-12 p-md-4 p-lg-4">

                                            <div className="p-d-flex p-ai-center">
                                                <InputSwitch
                                                    name={item}
                                                    checked={ handleCheck(item) }
                                                    onChange={ handleChange }/>
                                                <label className="p-ml-2">{displaNameForAdapters[item]}</label>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                      </div>
                </div>
            </div>
            )}

        </div>
    );

}

export default PresetAdapterSelection;
