import { InputSwitch } from 'primereact/inputswitch';
const CInputSwitch = (props) => {
  return (
    <div className="p-col-12" style={{display: props.disabled}}>
      <div className="p-d-flex p-ai-center">
        <div className="switch">
          <InputSwitch
            name={props.name}
            checked={props.checked}
            onChange={props.changeEvent}
          />
        </div>
        <div className="content" style={{marginLeft: '0.5em'}}>
          {props.tooltip ?
            <label className="adpositiontooltip after" data-pr-tooltip={props.tooltip}>{props.label_text}</label>
              :
            <label>{props.label_text}</label>
          }
          {
            props.showSideMessage &&
            <span style={{
              display: 'inline-block',
              marginLeft: '5em',
              color: '#ff1100',
              backgroundColor: '#ffeef4',
              padding: '0 6px',
              borderRadius: '3px'
            }}>
              {props.sideMessage}
            </span>
          }
          {
            props.children
          }
          {props.exMessage &&
            <div className="suggestion p-mb-2 p-mt-2">{props.exMessage}</div>
          }
        </div>
      </div>
    </div>
  );
}
export default CInputSwitch;
