import OrderRow from "./OrderRow";
import { InputNumber } from "primereact/inputnumber";

const OrderRowNumberInput = ({ changeHandler, idx, label, name, value, icon, mode, max = null, disabled = false }) => {
    return (
        <OrderRow idx={idx} label={label} name={name} icon={icon}>
            <div className="p-inputgroup">
                {mode === "currency" ? (
                    <>
                        <span className="p-inputgroup-addon">
                            <i className={`pi ${icon}`}></i>
                        </span>
                        <InputNumber
                            id={name + idx}
                            name={name}
                            value={value}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            max={100}
                            onValueChange={(e) => {
                                changeHandler(e, idx);
                            }}
                            disabled={disabled}
                        />
                    </>
                ) : (
                    <>
                        <span className="p-inputgroup-addon">
                            <i className={`pi ${icon}`}></i>
                        </span>
                        <InputNumber
                            id={name + idx}
                            name={name}
                            value={value}
                            mode="decimal"
                            min={1}
                            max={max}
                            onValueChange={(e) => {
                                changeHandler(e, idx);
                            }}
                            disabled={disabled}
                        />
                    </>
                )}
            </div>
        </OrderRow>
    );
};

export default OrderRowNumberInput;
