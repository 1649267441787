import React, { Component, useState, useEffect, useRef } from 'react';
import { AUTH_TOKEN, USER_NAME, AVATAR_LINK } from '../constants'
import { Mutation } from 'react-apollo'
import { gql, useMutation } from '@apollo/client';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import {Password} from 'primereact/password';
import Client from './Client';
import Partner from './Partner';
import { useHistory } from "react-router-dom";
import AppLogo from './AppLogo';

const USER_PASSWORD_RESET_UPDATE_MUTATION = gql`
  mutation callUserPasswordResetUpdateMutation($userData: [String]) {
    userPasswordResetUpdateMutation(input: {userData: $userData}) {
	    success
	    message
    }
  }
`

const ResetPassword = () => {
  const history = useHistory();
	const messages = useRef(null)
	const [userData, setUserData] = useState([]);
	const handleChange = (param) => {
    let name = param.target.name,
        value = param.target.value,
        temp  = Object.assign({}, userData);
    temp[name] = value;
    setUserData( temp );
  };
  const goToLogin = () => {
    history.push("/login");
  };

  const submitPassword = () => {
    const urlParams = new URLSearchParams(window.location.search)

    let temp = {...userData}
    temp['action'] = urlParams.get("action")
    temp['key']    = urlParams.get("key")
    temp['login']  = urlParams.get("login")

    if(!temp['password_for_pass_reset']){
      alert("Please enter your password")
    } else if(!temp['password_for_pass_reset_confirm']){
      alert("Please confirm your password")
    } else if(temp['password_for_pass_reset'] !== temp['password_for_pass_reset_confirm']){
      alert("Passwords donot match")
    }else{
    	USER_PASSWORD_RESET_UPDATE_MUTATION_CONTAINER({
        variables: { userData: JSON.stringify(temp) }
      });
    }
  };
  const addMessages = (message, sev = "success", sum = "Success.") => {
    messages.current.show([
      {
        severity: sev,
        summary: sum,
        detail: message,
        sticky: true
      }
    ]);
	};
	const [USER_PASSWORD_RESET_UPDATE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
		USER_PASSWORD_RESET_UPDATE_MUTATION,
		{
		  onCompleted(dataUpdate) {
		  	let severity = (dataUpdate.userPasswordResetUpdateMutation.success=="true") ? 'success' : 'error'
        addMessages(dataUpdate.userPasswordResetUpdateMutation.message, severity, '');
		  }
		}
	)
  return (

    <div
      className="p-d-flex p-jc-center p-ai-center p-flex-column"
      style={{backgroundColor: "#007cba", position: "absolute", left: "0", right: "0", top: "0", bottom: "0"}}
    >
      
      <div className="p-mb-6">
        <a href="http://adgrid.io/">
          <AppLogo />
        </a>
      </div>
      <Messages ref={messages} />
      <div className="p-shadow-10" style={{
        maxWidth: "320px",
        backgroundColor: "white",
        padding: "30px 25px 23px",
        width: "100%"}}
      >
        <div className="p-d-flex p-flex-column p-mb-2">
        	  <h6>Enter a new password</h6>
          <div className="p-grid p-fluid">
	        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
            <Password id={'password_for_pass_reset'} name="password_for_pass_reset" value={userData['password_for_pass_reset']} onChange={(e) => handleChange(e)} feedback={true} placeHolder={"Please enter a new password"}/>
          </div>
          <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
            <Password id={'password_for_pass_reset_confirm'} name="password_for_pass_reset_confirm" value={userData['password_for_pass_reset_confirm']} onChange={(e) => handleChange(e)} feedback={false} placeHolder={"Reenter your new password"}/>
          </div>
	        <div className="p-mt-3 p-d-flex p-jc-end">
            <Button type="button" label="Request Password Reset" tooltip="Reset Password" icon="pi pi-fw pi-cog" tooltipOptions={{ position: 'top' }} onClick={submitPassword} />
          </div>
          <div className="p-mt-3 p-d-flex p-jc-end">
            <Button type="button" label="Go to Adgrid Login Page" tooltip="Login" icon="pi pi-fw pi-cog" tooltipOptions={{ position: 'top' }} onClick={goToLogin} />
          </div>
	      </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
