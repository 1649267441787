import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import "react-list-editable/lib/react-list-editable.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../App.scss";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import LayoutWrapper from "./LayoutWrapper";
import SectionHeader from "./helper/SectionHeader";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        domainEditMutation(input: { id: $id, cid: $cid }) {
            clientMutationId
            customerInfo
        }
    }
`;

const SUPPLY_CHAIN_UPDATE_MUTATION = gql`
    mutation callUpdateSupplyChain($id: String, $schainGlobal: String, $schainBidder: String ) {
        updateSupplyChain(input: { id: $id, schainGlobal: $schainGlobal, schainBidder: $schainBidder }) {
            clientMutationId
            networkId
        }
    }
`;

const SupplyChain = () => {
    const { currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);

    const [schainGlobal, setSchainGlobal] = useState(null);
    const [schainBidder, setSchainBidder] = useState(null);
    const dispatch = useDispatch();
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('supply_chain');


    // Static JSON data for the instructions Description
    const jsonData = [{
        "asi":"something.com",
        "sid":"305",
        "hp":1
     }];
     const jsonDataBidder = {
        "gumgum": {
            "asi":"something.com",
            "sid":"305",
            "hp":1
        },
        "sovrn": {
            "asi":"something.com",
            "sid":"405",
            "hp":1
        },
     };

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    const [SUPPLY_CHAIN_UPDATE_MUTATION_CONTAINER] = useMutation(SUPPLY_CHAIN_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(pushMessage("Supply Chain", "Supply Chain Data updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentDomain}`, cid: `${currentCustomer}` },
            });
        }
    }, [currentCustomer, currentDomain]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
        setSchainGlobal(temp_data.schain_global);
        setSchainBidder(temp_data.schain_bidder);
        setMutationDataCompleted(true);
    };


    const saveSchainInfo = () => {
        var isValidJSON = true
        if(schainBidder) {try { JSON.parse(schainBidder) } catch(e) { isValidJSON = false }}
        if(schainGlobal) {try { JSON.parse(schainGlobal) } catch(e) { isValidJSON = false }}

        if (!isValidJSON) {
            dispatch(pushMessage("Supply Chain", "Please enter valid Json for the Schain fields", "error"));
            throw new Error("Please enter valid Json");
        }
        else return SUPPLY_CHAIN_UPDATE_MUTATION_CONTAINER({
            variables: { id: `${currentDomain}`, schainGlobal: schainGlobal, schainBidder: schainBidder },
        });
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveSchainInfo}>


            {mutationDataCompleted && (
                <>
            <div className={`p-panel p-component`}>
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content contact-form">
                        <SectionHeader screenName="Supply Chain">
                            <div className="p-d-flex p-ai-center f-width">
                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                    Supply Chain
                                </label>
                            </div>
                        </SectionHeader>
                        <div className="card p-panel-content disable-item">
                            <div className="p-panel-content p-fluid border-bottom-none">
                                <div className="p-grid small-margin-bottom-10">
                                    <Tooltip target=".adpositiontooltip" />
                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label {...tooltip('global', true)} htmlFor="schain_global">Global</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputTextarea
                                            id=""
                                            name="schain_global"
                                            value={schainGlobal}
                                            onChange={(e) => {
                                                setSchainGlobal(e.target.value);
                                                dispatch(registerScreenChanges("Supply Chain"));
                                            }}
                                            rows={13} cols={30}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="p-panel-content p-fluid border-bottom-none">
                                <div className="p-grid small-margin-bottom-10">

                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                        <label {...tooltip('bidder_specific', true)} htmlFor="schain_bidder">Bidder Specific</label>
                                    </div>
                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                        <InputTextarea
                                            id=""
                                            name="schain_bidder"
                                            value={schainBidder}
                                            onChange={(e) => {
                                                setSchainBidder(e.target.value);
                                                dispatch(registerScreenChanges("Supply Chain"));
                                            }}
                                            rows={13} cols={30}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader title="Description">
                            <div className="p-d-flex p-ai-center f-width">
                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                    Description
                                </label>
                            </div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Global</h6>
                                <p>{helpText('help_text____global')}</p>
                                {/*<pre>
                                    {JSON.stringify(jsonData, null, 2)}
                                </pre>*/}
                                <h6 className="p-text-bold p-mb-0">Bidder Specific</h6>
                                <p>{helpText('help_text____bidder_specific')}</p>
                                {/*<pre>
                                    {JSON.stringify(jsonDataBidder, null, 2)}
                                </pre>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </>
            )}
        </LayoutWrapper>
    );
};

export default SupplyChain;
