const ACTIONS = {
    ENQUEUE_ACTION: "ENQUEUE",
    DEQUEUE_ACTION: "DEQUEUE",
    CLEAR_ACTIONS: "CLEAR",
    CHECK_IS_EMPTY: "CHECK_IS_EMPTY",
};

const DEFAULT_STATE = [];

export default function marketplaceActionsQueueReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case ACTIONS.ENQUEUE_ACTION:
            let helper = async function helper() {
                helper.inProgress = true;
                try {
                    await action.op();
                    helper.finished = true;
                } catch {
                    helper.finished = true;
                }
            };
            helper.inProgress = false;
            helper.finished = false;
            return state.concat(helper);
        case ACTIONS.DEQUEUE_ACTION:
            return state.slice(1);
        case ACTIONS.CLEAR_ACTIONS:
            return DEFAULT_STATE;
        default:
            return state;
    }
}

const enqueueMarketplaceAction = (asyncOp) => {
    return {
        type: ACTIONS.ENQUEUE_ACTION,
        op: asyncOp,
    };
};

const dequeueMarketplaceAction = () => {
    return {
        type: ACTIONS.DEQUEUE_ACTION,
    };
};

const clearMarketplaceActions = () => {
    return {
        type: ACTIONS.CLEAR_ACTIONS,
    };
};

const checkMarketplaceQueueSize = () => {
    return {
        type: ACTIONS.CHECK_IS_EMPTY,
    };
};

export { enqueueMarketplaceAction, dequeueMarketplaceAction, clearMarketplaceActions, checkMarketplaceQueueSize };
