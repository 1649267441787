import { gql } from "@apollo/client";

export const COMMON_DOMAIN_INFO_MUTATION = gql`
    mutation callDomainCommonInfoMutation($domainId: String) {
        domainCommonInfoMutation(input: { domainId: $domainId }) {
            domainInfo
        }
    }
`;

export const DOMAIN_SETTINGS_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        domainEditMutation(input: { id: $id, cid: $cid }) {
            clientMutationId
            customerInfo
        }
    }
`;

export const MARKETPLACE_WELCOME_EMAIL_MUTATION = gql`
mutation callMarketplaceNotificationMutation($from: String, $to: String, $subject: String, $partner_logo_url: String, $action: String, $customerId: String) {
    marketplaceNotificationMutation(input: { from: $from, to: $to, subject: $subject, partner_logo_url: $partner_logo_url, action: $action, customerId: $customerId }) {
        response
    }
}
`;

export const SAVE_GENERAL_SETTINGS_MUTATION = gql`
    mutation saveGeneralSettingsMutation(
        $id: String
        $advPreset: String
        $industryOptions: String
        $networkId: String
        $childnetworkId: String
        $enableCLS: Boolean
        $overrideSiteCSS: Boolean
        $ads_ActiveViewTime: String
        $shortDomainName: String
        $bannerFloorsPrice: String
        $adEnableSandboxMode: Boolean
        $adEnableAdhesionIcon: Boolean
        $adInactive: Boolean
        $sendAllBids: Boolean
        $adDisableHBGlobally: Boolean
        $adDisableHB_GloballyByCountries: String
        $adOverridePrebidTimeout: Boolean
        $adPrebidTimeout: String
        $adEnableVideoWrapper: Boolean
        $adgVersion: String
        $stagingURL: String
        $domainMarketplaceNetworks: String
        $enable_marketplace: Boolean
        $marketplaceDomainOverviewData: String
        $marketplaceEmailsList: String
        $video_player_type: String
        $advertisement_label_text: String
        $advertisement_label_align: String
        $advertisement_label_position: String
        $stickyHeaderHeight: String
        $stickyHeaderHeightTab: String
        $stickyHeaderHeightMob: String
        $fromHBSettings: Boolean
        $overrideDisableHBGlobally: Boolean,
        $playerVendor: String
    ) {
        saveGeneralSettingsMutation(
            input: {
                id: $id
                advPreset: $advPreset
                industryOptions: $industryOptions
                networkId: $networkId
                childnetworkId: $childnetworkId
                enableCLS: $enableCLS
                overrideSiteCSS: $overrideSiteCSS
                ads_ActiveViewTime: $ads_ActiveViewTime
                shortDomainName: $shortDomainName
                bannerFloorsPrice: $bannerFloorsPrice
                adEnableSandboxMode: $adEnableSandboxMode
                adEnableAdhesionIcon: $adEnableAdhesionIcon
                adInactive: $adInactive
                sendAllBids: $sendAllBids
                adDisableHBGlobally: $adDisableHBGlobally
                adDisableHB_GloballyByCountries: $adDisableHB_GloballyByCountries
                adOverridePrebidTimeout: $adOverridePrebidTimeout
                adPrebidTimeout: $adPrebidTimeout
                adEnableVideoWrapper: $adEnableVideoWrapper
                adgVersion: $adgVersion
                stagingURL: $stagingURL
                domainMarketplaceNetworks: $domainMarketplaceNetworks
                enable_marketplace: $enable_marketplace
                marketplaceDomainOverviewData: $marketplaceDomainOverviewData
                video_player_type: $video_player_type
                advertisement_label_text: $advertisement_label_text
                advertisement_label_align: $advertisement_label_align
                advertisement_label_position: $advertisement_label_position
                stickyHeaderHeight : $stickyHeaderHeight
                stickyHeaderHeightTab : $stickyHeaderHeightTab
                stickyHeaderHeightMob : $stickyHeaderHeightMob
                marketplaceEmailsList: $marketplaceEmailsList
                fromHBSettings: $fromHBSettings
                overrideDisableHBGlobally: $overrideDisableHBGlobally,
                playerVendor: $playerVendor
            }
        ) {
            networkId
            clientMutationId
        }
    }
`;

export const SAVE_NATIVE_AD_INJECTION_MUTATION = gql`
    mutation callInjectionValueUpdateMutation($id: String, $injectionType: String, $injectionValueList: [String], $mode: String) {
        injectionValueUpdateMutation(input: { id: $id, injectionType: $injectionType, injectionValueList: $injectionValueList, mode: $mode }) {
            clientMutationId
            networkId
        }
    }
`;

export const ADS_TXT_MUTATION = gql`
    mutation callAdsTxtMutation($domainId: String, $operation: String, $data: String, $sellersData: String) {
        adsTxtMutation(input: { domainId: $domainId, operation: $operation, data: $data, sellersData: $sellersData }) {
            adsTxtInfo
        }
    }
`;

export const OPTIMIZE_BIDDING_UPDATE_MUTATION = gql`
    mutation callOptimizeBiddingUpdateMutation(
        $id: String,
        $optimizeBiddingList: String,
        $showCheckBox: Boolean,
        $overrideTrafficShapingSettings: Boolean,
        $domainTrafficShapingSettings: String
    ) {
        optimizeBiddingUpdateMutation(
            input: {
                id: $id,
                optimizeBiddingList: $optimizeBiddingList,
                showCheckBox: $showCheckBox,
                overrideTrafficShapingSettings: $overrideTrafficShapingSettings,
                domainTrafficShapingSettings: $domainTrafficShapingSettings
            }
        ) {
            clientMutationId
            networkId
        }
    }
`;

export const CUSTOM_ADAPTER_SETTINGS_MUTATION = gql`
    mutation callAdapterCustomSettingsMutation($id: String, $customAdapterSettings: String, $isPreset: Boolean, $customAdapterBids: String) {
        adapterCustomSettingsMutation(input: { id: $id, customAdapterSettings: $customAdapterSettings, isPreset: $isPreset, customAdapterBids: $customAdapterBids }) {
            presetInfo
        }
    }
`;

export const AD_POSITION_UPDATE_MUTATION = gql`
    mutation callAdPositionUpdateMutation(
        $id: String,
        $presetid: String,
        $positionList: String,
        $overridePositionList: String,
        $updateSlotPlacementID: Boolean,
        $slotPlacementIDList: String,
        $isVideo: Boolean,
        $isOverridingPreset: Boolean
    ) {
        adPositionUpdateMutation(
            input: {
                id: $id,
                presetid: $presetid,
                positionList: $positionList,
                overridePositionList: $overridePositionList,
                updateSlotPlacementID: $updateSlotPlacementID,
                slotPlacementIDList: $slotPlacementIDList,
                isVideo: $isVideo,
                isOverridingPreset: $isOverridingPreset
            }
        ) {
            clientMutationId
            networkId
        }
    }
`

export const DOMAIN_RESTORE_MUTATION = gql`
    mutation domainRestoreMutation($restoreData: [String]) {
        domainRestoreMutation(input: { restoreData: $restoreData }) {
            networkId
            clientMutationId
            success
            message
        }
    }
`;

export const PUBMATICHUB_UPDATE_MUTATION = gql`
    mutation callUpdatePresetPubmaticHub($id: String, $publisherId: String, $profileId: String, $override: Boolean, $id5Partner: String, $overrideID5: Boolean, $rampPlacementId: String, $overrideRamp: Boolean, $unifiedPartner: String, $overrideUnified: Boolean, $unified2SubscriptionID: String, $unified2PubKey: String, $overrideUnified2: Boolean, $eUIDSubscriptionID: String, $eUIDPubKey: String, $overrideEUID: Boolean, $permutiveAdapters: String, $overridePermutive: Boolean, $enableSharedId: Boolean, $overrideSharedId: Boolean, $enableAtsAnalytics: Boolean ) {
        updatePubmaticHub(input: { id: $id, publisherId: $publisherId, profileId: $profileId, override: $override, id5Partner: $id5Partner, overrideID5: $overrideID5, rampPlacementId: $rampPlacementId, overrideRamp: $overrideRamp, unifiedPartner: $unifiedPartner, overrideUnified: $overrideUnified, unified2SubscriptionID: $unified2SubscriptionID, unified2PubKey: $unified2PubKey, overrideUnified2: $overrideUnified2, eUIDSubscriptionID: $eUIDSubscriptionID, eUIDPubKey: $eUIDPubKey, overrideEUID: $overrideEUID, permutiveAdapters: $permutiveAdapters, overridePermutive: $overridePermutive, enableSharedId: $enableSharedId, overrideSharedId: $overrideSharedId, enableAtsAnalytics: $enableAtsAnalytics }) {
            clientMutationId
            networkId
        }
    }
`;

export const SUPPLY_CHAIN_UPDATE_MUTATION = gql`
    mutation callUpdateSupplyChain($id: String, $schainGlobal: String, $schainBidder: String ) {
        updateSupplyChain(input: { id: $id, schainGlobal: $schainGlobal, schainBidder: $schainBidder }) {
            clientMutationId
            networkId
        }
    }
`;

export const KEY_VALUE_UPDATE_MUTATION = gql`
    mutation callKeyValueUpdateMutation($id: String, $keyValueList: [String]) {
        keyValueUpdateMutation(input: { id: $id, keyValueList: $keyValueList }) {
            clientMutationId
            networkId
        }
    }
`;

export const LAZY_LOAD_UPDATE_MUTATION = gql`
    mutation callLazyloadUpdateMutation($id: String, $lazyLoadList: [String], $lazyLoadRefreshOverrideList: [String], $showCheckBox: Boolean, $showCheckBox2: Boolean) {
        lazyLoadUpdateMutation(input: { id: $id, lazyLoadList: $lazyLoadList, lazyLoadRefreshOverrideList: $lazyLoadRefreshOverrideList, showCheckBox: $showCheckBox, showCheckBox2: $showCheckBox2 }) {
            clientMutationId
            networkId
        }
    }
`;

export const PERFORMANCE_SETTINGS_MUTATION = gql`
    mutation callPerformancePreferencesMutation($id: String, $settingsData: String) {
        performancePreferencesMutation(input: { id: $id, settingsData: $settingsData }) {
            networkId
        }
    }
`;
