import { useMutation } from "@apollo/client";
import { COMMON_DOMAIN_INFO_MUTATION } from "../mutations/mutations";

export function useCommonDomainInfo(options = {}) {
    const [container] = useMutation(COMMON_DOMAIN_INFO_MUTATION, options)

    return ({
        domainId
    }) => {
        return container({
            variables: {
                domainId: String(domainId)
            }
        })
    }
}