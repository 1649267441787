/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { Messages } from "primereact/messages";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { registerScreenChanges } from "../../../reducers/screenChangesReducer";
import { first, getIn } from "../../../utilities/utils";
import { registerAdapterGlobalParametersMutation, registerAdapterMutation } from "../../../reducers/adapterMutationReducer";

const BaseMultiparameterAdapter = ({
    adapterName,
    adapterPrettyName,
    settings,
    adposition,
    adapterParametersNames = [] /* Array of arrays [[parameterName, parameterDataType, parameterPrettyName]] */,
    rowLevelParameters = [] /* Array of arrays [[parameterName, parameterDataType, parameterPrettyName]] */,
    adapterSizes = "ALL_SIZES",
    globalParameters = [] /* Array of arrays [[parameterName, parameterDataType, parameterPrettyName]] */,
    defaultGlobalParameterValues = {},
    sizeKey = null /* special case for adapters such as Pulsepoint that require you to specify size but do so using a bid parameter */,
    render,
    adtype,
    rawPositionsInfo,
    allowRefresh = false,
    customAdapterSettings,
    updateCustomAdapterSettings,
    adaptersBidAdjustment,
    setAdaptersBidAdjustments,
    toggleAdapterSelection,
    isAdapterEnabled,
}) => {
    const s = sizeKey || "size";
    const messages = useRef(null);
    const [mutationData, setMutationData] = useState(null);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const [inputID, setInputID] = useState(null);
    const [isPristine, setIsPristine] = useState(true);
    const [bidAdjustmentPercent, setBidAdjustmentPercent] = useState(adaptersBidAdjustment?.[adapterName] || 0);
    const [globalParams, setGlobalParams] = useState(
        globalParameters.length
            ? globalParameters.reduce((acc, [curr]) => {
                  acc[curr] = curr in defaultGlobalParameterValues ? defaultGlobalParameterValues[curr] : "";
                  return acc;
              }, {})
            : null
    );

    const dispatch = useDispatch();

    /* setting "allow_refresh" to false as default for all adapters that have that input parameter */
    useEffect(() => {
        if (allowRefresh && !(adapterName in customAdapterSettings)) {
            updateCustomAdapterSettings({ ...customAdapterSettings, [adapterName]: { allow_refresh: false } });
        }
    }, [customAdapterSettings]);

    useEffect(() => {
        if (adtype !== "video") {
            setAdaptersBidAdjustments({ ...adaptersBidAdjustment, [adapterName]: bidAdjustmentPercent });
        }
    }, [bidAdjustmentPercent]);

    useEffect(() => {
        dispatch(registerAdapterGlobalParametersMutation(adapterName, globalParams))
    }, [globalParams])

    useEffect(() => {
        let temp_arr = settings || {};
        let final_arr = [];
        let i = 0;
        let size = [];
        let final_size = [];

        let adapter_keys = Object.keys(temp_arr);

        const setGlobalParameters = (device) => {
            if (!globalParameters.length) return;

            setGlobalParams(
                globalParameters.reduce((acc, [curr, type]) => {
                    const paramValue = device && curr in device ? device[curr] : defaultGlobalParameterValues[curr];
                    acc[curr] = type === "number" ? parseFloat(paramValue) : paramValue;
                    return acc;
                }, {})
            );

        };

        adposition.forEach((position) => {
            const machineKey = position.machine_key;
            let final_size_index = 0;

            if (adapter_keys.includes(machineKey)) {
                const element = temp_arr[machineKey];
                const { desk, tab, mob } = element;

                function updateSizes(slotConfig, device) {
                    let config;
                    switch (device) {
                        case "desk":
                            config = desk[slotConfig];
                            break;
                        case "tab":
                            config = tab[slotConfig];
                            break;
                        default:
                            config = mob[slotConfig];
                    }

                    if ("sideId" in config) {
                        config.siteId = config.sideId;
                        delete config.sideId;
                    }

                    const extractParamValues = () => {
                        const result = [...adapterParametersNames, ...rowLevelParameters].reduce((result, [adapterParam]) => {
                            result[adapterParam] = config[adapterParam];
                            return result;
                        }, {});
                        return result;
                    };

                    if (adapterSizes === "ALL_SIZES") {
                        size[slotConfig] = { ...size[slotConfig], [device]: extractParamValues() };
                    } else {
                        const slotSize = s === "size" ? config[s].join("X") : config[s];
                        size[slotSize] = { ...size[slotSize], [s]: { name: slotSize }, [device]: extractParamValues() };
                    }
                }

                if (adtype !== "video") {
                    if (desk) {
                        Object.keys(desk).forEach((slotConfig) => updateSizes(slotConfig, "desk"));
                        setGlobalParameters(first(desk));
                    }
                    if (tab) {
                        Object.keys(tab).forEach((slotConfig) => updateSizes(slotConfig, "tab"));
                        setGlobalParameters(first(tab));
                    }
                    if (mob) {
                        Object.keys(mob).forEach((slotConfig) => updateSizes(slotConfig, "mob"));
                        setGlobalParameters(first(mob));
                    }
                    Object.keys(size).forEach((s) => {
                        final_size[final_size_index] = size[s];
                        ++final_size_index;
                    });
                } else {
                    const adpositionConfigObject = getIn(temp_arr, machineKey) || {};
                    setGlobalParameters(first(adpositionConfigObject));
                    Object.keys(adpositionConfigObject).forEach((slot) => {
                        final_size.push(getIn(adpositionConfigObject, slot));
                    });
                }

                final_arr[i] = {
                    ...final_arr[i],
                    name: machineKey,
                    display_name: position.display_name,
                    size: final_size,
                };
                i++;
            } else {
                final_arr[i] = {
                    ...final_arr[i],
                    name: machineKey,
                    display_name: position.display_name,
                    size: [],
                };
                i++;
            }
            size = [];
            final_size = [];
        });

        setMutationData(final_arr);
        setMutationDataCompleted(true);
    }, []);

    const dialogFuncMap = {
        displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, index, j) => {
        setPositionFinal(index);
        setInputID(j);
        dialogFuncMap[`${name}`](true);
        dispatch(registerScreenChanges("SSP Adapter"));
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const renderFooter = (name, index) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => {
                        onHide(name);
                        handleRemoveClick(index);
                        dispatch(registerScreenChanges("SSP Adapter"));
                    }}
                    autoFocus
                />
            </div>
        );
    };

    const handleAddClick = ({ i, isVideoAdapter, params }) => {
        let temp = [...mutationData];
        if (isVideoAdapter) {
            temp[i].size.push(params);
        } else {
            temp[i].size.push({ desk: "", tab: "", mob: "" });
        }
        dispatch(registerScreenChanges("SSP Adapter"));
        setMutationData(temp);
    };

    const handleRemoveClick = () => {
        const list = [...mutationData];
        list[positionFinal].size.splice(inputID, 1);
        dispatch(registerScreenChanges("SSP Adapter"));
        setMutationData(list);
        dispatch(
            registerAdapterMutation(adapterName, list[positionFinal].name, list[positionFinal].size)
        )
    };

    const handleChange = ({ param, key, id, isVideoAdapter, rowLevelParameter }) => {
        const { name, value, id: device } = param.target;
        let index = key;
        let inputID = id;
        let temp = [...mutationData];
        const inputDevice = getIn(temp, index, "size", inputID);

        if (rowLevelParameter) {
            let rowParametersConfigMap = temp[index].size[id];

            for (let deviceSize in rowParametersConfigMap) {
                if (rowParametersConfigMap[deviceSize]) {
                    rowParametersConfigMap[deviceSize][name] = value;
                }
            }
        } else if (isVideoAdapter) {
            inputDevice[name] = value;
        } else if (name === "adp_size") {
            temp[index].size[inputID][s] = value;
        } else {
            if (!inputDevice[device]) {
                inputDevice[device] = {};
            }
            if (rowLevelParameters.length) {
                rowLevelParameters
                    .filter(([paramName, type]) => {
                        return type === "boolean" && !(paramName in inputDevice[device]);
                    })
                    .forEach(([paramName]) => {
                        inputDevice[device][paramName] = false;
                    });
            }
            inputDevice[device][name] = value;
        }

        dispatch(registerScreenChanges("SSP Adapter"));
        setMutationData(temp);
        dispatch(
            registerAdapterMutation(adapterName, temp[index].name, [...temp[index].size])
        )
    };

    return (
        <div>
            <Messages ref={messages} />
            <Accordion className="adapter-accordion">
                <AccordionTab
                    headerClassName="adapter-accordion-tab"
                    contentClassName="adapter-accordion-body"
                    header={
                        <React.Fragment>
                            <div className="p-d-flex p-jc-between">
                                <div className="p-d-flex p-ai-center">
                                    <InputSwitch
                                        checked={isAdapterEnabled}
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            ev.stopPropagation()
                                            toggleAdapterSelection(adapterName)
                                        }}
                                        className="enable-adapter-switch"
                                    />
                                    <span className="p-ml-2 p-text-uppercase">{adapterPrettyName ? adapterPrettyName.toUpperCase() : adapterName.toUpperCase()}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                >
                    <div className="datatable-responsive">
                        <div className="p-datatable p-component p-datatable-striped p-datatable-responsive">
                            <div className="p-datatable-wrapper">
                                <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter("displayConfirmation")} onHide={() => onHide("displayConfirmation")}>
                                    <div className="confirmation-content p-d-flex p-ai-center">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to delete this item?</span>
                                    </div>
                                </Dialog>
                                {mutationDataCompleted &&
                                    render({
                                        mutationData,
                                        adapterName,
                                        adapterPrettyName,
                                        adapterParametersNames,
                                        adapterSizes,
                                        globalParams: globalParameters.reduce((acc, [curr, parameterDataType, globalParameterPrettyName]) => {
                                            acc[curr] = [globalParams[curr], globalParameterPrettyName, parameterDataType];
                                            return acc;
                                        }, {}),
                                        rowLevelParameters,
                                        sizeKey,
                                        adtype,
                                        handleChange,
                                        onClick,
                                        handleAddClick,
                                        setGlobalParams,
                                        setIsPristine,
                                        rawPositionsInfo,
                                        allowRefresh,
                                        customAdapterSettings,
                                        updateCustomAdapterSettings,
                                        bidAdjustmentPercent,
                                        setBidAdjustmentPercent,
                                    })}
                            </div>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default BaseMultiparameterAdapter;
