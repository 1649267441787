import deepcopy from "deepcopy"
import { displayAdapters, videoAdaptersConfig } from "../../../constants"

/**
 * Saves the data that has been changes for adapters
 * 
 * @param {Object} adapterData - The data of the adapter to be saved.
 * @param {Function} mutationSaveCallback - A callback function that takes one argument:
 * @param {Object} mutationSaveCallback.data - An object passed to the callback function.
 * @returns {Promise<Object>} A promise that resolves to an object.
 */

export function saveAdapterData(adapterData, mutationSaveCallback) {
    return mutationSaveCallback(adapterData)
}

/**
 * 
 * @param {Object} sspData 
 * @param {Object[]} positions 
 * @param {String} positions[].display_name
 * @param {String} positions[].machine_key
 * @param {String} positions[].hbid_list_exclude
 * @returns {Object}
 */

export function shapeSspData(sspData, positions) {
    let result = positions.reduce((acc, curr) => {
        acc[curr.machine_key] = {}
        return acc
    }, {})

    for (let [adapterName, positionValues] of Object.entries(sspData)) {
        for (let [positionName, rows] of Object.entries(positionValues)) {
            if (positionName in result) {
                result[positionName][adapterName] = rows || {}
            }
        }
    }

    return result
}

/**
 * 
 * @param {Object} sspData 
 * @param {Object} adapterData
 * @returns {Object}
 */

export function mergeUpdatedAdapterData(sspData, adapterData) {
    // let sspDataClone = deepcopy(sspData)
    try {
            
            function __getParametersDataTypeMappings(adapterName) {
                return displayAdapters[adapterName].adapterParametersNames
                    .reduce((acc, curr) => {
                        let [parameterName, type] = curr
                        acc[parameterName] = type
                        return acc
                    }, {})
            }
            function __extractParameters(adapterName) {
                return displayAdapters[adapterName].adapterParametersNames
                    .reduce((acc, curr) => {
                        let [parameterName] = curr
                        acc.push(parameterName)
                        return acc
                    }, [])
            }
            function __getDefaultParameterValues(adapterName) {
                return displayAdapters[adapterName].adapterParametersNames
                    .reduce((acc, curr) => {
                        let [parameterName] = curr
                        acc[parameterName] = ''
                        return acc
                    }, {})
            }
            function __enforceDefaults(device, defaultParameterValues, currentParameterValues, parameterNames, parameterDataTypeMappings) {
                
                if (typeof currentParameterValues[device] !== 'object') {
                    currentParameterValues[device] = defaultParameterValues
                    return
                }
                parameterNames.forEach(p => {
                    if (p in currentParameterValues[device]) {
                        let paramValue = currentParameterValues[device][p]
                        if (paramValue) {
                            currentParameterValues[device][p] = parameterDataTypeMappings[p] === 'number'
                                ? parseFloat(paramValue)
                                : paramValue
                        }
                        return
                    }
                    currentParameterValues[device][p] = ''
                })
            }
            function __defineNestedParameters(adapterName, parameterValues) {
                let nestedParameters = displayAdapters[adapterName]['nestedParameters'] || {}
                return Object
                    .keys(parameterValues)
                    .reduce((acc, curr) => {
                        if (curr in nestedParameters) {
                            let key = nestedParameters[curr]
                            acc[key] = acc[key] || {}
                            acc[key][curr] = parameterValues[curr]
                            delete parameterValues[curr]
                        } else {
                            acc[curr] = parameterValues[curr]
                        }
                        return acc
                    }, {})
            }

            let adapterDataCopy = deepcopy(adapterData)
            for (let [adapterName, adunitData] of Object.entries(adapterDataCopy)) {
                let adapterParametersDatatypeMappings = __getParametersDataTypeMappings(adapterName)
                let parameterNames = __extractParameters(adapterName)
                let defaultParametersObject = __getDefaultParameterValues(adapterName)
        
                if (!(adapterName in sspData)) {
                    sspData[adapterName] = {}
                }
        
                /* resetting object state */
                Object.keys(adunitData).forEach(adunitName => {
                    sspData[adapterName][adunitName] = {}
                })
        
                /* enforcing data type consistency and setting default values */
                for (let [_, values] of Object.entries(adunitData)) {
                    values.forEach(rowValue => {
                        __enforceDefaults('desk', defaultParametersObject, rowValue, parameterNames, adapterParametersDatatypeMappings)
                        __enforceDefaults('mob', defaultParametersObject, rowValue, parameterNames, adapterParametersDatatypeMappings)
                        __enforceDefaults('tab', defaultParametersObject, rowValue, parameterNames, adapterParametersDatatypeMappings)
                    })
                }
        
                for (let [adunitName, values] of Object.entries(adunitData)) {
                    
                    values.forEach((v, idx) => {
                        let nestedParameters = displayAdapters[adapterName].nestedParameters

                        if (nestedParameters) {
                            v.desk = __defineNestedParameters(adapterName, v.desk)
                            v.tab = __defineNestedParameters(adapterName, v.tab)
                            v.mob = __defineNestedParameters(adapterName, v.mob)
                        }
                        
                        if (sspData[adapterName][adunitName].desk) {
                            sspData[adapterName][adunitName].desk = { ...sspData[adapterName][adunitName].desk, [idx]: v.desk }
                        } else {
                            sspData[adapterName][adunitName].desk = { [idx]: v.desk }
                        }

                        if (sspData[adapterName][adunitName].tab) {
                            sspData[adapterName][adunitName].tab = { ...sspData[adapterName][adunitName].tab, [idx]: v.tab }
                        } else {
                            sspData[adapterName][adunitName].tab = { [idx]: v.tab, }
                        }

                        if (sspData[adapterName][adunitName].mob) {
                            sspData[adapterName][adunitName].mob = { ...sspData[adapterName][adunitName].mob, [idx]: v.mob }
                        } else {
                            sspData[adapterName][adunitName].mob = { [idx]: v.mob }
                        }
                    })
                }
        
                /* enforce global parameters are set up */ 
                
            }
            
            return sspData

    } catch (err) {
        console.log(err)
    }
}

/**
 * 
 * @param {Object} adUnitConfiguration 
 * @returns {Object}
 */

export function getSspConfigFromAdunits(adUnitConfiguration, type = 'display') {
    
    let result = {}
    if (!adUnitConfiguration) {
        return result;
    }
    function __flattenNestedParameters(conf) {
        for (let [rowIdx, parameterValues] of Object.entries(conf)) {
            for (let [parameterName, parameterValue] of Object.entries(parameterValues)) {
                if (typeof parameterValue === 'object') {
                    conf[rowIdx] = {...conf[rowIdx], ...parameterValue}
                    delete conf[rowIdx][parameterName]
                }
            }
        }
        return conf
    }
    Object.keys(adUnitConfiguration).forEach((adUnitName) => {
        Object.keys(adUnitConfiguration[adUnitName]).forEach((adapterName) => {
            let hasNestedParameters = type === 'display'
                ? !!displayAdapters[adapterName]?.['nestedParameters']
                : !!videoAdaptersConfig[adapterName]?.['nestedParameters']
            let conf = adUnitConfiguration[adUnitName][adapterName]
            
            if (hasNestedParameters && type === 'display') {
                conf.desk = __flattenNestedParameters(conf.desk || {})
                conf.mob = __flattenNestedParameters(conf.mob || {})
                conf.tab = __flattenNestedParameters(conf.tab || {})
            }

            result[adapterName] = {
                ...result[adapterName],
                [adUnitName]: conf,
            };
        });
    });
    
    return result;
}

/**
 * 
 * @param {*} sspData 
 * @param {*} adapterData 
 * @returns {Object}
 */

export function mergeVideoSspData(sspData, adapterData) {
    /**
     * 
     * @param {Array} coll 
     * @returns {Object}
     */

    function __toObject(coll) {
        return coll.reduce((acc, curr, idx) => {
            acc[idx] = curr
            return acc
        }, Object.create(null))
    }

    function __swapSspConfigProperties(sspConfig, globalParameters = null) {
        try {
            let result = {}
            for (let [sspAdapterName, adUnitConfig] of Object.entries(sspConfig)) {
                for (let [adUnitName, values] of Object.entries(adUnitConfig)) {
                    if (!(adUnitName in result)) {
                        result[adUnitName] = {}
                    }
    
                    let globalParams = globalParameters?.[sspAdapterName] || {}
    
                    if (Array.isArray(values)) {
                        result[adUnitName][sspAdapterName] = __toObject(values.map(v => ({...v, ...globalParams})))
                    } else {
                        for (let p in values) {
                            values[p] = {...values[p], ...globalParams}
                        }
                        result[adUnitName][sspAdapterName] = values
                    }
                }
            }
            return result
        } catch (err) {
            return sspData
        }
    }

    if (!adapterData || !Object.keys(adapterData).length) {
        return __swapSspConfigProperties(sspData)
    }

    let sspDataCopy = deepcopy(sspData);
    let adapterDataCopy = deepcopy(adapterData);
    let globalData = {...sspDataCopy.global, ...adapterDataCopy.global}
    delete sspDataCopy.global;
    delete adapterDataCopy.global;

    let merge = {};

    let sspDataObject = __swapSspConfigProperties(sspDataCopy, globalData)
    let adapterDataObject = __swapSspConfigProperties(adapterDataCopy, globalData)

    for (let [adUnitName, adUnitSspConfig] of Object.entries(adapterDataObject)) {
        merge[adUnitName] = {...sspDataObject[adUnitName], ...adUnitSspConfig}
    }

    return merge
}

export function enforceGlobalParameters(sspData, globalParameters) {
    try {
        let copy = deepcopy(sspData)
        
        for (let [adapterName, adapterConfig] of Object.entries(copy)) {
            for (let [__, adUnitConfig] of Object.entries(adapterConfig)) {
                
                for (let idx in adUnitConfig.desk) {
                    adUnitConfig.desk[idx] = adUnitConfig.desk[idx]
                        ? {...adUnitConfig.desk[idx], ...globalParameters[adapterName]}
                        : {...globalParameters}
                }

                for (let idx in adUnitConfig.mob) {
                    adUnitConfig.mob[idx] = adUnitConfig.mob[idx]
                        ? {...adUnitConfig.mob[idx], ...globalParameters[adapterName]}
                        : {...globalParameters}
                }

                for (let idx in adUnitConfig.tab) {
                    adUnitConfig.tab[idx] = adUnitConfig.tab[idx]
                        ? {...adUnitConfig.tab[idx], ...globalParameters[adapterName]}
                        : {...globalParameters[adapterName]}
                }
            }
        }
        return copy
    } catch (err) {
        return sspData
    }
}
