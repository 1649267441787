/** @jsxImportSource @emotion/react */
import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MarketplaceGamDataContext } from "../../../context/marketplaceGamDataContext";
import { MarketplaceGlobalDataContext } from "../../../context/marketplaceGlobalDataContext";
import { refreshMarketplaceOrder } from "../../../reducers/marketplaceOrdersReducer";
import { pushMessage } from "../../../reducers/messagingReducer";
import {
    ADGRID_GAM_NETWORK_CODE,
    LINE_ITEM_STATS_MUTATION,
    LINEITEM_ACTIONS,
    LINEITEM_STATUS,
    MARKETPLACE_ACTION_MUTATION,
    MARKETPLACE_DOMAIN_STATUS,
    MARKETPLACE_ORDERS_REVIEW_MUTATION
} from "./constants";
import Cta, { PauseCta, PlayCta } from "./CTA";
import { requestLineItemsStats } from "./functions";

export default function MarketplaceRowControls({
    deleteTemporaryProperties,
    deleteOrderHandler,
    updateOrderStats,
    order,
    idx,
    currentCustomer
}) {
    const dispatch = useDispatch();
    const { gamStats } = useContext(MarketplaceGamDataContext)
    const { domainToNetworkMappings, allOrders } = useContext(MarketplaceGlobalDataContext)
    const [marketplaceActionMutation] = useMutation(MARKETPLACE_ACTION_MUTATION);
    const [marketplaceOrderReviewMutation] = useMutation(MARKETPLACE_ORDERS_REVIEW_MUTATION);
    const [lineItemStatsMutation] = useMutation(LINE_ITEM_STATS_MUTATION);
    const [showPauseControl, setShowPauseControl] = useState(false);
    const [showResumeControl, setShowResumeControl] = useState(false);
    const { lineItemId } = order
    const orderStatus = gamStats?.[lineItemId]?.status || 'Draft'

    useEffect(() => {
        if (lineItemId) {
            if (orderStatus === LINEITEM_STATUS.PAUSED) {
                setShowResumeControl(true);
                setShowPauseControl(false);
            }
            if (orderStatus === LINEITEM_STATUS.READY || orderStatus === LINEITEM_STATUS.DELIVERING) {
                setShowResumeControl(false);
                setShowPauseControl(true);
            }
        }
    }, [orderStatus, lineItemId]);

    async function marketplaceOrderActionHandler() {
        try {
            let networks = {};
            let newStatuses = { ...order.networkStatuses };
            let domains = [];

            for (let [domainId, { status }] of Object.entries(order.networkStatuses)) {
                let isRejected = status === MARKETPLACE_DOMAIN_STATUS.REJECTED
                let isPending = status === MARKETPLACE_DOMAIN_STATUS.PENDING

                if (isRejected || isPending) {
                    continue;
                }

                let isRunning = status === MARKETPLACE_DOMAIN_STATUS.APPROVED ||
                    status === MARKETPLACE_DOMAIN_STATUS.RESUMED ||
                    status === MARKETPLACE_DOMAIN_STATUS.RESUMED_BY_BUYER ||
                    status === MARKETPLACE_DOMAIN_STATUS.DELIVERING ||
                    status === MARKETPLACE_DOMAIN_STATUS.RESUMED_BY_SELLER;

                let networkCode = domainToNetworkMappings[domainId]?.networkCode;

                if (!networkCode) {
                    delete order.networkStatuses?.[domainId];
                    continue;
                }

                networks[networkCode] = networks[networkCode] || [];

                if (order.domainToLineItemsMappings[domainId]) {
                    networks[networkCode].push(order.domainToLineItemsMappings[domainId]);
                }

                newStatuses[domainId] = { status: isRunning ? MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_BUYER : MARKETPLACE_DOMAIN_STATUS.RESUMED };
                domains.push(domainId);
            }

            networks[ADGRID_GAM_NETWORK_CODE] = [lineItemId];
            order.networkStatuses = newStatuses;
            order.inProgress = true;
            order.pausing = showPauseControl;
            order.resuming = showResumeControl;

            await marketplaceActionMutation({
                variables: {
                    networks: JSON.stringify(networks),
                    action: showPauseControl ? LINEITEM_ACTIONS.PAUSE : LINEITEM_ACTIONS.RESUME,
                },
            });

            let response = await requestLineItemsStats(
                allOrders.filter(order => order.lineItemId).map(order => order.lineItemId),
                lineItemStatsMutation
            );

            // updateOrderStats(lineItemId, response[lineItemId])
            updateOrderStats(response)

            await marketplaceOrderReviewMutation({
                variables: {
                    domainIds: JSON.stringify(domains),
                    domainStatus: showPauseControl ? MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_BUYER : MARKETPLACE_DOMAIN_STATUS.RESUMED,
                    marketplace_order_id: String(order.wpMarketplacePostId),
                },
            });

            deleteTemporaryProperties(order);
            dispatch(refreshMarketplaceOrder(order, idx, currentCustomer));
            dispatch(pushMessage("Marketplace: ", "Order has been updated"));

        } catch (err) {
            deleteTemporaryProperties(order);
            dispatch(refreshMarketplaceOrder(order, idx, currentCustomer));
            dispatch(pushMessage("Marketplace: ", err.message, "error"));
        }
    }

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                margin-right: 1em;
                .order-info {
                    margin-right: 1em;
                    .status-code {
                    }
                }
                .status {
                    margin-bottom: 0;
                    font-weight: 400;
                }
                .status-code {
                    text-transform: lowercase;
                }
            `}
        >
            {lineItemId ? (
                <div className="order-info">
                    <span className="status">
                        {orderStatus ? (
                            <>
                                Order status: <span className={`status-code ${orderStatus}`}>{orderStatus}</span>
                            </>
                        ) : (
                            <>
                                Retrieving status <i className="pi pi-spin pi-spinner" style={{ fontSize: "1em" }}></i>
                            </>
                        )}
                    </span>
                </div>
            ) : (
                <div className="order-info">
                    <span className="status">Order status: Draft</span>
                </div>
            )}

            <div>
                <div>
                    {showPauseControl && (
                        <PauseCta
                            ctaText="Pause Order"
                            themeClasses="marketplace-cta outlined warning"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                marketplaceOrderActionHandler();
                            }}
                        />
                    )}
                    {showResumeControl && (
                        <PlayCta
                            ctaText="Resume Order"
                            themeClasses="marketplace-cta outlined success"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                marketplaceOrderActionHandler();
                            }}
                        />
                    )}
                </div>

                {!lineItemId && (
                    <div>
                        <Cta
                            ctaText="Delete order"
                            icon={faCircleXmark}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                deleteOrderHandler();
                            }}
                            themeClasses="marketplace-cta outlined danger"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
