import { createContext } from "react";

const MarketplaceOrderDataContext = createContext({
    currentOrder: {},
    currentOrderIdx: null,
    allOrders: {},
    customerRate: 10,
    currentCustomer: null
})

export default MarketplaceOrderDataContext