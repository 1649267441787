/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { marketplaceSidebarInfoStyle } from "./constants";

export const networkRowStyles = css`
    display: flex;
    align-items: center;
    button {
        width: 150px !important;
    }
    .selected {
        margin-left: 1em;
        font-weight: bold;
    }
`

export const audienceTargetingRowStyles = css`
    display: flex;
    align-items: center;
    button {
        width: 150px !important;
    }
    .selected {
        margin-left: 1em;
        font-weight: bold;
    }
`

export const statusTableStyles = css`
    display: flex;
    justify-content: space-between;
    .status-table {
        flex-grow: 0.75;
        flex-basis: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        button {
            margin-top: auto;
        }
    }
    .sidebar-info {
        flex-basis: 25%;
        flex-grow: 0.25;
        background-color: #f8f9fa;
        padding: 0 1em;
    }
    table {
        width: 100%;
    }
    .management-notice {
        background-color: #c2eac3 !important;
        padding: 1em;
        text-align: center;
    }
`

export const sidebarStyles = css`
    ${marketplaceSidebarInfoStyle};
    margin: 0;
    padding: 0 1em;
    .current-spend,
    .estimated-spend {
        flex-grow: 1;
        margin: 0 0 0.25em 0;
    }
`

export const sidebarInfoStyles = css`
    background-color: #f8f9fa;
    padding: 0 1em;
`

export const managementSidebarStyles = css`
    ${marketplaceSidebarInfoStyle};
    margin: 0;
    padding: 0 1em;
    justify-content: flex-start;
    padding-left: 0;
    margin-left: 0;
    .current-spend,
    .estimated-spend {
        flex-grow: 1;
        margin: 0 0 0.25em 0;
    }
`

export const gridControlStyles = css`
    flex-wrap: no-wrap;
    .m-controls {
        order: 1;
        .p-grid {
            margin-top: -7px;
        }
    }
    @media (min-width: 768px) {
        .m-controls {
            flex-basis: 100%;
        }
    }
    @media (min-width: 1200px) {
        .m-controls {
            order: -1;
            flex-basis: 75%;
            max-width: 75%;
        }
    }
`;

export const buyReviewSectionStyles = css`
    position: relative;
    align-items: center;
    .text-black {
        color: #000;
    }
    .text-faded {
        opacity: 0.75;
    }
    .marketplace-new-orders {
        right: 0;
        display: flex;
        .marketplace-cta {
            margin-left: 1em;
        }
    }
`
export const paginatorCtaStyles = css`

`

export const marketplaceDataLoadStyle = css`
    align-items: center;
    position: fixed;
    bottom: 6em;
    right: 1em;
    z-index: 9999;
    background-color: #2196f3;
    border-radius: 3px;
    padding: 1em;
    color: #fff;
    .message {
        margin-left: 1em;
    }
`

export const marketplaceSellerHeaderStyle = css`
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    .mrktplc-order {
        color: #495057;
        font-weight: 400;
    }
`

export const deliveringStatusBadgeStyle = css`
    display: inline-block;
    margin-left: 1em;
    background-color: #5ba457;
    padding: 6px 16px;
    color: #fff;
    border-radius: 3px;
`

export const pendingOrderViewStyle = css`
    display: flex;
    align-items: center;
    margin-left: 1em;
    padding: 0.25em 0.5em;
    color: #fff;
    background-color: #505c5f;
    animation: pulse-animation 2s infinite;
    border-radius: 3px;
    @keyframes pulse-animation {
        0% {
            background-color: #000;
        }
        50% {
            background-color: #505c5f;
        }
        100% {
            background-color: #000;
        }
    }
`

export const utilityStyles = css`
    display: flex;
    flex-direction: row;
    .item {
        margin-bottom: 0;
        margin-right: 1em;
        font-size: 12px;
        font-weight: 400;
    }
`

export const paymentRegistrationBannerHeaderStyles = css`
    display: flex;
    align-items: center;
    p {
        flex-basis: 100%;
        text-align: center;
    }
    .pi {
        font-size: 2em;
        color: red;
    }
`

export const paymentRegistrationBannerBodyStyles = css`
    padding: 1em 0px;
    border-radius: 3px;
    > p {
        font-size: 1.25em;
    }
    a {
        color: red;
        border-bottom: 2px solid red;
    }
`