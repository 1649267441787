/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import Cta from "./CTA";
import { useDispatch } from "react-redux";
import { editMarketplaceOrder, removeOrder } from "../../../reducers/marketplaceOrdersReducer";
import { MARKETPLACE_ORDER_ACTIONS, SET_MARKETPLACE_ORDER_AS_DRAFT } from "./constants";
import { useMutation } from "@apollo/client";

const styles = css`
    display: flex;
    justify-content: flex-end;
    .marketplace-cta {
        margin: 0 1em;
    }
`

export default function MarketplaceOrderDeletionBanner({
    dismissDeleteDialog,
    showDeleteDialog,
    marketplaceOrders,
    idxToDelete,
    currentCustomer,
}) {
    const dispatch = useDispatch()
    const [setOrderAsDraftMutation] = useMutation(SET_MARKETPLACE_ORDER_AS_DRAFT);
    
    return (
        <Dialog header="Confirm order deletion" visible={showDeleteDialog} style={{ width: "20vw" }} onHide={dismissDeleteDialog}>
            <div>
                <p style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faInfoCircle} color="#666" fontSize="1em" /> Are you sure you want to proceed?
                </p>
                <div css={styles}>
                    <Cta
                        ctaText="Proceed"
                        onClick={async () => {
                            let marketplaceOrderCopy = { ...marketplaceOrders[idxToDelete] };
                            marketplaceOrderCopy.toDelete = true;
                            dispatch(editMarketplaceOrder(marketplaceOrderCopy, idxToDelete, currentCustomer));
                            dispatch(removeOrder(idxToDelete, currentCustomer));
                            dismissDeleteDialog();
                            if (marketplaceOrderCopy.wpMarketplacePostId && !marketplaceOrderCopy.lineItemId) {
                                await setOrderAsDraftMutation({
                                    variables: {
                                        marketplaceOrder: JSON.stringify(marketplaceOrderCopy),
                                        action: MARKETPLACE_ORDER_ACTIONS.ARCHIVE,
                                    },
                                });
                            }
                        }}
                        themeClasses="marketplace-cta outlined success"
                        showLoader={false}
                    />
                    <Cta
                        ctaText="Cancel"
                        onClick={dismissDeleteDialog}
                        themeClasses="marketplace-cta outlined default"
                        showLoader={false}
                    />
                </div>
            </div>
        </Dialog>
    )
}

