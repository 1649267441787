/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const flexRow = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const datePickerStyle = css`
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    margin-top: 10px;
    &:focus-visible,
    &:focus {
        border: none;
        outline: none;
    }
`;

export const flatInput = css`
    input {
        padding: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        &:enabled:focus,
        &:focus,
        &:hover {
            outline: none;
            box-shadow: none !important;
        }
    }
`;

export const deliverySection = css`
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    @media (min-width: 1400px) {
        & {
            flex-basis: 70%;
        }
    }
`;

export const deliverySubsection = css`
    flex-basis: 100%;
    margin-left: 2em;
    padding-left: 2em;
    border-left: 1px inset #ccc;
`;

export const billingSection = css`
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    @media (min-width: 1400px) {
        & {
            flex-basis: 20%;
        }
    }
`;

export const paymentMissingSection = css`
    padding: 2em 10px;
    border-left: 6px solid #000;
    margin-bottom: 1em;
    background-color: #fff2cb;
    border-radius: 3px;
    > p {
        font-size: 1.25em;
    }
    a {
        color: red;
        border-bottom: 2px solid red;
    }
`;

export const accordionHeaderSection = css`
    margin-top: 0.5em;
    margin-left: calc(1em + 10px);
    span {
        display: inline-block;
        margin-right: 0.5em;
        font-size: 13px;
        font-weight: normal;
        color: #84878b;
        .n {
            color: #495057;
        }
    }
`;

export const generalSettingsSection = css`
    display: flex;
    flex-direction: column;
    .item {
        ${flexRow}
        align-items: center;
        > p {
            min-width: 150px;
            color: #000;
            margin-bottom: 0;
        }
        .label {
            display: inline-block;
            min-width: 130px;
            font-size: 13px;
            margin-left: 10px;
        }
        &:last-of-type {
            margin-top: 10px;
        }
    }
`;

export const cpmSection = css`
    input {
        display: inline;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        width: 50%;
        &:focus,
        &:enabled:focus,
        &:focus-within {
            outline: none;
            box-shadow: none;
        }
    }
`;

export const creativeSettingsSection = css`
    border: 1px solid #dee2e6;
    height: 77vh;
    overflow-y: scroll;
    padding: 0px 10px;
    padding-top: 10px;
    border-radius: 3px;
`;
