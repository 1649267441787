/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import brace from "brace";
import AceEditor from "react-ace";
import "brace/mode/css";
import "brace/mode/javascript";
import "brace/theme/github";
import "brace/theme/twilight";
import "brace/theme/monokai";
import SectionHeader from "./helper/SectionHeader";
import { first, getIn } from "../utilities/utils";
import { useDispatch, useSelector } from "react-redux";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import deepcopy from "deepcopy";
import { pushMessage } from "../reducers/messagingReducer";
import LayoutWrapper from "./LayoutWrapper";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { useNativeInjectionSave } from "../hooks/useNativeInjectionSave";

let insertTypes = [
    { code: "sibling", name: "Append as a Sibling" },
    { code: "psibling", name: "Prepend as a Sibling" },
    { code: "child", name: "Append as a Child" },
    { code: "pchild", name: "Prepend as a Child" },
    { code: "incontent", name: "In Content" },
];

const DEFAULT_AD_INJECTION_OBJECT = [
    {
        native_ad_name: { label: "Name", choices: [], value: "" },
        native_ad_rep_ads_name: { label: "Ads Name", choices: [], value: {} },
        video_player_name: { label: "Ads Name", choices: [], value: {} },
        target_insert_type: { label: "Target Insert Type", choices: insertTypes, value: {} },
        words_no: { label: "Number Of Words", choices: [], value: "" },
        exclude_target_wrapper: { label: "Exclude Target Wrapper", choices: [], value: "" },
        target_wrapper: { label: "Target Wrapper", choices: [], value: "" },
        target_position: { label: "Target Position (Repeat After)", choices: [], value: "" },
        stop_repeat_after: { label: "Disable repeat after number of occurrences", choices: [], value: "" },
        exclutions_urls: { label: "Exclusions URLs", choices: [], value: "" },
        target_urls: { label: "Inclusions URLs", choices: [], value: "" },
        inclusions_user_agent: { label: "Inclusions User Agent", choices: [], value: "" },
        exclusions_user_agent: { label: "Exclusions User Agent", choices: [], value: "" },
        extra_ad_container_height: { label: "Extra Ad Container Height (Desktop & Tablet)", choices: [], value: "" },
        extra_ad_container_height_mob: { label: "Extra Ad Container Height (Mobile)", choices: [], value: "" },
        custom_inline_style: { label: "Custom Inline Style", choices: [], value: "" },
        close_button_delay: { label: "Close Button Delay (seconds)", choices: [], value: "" },
        player_heading: { label: "Player Heading", choices: [], value: "" },
        float_player_on_load: { label: "Float Player On Load", choices: [], value: false },
        video_title: { label: "Video Title ", choices: [], value: false },
        custom_parameter_or_code: { label: "Custom Parameter or Custom Code", choices: [], value: "" },
        enable_on_preview: { label: "Enable On Preview", choices: [], value: false },
        enable_native_ad_injection: { label: "Enable Native Ad Injection", choices: [], value: false },
        disable_refresh: { label: "Disable Refresh", choices: [], value: false },
        is_hidden_ad_target_wrapper: { label: "Is Hidden Ad Target Wrapper", choices: [], value: false },
        enable_shadowdom_support: { label: "Enable ShadowDOM Support", choices: [], value: false },
        shadowdom_target_wrapper: { label: "Shadow Host Target Wrapper", choices: [], value: "" },
        disable_repeat: { label: "Disable Repeat", choices: [], value: false },
        enable_native_ad_for_ajax: { label: "Enable Native Ad for AJAX", choices: [], value: false },
        reset_repeat_counter: { label: "Reset Repeat Counter", choices: [], value: false },
        advertisement_label: { label: "Advertisement Label", choices: [], value: false },
        advertisement_label_position: {
            label: "Advertisement Label Position",
            choices: [
                { code: "before", name: "Before" },
                { code: "after", name: "After" },
            ],
            value: { code: "" },
        },
        advertisement_label_align: {
            label: "Advertisement Label Align",
            choices: [
                { value: "center", name: "Center" },
                { value: "left", name: "Left" },
                { value: "right", name: "Right" },
            ],
            value: "",
        },
    },
];

const AdNativeInjection = ({ mode }) => {
    const CODE_TYPE = "native";
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [clsState, setClsState] = useState(false);
    const [settingsData, setSettingsData] = useState([]);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const { currentDomain, currentCustomer, isAdmin } = useSelector((state) => state.userInformation);
    const commonDomainInfo = useSelector((state) => state.commonDomainInfo);
    const dispatch = useDispatch();
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('injections');

    const handleRemoveClick = (index) => {
        const list = [...settingsData];
        list.splice(index, 1);
        setSettingsData(list);
        dispatch(registerScreenChanges("Ad Injection"));
    };

    const handleAddClick = () => {
        let copy = deepcopy(DEFAULT_AD_INJECTION_OBJECT[0]);
        copy.advertisement_label_position = {
            value: { code: commonDomainInfo[currentDomain]?.advertisement_label_position || DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_position.choices[1].code },
        };
        copy.advertisement_label_align = {
            value: commonDomainInfo[currentDomain]?.advertisement_label_align || DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_align.choices[0].value,
        };
        setSettingsData([...settingsData, copy]);
        dispatch(registerScreenChanges("Ad Injection"));
    };

    const handleChange = (i, name, value) => {
        let temp = [...settingsData];
        let err = false;
        if (name === "target_position" && (value < -1 || value > 100)) {
            dispatch(pushMessage("Ad Exclusion", "Target position value must be between -1 and 100. Resetting", "error"));
            err = true;
        }

        if (!temp[i][name]) {
            temp[i][name] = {};
        }
        if (name === "advertisement_label_position") {
            temp[i][name].value = err ? 0 : { code: value };
        } else {
            temp[i][name].value = err ? 0 : value;
        }

        setSettingsData(temp);
        dispatch(registerScreenChanges("Ad Injection"));
    };

    const handleChangeTemp = (e, index, value) => {
        e.stopPropagation();
        e.preventDefault();
        let temp = [...settingsData];
        temp[index]["enable_native_ad_injection"].value = !value;
        setSettingsData(temp);
        dispatch(registerScreenChanges("Ad Injection"));
    };

    const DOMAIN_SETTINGS_MUTATION = useDomainSettings({
        onCompleted(data) {
            setSettingsData([]);
            let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
            setClsState(temp_data.enable_cls)
            let adInjections = temp_data[mode === "video" ? "video_player_injections" : "native_ad_injections"]; // temp_data.native_ad_injections
            let adPositions = temp_data[mode === "video" ? "video_player_positions" : "ad_positions"] || {};
            let initiallyEmtpy = false;

           

            if (mode === "video") {
                let defaultInjection = first(DEFAULT_AD_INJECTION_OBJECT);
                delete defaultInjection.disable_refresh;
                delete defaultInjection.enable_native_ad_for_ajax;
            }

            if (mode === "display") {
                for (let { display_name: name, slot_name: code } of Object.values(adPositions)) {
                    DEFAULT_AD_INJECTION_OBJECT[0].native_ad_rep_ads_name.choices.push({ name, code });
                }
            } else {
                for (let o of Object.values(adPositions)) {
                    let { displayName: name, playerSlot: code } = o;
                    DEFAULT_AD_INJECTION_OBJECT[0].video_player_name.choices.push({ name, code });
                }
            }

            if (Array.isArray(adInjections) && adInjections.length) {
                temp_data[mode === "video" ? "video_player_injections" : "native_ad_injections"] = adInjections.filter((item) => {
                    if (Array.isArray(mode === "video" ? item.video_player_name.choices : item.native_ad_rep_ads_name.choices)) {
                        if (mode === "video") {
                            item.video_player_name.choices = item.video_player_name.choices.filter((i) => i.code !== "customCode");
                        } else {
                            item.native_ad_rep_ads_name.choices = item.native_ad_rep_ads_name.choices.filter((it) => it.code !== "customCode");
                        }
                    }
                    return mode === "video" ? item.video_player_name.value.code !== "customCode" : item.native_ad_rep_ads_name.value.code !== "customCode";
                });
            } else {
                initiallyEmtpy = true;
            }

            if (!initiallyEmtpy && mode !== "video") {
                adInjections.map((injection) => {
                    injection.useDefaultLabelPosition = !injection?.advertisement_label?.value;
                    return injection;
                });
            }

            setSettingsData(initiallyEmtpy ? [] : adInjections);
            setMutationDataCompleted(true);
        },
    })

    const SAVE_NATIVE_AD_INJECTION_MUTATION_CONTAINER = useNativeInjectionSave({
        onCompleted() {
            dispatch(pushMessage("Ad Injection", "Native ad injection data updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        }
    })

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            DOMAIN_SETTINGS_MUTATION({
                domainId: currentDomain,
                customerId: currentCustomer
            })
        }
    }, [currentCustomer, currentDomain]);



    const validityCheckCssSelector = (label, cssSelector) => {
        try {
            let fragmentElement = new DocumentFragment();
            fragmentElement.querySelector(cssSelector);
            return true;
        } catch (e) {
            dispatch(pushMessage(label, "Incorrect CSS selector! Please provide the correct CSS selector for the system to function properly.", "error"));
            return false;
        }
    }

    const formValidationAction = () => {
        let typeNameByPage = mode === 'video' ? 'video_player_name' : 'native_ad_rep_ads_name';
        let labelNameByPage = mode === 'video' ? 'Video Player Name' : 'Ads Name';

        let fieldObjectArr = [
            {machineName: 'native_ad_name',     humanName: 'Name',                              msg: "this field is required", type: 'string'},
            {machineName: typeNameByPage,       humanName: labelNameByPage,                     msg: "this field is required", type: 'object', objectProperty: 'code'},
            {machineName: 'target_wrapper',     humanName: 'Target Wrapper',                    msg: "this field is required", type: 'string', customRule: validityCheckCssSelector},
            {machineName: 'target_insert_type', humanName: 'Target Insert Type',                msg: "this field is required", type: 'object', objectProperty: 'code'},
            {machineName: 'target_position',    humanName: 'Target Position (Repeat After)',    msg: "this field is required", type: 'string'},
            {machineName: 'target_urls',        humanName: 'Exclusions URLs',                   msg: "this field is required", type: 'string'},
        ];

        let validationError = false;

        for (const settingsDataElement of settingsData) {

            for (const fieldObjectArrElement of fieldObjectArr) {

                let type = fieldObjectArrElement.type;
                let customRule = fieldObjectArrElement.customRule;

                if (type === 'string') {
                    let isFieldBlank = settingsDataElement[fieldObjectArrElement.machineName].value;
                    isFieldBlank = isFieldBlank === 0 || isFieldBlank;

                    if (!isFieldBlank) {
                        dispatch(pushMessage(fieldObjectArrElement.humanName, fieldObjectArrElement.msg, "error"));
                        validationError = true;
                    }
                }

                if (type === 'object') {
                    let isFieldBlank = settingsDataElement[fieldObjectArrElement.machineName].value[fieldObjectArrElement.objectProperty];
                    if (!isFieldBlank) {
                        dispatch(pushMessage(fieldObjectArrElement.humanName, fieldObjectArrElement.msg, "error"));
                        validationError = true;
                    }
                }

                if (customRule && !customRule(fieldObjectArrElement.humanName, settingsDataElement[fieldObjectArrElement.machineName].value)) {
                    validationError = true;
                }
            }
        }

        if (validationError) {
            throw Error('Validation error !');
        }
    }


    const saveAdNativeInjectionSettings = async () => {
        formValidationAction();
        dispatch(pushMessage("Ad Injection", "Saving ad injection edits..."));

        let response = await SAVE_NATIVE_AD_INJECTION_MUTATION_CONTAINER({
            mode,
            domainId: currentDomain,
            injectionType: CODE_TYPE,
            injectionValueList: JSON.stringify(
                settingsData.map((injection) => {
                    delete injection["useDefaultLabelPosition"];
                    return injection;
                })
            )
        });

        return response;
    };

    const dialogFuncMap = {
        displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, index) => {
        setPositionFinal(index);
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => {
                        onHide(name);
                        handleRemoveClick(positionFinal);
                    }}
                    autoFocus
                />
            </div>
        );
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveAdNativeInjectionSettings}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content">
                        <div className="p-panel p-component">
                            <SectionHeader screenName="Ad injection">
                                <div className="p-text-bold">{mode === "video" ? "Video Ad Injection" : "Ad Injection"}</div>
                            </SectionHeader>
                            <div className="p-panel-content">
                                <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter("displayConfirmation")} onHide={() => onHide("displayConfirmation")}>
                                    <div className="confirmation-content p-d-flex p-ai-center">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to delete this item?</span>
                                    </div>
                                </Dialog>
                                <Accordion>
                                    {settingsData.map((value, index) => {
                                        let advertisement_label_position = commonDomainInfo[currentDomain]?.advertisement_label_position;
                                        let advertisementLabelPositionValue = value.useDefaultLabelPosition
                                            ? advertisement_label_position || value.advertisement_label_position.value?.code || DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_position.choices[1].code
                                            : value.advertisement_label_position.value?.code || advertisement_label_position || DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_position.choices[1].code;
                                        return (
                                            <AccordionTab
                                                key={index}
                                                header={
                                                    <React.Fragment>
                                                        <div className="p-d-flex p-jc-between">
                                                            <div className="p-field-checkbox p-mb-0 native-input-switch">
                                                                <InputSwitch id={"enable_native_ad_injection" + index} name={"enable_native_ad_injection"} checked={value.enable_native_ad_injection.value} onClick={(e) => handleChangeTemp(e, index, value.enable_native_ad_injection.value)} />
                                                                <div className="p-ml-2">{value.native_ad_name.value}</div>
                                                            </div>
                                                            <Button
                                                                type="button"
                                                                icon="pi pi-times"
                                                                className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                                tooltip="Remove row"
                                                                tooltipOptions={{ position: "top" }}
                                                                onClick={() => onClick("displayConfirmation", index)}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <div className="p-grid p-fluid">
                                                    <Tooltip target=".adpositiontooltip" />
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('injections_name', true)}
                                                            htmlFor={"native_ad_name" + index}
                                                        >
                                                            Name
                                                        </label>
                                                        <span style={{color: 'red'}}>*</span>

                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id={"native_ad_name" + index} name="native_ad_name" value={value.native_ad_name.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        {mode === "video" ? (
                                                            <label
                                                                className="adpositiontooltip after"
                                                                data-pr-tooltip='Choose one of the ad slots created under "Ad Positions"'
                                                                htmlFor={"video_player_name" + index}
                                                            >
                                                                {value.video_player_name.label}
                                                            </label>
                                                        ) : (
                                                            <label
                                                                {...tooltip('ads_name', true)}
                                                                htmlFor={"native_ad_rep_ads_name" + index}
                                                            >
                                                                {value.native_ad_rep_ads_name.label}
                                                            </label>
                                                        )}
                                                        <span style={{color: 'red'}}>*</span>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        {mode === "video" ? (
                                                            <Dropdown value={value.video_player_name.value} name="video_player_name" options={value.video_player_name.choices} onChange={(e) => handleChange(index, e.target.name, e.target.value)} optionLabel="name" placeholder="Select Ad Name" />
                                                        ) : (
                                                            <Dropdown
                                                                value={value.native_ad_rep_ads_name.value}
                                                                name="native_ad_rep_ads_name"
                                                                options={value.native_ad_rep_ads_name.choices}
                                                                onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                                                optionLabel="name"
                                                                placeholder="Select Ad Name"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('target_wrapper', true)}
                                                            htmlFor={"target_wrapper" + index}
                                                        >
                                                            {value.target_wrapper.label}
                                                        </label>
                                                        <span style={{color: 'red'}}>*</span>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">

                                                        <InputText id={"target_wrapper" + index} name="target_wrapper" value={value.target_wrapper.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('target_insert_type', true)}
                                                            htmlFor={"target_insert_type" + index}
                                                        >
                                                            {value.target_insert_type.label}
                                                        </label>
                                                        <span style={{color: 'red'}}>*</span>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <Dropdown value={value.target_insert_type.value} name="target_insert_type" options={value.target_insert_type.choices} onChange={(e) => handleChange(index, e.target.name, e.target.value)} optionLabel="name" placeholder="Select Ad Name" />
                                                    </div>
                                                    {value.target_insert_type.value.code === "incontent" && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                <label htmlFor={"words_no" + index}>{value.words_no.label}</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                <InputText id={"words_no" + index} name="words_no" value={value.words_no.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} placeholder="Number Of words" />
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                <label htmlFor={"exclude_target_wrapper" + index}>{value.exclude_target_wrapper.label}</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                <InputText id={"exclude_target_wrapper" + index} name="exclude_target_wrapper" value={value.exclude_target_wrapper.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} placeholder="Exclude Target Wrapper" />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('target_position_repeat_after', true)}
                                                            htmlFor={"target_position" + index}
                                                        >
                                                            {value.target_position.label}
                                                        </label>
                                                        <span style={{color: 'red'}}>*</span>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputNumber
                                                            inputId={"target_position" + index}
                                                            name="target_position"
                                                            value={value.target_position.value}
                                                            onChange={(e) => {
                                                                handleChange(index, e.originalEvent.target.name, e.value);
                                                            }}
                                                        />
                                                    </div>
                                                    { mode !== "video" && (
                                                    <>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('disable_repeat_after_number_of_occurrences', true)}
                                                            htmlFor={"stop_repeat_after" + index}
                                                        >
                                                            {getIn(value, "stop_repeat_after", "label")}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText
                                                            id={"stop_repeat_after" + index}
                                                            name="stop_repeat_after"
                                                            value={value.stop_repeat_after.value}
                                                            onChange={(e) => {
                                                                handleChange(index, e.target.name, e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    </>
                                                    )}

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('inclusions_urls', true)}
                                                            htmlFor={"target_urls" + index}
                                                        >
                                                            {value.target_urls.label}
                                                        </label>
                                                        <span style={{color: 'red'}}>*</span>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"target_urls" + index} name="target_urls" value={value.target_urls.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('exclusions_urls', true)}
                                                            htmlFor={"exclutions_urls" + index}
                                                        >
                                                            {value.exclutions_urls.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"exclutions_urls" + index} name="exclutions_urls" value={value.exclutions_urls.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('inclusions_user_agent', true)}
                                                            htmlFor={"inclusions_user_agent" + index}
                                                        >
                                                            {value.inclusions_user_agent.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"inclusions_user_agent" + index} name="inclusions_user_agent" value={value.inclusions_user_agent.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip('exclusions_user_agent', true)} htmlFor={"exclusions_user_agent" + index}>
                                                            {value.exclusions_user_agent.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"exclusions_user_agent" + index} name="exclusions_user_agent" value={value.exclusions_user_agent.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>

                                                    { mode === "video" && (
                                                    <>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label className="adpositiontooltip after" data-pr-tooltip="Given text will be used as the floating player top title" htmlFor={"player_heading" + index}>
                                                        {DEFAULT_AD_INJECTION_OBJECT[0].player_heading.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"player_heading" + index} name="player_heading" value={value.player_heading !== undefined ? value.player_heading.value : DEFAULT_AD_INJECTION_OBJECT[0].player_heading.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label className="adpositiontooltip after" data-pr-tooltip="Seconds delay you want to display close button" htmlFor={"close_button_delay" + index}>
                                                        {DEFAULT_AD_INJECTION_OBJECT[0].close_button_delay.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"close_button_delay" + index} name="close_button_delay" value={value.close_button_delay !== undefined ? value.close_button_delay.value : DEFAULT_AD_INJECTION_OBJECT[0].close_button_delay.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    </>
                                                    )}

                                                    {(mode !== "video") && (clsState === true) && (
                                                    <>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label className="adpositiontooltip after" data-pr-tooltip="The value you add here will be added to the overall height of the current Ad container. So, if the the current Ad container is 250 and you add 100, then the total height of the ad container will be 350 and the Ad will float within that container. This field is for Desktop and Tablet." htmlFor={"extra_ad_container_height" + index}>
                                                            {DEFAULT_AD_INJECTION_OBJECT[0].extra_ad_container_height.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id={"extra_ad_container_height" + index} name="extra_ad_container_height" value={value.extra_ad_container_height !== undefined ? value.extra_ad_container_height.value : DEFAULT_AD_INJECTION_OBJECT[0].extra_ad_container_height.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label className="adpositiontooltip after" data-pr-tooltip="The value you add here will be added to the overall height of the current Ad container. So, if the the current Ad container is 250 and you add 100, then the total height of the ad container will be 350 and the Ad will float within that container. This field is for Mobile." htmlFor={"extra_ad_container_height_mob" + index}>
                                                            {DEFAULT_AD_INJECTION_OBJECT[0].extra_ad_container_height_mob.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id={"extra_ad_container_height_mob" + index} name="extra_ad_container_height_mob" value={value.extra_ad_container_height_mob !== undefined ? value.extra_ad_container_height_mob.value : DEFAULT_AD_INJECTION_OBJECT[0].extra_ad_container_height_mob.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    </>
                                                    )}

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label
                                                            {...tooltip('custom_inline_style', true)}
                                                            htmlFor={"custom_inline_style" + index}
                                                        >
                                                            {value.custom_inline_style.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <AceEditor mode="css" theme="monokai" value={value.custom_inline_style.value} onChange={(e) => handleChange(index, "custom_inline_style", e)} name="custom_inline_style" editorProps={{ $blockScrolling: true }} />
                                                    </div>
                                                    {mode !== "video" && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                <label
                                                                    {...tooltip('custom_parameter_or_custom_code', true)}
                                                                    htmlFor={"custom_parameter_or_code" + index}
                                                                >
                                                                    {value.custom_parameter_or_code.label}
                                                                </label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                <AceEditor mode="javascript" theme="monokai" value={value.custom_parameter_or_code.value} onChange={(e) => handleChange(index, "custom_parameter_or_code", e)} name="custom_parameter_or_code" editorProps={{ $blockScrolling: true }} />
                                                            </div>
                                                        </>
                                                    )}

                                                    {mode !== "video" && isAdmin && (
                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox p-mt-4 small-padding-bottom-0 small-margin-top-10">
                                                            <InputSwitch id={"is_hidden_ad_target_wrapper" + index} name={"is_hidden_ad_target_wrapper"} checked={value?.is_hidden_ad_target_wrapper?.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                            <label htmlFor={"is_hidden_ad_target_wrapper" + index}>{value?.is_hidden_ad_target_wrapper?.label}</label>
                                                        </div>
                                                    )}
                                                    {mode !== "video" && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-15 p-lg-12 p-field-checkbox">
                                                                <InputSwitch id={"enable_shadowdom_support" + index} name={"enable_shadowdom_support"} checked={value.enable_shadowdom_support.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                <label
                                                                    {...tooltip('enable_shadowdom_support', true)}
                                                                    htmlFor={"enable_shadowdom_support" + index}
                                                                >
                                                                    {value.enable_shadowdom_support.label}
                                                                </label>
                                                            </div>
                                                            {value.enable_shadowdom_support.value && (
                                                                <>
                                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 small-margin-top-0 small-padding-bottom-0">
                                                                        <label className="adpositiontooltip after" data-pr-tooltip="Example: .brick, #listing-detail-report etc." htmlFor={"shadowdom_target_wrapper" + index}>
                                                                            {value.shadowdom_target_wrapper.label}
                                                                        </label>
                                                                    </div>
                                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                        <InputText id={"shadowdom_target_wrapper" + index} name="shadowdom_target_wrapper" value={value.shadowdom_target_wrapper.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {mode === "video" && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox">
                                                                <InputSwitch id={"float_player_on_load" + index} name={"float_player_on_load"} checked={value?.float_player_on_load?.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                <label htmlFor={"float_player_on_load" + index}>{value?.float_player_on_load?.label || DEFAULT_AD_INJECTION_OBJECT[0].float_player_on_load.label}</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox">
                                                                <InputSwitch id={"video_title" + index} name={"video_title"} checked={value?.video_title?.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                <label htmlFor={"video_title" + index}>{value?.video_title?.label || DEFAULT_AD_INJECTION_OBJECT[0].video_title.label}</label>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox small-padding-bottom-0 small-margin-top-10">
                                                        <InputSwitch id={"enable_on_preview" + index} name={"enable_on_preview"} checked={value?.enable_on_preview?.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                        <label
                                                            {...tooltip('enable_on_preview', true)}
                                                            htmlFor={"enable_on_preview" + index}
                                                        >
                                                            {value?.enable_on_preview?.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox small-padding-bottom-0 small-margin-top-10">
                                                        <InputSwitch id={"disable_repeat" + index} name={"disable_repeat"} checked={value.disable_repeat.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                        <label
                                                            {...tooltip('disable_repeat', true)}
                                                            htmlFor={"disable_repeat" + index}
                                                        >
                                                            {value.disable_repeat.label}
                                                        </label>
                                                    </div>
                                                    {mode !== "video" && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox">
                                                                <InputSwitch id={"disable_refresh" + index} name={"disable_refresh"} checked={value.disable_refresh.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                <label
                                                                    {...tooltip('disable_refresh', true)}
                                                                    htmlFor={"disable_refresh" + index}
                                                                >
                                                                    {value.disable_refresh.label}
                                                                </label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox">
                                                                <InputSwitch id={"enable_native_ad_for_ajax" + index} name={"enable_native_ad_for_ajax"} checked={value?.enable_native_ad_for_ajax?.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                                <label htmlFor={"enable_native_ad_for_ajax" + index}>{value?.enable_native_ad_for_ajax?.label}</label>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox">
                                                        <InputSwitch id={"reset_repeat_counter" + index} name={"reset_repeat_counter"} checked={value.reset_repeat_counter.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                        <label
                                                            {...tooltip('reset_repeat_counter', true)}
                                                            htmlFor={"reset_repeat_counter" + index}
                                                        >
                                                            {value.reset_repeat_counter.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-field-checkbox">
                                                        <InputSwitch id={"advertisement_label" + index} name={"advertisement_label"} checked={value.advertisement_label.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                        <label
                                                            {...tooltip('advertisement_label', true)}
                                                            htmlFor={"advertisement_label" + index}
                                                        >
                                                            {value.advertisement_label.label}
                                                        </label>
                                                    </div>
                                                    {value.advertisement_label.value && (
                                                        <>
                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                <label
                                                                    {...tooltip('advertisement_label_position', true)}
                                                                    htmlFor={"advertisement_label_position" + index}
                                                                >
                                                                    {DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_position.label}
                                                                </label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                <Dropdown
                                                                    value={advertisementLabelPositionValue}
                                                                    name="advertisement_label_position"
                                                                    options={DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_position.choices}
                                                                    onChange={(e) => {
                                                                        handleChange(index, e.target.name, e.target.value);
                                                                    }}
                                                                    optionLabel="name"
                                                                    optionValue="code"
                                                                    placeholder="Select Advertisement Label Position"
                                                                />
                                                            </div>

                                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                <label
                                                                    {...tooltip('advertisement_label_align', true)}
                                                                    htmlFor={"advertisement_label_align" + index}
                                                                >
                                                                    {DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_align.label}
                                                                </label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                <Dropdown
                                                                    value={value?.advertisement_label_align?.value || commonDomainInfo[currentDomain]?.advertisement_label_align || DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_align.choices[0].value}
                                                                    name="advertisement_label_align"
                                                                    options={DEFAULT_AD_INJECTION_OBJECT[0].advertisement_label_align.choices}
                                                                    onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                                                    optionValue="value"
                                                                    optionLabel="name"
                                                                    placeholder="Select Advertisement Label Alignment"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </AccordionTab>
                                        );
                                    })}
                                </Accordion>
                                <div className="p-mt-3 p-d-flex p-jc-end">
                                    <Button type="button" label="Add Row" tooltip="Add row" icon="pi pi-plus" tooltipOptions={{ position: "top" }} onClick={handleAddClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader>
                                <div className="p-text-bold">Descriptions</div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Target Wrapper</h6>
                                <p>{ helpText('help_text____target_wrapper') }</p>
                                <h6 className="p-text-bold p-mb-0">Target Position (Repeat After)</h6>
                                <p>{ helpText('help_text____target_position_repeat_after') }</p>
                                <h6 className="p-text-bold p-mb-0">Number of Words</h6>
                                <p>{ helpText('help_text____number_of_words') }</p>
                                <h6 className="p-text-bold p-mb-0 m-mt-0">Inclusions URLs</h6>
                                <p>{ helpText('help_text____inclusions_urls') }</p>
                                <h6 className="p-text-bold p-mb-0">Inclusions User Agent</h6>
                                <p>{ helpText('help_text____inclusions_user_agent') }</p>
                                <h6 className="p-text-bold p-mb-0">Custom Inline Style</h6>
                                <p>{ helpText('help_text____custom_inline_style') }</p>
                                <h6 className="p-text-bold p-mb-0">Custom Parameter or Custom Code</h6>
                                {/*<p dangerouslySetInnerHTML={{ __html: 'Example: {"keyValue": {"Pagetype": "email"}}' }}></p>*/}
                                <p>{ helpText('help_text____custom_parameter_or_custom_code') }</p>
                                <h6 className="p-text-bold p-mb-0">Disable repeat after number of occurrences</h6>
                                <p>{ helpText('help_text____disable_repeat_after_number_of_occurrences') }</p>
                                <h6 className="p-text-bold p-mb-0">Reset repeat counter on AJAX loaded new pages</h6>
                                <p>{ helpText('help_text____reset_repeat_counter_on_ajax_loaded_new_pages') }</p>
                                <h6 className="p-text-bold p-mb-0">Advertisement Label</h6>
                                <p>{ helpText('help_text____advertisement_label') }</p>
                                <h6 className="p-text-bold p-mb-0">Advertisement Label Position</h6>
                                <p>{ helpText('help_text____advertisement_label_position') }</p>
                                <h6 className="p-text-bold p-mb-0">Extra Ad Container Height (Desktop & Tablet / Mobile)</h6>
                                <p>{ helpText('help_text____extra_ad_container_height_desktop_&_tablet__mobile') }</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default AdNativeInjection;
