import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../AppTopbar';
import { AppMenu } from '../AppMenu';
import { DomainsDropdown } from '../DomainsDropdown';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '../layout/flags/flags.css';
import '../layout/layout.scss';

import { sidebarClassName } from '../constants'
import { gql, useMutation } from '@apollo/client';
import AppLogo from './AppLogo';

const Client = (props) => {
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const sidebar = useRef();

    useEffect(() => {
    }, []);

    const onMenuItemClick = () => {

    }
    
    return (
        <>
            <AppTopbar {...props} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} >
                    <AppLogo />
                    <DomainsDropdown />
                    <AppMenu {...props} model={localStorage.getItem('role') === 'client'? 'customer': 'partnerCustomerMenu'} onMenuItemClick={onMenuItemClick} clientInfo={props.clientInfo} sourceData={props.sourceData} />
                </div>
            </CSSTransition>
        </>

    );

}

export default Client;
