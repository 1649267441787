import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import BuildDomainCta from "./common/BuildDomainCta";

const BuilDomainButton = ({tooltipPosition = 'top'}) => {
    return (
        <BuildDomainCta
            ctaText="Build"
            icon={faBarsStaggered}
            enabledTooltipText="There are changes available for this domain. Build is required for those changes to be pushed live."
            disabledTooltipText="Creating a new AdGrid build is not required right now"
            notificationText="Adding domain to build screen"
            url="/domains"
            tooltipPosition={tooltipPosition}
        />
    )
};

export default BuilDomainButton;
