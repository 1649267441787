import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import LayoutWrapper from "./LayoutWrapper";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import "brace/mode/html";
import "brace/theme/github";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import AceEditor from "react-ace";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { editCreative, resetEditConfig } from "../reducers/customOrdersReducer";
import { pushMessage } from "../reducers/messagingReducer";
import PaymentRegistrationBanner from "./common/PaymentRegistrationBanner";
import { CREATIVES_DISPLAY_NAMES, LINEITEM_STATS_MUTATION } from "./common/creatives/definitions";
import Cta from "./common/marketplace/CTA";

const GAM_API_MUTATION = gql`
    mutation callCustomOrdersMutation($id: String) {
        customOrdersMutation(input: { id: $id }) {
            ordersInfo
        }
    }
`;

const DELETE_ORDERS_MUTATION = gql`
    mutation callDeleteCustomOrderMutation($orderId: String, $gamLineItemId: String) {
        deleteCustomOrderMutation(input: { orderId: $orderId, gamLineItemId: $gamLineItemId }) {
            status
        }
    }
`;

const UPDATE_CREATIVE_CONFIG_MUTATION = gql`
    mutation callUpdateCreativeConfigurationMutation($id: String, $customerId: String, $data: String) {
        updateCreativeConfigurationMutation(input: {id: $id, customerId: $customerId, data: $data}) {
            mutationResult
        }
    }
`

let host = process.env?.REACT_APP_SERVER;
let protocol = process.env?.REACT_APP_SERVER_PROTOCOL || 'https'

const generateScriptTag = (id, customerId, adServingType) => {
    let macros = '';
    switch (adServingType) {
        case '3p':
            macros = "[['%%CLICK_URL_UNESC%%'],[],[],['%%CACHEBUSTER%%']]"
            break
        case 'cc':
            macros = "[['%%CLICK_URL_UNESC%%'],['%%VIEW_URL_UNESC%%'],['%%DEST_URL_UNESC%%'],['%%CACHEBUSTER%%']]"
            break
        case 'hdc':
            macros = "[[],[],[],[]]"
            break
        default:
            macros = "[['%%CLICK_URL_UNESC%%'],['%%VIEW_URL_UNESC%%'],['%%DEST_URL_UNESC%%'],['%%CACHEBUSTER%%']]"
    }
    return (
        `
        <script>
            var adgridOpts = window.adgridOpts || [];
            var creativeConf = {
            crmcId: ${id},
            cid: ${customerId},
            macros: ${macros},
            source: document.currentScript,
            server: "${host}",
            path: "/wp-content/uploads/crmc/library/adgrid-crmc.js"
            };
            adgridOpts.push(creativeConf);
            var adgridScriptTag = document.createElement('script');
            adgridScriptTag.src = "//" + creativeConf.server + creativeConf.path;
            document.body.insertBefore(adgridScriptTag, document.body.firstChild);
        </script>
        `
    );
}

function isEmpty(i) {
    const t = typeof i;
    switch (t) {
        case "string":
            return i.length === 0;
        case "object":
            return Array.isArray(i) ? i.length === 0 : t === null || Object.keys(i).length === 0;
        case "undefined":
            return true;
        default:
            throw new Error(`Arg of type t -> ${t} is not a collection type`);
    }
}

const ORDER_DELETION_OBJECT = { orderId: "", gamLineItemId: "" };

const CreativeOrders = () => {
    const { currentCustomer, isAdmin } = useSelector((state) => state.userInformation);
    const { customerToStripeAccountMappings, infoAvailable: stripeInfoAvailable } = useSelector((state) => state.stripeInfo);
    const dispatch = useDispatch();
    const [gamApiMutationContainer] = useMutation(GAM_API_MUTATION);
    const [deleteOrderMutationContainer] = useMutation(DELETE_ORDERS_MUTATION);
    const [lineItemStatsMutationContainer] = useMutation(LINEITEM_STATS_MUTATION);
    const [updateCreativeConfigContainer] = useMutation(UPDATE_CREATIVE_CONFIG_MUTATION);
    const [orderDeletionInfo, setOrderDeletionInfo] = useState(ORDER_DELETION_OBJECT);
    const [ordersInfo, setOrdersInfo] = useState({});
    const [isOrdersEmpty, setIsOrdersEmpty] = useState(false);
    const [displayScriptTagOverlay, setDisplayScriptOverlay] = useState(false);
    const [scriptTagCode, setScriptTagCode] = useState("");
    const [isConfirmDeletionVisible, setConfirmDeletionVisibility] = useState(false);
    const [currentJsonCode, setCurrentJsonCode] = useState("");
    const history = useHistory();
    const [lineItemStats, setLineItemStats] = useState({});
    const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
    const [displayPaymentMissingSection, setDisplayPaymentSection] = useState(false);

    async function updateCreativeConfiguration({ creativeId, customerId, creativeJsonData }) {
        let response = await updateCreativeConfigContainer({
            variables: {
                customerId,
                id: creativeId,
                data: creativeJsonData,
            }
        })
        return response;
    }

    useEffect(() => {
        if (stripeInfoAvailable) {
            setHasPaymentMethods(!!customerToStripeAccountMappings?.[currentCustomer]);
        }
    }, [customerToStripeAccountMappings, stripeInfoAvailable, currentCustomer]);

    useEffect(() => {
        if (currentCustomer) {
            gamApiMutationContainer({
                variables: { id: `${currentCustomer}` },
            })
                .then((response) => {
                    let ordersInfo = response?.data?.customOrdersMutation?.ordersInfo;
                    let lineItemIds = [];
                    ordersInfo = ordersInfo ? JSON.parse(ordersInfo) : {};
                    setIsOrdersEmpty(isEmpty(ordersInfo.posts_info));
                    if (ordersInfo.posts_info && Array.isArray(ordersInfo.posts_info)) {
                        ordersInfo.posts_info.sort((a, b) => {
                            let orderA = parseInt(a.id);
                            let orderB = parseInt(b.id);
                            return orderB - orderA;
                        });
                        lineItemIds = ordersInfo.posts_info.map((i) => i?.fields?.line_item_id);
                    }
                    setOrdersInfo(ordersInfo);
                    return lineItemIds;
                })
                .catch((err) => {
                    dispatch(pushMessage("Creative Orders", err.message, "error"));
                })
                .then((lineItemIdsList) => {
                    return lineItemStatsMutationContainer({
                        variables: { lineItemIdsList: JSON.stringify(lineItemIdsList) },
                    });
                })
                .then((response) => {
                    let stats = JSON.parse(response.data?.getLineItemStatsMutation?.gamApiStats || "{}");
                    setLineItemStats(stats);
                })
                .catch((err) => {
                    dispatch(pushMessage("Creative Orders", err.message, "error"));
                });
        }
    }, [currentCustomer]);

    const renderCreativeScriptTag = (id, customerId, adServingType) => {
        setScriptTagCode(generateScriptTag(id, customerId, adServingType));
    };

    const deleteWpOrder = (orderId, gamLineItemId) => {
        deleteOrderMutationContainer({
            variables: {
                orderId,
                gamLineItemId,
            },
        })
            .then(() => {
                dispatch(pushMessage("Creative Orders", "Custom order successfully deleted. ID was " + orderId));
                setOrderDeletionInfo(ORDER_DELETION_OBJECT);
                setOrdersInfo({
                    posts_info: ordersInfo?.posts_info.filter(({ id }) => id !== orderId),
                });
            })
            .catch(() => {
                dispatch(pushMessage("Creative Orders", "There was an error while trying to delete your custom order", "error"));
                setOrderDeletionInfo(ORDER_DELETION_OBJECT);
            });
    };

    const renderTableControls = (rowData) => {
        const config = JSON.parse(rowData.fields.creative_json_config);
        const { start_date, end_date, impression_goals, line_item_id, cpm, is_creative_enabled: enabled } = rowData.fields;

        return (
            <div
                css={css`
                    .preview {
                        display: inline-block;
                        margin-right: 0.5em;
                        cursor: pointer;
                    }
                    span {
                        margin-right: 0.5em;
                        font-size: 16px;
                        &.delete {
                            color: red;
                        }
                        &.edit {
                            color: #00bcd4;
                            margin-left: 0;
                            &:hover {
                                color: #0445de;
                            }
                        }
                        &.view {
                            color: #696868;
                            &:hover {
                                color: #000;
                            }
                        }
                        .action-icon {
                            visibility: hidden;
                            transition: all 0.25s ease-in-out;
                        }
                        &.disable {
                            color: #e84d4d;
                            &:hover {
                                color: #fb0e0e;
                                text-decoration: underline;
                                .action-icon {
                                    visibility: visible;
                                }
                            }
                        }
                        &.enable {
                            color: green;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &.disabled {
                            opacity: 0.5;
                        }
                    }
                `}
            >
                <a
                    className="preview"
                    target="_blank"
                    href={`${protocol}://${host}/wp-content/uploads/crmc/library/creatives/preview.html?s=${host}&crmcId=${rowData.id}&cid=${rowData?.fields?.customer_id}`}
                >
                    Preview
                </a>
                <span
                    className={`edit ${!enabled ? 'disabled' : ''}`}
                    onClick={() => {
                        if (!enabled) {
                            dispatch(pushMessage('Custom creatives', "This creative has been disabled by an administrator", "error"))
                            return
                        }
                        if (!isAdmin && !rowData.fields.is_creative_enabled) {
                            dispatch(pushMessage("Creative Orders", "This order has been disabled by an administrator and therefore cannot be edited. To enable this order again get in contact with support", "error"));
                            return;
                        }
                        dispatch(
                            editCreative(
                                config.creativeType,
                                rowData.id,
                                config,
                                { startDate: start_date, endDate: end_date, orderName: rowData.title, impressionGoals: impression_goals, lineItemId: line_item_id },
                                currentCustomer,
                                rowData?.fields?.payment_method_id || "",
                                lineItemStats[line_item_id],
                                cpm
                            )
                        );

                        history.push("/creatives");
                    }}
                >
                    Edit
                </span>

                {
                    isAdmin && (<span
                        className={enabled ? 'disable' : 'enable'}
                        onClick={async () => {
                            config.enabled = !enabled;
                            let creativeJsonData = JSON.stringify(config);

                            let response = await updateCreativeConfiguration({
                                customerId: String(currentCustomer),
                                creativeId: rowData.id,
                                creativeJsonData,
                            })

                            let data = JSON.parse(response.data.updateCreativeConfigurationMutation.mutationResult)

                            if (data.succeeded) {
                                let posts_info = ordersInfo.posts_info.map(creativeOrder => {
                                    if (creativeOrder.id === rowData.id) {
                                        return {
                                            ...creativeOrder,
                                            fields: {
                                                ...creativeOrder.fields,
                                                creative_json_config: creativeJsonData,
                                                is_creative_enabled: !enabled
                                            }
                                        }
                                    }
                                    return creativeOrder
                                })
                                setOrdersInfo({ ...ordersInfo, posts_info })
                            }
                        }}
                    >
                        {enabled ? 'Disable' : 'Enable'}
                    </span>

                    )
                }

                <span
                    className={`view ${!enabled ? 'disabled' : ''}`}
                    onClick={() => {
                        if (!enabled) {
                            dispatch(pushMessage('Custom creatives', "This creative has been disabled by an administrator", "error"))
                            return
                        }
                        if (!isAdmin && !rowData.fields.is_creative_enabled) {
                            dispatch(pushMessage("Creative Orders", "You cannot view this order configuration anymore. Get in contact with support", "error"));
                            return;
                        }
                        renderCreativeScriptTag(parseInt(rowData.id), currentCustomer, config.adServingType);
                        setDisplayScriptOverlay(true);
                        setCurrentJsonCode(rowData.id);
                    }}
                >
                    Ad code
                </span>
            </div>
        );
    };

    const creativeTypeColumn = (rowData) => {
        const config = JSON.parse(rowData.fields.creative_json_config);
        let creativeType = CREATIVES_DISPLAY_NAMES[config.creativeType];
        return (
            <p>
                <strong>{creativeType}</strong>
            </p>
        );
    };

    const columnDate = (rowData, key) => {
        let d = new Date(parseInt(rowData.fields[key]));
        d = d.toLocaleDateString();
        return <p>{d}</p>;
    };

    const startDateColumn = (rowData) => columnDate(rowData, "start_date");
    const endDateColumn = (rowData) => columnDate(rowData, "end_date");

    const statusColumn = (rowData) => {
        const label = !!rowData?.fields?.is_creative_enabled;
        return (
            <span
                css={css`
                    color: ${label ? "green" : "red"};
                `}
            >
                {label ? "Active" : "Inactive"}
            </span>
        );
    };

    const statsColumn = (rowData) => {
        let lineItemId = rowData?.fields?.line_item_id;
        let stats = lineItemStats[lineItemId] ?? {};
        let impressionsDelivered = stats.impressionsDelivered || 0;
        return parseInt(impressionsDelivered).toLocaleString();
    };

    const ongoingChargeColumn = (rowData) => {
        let cpm = parseFloat(rowData?.fields?.cpm);
        let lineItemId = rowData?.fields?.line_item_id;
        let stats = lineItemStats[lineItemId] ?? {};
        let impressionsDelivered = parseInt(stats.impressionsDelivered || 0);
        return "$" + parseFloat(cpm * impressionsDelivered * 0.001).toFixed(2);
    };

    const navigateToCreativesScreen = () => {
        dispatch(resetEditConfig());
        if (!hasPaymentMethods) {
            setDisplayPaymentSection(true);
            return;
        }
        history.push("/creatives");
    }

    return (
        <>
            <LayoutWrapper model="creative" renderOpsBar={false}>
                <PaymentRegistrationBanner
                    isVisible={displayPaymentMissingSection}
                    onHide={() => setDisplayPaymentSection(false)}
                    headerMessage={
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                p {
                                    flex-basis: 100%;
                                    text-align: center;
                                }
                                .pi {
                                    font-size: 2em;
                                    color: red;
                                }
                            `}
                        >
                            <div>
                                <i className="pi pi-exclamation-triangle"></i>
                            </div>
                            <p>Register a payment method</p>
                        </div>
                    }
                >
                    <div
                        css={css`
                            padding: 1em 0px;
                            border-radius: 3px;
                            > p {
                                font-size: 1.25em;
                            }
                            a {
                                color: red;
                                border-bottom: 2px solid red;
                            }
                        `}
                    >
                        <p>
                            In order to publish a creative you first need to register a payment method under <Link to="/billing">our Billing page</Link>. After registering a payment method you will be able to publish your custom creatives.
                        </p>
                    </div>
                </PaymentRegistrationBanner>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        align-items: center;
                    `}
                >
                    <div>
                        <h1>Orders</h1>
                    </div>
                    <Cta
                        ctaText="Add a new order"
                        icon={faCirclePlus}
                        onClick={navigateToCreativesScreen}
                        themeClasses="marketplace-cta default outlined"
                    />
                </div>
                <Dialog visible={isConfirmDeletionVisible} onHide={() => setConfirmDeletionVisibility(false)} style={{ width: "50vw" }} header="Order deletion confirmation" icon="pi pi-exclamation-triangle">
                    <p style={{ fontSize: "16px" }}>Deleting this order will delete all the custom creatives and code associated with this and therefore your GAM instance of this order will not work anymore.</p>
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-end;
                            > button {
                                margin: 0 6px;
                            }
                        `}
                    >
                        <Button
                            label="Delete order"
                            className="p-button-success"
                            onClick={() => {
                                setConfirmDeletionVisibility(false);
                                deleteWpOrder(orderDeletionInfo.orderId, orderDeletionInfo.gamLineItemId);
                            }}
                        />
                        <Button
                            label="Cancel"
                            className="p-button-secondary"
                            onClick={() => {
                                setConfirmDeletionVisibility(false);
                                setOrderDeletionInfo(ORDER_DELETION_OBJECT);
                            }}
                        />
                    </div>
                </Dialog>

                <Dialog
                    header={
                        <>
                            <p>Creative script tag:</p>
                            {isAdmin && (
                                <>
                                    <p>
                                        Creative library full path: &nbsp;
                                        <a href={`${protocol}://${host}/wp-content/uploads/crmc/library/adgrid-crmc.js`} target="_blank" rel="noreferrer">
                                            {protocol}://{host}/wp-content/uploads/crmc/library/adgrid-crmc.js
                                        </a>
                                    </p>
                                    <p>
                                        Creative JSON path: &nbsp;
                                        <a href={`${protocol}://${host}/wp-content/uploads/crmc/assets/${currentCustomer}/${currentJsonCode}.json`} target="_blank" rel="noreferrer">
                                            {protocol}://{host}/wp-content/uploads/crmc/assets/{currentCustomer}/{currentJsonCode}.json
                                        </a>
                                    </p>
                                </>
                            )}
                        </>
                    }
                    visible={displayScriptTagOverlay}
                    style={{ width: "70vw" }}
                    modal
                    onHide={() => {
                        setDisplayScriptOverlay(false);
                    }}
                >
                    <AceEditor height="300px" width="100%" mode="html" theme="github" showGutter={true} showPrintMargin={false} value={scriptTagCode} editorProps={{ $blockScrolling: true }} fontSize={16} name="gam_script_tag" />
                </Dialog>

                {ordersInfo.posts_info && (
                    <DataTable
                        value={ordersInfo.posts_info}
                        css={css`
                            table {
                                table-layout: auto;
                                td.order-name {
                                    max-width: 360px;
                                }
                            }
                        `}
                    >
                        <Column field="id" header="Order ID" className="order-id"></Column>
                        <Column field="title" header="Order name" className="order-name"></Column>
                        <Column header="Creative type" body={creativeTypeColumn}></Column>
                        <Column header="Start date" body={startDateColumn} className="start-date"></Column>
                        <Column header="End date" body={endDateColumn} className="end-date" bodyStyle={{ maxWidth: "50px" }}></Column>
                        <Column header="Impressions" body={statsColumn} bodyStyle={{ maxWidth: "50px" }}></Column>
                        <Column header="Ongoing charge" body={ongoingChargeColumn} bodyStyle={{ maxWidth: "50px" }}></Column>
                        <Column header="Status" body={statusColumn} className="status" colSpan={1}></Column>
                        <Column header="Actions" body={renderTableControls}></Column>
                    </DataTable>
                )}

                {isOrdersEmpty && (
                    <div
                        css={css`
                            background-color: #f3edbe;
                            border-left: 6px solid #f44333;
                            padding: 3em 1em;
                            border-radius: 6px;
                            max-width: 768px;
                            margin-top: 2em;
                            > p {
                                font-size: 1.5em;
                            }
                        `}
                    >
                        <p>
                            You haven't created any
                            <button
                                onClick={navigateToCreativesScreen}
                                css={css`
                                    border: none;
                                    text-decoration: underline;
                                    color: blue;
                                    cursor: pointer;
                                    font-size: 1em;
                                `}
                            >custom creatives</button>
                            at the moment
                        </p>
                    </div>
                )}
            </LayoutWrapper>
        </>
    );
};

export default CreativeOrders;
