import React from "react";
// import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { combineReducers, createStore } from "redux";
import App from "./App";
import { client } from "./constants";
import adapterErrorsReducer from "./reducers/adapterErrorsReducer";
import adapterReducer from "./reducers/adapterReducer";
import userLoginReducer from "./reducers/userLoginReducer";
import buildReducer from "./reducers/buildReducer";
import customOrdersReducer from "./reducers/customOrdersReducer";
import ScrollToTop from "./ScrollToTop";
import "./styles/index.css";
import stripeInfoReducer from "./reducers/stripeInfoReducer";
import marketplaceOrdersReducer from "./reducers/marketplaceOrdersReducer";
import messagingReducer from "./reducers/messagingReducer";
import screenChangesReducer from "./reducers/screenChangesReducer";
import commonDomainDataReducer from "./reducers/commonDomainDataReducer";
import marketplaceActionsQueueReducer from "./reducers/marketplaceActionsQueueReducer";
import lineItemStatsReducer from "./reducers/lineItemStatsReducer";
import tooltipTextReducer from "./reducers/tooltipTextReducer";
import multiBuildSystemReducer from "./reducers/multiBuildSystemReducer";
import adapterMutationReducer from "./reducers/adapterMutationReducer";

const reducers = combineReducers({
    adapterMutators: adapterReducer,
    adapterErrors: adapterErrorsReducer,
    userInformation: userLoginReducer,
    buildState: buildReducer,
    orderEditState: customOrdersReducer,
    stripeInfo: stripeInfoReducer,
    marketplaceInfo: marketplaceOrdersReducer,
    globalMessages: messagingReducer,
    screenStateWatcher: screenChangesReducer,
    commonDomainInfo: commonDomainDataReducer,
    marketplaceActionsReducer: marketplaceActionsQueueReducer,
    lineItemStatsReducer,
    tooltipTextReducer,
    multiBuildSystemInfo: multiBuildSystemReducer,
    adapterStateMutationReducer: adapterMutationReducer
});

const store = createStore(reducers);

/*store.subscribe(() =>
    console.log('store.getState(): ',store.getState())
)*/

ReactDOM.render(
    <BrowserRouter basename={"/"}>
        <ScrollToTop>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ApolloProvider>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById("root")
);
