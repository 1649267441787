import React, { useState, useEffect, useRef } from 'react';
import 'react-list-editable/lib/react-list-editable.css';
import { CSSTransition } from 'react-transition-group';
import { InputSwitch } from 'primereact/inputswitch';
import { AppTopbar } from '../AppTopbar';
import { AppMenu } from '../AppMenu';
import { DomainsDropdown } from '../DomainsDropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '../layout/flags/flags.css';
import '../layout/layout.scss';
import '../App.scss';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { wrapperClass, sidebarClassName } from '../constants'
import { gql, useMutation } from '@apollo/client';
import AppLogo from './AppLogo';


const DOMAIN_Edit_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    domainEditMutation(input: {id: $id, cid: $cid}) {
        clientMutationId
        customerInfo
    }
  }
`
const AD_ADAPTERSELECTION_UPDATE_MUTATION = gql`
  mutation callAdAdapterselectionListUpdateMutation($id: String, $adapterselectionList: String, $overrideAdapterselectionList: String) {
    adAdapterselectionListUpdateMutation(input: {id: $id, adapterselectionList: $adapterselectionList, overrideAdapterselectionList: $overrideAdapterselectionList}) {
        clientMutationId
        networkId
    }
  }
`


const AdaptorSelection = (props) => {

    let domainId = props.match.params.domainId?props.match.params.domainId.split('?'):props.match.params.domainId;
  domainId = domainId?domainId[0]:domainId; 

    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [listData, setListData] = useState(null);
    const [listDataOptions, setListDataOptions] = useState(null);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displaNameForAdapters, setDisplaNameForAdapters] = useState(false);
    const [cId, setCId] = useState(false);
    const [presetName, setPresetName] = useState(false);
    const messages = useRef(null);

    const handleChange = (param) => {
        let temp = [...listData];
        var index = temp.indexOf(param.target.id);
        if (index !== -1) {
            temp.splice(index, 1);
        } else {
            temp.push(param.target.id)
        }
        setListData(temp)
    };

    const handleCheck = (item) => {
        return listData.includes(item)
    };

    const refreshPage = () => {
        window.location.reload();
    }

    const addMessages = (message) => {
        messages.current.show([
            {
                severity: "success",
                summary: "Success.",
                detail: message,
                sticky: false
            }
        ]);
    };


    const sidebar = useRef();

    const [DOMAIN_EDIT_MUTATION_CONTAINER, { loading: mutationLoading, error: mutationError, data }] = useMutation(
        DOMAIN_Edit_MUTATION,
        {
            onCompleted(data) {
                confirm(data);
            }
        }
    );

    const [AD_ADAPTERSELECTION_UPDATE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
        AD_ADAPTERSELECTION_UPDATE_MUTATION,
        {
            onCompleted(dataUpdate) {
                addMessages("Adapter Selections Updated.");
                setTimeout(refreshPage, 3000);
            }
        }
    );

    useEffect(() => {
        const search = window.location.search; // returns the URL query String
        const params = new URLSearchParams(search);
        setCId(params.get('cid'));
        DOMAIN_EDIT_MUTATION_CONTAINER({
            variables: { id: domainId, cid: params.get('cid') }
        });
    }, []);

    const confirm = data => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo)
        if (temp_data.adv_preset && temp_data.adv_preset.name) {
            setPresetName(temp_data.adv_preset.name)
        }
        setDisplaNameForAdapters(temp_data.display_name_adapters)
        if (temp_data.adapter_list === null) {
            temp_data.adapter_list = [];
        }
        setListData(temp_data.adapter_list.map(function (item) {
            return item;
        }))
        setListDataOptions(temp_data.adapter_list_choices.map(function (item) {
            return item;
        }))
        setShowCheckBox(temp_data.override_ad_adapterselection_list)
        setMutationDataCompleted(true)
    }

    const overrideCheckBox = () => {
        setShowCheckBox(!showCheckBox)
    }

    const onMenuItemClick = () => {
    }


    const saveAdapterselectionList = () => {
        AD_ADAPTERSELECTION_UPDATE_MUTATION_CONTAINER({
            variables: { id: domainId, adapterselectionList: JSON.stringify(listData), overrideAdapterselectionList: showCheckBox ? 'true' : 'false' }
        });
    }


    return (
        <div className="center w100">
            {mutationDataCompleted && (
                <div className={wrapperClass} >
                    {cId !== false && (
                        <AppTopbar {...props} domain_name={true}  />
                    )}

                    <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                        <div ref={sidebar} className={sidebarClassName} >
                            <AppLogo />
                            <DomainsDropdown />
                            {cId !== false && (
                                <AppMenu {...props} model={'domain'} onMenuItemClick={onMenuItemClick}  />
                            )}
                        </div>
                    </CSSTransition>

                    <div className={`layout-main p-panel p-component ${!showCheckBox ? "disabled-content" : "enabled-content"}`}>
                        <Messages ref={messages} />
                        <div className="p-panel-header">
                            <div className="p-d-flex p-ai-center">
                                <InputSwitch checked={showCheckBox} onChange={overrideCheckBox} />
                                <label htmlFor="cb1" className="p-checkbox-label p-pl-2 p-text-bold" onClick={overrideCheckBox}>Override Adapter Selection{presetName ? " (" + presetName + ")" : ""}</label>
                            </div>
                            <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => saveAdapterselectionList()} />
                        </div>

                        <div className="p-panel-content disable-item">
                            <div className="p-grid">
                                {
                                    listDataOptions.map((item) => {
                                        return (
                                            <div key={item} className="p-field-checkbox p-col-12 p-sm-12 p-md-4 p-lg-4">
                                                {/*<Checkbox
                    <div className="p-panel-content disable-item">
                      <div className="p-grid">
                        {
                            listDataOptions.map((item) => {
                                return (
                                    <div key={item} className="p-field-checkbox p-col-12 p-sm-12 p-md-4 p-lg-3">
                                        {/*<Checkbox
                                            inputId={item}
                                            name={item} value={item}
                                            onChange={onListChange}
                                            checked={listData.some((itm) => itm === item)}
                                            disabled={item === 'R'} />
                                        <label htmlFor={item}>{item}</label>*/
                                                }

                                                <div className="p-d-flex p-ai-center">
                                                    <InputSwitch
                                                        id={item}
                                                        name={item}
                                                        checked={handleCheck(item)}
                                                        onChange={handleChange} />
                                                    <label className="p-ml-2">{displaNameForAdapters[item]}</label>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );

}

export default AdaptorSelection;
