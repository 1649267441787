import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { gql, useMutation } from '@apollo/client';
import Client from './Client';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const DOMAIN_MUTATION = gql`
  mutation callDomainInfoMutation($id: String, $userId: String, $adminRole: Boolean) {
    domainInfoMutation(input: {id: $id, userId: $userId, adminRole: $adminRole }) {
        clientMutationId
        customerInfo
        presetInfo
        industryInfo
        partnerName
    }
  }
`
const DOMAIN_DELETE_MUTATION = gql`
  mutation callDomainDeleteMutation($id: String) {
    domainHardDeleteMutation(input: {id: $id}) {
        clientMutationId
        deleted
    }
  }
`

const DOMAIN_RESTORE_MUTATION = gql`
  mutation callDomainDeleteRestoreMutation($id: String) {
    domainDeleteRestoreMutation(input: {id: $id}) {
        clientMutationId
        deleted
    }
  }
`

const DomainRecycleBin = (props) => {
  const [domainInfo, setDomainInfo] = useState(null);
  const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
  const [adminRole, setAdminRole] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductTitle, setDeleteProductTitle] = useState(null);
  const [deleteProductID, setDeleteProductID] = useState(null);
  const [restoreProductDialog, setRestoreProductDialog] = useState(false);
  const [restoreProductTitle, setRestoreProductTitle] = useState(null);
  const [restoreProductID, setRestoreProductID] = useState(null);
  const messages = useRef(null);
  const messagesDelete = useRef(null);
  const messagesRestore = useRef(null);
  const {userID, currentCustomer, isAdmin} = useSelector(state => state.userInformation)
  const history = useHistory()

  const [DOMAIN_MUTATION_CONTAINER, { loading: mutationLoading, data }] = useMutation(
    DOMAIN_MUTATION,
    {
      onCompleted(data) {
        confirm(data);
      }
    }
  );
  const [DOMAIN_DELETE_MUTATION_CONTAINER] = useMutation(
    DOMAIN_DELETE_MUTATION,
    {
      onCompleted(dataDelete) {
        confirmDelete(dataDelete);
      },
      onError(error) {

      }
    }
  );
  const [DOMAIN_RESTORE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
    DOMAIN_RESTORE_MUTATION,
    {
      onCompleted(dataUpdate) {
        confirmUpdate(dataUpdate);
      }
    }
  );
  useEffect(() => {
    console.log(currentCustomer, userID)
    if (!currentCustomer || !userID) {
      return history.push('/domains')
    }
    setAdminRole(isAdmin)
    DOMAIN_MUTATION_CONTAINER({
      variables: { id: `${currentCustomer}`, userId: `${userID}`, adminRole: isAdmin }
    });
  }, [currentCustomer, userID]);

  const addMessages = (message, severity, summary,) => {
    messages.current.show([
      {
        severity: severity,
        summary: summary,
        detail: message,
        sticky: false
      }
    ]);
  };
  const deleteMessages = (message, severity, summary,) => {
    messagesDelete.current.show([
      {
        severity: severity,
        summary: summary,
        detail: message,
        sticky: false
      }
    ]);
  };

  const confirm = object => {
    let temp_data = object.domainInfoMutation.partnerName
    temp_data = JSON.parse(temp_data)
    setDomainInfo(temp_data.sort(compare));
    setMutationDataCompleted(true)
  }
  if (domainInfo != null) {
    domainInfo.forEach(element => {
      if (element.status === '0' || 'Blocked') {
        element.status = 'Active'
      } else {
        element.status = 'Blocked'
      }
    })
  }

  const refreshPage = () => {
    window.location.reload();
  }
  const confirmUpdate = data => {
    if (data.domainDeleteRestoreMutation.deleted === "exists") {
        addMessages(restoreProductTitle + ' ' + `already exists`, 'error');
    } else {
        addMessages(restoreProductTitle + ' ' + `Domain Restored.`, 'success');
        setTimeout(refreshPage, 3000);
    }
  }

  const confirmDelete = data => {
    deleteMessages(deleteProductTitle + ' ' + `Domain Permanently Deleted.`, 'success');
    setTimeout(refreshPage, 3000);

  }

  const compare = (a, b) => {
    const domainA = a.title.toUpperCase();
    const domainB = b.title.toUpperCase();
    let comparison = 0;

    if (domainA > domainB) {
      comparison = 1;
    } else if (domainA < domainB) {
      comparison = -1;
    }
    return comparison;
  }

  const actionBodyTemplate = (rowData) => {
    if (adminRole === true) {
      return (
        <div className="actions p-text-middle">
          <div>

          </div>
          <Button icon="pi pi-reply" className="p-button-rounded p-button-success p-mr-3" onClick={() => confirmRestoreProduct(rowData)} />
          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
        </div>

      );
    }

  }

  const actionBodyTemplateDelete = (rowData) => {
    if (adminRole === true) {
      return (
        <div className="actions p-text-middle">
          <div>

          </div>
          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
        </div>

      );
    }

  }

  const confirmDeleteProduct = (product) => {
    setDeleteProductTitle(product.title);
    setDeleteProductID(product.id);
    setDeleteProductDialog(true);
  }
  const confirmRestoreProduct = (product) => {
    setRestoreProductTitle(product.title);
    setRestoreProductID(product.id);
    setRestoreProductDialog(true);
  }

  const idBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </React.Fragment>
    );
  }
  const nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.title}
      </React.Fragment>
    );
  }
  const urlBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        deleted
      </React.Fragment>
    );
  }

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  }

  const hideRestoreProductDialog = () => {
    setRestoreProductDialog(false);
  }

  const deleteProduct = () => {
    setDeleteProductDialog(false);
    DOMAIN_DELETE_MUTATION_CONTAINER({
      variables: { id: deleteProductID.toString() }
    });
  }
  const restoreProduct = () => {
    setRestoreProductDialog(false);
    DOMAIN_RESTORE_MUTATION_CONTAINER({
      variables: { id: restoreProductID.toString() }
    });
  }
  const deleteProductDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
    </>
  );

  const restoreProductDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRestoreProductDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={restoreProduct} />
    </>
  );


  return (
    <>
      <Client {...props} />

      <div className="layout-main">
        <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span>Are you sure you want to permanently delete <b>{deleteProductTitle}</b>?</span>}
          </div>
        </Dialog>
        <Dialog visible={restoreProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={restoreProductDialogFooter} onHide={hideRestoreProductDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span>Are you sure you want to restore <b>{restoreProductTitle}</b>?</span>}
          </div>
        </Dialog>

        <div className="p-grid crud-demo">
          <Messages ref={messages} />
          <Messages ref={messagesDelete} />
          <div className="p-col-12 p-md-12 p-lg-12 contact-form">
            <h3>Deleted Domain List</h3>
            {domainInfo && mutationDataCompleted && (
              <div className="datatable-responsive">
                <DataTable value={domainInfo} className="p-datatable-responsive p-datatable-striped" header="">
                  <Column field="id" header="ID" className='p-d-none' body={idBodyTemplate}></Column>
                  <Column field="title" header="Name" body={nameBodyTemplate}></Column>
                  <Column style={{width:'120px', textAlign: 'center'}} field="" header="Action" body={actionBodyTemplate}></Column>
                </DataTable>
              </div>
            )}

          </div>
        </div>
      </div>

    </>

  );
}

export default DomainRecycleBin
