import { useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { getIn, isEmpty, noop } from "../utilities/utils";
import LayoutWrapper from "./LayoutWrapper";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faComputer, faExclamationCircle, faEye, faMobileAndroidAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import md5 from "md5";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { resetEditConfig, setOrderPaymentMethod } from "../reducers/customOrdersReducer";
import { pushMessage } from "../reducers/messagingReducer";
import DropdownSection from "./common/creatives/DropdownSection";
import creativesMappings from "./common/creatives/components";
import { CREATIVES_DEFAULT_DEFINITIONS, CREATIVES_DISPLAY_NAMES, CREATIVE_NAMES, CREATIVE_TYPES, EDIT_ORDERS_MUTATION, GAM_API_MUTATION, LINEITEM_STATS_MUTATION, SAVE_REVISION_MUTATION, STRIPE_CARD_LISTING_MUTATION, SYNDICATION_MUTATION, VIDEO_PROPERTY_MAPPINGS } from "./common/creatives/definitions";
import { getMetadata } from "./common/creatives/functions";
import { accordionHeaderSection, billingSection, cpmSection, creativeSettingsSection, datePickerStyle, deliverySection, deliverySubsection, flatInput, flexRow, generalSettingsSection } from "./common/creatives/styles";
import Cta from "./common/marketplace/CTA";
import { ItemTemplate, ValueTemplate } from "./common/utilityComponents";

library.add(faExclamationCircle);

const CustomCreatives = ({
    mode, // mode can be either 'creative' or 'marketplace'
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [gamApiMutationContainer] = useMutation(GAM_API_MUTATION);
    const [editCreativeOrderMutationContainer] = useMutation(EDIT_ORDERS_MUTATION);
    const [savePostRevision] = useMutation(SAVE_REVISION_MUTATION)
    const { currentCustomer, customers, isAdmin, userID: userId } = useSelector((state) => state.userInformation);
    const { config, creativeType: type, orderId, editMode, lineItemConfig, orderCreator, paymentMethod, stats, currentCpm } = useSelector((state) => state.orderEditState);
    const { customerToStripeAccountMappings, infoAvailable: stripeInfoAvailable } = useSelector((state) => state.stripeInfo);
    const [orderStats, setOrderStats] = useState(stats);
    const [cpm, setCpm] = useState(1);
    const [lineItemStatsMutation] = useMutation(LINEITEM_STATS_MUTATION);
    const [isRequestingGamData, setGamDataRequestState] = useState(false);
    const [campaignName, setCampaignName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [impressionGoals, setImpressionGoals] = useState(1_000_000);
    const [creativeType, setCreativeType] = useState(CREATIVE_NAMES.DISPLAY_INTERSTITIAL);
    const [mobilePreviewMode, setMobilePreviewMode] = useState(false);
    const [isEditingExistingOrder, setIsEditingExistingOrder] = useState(false);
    const [editModeConfigLoaded, setEditModeConfigStatus] = useState(false);
    const [gamStartDate, setGamStartDate] = useState("");
    const [gamEndDate, setGamEndDate] = useState("");
    const iframeRef = useRef(null);
    const [registerCustomer, setRegisterCustomerDialog] = useState(false);
    const [listCreditCardsMutation] = useMutation(STRIPE_CARD_LISTING_MUTATION);
    const [syndicationMutation] = useMutation(SYNDICATION_MUTATION);
    const [isSavingEdits, setIsSavingEdits] = useState(false);
    const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [creativeConfiguration, setCreativeConfiguration] = useState({});

    useEffect(() => {
        setCreativeConfiguration(editMode ? config : CREATIVES_DEFAULT_DEFINITIONS[creativeType]);
        editMode ? setCpm(parseFloat(currentCpm)) : setCpm(creativeType === CREATIVE_NAMES.SYNDICATION_CREATIVE ? 0.5 : 1);
    }, [creativeType]);

    function exitEditCreativeMode() {
        dispatch(pushMessage("Creative Orders", "Exiting 'Edit mode'"));
        setIsEditingExistingOrder(false);
        setCreativeType(CREATIVE_NAMES.DISPLAY_INTERSTITIAL);
        setCreativeConfiguration(CREATIVES_DEFAULT_DEFINITIONS[CREATIVE_NAMES.DISPLAY_INTERSTITIAL]);
        setGamStartDate("");
        setGamEndDate("");
        setStartDate("");
        setEndDate("");
        setCampaignName("");
        setImpressionGoals(1_000_000);
        dispatch(resetEditConfig());
    }

    useEffect(() => {
        if (currentCustomer && customers?.length) {
            const o = customers.filter(({ value }) => value === currentCustomer);
            if (o?.length) {
                let customer = o.pop();
                setCustomerName(customer.name);
            }
            if (orderCreator && orderCreator !== currentCustomer) {
                exitEditCreativeMode();
            }
            if (stripeInfoAvailable && currentCustomer in customerToStripeAccountMappings) {
                listCreditCardsMutation({
                    variables: {
                        stripeCustomerId: customerToStripeAccountMappings[currentCustomer],
                    },
                })
                    .then((response) => {
                        return JSON.parse(getIn(response.data, "stripeCardListMutation", "cardListingResult"));
                    })
                    .then((data) => {
                        let cards = data?.cards?.data || [];
                        setDefaultPaymentMethod(data?.defaultCard || "");
                        setPaymentMethods(
                            cards.map((item) => {
                                let {
                                    card: { brand, exp_month, exp_year, last4 },
                                    id,
                                } = item;
                                return { data: [brand, exp_month, exp_year, last4].join(","), value: id };
                            })
                        );
                        setHasPaymentMethods(!!cards.length);
                    })
                    .catch(() => {
                        setHasPaymentMethods(false);
                        dispatch(setOrderPaymentMethod(""));
                        setDefaultPaymentMethod("");
                        setPaymentMethods([]);
                    });
            }
        }
    }, [currentCustomer, customers, customerToStripeAccountMappings, stripeInfoAvailable]);

    useEffect(() => {
        if (editMode) {
            dispatch(pushMessage("Creative Orders", "You are editing an existing creative. Click on the 'Save edits' button to save your changes"));
            setCreativeType(type);
            setCreativeConfiguration(config);
            setIsEditingExistingOrder(true);
            const d1 = new Date(parseInt(lineItemConfig.startDate));
            const d2 = new Date(parseInt(lineItemConfig.endDate));
            setStartDate(d1);
            setEndDate(d2);
            setGamStartDate(d1.getTime());
            setGamEndDate(d2.getTime());
            setCampaignName(lineItemConfig.orderName);
            setEditModeConfigStatus(true);
            setImpressionGoals(lineItemConfig.impressionGoals);
            if (!stats) {
                lineItemStatsMutation({
                    variables: {
                        lineItemIdsList: JSON.stringify([lineItemConfig.lineItemId]),
                    },
                })
                    .then((response) => {
                        let gamApiStats = JSON.parse(response.data?.getLineItemStatsMutation?.gamApiStats || "{}");
                        setOrderStats(gamApiStats[lineItemConfig.lineItemId]);
                    })
                    .catch((err) => {
                        dispatch(pushMessage("Line item stats", err.message, "error"));
                    });
            }
        }
    }, []);

    useEffect(() => {
        if (editModeConfigLoaded) {
            setTimeout(reloadPreviewIframe, 1500);
        }
    }, [editModeConfigLoaded]);

    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

        // start date cannot be set at any time in the past
        if (!editMode) {
            if (gamStartDate) {
                const setDate = new Date(gamStartDate);
                if (!(setDate.getTime() > today.getTime())) {
                    setStartDate(tomorrow);
                    setGamStartDate(tomorrow.getTime());
                    dispatch(pushMessage("Creative Orders", "Campaign start date must be a date in the future. Resetting", "error"));
                }
            }
            if (gamEndDate && gamStartDate && gamEndDate <= gamStartDate) {
                dispatch(pushMessage("Creative Orders", "Campaign end date must be a date in the future. Resetting", "error"));
            }
        } else {
            let d = parseInt(lineItemConfig.endDate);
            if (gamEndDate && gamEndDate < d) {
                setEndDate("");
                setGamEndDate("");
                dispatch(pushMessage("Creative Orders", "Campaign end date must be a date in the future. Resetting", "error"));
            }
        }

        // end date cannot be <= than start date
    }, [gamStartDate, gamEndDate]);

    useEffect(() => {
        if (creativeType !== type && isEditingExistingOrder) {
            exitEditCreativeMode();
        }

        if (iframeRef && iframeRef.current) {
            setTimeout(() => {
                iframeRef.current.contentWindow.postMessage({ action: "reload", config: { ct: creativeType, startup: true } }, "*");
            }, 250);
        }
    }, [creativeType, iframeRef]);

    async function callSyndicationMutation(creativeConfig, addToFeedList = false) {
        try {
            let feedUrl = creativeConfig?.rssFeedUrl.endsWith("/") ? creativeConfig.rssFeedUrl.slice(0, creativeConfig.rssFeedUrl.length - 1) : creativeConfig.rssFeedUrl;
            let hostname = new URL(feedUrl).hostname;
            const response = await syndicationMutation({
                variables: {
                    feedName: hostname + md5(feedUrl),
                    feedUrl,
                    addToFeedList,
                },
            });
            return JSON.parse(response.data?.syndicationUpdateMutation?.syndicationResult || null);
        } catch (err) {
            // dispatch(pushMessage("Custom creatives", err.message, "error"));
        }
    }

    function refreshPreviewIframe() {
        try {
            iframeRef.current.src = process.env.REACT_APP_CREATIVES_DEMO + `?s=${process.env.REACT_APP_SERVER}&crmcId=${orderId}&cid=${currentCustomer}`
            dispatch(pushMessage("Creative Orders", "Reloading creative preview"));
        } catch (err) {
            console.log(err);
        }
    }

    const reloadPreviewIframe = async () => {
        try {
            let config = creativesMappings[creativeType].config;
            let areRequiredFieldsMissing = config?.required?.some((v) => isEmpty(config[v]));

            if (areRequiredFieldsMissing) throw new Error("Some required fields missing");
            if (creativeType === CREATIVE_NAMES.SYNDICATION_CREATIVE) {
                await callSyndicationMutation(config);
                refreshPreviewIframe();
            } else {
                refreshPreviewIframe();
            }
        } catch (err) {
            dispatch(pushMessage("Creative Orders", "Required fields are missing", "error"));
        }
    };

    const togglePreviewMode = () => {
        iframeRef.current.style.width = mobilePreviewMode ? "100%" : "480px";
        iframeRef.current.style.height = mobilePreviewMode ? "100%" : "768px";
        setMobilePreviewMode(!mobilePreviewMode);
        reloadPreviewIframe();
    };

    const handleChange = (ev, value) => {
        setCreativeConfiguration({ ...creativeConfiguration, [ev.target.name]: value });
    };

    const handleChanges = (o) => {
        setCreativeConfiguration({ ...creativeConfiguration, ...o })
    }

    const publishCustomCreative = async () => {
        let generalSettings = 
            {
                campaignName: 'Campaign name',
                gamStartDate: 'Campaign start date',
                gamEndDate: 'Campaign end date',
                customerName: 'Customer not selected'
            }
        /** @type {string[]} */
        let requiredCreativeSettings 
            = CREATIVES_DEFAULT_DEFINITIONS[creativeType].required
        let missingGeneralSettings 
            = [campaignName, gamStartDate, gamEndDate, customerName].filter(v => isEmpty(v))
        let missingCreativeSettings
            = requiredCreativeSettings.filter(prop => isEmpty(creativeConfiguration[prop]))
        /** @type {{ [key: string]: string }} */
        let requiredPropertyNameMappings
            = CREATIVES_DEFAULT_DEFINITIONS[creativeType].requiredPropertyNameMappings

        if (isEmpty(missingGeneralSettings) && isEmpty(missingCreativeSettings)) {
            /* Remove the 'required' property of the configuration object. We only need it for validation purposes but not in the final JSON file  */
            let campaignStartDate = +new Date(gamStartDate);
            let campaignEndDate = +new Date(gamEndDate);
            let creativeConfig = { ...creativeConfiguration };
            let videoCreativeMetadata = {};
            let cubeCreativeMetadata = [];
            let cubeVideoAdUnits = [];
            let mutationData = {};
            let isCubeVideoCreative = false;
            let isVastCreative = false;
            let videoCreativeProperty = VIDEO_PROPERTY_MAPPINGS[creativeType]?.videoProperty;
            creativeConfig.impressionTag = creativeConfig.impressionTag ? creativeConfig.impressionTag.replace(/"/g, "'") : "";

            if (editMode) {
                setIsSavingEdits(true);
            } else {
                setGamDataRequestState(true);
            }

            if (creativeType === CREATIVE_NAMES.SYNDICATION_CREATIVE) {
                const server = process?.env?.REACT_APP_SERVER;
                const mutationData = await callSyndicationMutation(creativeConfig, true);
                creativeConfig.site = `https://${server}/backend-wp/wp-content/uploads/crmc/${mutationData.feedName}.xml`;
            }

            creativeConfig.enabled = true;

            if (creativeType === CREATIVE_NAMES.CUBE) {
                delete creativeConfig.vast1;
                delete creativeConfig.vast2;
                delete creativeConfig.vast3;
                delete creativeConfig.vast4;

                const vastRegex = /vast|gampad|pubads|doubleverify|ad\.doubleclick\.net|rtr\.innovid\.com/;
                const cubeSides = ["side1", "side2", "side3", "side4"]
                const sideTypes = ['side1Type', 'side2Type', 'side3Type', 'side4Type']
                isVastCreative = sideTypes.some(sideType => creativeConfig[sideType] === 'vast')

                if (isVastCreative) {
                    isCubeVideoCreative = true
                    cubeSides.forEach((side, sideIdx) => {
                        let videoUrl = creativeConfig[side];
                        let currIdx = sideIdx + 1
                        let tau = 'crmc_video_cube_' + currIdx
                        if (videoUrl && vastRegex.test(videoUrl)) {
                            cubeCreativeMetadata.push({
                                vastProperty: 'vast' + currIdx,
                                videoUrl,
                                tau,
                                duration: creativeConfig['vast' + currIdx + 'Duration']
                            })
                            cubeVideoAdUnits.push(tau)
                        }
                    })
                } else if (sideTypes.some(sideType => creativeConfig[sideType] === 'video')) {
                    for (let [idx, s] of cubeSides.entries()) {
                        let cubeSideValue = creativeConfig[s];
                        if (cubeSideValue && creativeConfig[s + 'Type'] === 'video') {
                            isCubeVideoCreative = true;
                            let currIdx = idx + 1;
                            let videoMetadata = await getMetadata(cubeSideValue);
                            let tau = "crmc_video_cube_" + currIdx
                            cubeCreativeMetadata.push({
                                videoUrl: cubeSideValue,
                                duration: videoMetadata.duration,
                                byteLength: videoMetadata.contentLength,
                                bitrate: Math.floor(videoMetadata.contentLength / videoMetadata.duration),
                                vastProperty: "vast" + currIdx,
                                tau,
                            });
                            cubeVideoAdUnits.push(tau);
                        }
                    }
                }

                mutationData = {
                    ...creativeConfig,
                    videoCreativeMetadata: cubeCreativeMetadata,
                    cubeVideoAdUnits,
                    isVideoCreative: isCubeVideoCreative,
                    creativeType,
                    customerName,
                    isVastCreative
                };
            } else if (creativeConfig.vastUrl) {
                mutationData = {
                    ...creativeConfig,
                    ...videoCreativeMetadata,
                    isVideoCreative: true,
                    creativeType,
                    customerName,
                    videoProperty: videoCreativeProperty,
                    tau: VIDEO_PROPERTY_MAPPINGS[creativeType]?.tau,
                };
                isVastCreative = true
            } else if (videoCreativeProperty) {
                let metaData = await getMetadata(creativeConfig[videoCreativeProperty]);
                let duration = parseInt(metaData.duration);
                let byteLength = parseInt(metaData.contentLength);
                videoCreativeMetadata.duration = duration;
                videoCreativeMetadata.bitrate = Math.floor(byteLength / duration);
                creativeConfig.duration = duration;
                mutationData = {
                    ...creativeConfig,
                    ...videoCreativeMetadata,
                    isVideoCreative: true,
                    creativeType,
                    customerName,
                    videoProperty: videoCreativeProperty,
                    tau: VIDEO_PROPERTY_MAPPINGS[creativeType]?.tau,
                };
            } else {
                mutationData = { ...creativeConfig };
            }

            mutationData.cpm = cpm;

            try {
                if (editMode) {
                    let mutationVariables = {
                        id: orderId,
                        customerId: String(currentCustomer),
                        data: JSON.stringify(mutationData),
                        lineItemId: lineItemConfig.lineItemId,
                        orderName: campaignName,
                        startDate: String(Math.floor(gamStartDate / 1000)),
                        endDate: String(Math.floor(gamEndDate / 1000)),
                        impressionGoals: String(impressionGoals),
                        paymentMethodId: paymentMethod || defaultPaymentMethod,
                        isVastCreative
                    };
                    let response = await editCreativeOrderMutationContainer({
                        variables: mutationVariables,
                    });

                    setIsSavingEdits(false);
                    let result = JSON.parse(response.data?.editCustomOrdersMutation?.mutationResult);
                    dispatch(pushMessage("Creative orders", result.message));
                    await savePostRevision({
                        variables: {
                            postId: String(orderId),
                            userId: String(userId)
                        }
                    })
                } else {
                    let mutationVariables = {
                        id: String(currentCustomer),
                        customerName,
                        campaignName,
                        creativeType,
                        creativeJson: JSON.stringify(mutationData),
                        gamStartDate: `${Math.floor(campaignStartDate / 1000)}`,
                        gamEndDate: `${Math.floor(campaignEndDate / 1000)}`,
                        impressionGoals: `${impressionGoals}`,
                        isTestOrder: window.location.hostname !== "console.adgrid.io",
                        creativeTypeDisplayName: CREATIVES_DISPLAY_NAMES[creativeType],
                        paymentMethodId: paymentMethod || defaultPaymentMethod,
                        isVastCreative
                    };
                    
                    let gamApiResponse = await gamApiMutationContainer({
                        variables: mutationVariables,
                    });
                    let gamData = JSON.parse(gamApiResponse.data.createGamLineItemMutation.gamApiResponse);
                    if (gamData) {
                        if (gamData.errorMessage) {
                            dispatch(pushMessage("Creative orders", gamData.errorMessage, 'error'))
                        } else {
                            dispatch(pushMessage("Creative Orders", "Custom creative has been created"));
                            setTimeout(() => {
                                history.push("/creative-orders");
                            }, 2000);
                        }
                    }

                    setGamDataRequestState(false);
                }
            } catch (err) {
                setIsSavingEdits(false);
                setGamDataRequestState(false);
                dispatch(pushMessage("Creative orders", err.message, "error"));
            }
        } else {
            if (missingGeneralSettings.length) {
                let mappings = 
                    {
                        campaignName,
                        gamStartDate,
                        gamEndDate,
                        customerName
                    };
                
                ['campaignName', 'gamStartDate', 'gamEndDate', 'customerName']
                    .filter(prop => isEmpty(mappings[prop]))
                    .forEach(prop => {
                        dispatch(
                            pushMessage(
                                'Required',
                                generalSettings[prop],
                                'error'
                            )
                        )
                    })
            } else if (missingCreativeSettings.length) {
                missingCreativeSettings.forEach(prop => {
                    dispatch(
                        pushMessage(
                            'Required',
                            requiredPropertyNameMappings[prop],
                            'error'
                        )
                    )
                })
            }
        }
    };

    return (
        <>
            <LayoutWrapper model={mode} renderOpsBar={false}>
                <Accordion activeIndex={0}>
                    <AccordionTab
                        className="config-tab"
                        header={
                            <div>
                                <div>
                                    <i className="pi pi-cog" style={{ fontSize: "1em" }}></i>
                                    <strong style={{ display: "inline-block", marginLeft: "10px", fontSize: "1em" }}>General Settings</strong>
                                </div>
                                <div css={accordionHeaderSection}>
                                    {campaignName && (
                                        <span>
                                            <span className="n">Order name:</span> {campaignName}
                                        </span>
                                    )}
                                    {orderId && (
                                        <span>
                                            <span className="n">Order Id:</span> {orderId}
                                        </span>
                                    )}
                                    {startDate && (
                                        <span>
                                            <span className="n">Start date:</span> {startDate.toLocaleDateString()}
                                        </span>
                                    )}
                                    {endDate && (
                                        <span>
                                            <span className="n">End date:</span> {endDate.toLocaleDateString()}
                                        </span>
                                    )}
                                    {editMode && orderStats && (
                                        <span>
                                            <span className="n">Impressions:</span> {orderStats?.impressionsDelivered?.toLocaleString()}
                                        </span>
                                    )}
                                    {editMode && orderStats && (
                                        <span>
                                            <span className="n">Clicks:</span> {orderStats?.clicksDelivered?.toLocaleString()}
                                        </span>
                                    )}
                                </div>
                            </div>
                        }
                    >
                        <div
                            css={css`
                                ${flexRow};
                                section {
                                    margin-top: 0.5em;
                                    .section-header {
                                        min-width: 70px;
                                    }
                                }
                            `}
                        >
                            <section css={deliverySection}>
                                <div className="section-header">
                                    <h5>Delivery</h5>
                                </div>
                                <div css={deliverySubsection}>
                                    <div css={generalSettingsSection}>
                                        <div className="item" css={flatInput}>
                                            <p>
                                                <i className="pi pi-info-circle"></i>
                                                <strong className="label">Order Name</strong>
                                            </p>
                                            <InputText name="campaign_name" value={campaignName} onChange={(ev) => setCampaignName(ev.target.value)} />
                                        </div>
                                        <div className="date-ranges" css={flexRow}>
                                            <div className="item start-date">
                                                <p>
                                                    <i className="pi pi-calendar"></i>
                                                    <strong className="label">Start Date</strong>
                                                </p>
                                                <DatePicker
                                                    css={datePickerStyle}
                                                    selected={startDate}
                                                    disabled={editMode}
                                                    onChange={(d) => {
                                                        setStartDate(d);
                                                        setGamStartDate(d.getTime());
                                                    }}
                                                    placeholderText="Select a day, month, and year"
                                                />
                                                <span className="label">{startDate ? "Campaign starts at 12:00 am" : ""}</span>
                                            </div>
                                            <div className="item end-date">
                                                <p>
                                                    <i className="pi pi-calendar"></i>
                                                    <strong className="label">End date</strong>
                                                </p>
                                                <DatePicker
                                                    css={datePickerStyle}
                                                    selected={endDate}
                                                    onChange={(d) => {
                                                        setEndDate(d);
                                                        setGamEndDate(d.getTime());
                                                    }}
                                                    placeholderText="Select a day, month, and year"
                                                />
                                                <span className="label">{endDate ? "Campaign ends at 11:59 pm" : ""}</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <i className="pi pi-check-circle" style={{ color: "green" }}></i>
                                                <strong className="label">Impression Goals</strong>
                                            </p>
                                            <InputNumber
                                                css={flatInput}
                                                name="impression_goals"
                                                value={impressionGoals}
                                                onValueChange={(ev) => {
                                                    setImpressionGoals(ev.value);
                                                }}
                                                max={100_000_000}
                                            />
                                            <span className="label">
                                                <b>{impressionGoals && `At $${cpm.toFixed(2)} CPM, your estimated billing costs for ${impressionGoals.toLocaleString()} impressions equal to $${((impressionGoals * cpm) / 1000).toLocaleString()}`}</b>
                                            </span>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <i className="pi pi-dollar" style={{ color: "green" }}></i>
                                                <strong className="label">Payment Method</strong>
                                            </p>
                                            <Dropdown
                                                value={paymentMethod || defaultPaymentMethod /* the payment method id goes here */}
                                                options={paymentMethods /* list of possible payment methods go here */}
                                                onChange={(ev) => {
                                                    /* handle for when there are several payment methods and you get to pick one or another */
                                                    dispatch(setOrderPaymentMethod(ev.value));
                                                }}
                                                optionLabel="data"
                                                optionValue="value"
                                                itemTemplate={(v) => <ItemTemplate cardData={v} />}
                                                valueTemplate={(v) => <ValueTemplate cardData={v} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section css={billingSection}>
                                <div className="section-header">
                                    <h5>Billing</h5>
                                </div>
                                <div css={deliverySubsection}>
                                    {(editMode && orderStats) || creativeType === CREATIVE_NAMES.SYNDICATION_CREATIVE ? (
                                        <div>
                                            <p>
                                                <strong>Impressions:</strong> {orderStats?.impressionsDelivered?.toLocaleString() || 0}
                                            </p>
                                            <p>
                                                <strong>CPM:</strong>
                                                <InputNumber
                                                    disabled={!isAdmin}
                                                    name="name"
                                                    value={cpm}
                                                    mode="currency"
                                                    currency="USD"
                                                    locale="en-US"
                                                    max={100}
                                                    css={cpmSection}
                                                    onChange={(e) => {
                                                        setCpm(e.value || 0);
                                                    }}
                                                />
                                            </p>
                                            <p>
                                                <strong>Total:</strong> ${(((orderStats?.impressionsDelivered || 0) / 1000) * cpm).toFixed(2)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p>Your ongoing custom creative impression statistics and billing information will be shown here after your creative has been published</p>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </div>
                    </AccordionTab>
                </Accordion>
                <Dialog
                    header={
                        <div>
                            <p>
                                <FontAwesomeIcon icon="exclamation-circle" color="#ff0000" /> &nbsp; Creative publication error
                            </p>
                        </div>
                    }
                    visible={registerCustomer}
                    style={{ width: "50vw" }}
                    onHide={() => setRegisterCustomerDialog(false)}
                >
                    <p>
                        In order to publish a creative you first need to register a payment method under <Link to="/billing">our Billing page</Link>. After registering a payment method you will be able to publish your custom creatives.
                    </p>
                </Dialog>

                <div
                    className="creative"
                    css={css`
                        display: flex;
                        flex-direction: row;
                        margin-top: 1em;
                    `}
                >
                    <div className="creative-settings" style={{ minWidth: "300px" }}>
                        <section css={creativeSettingsSection}>
                            {!editMode && (
                                <DropdownSection
                                    label="Type of creative"
                                    name="creative_type"
                                    value={creativeType}
                                    options={
                                        CREATIVE_TYPES
                                            .sort((ta, tb) => {
                                                return ta.label.localeCompare(tb.label)
                                            })
                                            .filter(item => {
                                                return item.adminOnly
                                                    ? isAdmin
                                                    : true
                                            })
                                    }
                                    changeHandler={(ev) => {
                                        setCreativeType(ev.value);
                                    }}
                                />
                            )}
                            {editMode && <h5>{CREATIVES_DISPLAY_NAMES[config.creativeType]}</h5>}

                            {Object.keys(creativesMappings).map((componentName) => {
                                let CreativeComponent = creativesMappings[componentName];
                                return (
                                    <div key={componentName} style={{ display: creativeType === componentName ? "block" : "none" }}>
                                        <CreativeComponent {...creativeConfiguration} handleChange={handleChange} currentCustomer={currentCustomer} handleChanges={handleChanges} />
                                    </div>
                                );
                            })}
                        </section>
                    </div>
                    <div
                        className="creative-preview"
                        css={css`
                            width: 100%;
                            margin-left: 20px;
                            iframe {
                                width: 100%;
                                height: 100%;
                                border: 1px solid #dee2e6;
                                border-radius: 3px;
                            }
                        `}
                    >
                        <iframe src={process.env.REACT_APP_CREATIVES_DEMO} width="100%" height="100%" title="Creative iframe" ref={iframeRef}></iframe>
                    </div>
                </div>
                <div className="ops-bar">
                    <Cta ctaText={editMode ? "Save edits" : "Save"} disabled={isRequestingGamData || isSavingEdits || !hasPaymentMethods} showLoader={isRequestingGamData || isSavingEdits} onClick={publishCustomCreative} icon={faSave} themeClasses="marketplace-cta default outlined" />
                    <Cta ctaText="Preview" onClick={reloadPreviewIframe} icon={faEye} themeClasses="marketplace-cta default outlined" />
                    <Cta ctaText={mobilePreviewMode ? "Switch to desktop view" : "Mobile preview"} icon={mobilePreviewMode ? faComputer : faMobileAndroidAlt} onClick={togglePreviewMode} themeClasses="marketplace-cta default outlined" />
                </div>
            </LayoutWrapper>
        </>
    );
};

export default CustomCreatives;
