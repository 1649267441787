import { gql, useMutation } from "@apollo/client";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Messages } from "primereact/messages";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavigationMode } from "../../reducers/userLoginReducer";
import SectionHeader from "../helper/SectionHeader";
import {addToPresetBuildList} from "../../reducers/buildReducer";
import { pushMessage } from "../../reducers/messagingReducer";
import LayoutWrapper from "../LayoutWrapper";
import { registerScreenChanges } from "../../reducers/screenChangesReducer";
import TrafficShaping from "../TrafficShaping";
import { foldRules } from "../common/trafficShaping/utils";
import {MultiSelect} from "primereact/multiselect";
import {COUNTRIES} from "../common/trafficShaping/constants";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        presetAllInfoMutation(input: { id: $id, cid: $cid }) {
            presetInfo
        }
    }
`;

const OPTIMIZE_BIDDING_UPDATE_MUTATION = gql`
    mutation callOptimizeBiddingUpdateMutation(
            $id: String,
            $optimizeBiddingList: String,
            $trafficShapingSettings: String,
            $adDisableHBGlobally: Boolean,
            $adDisableHB_GloballyByCountries: String
        ) {
        updatePresetOptimizeBiddingMutation(
            input: {
                id: $id,
                optimizeBiddingList: $optimizeBiddingList,
                trafficShapingSettings: $trafficShapingSettings,
                adDisableHBGlobally: $adDisableHBGlobally,
                adDisableHB_GloballyByCountries: $adDisableHB_GloballyByCountries
            }
        ) {
            clientMutationId
            networkId
        }
    }
`;

const InputRow = ({
    children,
    tooltipText,
    htmlFor,
    labelText,
    isRequired
}) => {
    return (
        <div className="p-grid small-margin-top-10">
            <div className="p-col-3 p-sm-none small-hide p-mt-2">
                <label className="adpositiontooltip after" data-pr-tooltip={tooltipText} htmlFor={htmlFor}>
                    <strong>
                        {labelText}
                        {isRequired && <span className="color-red">*</span>}
                    </strong>
                </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                <div className="p-inputgroup p-fluid">
                    {children}
                </div>
            </div>
        </div>
    )
}

const PresetOptimizeBidding = () => {
    const { currentCustomer, currentPreset } = useSelector((state) => state.userInformation);
    const [settingsData, setSettingsData] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const messages = useRef(null);
    const dispatch = useDispatch();
    const [bidders, setBidders] = useState([])
    const [rules, setRules] = useState([])

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });


    const [OPTIMIZE_BIDDING_UPDATE_MUTATION_CONTAINER] = useMutation(OPTIMIZE_BIDDING_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(addToPresetBuildList(currentPreset));
            dispatch(pushMessage("Optimize Bidding Settings", "Optimize Bidding Settings Updated."));
        },
    });

    const [inputList, setInputList] = useState(null);

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentPreset}`, cid: currentCustomer },
            });
            dispatch(setNavigationMode("preset"));
        }
    }, [currentCustomer, currentPreset]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo);

        //console.log({temp_data}) //setSelectedCountry(value); disable_hb_globally_by_country

        setSettingsData(temp_data);
        temp_data.disable_hb_globally_by_country && setSelectedCountry(JSON.parse(temp_data.disable_hb_globally_by_country));

        setInputList({ prebid_timeout: temp_data.global_prebid_timeout, split_prebid_timeout: temp_data.split_prebid_timeout, inview_prebid_timeout: temp_data.inview_prebid_timeout, outofview_prebid_timeout: temp_data.outofview_prebid_timeout });

        if (temp_data.adapter_list && Array.isArray(temp_data.adapter_list)) {
            setBidders(
                temp_data.adapter_list.reduce((acc, curr) => {
                    acc.push({name: curr, value: curr})
                    return acc
                }, [])
            )
        }

        try {
            if (temp_data.trafficShapingSettings) {
                let tempRules = JSON.parse(temp_data.trafficShapingSettings)
                setRules(Array.isArray(tempRules.rules) ? tempRules.rules : [])
            }
        } catch {
            setRules([])
        }

        setMutationDataCompleted(true);
    };

    const handleChange = (index, param, targetName = null) => {
        if (param.target !== undefined && param.target.name !== undefined) {
            let name = param.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            setInputList(temp);
            dispatch(registerScreenChanges("Preset Optimize Bidding"));
        } else {
            let name = targetName !== null ? targetName : param.originalEvent.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            setInputList(temp);
            dispatch(registerScreenChanges("Preset Optimize Bidding"));
        }
    };

    const handleChangeHB = (params) => {

        let {target: {name, value}} = params;

        let temp = {...settingsData};

        switch (name) {
            case "disable_hb_globally_by_country":
                setSelectedCountry(value);
                temp[name] = JSON.stringify(value);
                break;
            default:
                temp[name] = value;
        }

        setSettingsData(temp);
    }

    const saveOptimizeBiddingData = () => {
        return OPTIMIZE_BIDDING_UPDATE_MUTATION_CONTAINER({
            variables: {
                id: `${currentPreset}`,
                optimizeBiddingList: JSON.stringify(inputList),
                trafficShapingSettings: JSON.stringify({rules, trafficShapingSettings: foldRules(rules)}),
                adDisableHBGlobally: settingsData.disable_hb_globally,
                adDisableHB_GloballyByCountries: settingsData.disable_hb_globally_by_country,
            },
        });
    };

    return (
        <LayoutWrapper
            model="preset"
            saveCallback={async () => {
                if (!inputList.prebid_timeout) {
                    dispatch(pushMessage("Optimize Bidding Settings", "Global Prebid Timeout is required", "error"));
                    throw new Error("Global prebid timeout is required");
                } else if (!inputList.inview_prebid_timeout && inputList.split_prebid_timeout) {
                    dispatch(pushMessage("Optimize Bidding Settings", "Inview Prebid Timeout is required", "error"));
                    throw new Error("Inview prebid timeout is required");
                } else {
                    saveOptimizeBiddingData();
                }
            }}
        >
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content">
                        <Messages ref={messages} />
                        <div>

                            <div className="p-panel p-component mb-1">
                                <div className="p-datatable p-component p-datatable-gridlines">
                                    <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                        <div className="p-d-flex p-ai-center f-width">

                                            <InputSwitch
                                                id="disable_hb_globally"
                                                name="disable_hb_globally"
                                                checked={settingsData.disable_hb_globally}
                                                onChange={handleChangeHB}
                                            />

                                            <label
                                                htmlFor="disable_hb_globally"
                                            >
                                                <strong> &nbsp; Disable Header Bidding</strong>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-panel p-component">
                                        <div className="p-panel-content">

                                            <div className="container conditions-container">
                                                <p className={settingsData.disable_hb_globally ? "text-black" : "gray"}>
                                                    <strong>Disable Country(ies)</strong>
                                                </p>
                                                <div>
                                                    <MultiSelect
                                                        disabled={!settingsData.disable_hb_globally}
                                                        value={selectedCountry}
                                                        onChange={handleChangeHB}
                                                        name="disable_hb_globally_by_country"
                                                        options={COUNTRIES}
                                                        optionLabel="name"
                                                        placeholder="Select Country"
                                                        maxSelectedLabels={3}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-panel p-component">
                                <SectionHeader screenName="Preset optimize bidding">
                                    <div className="p-text-bold">Optimize Bidding Settings ddd</div>
                                </SectionHeader>
                                <Tooltip target=".adpositiontooltip" />
                                <div className="p-panel-content">

                                    <InputRow
                                        tooltipText={"Amount of time that prebid allows the bidders to respond to ad requests."}
                                        htmlFor={"prebid_timeout"}
                                        isRequired={true}
                                        labelText={"Global Prebid Timeout"}
                                    >
                                        <InputNumber
                                            inputId="prebid_timeout"
                                            name="prebid_timeout"
                                            value={inputList.prebid_timeout}
                                            onChange={(e) => handleChange(null, e, "prebid_timeout")}
                                            mode="decimal"
                                            min={0}
                                            max={99999999}
                                        />
                                    </InputRow>

                                    <InputRow
                                        tooltipText={"If enabled, the prebid timeout splits between In-View and Out-of-View prebid timeout."}
                                        htmlFor={"split_prebid_timeout"}
                                        labelText={"Split Prebid Timeout"}
                                    >
                                        <InputSwitch name="split_prebid_timeout" checked={inputList.split_prebid_timeout} onChange={(e) => handleChange(null, e)} />
                                                <label htmlFor="split_prebid_timeout" className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="If enabled, the prebid timeout splits between In-View and Out-of-View prebid timeout.">
                                                    <strong>Split Prebid Timeout</strong>
                                                </label>
                                    </InputRow>

                                    <InputRow
                                        tooltipText={"Amount of time that prebid allows the bidders to respond to ad requests for In-View ad."}
                                        htmlFor={"inview_prebid_timeout"}
                                        isRequired={true}
                                        labelText={"In-View Prebid Timeout"}
                                    >
                                        <InputNumber inputId="inview_prebid_timeout" name="inview_prebid_timeout" value={inputList.inview_prebid_timeout} onChange={(e) => handleChange(null, e, "inview_prebid_timeout")} mode="decimal" min={0} max={99999999} />
                                    </InputRow>

                                    <InputRow
                                        tooltipText={"Amount of time that prebid allows the bidders to respond to ad requests for Out-of-View ad."}
                                        htmlFor={"outofview_prebid_timeout"}
                                        isRequired={false}
                                        labelText={"Out-of-View Prebid Timeout"}
                                    >
                                        <InputNumber inputId="outofview_prebid_timeout" name="outofview_prebid_timeout" value={inputList.outofview_prebid_timeout} onChange={(e) => handleChange(null, e, "outofview_prebid_timeout")} mode="decimal" min={0} max={99999999} />
                                    </InputRow>

                                </div>
                            </div>
                            <div className="p-panel p-component small-margin-top-10" style={{marginTop: '1em'}}>
                                <SectionHeader screenName="Preset optimize bidding">
                                    <div className="p-text-bold">Traffic Shaping Settings</div>
                                </SectionHeader>
                                <div className="p-panel-content">
                                    <TrafficShaping
                                        bidders={bidders}
                                        rules={rules}
                                        setRules={setRules}
                                        disableControls={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader title="Description" />
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Global Prebid Timeout</h6>
                                <p>It is the amount of time that prebid allows the bidders to respond to ad requests.</p>
                                <h6 className="p-text-bold p-mb-0">Split Prebid Timeout</h6>
                                <p>If enabled, the prebid timeout splits between In-View and Out-of-View prebid timeout. When disabled prebid timeout will be the value of 'Global prebid timeout'.</p>
                                <h6 className="p-text-bold p-mb-0"> In-View Prebid Timeout</h6>
                                <p>It is the amount of time that prebid allows the bidders to respond to ad requests when ad is on viewport.</p>
                                <h6 className="p-text-bold p-mb-0">Out-of-View Prebid Timeout</h6>
                                <p>It is the amount of time that prebid allows the bidders to respond to ad requests when ad is in on outside of the viewport.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetOptimizeBidding;
