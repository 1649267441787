import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import MarketplacePartners from "../../MarketplacePartners";

const DomainSelectionOverlay = ({
    onHide,
    isVisible,
    marketplaceNetworks,
    networkMappings,
    handleDomainSelection,
    orderIndex,
    marketplaceOrders,
    currentCustomer,
    domainToCustomerMappings
}) => {

    function getParticipatingDomains() {
        let participatingDomains = [];
        try {
            let orderFormat = marketplaceOrders[orderIndex]?.format

            if (!orderFormat) {
                return participatingDomains
            }

            for (let [id, v] of Object.entries(networkMappings || {})) {
                /* skip domains belonging to the currently-selected customer */
                if (domainToCustomerMappings[id] === currentCustomer) {
                    continue;
                }

                let isMarketplaceInformationSet = v.marketplaceEnabled && v.domainOverview && Object.keys(v.domainOverview).length

                let isAdSizeInformationSet = v.sizesSet &&
                    Object.getPrototypeOf(v.sizesSet) === Set.prototype

                if (isMarketplaceInformationSet) {
                    if (orderFormat === 'video') {
                        if (v.domainOverview.allows_video) {
                            participatingDomains.push({ ...v.domainOverview, id })
                        }
                    } else if (orderFormat === 'display') {
                        if (!marketplaceOrders[orderIndex].displaySizes || marketplaceOrders[orderIndex].displaySizes.length === 0) {
                            participatingDomains.push({ ...v.domainOverview, id })
                        } else if (isAdSizeInformationSet) {
                            let domainContainsSize = marketplaceOrders[orderIndex]
                                .displaySizes
                                .some(size => v.sizesSet.has(size))

                            if (domainContainsSize)
                                participatingDomains.push({ ...v.domainOverview, id })

                        } else {
                            /* as a default, if a domain doesn't have any ad size information configured for it, just add it to the list of available domains in the marketplace */
                            participatingDomains.push({ ...v.domainOverview, id })
                        }
                    }
                }

            }
        } catch (err) {
            console.log(err)
        }

        return participatingDomains;
    }

    function getSelectedDomainsSet() {
        return new Set(marketplaceOrders?.[orderIndex]?.networks || [])
    }

    return (
        <Dialog
            visible={isVisible}
            style={{ width: "80%" }}
            onHide={onHide}
            css={css`
                .p-dialog-content {
                    min-height: 350px;
                }
            `}
        >
            <MarketplacePartners
                allowDownloadCsvLink={true}
                allowLayoutSelection={true}
                availableNetworks={marketplaceNetworks}
                participatingDomains={getParticipatingDomains(networkMappings)}
                hasStaticData={true}
                initialLayout="row"
                selectedDomainsSet={getSelectedDomainsSet()}
                enableDomainSelection={true}
                domainSelectionHandler={({ domainId }) => {
                    let selectedSet = getSelectedDomainsSet();
                    handleDomainSelection({ orderIndex, isAdditionOperation: !selectedSet.has(domainId), domainId })
                }}
            />
        </Dialog>
    );
};

export default DomainSelectionOverlay;
