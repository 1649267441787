/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { noop } from "../../../utilities/utils";

const NoticeSection = ({ text, ctaHandler, visible = true }) => {
    const [isDismissed, setDismissed] = useState(false);

    return isDismissed ? null : (
        <div
            css={css`
                display: ${visible ? "flex" : "none !important"};
                align-items: center;
                padding: 2em;
                background-color: #c2eac3 !important;
                border-radius: 3px;
                margin-bottom: 2em;
                .heading {
                    margin: 0;
                    font-weight: 600;
                }
                .controls {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    .review-btn {
                        background-color: #5ba457;
                        border: 1px solid #5ba457;
                    }
                    .close-btn {
                        display: inline-block;
                        margin-left: 1em;
                        color: #b90707;
                        font-weight: 700;
                        font-size: 1.25em;
                        cursor: pointer;
                    }
                }
            `}
        >
            <h2 className="heading">{text}</h2>
            <div className="controls">
                <Button
                    className="review-btn font-weight-700"
                    label="Review and Approve"
                    onClick={() => {
                        ctaHandler && typeof ctaHandler === "function" ? ctaHandler() : noop();
                    }}
                />
                <span
                    className="pi pi-times close-btn"
                    title="Dismiss"
                    onClick={() => {
                        setDismissed(true);
                    }}
                ></span>
            </div>
        </div>
    );
};

export default NoticeSection;
