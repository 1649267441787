/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { MarketplaceUploadControls } from "../creatives/MarketplaceUploadControls";
import UploadAssetSection from "../creatives/UploadAssetSection";
import OrderRow from "./OrderRow";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const uploadRowStyle = css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .empty-creatives-notice {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-grow: 1;
        font-size: 1.5em;
    }
`;

const creativePreviewStyles = css`
    .creative {
        display: flex;
        flex-basis: 768px;
        flex-direction: row;
        padding: 6px 0;
        width: 768px;
        min-height: 100px;
        margin-top: 1em;
        align-items: center;
        border: 1px solid #efe2e2;
        border-radius: 9px;
        .thumb {
            width: 100px;
            min-width: 100px;
            height: auto;
            max-height: 150px;
            overflow: hidden;
            margin: 0 1em;
        }
        .content {
            flex-grow: 1;
            display: flex;
            align-items: center;
            .info {
                flex-grow: 0.9;
                width: 80%;
                .filename {
                    color: #000;
                    margin-bottom: 0;
                }
                .details {
                    .item {
                        color: #7c7878;
                        font-size: 12px;
                    }
                    .creative-url {
                        color: #308ad3;
                        .pi {
                            cursor: pointer;
                            padding: 6px;
                            background-color: #2196f3;
                            color: #fff;
                            border-radius: 50%;
                        }
                        &.needs-url {
                            color: #ff5722;
                            .pi {
                                background-color: #ff5722;
                            }
                        }
                    }
                }
            }
            .controls {
                .pi-times-circle {
                    cursor: pointer;
                    font-size: 1.5em;
                }
            }
        }
    }
`;

const ALLOWED_SIZES = new Set(["970x250", "970x90", "728x90", "320x50", "300x250", "300x600", "160x600"]);

const AD_UNIT_NAMES = {
    "970x250": "Billboard ad",
    "970x90": "Leaderboard ad",
    "728x90": "Leaderboard ad",
    "320x50": "Mobile leaderboard ad",
    "300x250": "Medium rectangle ad",
    "300x600": "Halfpage ad",
    "160x600": "Skyscraper ad",
};

const CREATIVE_TYPE = "display";

const OrderRowUpload = ({ 
    idx, 
    name, 
    label, 
    creatives, 
    currentCustomer, 
    onUploadComplete, 
    deleteHandler, 
    deleteMutation, 
    icon, 
    allowedSizes,
    updateCreativeUrlHandler,
    disabled = false 
}) => {
    const [creativeURL, setCreativeURL] = useState("");
    const [isEditingURL, setIsEditingURL] = useState(false);
    const [orderIndex, setOrderIndex] = useState(0);
    const [creativeIndex, setCreativeIndex] = useState(0);
    let totalCreatives = creatives?.length;
    const validSizes = typeof allowedSizes === 'object' && Object.getPrototypeOf(allowedSizes) === Set.prototype && allowedSizes.size
        ? allowedSizes
        : ALLOWED_SIZES

    const acceptUrlCb = () => {
        updateCreativeUrlHandler(orderIndex, creativeIndex, creativeURL.trim());
        setCreativeURL("");
        setOrderIndex(0);
        setCreativeIndex(0);
        setIsEditingURL(false);
    };

    const footer = (
        <div>
            <Button
                label="Accept"
                style={{
                    backgroundColor: "#4caf50",
                    border: "none",
                }}
                onClick={acceptUrlCb}
            />
            <Button
                label="Cancel"
                style={{
                    backgroundColor: "#3f3b39",
                    border: "none",
                }}
                onClick={() => {
                    setIsEditingURL(false);
                }}
            />
        </div>
    );

    return (
        <OrderRow idx={idx} name={name + ` (${totalCreatives}) `} label={label} icon={icon} lg={true}>
            <Dialog
                header="Click through URL"
                footer={footer}
                visible={isEditingURL}
                style={{ width: "50vw" }}
                modal
                onHide={() => {
                    setIsEditingURL(false);
                    setCreativeURL("");
                }}
            >
                <InputText
                    value={creativeURL}
                    onChange={(ev) => {
                        setCreativeURL(ev.target.value);
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter" || ev.code === "Enter") {
                            acceptUrlCb();
                        }
                    }}
                    css={css`
                        border: none;
                        border-bottom: 1px solid #ccc;
                        border-radius: 0;
                        max-width: 768px;
                        &:enabled:focus {
                            box-shadow: none;
                        }
                    `}
                />
            </Dialog>
            <div className="creatives" css={uploadRowStyle}>
                <div className="upload-controls">
                    <UploadAssetSection
                        currentCustomer={currentCustomer}
                        accept="image/*"
                        onUploadComplete={(response, assetSize, clickThroughUrl, filename, fileSize) => {
                            let creativeURL = response?.image;
                            onUploadComplete({ target: { value: creativeURL, name } }, idx, assetSize, CREATIVE_TYPE, clickThroughUrl, filename, fileSize);
                        }}
                        validSizes={validSizes}
                        validateSize={true}
                        isMarketPlace={true}
                        disabled={disabled}
                        label={creatives.length ? "Upload Another Creative" : "Upload Creative"}
                        render={(props) => {
                            return <MarketplaceUploadControls {...props} />;
                        }}
                    />
                </div>
                <div>
                    {creatives.map(({ type, size, creative, fileSize, clickThroughUrl }, creativeIndex) => {
                        let creativeName = creative?.split("/")?.pop();
                        let humanReadableFilesize = fileSize > 1000 ? (fileSize / 1000).toFixed(2) + " MB " : fileSize.toFixed(2) + " KB ";

                        return (
                            <div className="creative-preview" key={creativeIndex} css={creativePreviewStyles}>
                                {type === "display" ? (
                                    <div className="creative">
                                        <img className="thumb" src={creative} alt="Creative" />
                                        <div className="content">
                                            <div className="info">
                                                <p className="filename">{creativeName}</p>
                                                <div className="details">
                                                    <div>
                                                        <span className="item filesize">{humanReadableFilesize}</span>
                                                        <span> | </span>
                                                        <span className="item dimensions">
                                                            {size} ({AD_UNIT_NAMES[size]})
                                                        </span>
                                                    </div>
                                                    <div className={`creative-url ${clickThroughUrl ? "" : "needs-url"}`}>
                                                        {clickThroughUrl || "Click-through URL"} &nbsp;
                                                        <span
                                                            className="pi pi-pencil"
                                                            onClick={() => {
                                                                if (disabled) {
                                                                    return;
                                                                } else if (clickThroughUrl) {
                                                                    setCreativeURL(clickThroughUrl);
                                                                }
                                                                setOrderIndex(idx);
                                                                setCreativeIndex(creativeIndex);
                                                                setIsEditingURL(true);
                                                            }}
                                                        ></span>
                                                    </div>
                                                    <div className="third-party-tracker"></div>
                                                </div>
                                            </div>
                                            <div className="controls">
                                                <i
                                                    className="pi pi-times-circle"
                                                    onClick={() => {
                                                        if (disabled) return;
                                                        deleteMutation({
                                                            variables: {
                                                                id: `${currentCustomer}`,
                                                                filename: creativeName,
                                                                isMarketPlace: true,
                                                            },
                                                        });
                                                        deleteHandler({ target: { name, value: creative } }, idx, creativeIndex);
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </OrderRow>
    );
};

export default OrderRowUpload;
