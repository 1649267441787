/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { useDispatch, useSelector } from "react-redux";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import LayoutWrapper from "./LayoutWrapper";
import SectionHeader from "./helper/SectionHeader";
import deepcopy from "deepcopy";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { useNativeInjectionSave } from "../hooks/useNativeInjectionSave";

let controlsEntries = {
    native_ad_name: {
        label: "Name",
        choices: [],
        value: "",
    },
    target_wrapper: {
        label: "Target Wrapper",
        choices: [
            {
                code: "head",
                name: "Header",
            },
            {
                code: "after_body",
                name: "After opening <body> tag",
            },
            {
                code: "before_body",
                name: "Before closing </body> tag",
            },
        ],
        value: {
            code: "before_body",
            name: "Before closing </body> tag",
        },
    },
    exclutions_urls: {
        label: "Exclutions URLs",
        choices: [],
        value: "",
    },
    target_urls: {
        label: "Inclusions URLs",
        choices: [],
        value: "",
    },
    custom_parameter_or_code: {
        label: "Custom Code",
        choices: [],
        value: "",
    },
    enable_native_ad_injection: {
        label: "Enable Native Ad Injection",
        choices: [],
        value: false,
    },
    enable_on_preview: {
        label: "Enable On Preview",
        choices: [],
        value: false,
    },
};

const AdCustomInjection = () => {
    const { currentDomain: domainId, currentCustomer } = useSelector((state) => state.userInformation);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [settingsData, setSettingsData] = useState([]);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const dispatch = useDispatch();
    const { toolTipTextWithAttributes: tooltip, toolTipText: helpText } = useAdgridToolTipManager("tag_manager");

    const handleRemoveClick = (index) => {
        const list = [...settingsData];
        list.splice(index, 1);
        setSettingsData(list);
        dispatch(registerScreenChanges("Tag Manager"));
    };

    const handleAddClick = () => {
        dispatch(registerScreenChanges("Tag Manager"));
        setSettingsData([...settingsData, deepcopy(controlsEntries)]);
    };

    const handleChange = (i, name, value) => {
        let temp = [...settingsData];
        temp[i][name].value = value;
        setSettingsData(temp);
        dispatch(registerScreenChanges("Tag Manager"));
    };

    const handleChangeTemp = (e, index, value) => {
        e.stopPropagation();
        e.preventDefault();
        let temp = [...settingsData];
        temp[index]["enable_native_ad_injection"].value = !value;
        setSettingsData(temp);
        dispatch(registerScreenChanges("Tag Manager"));
    };

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
            let tagManagerCode = temp_data.native_custom_code;
            tagManagerCode = Array.isArray(tagManagerCode) && tagManagerCode.length ? tagManagerCode : [];
            setSettingsData(tagManagerCode);
            setMutationDataCompleted(true);
        }
    })

    const SAVE_NATIVE_AD_INJECTION_MUTATION_CONTAINER = useNativeInjectionSave({
        onCompleted() {
            dispatch(pushMessage("Tag manager", "Custom ad injection data updated"));
            removeFromBuiltDomains(`${domainId}`);
        }
    })

    useEffect(() => {
        if (currentCustomer && domainId) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId,
                customerId: currentCustomer
            });
        }
    }, [currentCustomer, domainId]);

    const saveAdNativeInjectionSettings = async () => {
        let validationFailed = false;

        const requiredFields = {
            native_ad_name: "Injection name required",
            target_urls: "Inclusion URLs must be specified",
            custom_parameter_or_code: "Custom code to be injected must be specified"
        }
        for (let fieldName of Object.keys(requiredFields)) {
            for (let item of settingsData) {
                validationFailed = !item[fieldName].value
                if (validationFailed) {
                    dispatch(pushMessage("Tag Manager", requiredFields[fieldName], 'error'))
                    throw new Error("Error registering Tag Manager information");
                }
            }
        }
        
        dispatch(pushMessage("Tag manager", "Saving changes..."));
        return SAVE_NATIVE_AD_INJECTION_MUTATION_CONTAINER({
            domainId,
            injectionType: "custom",
            injectionValueList: JSON.stringify(settingsData),
        });

    };

    const onClick = (index) => {
        setPositionFinal(index);
        setDisplayConfirmation(true);
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveAdNativeInjectionSettings}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content">
                        <div className="p-panel p-component">
                            <SectionHeader screenName="Tag manager">
                                <div className="p-text-bold">Tag manager</div>
                            </SectionHeader>
                            <div className="p-panel-content">
                                <Dialog
                                    header="Confirmation"
                                    visible={displayConfirmation}
                                    modal
                                    style={{ width: "350px" }}
                                    footer={
                                        <div>
                                            <Button label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                                            <Button
                                                label="Yes"
                                                icon="pi pi-check"
                                                onClick={() => {
                                                    setDisplayConfirmation(false);
                                                    handleRemoveClick(positionFinal);
                                                }}
                                                autoFocus
                                            />
                                        </div>
                                    }
                                    onHide={() => setDisplayConfirmation(false)}
                                >
                                    <div className="confirmation-content p-d-flex p-ai-center">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to delete this item?</span>
                                    </div>
                                </Dialog>
                                <Accordion>
                                    {settingsData.map((value, index) => {
                                        return (
                                            <AccordionTab
                                                key={index}
                                                header={
                                                    <React.Fragment>
                                                        <div className="p-d-flex p-jc-between">
                                                            <div className="p-field-checkbox p-mb-0 native-input-switch">
                                                                <InputSwitch id={"enable_native_ad_injection" + index} name={"enable_native_ad_injection"} checked={value.enable_native_ad_injection.value} onClick={(e) => handleChangeTemp(e, index, value.enable_native_ad_injection.value)} />
                                                                <div className="p-ml-2">{value.native_ad_name.value}</div>
                                                            </div>
                                                            <Button
                                                                type="button"
                                                                icon="pi pi-times"
                                                                className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                                tooltip="Remove row"
                                                                tooltipOptions={{ position: "top" }}
                                                                onClick={(ev) => {
                                                                    ev.stopPropagation()
                                                                    onClick(index);
                                                                }}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <div className="p-grid p-fluid">
                                                    <Tooltip target=".adpositiontooltip" />
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip("tag_manager_name", true)} htmlFor={"native_ad_name" + index}>
                                                            Name
                                                        </label>
                                                        <label style={{ color: "red" }}>*</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id={"native_ad_name" + index} name="native_ad_name" value={value.native_ad_name.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip("target_wrapper_tag_manager", true)} htmlFor={"target_wrapper" + index}>
                                                            {value.target_wrapper.label}
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <Dropdown
                                                            value={value.target_wrapper.value.code ? value.target_wrapper.value : { code: "head", name: "Header" }}
                                                            name="target_wrapper"
                                                            options={value.target_wrapper.choices}
                                                            onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                                                            optionLabel="name"
                                                            placeholder="Select Insert Type"
                                                        />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip("target_urls", true)} htmlFor={"target_urls" + index}>
                                                            {value.target_urls.label}
                                                        </label>
                                                        <label style={{ color: "red" }}>*</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"target_urls" + index} name="target_urls" value={value.target_urls.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor={"exclutions_urls" + index}>{value.exclutions_urls.label}</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea id={"exclutions_urls" + index} name="exclutions_urls" value={value.exclutions_urls.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                    </div>

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip("custom_code_tag_manager", true)} htmlFor={"custom_parameter_or_code" + index}>
                                                            {value.custom_parameter_or_code.label}
                                                        </label>
                                                        <label style={{ color: "red" }}>*</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <AceEditor mode="html" theme="monokai" value={value.custom_parameter_or_code.value} onChange={(e) => handleChange(index, "custom_parameter_or_code", e)} name="custom_parameter_or_code" editorProps={{ $blockScrolling: true }} />
                                                    </div>

                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-field-checkbox p-mt-4">
                                                        <InputSwitch id={"enable_on_preview" + index} name={"enable_on_preview"} checked={value.enable_on_preview.value} onChange={(e) => handleChange(index, e.target.name, e.target.value)} />
                                                        <label {...tooltip("enable_on_preview", true)} htmlFor={"enable_on_preview" + index}>
                                                            {value.enable_on_preview.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            </AccordionTab>
                                        );
                                    })}
                                </Accordion>
                                <div className="p-mt-3 p-d-flex p-jc-end">
                                    <Button type="button" label="Add Row" tooltip="Add row" icon="pi pi-plus" tooltipOptions={{ position: "top" }} onClick={handleAddClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader>
                                <div className="p-text-bold">Descriptions</div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Target Wrapper</h6>
                                <p>{helpText("help_text____target_wrapper")}</p>
                                <h6 className="p-text-bold p-mb-0 m-mt-0">Inclusions URLs</h6>
                                <p>{helpText("help_text____inclusions_urls")}</p>
                                <h6 className="p-text-bold p-mb-0">Custom Code</h6>
                                <p>{helpText("help_text____custom_code")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default AdCustomInjection;
