import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_ASSET_TYPES, CREATIVE_NAMES, VIDEO_ADHESION_AD_FIELDS } from "./definitions";
import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection";
import VideoAssetCreativeType from "./VideoAssetCreativeType";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.VIDEO_ADHESION;

const COLLAPSE_AD_OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export default function VideoAdhesion({
    collapsibleVideoUrl,
    collapsibleDesktopImageUrl,
    collapsibleTabletImageUrl,
    collapsibleMobileImageUrl,
    collapseDelay,
    autoCollapseAd,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    videoCreativeType,
    vastUrl,
    vastDuration,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <VideoAssetCreativeType
                    dropdownLabel="Video Creative Type"
                    types={CREATIVE_ASSET_TYPES}
                    videoCreativeTypePropertyName={VIDEO_ADHESION_AD_FIELDS.videoCreativeType}
                    videoCreativeType={videoCreativeType || 'video'}
                    handleChange={handleChange}
                    CREATIVE_TYPE={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    videoUrlInputLabel="Video URL"
                    videoUrlInputName={VIDEO_ADHESION_AD_FIELDS.collapsibleVideoUrl}
                    videoUrl={collapsibleVideoUrl}
                    vastInputName={VIDEO_ADHESION_AD_FIELDS.vastUrl}
                    vastUrl={vastUrl}
                    vastDurationInputName={VIDEO_ADHESION_AD_FIELDS.vastDuration}
                    vastDuration={vastDuration}
                />
                <AssetInputOrUploadSection
                    inputValue={collapsibleDesktopImageUrl}
                    inputLabel="Collapsible desktop image/url"
                    inputName={VIDEO_ADHESION_AD_FIELDS.collapsibleDesktopImageUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={collapsibleTabletImageUrl}
                    inputLabel="Collapsible tablet image/url"
                    inputName={VIDEO_ADHESION_AD_FIELDS.collapsibleTabletImageUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={collapsibleMobileImageUrl}
                    inputLabel="Collapsible mobile image/url"
                    inputName={VIDEO_ADHESION_AD_FIELDS.collapsibleMobileImageUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <DropdownSection
                    label="Autocollapse Ad"
                    name={VIDEO_ADHESION_AD_FIELDS.autoCollapseAd}
                    value={autoCollapseAd}
                    options={COLLAPSE_AD_OPTIONS}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                {autoCollapseAd &&
                    (
                        <InputTextSection label={"Collapse delay"} name={VIDEO_ADHESION_AD_FIELDS.collapseDelay} value={collapseDelay} changeHandler={handleChange} adtype={CREATIVE_TYPE} required={false} />
                    )
                }
            </BaseCreativeControls>
        </>
    );
}
