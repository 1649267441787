import OrderRow from "./OrderRow";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderRowDate = ({
    changeHandler,
    name,
    value,
    icon,
    inputName,
    disabled,
    idx
}) => {

    return (
        <OrderRow idx={idx} name={name} icon={icon}>
            <div
                className="p-inputgroup"
                css={css`
                    .react-datepicker-wrapper {
                        flex-grow: 1;
                        display: block;
                    }
                    .react-datepicker__input-container {
                        input {
                            width: 100%;
                            border: 1px solid #ccc;
                            &:active,
                            &:focus,
                            &:focus-within {
                                border: 1px solid #000;
                            }
                        }
                    }
                    .p-inputgroup-addon {
                        background-color: #4caf50;
                        color: #fff;
                    }
                `}
            >
                <span className="p-inputgroup-addon">
                    <i className={`pi ${disabled ? "pi-check" : icon}`}></i>
                    {disabled && <span>&nbsp;Already started</span>}
                </span>
                <DatePicker
                    selected={value}
                    disabled={disabled}
                    onChange={(d) => {
                        changeHandler({ target: { name: inputName, value: +d } }, idx);
                    }}
                    placeholderText="Select a start date for the campaign"
                />
            </div>
        </OrderRow>
    );
};

export default OrderRowDate;
