import { InputTextarea } from "primereact/inputtextarea";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushMessage } from "../reducers/messagingReducer";
import SectionHeader from "./helper/SectionHeader";
import LayoutWrapper from "./LayoutWrapper";
import { useAdsTxt } from "../hooks/useAdsTxt";

const AdsTxt = () => {
    const adsMutationContainer = useAdsTxt()
    const { currentDomain, currentCustomer } = useSelector((state) => state.userInformation);
    const [adsValue, setAdsValue] = useState("");
    const [sellersValue, setSellersValue] = useState("");
    const [domainSlug, setDomainSlug] = useState("slug-error");
    const dispatch = useDispatch();

    async function saveAdsTxtInformation() {
        var isValidJSON = sellersValue ? true : false
        try { JSON.parse(sellersValue) } catch (e) { isValidJSON = false }

        if (sellersValue && !isValidJSON) {
            dispatch(pushMessage("Ads.txt/Sellers.json", "Please enter valid Json for the sellers.json field", "error"));
            throw new Error("Please enter valid Json");
        }
        else return adsMutationContainer({
            domainId: currentDomain,
            operation: "write",
            data: adsValue,
            sellersData: sellersValue
        })
            .then((response) => {
                dispatch(pushMessage("Ads.txt", "Ads.txt/Sellers.json data updated"));
                return response;
            })
            .catch((err) => {
                dispatch(pushMessage("Ads.txt/Sellers.json", err.message, "error"));
            });
    }

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            adsMutationContainer({
                domainId: currentDomain,
                operation: "read",
            })
                .then((response) => {
                    let adsTxt = JSON.parse(response.data?.adsTxtMutation?.adsTxtInfo || null);
                    adsTxt = adsTxt?.adsTxtData || "";
                    setAdsValue(adsTxt);
                    let sellersJson = JSON.parse(response.data?.adsTxtMutation?.adsTxtInfo || null);
                    sellersJson = sellersJson?.sellersJsonData || "";
                    setSellersValue(sellersJson);
                    let postTitle = JSON.parse(response.data?.adsTxtMutation?.adsTxtInfo || null);
                    postTitle = postTitle?.postTitle || "";
                    setDomainSlug(postTitle.replace(".", "-"));
                    dispatch(pushMessage("Ads.txt/Sellers.json:", "Ads.txt & Sellers.json data has been loaded"));
                })
                .catch((err) => {
                    dispatch(pushMessage("Ads.txt/Sellers.json:", err.message, "error"));
                });
        }
    }, [currentDomain, currentCustomer]);

    return (
        <LayoutWrapper model={"domain"} saveCallback={saveAdsTxtInformation}>
            <div className="p-grid p-dir-col">
                <div className="small-full-width p-col p-col-content">
                    <div className="p-panel p-component">
                        <SectionHeader screenName="Ad injection">
                            <div className="p-text-bold">Ads.txt</div>
                        </SectionHeader>
                        <div className="p-panel-content">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="p-text-bold">Values</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputTextarea
                                        rows={10}
                                        cols={30}
                                        style={{ width: "100%" }}
                                        value={adsValue}
                                        onChange={(ev) => {
                                            setAdsValue(ev.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className="pre-wrapper">
                                <span className="p-text-bold">Ads.txt redirect URL:</span>
                                <br></br>
                                <br></br>
                                <span>https://console.adgrid.io/adgrid-build/{domainSlug}/ads.txt</span>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="small-full-width p-col p-col-content">
                    <div className="p-panel p-component">
                        <SectionHeader screenName="Ad injection">
                            <div className="p-text-bold">Sellers.json</div>
                        </SectionHeader>
                        <div className="p-panel-content">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label className="p-text-bold">Values</label>
                                </div>
                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputTextarea
                                        rows={10}
                                        cols={30}
                                        style={{ width: "100%" }}
                                        value={sellersValue}
                                        onChange={(ev) => {
                                            setSellersValue(ev.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className="pre-wrapper">
                                <span className="p-text-bold">Sellers.json redirect URL:</span>
                                <br></br>
                                <br></br>
                                <span>https://console.adgrid.io/adgrid-build/{domainSlug}/sellers.json</span>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </LayoutWrapper>
    );
};

export default AdsTxt;
