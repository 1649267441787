import { ApolloProvider, useMutation } from "@apollo/client";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import SlotPlacementID from "./components/adapters/SlotPlacementID";
import VideoAdSettings from "./components/VideoAdSettings";
import DomainVideoAdSettings from "./components/DomainVideoAdSettings";
import AdaptorSelection from "./components/AdaptorSelection";
import AdCmpConf from "./components/AdCmpConf";
import AdCustomInjection from "./components/AdCustomInjection";
import AdExclusion from "./components/AdExlusion";
import AdSettings from "./components/AdSettings";
import CustomerInfo from "./components/CustomerInfo";
import { Dashboard } from "./components/Dashboard";
import Documentation from "./components/Documentation";
import DocumentationContent from "./components/DocumentationContent";
import DomainRecycleBin from "./components/DomainRecycleBin";
import DomainRestoreSystem from "./components/DomainRestoreSystem";
import DomainsInfoEdit from "./components/DomainsInfoEdit";
import ForgotPassword from "./components/ForgotPassword";
import IdentityHub from "./components/IdentityHub";
import SupplyChain from "./components/SupplyChain";
import KeyValues from "./components/KeyValues";
import LazyLoad from "./components/LazyLoad";
import HeaderBiddingSettings from "./components/HeaderBiddingSettings";
import Login from "./components/Login";
import Partner from "./components/Partner";
import GeneralPresetConf from "./components/Preset/GeneralPresetConf";
import PresetAdapterSelection from "./components/Preset/PresetAdapterSelection";
import PresetAdExclusion from "./components/Preset/PresetAdExclusion";
import PresetAdPosition from "./components/Preset/PresetAdPosition";
import PresetIdentityHub from "./components/Preset/PresetIdentityHub";
import PresetLazyLoad from "./components/Preset/PresetLazyLoad";
import PresetOptimizeBidding from "./components/Preset/PresetOptimizeBidding";
import PresetSettings from "./components/Preset/PresetSettings";
import PresetSlotPlacementID from "./components/Preset/PresetSlotPlacementID";
import PresetDomainsInfo from "./components/PresetDomainsInfo";
import PerformancePreferences from "./components/PerformancePreferences";
import Reports from "./components/Reports";
import ResetPassword from "./components/ResetPassword";
import UserEdit from "./components/UserEdit";
import UserEditScreen from "./components/UserEditScreen";
import UserProfile from "./components/UserProfile";
import Users from "./components/Users";
import UsersInfo from "./components/UsersInfo";
import { client } from "./constants";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import PrivateRoute from "./PrivateRoute";
import { setCurrentCustomer, setNavigationMode, setUserID } from "./reducers/userLoginReducer";
import { first, noop, partition } from "./utilities/utils";
import DomainAdPosition from "./components/DomainAdPosition";
import VideoAdPositions from "./components/VideoAdPositions";
import PresetVideoAdPosition from "./components/Preset/PresetVideoAdPosition";
import DomainVideoPlacementID from "./components/DomainVideoPlacementID";
import PresetVideoPlacementID from "./components/Preset/PresetVideoPlacementID";
import CreativeOrders from "./components/CreativeOrders";
import CustomCreativesDemo from "./components/CustomCreativesDemo";
import Billing from "./components/Billing";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PresetVideoPlayerPositions from "./components/PresetVideoPlayerPositions";
import DomainVideoPlayerPositions from "./components/DomainVideoPlayerPositions";
import DisplayAdsInjection from "./components/DisplayAdsInjection";
import VideoPlayerInjections from "./components/VideoPlayerInjections";
import AdgridCustomCreatives from "./components/AdgridCustomCreatives";
import Marketplace from "./components/MarketPlace";
import { Messages } from "primereact/messages";
import Invoices from "./components/Invoices";
import AdsTxt from "./components/AdsTxt";
import CatapultX from "./components/CatapultX";
import MarketplacePaymentDataExport from "./components/MarketplacePaymentDataExport";
import PartnersList from "./components/common/marketplace/PartnersList";
import TrafficShaping from "./components/TrafficShaping";
import MultiBuildSystem from "./components/MultiBuildSystem";
import { CACHE_CLEAR_MUTATION, CF_PURGE_MUTATION, DOMAIN_BUILD_MUTATION, DOMAIN_STATUS_MUTATION } from "./components/common/multiBuild/constants";
import { clearBuildList, markDomainsAsFinished, removeDomain, setBuildingState, setCloudflareCacheClearState, setDomainToFinished, setDomainToInProgress, setLatestBuildId, setModifiedDomainsList, setPollingServerState } from "./reducers/multiBuildSystemReducer";
import { removeFromBuildList } from "./reducers/buildReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import { COMMON_CUSTOMER_DATA_MUTATION, COMMON_CUSTOMER_DATA_UPDATE_MUTATION } from "./components/common/creatives/definitions";
import { DomainsCloudflareCacheClearContext } from "./context/buildContext";
import CensusTargeting from "./components/CensusTargeting";

let STRIPE_PUBLISHABLE_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!STRIPE_PUBLISHABLE_KEY) {
    STRIPE_PUBLISHABLE_KEY =
        window.location.hostname === "console.adgrid.io"
            ? "pk_live_51KHYMKFWlWKpiY8o166BuSuak49Xvlupz9QbKfWkBRcbXisX0xehtUtXwpQk7UTKuyqJeQnYFmWrI4YWhURSpTQY00zUb3edrG"
            : "pk_test_51KHYMKFWlWKpiY8oG256iBwYezaSwsAEDBtqqBSwGHfd0wp1zxKA5nQA9O6rKUxH7hNf7owKLX2yAVWPJ9i6zAQX00FxCqW6ht";
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const readMessages = new Set();

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentCustomer, userID } = useSelector((state) => state.userInformation);
    const marketplaceInfo = useSelector((state) => state.marketplaceInfo);
    const globalMessages = useSelector((state) => state.globalMessages);
    const marketplaceActions = useSelector((state) => state.marketplaceActionsReducer);
    const [marketplaceNotifications, setMarketplaceNotifications] = useState([]);
    const [pausedOrders, setPausedOrders] = useState(0);
    const [creatingOrders, setCreatingOrders] = useState(0);
    const [updatingOrders, setUpdatingOrders] = useState(0);
    const [domainBuildMutation] = useMutation(DOMAIN_BUILD_MUTATION)
    const [cfPurgeMutation] = useMutation(CF_PURGE_MUTATION)
    const [buildStatusMutation] = useMutation(DOMAIN_STATUS_MUTATION)
    const [commonCustomerDataMutation] = useMutation(COMMON_CUSTOMER_DATA_MUTATION)
    const [commonCustomerDataUpdateMutation] = useMutation(COMMON_CUSTOMER_DATA_UPDATE_MUTATION)
    const buildState = useSelector(state => state.multiBuildSystemInfo)
    const [isNotificationPresent, setIsNotificationPresent] = useState(false)
    const [cacheClearMutation] = useMutation(CACHE_CLEAR_MUTATION)
    let messagesRef = useRef(null);

    async function requestModifiedDomains(currentCustomer) {
        let response = await commonCustomerDataMutation({ variables: { customerId: String(currentCustomer) } })
        let domains = JSON.parse(response.data.customerCommonInfoMutation.customerInfo)
        return domains?.modifiedDomains || []
    }

    async function buildDomain(domainId) {
        try {
            let domainBuildResponse = await domainBuildMutation({
                variables: {
                    id: String(domainId),
                    clientId: localStorage.getItem('userID'),
                    nonceKey: buildState[currentCustomer].buildNonceKey,
                    logMessage: buildState[currentCustomer].logMessage
                }
            })

            await cfPurgeMutation({ variables: { id: String(domainId) } })
            return domainBuildResponse.data
        } catch (err) {
            console.log(err)
        }
    }

    async function clearDomainsCloudflareCache(customerId) {
        try {
            const customerState = buildState[customerId]
            const selectedDomains = customerState?.buildList || []
            dispatch(setCloudflareCacheClearState({ customerId: currentCustomer, isClearingCache: true }))
            setIsNotificationPresent(true)
            setTimeout(() => {
                messagesRef.current.show(
                    {
                        closable: true,
                        severity: 'warn',
                        summary: 'Clearing build cache. Do not close your browser tab',
                        content: <div>
                            <FontAwesomeIcon icon={faWarning} />
                            &nbsp;
                            Clearing cache in progress. Closing your browser will interrupt the cache-clearing process
                        </div>,
                        sticky: true
                    }
                )
            }, 1000)
            const partitionedColl = partition(3, selectedDomains);
            for (let i = 0; i < partitionedColl.length; i++) {
                await Promise.all(partitionedColl[i].map(domainId => {
                    return cacheClearMutation({ variables: { domainId: String(domainId) } })
                }))
            }
            dispatch(clearBuildList({ customerId: currentCustomer }))
            dispatch(setCloudflareCacheClearState({ customerId: currentCustomer, isClearingCache: false }))
            setIsNotificationPresent(false)

        } catch (err) {
            dispatch(setCloudflareCacheClearState({ customerId: currentCustomer, isClearingCache: false }))
            setIsNotificationPresent(false)
            console.log({ clearCacheError: err })
        }
    }

    useEffect(() => {
        let isBuilding =
            Object
                .values(buildState)
                .some(customerBuildState => {
                    return customerBuildState.isBuilding
                })

        if (isBuilding) {
            setIsNotificationPresent(true)
            if (!isNotificationPresent) {
                messagesRef.current.show([
                    {
                        closable: false,
                        severity: 'success',
                        summary: 'Build in progress',
                        content: <div>
                            <FontAwesomeIcon icon={faCircleNotch} spin={true} speed={2} />
                            &nbsp;
                            Build in progress.
                            <span
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => {
                                    history.push("/domains", { modified: false })
                                }}
                            >
                                Visit build page.
                            </span>
                        </div>,
                        sticky: true
                    }, {
                        closable: true,
                        severity: 'warn',
                        summary: 'Build in progress',
                        content: <div>
                            <FontAwesomeIcon icon={faWarning} />
                            &nbsp;
                            Build in progress. Closing your browser will interrupt the build process
                        </div>,
                        sticky: true
                    }
                ])

            }
        } else {
            setIsNotificationPresent(false)
            messagesRef.current.clear()
        }
    }, [buildState])

    useEffect(async () => {
        if (currentCustomer) {
            let modifiedDomains = await requestModifiedDomains(currentCustomer)
            dispatch(
                setModifiedDomainsList({ customerId: currentCustomer, domainsIds: new Set(modifiedDomains) })
            )
        }
    }, [currentCustomer])

    useEffect(() => {
        if (currentCustomer) {
            if (buildState[currentCustomer]?.isBuilding) {
                (async () => {
                    if (buildState[currentCustomer]?.buildList.length) {
                        if (buildState[currentCustomer]?.pollingServer) {
                            return;
                        }

                        let [domainToBuild] = buildState[currentCustomer].buildList

                        dispatch(setDomainToInProgress({
                            customerId: currentCustomer,
                            domainId: domainToBuild
                        }))

                        dispatch(setPollingServerState({
                            customerId: currentCustomer,
                            isPollingServer: true
                        }))

                        let { lastid, wrapperVersion } = await buildDomain(domainToBuild);

                        dispatch(
                            setLatestBuildId({
                                customerId: currentCustomer,
                                buildId: lastid,
                                domainId: domainToBuild,
                                lastBuildWrapperVersion: wrapperVersion
                            })
                        );

                        (function helper() {
                            buildStatusMutation({
                                variables: {
                                    pid: String(domainToBuild),
                                    buildId: String(lastid)
                                }
                            })
                                .then(response => {
                                    let { data: { in_queue, messages } } = response
                                    if (in_queue) {
                                        setTimeout(helper, 5000)
                                    } else {
                                        dispatch(setPollingServerState({
                                            customerId: currentCustomer,
                                            isPollingServer: false
                                        }))
                                        dispatch(removeFromBuildList(String(domainToBuild)))
                                        dispatch(removeDomain({
                                            domainId: domainToBuild,
                                            customerId: currentCustomer
                                        }))
                                        dispatch(setDomainToFinished({
                                            customerId: currentCustomer,
                                            domainId: domainToBuild
                                        }))
                                        commonCustomerDataUpdateMutation({
                                            variables: {
                                                customerId: String(currentCustomer),
                                                domainId: String(domainToBuild),
                                                operation: 'REMOVE'
                                            }
                                        })
                                            .then(response => {
                                                let json = JSON.parse(response.data.updateCustomerCommonInfoMutation.response)
                                                let modifiedDomains = json.modifiedDomains

                                                dispatch(setModifiedDomainsList({
                                                    customerId: currentCustomer,
                                                    domainsIds: new Set(modifiedDomains || [])
                                                }))
                                            })
                                    }
                                })
                                .catch(() => {
                                    dispatch(removeDomain({ domainId: domainToBuild, customerId: currentCustomer }))
                                })
                        })()

                    } else {
                        dispatch(setBuildingState({ customerId: currentCustomer, isBuilding: false }))
                        dispatch(clearBuildList({ customerId: currentCustomer }))
                        dispatch(markDomainsAsFinished({ customerId: currentCustomer }))
                    }
                })()
            }
        }
    }, [buildState[currentCustomer]?.buildList, buildState[currentCustomer]?.isBuilding])

    useEffect(() => {
        (async () => {
            if (marketplaceActions?.length) {
                let operation = first(marketplaceActions);
                if (!operation.inProgress) {
                    await operation();
                }
            }
        })();
    }, [marketplaceActions]);

    useEffect(() => {
        const path = window.location.pathname.slice(1);
        const presetPaths = ["presetinfo", "presetsettings", "presetadpositions", "presetslotplacementid", "presetadexclusion", "presetlazyload", "presetoptimizebidding", "presetidentityhub"];

        presetPaths.some((p) => path.startsWith(p)) ? dispatch(setNavigationMode("preset")) : dispatch(setNavigationMode("domain"));

        userID ? noop() : dispatch(setUserID(localStorage.getItem("userID")));

        if (localStorage.getItem("role") === "client") {
            dispatch(setCurrentCustomer(localStorage.getItem("clientID")));
        }
    }, []);

    useEffect(() => {
        let vCreating = 0;
        let vPausing = 0;
        let vUpdating = 0;
        for (let { creating, pausing, updating } of Object.values(marketplaceInfo)) {
            vCreating += creating;
            vPausing += pausing;
            vUpdating += updating;
        }
        setPausedOrders(vPausing);
        setCreatingOrders(vCreating);
        setUpdatingOrders(vUpdating);
    }, [marketplaceInfo]);

    useEffect(() => {
        let marketplaceMessages = [];

        if (creatingOrders) {
            marketplaceMessages.push({ severity: "success", summary: "Marketplace orders queue: ", detail: `${creatingOrders} orders to be created`, life: 6000 });
        }

        if (updatingOrders) {
            marketplaceMessages.push({ severity: "success", summary: "Marketplace updates: ", detail: `${updatingOrders} orders left to update`, life: 6000 });
        }

        setMarketplaceNotifications(marketplaceMessages);
    }, [creatingOrders, pausedOrders, updatingOrders]);

    useEffect(() => {
        messagesRef.current.show(marketplaceNotifications);
    }, [marketplaceNotifications]);

    useEffect(() => {
        if (messagesRef && messagesRef.current) {
            if (globalMessages?.messages?.length) {
                const toDisplay = globalMessages.messages?.filter((message) => {
                    return !readMessages.has(message.id);
                });
                messagesRef.current.show(toDisplay);
                toDisplay?.forEach((message) => {
                    readMessages.add(message.id);
                });
            }
        }
    }, [globalMessages]);

    return (
        <div className="center w100">
            <div className="layout-wrapper layout-static p-ripple-disabled">
                <Elements stripe={stripePromise}>
                    <ApolloProvider client={client}>
                        <Switch>
                            <PrivateRoute path="/" exact component={Dashboard} />
                            <PrivateRoute path="/dashboard" exact component={Dashboard} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/forgotpassword" exact component={ForgotPassword} />
                            <Route path="/resetpassword" exact component={ResetPassword} />

                            <PrivateRoute path="/dashboard/:customerId" exact component={Dashboard} />
                            <PrivateRoute path="/partner" component={Partner} />
                            <PrivateRoute path="/customer" component={CustomerInfo} />

                            <PrivateRoute path="/users" component={Users} />
                            <PrivateRoute path="/usersinfo" component={UsersInfo} />
                            <PrivateRoute path="/userprofile" component={UserProfile} />

                            {/*<PrivateRoute path="/domains" component={MultiBuildSystem} />*/}
                            <PrivateRoute path="/domainsrecyclebin" component={DomainRecycleBin} />

                            {/* {!isAgency && <PrivateRoute path="/presetinfo" component={GeneralPresetConf} />} */}
                            <PrivateRoute path="/presetinfo" component={GeneralPresetConf} />

                            <PrivateRoute path="/documentation/:customerId" component={Documentation} />
                            <PrivateRoute path="/documentationcontent/:postId" component={DocumentationContent} />

                            <PrivateRoute path="/presetdomainsinfo" component={PresetDomainsInfo} />
                            <PrivateRoute path="/adexclusion" component={AdExclusion} />
                            <PrivateRoute path="/useredit" component={UserEdit} />
                            <PrivateRoute path="/usereditscreen" component={UserEditScreen} />
                            <PrivateRoute path="/domainsinfoedit/:domainId" component={DomainsInfoEdit} />
                            <PrivateRoute path="/reports" component={Reports} />
                            <PrivateRoute path="/adsettings" component={AdSettings} />
                            <PrivateRoute path="/adpositions" component={DomainAdPosition} />
                            <PrivateRoute path="/video-ad-settings" component={DomainVideoAdSettings} />
                            <PrivateRoute path="/preset-video-ad-settings" component={VideoAdSettings} />
                            <PrivateRoute path="/keyvalue" component={KeyValues} />
                            <PrivateRoute path="/nativeads" component={DisplayAdsInjection} />
                            <PrivateRoute path="/customads" component={AdCustomInjection} />
                            <PrivateRoute path="/adapter/:domainId" component={AdaptorSelection} />
                            <PrivateRoute path="/performance-preferences" component={PerformancePreferences} />
                            <PrivateRoute path="/lazyload" component={LazyLoad} />
                            <PrivateRoute path="/header-bidding-settings" component={HeaderBiddingSettings} />
                            <PrivateRoute path="/adcpminfo" component={AdCmpConf} />
                            <PrivateRoute path="/slotplacementid" component={SlotPlacementID} />
                            <PrivateRoute path="/domainrestoresystem" component={DomainRestoreSystem} />
                            <PrivateRoute path="/supplychain" component={SupplyChain} />
                            <PrivateRoute path="/identityhub" component={IdentityHub} />
                            <PrivateRoute path="/presetidentityhub" component={PresetIdentityHub} />

                            <PrivateRoute path="/presetslotplacementid" component={PresetSlotPlacementID} />
                            <PrivateRoute path="/presetsettings" component={PresetSettings} />
                            <PrivateRoute path="/presetadapter/:presetId" component={PresetAdapterSelection} />
                            <PrivateRoute path="/presetadpositions" component={PresetAdPosition} />
                            <PrivateRoute path="/presetadexclusion" component={PresetAdExclusion} />
                            <PrivateRoute path="/presetlazyload" component={PresetLazyLoad} />
                            <PrivateRoute path="/presetoptimizebidding" component={PresetOptimizeBidding} />
                            <PrivateRoute path="/domain-videoplacementids" component={DomainVideoPlacementID} />
                            <PrivateRoute path="/preset-videoplacementids" component={PresetVideoPlacementID} />

                            <PrivateRoute path="/video-adpositions" component={VideoAdPositions} />
                            <PrivateRoute path="/video-preset-adpositions" component={PresetVideoAdPosition} />

                            <PrivateRoute path="/creatives" component={AdgridCustomCreatives} />
                            <PrivateRoute path="/creative-orders" component={CreativeOrders} />

                            <PrivateRoute path="/custom-creatives" component={CustomCreativesDemo} />
                            <PrivateRoute path="/billing" component={Billing} />

                            <PrivateRoute path="/preset-video-player-positions" component={PresetVideoPlayerPositions} />
                            <PrivateRoute path="/video-player-positions" component={DomainVideoPlayerPositions} />
                            <PrivateRoute path="/video-player-injections" component={VideoPlayerInjections} />

                            <PrivateRoute path="/marketplace" component={Marketplace} />
                            <PrivateRoute path="/partners" component={PartnersList} />
                            <PrivateRoute path="/payment-data" component={MarketplacePaymentDataExport} />
                            <PrivateRoute path="/invoices" component={Invoices} />
                            <PrivateRoute path="/adstxt" component={AdsTxt} />
                            <PrivateRoute path="/extensions" component={CatapultX} />
                            <PrivateRoute path="/traffic-shaping" component={TrafficShaping} />
                            <PrivateRoute path="/census-settings" component={CensusTargeting} />
                            <DomainsCloudflareCacheClearContext.Provider value={clearDomainsCloudflareCache}>
                                <PrivateRoute path="/domains" component={MultiBuildSystem} />
                            </DomainsCloudflareCacheClearContext.Provider>

                        </Switch>
                    </ApolloProvider>
                </Elements>
            </div>
            <div
                css={css`
                    position: fixed;
                    bottom: calc(1em + 50px);
                    right: 1em;
                    width: 480px;
                    z-index: 9999;
                    .p-message-detail {
                        margin-left: 0.25em;
                        display: block;
                        font-weight: bold;
                    }
                `}
            >
                <Messages ref={messagesRef}></Messages>
            </div>
        </div>
    );
};

export default App;
