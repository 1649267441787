import React, { useState } from "react";
import { Base64 } from "js-base64";
import { gql, useMutation } from "@apollo/client";
import { getIn, noop } from "../../../utilities/utils";
import { useDispatch } from "react-redux";
import { pushMessage } from "../../../reducers/messagingReducer";

const UPLOAD_ASSET_MUTATION = gql`
    mutation callCreativeAssetUploadMutation($id: String, $data: String, $filename: String, $extension: String, $server: String, $isMarketPlace: Boolean) {
        creativeAssetUploadMutation(input: { id: $id, data: $data, filename: $filename, extension: $extension, server: $server, isMarketPlace: $isMarketPlace }) {
            mutationResult
        }
    }
`;

// File size limit in megabytes
const FILESIZE_LIMIT = 15;

function getFileExtension(file, defaultExtension) {
    let extension = defaultExtension;
    try {
        extension = file.type.split("/").pop();
        if (extension) return extension;
        else {
            return file.name.match(/\.[^\.]+$/).shift();
        }
    } catch (err) {
        return extension;
    }
}

const UploadAssetSection = ({ currentCustomer, accept, defaultExtension, onUploadComplete, setUploadState, render, validateSize = false, validSizes = [], isMarketPlace = false, disabled = false, ratio = null, label = "BROWSE" }) => {
    const dispatch = useDispatch();
    const [uploadAssetMutationContainer] = useMutation(UPLOAD_ASSET_MUTATION);
    const [isAssetSelected, setAssetSelectionStatus] = useState(false);
    const [filename, setFilename] = useState("");
    const [extension, setExtension] = useState("");
    const [base64Data, setBase64Data] = useState("");
    const [isInvalidSize, setSizeStatus] = useState(false);
    const [assetSize, setAssetSize] = useState("");
    const [assetDiskSize, setAssetDiskSize] = useState(0);

    function resetComponentState() {
        setAssetSelectionStatus(false);
        setFilename("");
        setExtension("");
        setBase64Data("");
        setSizeStatus(false);
        setAssetSize("");
        setAssetDiskSize(0);
    }

    function uploadAsset(v /* any value/object that you want to pass to this function */) {
        setUploadState && typeof setUploadState === "function" ? setUploadState(true) : noop();

        return uploadAssetMutationContainer({
            variables: {
                id: `${currentCustomer}`,
                data: base64Data,
                filename,
                extension,
                server: process.env.REACT_APP_SERVER || window.location.hostname,
                isMarketPlace,
            },
        })
            .then((response) => {
                let mutationData = getIn(response, "data", "creativeAssetUploadMutation", "mutationResult");
                mutationData = mutationData ? JSON.parse(mutationData) : {};

                onUploadComplete && typeof onUploadComplete === "function" ? onUploadComplete(mutationData, assetSize, v, filename, assetDiskSize) : noop();
                dispatch(pushMessage("Success", "Asset has been saved!"));
                setUploadState && typeof setUploadState === "function" ? setUploadState(false) : noop();
                resetComponentState();
            })
            .catch((err) => {
                setUploadState && typeof setUploadState === "function" ? setUploadState(false) : noop();
                dispatch(pushMessage("Error", "There was an error uploading this asset: " + err.message, "error"));
            });
    }

    function setAssetData({ base64Data, filename, extension, selectionStatus, diskSize }) {
        setBase64Data(base64Data);
        setFilename(filename);
        setExtension(extension);
        setAssetSelectionStatus(selectionStatus);
        setAssetDiskSize(diskSize || 0);
    }

    function validateSizeCallback({ htmlImageElement, fileBufferView, fileBase64Encoding, filename, fileExtension }) {
        let { width, height } = htmlImageElement;
        let size = `${width}x${height}`;
        if (!validSizes.has(size)) {
            dispatch(pushMessage("Error", "The size of the creative you are trying to upload is not allowed", "error"));
            setSizeStatus(true);
            return;
        } else {
            dispatch(pushMessage("Success", "Asset has been loaded. Click on upload to save the asset"));
            setAssetDiskSize(fileBufferView.byteLength / 1000);
            setSizeStatus(false);
            setAssetSize(size);
        }
        setAssetData({ base64Data: fileBase64Encoding, filename, extension: fileExtension, selectionStatus: true });
    }

    function loadAssetHandler(ev) {
        let files = ev.target.files;

        if (files.length) {
            let file = files[0];

            let extension = getFileExtension(file, defaultExtension);
            let filename = file.name.includes(".") ? file.name.slice(0, file.name.lastIndexOf(".")) : file.name;

            let fr = new FileReader();
            fr.readAsArrayBuffer(file);
            fr.addEventListener("load", () => {
                let view = new Uint8Array(fr.result);
                let encoded = Base64.fromUint8Array(view);
                let fileSize = view.length / 1_000_000; /* size in MB */
                let { w, h } = ratio || {};

                if (fileSize >= FILESIZE_LIMIT) {
                    dispatch(pushMessage("Error", `The size of the creative you are trying to upload (${fileSize.toFixed(2)}MB) exceeds the allowed limit (10MB)`, "error"));
                } else if (validateSize) {
                    let img = new Image();
                    img.src = `data:image/${extension};base64, ${encoded}`;
                    img.onload = () => {
                        validateSizeCallback({
                            htmlImageElement: img,
                            fileBufferView: view,
                            fileBase64Encoding: encoded,
                            filename,
                            fileExtension: extension,
                        });
                    };
                } else if (w && h) {
                    let img = new Image();
                    img.src = `data:image/${extension};base64, ${encoded}`;
                    img.onload = () => {
                        setAssetData({ base64Data: encoded, filename, extension, selectionStatus: true });
                        dispatch(pushMessage("Success", "Asset has been loaded. Click on upload to save the asset"));
                    };
                } else {
                    setAssetData({ base64Data: encoded, filename, extension, selectionStatus: true, diskSize: view.byteLength });
                    dispatch(pushMessage("Success", "Asset has been loaded. Click on upload to save the asset"));
                }
            });
        }
    }

    return render({
        accept,
        isAssetSelected,
        filename,
        extension,
        base64Data,
        isInvalidSize,
        assetSize,
        assetDiskSize,
        uploadAsset,
        loadAssetHandler,
        disabled,
        label,
        validSizes,
    });
};

export default UploadAssetSection;
