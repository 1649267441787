import React from 'react'
import { AutoComplete } from 'primereact/autocomplete'

const AdsizeAutocomplete = ({
  bgColorClass,
  name,
  filteredAdSizes,
  searchAdSizes,
  value,
  showCheckBox,
  handleSizeChange,
  idx
}) => {
  return (
    <div className="param-input adpos">
      <AutoComplete
          multiple
          suggestions={filteredAdSizes}
          completeMethod={searchAdSizes}
          name={name}
          value={value}
          disabled={!showCheckBox}
          onChange={e => handleSizeChange(e, idx)}
        />
    </div>
  )
  /* return (
    <div className={`p-col-4 ${bgColorClass}`}>
      <div className="p-col-12 adsize-autocomplete-container">
        <AutoComplete
          multiple
          suggestions={filteredAdSizes}
          completeMethod={searchAdSizes}
          name={name}
          value={value}
          disabled={!showCheckBox}
          onChange={e => handleSizeChange(e, idx)}
        />
      </div>
    </div>
  ) */
}

export default AdsizeAutocomplete
