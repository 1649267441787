import React, { Component, useState, useEffect, useRef } from 'react';
import { AUTH_TOKEN, USER_NAME, AVATAR_LINK } from '../constants'
import { Mutation } from 'react-apollo'
import { gql, useMutation } from '@apollo/client';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Client from './Client';
import Partner from './Partner';
import AppLogo from './AppLogo';

const USER_PASSWORD_RESET_MUTATION = gql`
  mutation callUserPasswordResetMutation($email: String) {
    userPasswordResetMutation(input: {email: $email}) {
	    success
	    message
    }
  }
`

const ForgotPassword = () => {
	const messages = useRef(null)
	const [userEmail, setUserEmail] = useState("");
	const handleChange = (param) => {
	  setUserEmail(param.target.value)
    };

    const submitEmail = () => {
    	if(validateEmail(userEmail)){
    		USER_PASSWORD_RESET_MUTATION_CONTAINER({
        		variables: { email: userEmail }
      		});
    	}else{
    		addMessages('Please enter a valid email', 'warn', '')
    	}
    };
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const addMessages = (message, sev = "success", sum = "Success.") => {
	    messages.current.show([
	      {
	        severity: sev,
	        summary: sum,
	        detail: message,
	        sticky: true
	      }
	    ]);
  	};
	const [USER_PASSWORD_RESET_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
		USER_PASSWORD_RESET_MUTATION,
		{
		  onCompleted(dataUpdate) {
		  	let severity = (dataUpdate.userPasswordResetMutation.success=="true") ? 'success' : 'error'
			addMessages(dataUpdate.userPasswordResetMutation.message, severity, '');
		  }
		}
	)
    return (

      <div
        className="p-d-flex p-jc-center p-ai-center p-flex-column"
        style={{backgroundColor: "#007cba", position: "absolute", left: "0", right: "0", top: "0", bottom: "0"}}
      >
        <div className="p-mb-6">
          <a href="http://adgrid.io/">
            <AppLogo />
          </a>
        </div>
        <Messages ref={messages} />
        <div className="p-shadow-10" style={{
          maxWidth: "320px",
          backgroundColor: "white",
          padding: "30px 25px 23px",
          width: "100%"}}
        >
          <div className="p-d-flex p-flex-column p-mb-2">
          	  <h6>Enter you email to reset your password</h6>
	          <div className="p-grid p-fluid">
		        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
		          <InputText id={'email_for_pass_reset'} name="email_for_pass_reset" value={userEmail} placeHolder={"Enter your email"} onChange={(e) => handleChange(e)}/>
		        </div>
		        <div className="p-mt-3 p-d-flex p-jc-end">
                  <Button type="button" label="Request Password Reset Link" tooltip="Reset Password" icon="pi pi-fw pi-cog" tooltipOptions={{ position: 'top' }} onClick={submitEmail} />
                </div>
		      </div>
          </div>
        </div>
      </div>
    )
}

export default ForgotPassword
