import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import ColorPicker from "./ColorPicker";
import { CREATIVE_NAMES, DESKTOP_FOOTER_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.DESKTOP_FOOTER;

export default function DesktopFooter({
    creativeBg,
    leftImage,
    centerImage,
    ctaBgColor,
    ctaText,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {

    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={leftImage}
                    inputLabel="Left side logo"
                    inputName={DESKTOP_FOOTER_AD_FIELDS.leftImage}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={centerImage}
                    inputLabel="Middle banner"
                    inputName={DESKTOP_FOOTER_AD_FIELDS.centerImage}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />

                <ColorPicker
                    label="Creative section background color (optional)"
                    name={DESKTOP_FOOTER_AD_FIELDS.creativeBg}
                    value={creativeBg}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <InputTextSection
                    label="CTA text (optional)"
                    name={DESKTOP_FOOTER_AD_FIELDS.ctaText}
                    value={ctaText}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
                <ColorPicker
                    label="CTA background color (optional)"
                    name={DESKTOP_FOOTER_AD_FIELDS.ctaBgColor}
                    value={ctaBgColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
            </BaseCreativeControls>
        </>
    );
}
