/**
 * Returns the value in a nested associative structure where keys
 * are the keys to go looking for values in obj. Returns undefined
 * when no values are present. If called without keys, the original
 * object is returned
 *
 * Example:
 *
 * const obj = {
 *  name: 'Joe',
 *  info: {
 *    hobbies: ['swimming']
 *  }
 * }
 *
 * getIn(obj, 'info', 'hobbies', 0) // 'swimming'
 *
 * @param {object} obj
 * @param  {...string|number} keys
 * @returns
 */

function getIn(obj, ...keys) {
    try {
        return keys.reduce((result, currentKey) => result[currentKey], obj);
    } catch (err) {
        return undefined;
    }
}

/**
 * No-op
 */

function noop() {}

/**
 * Returns the first element of a collection or array-like object, or undefined
 *
 * @param {*[]} coll
 * @return {*}
 */

function first(coll) {
    return coll[0];
}

/**
 * Returns the value passed to it without changing it
 *
 * @param {v} v
 * @returns {v} v
 */

function identity(v) {
    return v;
}

/**
 * Displays a notification
 *
 * @param {string} title
 * @param {string} message
 * @param {string} type
 * @param {number} duration
 */

function isEmpty(i) {
    const t = typeof i;
    switch (t) {
        case "string":
            return i.length === 0;
        case "number":
            return Number.isNaN(i);
        case "object":
            return Array.isArray(i) ? i.length === 0 : t === null || Object.keys(i).length === 0;
        case "undefined":
            return true;
        default:
            return false;
    }
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function partition(size, coll, paddingColl) {
    const result = [];
    for (let i = 0; i < coll.length; i += size) {
        const partitionedChunk = coll.slice(i, i + size);
        if (partitionedChunk.length < size && paddingColl) {
            while (partitionedChunk.length < size) {
                partitionedChunk.push(paddingColl.shift());
            }
        }
        result.push(partitionedChunk);
    }
    return result;
}

export { getIn, noop, first, identity, isEmpty, decodeHtml, partition };
