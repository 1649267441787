import { gql, useMutation } from '@apollo/client';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIn } from '../utilities/utils';
import AdPosition from './AdPosition';
import LayoutWrapper from './LayoutWrapper';
import saveAdPositionList, { handleInputChange, handleMultipleInput } from './utils/functions';
import { useDomainSettings } from '../hooks/useDomainSettings';
import { AD_POSITION_UPDATE_MUTATION } from '../mutations/mutations';

const MODE = 'domain'
const ADTYPE = 'display'

const DomainAdPosition = () => {
    const { currentDomain, currentCustomer } = useSelector(state => state.userInformation)
    const [queryInfoFinished, setQueryInfoFinished] = useState({ done: false })
    const [positioningData, setPositioningData] = useState({})
    
    const DOMAIN_SETTINGS_MUTATION = useDomainSettings()
    const [saveAdPositionMutation] = useMutation(AD_POSITION_UPDATE_MUTATION)
    const [currentPreset, setCurrentPreset] = useState('')
    const [defaultPresetPositions, setDefaultPresetPositions] = useState(new Set())
    const [presetName, setPresetName] = useState('')

    const saveAdPositions = (id, presetid, updatePositioningData, positioningData) => (inputList, adPositionsAlertList, overridePositions, changedListArray, overriden) => {
        const mutationVariables = {
            id,
            presetid,
            overridePositionList: `${overridePositions}`,
            slotPlacementIDList: JSON.stringify(changedListArray),
            isVideo: false,
        }
        
        return saveAdPositionList({
            inputList,
            adPositionsAlertList,
            saveMutation: saveAdPositionMutation,
            mutationVariables,
            mode: MODE,
            setPositioningData: updatePositioningData,
            overriden,
            domainPositioningData: positioningData,
            adtype: ADTYPE
        })
    }

    const queryDomainInfo = async () => {
        const response = await DOMAIN_SETTINGS_MUTATION({
            domainId: currentDomain,
            customerId: currentCustomer
        })
        return JSON.parse(getIn(response, 'data', 'domainEditMutation', 'customerInfo'))
    }

    useEffect(() => {
        (async () => {

            if (currentDomain && currentCustomer) {
                try {
                    const positionData = await queryDomainInfo()
                    setDefaultPresetPositions(new Set(positionData.ad_positions_for_preset, []))
                    setQueryInfoFinished({ done: false })
                    setCurrentPreset(positionData.adv_preset.code)
                    setPresetName(positionData.adv_preset.name)
                    setPositioningData(positionData)
                    setQueryInfoFinished({ done: true })           
                    
                } catch (err) {
                    console.log('err: ', err.message)
                }
            }

        })()
    }, [currentDomain, currentCustomer])

    return (
        <LayoutWrapper model={MODE} renderOpsBar={false}>
            {
                queryInfoFinished.done &&
                <AdPosition
                    model={MODE}
                    overridable={true}
                    positioningData={positioningData}
                    savePositionsFunc={saveAdPositions(`${currentDomain}`, currentPreset, setPositioningData, positioningData)}
                    presetName={presetName}
                    handleInputChange={handleInputChange}
                    handleMultipleInput={handleMultipleInput}
                    currentPreset={currentPreset}
                    queryDomainInfo={queryDomainInfo}
                    defaultPresetPositions={defaultPresetPositions}
                    headingLabel='Override Ad Positions Preset'
                    adtype={ADTYPE}
                />
            }
        </LayoutWrapper>
    )
}

export default DomainAdPosition
