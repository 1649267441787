import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavLink, useHistory} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { clearScreenChanges } from "./reducers/screenChangesReducer";
import { setActiveMenuIndex } from "./reducers/userLoginReducer";
import { noop } from "./utilities/utils";
import DocumentationListMenu from "./components/DocumentationListMenu";

const AppSubmenu = ({ items, activeIndex, setActiveIndex, screenState }) => {
    const [navMenu, setNavMenu] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const renderLinkContent = (item, isCollapsed) => {
        let submenuIcon = item.items && <i className={`pi pi-angle-${isCollapsed ? "down" : "right"} menuitem-toggle-icon`}></i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        return (
            <React.Fragment>
                <span>{item.label}</span>
                {item.total && <span className="item-count">{item.total}</span>}
                {submenuIcon}
                {badge}
            </React.Fragment>
        );
    };

    const renderLink = (item, i, isSublink, isCollapsed) => {
        let content = renderLinkContent(item, isCollapsed);
        if (item.label === "Video Ad Settings" && window.location.hostname.startsWith("console.adgrid")) {
            return null;
        }
        if (item.to) {
            return (
                <a
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (screenState.isModified) {
                            let response = window.confirm("You haven't saved your changes. Do you want to leave without saving?");
                            if (response) {
                                dispatch(clearScreenChanges());
                                history.push(item.to);
                                return;
                            }
                        } else {
                            history.push(item.to);
                        }
                        // isSublink ? noop() : setActiveIndex(0);
                    }}
                    // target={item.target}
                    key={item.label}
                >
                    {content}
                </a>
            );
        } else {
            return (
                <a
                    href={item.url}
                    onClick={() => {
                        if (!isSublink) {
                            setActiveIndex(i);
                        }
                    }}
                    target={item.target}
                    key={item.label}
                >
                    {content}
                </a>
            );
        }
    };

    useEffect(() => {
        if (items) {
            const nav = items.map((item, idx) => {

                let styleClass = classNames(item.badgeStyleClass, { "active-menuitem": window.location.toString().includes(item.to) });
                let isCollapsed = idx === activeIndex;

                if (item.items && !isCollapsed) {
                    isCollapsed = item.items.some((i) => i.to === window.location.pathname);
                }

                return (
                    <li className={styleClass} key={idx}>
                        {item.items && <div className="arrow"></div>}
                        {renderLink(item, idx, false, isCollapsed)}
                        <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 250, exit: 250 }} in={isCollapsed} unmountOnExit>
                            <ul className="sub-nav">{item.items && item.items.map((i, index) => renderLink(i, idx, true))}</ul>
                        </CSSTransition>
                    </li>
                );
            });
            setNavMenu(<ul className="layout-menu">{nav}</ul>);
        }
    }, [items, activeIndex, screenState]);

    return navMenu;
};

const MARKETPLACE_MENU_LINKS = [
    { label: "Marketplace Orders", icon: "pi pi-key", to: "/marketplace" },
    { label: "Marketplace Partners", icon: "pi pi-key", to: "/partners" },
];

export const AppMenu = (props) => {
    const { activeMenuIndex, isAgency, isAdmin } = useSelector((state) => state.userInformation);
    const dispatch = useDispatch();
    const screenState = useSelector((state) => state.screenStateWatcher);
    const [presetMenu, setPresetMenu] = useState([
        { label: "Preset Settings", icon: "pi pi-id-card", to: "/presetsettings" },
        { label: "Header Bidding Settings", icon: "pi pi-spinner", to: "/presetoptimizebidding" },
        {
            label: "SSP Adapters",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display", icon: "pi pi-mobile", to: "/presetslotplacementid" },
                { label: "Video", icon: "pi pi-mobile", to: "/preset-videoplacementids" },
            ],
        },
        {
            label: "Ad Positions",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display Ads", icon: "pi pi-check-square", to: "/presetadpositions" },
                { label: "Video Ads", icon: "pi pi-check-square", to: "/video-preset-adpositions" },
                { label: "Video Player", icon: "pi pi-check-square", to: "/preset-video-player-positions" },
            ],
        },
        { label: "Lazyload & Refresh", icon: "pi pi-spinner", to: "/presetlazyload" },
        { label: "Prebid Modules", icon: "pi pi-tags", to: "/presetidentityhub" },
    ]);

    const [menu, setMenu] = useState(null);

    const [domainEditMenu, setDomainEditMenu] = useState([
        { label: "General Settings", icon: "pi pi-id-card", to: "/adsettings" },
        { label: "Header Bidding Settings", icon: "pi pi-spinner", to: "/header-bidding-settings" },
        { label: "Ads txt & Sellers.json", icon: "pi pi-check-square", to: "/adstxt" },
        {
            label: "SSP Adapters",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display", icon: "pi pi-arrow-circle-down", to: "/slotplacementid" },
                { label: "Video", icon: "pi pi-mobile", to: "/domain-videoplacementids" },
            ],
        },
        {
            label: "Ad Positions",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display Ads", icon: "pi pi-check-square", to: "/adpositions" },
                { label: "Video Ads", icon: "pi pi-check-square", to: "/video-adpositions" },
                { label: "Video Player", icon: "pi pi-check-square", to: "/video-player-positions" },
            ],
        },
        { label: "Video Ad Settings", icon: "pi pi-check-square", to: "/video-ad-settings" },
        { label: "Key-Value Pairs", icon: "pi pi-key", to: "/keyvalue" },
        { label: "Performance Preferences", icon: "pi pi-key", to: "/performance-preferences" },
        { label: "Lazy Load", icon: "pi pi-spinner", to: "/lazyload" },
        { label: "Consent Management (CMP)", icon: "pi pi-mobile", to: "/adcpminfo" },
        {
            label: "Injections",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display Ads", icon: "pi pi-sort-amount-down", to: "/nativeads" },
                { label: "Video Player", icon: "pi pi-sort-amount-down", to: "/video-player-injections" },
            ],
        },
        { label: "Tag Manager", icon: "pi pi-tags", to: "/customads" },
        { label: "Identity Hub", icon: "pi pi-tags", to: "/identityhub" },
        { label: "Extensions", icon: "pi pi-tags", to: "/extensions" },
        { label: "Reports", icon: "pi pi-chart-bar", to: "/reports" },
    ]);

    const [partnerDomainEditMenu, setPartnerDomainEditMenu] = useState([
        { label: "General Settings", icon: "pi pi-id-card", to: "/adsettings" },
        { label: "Header Bidding Settings", icon: "pi pi-spinner", to: "/header-bidding-settings" },
        { label: "Ads txt & Sellers.json", icon: "pi pi-check-square", to: "/adstxt" },
        {
            label: "SSP Adapters",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display", icon: "pi pi-arrow-circle-down", to: "/slotplacementid" },
                { label: "Video", icon: "pi pi-mobile", to: "/domain-videoplacementids" },
            ],
        },
        {
            label: "Ad Positions",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display Ads", icon: "pi pi-check-square", to: "/adpositions" },
                { label: "Video Ads", icon: "pi pi-check-square", to: "/video-adpositions" },
                { label: "Video Player", icon: "pi pi-check-square", to: "/video-player-positions" },
            ],
        },
        { label: "Video Ad Settings", icon: "pi pi-check-square", to: "/video-ad-settings" },
        { label: "Key-Value Pairs", icon: "pi pi-key", to: "/keyvalue" },
        { label: "Performance Preferences", icon: "pi pi-key", to: "/performance-preferences" },
        { label: "Lazyload & Refresh", icon: "pi pi-spinner", to: "/lazyload" },
        { label: "Consent Management (CMP)", icon: "pi pi-mobile", to: "/adcpminfo" },
        {
            label: "Injections",
            icon: "pi pi-arrow-circle-down",
            items: [
                { label: "Display Ads", icon: "pi pi-sort-amount-down", to: "/nativeads" },
                { label: "Video Player", icon: "pi pi-sort-amount-down", to: "/video-player-injections" },
            ],
        },
        { label: "Tag Manager", icon: "pi pi-tags", to: "/customads" },
        { label: "Prebid Modules", icon: "pi pi-tags", to: "/identityhub" },
        { label: "Extensions", icon: "pi pi-tags", to: "/extensions" },
        { label: "Reports", icon: "pi pi-chart-bar", to: "/reports" },
    ]);

    const [creativesMenu] = useState([
        { label: "Creative Orders", icon: "pi pi-check-square", to: "/creative-orders" },
        { label: "Creative Types Demo", icon: "pi pi-key", to: "/custom-creatives" },
    ]);

    const [marketplaceMenu, setMarketplaceMenu] = useState(MARKETPLACE_MENU_LINKS);

    const [invoicesMenu] = useState([{ label: "Invoices", icon: "pi pi-key", to: "/invoices" }]);

    useEffect(() => {
        if (isAdmin) {
            setMarketplaceMenu([...marketplaceMenu, { label: "Marketplace Payment Data", icon: "pi pi-key", to: "/payment-data" }]);
        }
    }, [isAdmin]);

    useEffect(() => {
        //console.log({model: props.model})
        if (isAgency) {
            switch (props.model) {
                case "marketplace":
                    setMenu(marketplaceMenu);
                    break;
                case "creative":
                    setMenu(creativesMenu);
                    break;
                default:
                    noop();
            }
        } else {
            switch (props.model) {
                case "domain":
                    localStorage.getItem("role") === "client" ? setMenu(domainEditMenu) : setMenu(partnerDomainEditMenu);
                    break;
                case "preset":
                    setMenu(presetMenu);
                    break;
                case "creative":
                    setMenu(creativesMenu);
                    break;
                case "marketplace":
                    setMenu(marketplaceMenu);
                    break;
                case "invoices":
                    setMenu(invoicesMenu);
                    break;
                default:
                    setMenu([]);
            }
        }
    }, [marketplaceMenu]);

    return (
        <div className="layout-menu-container main-menu">
            <AppSubmenu
                items={menu}
                className="layout-menu"
                activeIndex={activeMenuIndex}
                setActiveIndex={(idx) => {
                    dispatch(setActiveMenuIndex(activeMenuIndex === idx ? 0 : idx));
                    // setActiveIndex(idx)
                }}
                screenState={screenState}
            />

            {
                (()=>{
                    let urlIncludes = (urlPart) => window.location.href.includes(urlPart);
                    let documentationLinks = props.model === "domain" || urlIncludes('customer') || urlIncludes('domains');

                    if (documentationLinks) {
                        return (
                            <ul className="layout-menu">
                                <li>
                                    <NavLink to={`/documentationcontent/9032`}>
                                        <span>
                                            Documentation
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        );
                    }

                    if (urlIncludes('documentationcontent')) {
                        return <DocumentationListMenu/>
                    }

                })()
            }

        </div>
    );
};
