import React, { useEffect, useState } from 'react'
import VideoPlayerPositions from './VideoPlayerPositions'
import { gql, useMutation } from '@apollo/client';
import { getIn } from '../utilities/utils';
import { useSelector } from 'react-redux';
import { pushMessage } from '../reducers/messagingReducer';
import { useDispatch } from 'react-redux'

const PRESET_INFO_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $isVideo: Boolean) {
        presetAllInfoMutation(input: {id: $id, isVideo: $isVideo}) {
            clientMutationId
            presetInfo
        }
    }
`

const VIDEO_POSITIONS_UPDATE_MUTATION = gql`
    mutation callUpdateVideoPlayerPositionsMutation($id: String, $positionList: String, $isPreset: Boolean) {
        updateVideoPlayerPositionsMutation(input: {id: $id, positionList: $positionList, isPreset: $isPreset}) {
            response
        }
    }
`

const PresetVideoPlayerPositions = () => {
    const dispatch = useDispatch()
    const [presetVideoPlayerPositions, setPresetVideoPlayerPositions] = useState([])
    const [presetInfoMutationContainer] = useMutation(PRESET_INFO_MUTATION)
    const [videoPositionUpdateMutationContainer] = useMutation(VIDEO_POSITIONS_UPDATE_MUTATION)
    const { currentPreset, currentCustomer } = useSelector(state => state.userInformation)
    const [hasMutationCompleted, setMutationStatus] = useState(false)
    const [machineNames, setUsedMachineNames] = useState(new Set())

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            presetInfoMutationContainer({
                variables: {
                    id: `${currentPreset}`
                }
            }).then(response => {
                let presetInfo = JSON.parse(getIn(response, 'data', 'presetAllInfoMutation', 'presetInfo'))
                let presetPositions = presetInfo.video_player_positions || []
                setPresetVideoPlayerPositions(presetPositions)
                setUsedMachineNames(new Set(Object.values(presetPositions).map(i => i.displayName)))
                setMutationStatus(true)
            }).catch(err => {
                dispatch(pushMessage('Preset Video Positions', "Error querying preset positions: " + err.message, 'error'))
                setPresetVideoPlayerPositions({})
                setMutationStatus(true)
            })
        }
    }, [currentPreset, currentCustomer])


    return (
        <VideoPlayerPositions 
            mode="preset"
            videoPlayerPositions={presetVideoPlayerPositions}
            setPresetVideoPlayerPositions={setPresetVideoPlayerPositions}
            usedMachineNames={machineNames}
            mutationCompleted={hasMutationCompleted}
            saveHandler={() => {
                return videoPositionUpdateMutationContainer({
                    variables: {
                        id: `${currentPreset}`,
                        positionList: JSON.stringify(presetVideoPlayerPositions),
                        isPreset: true
                    }
                }).then(response => {
                    return response
                }).catch(err => {
                    console.log('err: ', err.message)
                })
            }}
        />
    )
}

export default PresetVideoPlayerPositions