import React from "react";
import { InputSwitch } from "primereact/inputswitch";

const SectionSwitch = ({ label, name, showCheckBox, checked, handleChange, tooltip, idx }) => {
    return (
        <div className="switch-container">
            <InputSwitch name={name} checked={checked} disabled={!showCheckBox} onChange={(e) => handleChange(e, idx, name)} />
            {
                tooltip ?
                    <span data-pr-tooltip={tooltip} className="adpositiontooltip after">{label}</span>
                    :
                    <span>{label}</span>
            }

        </div>
    );
};

export default SectionSwitch;
