import { useMutation } from "@apollo/client";
import { SAVE_GENERAL_SETTINGS_MUTATION } from "../mutations/mutations";

export function useGeneralDomainSettingsSave(options = {}) {
    const [container] = useMutation(SAVE_GENERAL_SETTINGS_MUTATION, options)


    /**
     * Function description here.
     *
     * @param {Object} options - Configuration options.
     * @param {string} options.id - The domain ID.
     * @param {string} options.advPreset - The preset ID.
     * @param {string} options.industryOptions - Industry options.
     * @param {string} options.networkId - The network ID.
     * @param {string} options.childnetworkId - The child network ID.
     * @param {string} options.enableCLS - Enable CLS.
     * @param {string} options.overrideSiteCSS - Override site CSS.
     * @param {string} options.ads_ActiveViewTime - Ads active view time.
     * @param {string} options.shortDomainName - Short domain name.
     * @param {string} options.bannerFloorsPrice - Banner floors price.
     * @param {string} options.adEnableSandboxMode - Enable sandbox mode for ads.
     * @param {string} options.adEnableAdhesionIcon - Enable adhesion icon for ads.
     * @param {string} options.adInactive - Set ads as inactive.
     * @param {string} options.sendAllBids - Send all bids.
     * @param {string} options.adDisableHBGlobally - Disable header bidding globally.
     * @param {string} options.adDisableHB_GloballyByCountries - Disable header bidding globally by countries.
     * @param {string} options.adOverridePrebidTimeout - Override Prebid timeout.
     * @param {string} options.adPrebidTimeout - Prebid timeout.
     * @param {string} options.adEnableVideoWrapper - Enable video wrapper for ads.
     * @param {string} options.adgVersion - Adg version.
     * @param {string} options.stagingURL - Staging URL.
     * @param {string} options.domainMarketplaceNetworks - Domain marketplace networks.
     * @param {string} options.enable_marketplace - Enable marketplace.
     * @param {string} options.marketplaceDomainOverviewData - Marketplace domain overview data.
     * @param {string} options.marketplaceEmailsList - Marketplace emails list.
     * @param {string} options.video_player_type - Video player type.
     * @param {string} options.advertisement_label_text - Advertisement label text.
     * @param {string} options.advertisement_label_align - Advertisement label alignment.
     * @param {string} options.advertisement_label_position - Advertisement label position.
     * @param {string} options.stickyHeaderHeight - Sticky header height.
     * @param {string} options.stickyHeaderHeightTab - Sticky header height for tablets.
     * @param {string} options.stickyHeaderHeightMob - Sticky header height for mobile.
     * @param {string} options.fromHBSettings - From HB settings.
     * @param {string} options.overrideDisableHBGlobally - Override disable header bidding globally.
     * @param {string} options.playerVendor - Player vendor.
 */

    return (options) => {
        options.id = options.id && String(options.id)
        options.advPreset = options.advPreset && String(options.advPreset)

        for (let p in options) {
            if (options[p] === null || options[p] === undefined)
                delete options[p]
        }

        return container({variables: options})
    }
}