import React from "react";
import { CREATIVE_ASSET_TYPES, CREATIVE_NAMES, INSERTION_MODES, VIDEO_PARALLAX_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import DropdownSection from "./DropdownSection";
import VideoAssetCreativeType from "./VideoAssetCreativeType";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.VIDEO_PARALLAX;

export default function ParallaxVideo({
    videoCreativeURL,
    container,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    vastUrl,
    vastDuration,
    topOffset,
    insertionMode,
    videoCreativeType,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <VideoAssetCreativeType
                    dropdownLabel="Video Creative Type"
                    types={CREATIVE_ASSET_TYPES}
                    videoCreativeTypePropertyName={VIDEO_PARALLAX_AD_FIELDS.videoCreativeType}
                    videoCreativeType={videoCreativeType || 'video'}
                    handleChange={handleChange}
                    CREATIVE_TYPE={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    videoUrlInputLabel="Video URL"
                    videoUrlInputName={VIDEO_PARALLAX_AD_FIELDS.videoCreativeURL}
                    videoUrl={videoCreativeURL}
                    vastInputName={VIDEO_PARALLAX_AD_FIELDS.vastUrl}
                    vastUrl={vastUrl}
                    vastDurationInputName={VIDEO_PARALLAX_AD_FIELDS.vastDuration}
                    vastDuration={vastDuration}
                />
                <InputTextSection
                    label={"Container selector"}
                    name={VIDEO_PARALLAX_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={true}
                    uriEncodeValue={false}
                />
                <InputTextSection
                    label={"Top Offset"}
                    name={VIDEO_PARALLAX_AD_FIELDS.topOffset}
                    value={topOffset}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                    uriEncodeValue={false}
                />
                <DropdownSection
                    label="DOM insertion mode"
                    name={VIDEO_PARALLAX_AD_FIELDS.insertionMode}
                    optionLabel="label"
                    optionValue="value"
                    options={INSERTION_MODES}
                    value={insertionMode}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
            </BaseCreativeControls>
        </>
    );
}
