import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, CROSS_SCREEN_TAKEOVER_AD_FIELDS } from "./definitions";
import InputSwitchSection from "./InputSwitchSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.CROSS_SCREEN_TAKEOVER;

export default function CrossScreenTakeover({
    cstBanner,
    rightBanner,
    leftBanner,
    videoCreativeUrl,
    mobileTopbanner,
    mobileBottombanner,
    mobileScroller,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    dynamicFit,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                creativeType={CREATIVE_TYPE}
                impressionTag={impressionTag}
            >
                <InputSwitchSection
                    isChecked={dynamicFit}
                    changeHandler={handleChange}
                    creativeType={CREATIVE_TYPE}
                    label="Dynamically fit creative?"
                    switchName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.dynamicFit}
                />
                <AssetInputOrUploadSection
                    inputValue={cstBanner}
                    inputLabel="Desktop top banner"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.cstBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={leftBanner}
                    inputLabel="Desktop left banner"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.leftBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={rightBanner}
                    inputLabel="Desktop right banner"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.rightBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={videoCreativeUrl}
                    inputLabel="Video creative URL"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.videoCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="video/*"
                    defaultExtension="mp4"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileTopbanner}
                    inputLabel="Mobile top banner"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.mobileTopbanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileBottombanner}
                    inputLabel="Mobile bottom banner"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.mobileBottombanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileScroller}
                    inputLabel="Mobile scroller image"
                    inputName={CROSS_SCREEN_TAKEOVER_AD_FIELDS.mobileScroller}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
            </BaseCreativeControls>
        </>
    );
}