import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

function InputControls({ name, value, required, disabled, changeHandler, adtype, isNumericInput, isTextInput, min, max, placeholder = "", uriEncodeValue }) {
    if (isNumericInput) {
        return <InputNumber placeholder={placeholder} id={name} name={name} value={value} required={required} disabled={disabled} min={min} max={max} onValueChange={(ev) => changeHandler(ev, ev.target.value, adtype)} />;
    } else if (isTextInput) {
        return (
            <InputText
                id={name}
                name={name}
                value={value}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(ev) => {
                    if (uriEncodeValue) {
                        let value = ev.target.value;
                        let decodedUrl = window.decodeURI(value);
                        let encodedUrl = window.encodeURI(value);
                        changeHandler(ev, value === encodedUrl ? value : decodedUrl === value ? encodedUrl : value, adtype);
                    } else {
                        changeHandler(ev, ev.target.value, adtype);
                    }
                }}
            />
        );
    } else {
        return <InputTextarea rows={3} cols={30} id={name} name={name} value={value} required={required} disabled={disabled} onChange={(ev) => changeHandler(ev, ev.target.value, adtype)} />;
    }
}

export default function InputTextSection({ label, name, value, changeHandler, adtype, min, max, required = false, disabled = false, textInput = true, isNumericInput = false, placeHolder = "", uriEncodeValue = true }) {
    return (
        <div
            css={css`
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                margin-bottom: 10px;
                input,
                textarea {
                    width: 100%;
                }
            `}
        >
            <label
                htmlFor={name}
                css={css`
                    font-weight: 600;
                    margin-bottom: 5px;
                    position: relative;
                `}
            >
                {label}
                {required && (
                    <span
                        css={css`
                            color: #ff0000;
                            font-size: 16px;
                            padding-left: 3px;
                        `}
                    >
                        *
                    </span>
                )}
            </label>
            <InputControls name={name} value={value} required={required} disabled={disabled} changeHandler={changeHandler} adtype={adtype} min={min} max={max} isTextInput={textInput} isNumericInput={isNumericInput} placeholder={placeHolder} uriEncodeValue={uriEncodeValue} />
        </div>
    );
}
