import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, FOOTER_GALLERY_CONFIG_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.FOOTER_GALLERY;

export default function FooterGallery({
    logo1,
    logo2,
    logo3,
    logo4,
    slide1,
    slide2,
    slide3,
    slide4,
    handleChange,
    currentCustomer,
    impressionTag,
    ctaText,
    clickTag,
    rightPanelSecondLogo,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={slide1}
                    inputLabel="Gallery slide 1"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.slide1} inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*" defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={slide2}
                    inputLabel="Gallery slide 2"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.slide2} inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={slide3}
                    inputLabel="Gallery slide 3"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.slide3}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <AssetInputOrUploadSection
                    inputValue={slide4}
                    inputLabel="Gallery slide 4"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.slide4}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <AssetInputOrUploadSection
                    inputValue={rightPanelSecondLogo}
                    inputLabel="Right-panel logo"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.rightPanelSecondLogo}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <InputTextSection
                    label="CTA text"
                    name={FOOTER_GALLERY_CONFIG_AD_FIELDS.ctaText}
                    value={ctaText}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    textInput={true}
                    uriEncodeValue={false}
                    required={true}
                />

                <AssetInputOrUploadSection
                    inputValue={logo1}
                    inputLabel="Front image 1"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.logo1}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <AssetInputOrUploadSection
                    inputValue={logo2}
                    inputLabel="Front image 2"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.logo2}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <AssetInputOrUploadSection
                    inputValue={logo3}
                    inputLabel="Front image 3"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.logo3}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
                <AssetInputOrUploadSection
                    inputValue={logo4}
                    inputLabel="Front image 4"
                    inputName={FOOTER_GALLERY_CONFIG_AD_FIELDS.logo4}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                    isRequired={true}
                />
            </BaseCreativeControls>
        </>
    );
}
