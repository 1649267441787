import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../App.scss";
import "../../layout/flags/flags.css";
import "../../layout/layout.scss";
import { setNavigationMode } from "../../reducers/userLoginReducer";
import { getIn } from "../../utilities/utils";
import SectionHeader from "../helper/SectionHeader";
import { addToPresetBuildList } from "../../reducers/buildReducer";
import { pushMessage } from "../../reducers/messagingReducer";
import LayoutWrapper from "../LayoutWrapper";
import { registerScreenChanges } from "../../reducers/screenChangesReducer";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        presetAllInfoMutation(input: { id: $id, cid: $cid }) {
            presetInfo
        }
    }
`;

const LAZY_LOAD_UPDATE_MUTATION = gql`
    mutation callLazyloadUpdateMutation($id: String, $lazyLoadList: [String], $lazyLoadOverrideList: [String]) {
        updatePresetLazyLoadMutation(input: { id: $id, lazyLoadList: $lazyLoadList, lazyLoadOverrideList: $lazyLoadOverrideList }) {
            clientMutationId
            networkId
        }
    }
`;

const PresetLazyLoad = (props) => {
    const { currentCustomer, currentPreset } = useSelector((state) => state.userInformation);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const messages = useRef(null);
    const dispatch = useDispatch();

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    const [LAZY_LOAD_UPDATE_MUTATION_CONTAINER] = useMutation(LAZY_LOAD_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(addToPresetBuildList(currentPreset));
            dispatch(pushMessage("Preset Identity Hub", "Lazy load settings updated"));
        },
    });

    const [inputList, setInputList] = useState(null);
    const [inputList2, setInputList2] = useState(null);
    const [isRenderMarginPercentValid, validateMarginPercent] = useState(true);
    const [isLazyFetchPercentValid, validateFetchPercent] = useState(true);

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentPreset}`, cid: currentCustomer },
            });
            dispatch(setNavigationMode("preset"));
        }
    }, [currentCustomer, currentPreset]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo);
        if (temp_data.lazyload["lazyload_refresh_settings"] === null) {
            temp_data.lazyload["lazyload_refresh_settings"] = JSON.parse('{"disable_lazyload":false,"enable_lazyload":true,lazy_load_preset_viewport_buffer":""}');
        }
        setInputList(temp_data.lazyload["lazyload_refresh_settings"]);
        let override_array = temp_data.lazyload["override_by_url"];
        //No repeater data found add an empty set for user
        if (override_array === false || override_array === null) {
            override_array = [];
            override_array.push({ url: "", exact_match: false, enable_lazyload: false, scroll_delay: 100, fetch_margin_percent: 100, render_margin_percent: 100, mobile_scaling: 1, refresh_time: 30, lazy_load_viewport_buffer: 30, ad_refresh_limit: 999999, disable_refresh: false });
        }
        setInputList2(override_array);
        setMutationDataCompleted(true);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList2];
        list.splice(index, 1);
        setInputList2(list);
        dispatch(registerScreenChanges("Preset Lazy Load"));
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList2([...inputList2, { url: "", exact_match: false, enable_lazyload: false, scroll_delay: 100, fetch_margin_percent: 100, render_margin_percent: 100, mobile_scaling: 1, refresh_time: 30, lazy_load_viewport_buffer: 30, ad_refresh_limit: 999999, disable_refresh: false }]);
        dispatch(registerScreenChanges("Preset Lazy Load"));
    };

    const handleChange = (index, param, targetName = null) => {
        if (index !== null) {
            let temp = [...inputList2];
            if (param.target !== undefined && param.target.name !== undefined) {
                temp[index][param.target.name] = param.target.value;
            } else {
                if (targetName !== null) {
                    temp[index][targetName] = param.value;
                } else {
                    temp[index][param.originalEvent.target.name] = param.value;
                }
            }

            setInputList2(temp);
            dispatch(registerScreenChanges("Preset Lazy Load"));
        } else {
            if (param.target !== undefined && param.target.name !== undefined) {
                let name = param.target.name,
                    value = param.value,
                    temp = Object.assign({}, inputList);
                temp[name] = value;
                setInputList(temp);
            } else {
                let name = targetName !== null ? targetName : param.originalEvent.target.name,
                    value = param.value,
                    temp = Object.assign({}, inputList);
                temp[name] = value;
                setInputList(temp);
            }
            dispatch(registerScreenChanges("Preset Lazy Load"));
        }
    };

    const saveLazyLoadData = () => {
        return LAZY_LOAD_UPDATE_MUTATION_CONTAINER({
            variables: { id: `${currentPreset}`, lazyLoadList: JSON.stringify(inputList), lazyLoadOverrideList: JSON.stringify(inputList2) },
        });
    };

    const dialogFuncMap = {
        displayConfirmation: setDisplayConfirmation,
    };

    const onClick = (name, index) => {
        setPositionFinal(index);
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const renderFooter = (name, index) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={() => {
                        onHide(name);
                        handleRemoveClick(positionFinal);
                        dispatch(registerScreenChanges("Preset Lazy Load"));
                    }}
                    autoFocus
                />
            </div>
        );
    };

    useEffect(() => {
        if (mutationDataCompleted && inputList) {
            const lazyRenderMarginPercent = inputList.render_margin_percent ? parseFloat(inputList.render_margin_percent) : 0;
            const lazyFetchMarginPercent = inputList.fetch_margin_percent ? parseFloat(inputList.fetch_margin_percent) : 0;
            const refreshRenderMarginPercent = inputList.lazy_load_preset_viewport_buffer ? parseFloat(inputList.lazy_load_preset_viewport_buffer) : 0;
            validateMarginPercent(lazyRenderMarginPercent >= refreshRenderMarginPercent);
            validateFetchPercent(lazyFetchMarginPercent >= lazyRenderMarginPercent);
        }
    }, [mutationDataCompleted, inputList]);

    const saveCallback = async () => {
        let urlOverridesWithoutValidationErrors = true;
        if (inputList2 && inputList2.length) {
            urlOverridesWithoutValidationErrors = inputList2
                .filter((item) => item.enable_lazyload)
                .every(({ lazy_load_viewport_buffer, render_margin_percent }) => {
                    return parseInt(render_margin_percent || 0) >= parseInt(lazy_load_viewport_buffer || 0);
                });
        }
        if (inputList && inputList.length) {
            urlOverridesWithoutValidationErrors = inputList
                .filter((item) => item.enable_lazyload)
                .every(({ fetch_margin_percent, render_margin_percent }) => {
                    return parseInt(fetch_margin_percent || 0) >= parseInt(render_margin_percent || 0);
                });
        }
        if (!urlOverridesWithoutValidationErrors) {
            dispatch(pushMessage("Preset lazy load", "The refresh render margin percent cannot be greater than lazyload render margin percent", "error"));
            throw new Error("The refresh render margin percent cannot be greater than lazyload render margin percent");
        }
        if (!urlOverridesWithoutValidationErrors) {
            dispatch(pushMessage("Preset Lazyload settings", "The Fetch margin percent cannot be smaller than lazyload render margin percent", "error"));
            throw new Error("The Fetch margin percent cannot be smaller than lazyload render margin percent");
        }
        if (inputList.enable_lazyload) {
            if (isRenderMarginPercentValid  && isLazyFetchPercentValid) {
                return saveLazyLoadData();
            } else if(isRenderMarginPercentValid) {
                dispatch(pushMessage("Preset lazy load", "The refresh render margin percent cannot be greater than lazyload render margin percent", "error"));
                throw new Error("The refresh render margin percent cannot be greater than lazyload render margin percent");
            } else if(isLazyFetchPercentValid) {
                dispatch(pushMessage("Preset Lazyload settings", "The Fetch margin percent cannot be smaller than lazyload render margin percent", "error"));
                throw new Error("The Lazyload Fetch margin percent cannot be smaller than lazyload render margin percent");
            }
        } else {
            return saveLazyLoadData();
        }
    };

    return (
        <LayoutWrapper model="preset" saveCallback={saveCallback}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content">
                        <Messages ref={messages} />
                        <div className="card">
                            <div className="p-panel p-component">
                                <SectionHeader screenName="Lazyload & refresh">
                                    <div className="p-text-bold">Lazy Settings</div>
                                </SectionHeader>
                                <Tooltip target=".adpositiontooltip" />
                                <div className="p-panel-content">
                                    <div className="p-grid small-margin-top-10">
                                        <div className="p-col-3 p-sm-none small-hide">
                                            <label className="adpositiontooltip after" data-pr-tooltip="Check to enable lazyload for this Publisher." htmlFor="enable_lazyload">
                                                <strong>Enable Lazyload</strong>
                                            </label>
                                        </div>
                                        <div className="p-col-9 p-fluid">
                                            <div className="p-d-flex p-ai-center p-mb-2">
                                                <InputSwitch name="enable_lazyload" checked={inputList.enable_lazyload} onChange={(e) => handleChange(null, e)} />
                                                <label htmlFor="enable_lazyload" className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="Check to enable lazyload for this Publisher.">
                                                    <strong>Enable Lazyload</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label
                                                className="adpositiontooltip after"
                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we fetch the ad as a percentage of viewport size. A value of 0 means 'when the slot enters the viewport', 100 means 'when the ad is 1 viewport away', and so on.
                              "
                                                htmlFor="fetch_margin_percent"
                                            >
                                                <strong>
                                                    Fetch Margin Percent <span className="color-red">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber inputId="fetch_margin_percent" name="fetch_margin_percent" value={inputList.fetch_margin_percent} onChange={(e) => handleChange(null, e, "fetch_margin_percent")} mode="decimal" min={0} max={99999999} />
                                                <span className="p-inputgroup-addon">%</span>
                                            </div>
                                            {inputList.fetch_margin_percent && !isLazyFetchPercentValid && <span className="small-notice">Lazyload Fetch margin Percent must be greater than lazyload Render Margin Percent when lazyload is enabled</span>}
                                        </div>
                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label
                                                className="adpositiontooltip after"
                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we render an ad. This allows for prefetching the ad, but waiting to render and download other subresources. The value works just like fetchMarginPercent as a percentage of viewport.
                            "
                                                htmlFor="render_margin_percent"
                                            >
                                                <strong>
                                                    Render Margin Percent <span className="color-red">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber inputId="render_margin_percent" name="render_margin_percent" value={inputList.render_margin_percent} onChange={(e) => handleChange(null, e, "render_margin_percent")} mode="decimal" min={0} max={99999999} />
                                                <span className="p-inputgroup-addon">%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label
                                                className="adpositiontooltip after"
                                                data-pr-tooltip="A multiplier applied to margins on mobile devices. This allows varying margins on mobile vs. desktop. For example, a value of 2.0 will multiply all margins by 2 on mobile devices, increasing the minimum distance a slot can be before fetching and rendering.
                              "
                                                htmlFor="mobile_scaling"
                                            >
                                                <strong>
                                                    Mobile Scale<span className="color-red">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber inputId="mobile_scaling" name="mobile_scaling" value={inputList.mobile_scaling} onChange={(e) => handleChange(null, e, "mobile_scaling")} mode="decimal" min={0} max={99999999} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="p-panel p-component">
                                <SectionHeader title="Refresh Settings" />
                                <Tooltip target=".adpositiontooltip" />
                                <div className="p-panel-content">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label className="adpositiontooltip after" data-pr-tooltip="Time in seconds to take an Ad refresh.  Integer number example 30." htmlFor="ad_refresh_time">
                                                <strong>
                                                    Ad Refresh Time <span className="color-red">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber inputId="ad_refresh_time" name="ad_refresh_time" value={inputList.ad_refresh_time} onChange={(e) => handleChange(null, e, "ad_refresh_time")} mode="decimal" min={0} max={99999999} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label className="adpositiontooltip after" data-pr-tooltip="The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen." htmlFor="ad_refresh_limit">
                                                <strong>Ad Refresh Limit </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber inputId="ad_refresh_limit" name="ad_refresh_limit" value={inputList.ad_refresh_limit} onChange={(e) => handleChange(null, e, "ad_refresh_limit")} mode="decimal" min={0} max={99999999} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                            <label
                                                className="adpositiontooltip after"
                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we refresh an ad. The value works just like fetchMarginPercent as a percentage of viewport.
                              "
                                                htmlFor="lazy_load_preset_viewport_buffer"
                                            >
                                                <strong>
                                                    Render Margin Percent <span className="color-red">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                            <div className="p-inputgroup p-fluid">
                                                <InputNumber
                                                    inputId="lazy_load_preset_viewport_buffer"
                                                    name="lazy_load_preset_viewport_buffer"
                                                    value={inputList.lazy_load_preset_viewport_buffer}
                                                    onChange={(e) => handleChange(null, e, "lazy_load_preset_viewport_buffer")}
                                                    mode="decimal"
                                                    min={0}
                                                    style={{
                                                        backgroundColor: inputList.enable_lazyload ? "#ccc" : "#fff",
                                                        borderRadius: "4px",
                                                        border: `2px solid ${inputList.enable_lazyload ? (isRenderMarginPercentValid ? "#ccc" : "#ff0000") : "#ccc"} `,
                                                    }}
                                                    max={99999999}
                                                />
                                                <span className="p-inputgroup-addon">%</span>
                                            </div>
                                            {inputList.enable_lazyload && !isRenderMarginPercentValid && <span className="small-notice">This value cannot be greater than lazyload render margin percent when lazyload is enabled</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {inputList2 && (
                            <div className="card">
                                <div className="p-datatable p-component p-datatable-gridlines">
                                    <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                        <div className="p-text-bold">Override by URL</div>
                                    </div>
                                    <div className="p-panel p-component">
                                        <div className="p-panel-content">
                                            <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter("displayConfirmation")} onHide={() => onHide("displayConfirmation")}>
                                                <div className="confirmation-content p-d-flex p-ai-center">
                                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                                    <span>Are you sure you want to delete this item?</span>
                                                </div>
                                            </Dialog>

                                            <Accordion className="">
                                                {inputList2.map((value, index) => {
                                                    return (
                                                        <AccordionTab
                                                            key={index}
                                                            header={
                                                                <React.Fragment>
                                                                    <div className="p-d-flex p-jc-between">
                                                                        <div className="p-field-checkbox p-mb-0">
                                                                            <div className="p-text-bold">{value.url}</div>
                                                                        </div>
                                                                        <Button
                                                                            type="button"
                                                                            icon="pi pi-times"
                                                                            className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                                            tooltip="Remove row"
                                                                            tooltipOptions={{ position: "top" }}
                                                                            onClick={() => onClick("displayConfirmation", index)}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <table>
                                                                <tbody className="">
                                                                    <tr>
                                                                        <td>
                                                                            <div className="p-panel p-component">
                                                                                <SectionHeader title="URL Override" />
                                                                                <div className="p-panel-content">
                                                                                    <Tooltip target=".adpositiontooltip" />
                                                                                    <div className="p-grid p-mb-2 small-margin-bottom-10">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="You MUST use relative url in this list. Example: / for homepage" htmlFor={"url" + index}>
                                                                                                <strong>URL</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <InputTextarea rows={15} cols={30} id={"url" + index} name="url" value={value.url} onChange={(e) => handleChange(index, e, "url")} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid small-margin-top-10">
                                                                                        <div className="p-col-3 p-sm-none small-hide">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="If checked then will check site's current URL with query string." htmlFor={"exact_match" + index}>
                                                                                                <strong>Exact Match</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-9 p-fluid">
                                                                                            <div className="p-d-flex p-ai-center p-mb-2">
                                                                                                <InputSwitch id={"exact_match" + index} name={"exact_match"} checked={value.exact_match} onChange={(e) => handleChange(index, e)} />
                                                                                                <label htmlFor={"exact_match" + index} className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="If checked then will check site's current URL with query string">
                                                                                                    <strong>Exact Match</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <br></br>

                                                                            <div className="p-panel p-component">
                                                                                <SectionHeader title="Lazy Settings" />
                                                                                <Tooltip target=".adpositiontooltip" />
                                                                                <div className="p-panel-content">
                                                                                    <div className="p-grid small-margin-top-10">
                                                                                        <div className="p-col-3 p-sm-none small-hide">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="If checked then it will enabled lazyload for these speccific URLs." htmlFor={"enable_lazyload" + index}>
                                                                                                <strong>Enable Lazyload</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-9 p-fluid">
                                                                                            <div className="p-d-flex p-ai-center p-mb-2">
                                                                                                <InputSwitch id={"enable_lazyload" + index} name={"enable_lazyload"} checked={value.enable_lazyload} onChange={(e) => handleChange(index, e)} />
                                                                                                <label htmlFor={"enable_lazyload" + index} className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="If checked then will check site's current URL with query string">
                                                                                                    <strong>Enable Lazyload</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="1000 ms = 1 sec. Min: 0, Max: unlimited" htmlFor="scroll_delay">
                                                                                                <strong>
                                                                                                    Scroll Delay <span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"scroll_delay" + index} name="scroll_delay" value={value.scroll_delay} onChange={(e) => handleChange(index, e, "scroll_delay")} mode="decimal" />
                                                                                                <span className="p-inputgroup-addon">%</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label
                                                                                                className="adpositiontooltip after"
                                                                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we fetch the ad as a percentage of viewport size. A value of 0 means 'when the slot enters the viewport', 100 means 'when the ad is 1 viewport away', and so on.
                        "
                                                                                                htmlFor="fetch_margin_percent"
                                                                                            >
                                                                                                <strong>
                                                                                                    Fetch Margin Percent <span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"fetch_margin_percent" + index} name="fetch_margin_percent" value={value.fetch_margin_percent} onChange={(e) => handleChange(index, e, "fetch_margin_percent")} mode="decimal" />
                                                                                                <span className="p-inputgroup-addon">%</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label
                                                                                                className="adpositiontooltip after"
                                                                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we render an ad. This allows for prefetching the ad, but waiting to render and download other subresources. The value works just like fetchMarginPercent as a percentage of viewport.
                        "
                                                                                                htmlFor="render_margin_percent"
                                                                                            >
                                                                                                <strong>
                                                                                                    Render Margin Percent <span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"render_margin_percent" + index} name="render_margin_percent" value={value.render_margin_percent} onChange={(e) => handleChange(index, e, "render_margin_percent")} mode="decimal" />
                                                                                                <span className="p-inputgroup-addon">%</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label
                                                                                                className="adpositiontooltip after"
                                                                                                data-pr-tooltip="A multiplier applied to margins on mobile devices. This allows varying margins on mobile vs. desktop. For example, a value of 2.0 will multiply all margins by 2 on mobile devices, increasing the minimum distance a slot can be before fetching and rendering.
                        "
                                                                                                htmlFor="mobile_scaling"
                                                                                            >
                                                                                                <strong>
                                                                                                    Mobile Scale<span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"mobile_scaling" + index} name="mobile_scaling" value={value.mobile_scaling} onChange={(e) => handleChange(index, e, "mobile_scaling")} mode="decimal" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br></br>
                                                                            <div className="p-panel p-component">
                                                                                <SectionHeader title="Refresh Settings" />
                                                                                <Tooltip target=".adpositiontooltip" />
                                                                                <div className="p-panel-content">
                                                                                    <div className="p-grid small-margin-top-10">
                                                                                        <div className="p-col-3 p-sm-none small-hide">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="If checked then will disable the refresh of the specific URL/URLs." htmlFor={"disable_refresh" + index}>
                                                                                                <strong>Disable Refresh</strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-9 p-fluid">
                                                                                            <div className="p-d-flex p-ai-center p-mb-2">
                                                                                                <InputSwitch id={"disable_refresh" + index} name={"disable_refresh"} checked={value.disable_refresh} onChange={(e) => handleChange(index, e)} />
                                                                                                <label htmlFor={"disable_refresh" + index} className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="If checked then will disable the refresh of the specific URL/URLs">
                                                                                                    <strong>Disable Refresh</strong>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="Time in seconds to take an Ad refresh.  Integer number example 30." htmlFor="refresh_time">
                                                                                                <strong>
                                                                                                    Ad Refresh Time <span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"refresh_time" + index} name="refresh_time" value={value.refresh_time} onChange={(e) => handleChange(index, e, "refresh_time")} mode="decimal" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label className="adpositiontooltip after" data-pr-tooltip="The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen." htmlFor="ad_refresh_limit">
                                                                                                <strong>Ad Refresh Limit </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber inputId={"ad_refresh_limit" + index} name="ad_refresh_limit" value={value.ad_refresh_limit} onChange={(e) => handleChange(index, e, "ad_refresh_limit")} mode="decimal" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                                                            <label
                                                                                                className="adpositiontooltip after"
                                                                                                data-pr-tooltip="The minimum distance from the current viewport a slot must be before we refresh an ad. The value works just like fetchMarginPercent as a percentage of viewport.
                          "
                                                                                                htmlFor="lazy_load_viewport_buffer"
                                                                                            >
                                                                                                <strong>
                                                                                                    Render Margin Percent <span className="color-red">*</span>
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                                            <div className="p-inputgroup p-fluid">
                                                                                                <InputNumber
                                                                                                    inputId={"lazy_load_viewport_buffer" + index}
                                                                                                    name="lazy_load_viewport_buffer"
                                                                                                    value={value.lazy_load_viewport_buffer}
                                                                                                    onChange={(e) => handleChange(index, e, "lazy_load_viewport_buffer")}
                                                                                                    mode="decimal"
                                                                                                    min={0}
                                                                                                    style={{
                                                                                                        backgroundColor: value.enable_lazyload ? "#ccc" : "#fff",
                                                                                                        borderRadius: "4px",
                                                                                                        border: `2px solid ${value.enable_lazyload ? (isRenderMarginPercentValid ? "#ccc" : "#ff0000") : "#ccc"} `,
                                                                                                    }}
                                                                                                    max={99999999}
                                                                                                />
                                                                                                <span className="p-inputgroup-addon">%</span>
                                                                                            </div>
                                                                                            {getIn(inputList2, index, "enable_lazyload") && parseInt(getIn(inputList2, index, "lazy_load_viewport_buffer") || 0) > parseInt(getIn(inputList2, index, "render_margin_percent") || 0) && (
                                                                                                <span className="small-notice">This value cannot be greater than lazyload render margin percent when lazyload is enabled</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </AccordionTab>
                                                    );
                                                })}
                                            </Accordion>
                                            <div className="p-d-flex p-jc-end p-mt-3">
                                                <Button type="button" label="Add Row" icon="pi pi-plus" tooltip="Add row" tooltipOptions={{ position: "top" }} onClick={handleAddClick} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader title="Description" />
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Enable Lazyload</h6>
                                <p>Check to enable lazyload for this Publisher.</p>
                                <h6 className="p-text-bold p-mb-0">Fetch Margin Percent</h6>
                                <p>The minimum distance from the current viewport a slot must be before we fetch the ad as a percentage of viewport size. A value of 0 means 'when the slot enters the viewport', 100 means 'when the ad is 1 viewport away', and so on</p>
                                <h6 className="p-text-bold p-mb-0">Render Margin Percent</h6>
                                <p>The minimum distance from the current viewport a slot must be before we render an ad. This allows for prefetching the ad, but waiting to render and download other subresources. The value works just like fetchMarginPercent as a percentage of viewport</p>
                                <h6 className="p-text-bold p-mb-0">Mobile Scale</h6>
                                <p>A multiplier applied to margins on mobile devices. This allows varying margins on mobile vs. desktop. For example, a value of 2.0 will multiply all margins by 2 on mobile devices, increasing the minimum distance a slot can be before fetching and rendering.</p>
                                <h6 className="p-text-bold p-mb-0">Ad Refresh Time</h6>
                                <p>Time in seconds to take an Ad refresh. Integer number example 30.</p>
                                <h6 className="p-text-bold p-mb-0">Ad Refresh Limit</h6>
                                <p>The maximum number of each Ad refresh. After exceeding the maximum number, the 'Ad refresh' will not happen.</p>
                                <h6 className="p-text-bold p-mb-0">Refresh Render Margin Percent</h6>
                                <p>The minimum distance from the current viewport a slot must be before we refresh an ad. The value works just like fetchMarginPercent as a percentage of viewport.</p>
                                <hr />
                                <h6 className="p-text-bold p-mb-0 m-mt-0">URL</h6>
                                <p>You MUST use relative url in this list. Example: / for homepage</p>
                                <h6 className="p-text-bold p-mb-0">Buffer</h6>
                                <p>Minimum percentage of requiring viewport to call an Ad as Lazy Load.</p>
                                <h6 className="p-text-bold p-mb-0">Disable lazyload</h6>
                                <p>Check to disable lazyload for this URL.</p>
                                <hr />
                                <h6 className="p-text-bold p-mb-0 m-mt-0">Override by URL</h6>
                                <h6 className="p-text-bold p-mb-0 m-mt-0">URL</h6>
                                <p>You MUST use relative url in this list. Example: / for homepage</p>
                                <h6 className="p-text-bold p-mb-0">Refresh Time</h6>
                                <p>Number of seconds of refresh time.</p>
                                <h6 className="p-text-bold p-mb-0">Disable refresh</h6>
                                <p>Check to disable refresh for this URL.</p>
                                <h6 className="p-text-bold p-mb-0">Exact Match</h6>
                                <p>If checked then will check site's current URL with query string..</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetLazyLoad;
