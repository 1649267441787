/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { getIn, noop } from "../utilities/utils";
import LayoutWrapper from "./LayoutWrapper";
import SectionHeader from "./helper/SectionHeader";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { PERFORMANCE_SETTINGS_MUTATION } from "../mutations/mutations";

const Input = ({ name, value, onChange, placeholder, className }) => <InputText id={name} name={name} value={value} className={className || ""} placeholder={placeholder || ""} onChange={(ev) => onChange(ev)} />;

const RadioBtn = ({ name, label, checked, onChange, className }) => (
    <span className="radio-input-container">
        <RadioButton name={name} id={name} checked={checked} onChange={(ev) => onChange(ev)} className={`radio ${className}`} />
        <label htmlFor={name}>{label}</label>
    </span>
);

const INITIAL_CONFIG_STATE = {
    "ads-loading": true,
    "page-loading": false,
    "viewport-range-start": "100",
    "viewport-range-end": "800",
};

let IS_CONFIG_OVERRIDEN = false;

const PerformancePreferences = (props) => {
    const { currentDomain, currentCustomer, currentPreset } = useSelector((state) => state.userInformation);
    const [showCheckBox, setShowCheckBox] = useState(IS_CONFIG_OVERRIDEN);
    const [state, setState] = useState(INITIAL_CONFIG_STATE);
    const [isMutationComplete, setMutationComplete] = useState(false);
    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted() {
            setMutationComplete(true);
        },
    })
    const [performancePreferencesMutation] = useMutation(PERFORMANCE_SETTINGS_MUTATION);
    const dispatch = useDispatch();
    const toSnakeCase = (str) => str.split("-").join("_");

    const handleRadioChange = (ev) => {
        const {
            target: { name },
        } = ev;
        dispatch(registerScreenChanges("Performance Preferences"));
        switch (name) {
            case "ads-loading":
                return setState({ ...state, [name]: true, "page-loading": false });
            case "page-loading":
                return setState({ ...state, [name]: true, "ads-loading": false });
            default:
                noop();
        }
    };

    const handleInputChange = (ev) => {
        dispatch(registerScreenChanges("Performance Preferences"));
        setState({ ...state, [ev.target.name]: ev.target.value });
    };

    useEffect(() => {
        if (currentCustomer && currentDomain) {

            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            })
                .then((response) => {
                    let customerInfo = JSON.parse(getIn(response, "data", "domainEditMutation", "customerInfo"));
                    let preferences = customerInfo.performance_preferences;

                    preferences['viewport-range-start'] || (()=>preferences['viewport-range-start'] = '100')();
                    preferences['viewport-range-end'] || (()=>preferences['viewport-range-end'] = '800')();

                    if (preferences["performance-preference"] === "page_load") {
                        preferences["page-loading"] = true;
                        preferences["ads-loading"] = false;
                    } else {
                        preferences["page-loading"] = false;
                        preferences["ads-loading"] = true;
                    }

                    setState(preferences);
                })
                .catch(() => {
                    dispatch(pushMessage("Performance preferences", "There was an error while trying to gather video ad configurations", "error"));
                });
        }
    }, [currentDomain, currentCustomer, currentPreset]);

    function validationData(obj, field) {
        if (!obj[field]) {
            dispatch(pushMessage("Performance preferences", "Please enter require field", "error"));
            throw new Error("Please enter network id");
        }
    }

    function handleSave(state) {
        const json = Object.keys(state).reduce((acc, curr) => {
            acc[toSnakeCase(curr)] = state[curr];
            return acc;
        }, {});

        validationData(json, 'viewport_range_start');
        validationData(json, 'viewport_range_end');

        json["performance_preference"] = json.page_loading ? "page_load" : "ads_load";


        return performancePreferencesMutation({
            variables: {
                id: `${currentDomain}`,
                settingsData: JSON.stringify(json),
            },
        })
            .then(() => {
                dispatch(pushMessage("Performance preferences", "Performance settings were saved"));
                removeFromBuiltDomains(`${currentDomain}`);
            })
            .catch(() => {
                dispatch(pushMessage("Performance preferences", "There was an error while trying to update the performance preferences", "danger"));
                throw new Error("There was an error trying to update the performance preferences");
            });
    }

    return (
        <LayoutWrapper model="domain" saveCallback={async () => handleSave(state)}>
            <div className={`p-panel p-component ${!showCheckBox && props.overridable ? "disabled-content" : "enabled-content"}`}>
                {isMutationComplete && (
                    <>
                        <SectionHeader screenName="Performance preferences">
                            <div className="p-d-flex p-ai-center f-width">
                                {props.overridable ? (
                                    <>
                                        <InputSwitch
                                            checked={showCheckBox}
                                            onChange={() => {
                                                setShowCheckBox(!showCheckBox);
                                            }}
                                        />
                                        <label
                                            htmlFor="cb1"
                                            className="p-checkbox-label p-pl-2 p-text-bold"
                                            onClick={() => {
                                                setShowCheckBox(!showCheckBox);
                                            }}
                                        >
                                            Override Performance Preferences
                                        </label>
                                    </>
                                ) : (
                                    <p>
                                        <strong>Performance Preferences</strong>
                                    </p>
                                )}
                            </div>
                        </SectionHeader>

                        <div className="input-row multiple">
                            <h6>
                                <strong>Performance type</strong>
                            </h6>
                            <div className="inputs">
                                <RadioBtn name="ads-loading" label="Ads Loading" checked={state["ads-loading"]} className="preference-radio" onChange={handleRadioChange} />
                                <RadioBtn name="page-loading" label="Page Loading" checked={state["page-loading"]} onChange={handleRadioChange} />
                                <div className="wording">
                                    <p>Ads loading will make the Ad calls as soon as the DOM starts to load.</p>
                                    <p>Page loading will defer the Ad calls after DOM is fully loaded.</p>
                                </div>
                                {state["page-loading"] && (
                                    <div>
                                        <div>
                                            <hr />
                                            <label
                                                htmlFor="range-start"
                                                className="preference-label"
                                                style={{
                                                    paddingLeft: "0.25em",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Viewport range start <label style={{ color: 'red' }}>*</label>
                                            </label>
                                            <Input name="viewport-range-start" value={state["viewport-range-start"]} id="range-start" onChange={(ev) => handleInputChange(ev)} className="range-input" />
                                            <div className="wording">
                                                <p>Enter the start range of viewport where the deferred Ad calls will apply</p>
                                            </div>
                                        </div>
                                        <div>
                                            <hr />
                                            <label
                                                htmlFor="range-end"
                                                className="preference-label"
                                                style={{
                                                    paddingLeft: "0.25em",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Viewport range end <label style={{ color: 'red' }}>*</label>
                                            </label>
                                            <Input name="viewport-range-end" value={state["viewport-range-end"]} id="range-end" onChange={(ev) => handleInputChange(ev)} className="range-input" />
                                            <div className="wording">
                                                <p>Enter the end range of viewport where the deferred Ad calls will apply</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </LayoutWrapper>
    );
};

export default PerformancePreferences;
