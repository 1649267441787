/* eslint-disable react-hooks/exhaustive-deps */
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Messages } from "primereact/messages";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { setCurrentPreset } from "../reducers/userLoginReducer";
import LayoutWrapper from "./LayoutWrapper";
import TrafficShaping from "./TrafficShaping";
import { foldRules } from "./common/trafficShaping/utils";
import SectionHeader from "./helper/SectionHeader";
import { MultiSelect } from "primereact/multiselect";
import { COUNTRIES } from "./common/trafficShaping/constants";
import deepcopy from "deepcopy";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { useGeneralDomainSettingsSave } from "../hooks/useGeneralDomainSettingsSave";
import { useOptimizeBiddingSave } from "../hooks/useOptimizeBiddingSave";

const HeaderBiddingSettings = () => {
    const history = useHistory();
    const { currentDomain, currentCustomer, userID } = useSelector((state) => state.userInformation);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const messages = useRef(null);
    const dispatch = useDispatch();

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            confirm(data);
        }
    })

    const OPTIMIZE_BIDDING_UPDATE_MUTATION_CONTAINER = useOptimizeBiddingSave({
        onCompleted() {
            dispatch(pushMessage("Optimize Bidding Settings", "Optimize Bidding Settings Updated."));
            removeFromBuiltDomains(`${currentDomain}`);
        }
    })

    const SAVE_GENERAL_SETTINGS_MUTATION_CONTAINER = useGeneralDomainSettingsSave({
        onCompleted(dataUpdate) {
            dispatch(pushMessage("General Ad Settings", "General settings updated"));
            removeFromBuiltDomains(`${currentDomain}`);
            setSettingsData({ ...settingsData });
        }
    })

    const [originalSettings, setOriginalSettings] = useState({})
    const [settingsData, setSettingsData] = useState(null);
    const [overrideDisableHBGlobally, setOverrideDisableHBGlobally] = useState(false);
    const [disable_hb, setDisable_hb] = useState(false);
    const [inputList, setInputList] = useState(null);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [domainData, setDomainData] = useState({});
    const [presetData, setPresetData] = useState({});
    const [dataFetchComplete, setDataFetchComplete] = useState(false);
    const [presetInfo, setPresetInfo] = useState({});
    const [overrideTrafficShaping, setOverrideTrafficShaping] = useState(false)
    const [bidders, setBidders] = useState([])
    const [presetTrafficShapingRules, setPresetTrafficShapingRules] = useState([])
    const [rules, setRules] = useState([])
    const { toolTipTextWithAttributes: tooltip, toolTipText: helpText } = useAdgridToolTipManager('optimize_bidding');

    useEffect(() => {
        if (currentDomain && currentCustomer) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            })
        }
    }, [currentDomain, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
        let domainAdapterList = temp_data.adapter_list || []
        let presetAdapterList = temp_data.adapter_list_preset || []

        if (!Array.isArray(domainAdapterList)) {
            domainAdapterList = typeof domainAdapterList === 'object'
                ? Object.values(temp_data.adapter_list || [])
                : []
        }

        if (!Array.isArray(presetAdapterList)) {
            presetAdapterList = typeof presetAdapterList === 'object'
                ? Object.values(temp_data.adapter_list_preset || [])
                : []
        }

        let adapterListUnion = [...domainAdapterList, ...presetAdapterList].reduce((acc, curr) => {
            acc[curr] = true
            return acc
        }, {})

        if (temp_data.override_disable_hb_globally) {
            setOverrideDisableHBGlobally(true);
            setDisable_hb(temp_data.disable_hb_globally);
            temp_data.disable_hb_globally_by_country && setSelectedCountry(JSON.parse(temp_data.disable_hb_globally_by_country));
        } else {
            setDisable_hb(temp_data.disable_hb_globally_preset);
            temp_data.disable_hb_globally_by_country_preset && setSelectedCountry(JSON.parse(temp_data.disable_hb_globally_by_country_preset));
        }

        setSettingsData(temp_data);

        setBidders(Object.keys(adapterListUnion).map(adapterName => ({ name: adapterName, value: adapterName })))
        setOverrideTrafficShaping(!!temp_data.overridePresetTrafficShapingSettings)

        let presetRules = null
        let domainRules = null

        try {
            presetRules = !!temp_data.presetTrafficShapingSettings ? JSON.parse(temp_data.presetTrafficShapingSettings) : []
            presetRules = Array.isArray(presetRules.rules) ? presetRules.rules : []
            domainRules = !!temp_data.trafficShapingSettings ? JSON.parse(temp_data.trafficShapingSettings) : presetRules
            //console.log({domainTrafficShapingSettings: domainRules.trafficShapingSettings})
            domainRules = Array.isArray(domainRules.rules) ? domainRules.rules : presetRules
            setPresetTrafficShapingRules(presetRules)
            setRules(domainRules)
        } catch (err) {
            console.log(err)
        }

        setPresetInfo(temp_data.adv_preset)
        setShowCheckBox(temp_data.override_prebid_timeout);

        let domainData = { prebid_timeout: temp_data.prebid_timeout, split_prebid_timeout: temp_data.split_prebid_timeout, inview_prebid_timeout: temp_data.inview_prebid_timeout, outofview_prebid_timeout: temp_data.outofview_prebid_timeout }
        setDomainData(domainData);

        let presetData = { prebid_timeout: temp_data.global_prebid_timeout, split_prebid_timeout: temp_data.preset_split_prebid_timeout, inview_prebid_timeout: temp_data.preset_inview_prebid_timeout, outofview_prebid_timeout: temp_data.preset_outofview_prebid_timeout }

        setPresetData(presetData);
        setDataFetchComplete(true);

        setOriginalSettings({
            ...temp_data,
            inputList: temp_data.override_prebid_timeout ? domainData : presetData,
            overrideHeaderBidding: temp_data.override_disable_hb_globally,
            overrideTimeout: temp_data.override_prebid_timeout,
            overrideTrafficShaping: temp_data.overridePresetTrafficShapingSettings,
            rules: [...domainRules],
            presetTrafficShapingRules: [...presetRules]
        })
    };

    useEffect(() => {
        if (dataFetchComplete) {
            domainData.prebid_timeout = domainData.prebid_timeout ? domainData.prebid_timeout : presetData.prebid_timeout;
            domainData.split_prebid_timeout = domainData.split_prebid_timeout ? domainData.split_prebid_timeout : presetData.split_prebid_timeout;
            domainData.inview_prebid_timeout = domainData.inview_prebid_timeout ? domainData.inview_prebid_timeout : presetData.inview_prebid_timeout;
            domainData.outofview_prebid_timeout = domainData.outofview_prebid_timeout ? domainData.outofview_prebid_timeout : presetData.outofview_prebid_timeout;

            setInputList(showCheckBox ? domainData : presetData);
            setMutationDataCompleted(true);
        }
    }, [dataFetchComplete, showCheckBox]);

    const handleChange = (index, param, targetName = null) => {
        if (param.target !== undefined && param.target.name !== undefined) {
            let name = param.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            setInputList(temp);
            dispatch(registerScreenChanges("Optimize Bidding"));
        } else {
            let name = targetName !== null ? targetName : param.originalEvent.target.name,
                value = param.value,
                temp = Object.assign({}, inputList);
            temp[name] = value;
            dispatch(registerScreenChanges("Optimize Bidding"));
            setInputList(temp);
        }
    };

    const handleChangeHB = (params) => {

        let { target: { name, value } } = params;

        let temp = { ...settingsData };

        switch (name) {
            case "disable_hb_globally_by_country":
                setSelectedCountry(value);
                temp[name] = JSON.stringify(value);
                break;
            case "disable_hb_globally":
                setDisable_hb(value);
                temp[name] = value;
                break;
            case "override_disable_hb_globally":
                setOverrideDisableHBGlobally(value);
                if (!value) {
                    setDisable_hb(settingsData.disable_hb_globally_preset);
                    settingsData.disable_hb_globally_by_country_preset && setSelectedCountry(JSON.parse(settingsData.disable_hb_globally_by_country_preset));
                } else {
                    setDisable_hb(settingsData.disable_hb_globally);
                    settingsData.disable_hb_globally_by_country && setSelectedCountry(JSON.parse(settingsData.disable_hb_globally_by_country));
                }
                temp[name] = value;
                break;
            default:
                temp[name] = value;
        }

        setSettingsData(temp);
    }

    const saveOptimizeBiddingData = async () => {

        await SAVE_GENERAL_SETTINGS_MUTATION_CONTAINER({
            domainId: currentDomain,
            fromHBSettings: true,
            adDisableHBGlobally: settingsData.disable_hb_globally,
            adDisableHB_GloballyByCountries: settingsData.disable_hb_globally_by_country,
            overrideDisableHBGlobally: settingsData.override_disable_hb_globally,
        });

        await OPTIMIZE_BIDDING_UPDATE_MUTATION_CONTAINER({
            currentDomain,
            showCheckBox,
            optimizeBiddingList: JSON.stringify(inputList),
            overrideTrafficShapingSettings: overrideTrafficShaping,
            domainTrafficShapingSettings: JSON.stringify({
                rules,
                trafficShapingSettings: foldRules(overrideTrafficShaping ? rules : presetTrafficShapingRules)
            })
        });

        let originalSettingsCopy = deepcopy(originalSettings)
        let mutatedData = {
            ...settingsData,
            inputList,
            overrideHeaderBidding: overrideDisableHBGlobally,
            overrideTimeout: showCheckBox,
            overrideTrafficShaping: overrideTrafficShaping,
            rules: [...rules],
            presetTrafficShapingRules: [...presetTrafficShapingRules]
        }
        setOriginalSettings(mutatedData)

        return {
            url: window.location.pathname,
            user_id: String(userID),
            originalData: originalSettingsCopy,
            mutatedData,
            postId: String(currentDomain)
        }
    };

    return (
        <LayoutWrapper
            model="domain"
            saveCallback={async () => {
                if (!inputList.prebid_timeout) {
                    dispatch(pushMessage("Optimize Bidding Settings", "Global Prebid Timeout is required", "error"));
                    throw new Error("Global prebid timeout is required");
                } else if (!inputList.inview_prebid_timeout && inputList.split_prebid_timeout) {
                    dispatch(pushMessage("Optimize Bidding Settings", "In-View Prebid Timeout is required", "error"));
                    throw new Error("In-view prebid timeout is required");
                } else {
                    setDomainData(inputList);
                    return saveOptimizeBiddingData();
                }
            }}
        >
            <>
                {mutationDataCompleted && (
                    <div className="p-grid">
                        <div className="small-full-width p-col p-col-content">
                            <Messages ref={messages} />
                            <div className="mb-1">
                                <div className="p-panel p-component mb-1">
                                    <div className="p-datatable p-component p-datatable-gridlines">
                                        <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                            <div className="p-d-flex p-ai-center f-width">

                                                <InputSwitch
                                                    id="override_disable_hb_globally"
                                                    name="override_disable_hb_globally"
                                                    checked={settingsData.override_disable_hb_globally}
                                                    onChange={handleChangeHB}
                                                />

                                                <label>
                                                    <strong> &nbsp; Override Disable Header Bidding &nbsp;
                                                        <span
                                                            style={{
                                                                paddingBottom: "2px",
                                                                color: "#0388e5",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                dispatch(setCurrentPreset(presetInfo.code));
                                                                history.push("/presetoptimizebidding");
                                                            }}
                                                        >
                                                            [{presetInfo.name}]
                                                        </span>
                                                    </strong>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="p-panel p-component">
                                            <div className="p-panel-content">

                                                <div className="p-grid">
                                                    <div className="p-col-3 p-sm-none small-hide">
                                                        <label>
                                                            <strong>
                                                                Disable Header Bidding
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                        <div className="p-inputgroup p-fluid">
                                                            <InputSwitch
                                                                id="disable_hb_globally"
                                                                name="disable_hb_globally"
                                                                checked={disable_hb}
                                                                onChange={handleChangeHB}
                                                                disabled={!overrideDisableHBGlobally}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="p-grid">
                                                    <div className="p-col-3 p-sm-none small-hide">
                                                        <label className={overrideDisableHBGlobally === disable_hb ? "text-black" : "gray"}>
                                                            <strong>
                                                                <strong>Disable Country(ies)</strong>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                        <div className="p-inputgroup p-fluid">
                                                            <MultiSelect
                                                                disabled={!overrideDisableHBGlobally === disable_hb}
                                                                value={selectedCountry}
                                                                onChange={handleChangeHB}
                                                                name="disable_hb_globally_by_country"
                                                                options={COUNTRIES}
                                                                optionLabel="name"
                                                                placeholder="Select Country"
                                                                maxSelectedLabels={3}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-panel p-component">
                                    <SectionHeader>
                                        <div className="p-d-flex p-ai-center f-width">
                                            <InputSwitch
                                                checked={showCheckBox}
                                                onChange={() => {
                                                    dispatch(registerScreenChanges("Optimize Bidding"));
                                                    setShowCheckBox(!showCheckBox);
                                                }}
                                            />
                                            <Tooltip target=".adpositiontooltip" />

                                            <label
                                                {...tooltip('override_prebid_timeout', true)}
                                                htmlFor="prebid_timeout"
                                            >
                                                <strong>
                                                    &nbsp;Override Prebid Timeout&nbsp;
                                                    <span
                                                        style={{
                                                            paddingBottom: "2px",
                                                            color: "#0388e5",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            dispatch(setCurrentPreset(presetInfo.code));
                                                            history.push("/presetoptimizebidding");
                                                        }}
                                                    >
                                                        [{presetInfo.name}]
                                                    </span>
                                                </strong>
                                            </label>

                                        </div>
                                    </SectionHeader>
                                    <Tooltip target=".adpositiontooltip" />
                                    <div className="p-panel-content">
                                        <div className="p-grid">
                                            <div className="p-col-3 p-sm-none small-hide">
                                                <label
                                                    {...tooltip('global_prebid_timeout', true)}
                                                    htmlFor="prebid_timeout"
                                                >
                                                    <strong>
                                                        Global Prebid Timeout<span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="prebid_timeout" name="prebid_timeout" value={inputList.prebid_timeout} onChange={(e) => handleChange(null, e, "prebid_timeout")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid small-margin-top-10">
                                            <div className="p-col-3 p-sm-none small-hide">
                                                <label
                                                    {...tooltip('split_prebid_timeout', true)}
                                                    htmlFor="split_prebid_timeout"
                                                >
                                                    <strong>Split Prebid Timeout</strong>
                                                </label>
                                            </div>
                                            <div className="p-col-9 p-fluid">
                                                <div className="p-d-flex p-ai-center p-mb-2">
                                                    <InputSwitch name="split_prebid_timeout" checked={inputList.split_prebid_timeout} onChange={(e) => handleChange(null, e)} disabled={!showCheckBox} />
                                                    <label htmlFor="split_prebid_timeout" className="adpositiontooltip after p-ml-2 p-d-none small-show" data-pr-tooltip="When you want a bidder will want to split a winning bid they have placed with one or more other bidders.">
                                                        <strong>Split Prebid Timeout</strong>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('in-view_prebid_timeout', true)}
                                                    htmlFor="inview_prebid_timeout"
                                                >
                                                    <strong>
                                                        In-View Prebid Timeout <span className="color-red">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber inputId="inview_prebid_timeout" name="inview_prebid_timeout" value={inputList.inview_prebid_timeout} onChange={(e) => handleChange(null, e, "inview_prebid_timeout")} mode="decimal" min={0} max={99999999} disabled={!showCheckBox} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-grid">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2">
                                                <label
                                                    {...tooltip('out-of-view_prebid_timeout', true)}
                                                    htmlFor="outofview_prebid_timeout"
                                                >
                                                    <strong>Out-of-View Prebid Timeout</strong>
                                                </label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                <div className="p-inputgroup p-fluid">
                                                    <InputNumber
                                                        inputId="outofview_prebid_timeout"
                                                        name="outofview_prebid_timeout"
                                                        value={inputList.outofview_prebid_timeout}
                                                        onChange={(e) => handleChange(null, e, "outofview_prebid_timeout")}
                                                        mode="decimal"
                                                        min={0}
                                                        max={99999999}
                                                        disabled={!showCheckBox}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="p-panel p-component mb-1">
                                    <SectionHeader screenName="Optimize bidding">
                                        <div className="p-d-flex p-ai-center f-width">
                                            <InputSwitch
                                                checked={overrideTrafficShaping}
                                                onChange={() => {
                                                    dispatch(registerScreenChanges("Optimize Bidding"));
                                                    setOverrideTrafficShaping(!overrideTrafficShaping)
                                                }}
                                            />
                                            <Tooltip target=".adpositiontooltip" />

                                            <label
                                                {...tooltip('override_traffic_shaping_settings', true)}
                                                htmlFor="prebid_timeout"
                                            >
                                                <strong>
                                                    &nbsp;Override Traffic Shaping Settings&nbsp;
                                                    <span
                                                        style={{
                                                            paddingBottom: "2px",
                                                            color: "#0388e5",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            dispatch(setCurrentPreset(presetInfo.code));
                                                            history.push("/presetoptimizebidding");
                                                        }}
                                                    >
                                                        [{presetInfo.name}]
                                                    </span>
                                                </strong>
                                            </label>
                                        </div>
                                    </SectionHeader>
                                    <div className="p-panel-content">
                                        <TrafficShaping
                                            bidders={bidders}
                                            rules={overrideTrafficShaping ? rules : presetTrafficShapingRules}
                                            setRules={setRules}
                                            disableControls={!overrideTrafficShaping}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="small-full-width p-col p-col-sidebar">
                            <div className="p-panel p-component">
                                <SectionHeader>
                                    <div className="p-text-bold">Description</div>
                                </SectionHeader>
                                <div className="p-panel-content p-fluid">
                                    <h6 className="p-text-bold p-mb-0">Global Prebid Timeout</h6>
                                    <p>{helpText('help_text____global_prebid_timeout')}</p>
                                    <h6 className="p-text-bold p-mb-0">Split Prebid Timeout</h6>
                                    <p>{helpText('help_text____split_prebid_timeout')}</p>
                                    <h6 className="p-text-bold p-mb-0">In-View Prebid Timeout</h6>
                                    <p>{helpText('help_text____in-view_prebid_timeout')}</p>
                                    <h6 className="p-text-bold p-mb-0">Out-of-View Prebid Timeout</h6>
                                    <p>{helpText('help_text____out-of-view_prebid_timeout')}</p>
                                    <h6 className="p-text-bold p-mb-0">Override Traffic Shaping Settings</h6>
                                    <p>{helpText('help_text____override_traffic_shaping_settings')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </LayoutWrapper>
    );
};

export default HeaderBiddingSettings;
