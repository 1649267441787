import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "primereact/multiselect";
import { DISPLAY_SIZES, colors } from "./constants";

const sectionStyle = css`
    display: flex;
    .format {
        display: flex;
        background-color: #fff;
        align-items: center;
        border: 1px solid #ccc;
        width: fit-content;
        padding: 0.75em;
        border-radius: 6px;
        transition: all 0.25s;
        margin-right: 1em;
        .icon {
            margin-right: 2em;
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            box-shadow: 1px 2px 3px #ccc;
        }
        &.active {
            border: 2px solid #689f38;
        }
    }
`;

const sizesSectionStyles = css`
    margin-top: 1em;
    .p-component.p-multiselect {
        max-width: 480px;
    }
`

function Section({ wording, icon, onClick, iconColor, isActive }) {
    return (
        <div className={`${isActive ? 'active' : ''} format`} onClick={onClick}>
            <span className="icon">
                <FontAwesomeIcon icon={icon} fontSize={"5em"} color={iconColor} />
            </span>
            <div>
                <p>{wording}</p>
            </div>
        </div>
    );
}

export default function FormatSection({
    setOrderFormat,
    currentFormat,
    selectedSizes,
    setSelectedSizes,
    showChoices = true
}) {

    return (
        <div>
            {
                showChoices && <>
                    <p>
                        <strong>
                            What kind of ad do you want to serve?
                        </strong>
                    </p>
                    <section className="formats" css={sectionStyle}>
                        <Section isActive={currentFormat === 'display'} wording="Display" icon={faImage} onClick={() => setOrderFormat("display")} iconColor={colors.lightBlue} />
                        <Section isActive={currentFormat === 'video'} wording="Video" icon={faVideo} onClick={() => setOrderFormat("video")} iconColor={colors.salmon} />
                    </section>
                </>
            }
            {
                currentFormat === 'display'
                    ? (
                        <div css={sizesSectionStyles}>
                            <hr />
                            <div className="mb-1">
                                <strong>
                                    <span>
                                        Ad sizes
                                    </span>
                                </strong>
                            </div>
                            <MultiSelect
                                filter={true}
                                filterBy="name"
                                value={selectedSizes}
                                display="chip"
                                placeholder="Choose your ad sizes"
                                onChange={ev => {
                                    setSelectedSizes(ev.value)
                                }}
                                optionValue="value"
                                optionLabel="name"
                                options={DISPLAY_SIZES}
                            />
                        </div>
                    )
                    : null
            }
        </div>
    );
}
