import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import UploadAssetSection from "../creatives/UploadAssetSection";
import { decodeHtml, noop } from "../../../utilities/utils";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { DISPLAY_SIZES } from "./constants";

const IMPRESSIONS_OPTIONS = [
    { name: "Under 1MM", value: "Under 1MM" },
    { name: "1MM - 5MM", value: "1MM - 5MM" },
    { name: "5MM - 10MM", value: "5MM - 10MM" },
    { name: "10MM - 50MM", value: "10MM - 50MM" },
    { name: "50MM+", value: "50MM+" },
];

const OPTION_VALUES = new Set(IMPRESSIONS_OPTIONS.map((v) => v.value));

const DomainLogoUploadSection = ({ accept, isAssetSelected, filename, uploadAsset, loadAssetHandler }) => {
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                background-color: #eee;
                margin-top: 1em;
            `}
        >
            <div
                css={css`
                    position: relative;
                    flex-basis: 35%;
                    input {
                        opacity: 0;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        cursor: pointer;
                        z-index: 9999;
                        padding: 0 !important;
                        display: ${isAssetSelected ? "none" : "block"};
                    }
                    button {
                        border-radius: 0;
                        width: 100%;
                        cursor: pointer;
                        &.asset-selected {
                            background-color: #39c939;
                            border: 1px solid #39c939;
                        }
                    }
                `}
            >
                <input type="file" accept={accept} name="domain_logo" onChange={loadAssetHandler} />
                <Button
                    className={isAssetSelected ? "asset-selected" : ""}
                    label={isAssetSelected ? "Upload file" : "Choose file"}
                    onClick={() => {
                        isAssetSelected ? uploadAsset() : noop();
                    }}
                />
            </div>
            <div
                css={css`
                    flex-basis: 65%;
                    text-align: center;
                `}
            >
                {filename || "No file selected"}
            </div>
        </div>
    );
};

const Row = ({ label, children }) => {
    return (
        <div className="p-grid small-margin-bottom-10">
            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">{label}</div>
            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">{children && children}</div>
        </div>
    );
};

const MarketplaceDomainOverview = ({
    selectedMarketplaceNetworks,
    selectedDisplaySizes,
    onChange,
    suggestedMarketplaceNetworks,
    searchIndustries,
    currentCustomer,
    marketplaceDomainName,
    marketplaceDomainUrl,
    marketplaceImpressions,
    marketplaceDomainDescription,
    marketplaceMailList,
    marketplaceLogo,
    allowSafetag,
    allowVideo,
    safetagWords,
}) => {
    const [isUploadingAsset, setUploadState] = useState(false);
    
    return (
        <div>
            <Row label="Name *">
                <InputText
                    name="marketplace_domain_name"
                    placeholder="Name *"
                    required={true}
                    value={marketplaceDomainName}
                    onChange={(ev) => {
                        onChange({ target: { name: ev.target.name, value: ev.target.value } });
                    }}
                />
            </Row>
            <Row label="URL *">
                <InputText name="marketplace_domain_url" placeholder="URL *" required={true} value={marketplaceDomainUrl} onChange={onChange} />
            </Row>
            <Row label="Categories *">
                <AutoComplete
                    value={(selectedMarketplaceNetworks || []).map((network) => {
                        network.name = decodeHtml(network.name);
                        return network;
                    })}
                    suggestions={suggestedMarketplaceNetworks}
                    completeMethod={(ev) => {
                        searchIndustries(ev, "marketplace_network");
                    }}
                    field="name"
                    onChange={onChange}
                    multiple
                    dropdown
                    name="marketplace_network"
                />
            </Row>
            <Row label="Display sizes">
                <MultiSelect
                    value={selectedDisplaySizes}
                    options={DISPLAY_SIZES}
                    display="chip"
                    name="display_sizes"
                    optionLabel="name"
                    optionValue="value"
                    filter={true}
                    filterMatchMode="contains"
                    onChange={onChange}
                />
            </Row>
            <Row label="Description">
                <InputTextarea
                    name="marketplace_domain_description"
                    required={true}
                    placeholder="Description"
                    value={marketplaceDomainDescription}
                    rows={5}
                    onChange={(ev) => {
                        onChange({ target: { name: ev.target.name, value: ev.target.value } });
                    }}
                />
            </Row>
            <Row label="Notification List *">
                <InputTextarea
                    name="marketplace_mail_list"
                    required={false}
                    placeholder="Enter email addresses each on a new line"
                    value={marketplaceMailList || ""}
                    rows={5}
                    onChange={(ev) => {
                        onChange({ target: { name: ev.target.name, value: ev.target.value } });
                    }}
                />
            </Row>
            <Row label="Monthly impressions">
                <div className="control impressions">
                    <Dropdown
                        optionLabel="name"
                        optionValue="value"
                        name="marketplace_impressions"
                        value={OPTION_VALUES.has(marketplaceImpressions) ? marketplaceImpressions : IMPRESSIONS_OPTIONS[0].value}
                        options={IMPRESSIONS_OPTIONS}
                        onChange={(ev) => {
                            onChange({ target: { name: ev.target.name, value: ev.value } });
                        }}
                    />
                </div>
            </Row>
            <div className="p-grid small-margin-bottom-10">
                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            .label {
                                margin-left: 1em;
                            }
                        `}
                    >
                        <InputSwitch
                            name="enable_safetag"
                            checked={allowSafetag}
                            onChange={(ev) => {
                                onChange({ target: { name: ev.target.name, value: ev.value } });
                            }}
                        />
                        <span className="label">Supports SafeTag</span>
                    </div>
                </div>
                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-mt-2 small-margin-top-0">
                    <p>Safetag helps publishers meet brand safety requirements by scanning all posts for negative keyword lists provided by advertising partners.</p>
                </div>
            </div>
            <div className="p-grid small-margin-bottom-10">
                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            .label {
                                margin-left: 1em;
                            }
                        `}
                    >
                        <InputSwitch
                            name="allows_video"
                            checked={allowVideo}
                            onChange={(ev) => {
                                onChange({ target: { name: ev.target.name, value: ev.value } });
                            }}
                        />
                        <span className="label">Allows video</span>
                    </div>
                </div>
                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-mt-2 small-margin-top-0">
                    <p>Allow video campaign</p>
                </div>
            </div>

            <Row label="Logo">
                <>
                    <UploadAssetSection
                        accept="image/*"
                        currentCustomer={currentCustomer}
                        defaultExtension="image/jpeg"
                        onUploadComplete={(v) => {
                            onChange({ target: { name: "marketplace_domain_logo", value: v.image } });
                        }}
                        setUploadState={(uploadState) => setUploadState(uploadState)}
                        disabled={isUploadingAsset}
                        render={(props) => <DomainLogoUploadSection {...props} />}
                    />
                    {marketplaceLogo && (
                        <div className="logo-container" style={{ width: "100px", height: "100px", marginTop: "1em" }}>
                            <img src={marketplaceLogo} style={{ width: "100%", height: "auto" }} />
                        </div>
                    )}
                </>
            </Row>
        </div>
    );
};

export default MarketplaceDomainOverview;
