/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MarketplacePreviewLink = ({ creative }) => {
    const [previewUrl, setPreviewUrl] = useState("");

    useEffect(() => {
        setPreviewUrl(creative?.previewUrl)
    }, []);

    return previewUrl ? (
        <div
            css={css`
                font-size: 1.25em;
                span {
                    display: inline-block;
                    margin-left: 1em;
                }
            `}
        >
            <a href={previewUrl} target="_blank" title="Open preview link" rel="noreferrer">
                <FontAwesomeIcon icon={faLink} fontSize="1.25em" />
                <span>Open preview link</span>
            </a>
        </div>
    ) : null;
};

export default MarketplacePreviewLink;
