import { gql, useMutation } from "@apollo/client";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetCurrentDomain, resetCurrentPreset, setCurrentCustomer, setCurrentDomain, setDomainsToCustomersMappings, setNavigationMode } from "../reducers/userLoginReducer";
import { getIn } from "../utilities/utils";
import Client from "./Client";
import Partner from "./Partner";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts-data")(Highcharts);

Highcharts.setOptions({
    lang: {
        decimalPoint: ".",
        thousandsSep: ",",
    },
});

// MoM chart
const revenueLine = (chartData, leftText, domainId) => ({
    chart: {
        type: "line",
    },
    title: {
        text: "",
    },
    yAxis: [
        {
            title: { text: leftText },
            type: domainId !== "all" ? "logarithmic" : "",
        },
    ],
    plotOptions: {
        column: {
            stacking: domainId === "all" ? "normal" : "",
            dataLabels: {
                enabled: true,
            },
        },
    },
    data: {
        rows: chartData,
    },
});

const DOMAIN_MUTATION = gql`
    mutation callDomainInfoMutation($id: String, $userId: String, $adminRole: Boolean) {
        domainInfoMutation(input: { id: $id, userId: $userId, adminRole: $adminRole }) {
            clientMutationId
            customerInfo
        }
    }
`;

let assignedClients;

try {
    assignedClients = JSON.parse(localStorage.getItem("assignedClients"));
    assignedClients = assignedClients ? assignedClients : [];
} catch (err) {
    assignedClients = [];
}

export const Dashboard = (props) => {
    const originalFetch = require("isomorphic-fetch");
    var fetch = require("fetch-retry")(originalFetch, {
        retries: 10,
        retryDelay: 1000,
    });

    let today = new Date();
    let prevDate = today.setDate(today.getDate() - 7);
    today = new Date();
    let nextDate = today.setDate(today.getDate() - 1);
    const [currentDateRange, onChange] = useState([new Date(prevDate), new Date(nextDate)]);
    const [reportingData, setReportingData] = useState(null);
    const [gaData, setGaData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [selectedYear, setSeletedYear] = useState("2023");
    const [selectedDomain, setSelectedDomain] = useState("all");
    const [summaryData, setSummaryData] = useState(null);
    const [domainIds, setDomainIds] = useState(null);
    const [isMoMVisible, setIsMoMVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [domains, setDomains] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch();

    let leftText = "Estimated Revenue ($USD)";
    if (selectedDomain === "all") {
        leftText = "Estimated Impression";
    }
    const momRevenue = revenueLine(chartData, leftText, selectedDomain);

    const [DOMAIN_MUTATION_CONTAINER] = useMutation(DOMAIN_MUTATION, {
        onCompleted(data) {
            const domainsData = getIn(data, "domainInfoMutation", "customerInfo");
            confirm(domainsData ? JSON.parse(domainsData) : []);
        },
    });

    const { domainsToCustomersMappings, currentCustomer, userID, isAdmin } = useSelector((state) => state.userInformation);
    const API_URL = process.env.REACT_APP_REPORTING_API_URL;

    useEffect(() => {
        dispatch(resetCurrentDomain());
        dispatch(resetCurrentPreset());
        dispatch(setNavigationMode("domain"));
        localStorage.removeItem("currentDomain");

        if (assignedClients.length < 2) {
            DOMAIN_MUTATION_CONTAINER({
                variables: {
                    id: `${currentCustomer}` || localStorage.getItem("clientID"),
                    userId: userID,
                    adminRole: localStorage.getItem("admin_role") === "true",
                },
            });
        }

        // reading the correct date, due to timezone issue
        let startDate = new Date(currentDateRange[0]);
        let endDate = new Date(currentDateRange[1]);

        var tempDate = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        startDate = new Date(tempDate);

        let startMonth = startDate.getUTCMonth() + 1;
        if (startMonth < 10) startMonth = "0" + startMonth;

        let startDay = startDate.getUTCDate();
        if (startDay < 10) startDay = "0" + startDay;

        tempDate = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        endDate = new Date(tempDate);

        let endMonth = endDate.getUTCMonth() + 1;

        if (endMonth < 10) endMonth = "0" + endMonth;

        let endDay = endDate.getUTCDate();
        if (endDay < 10) endDay = "0" + endDay;

        startDate = startDate.getUTCFullYear() + "-" + startMonth + "-" + startDay;
        endDate = endDate.getUTCFullYear() + "-" + endMonth + "-" + endDay;

        // is admin check
        let role = localStorage.getItem("role");

        setSpinner(true);
        fetch(API_URL + "/getDashboardReport/?start=" + startDate + "&end=" + endDate + "&isAdmin=" + role + "&domainIds=" + domainIds, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                const cid = parseInt(currentCustomer || localStorage.getItem("clientID"));
                const dtMappings = {};
                let dataPoints = [];

                if (isAdmin || role === "administrator") {
                    dataPoints = response.table;
                } else {
                    response.table.forEach((domainInfo) => {
                        const { domain, customerId, domain_id: domainId, totalRevenue } = domainInfo;
                        dtMappings[domain] = { customerId, domainId };
                        if (customerId === cid) {
                            dataPoints.push(domainInfo);
                        }
                    });
                }
                dispatch(setDomainsToCustomersMappings(dtMappings));
                setReportingData(dataPoints);
                setSummaryData(response.total);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentDateRange]);

    useEffect(() => {
        if (assignedClients.length >= 2) {
            (async () => {
                let ids = [];
                for (let i = 0; i < assignedClients.length; i++) {
                    const response = await DOMAIN_MUTATION_CONTAINER({
                        variables: {
                            id: assignedClients[i],
                            userId: userID,
                            adminRole: localStorage.getItem("admin_role") === "true",
                        },
                    });
                    const domainsData = JSON.parse(getIn(response, "data", "domainInfoMutation", "customerInfo"));
                    domainsData.forEach((d) => ids.push(d.id));
                }
                setDomainIds(ids.join());
            })();
        }
    }, []);

    useEffect(() => {
        fetch(API_URL + "/getMoMData/" + selectedDomain + "/?year=" + selectedYear, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                setGaData(response);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(API_URL + "/getMoMChart/" + selectedDomain + "/?year=" + selectedYear, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                setChartData(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [selectedYear, selectedDomain]);

    useEffect(() => {
        fetch(API_URL + "/getDomainList/" + currentCustomer, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((response) => {
                let domain_list = [{ label: "Total Network", value: "all" }];
                if (response.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        let item = { label: response[i].name, value: response[i].domainId };
                        domain_list.push(item);
                    }
                    setDomains(domain_list);
                    setIsMoMVisible(false);
                } else {
                    setIsMoMVisible(true);
                    setActiveIndex(0);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentCustomer]);

    const confirm = (domainsData) => {
        const domainIds = domainsData.map((d) => d.id);
        setDomainIds(domainIds.join());
    };

    const domainTemplate = (rowData) => {
        let url = "";

        if (rowData.domain_id) url = "/reports";

        return (
            <React.Fragment>
                <span className="tb-large">
                    <Link
                        onClick={(ev) => {
                            const domain = ev.target.textContent;
                            const { domainId, customerId } = domainsToCustomersMappings[domain];
                            dispatch(setNavigationMode("domain"));
                            dispatch(setCurrentCustomer(`${customerId}`));
                            dispatch(setCurrentDomain(`${domainId}`));
                        }}
                        to={url}
                    >
                        {rowData.domain}
                    </Link>
                </span>
            </React.Fragment>
        );
    };

    const years = [
        { label: "2023", value: "2023" },
        { label: "2022", value: "2022" },
        { label: "2021", value: "2021" },
    ];

    const revenueTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="tb-large">$ {rowData.totalRevenue.toLocaleString()}</span>
                <br />
                <span style={rowData.revenue_trend > 0 ? { color: "#006837" } : { color: "#ff0000" }} className="tb-small text-12">
                    {" "}
                    {rowData.revenue_trend > 0 ? "+" + rowData.revenue_trend : rowData.revenue_trend}%{" "}
                </span>
            </React.Fragment>
        );
    };

    const impressionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="tb-large">{rowData.impression.toLocaleString()}</span>
                <br />
                <span style={rowData.impression_trend > 0 ? { color: "#006837" } : { color: "#ff0000" }} className="tb-small text-12">
                    {" "}
                    {rowData.impression_trend > 0 ? "+" + rowData.impression_trend : rowData.impression_trend}%{" "}
                </span>
            </React.Fragment>
        );
    };

    const cpmTemplate = (rowData) => {
        let display = "none";
        let color = "green";

        if (rowData.cpm_trend > 0) {
            display = "";
        } else if (rowData.cpm_trend < 0) {
            display = "";
            color = "red";
        }

        return (
            <React.Fragment>
                <span className="tb-large">{rowData.cpm ? "$ " + rowData.cpm : ""}</span>
                <br />
                <span style={{ display: display, color: color }} className="tb-small text-12">
                    {" "}
                    {rowData.cpm_trend > 0 ? "+" + rowData.cpm_trend + "%" : rowData.cpm_trend + "%"}{" "}
                </span>
            </React.Fragment>
        );
    };

    const topBidderTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="tb-large">{rowData.topBidder}</span>
                <br />
                <span style={{ color: "#898989" }} className="tb-small text-12">
                    {" "}
                    {rowData.oldTopBidder}{" "}
                </span>
            </React.Fragment>
        );
    };

    const topBidderRevenueTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="tb-large">{rowData.topBidderByRevenue !== undefined ? "$ " + rowData.topBidderByRevenue.toLocaleString() : ""}</span>
            </React.Fragment>
        );
    };

    const bidWonTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="tb-large">{rowData.win_rate}</span>
            </React.Fragment>
        );
    };

    return (
        <>
            {localStorage.getItem("role") === "client" ? <Client {...props} /> : <Partner {...props} />}

            <div className="layout-main">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Overview">
                        {/* <div className="p-grid p-fluid dashboard">
                            <div className="p-col-12 p-lg-4">
                                <div className="card summary font_handlee p-text-center">
                                    <div className="title">{summaryData ? summaryData.monthly_text : ""}</div>
                                    <div className="count visitors font-weight-700">
                                        $ {summaryData ? parseInt(summaryData.monthly).toLocaleString() : 0.0} <span className="text-12 font-weight-400 color-d-green p-ml-1 p-d-none"> + 24%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-4">
                                <div className="card summary font_handlee p-text-center">
                                    <div className="title">{summaryData ? summaryData.yearly_text : ""}</div>
                                    <div className="count visitors font-weight-700">
                                        $ {summaryData ? parseInt(summaryData.yearly).toLocaleString() : 0.0} <span className="text-12 font-weight-400 color-d-green p-ml-1 p-d-none"> + 19%</span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4">
                                <div className="card summary font_handlee p-text-center">
                                    <div className="has-picker p-mb-2">
                                        <span className="title">
                                            {" "}
                                            <DateRangePicker id="datePicker" onChange={onChange} value={currentDateRange ? currentDateRange : ""} />{" "}
                                        </span>
                                        <span className="detail"></span>
                                    </div>
                                    <div className="count visitors font-weight-700">
                                        $ {summaryData ? parseInt(summaryData.revenue).toLocaleString() : 0.0} <span className="text-12 font-weight-400 color-d-green p-ml-1 p-d-none"> + 12%</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div>
                            <div className="p-mb-2">Numbers reflect programmatic bid amounts only. Some auctions may have been replaced with direct buy ads resulting in a different final total.</div>
                            {spinner ? (
                                <ProgressSpinner />
                            ) : (
                                <div className="card d-text-right d-table-wrapper">
                                    <DataTable value={reportingData}>
                                        <Column field="domain" header="Domain" body={domainTemplate}></Column>
                                        <Column field="totalRevenue" header="Revenue" body={revenueTemplate}></Column>
                                        <Column field="impression" header="Impression" body={impressionTemplate}></Column>
                                        <Column field="cpm" header="eCPM" body={cpmTemplate}></Column>
                                        <Column field="topBidder" className="align-left" header="Top Bidder" body={topBidderTemplate}></Column>
                                        <Column field="topBidderByRevenue" header="Revenue" body={topBidderRevenueTemplate}></Column>
                                        <Column field="win_rate" header="% Win " body={bidWonTemplate}></Column>
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel disabled={isMoMVisible} header="Month to Month">
                        <div className="p-grid p-fluid p-justify-between">
                            <div className="p-col-12 p-lg-2">
                                Select Domain:
                                <Dropdown value={selectedDomain} options={domains} onChange={(e) => setSelectedDomain(e.value)} optionLabel="label" />
                            </div>
                            <div className="p-col-12 p-lg-2">
                                Select Year:
                                <Dropdown value={selectedYear} options={years} onChange={(e) => setSeletedYear(e.value)} optionLabel="label" />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-12">
                                <HighchartsReact highcharts={Highcharts} options={momRevenue} immutable={true} />
                            </div>
                        </div>

                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-12">
                                <table style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey"></th>
                                            <th colspan="6" style={{ padding: "20px" }} bgcolor="lightgrey">
                                                {" "}
                                                Key Matrix{" "}
                                            </th>
                                            <th colspan="3" style={{ padding: "20px" }} bgcolor="lightgrey">
                                                {" "}
                                                Traffic{" "}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Month - Year
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Total Impression
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Total Revenue
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                eCPM
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                RPM
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                RPM (Unique Visitors)
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                RPM (Sessions)
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Total PageView
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Unique Visitors
                                            </th>
                                            <th style={{ padding: "20px" }} bgcolor="lightgrey">
                                                Sessions
                                            </th>
                                        </tr>
                                    </thead>

                                    {gaData
                                        ? gaData.map((value, key) => {
                                              let rowLead = "lightgrey";
                                              let color = "";
                                              if (value.class === "summary") {
                                                  color = "silver";
                                                  rowLead = "silver";
                                              } else if (value.class === "last2") {
                                                  color = "whitesmoke";
                                              } else if (value.class === "forecast") {
                                                  color = "lightyellow";
                                              }

                                              return (
                                                  <tbody key={key}>
                                                      <tr style={{ "background-color": color }}>
                                                          <td bgcolor={rowLead} style={{ padding: "20px" }}>
                                                              {value.month_year_text}{" "}
                                                          </td>
                                                          <td style={{ padding: "20px" }}>{value.impression.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.revenue.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.ecpm.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.rpm.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.rpm_unique.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.rpm_session.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.page_view.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.unique_visitors.toLocaleString()} </td>
                                                          <td style={{ padding: "20px" }}>{value.sessions.toLocaleString()} </td>
                                                      </tr>
                                                  </tbody>
                                              );
                                          })
                                        : ""}
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
};
