import React from 'react'
import AssetInputOrUploadSection from './AssetInputOrUploadSection'
import { CREATIVE_NAMES, CUBE_AD_FIELDS } from './definitions'
import DropdownSection from './DropdownSection'
import InputTextSection from './InputTextSection'
import BaseCreativeControls from './BaseCreativeControls'

const CREATIVE_TYPE = CREATIVE_NAMES.CUBE

const types = [
    { label: 'Image Upload/URL', value: 'display' },
    { label: 'Video Upload/URL', value: 'video' },
    { label: 'VAST Tag', value: 'vast' }
]

export default function Cube({
    side1,
    side2,
    side3,
    side4,
    vast1Duration,
    vast2Duration,
    vast3Duration,
    vast4Duration,
    side1Type,
    side2Type,
    side3Type,
    side4Type,
    container,
    autoRotate,
    autoRotateInterval,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {

    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                creativeType={CREATIVE_TYPE}
                impressionTag={impressionTag}
            >
                {
                    [
                        [side1, vast1Duration, side1Type],
                        [side2, vast2Duration, side2Type],
                        [side3, vast3Duration, side3Type],
                        [side4, vast4Duration, side4Type]
                    ]
                        .map((side, sideIdx) => {
                            let [value, duration, type] = side
                            let faceNumber = sideIdx + 1
                            let control = null
                            if (type === 'display' || type === 'video') {
                                control = (
                                    <AssetInputOrUploadSection
                                        inputValue={value}
                                        inputLabel={`Cube side ${faceNumber} creative`}
                                        inputName={CUBE_AD_FIELDS['side' + faceNumber]}
                                        inputChangeHandler={handleChange}
                                        inputAdtype={CREATIVE_TYPE}
                                        isRequired={true}
                                        currentCustomer={currentCustomer}
                                        accept={type === 'display' ? 'image/*' : 'video/*'}
                                        defaultExtension={type === 'display' ? "jpeg" : 'mp4'}
                                    />
                                )
                            } else if (type === 'vast') {
                                control = (
                                    <div style={{
                                        backgroundColor: '#e6edf2',
                                        padding: '6px',
                                        margin: '10px 0'
                                    }}>
                                        <InputTextSection
                                            label={`Vast Tag #${faceNumber}`}
                                            name={CUBE_AD_FIELDS['side' + faceNumber]}
                                            value={value}
                                            changeHandler={handleChange}
                                            adtype={CREATIVE_TYPE}
                                            required={true}
                                        />
                                        <InputTextSection
                                            label={`Approx. duration`}
                                            name={CUBE_AD_FIELDS['vast' + faceNumber + 'Duration']}
                                            value={duration}
                                            changeHandler={handleChange}
                                            adtype={CREATIVE_TYPE}
                                            required={false}
                                        />
                                    </div>
                                )
                            }
                            return (
                                <div key={faceNumber}>
                                    <DropdownSection
                                        label={
                                            <p>
                                                Side {faceNumber} creative type <span style={{ color: '#ff0000' }}>*</span>
                                            </p>
                                        }
                                        options={types}
                                        name={CUBE_AD_FIELDS['side' + faceNumber + 'Type']}
                                        value={type}
                                        changeHandler={handleChange}
                                        adtype={CREATIVE_TYPE}
                                    />
                                    {control}
                                    <hr />
                                </div>
                            )
                        })
                }
                <InputTextSection
                    label="Cube HTML container selector/wrapper (optional)"
                    name={CUBE_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                />
                <DropdownSection
                    label="Auto-rotate cube?"
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false }
                    ]}
                    name={CUBE_AD_FIELDS.autoRotate}
                    value={autoRotate}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                {
                    autoRotate &&
                    <InputTextSection
                        label="Interval between cube rotations (in milliseconds)"
                        name={CUBE_AD_FIELDS.autoRotateInterval}
                        value={autoRotateInterval}
                        changeHandler={handleChange}
                        adtype={CREATIVE_TYPE}
                        isNumericInput={true}
                        min={1000}
                        max={10_000}
                        required={false}
                    />
                }
            </BaseCreativeControls>
        </>
    )
}
