/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import OrderRowPanelSelector from "./OrderRowPanelSelector";
import { DEVICE_TARGETING_OPTIONS } from "./constants";

function TargetDevice({ deviceObject, handleSelect, handleRemove, renderSelectControl = true, renderRemoveControl = true }) {
    return (
        <div
            className="flex"
            css={css`
                align-items: center;
                margin-top: 0.5em;
                .device-category-name {
                    min-width: 150px;
                    margin-top: 0.35em;
                    margin-bottom: 0.35em;
                }
                .device-controls {
                    margin-left: 5em;
                    .pi {
                        border: 1px solid #939393;
                        padding: 2px;
                        box-shadow: 1px 1px 3px #ccc;
                        cursor: pointer;
                        &:hover {
                            color: #4e4eea;
                            border-color: #4e4eea;
                        }
                    }
                }
                &:hover {
                    background-color: #f4f4f4;
                    .device-controls {
                        display: block !important;
                    }
                }
            `}
        >
            <div className="mb-0 device-category-name">
                <i className={`pi ${deviceObject.icon}`}></i>
                <span className="p-m-1">{deviceObject.name}</span>
            </div>
            <div className="device-controls d-none">
                {renderSelectControl && <span className="pi pi-check" onClick={handleSelect}></span>}
                &nbsp;&nbsp;&nbsp;
                {renderRemoveControl && <span className="pi pi-ban" onClick={handleRemove}></span>}
            </div>
        </div>
    );
}

const DeviceTargeting = ({
    handleSelection,
    orderRowName,
    orderRowLabel,
    orderRowDescription,
    disabled = false
}) => {
    return (
        <OrderRowPanelSelector
            sourceData={DEVICE_TARGETING_OPTIONS}
            handleSelection={handleSelection}
            orderRowName={orderRowName}
            orderRowLabel={orderRowLabel}
            orderRowDescription={orderRowDescription}
            ItemTemplate={TargetDevice}
            disabled={disabled}
        />
    );
};

export default DeviceTargeting;
