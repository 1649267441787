import { gql, useMutation } from '@apollo/client';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { pushMessage } from '../../reducers/messagingReducer';
import { resetCurrentDomain, resetCurrentPreset, setActiveMenuIndex, setAvailablePresets, setCurrentPreset, setNavigationMode } from '../../reducers/userLoginReducer';
import SelectOptionNotice from '../../SelectOptionNotice';
import { getIn } from '../../utilities/utils';
import Client from '../Client';

const PRESET_MUTATION = gql`
  mutation callPresetInfoMutation($id: String) {
    presetInfoMutation(input: {id: $id}) {
        presetInfo
    }
  }
`

const PRESET_SAVE_MUTATION = gql`
  mutation callSaveNewPresetMutation($clientId: String, $presetName: String) {
    saveNewPresetMutation(input: {clientId: $clientId, presetName: $presetName}) {
      success
      message
    }
  }
`

const GeneralPresetConf = (props) => {
  const history = useHistory()
  const [presetInfo, setPresetInfo] = useState(null);
  const [presetName, setPresetName] = useState('');
  const [mutationDataCompleted, setMutationDataCompleted] = useState({ completed: false });
  const messages = useRef(null);
  const { isAdmin, currentCustomer } = useSelector(state => state.userInformation)
  const dispatch = useDispatch()
  const [PRESET_MUTATION_CONTAINER, { loading: mutationLoading, data }] = useMutation(
    PRESET_MUTATION,
    {
      onCompleted(data) {
        confirm(data);
      }
    }
  )

  const [PRESET_SAVE_MUTATION_CONTAINER, { dataUpdate }] = useMutation(
    PRESET_SAVE_MUTATION,
    {
      onCompleted(dataUpdate) {
        confirmUpdate(dataUpdate);
      }
    }
  )

  useEffect(() => {
    dispatch(setActiveMenuIndex(0))
    dispatch(setNavigationMode('preset'))
    dispatch(resetCurrentDomain())
    dispatch(resetCurrentPreset())
    if (currentCustomer) {
      PRESET_MUTATION_CONTAINER({
        variables: { id: currentCustomer }
      })
    }
    return () => dispatch(setNavigationMode('domain'))
  }, [currentCustomer])

  const confirm = data => {
    const presetsInfo = JSON.parse(getIn(data, 'presetInfoMutation', 'presetInfo'))
    const info = presetsInfo.presetInfo
    setPresetInfo(info)
    const presets = info.reduce((acc, curr) => {
      const { title: name, id: value } = curr
      acc.push({ name, value })
      return acc
    }, [])

    dispatch(setAvailablePresets(presets))
    setMutationDataCompleted({ completed: true })
  }

  const confirmUpdate = async data => {
    const presetSaved = getIn(data, 'saveNewPresetMutation', 'success')
    const sms = getIn(data, 'saveNewPresetMutation', 'message')

    presetSaved === 'true'
      ? dispatch(pushMessage('Preset Configuration', 'A new preset was added'))
      : dispatch(pushMessage('Preset Configuration', "New Domain Info Update Failed. Reason: " + sms, 'error'))
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions p-text-right">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
      </div>
    );
  }

  const editProduct = (product) => {
    dispatch(setCurrentPreset(product.id))
    history.push('/presetsettings')
  }

  const saveNewPresetInfo = async () => {
    if (presetName === '' || presetName === null) {
      dispatch(pushMessage('Preset Configuration', 'You need to enter a name for the preset', 'error'))
    } else {
      setPresetName('')
      await PRESET_SAVE_MUTATION_CONTAINER({
        variables: { clientId: currentCustomer, presetName: presetName }
      })
      await PRESET_MUTATION_CONTAINER({
        variables: { id: currentCustomer }
      })
    }
  }

  const idBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">ID</span>
        {rowData.id}
      </React.Fragment>
    );
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.title}
      </React.Fragment>
    );
  }

  return (
    <>
      <Client {...props} />
      <div className="layout-main">
        {
          isAdmin && !currentCustomer ?
            <div className="no-preset-selected">
              <SelectOptionNotice optionName="customer" />
            </div> :
            <>
              <div className="p-col-12 p-md-12 p-lg-12 contact-form">
                <div className="p-grid crud-demo">
                  <div className="p-col-12 p-md-12 p-lg-12 contact-form">
                    {presetInfo && mutationDataCompleted.completed && (
                      <div className="datatable-responsive">
                        <DataTable value={presetInfo} className="p-datatable-responsive p-datatable-striped">
                          <Column className='p-d-none' field="id" header="ID" body={idBodyTemplate}></Column>
                          <Column field="title" header="Name" body={nameBodyTemplate}></Column>
                          <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                      </div>
                    )}
                    {mutationDataCompleted.completed && (
                      <div className="p-mt-3">
                        <Messages ref={messages} />
                        <Accordion className="p-mt-2">
                          <AccordionTab header="Add New Preset">
                            <div className="">
                              <div className="p-panel-header p-mb-3">
                                <div className="p-text-bold text-left">Enter Preset Details Below to Create New Preset</div>
                                <Button type="button" label="Save" icon="pi pi-envelope" onClick={async () => await saveNewPresetInfo()} />
                              </div>

                              <div className="p-panel-content p-fluid">
                                <div className="p-grid small-margin-bottom-10">
                                  <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                    <label htmlFor="preset_name">Preset Name</label>
                                  </div>
                                  <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                    <InputText id="preset_name" name="preset_name" value={presetName} onChange={(e) => { setPresetName(e.target.value) }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionTab>
                        </Accordion>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
}

export default GeneralPresetConf
