import React, { useEffect, useRef, useState } from "react";
/** @jsxImportSource @emotion/react */
import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import { Carousel } from "primereact/carousel";
import { useDispatch } from "react-redux";
import { pushMessage } from "../../../reducers/messagingReducer";
import DomainStatusTable from "./DomainStatusTable";
import { CUSTOMER_MARKETPLACE_ORDER_CREATION, CUSTOMER_MARKETPLACE_VIDEO_ORDER_CREATION, LINEITEM_ACTIONS, MARKETPLACE_ACTION_MUTATION, MARKETPLACE_DOMAIN_STATUS, MARKETPLACE_ORDERS_REVIEW_MUTATION } from "./constants";
import "brace/mode/plain_text";
import { Button } from "primereact/button";
import MarketplacePreviewLink from "./MarketplacePreviewLink";

const orderDataStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    .info {
        flex-grow: 1;
        text-align: center;
    }
`;

const styles = css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    .ops,
    .assigned-domains {
        width: 70%;
    }
    .ops {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        .op {
            width: 82px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .w {
                font-weight: 700;
            }
        }
        p {
            margin: 0;
        }
        .box {
            width: 15px;
            height: 15px;
            border: 1px solid #ccc;
            display: block;
            margin: 0 auto;
        }
    }
    .domains {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .assigned-domains {
        border: 2px solid #ccc;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
        .checkbox {
            display: inline-block;
            width: 80px;
            float: right;
            .p-checkbox {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .submit {
        margin-top: 2em;
        .p-button {
            display: block;
            margin: 0 auto;
            width: 100px;
        }
    }
`;

const DomainReviewOverlay = ({ marketplaceOrder = null, currentlyAssignedDomains = {}, submitHandler, customerId, customerName, domainToNetworkMappings }) => {
    let impressions = (marketplaceOrder?.impressionGoals || 0).toLocaleString();
    let startDate = new Date(marketplaceOrder.startDate);
    let endDate = new Date(marketplaceOrder.endDate);
    const [domainsToReview, setDomainsToReview] = useState([]);
    const [affectedDomains, setAffectedDomains] = useState({});
    const [marketplaceOrderId, setMarketplaceOrderId] = useState(null);
    const [updateMarketplaceReviewStatusMutation] = useMutation(MARKETPLACE_ORDERS_REVIEW_MUTATION);
    const [createMarketplaceOrder] = useMutation(CUSTOMER_MARKETPLACE_ORDER_CREATION);
    const [createVideoMarketplaceOrder] = useMutation(CUSTOMER_MARKETPLACE_VIDEO_ORDER_CREATION);
    const creativesRef = useRef(null);
    const [showCreativesDemo, setShowCreativesDemo] = useState(false);

    const [marketplaceOrderActionMutation] = useMutation(MARKETPLACE_ACTION_MUTATION);
    const dispatch = useDispatch();

    useEffect(() => {
        if (marketplaceOrder?.networks?.length) {
            setDomainsToReview(marketplaceOrder.networks.filter((networkId) => networkId in currentlyAssignedDomains));
        }
    }, [marketplaceOrder]);

    useEffect(() => {
        if (marketplaceOrder) {
            setAffectedDomains(marketplaceOrder?.networkStatuses || {});
            setMarketplaceOrderId(marketplaceOrder.wpMarketplacePostId);
        }
    }, [marketplaceOrder]);

    useEffect(checkDomainStatuses, [affectedDomains]);

    function checkDomainStatuses() {
        let totalDomains = domainsToReview.length;
        let approved = 0;
        let rejected = 0;
        if (!Object.keys(affectedDomains).length) return;
        domainsToReview.forEach((domain) => {
            let status = affectedDomains[domain].status;
            if (status === MARKETPLACE_DOMAIN_STATUS.APPROVED) {
                ++approved;
            } else if (status === MARKETPLACE_DOMAIN_STATUS.REJECTED) {
                ++rejected;
            }
        });
    }

    function domainSelectionHandler({ domainId, status }) {
        if (domainId in affectedDomains) {
            setAffectedDomains({ ...affectedDomains, [domainId]: { status } });
        }
    }

    function domainApprovalHandler(domain) {
        domainSelectionHandler({ domainId: domain, status: MARKETPLACE_DOMAIN_STATUS.APPROVED });
    }

    function domainRejectionHandler({ domain }) {
        domainSelectionHandler({ domainId: domain, status: MARKETPLACE_DOMAIN_STATUS.REJECTED });
    }

    function modifyLineItemState(action, networks) {
        /* 
            networks is an object with shape: 
            {gamNetworkCode: [lineItemId, lineItemId2, ...]}
        */
        return marketplaceOrderActionMutation({
            variables: {
                networks,
                action,
            },
        });
    }

    const modifyCustomerLineItem = async (domain, status, action) => {
        try {
            let gamNetworkCode = String(domainToNetworkMappings[domain]?.networkCode);
            let lineItemId = String(marketplaceOrder?.domainToLineItemsMappings[domain]);

            let marketplaceReviewStatusVariables = {
                domainIds: JSON.stringify([String(domain)]),
                domainStatus: status,
                marketplace_order_id: String(marketplaceOrderId),
            };

            await modifyLineItemState(action, JSON.stringify({ [gamNetworkCode]: [lineItemId] }));
            let reviewResponse = await updateMarketplaceReviewStatusMutation({ variables: marketplaceReviewStatusVariables });
            let data = JSON.parse(reviewResponse.data.createCustomerMarketplaceDomainReviewMutation.domainReviewStatus);
            let newDomainStatuses = JSON.parse(data.networkStatuses);
            dispatch(pushMessage("Marketplace", "Order status has been updated"));
            setAffectedDomains(newDomainStatuses);
            submitHandler({ ...marketplaceOrder, networkStatuses: newDomainStatuses });
            return newDomainStatuses;
        } catch (err) {
            dispatch(pushMessage("Marketplace", err.message, "error"));
        }
    };

    const submitMarketplaceOrderStatus = async (domainId, status) => {
        let approvedDomain = status === MARKETPLACE_DOMAIN_STATUS.APPROVED ? domainId : null;
        let marketplaceReviewStatusVariables = {
            domainIds: JSON.stringify([String(domainId)]),
            marketplace_order_id: String(marketplaceOrderId),
        };
        let marketplaceOrderCreationVariables = {
            id: String(customerId),
            marketplacePostID: String(marketplaceOrderId),
            domainId: approvedDomain,
        };

        try {
            if (approvedDomain) {
                let mutationVariables = { variables: marketplaceOrderCreationVariables };
                let property = marketplaceOrder.format === "video" ? "createCustomerMarketplaceVideoOrderMutation" : "createCustomerMarketplaceOrderMutation";
                let response = marketplaceOrder.format === "video" ? await createVideoMarketplaceOrder(mutationVariables) : await createMarketplaceOrder(mutationVariables);
                
                let gamOrder = JSON.parse(response.data[property]?.gamApiResponse || null);

                if (gamOrder?.errorMessage) {
                    dispatch(pushMessage("Marketplace", gamOrder.errorMessage, "error"));
                    return;
                }

                let reviewResponse = await updateMarketplaceReviewStatusMutation({ variables: { ...marketplaceReviewStatusVariables, domainStatus: "APPROVED" } });
                let data = JSON.parse(reviewResponse.data.createCustomerMarketplaceDomainReviewMutation.domainReviewStatus);
                let newDomainStatuses = JSON.parse(data.networkStatuses);
                setAffectedDomains(newDomainStatuses);
                dispatch(pushMessage("Marketplace", "Order status has been updated"));
                submitHandler({ ...gamOrder, networkStatuses: newDomainStatuses });
            } else {
                let reviewResponse = await updateMarketplaceReviewStatusMutation({ variables: { ...marketplaceReviewStatusVariables, domainStatus: "REJECTED" } });
                let data = JSON.parse(reviewResponse.data.createCustomerMarketplaceDomainReviewMutation.domainReviewStatus);
                let newDomainStatuses = JSON.parse(data.domains);
                dispatch(pushMessage("Marketplace", "Order status has been updated"));
                submitHandler({ ...marketplaceOrder, networkStatuses: newDomainStatuses });
            }
        } catch (err) {
            console.log({ err });
        }
    };

    return (
        <div>
            <div className="order-data" css={orderDataStyles}>
                <div className="info">
                    <strong>Impression Goal: </strong>
                    <span>{impressions}</span>
                </div>
                <div className="info">
                    <strong>Start Date: </strong>
                    <span>{startDate.toLocaleDateString()}</span>
                </div>
                <div className="info">
                    <strong>End Date: </strong>
                    <span>{endDate.toLocaleDateString()}</span>
                </div>
            </div>
            <div
                className="previews"
                css={css`
                    .p-carousel-items-container {
                        align-items: center;
                        text-align: center;
                    }
                    .img-preview-container {
                        display: flex;
                        width: 100%;
                        flex-grow: 1;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            >
                <Carousel
                    value={[...(marketplaceOrder.thirdPartytagCreatives || []), ...(marketplaceOrder.creatives || []), marketplaceOrder.vastPreviewUrl]}
                    itemTemplate={(item) => {
                        if (item) {
                            if (typeof item === 'string') {
                                return <MarketplacePreviewLink creative={{previewUrl: item.endsWith('%') ? item : item + '%'}} />
                            } else if (item.creative) {
                                return (
                                    <div className="img-preview-container">
                                        <img src={item.creative} alt="Creative preview" />
                                    </div>
                                );
                            }
                        }
                        return <MarketplacePreviewLink creative={item} />;
                    }}
                    numVisible={1}
                    numScroll={1}
                />
            </div>
            <div className="controls" css={styles}>
                <div className="domains-ops">
                    <div className="domains">
                        <DomainStatusTable
                            isBuyer={false}
                            domainList={domainsToReview}
                            domainToNetworkMappings={domainToNetworkMappings}
                            marketplaceOrder={marketplaceOrder}
                            affectedDomains={affectedDomains}
                            approveDomainHandler={async (domain) => {
                                domainApprovalHandler(domain);
                                return await submitMarketplaceOrderStatus(domain, MARKETPLACE_DOMAIN_STATUS.APPROVED);
                            }}
                            rejectDomainHandler={async (domain) => {
                                domainRejectionHandler(domain);
                                return await submitMarketplaceOrderStatus(domain, MARKETPLACE_DOMAIN_STATUS.REJECTED);
                            }}
                            pauseDomainLineItem={async (domain) => {
                                return modifyCustomerLineItem(domain, MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_SELLER, LINEITEM_ACTIONS.PAUSE);
                            }}
                            resumeDomainLineItem={async (domain) => {
                                return modifyCustomerLineItem(domain, MARKETPLACE_DOMAIN_STATUS.RESUMED, LINEITEM_ACTIONS.RESUME);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                css={css`
                    display: ${showCreativesDemo ? "block" : "none"};
                    position: fixed;
                    z-index: 999999;
                    width: 100%;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    iframe {
                        margin: 0 auto;
                        display: block;
                    }
                    .close-btn {
                        position: absolute;
                        z-index: 999999999;
                        right: 1em;
                        top: 1em;
                    }
                `}
            >
                <iframe width="100%" height="100%" ref={creativesRef} src={process.env.REACT_APP_CREATIVES_DEMO} title={"creatives_frame"} />
                <Button
                    className="p-button-danger p-button-rounded p-button-icon-only close-btn"
                    icon="pi pi-times"
                    label="Close preview"
                    onClick={() => {
                        creativesRef.current.src = process.env.REACT_APP_CREATIVES_DEMO;
                        setShowCreativesDemo(false);
                    }}
                />
            </div>
        </div>
    );
};

export default DomainReviewOverlay;
