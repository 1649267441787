import React from "react";
import { useSelector } from "react-redux";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, DISPLAY_PARALLAX_AD_FIELDS } from "./definitions";
import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.DISPLAY_PARALLAX;

export default function ParallaxDisplay({
    desktopCreativeURL,
    container,
    handleChange,
    currentCustomer,
    offsetBreakpoint,
    mobileTopOffset,
    topOffset,
    impressionTag,
    clickTag,
    adServingType
}) {
    const { isAdmin } = useSelector((state) => state.userInformation);

    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                creativeType={CREATIVE_TYPE}
                impressionTag={impressionTag}
            >
                <AssetInputOrUploadSection
                    inputValue={desktopCreativeURL}
                    inputLabel="Image creative Url"
                    inputName={DISPLAY_PARALLAX_AD_FIELDS.desktopCreativeURL}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <InputTextSection
                    label={"Container selector"}
                    name={DISPLAY_PARALLAX_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={true}
                    uriEncodeValue={false}
                />
                {isAdmin && (
                    <>
                        <InputTextSection
                            label="Offset breakpoint (px)"
                            name={DISPLAY_PARALLAX_AD_FIELDS.offsetBreakpoint}
                            value={offsetBreakpoint}
                            changeHandler={handleChange}
                            adtype={CREATIVE_TYPE}
                            required={false}
                            isNumericInput={true}
                        />
                        <InputTextSection
                            label="Top offset (px)"
                            name={DISPLAY_PARALLAX_AD_FIELDS.topOffset}
                            value={topOffset}
                            changeHandler={handleChange}
                            adtype={CREATIVE_TYPE}
                            required={false}
                            isNumericInput={true}
                        />
                        <InputTextSection
                            label="Mobile top offset (px)"
                            name={DISPLAY_PARALLAX_AD_FIELDS.mobileTopOffset}
                            value={mobileTopOffset}
                            changeHandler={handleChange}
                            adtype={CREATIVE_TYPE}
                            required={false}
                            isNumericInput={true}
                        />
                    </>
                )}
            </BaseCreativeControls>


        </>
    );
}
