import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import InputTextSection from "./InputTextSection";
import UploadAssetSection from "./UploadAssetSection";
import { CustomCreativesUploadControls } from "./CustomCreativesUploadControls";

/**
 * Check if a string is vast tag url.
 * @param {string} value - The string to check.
 * @returns {boolean} Returns true if the string is a vast tag, otherwise false.
 */
function isVastTag(value) {
    if (typeof value !== 'string') {
        return false
    }
    const regex = /vast|gampad/
    const isVastTag = 
        value.startsWith('https://vast.doubleverify.com') ||
        value.startsWith('https://pubads.g.doubleclick.net') ||
        regex.test(value)
    return isVastTag
}



const AssetInputOrUploadSection = ({ 
    inputValue, 
    inputLabel, 
    inputName,
    inputChangeHandler, 
    inputAdtype, 
    isRequired, 
    currentCustomer, 
    accept, 
    defaultExtension, 
    alternateActionLabel = "Or upload a file:", 
    ratio = null, 
    minWidth = 0, 
    minHeight = 0,
}) => {
    const [isUploading, setUploadState] = useState(false);
    return (
        <div
            css={css`
                background-color: ${isUploading ? "#dcedc8" : "#e6edf2"};
                padding: 6px;
                margin: 10px 0;
            `}
        >
            {isUploading ? (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-weight: bold;
                        > span {
                            padding-right: 1em;
                        }
                    `}
                >
                    <span>... uploading</span>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
                </div>
            ) : (
                <>
                    <InputTextSection 
                        label={inputLabel} 
                        name={inputName} 
                        value={inputValue} 
                        changeHandler={inputChangeHandler} 
                        adtype={inputAdtype} 
                        required={isRequired} 
                        disabled={isUploading} 
                        uriEncodeValue={isVastTag(inputValue)}
                    />
                    <p style={{ marginBottom: "0.5em" }}>{alternateActionLabel}</p>
                    <UploadAssetSection
                        currentCustomer={currentCustomer}
                        accept={accept}
                        defaultExtension={defaultExtension}
                        setUploadState={setUploadState}
                        onUploadComplete={(data) => {
                            let value = data.image;
                            let decodedUrl = window.decodeURI(value);
                            let encodedUrl = window.encodeURI(value);
                            inputChangeHandler({ target: { name: inputName } }, value === encodedUrl ? value : decodedUrl === value ? encodedUrl : value, inputAdtype);
                        }}
                        ratio={ratio}
                        minWidth={minWidth}
                        minHeight={minHeight}
                        render={(props) => <CustomCreativesUploadControls {...props} />}
                    />
                </>
            )}
        </div>
    );
};

export default AssetInputOrUploadSection;
