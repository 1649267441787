import { InputText } from 'primereact/inputtext';
const AdPositionInput = (props) => {
  return (
    <div>
      <InputText
        className={props.class}
        disabled={props.disable}
        name={props.name}
        value={props.value}
        onChange={props.changeEvent}
      />
    </div>
  );
}
export default AdPositionInput;
