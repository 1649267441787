const DEFAULT_MARKETPLACE_STATE = {
    marketplaceOrders: [],
    marketplaceNetworks: [],
    networkOptions: {},
    networkNames: {},
    orderStatuses: {},
    updating: 0,
    creating: 0,
    archiving: 0,
    pausing: 0,
};

const ACTIONS = {
    CREATE_ORDERS: "CREATE_ORDERS",
    REFRESH_ORDER: "REFRESH_ORDER",
    EDIT_ORDER: "EDIT_ORDER",
    REGISTER_ORDERS: "REGISTER_ORDERS",
    REMOVE_ORDER: "REMOVE_ORDER",
    INC_UPDATING: "INC_UPDATING",
    DEC_UPDATING: "DEC_UPDATING",
    INC_CREATING: "INC_CREATING",
    DEC_CREATING: "DEC_CREATING",
    INC_PAUSING: "INC_PAUSING",
    DEC_PAUSING: "DEC_PAUSING",
    SET_NETWORKS: "SET_NETWORKS",
    SET_NETWORK_OPTIONS: "SET_NETWORK_OPTIONS",
    SET_NETWORK_NAMES: "SET_NETWORK_NAMES",
    UPDATE_BY_PROP: "UPDATE_BY_PROP",
    UPDATE_MARKETPLACE_DOMAIN_STATUS: "UPDATE_MARKETPLACE_DOMAIN_STATUS",
    UPDATE_ORDERS_STATUSES: "UPDATE_ORDERS_STATUSES",
};

export default function marketplaceOrdersReducer(state = {}, action) {
    let customerId = action?.data?.customerId;
    let customerState = state[customerId] || { ...DEFAULT_MARKETPLACE_STATE };
    let marketplaceOrders = customerState.marketplaceOrders;

    switch (action.type) {
        case ACTIONS.REGISTER_ORDERS: {
            return { ...state, [customerId]: { ...customerState, marketplaceOrders: action.data.orders } };
        }
        case ACTIONS.CREATE_ORDERS: {
            return { ...state, [customerId]: { ...customerState, marketplaceOrders: [...action.data.ordersData, ...marketplaceOrders] } };
        }
        case ACTIONS.REFRESH_ORDER:
        case ACTIONS.EDIT_ORDER: {
            marketplaceOrders.splice(action.data.orderIndex, 1, action.data.orderData);
            return { ...state, [customerId]: { ...customerState, marketplaceOrders } };
        }
        case ACTIONS.UPDATE_BY_PROP: {
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    marketplaceOrders: marketplaceOrders.map((order) => {
                        let propValue = order[action.data.prop];
                        return propValue === action.data.propVal ? action.data.orderData : order;
                    }),
                },
            };
        }
        case ACTIONS.UPDATE_MARKETPLACE_DOMAIN_STATUS: {
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    marketplaceOrders: marketplaceOrders.map((order) => {
                        return order.wpMarketplacePostId === action.data.order.wpMarketplacePostId ? { ...action.data.order, networkStatuses: { ...order.networkStatuses, [action.data.domainId]: { status: action.data.domainStatus } } } : order;
                    }),
                },
            };
        }
        case ACTIONS.INC_CREATING: {
            return { ...state, [customerId]: { ...customerState, creating: customerState.creating + 1 } };
        }
        case ACTIONS.DEC_CREATING: {
            return { ...state, [customerId]: { ...customerState, creating: customerState.creating - 1 } };
        }
        case ACTIONS.REMOVE_ORDER: {
            marketplaceOrders.splice(action.data.orderIndex, 1);
            return { ...state, [customerId]: { ...customerState, marketplaceOrders } };
        }
        case ACTIONS.SET_NETWORKS: {
            return { ...state, [customerId]: { ...customerState, marketplaceNetworks: action.data.marketplaceNetworks } };
        }
        case ACTIONS.SET_NETWORK_OPTIONS: {
            return { ...state, [customerId]: { ...customerState, networkOptions: action.data.marketplaceNetworkOptions } };
        }
        case ACTIONS.SET_NETWORK_NAMES: {
            return { ...state, [customerId]: { ...customerState, networkNames: action.data.marketplaceNetworkName } };
        }
        default:
            return state;
    }
}

function changeStat(op, customerId) {
    return { type: op, data: { customerId } };
}

function increaseUpdateCount(customerId) {
    return changeStat(ACTIONS.INC_UPDATING, customerId);
}
function decreaseUpdateCount(customerId) {
    return changeStat(ACTIONS.DEC_UPDATING, customerId);
}
function increaseCreateCount(customerId) {
    return changeStat(ACTIONS.INC_CREATING, customerId);
}
function decreaseCreateCount(customerId) {
    return changeStat(ACTIONS.DEC_CREATING, customerId);
}

function setMarketplaceNetworks(marketplaceNetworks, customerId) {
    return {
        type: ACTIONS.SET_NETWORKS,
        data: {
            marketplaceNetworks,
            customerId,
        },
    };
}

function setMarketplaceNetworkOptions(marketplaceNetworkOptions, customerId) {
    return {
        type: ACTIONS.SET_NETWORK_OPTIONS,
        data: {
            marketplaceNetworkOptions,
            customerId,
        },
    };
}

function setMarketplaceNetworkNames(marketplaceNetworkName, customerId) {
    return {
        type: ACTIONS.SET_NETWORK_NAMES,
        data: {
            marketplaceNetworkName,
            customerId,
        },
    };
}

function removeOrder(orderIndex, customerId) {
    return {
        type: ACTIONS.REMOVE_ORDER,
        data: {
            orderIndex,
            customerId,
        },
    };
}

function registerMarketplaceOrders(orders, customerId) {
    return {
        type: ACTIONS.REGISTER_ORDERS,
        data: {
            orders,
            customerId,
        },
    };
}

function createMarketplaceOrders(ordersData, customerId) {
    return {
        type: ACTIONS.CREATE_ORDERS,
        data: {
            ordersData,
            customerId,
        },
    };
}

function refreshMarketplaceOrder(orderData, orderIndex, customerId) {
    return {
        type: ACTIONS.REFRESH_ORDER,
        data: {
            orderIndex,
            orderData,
            customerId,
        },
    };
}

function updateMarketplaceOrderByProp(orderData, prop, propVal, customerId) {
    return {
        type: ACTIONS.UPDATE_BY_PROP,
        data: {
            orderData,
            prop,
            propVal,
            customerId,
        },
    };
}

function updateMarketplaceOrderDomainStatus(order, domainId, domainStatus, customerId) {
    return {
        type: ACTIONS.UPDATE_MARKETPLACE_DOMAIN_STATUS,
        data: {
            order,
            domainId,
            domainStatus,
            customerId,
        },
    };
}

function editMarketplaceOrder(orderData, orderIndex, customerId) {
    return {
        type: ACTIONS.EDIT_ORDER,
        data: {
            orderData,
            orderIndex,
            customerId,
        },
    };
}

export {
    createMarketplaceOrders,
    editMarketplaceOrder,
    refreshMarketplaceOrder,
    registerMarketplaceOrders,
    increaseCreateCount,
    decreaseCreateCount,
    removeOrder,
    setMarketplaceNetworkNames,
    setMarketplaceNetworkOptions,
    setMarketplaceNetworks,
    increaseUpdateCount,
    decreaseUpdateCount,
    updateMarketplaceOrderByProp,
    updateMarketplaceOrderDomainStatus,
};
