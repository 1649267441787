import { InputText } from "primereact/inputtext"
import OrderRow from "./OrderRow"

const OrderRowInput = ({
    changeHandler,
    idx,
    label,
    name,
    value,
    icon,
    placeholder,
    disabled = false
}) => {
    return (
        <OrderRow idx={idx} name={name} icon={icon} label={label}>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className={`pi ${icon}`}></i>
                </span>
                <InputText
                    placeholder={placeholder || ''}
                    id={name + idx}
                    name={name}
                    value={value}
                    onChange={(e) => { changeHandler(e, idx) }}
                    disabled={disabled}
                />
            </div>
        </OrderRow>
    )
}


export default OrderRowInput