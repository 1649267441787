import { gql, useMutation } from '@apollo/client';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'prismjs/themes/prism-coy.css';
import React, { useEffect, useRef, useState } from 'react';
import 'react-list-editable/lib/react-list-editable.css';
import { CSSTransition } from 'react-transition-group';
import '../../App.scss';
import { AppMenu } from '../../AppMenu';
import { AppTopbar } from '../../AppTopbar';
import { sidebarClassName, wrapperClass } from '../../constants';
import { DomainsDropdown } from '../../DomainsDropdown';
import '../../layout/flags/flags.css';
import '../../layout/layout.scss';
import { noop } from '../../utilities/utils';
import AppLogo from '../AppLogo';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationMode } from '../../reducers/userLoginReducer';
import { useHistory } from 'react-router';
import { addToPresetBuildList } from '../../reducers/buildReducer';
import { pushMessage } from '../../reducers/messagingReducer';


const DOMAIN_Edit_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    presetAllInfoMutation(input: {id: $id, cid: $cid}) {
        presetInfo
    }
  }
`
const AD_EXCLUSION_UPDATE_MUTATION = gql`
  mutation callAdExclusionListUpdateMutation($id: String, $exclusionList: String) {
    updatePresetAdExclusionMutation(input: {id: $id, exclusionList: $exclusionList}) {
        clientMutationId
        networkId
    }
  }
`

const PresetAdExclusion = (props) => {
    const { currentPreset, currentCustomer } = useSelector(state => state.userInformation)
    const staticMenuInactive = false
    const [listData, setListData] = useState([]);
    const [listOptionsData, setListOptionsData] = useState([]);
    const sidebar = useRef();
    const messages = useRef(null);
    const dispatch = useDispatch()
    const history = useHistory()
    const exclusionItemTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    const selectedItemsTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return "Select Ad Exclusion Options";
    }

    const htmlDecode = (input) => {
        var e = document.createElement('textarea');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(
        DOMAIN_Edit_MUTATION,
        {
            onCompleted(data) {
                confirm(data);
            }
        }
    )

    const [AD_EXCLUSION_UPDATE_MUTATION_CONTAINER] = useMutation(
        AD_EXCLUSION_UPDATE_MUTATION,
        {
            onCompleted() {
		dispatch(addToPresetBuildList(currentPreset))
                dispatch(pushMessage('Preset Ad Exclusion', 'Ad exclusion list was updated!'))
            }
        }
    );

    useEffect(() => {
        if (!currentCustomer || !currentPreset) {
            return history.push('/presetinfo')
        }

        dispatch(setNavigationMode('preset'))
        DOMAIN_EDIT_MUTATION_CONTAINER({
            variables: { id: currentPreset, cid: currentCustomer }
        })
    }, [currentCustomer, currentPreset])

    const confirm = data => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo)
        if (temp_data.ad_exclusion_list !== null) {
            setListData(temp_data.ad_exclusion_list.map(function (item) {
                return { name: htmlDecode(item.title), code: htmlDecode(item.title) };
            }))
        }
        if (temp_data.ad_exclusion_list_options !== null) {
            temp_data.ad_exclusion_list_options = temp_data.ad_exclusion_list_options.map(function (item) {
                return { name: htmlDecode(item.name), code: htmlDecode(item.name) };
            })
            setListOptionsData(temp_data.ad_exclusion_list_options)
        }
    }

    const saveAdExclusionList = () => {
        let simplifiedData = [...listData]
        simplifiedData = simplifiedData.map(function (item) {
            return item.name
        })
        AD_EXCLUSION_UPDATE_MUTATION_CONTAINER({
            variables: { id: `${currentPreset}`, exclusionList: JSON.stringify(simplifiedData) }
        })
    }

    const listChanged = (newList) => {
        newList.sort(function (a, b) {
            return ('' + a.name).localeCompare(b.name);
        });
        setListData(newList)
    }

    return (
        <div className="center w100">
            <div className={wrapperClass} >
                <AppTopbar {...props} domain_name={false} preset_name={true} />
                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} >
                        <AppLogo />
                        <DomainsDropdown />
                        <AppMenu {...props} model={'preset'} onMenuItemClick={noop} clientInfo={props.clientInfo} sourceData={props.sourceData} />
                    </div>
                </CSSTransition>

                <div className="layout-main p-panel p-component">
                    <Messages ref={messages} />
                    <div className="p-panel-header">
                        <div className="p-d-flex p-ai-center">
                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold" >Preset Ad Exclusion</label>
                        </div>
                        <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => saveAdExclusionList()} />
                    </div>
                    <div className="p-panel-content" id="listings-panel">
                        <MultiSelect
                            style={{ minWidth: "90rem" }}
                            value={listData}
                            scrollHeight="600px"
                            options={listOptionsData}
                            display="chip"
                            onChange={(e) => listChanged(e.value)}
                            optionLabel="name"
                            placeholder="Select Ad Exclusion Options"
                            filter
                            className="multiselect-custom"
                            itemTemplate={exclusionItemTemplate}
                            selectedItemTemplate={selectedItemsTemplate}
                        />
                        <div className="p-panel-content disable-item">
                            {listData.map((item, index) => (
                                <InputText key={index} value={item.name} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default PresetAdExclusion;
