const ACTIONS = {
    MUTATE_ADAPTER: 'MUTATE_ADAPTER',
    MUTATE_AMAZON_ADP: 'MUTATE_AMAZON_ADP',
    CLEAR_ADAPTERS_MUTATIONS: 'CLEAR_ADAPTERS_MUTATIONS',
    MUTATE_GLOBAL_PARAMS: 'MUTATE_GLOBAL_PARAMS'
}

export default function adapterMutationReducer(state = {global: {}}, action) {
    let { type } = action
    switch (type) {
        case ACTIONS.MUTATE_ADAPTER: {
            let {data: {adapterName, adPositionName, values}} = action
            let adapterState = state[adapterName] || {}
            let adapterStateCopy = {...adapterState}
            adapterStateCopy[adPositionName] = values
            return {...state, [adapterName]: adapterStateCopy}
        }
        case ACTIONS.MUTATE_GLOBAL_PARAMS: {
            let {data: {adapterName, values}} = action
            return {...state, global: {...state.global, [adapterName]: values}}
        }
        case ACTIONS.MUTATE_AMAZON_ADP: {
            let {data: {adapterName, publisherID, isEnabled}} = action
            return {...state, [adapterName]: {publisherID, isEnabled}}
        }
        case ACTIONS.CLEAR_ADAPTERS_MUTATIONS:
            return {global: {}}
        default:
            return state
    }
}

/**
 * Registers a mutation for a given adapter and ad position with the specified values.
 *
 * @param {String} adapterName - The name of the adapter.
 * @param {String} adPositionName - The name of the ad position.
 * @param {Object[]} values - An array of objects with any shape.
 */

export function registerAdapterMutation(adapterName, adPositionName, values) {
    return {
        type: ACTIONS.MUTATE_ADAPTER,
        data: {
            adapterName,
            adPositionName,
            values
        }
    }
}

/**
 * 
 * @param {String} adapterName 
 * @param {String} parameterName 
 * @param {String} parameterValue 
 * @returns {Object}
 */

export function registerAdapterGlobalParametersMutation(adapterName, values) {
    return {
        type: ACTIONS.MUTATE_GLOBAL_PARAMS,
        data: {
            adapterName,
            values
        }
    }
}

/**
 * Clears all current adapter parameters changes
 * 
 * @returns {Object}
 */

export function clearAdapterMutations() {
    return {
        type: ACTIONS.CLEAR_ADAPTERS_MUTATIONS
    }
}

/**
 * 
 * @param {String} publisherID
 * @param {Boolean} isEnabled
 * @returns {Object}
 */

export function registerAmazonAdapterChanges(publisherID, isEnabled) {
    return {
        type: ACTIONS.MUTATE_AMAZON_ADP,
        data: {
            publisherID,
            adapterName: 'amazon',
            isEnabled
        }
    }
}