import { InputSwitch } from "primereact/inputswitch";
import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import InputTextSection from "./InputTextSection";
import { CREATIVE_NAMES, SYNDICATION_CREATIVE_AD_FIELDS } from "./definitions";
/** @jsxImportSource @emotion/react */
import { gql } from "@apollo/client";
import { css } from "@emotion/react";
import { InputNumber } from "primereact/inputnumber";
import { useSelector } from "react-redux";
import ColorPicker from "./ColorPicker";
import DropdownSection from "./DropdownSection";
import BaseCreativeControls from "./BaseCreativeControls";

export const SYNDICATION_CREATIVES_MUTATION = gql`
    mutation callSyndicationCreativesMutation($id: String) {
        syndicationCreativesMutation(input: { id: $id }) {
            syndicationCreatives
        }
    }
`;

const CREATIVE_TYPE = CREATIVE_NAMES.SYNDICATION_CREATIVE;

const FORMAT_OPTIONS = [
    { label: "Responsive", value: "default" },
    { label: "970x250 banner", value: "banner" },
    { label: "300x600 right-rail", value: "rail" },
];

const SWITCH_SECTION_STYLES = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
    .label {
        font-weight: bold;
    }
`;

const generalSwitchSectionStyles = css`
    .label {
        margin-bottom: 0.25em;
        display: inline-block;
    }
    .p-inputnumber.p-component {
        margin-bottom: 0.25em;
        width: 100%;
    }
`;

function SwitchSection({ label, isChecked, renderInput, switchName, name, value, changeHandler, creativeType }) {
    return (
        <>
            <div css={SWITCH_SECTION_STYLES}>
                <span className="label">{label}</span>
                <InputSwitch
                    checked={isChecked}
                    name={switchName}
                    onChange={(e) => {
                        changeHandler(e, e.value, creativeType);
                    }}
                />
            </div>
            <div
                css={css`
                    margin-bottom: 1.25em;
                    span {
                        display: block;
                        input {
                            width: 100%;
                        }
                    }
                `}
            >
                {isChecked && renderInput && <InputNumber name={name} value={value} mode="decimal" minFractionDigits={2} maxFractionDigits={2} min={0} max={10} onValueChange={(ev) => changeHandler(ev, ev.target.value, creativeType)} />}
            </div>
        </>
    );
}

function GeneralSwitchSection({ label, isChecked, switchName, changeHandler, creativeType, children }) {
    return (
        <>
            <div css={SWITCH_SECTION_STYLES}>
                <span className="label">{label}</span>
                <InputSwitch
                    checked={isChecked}
                    name={switchName}
                    onChange={(e) => {
                        changeHandler(e, e.value, creativeType);
                    }}
                />
            </div>
            <div>{isChecked && children}</div>
        </>
    );
}

export default function SyndicationCreative({
    logo,
    highContrastLogo,
    syndicationTitle,
    externalLink,
    rssFeedUrl,
    refreshEnabled,
    refreshInterval,
    container,
    handleChange,
    impressionTag,
    currentCustomer,
    lazyload,
    fetchMarginPercent,
    renderMarginPercent,
    mobileScaling,
    syndicationBackgroundColor,
    format = "default",
    vast = "",
    navigationBackgroundColor,
    navigationHoverColor,
    wrapperUrl,
    gamAdUnitPath,
    borderColor,
    hideArticleTitle,
    adServingType
}) {
    const { isAdmin } = useSelector((state) => state.userInformation);

    return (
        <>
            <BaseCreativeControls
                isClickTagRequired={false}
                adServingType={adServingType}
                changeHandler={handleChange}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
                renderClickUrlInput={false}
            >

                <DropdownSection adtype={CREATIVE_TYPE} label="Syndication format" name={SYNDICATION_CREATIVE_AD_FIELDS.format} changeHandler={handleChange} value={format} options={FORMAT_OPTIONS} />
                <AssetInputOrUploadSection
                    inputValue={logo}
                    inputLabel="Light background Logo (100x30px)"
                    inputName={SYNDICATION_CREATIVE_AD_FIELDS.logo}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={highContrastLogo}
                    inputLabel="Dark background Logo (100x30px)"
                    inputName={SYNDICATION_CREATIVE_AD_FIELDS.highContrastLogo}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={false}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />

                {isAdmin && <InputTextSection label="Ad Wrapper URL" name={SYNDICATION_CREATIVE_AD_FIELDS.wrapperUrl} value={wrapperUrl} changeHandler={handleChange} adtype={CREATIVE_TYPE} textInput={true} placeHolder="Ad wrapper url" required={false} uriEncodeValue={false} />}
                {isAdmin && <InputTextSection label="Base Ad Unit Path" name={SYNDICATION_CREATIVE_AD_FIELDS.gamAdUnitPath} value={gamAdUnitPath} changeHandler={handleChange} adtype={CREATIVE_TYPE} textInput={true} placeHolder="Base ad unit path" required={false} uriEncodeValue={false} />}
                <InputTextSection label="VAST tag" value={vast} name={SYNDICATION_CREATIVE_AD_FIELDS.vast} changeHandler={handleChange} adtype={CREATIVE_TYPE} uriEncodeValue={false} />
                <InputTextSection
                    label="RSS feed URL"
                    placeHolder="e.g. https://www.ebony.com/feed"
                    value={rssFeedUrl}
                    name={SYNDICATION_CREATIVE_AD_FIELDS.rssFeedUrl}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={true}
                />
                <InputTextSection label="Feed Section Title" name={SYNDICATION_CREATIVE_AD_FIELDS.syndicationTitle} value={syndicationTitle} changeHandler={handleChange} adtype={CREATIVE_TYPE} textInput={true} placeHolder="e.g. Diverse Voices" required={false} uriEncodeValue={false} />
                <InputTextSection label="Brought-by external link URL" name={SYNDICATION_CREATIVE_AD_FIELDS.externalLink} value={externalLink} changeHandler={handleChange} adtype={CREATIVE_TYPE} textInput={true} placeHolder="e.g. https://www.ebony.com" required={false} />

                <InputTextSection label="Wrapper (optional)" name={SYNDICATION_CREATIVE_AD_FIELDS.container} value={container} changeHandler={handleChange} adtype={CREATIVE_TYPE} textInput={true} required={false} uriEncodeValue={false} />

                {
                    format === 'banner' && (
                        <SwitchSection label="Hide Article Categories?" isChecked={hideArticleTitle} switchName={SYNDICATION_CREATIVE_AD_FIELDS.hideArticleTitle} name={SYNDICATION_CREATIVE_AD_FIELDS.hideArticleTitle} value={hideArticleTitle} changeHandler={handleChange} creativeType={CREATIVE_TYPE} />
                    )
                }


                <ColorPicker label="Syndication Background Color" changeHandler={handleChange} adtype={CREATIVE_TYPE} required={false} name={SYNDICATION_CREATIVE_AD_FIELDS.syndicationBackgroundColor} value={syndicationBackgroundColor} />
                {(format === "rail" || format === "banner") && <ColorPicker label="Syndication Border Color" changeHandler={handleChange} adtype={CREATIVE_TYPE} required={false} name={SYNDICATION_CREATIVE_AD_FIELDS.borderColor} value={borderColor} />}
                {format !== "default" && (
                    <>
                        <ColorPicker label="Navigation Background Color" changeHandler={handleChange} adtype={CREATIVE_TYPE} required={false} name={SYNDICATION_CREATIVE_AD_FIELDS.navigationBackgroundColor} value={navigationBackgroundColor} />
                        <ColorPicker label="Navigation Hover Color" changeHandler={handleChange} adtype={CREATIVE_TYPE} required={false} name={SYNDICATION_CREATIVE_AD_FIELDS.navigationHoverColor} value={navigationHoverColor} />
                    </>
                )}
                <GeneralSwitchSection changeHandler={handleChange} creativeType={CREATIVE_TYPE} isChecked={lazyload} label="Lazyload" switchName={SYNDICATION_CREATIVE_AD_FIELDS.lazyload}>
                    {[
                        { name: SYNDICATION_CREATIVE_AD_FIELDS.fetchMarginPercent, value: fetchMarginPercent, prettyName: "Fetch margin percent" },
                        { name: SYNDICATION_CREATIVE_AD_FIELDS.renderMarginPercent, value: renderMarginPercent, prettyName: "Render margin percent" },
                        { name: SYNDICATION_CREATIVE_AD_FIELDS.mobileScaling, value: mobileScaling, prettyName: "Mobile scaling", minFractionDigits: 2, maxFractionDigits: 2 },
                    ].map((inputFields) => {
                        return (
                            <div key={inputFields.name} css={generalSwitchSectionStyles}>
                                <span className="label">{inputFields.prettyName}</span>
                                <InputNumber {...inputFields} mode="decimal" onValueChange={(ev) => handleChange(ev, ev.target.value, CREATIVE_TYPE)} />
                            </div>
                        );
                    })}
                </GeneralSwitchSection>
                <SwitchSection label="Refresh" isChecked={refreshEnabled} switchName={SYNDICATION_CREATIVE_AD_FIELDS.refreshEnabled} name={SYNDICATION_CREATIVE_AD_FIELDS.refreshInterval} value={refreshInterval} changeHandler={handleChange} creativeType={CREATIVE_TYPE} />
            </BaseCreativeControls>

        </>
    );
}
