import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import "react-list-editable/lib/react-list-editable.css";
import { useDispatch, useSelector } from "react-redux";
import "../../App.scss";
import "../../layout/flags/flags.css";
import "../../layout/layout.scss";
import { addToPresetBuildList } from "../../reducers/buildReducer";
import { pushMessage } from "../../reducers/messagingReducer";
import { registerScreenChanges } from "../../reducers/screenChangesReducer";
import { setNavigationMode } from "../../reducers/userLoginReducer";
import SectionHeader from "../helper/SectionHeader";
import {MultiSelect} from "primereact/multiselect";
import LayoutWrapper from "../LayoutWrapper";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import {InputSwitch} from "primereact/inputswitch";
// import useAdgridToolTipManager from "../";

const PRESET_EDIT_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $cid: String) {
        presetAllInfoMutation(input: { id: $id, cid: $cid }) {
            presetInfo
        }
    }
`;
const PUBMATICHUB_UPDATE_MUTATION = gql`
    mutation callUpdatePresetPubmaticHub($id: String, $publisherId: String, $profileId: String, $id5Partner: String, $rampPlacementId: String, $unifiedPartner: String, $unified2PubKey: String, $unified2SubscriptionID: String, $eUIDPubKey: String, $eUIDSubscriptionID: String, $adaptersPermutive: String, $enableSharedId: Boolean, $enableAtsAnalytics: Boolean ) {
        updatePresetPubmaticHub(input: { id: $id, publisherId: $publisherId, profileId: $profileId, id5Partner: $id5Partner, rampPlacementId: $rampPlacementId, unifiedPartner: $unifiedPartner, unified2PubKey: $unified2PubKey, unified2SubscriptionID: $unified2SubscriptionID, eUIDPubKey: $eUIDPubKey, eUIDSubscriptionID: $eUIDSubscriptionID, adaptersPermutive: $adaptersPermutive, enableSharedId: $enableSharedId, enableAtsAnalytics: $enableAtsAnalytics   }) {
            clientMutationId
            networkId
        }
    }
`;

const PresetAdExclusion = () => {
    const { currentPreset, currentCustomer } = useSelector((state) => state.userInformation);
    const [publisherId, setPublisherId] = useState(null);
    const [profileId, setProfileId] = useState(null);
    const [id5Partner, setid5Partner] = useState(null);
    const [rampPlacementId, setRampPlacementId] = useState(null);
    const [unifiedPartner, setUnifiedPartner] = useState(null);
    const [unified2PubKey, setUnified2PubKey] = useState(null);
    const [unified2SubscriptionID, setUnified2SubscriptionID] = useState(null);
    const [eUIDPubKey, seteUIDPubKey] = useState(null);
    const [eUIDSubscriptionID, seteUIDSubscriptionID] = useState(null);
    const [optionsPermutive, setOptionsPermutive] = useState(null);
    const [adaptersPermutive, setAdaptersPermutive] = useState(null);
    const [selectedAdaptersPermutive, setSelectedAdaptersPerutive] = useState(null);
    const [enableSharedId, setEnableSharedId] = useState(false);
    const [enableAtsAnalytics, setEnableAtsAnalytics] = useState(false);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    // const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('supply_chain');
    const dispatch = useDispatch();

    const [PRESET_EDIT_MUTATION_CONTAINER] = useMutation(PRESET_EDIT_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    const [PRESET_UPDATE_MUTATION_CONTAINER] = useMutation(PUBMATICHUB_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(addToPresetBuildList(currentPreset));
            dispatch(pushMessage("Preset Identity Hub", "Pubmatic hub updated"));
        },
    });

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            PRESET_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentPreset}`, cid: currentCustomer },
            });
            dispatch(setNavigationMode("preset"));
            setPublisherId("");
            setProfileId("");
            setid5Partner("");
            setRampPlacementId("");
            setUnifiedPartner("");
            setUnified2PubKey("");
            setUnified2SubscriptionID("");
            seteUIDPubKey("");
            seteUIDSubscriptionID("");
            setAdaptersPermutive("");
        }
    }, [currentCustomer, currentPreset]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo);
        //console.log({temp_data});
        setOptionsPermutive(temp_data.adapter_list_choices)
        if (temp_data.permutive_adapter_list) {
            const permutiveAdapters = JSON.parse(temp_data.permutive_adapter_list)
            //console.log({aa: permutiveAdapters.bidders})
            setSelectedAdaptersPerutive(permutiveAdapters.bidders);
            setAdaptersPermutive(JSON.stringify({"bidders":permutiveAdapters.bidders}))
        }
        setPublisherId(temp_data.pubmatic_publisher_id);
        setProfileId(temp_data.pubmatic_profile_id);
        setid5Partner(temp_data.id5_partner);
        setRampPlacementId(temp_data.liveramp_pi);
        setUnifiedPartner(temp_data.unified_pid);
        setUnified2PubKey(temp_data.uid2_public_key);
        setUnified2SubscriptionID(temp_data.uid2_sid);
        seteUIDPubKey(temp_data.euid_public_key);
        seteUIDSubscriptionID(temp_data.euid_sid);
        setEnableSharedId(temp_data.enable_sharedid)
        setEnableAtsAnalytics(temp_data.enable_ats_analytics)
        setMutationDataCompleted(true);
    };

    const setCheckBoxEnableSharedId = () => {
        setEnableSharedId(!enableSharedId);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const setCheckBoxEnableAtsAnalytics = () => {
        setEnableAtsAnalytics(!enableAtsAnalytics);
        dispatch(registerScreenChanges("__Identity Hub"));
    };

    const handleChangeAdapters = (params) => {

        let {target: {name, value}} = params;
        const obj = {bidders: value};
        const jsonString = JSON.stringify(obj);

        //console.log({value, jsonString})

        setSelectedAdaptersPerutive(value)
        setAdaptersPermutive(jsonString)

    }

    const savePubmaticHub = () => {
        return PRESET_UPDATE_MUTATION_CONTAINER({
            variables: {
                id: `${currentPreset}`,
                publisherId: publisherId,
                profileId: profileId,
                id5Partner: id5Partner,
                rampPlacementId: rampPlacementId,
                unifiedPartner: unifiedPartner,
                unified2PubKey: unified2PubKey,
                unified2SubscriptionID: unified2SubscriptionID,
                eUIDPubKey: eUIDPubKey,
                eUIDSubscriptionID: eUIDSubscriptionID,
                adaptersPermutive: adaptersPermutive,
                enableSharedId: enableSharedId,
                enableAtsAnalytics: enableAtsAnalytics
            },
        });
    };

    return (
        <LayoutWrapper model="preset" saveCallback={savePubmaticHub}>
            {mutationDataCompleted && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content contact-form">
                        <div className="p-panel p-component mb-1">
                            <div className="p-datatable p-component p-datatable-gridlines">
                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                    <div className="p-d-flex p-ai-center f-width">
                                        <label>
                                            <strong> User modules </strong>
                                        </label>
                                    </div>
                                </div>
                                <div className="p-panel p-component">
                                    <div className="p-panel-content">
                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; Pubmatic Identity Hub &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="publisher_id">Publisher Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="publisher_id"
                                                                        value={publisherId}
                                                                        onChange={(e) => {
                                                                            setPublisherId(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="profile_id">Profile Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="profile_id"
                                                                        value={profileId}
                                                                        onChange={(e) => {
                                                                            setProfileId(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; ID5 Identity Hub &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="id5_partner">Partner</label>

                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="id5_partner"
                                                                        value={id5Partner}
                                                                        onChange={(e) => {
                                                                            setid5Partner(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; LiveRamp Identity Hub &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="placement_id">Placement Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="liveramp_pi"
                                                                        value={rampPlacementId}
                                                                        onChange={(e) => {
                                                                            setRampPlacementId(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="placement_id">Enable ATS Analytics</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputSwitch checked={enableAtsAnalytics} onChange={setCheckBoxEnableAtsAnalytics} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; Unified Identity Hub &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="liveramp_pi">Partner Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="unified_pid"
                                                                        value={unifiedPartner}
                                                                        onChange={(e) => {
                                                                            setUnifiedPartner(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; Unified Identity (UID2) Hub &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="uid2_public_key">Public Key</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputTextarea
                                                                        id=""
                                                                        name="uid2_public_key"
                                                                        value={unified2PubKey}
                                                                        onChange={(e) => {
                                                                            setUnified2PubKey(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="uid2_sid">Subscription Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="uid2_sid"
                                                                        value={unified2SubscriptionID}
                                                                        onChange={(e) => {
                                                                            setUnified2SubscriptionID(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; European Unified ID (EUID) &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="euid_public_key">Public Key</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputTextarea
                                                                        id=""
                                                                        name="euid_public_key"
                                                                        value={eUIDPubKey}
                                                                        onChange={(e) => {
                                                                            seteUIDPubKey(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="euid_sid">Subscription Id</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <InputText
                                                                        id=""
                                                                        name="euid_sid"
                                                                        value={eUIDSubscriptionID}
                                                                        onChange={(e) => {
                                                                            seteUIDSubscriptionID(e.target.value);
                                                                            dispatch(registerScreenChanges("Preset Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">

                                                        <label>
                                                            <strong>
                                                                &nbsp; SharedID Module &nbsp;
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-fluid">
                                                                    <div className="p-grid small-margin-bottom-10">
                                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                            <div className="p-d-flex p-ai-center f-width">
                                                                            <InputSwitch checked={enableSharedId} onChange={setCheckBoxEnableSharedId} />
                                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2" onClick={setCheckBoxEnableSharedId}>Enable SharedId</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* Vendoe Specific Modules */}
                        <div className="p-panel p-component mb-1">
                            <div className="p-datatable p-component p-datatable-gridlines">
                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                    <div className="p-d-flex p-ai-center f-width">
                                        <label>
                                            <strong> Vendor Specific modules </strong>
                                        </label>
                                    </div>
                                </div>
                                <div className="p-panel p-component">
                                    <div className="p-panel-content">

                                        <div className="p-panel p-component mb-1">
                                            <div className="p-datatable p-component p-datatable-gridlines">
                                                <div className="p-datatable-header border-bottom-1 p-d-flex p-jc-between p-ai-center">
                                                    <div className="p-d-flex p-ai-center f-width">
                                                        <label htmlFor="euid_public_key">Permutive RTD Provider</label>
                                                    </div>
                                                </div>
                                                <div className="p-panel p-component">
                                                    <div className="p-panel-content">

                                                        <div className="p-grid">
                                                            <div className="p-col-3 p-sm-none small-hide">
                                                                <label htmlFor="euid_public_key">Permutive Adapter List</label>
                                                            </div>
                                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                <div className="p-inputgroup p-fluid">
                                                                    <MultiSelect
                                                                        value={selectedAdaptersPermutive}
                                                                        onChange={handleChangeAdapters}
                                                                        name="permutive_adapter_list"
                                                                        options={optionsPermutive}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetAdExclusion;
