/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";

const PaymentRegistrationBanner = ({ isVisible, onHide, headerMessage = "Register a payment method", children }) => {
    return (
        <Dialog
            header={headerMessage}
            visible={isVisible}
            css={css`
                width: 40vw;
                .p-dialog-title {
                    display: block;
                    width: 100%;
                }
            `}
            style={{ width: "40vw" }}
            onHide={() => {
                if (onHide && typeof onHide === "function") {
                    onHide();
                }
            }}
        >
            {children}
        </Dialog>
    );
};

export default PaymentRegistrationBanner;
