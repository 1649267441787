/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCreditCard, faCreditCardAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faCreditCard, faCreditCardAlt, faCheckSquare);

export function ItemTemplate(cardData) {
    let [brand, expMonth, expYear, last4] = (cardData?.cardData?.data || "").split(",");

    return (
        <div
            css={css`
                .card-item {
                    display: inline-block;
                    padding-left: 0.5em;
                }
            `}
        >
            <FontAwesomeIcon icon="credit-card-alt" color="#ff0000" />
            <span className="card-item">
                <b>{brand}</b>
            </span>
            <span className="card-item">
                <b>exp:</b> {expMonth}/{expYear}
            </span>
            <span className="card-item">
                <b>last 4 digits:</b> {last4}
            </span>
        </div>
    );
}

export function ValueTemplate(cardData) {
    if (!cardData?.cardData) {
        return (
            <p>
                <b>No payment methods available</b>
            </p>
        );
    }
    let [brand, , , last4] = (cardData?.cardData?.data || "").split(",");

    return (
        <div
            css={css`
                .card-item {
                    display: inline-block;
                    padding-left: 0.5em;
                }
            `}
        >
            <FontAwesomeIcon icon="check-square" color="#4caf50" />
            <span className="card-item">
                <b>{brand}</b>
            </span>
            <span className="card-item">
                <b>last 4:</b> {last4}
            </span>
        </div>
    );
}
