function makeHeadRequest(url) {
    return fetch(url, { method: "HEAD" })
        .then((response) => (response.ok ? Promise.resolve(response) : Promise.reject(new Error("Request failed"))))
        .then((response) => response.headers);
}

async function getResourceContentLength(url) {
    let headers = await makeHeadRequest(url);
    let cl = headers.get("content-length");
    return cl;
}

export async function getMetadata(url) {
    let contentLength = await getResourceContentLength(url);
    return new Promise((resolve) => {
        let video = document.createElement("video");
        video.addEventListener("loadedmetadata", function () {
            resolve({ contentLength, duration: video.duration, url });
        });
        video.src = url;
    });
}

export async function retrieveMetadata(...urls) {
    if (urls.length) {
        let promises = urls.map(getMetadata);
        let values = await Promise.allSettled(promises);
        return values;
    } else {
        return urls;
    }
}
