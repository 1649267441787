import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import "react-list-editable/lib/react-list-editable.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../App.scss";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import LayoutWrapper from "./LayoutWrapper";
import SectionHeader from "./helper/SectionHeader";
import {MultiSelect} from "primereact/multiselect";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { PUBMATICHUB_UPDATE_MUTATION, SUPPLY_CHAIN_UPDATE_MUTATION } from "../mutations/mutations";

const IdentityHub = () => {
    const { currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const history = useHistory();
    const [schainGlobal, setSchainGlobal] = useState(null);
    const [schainBidder, setSchainBidder] = useState(null);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [showCheckBoxID5, setShowCheckBoxID5] = useState(false);
    const [showCheckBoxRamp, setShowCheckBoxRamp] = useState(false);
    const [showCheckBoxUnified, setShowCheckBoxUnified] = useState(false);
    const [showCheckBoxUnified2, setShowCheckBoxUnified2] = useState(false);
    const [showCheckBoxEUID, setShowCheckBoxEUID] = useState(false);
    const [showCheckBoxSharedId, setShowCheckBoxSharedId] = useState(false);
    const [showCheckBoxPermutive, setShowCheckBoxPermutive] = useState(false);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [publisherId, setPublisherId] = useState(null);
    const [profileId, setProfileId] = useState(null);
    const [id5Partner, setid5Partner] = useState(null);
    const [rampPlacementId, setRampPlacementId] = useState(null);
    const [unifiedPartner, setUnifiedPartner] = useState(null);
    const [enableAtsAnalytics, setEnableAtsAnalytics] = useState(false);
    const [enableSharedId, setEnableSharedId] = useState(false);
    const [unified2PubKey, setUnified2PubKey] = useState(null);
    const [unified2SubscriptionID, setUnified2SubscriptionID] = useState(null);
    const [eUIDSubscriptionID, seteUIDSubscriptionID] = useState(null);
    const [eUIDPubKey, seteUIDPubKey] = useState(null);
    const [optionsPermutive, setOptionsPermutive] = useState(null);
    const [adaptersPermutive, setAdaptersPermutive] = useState(null);
    const [selectedAdaptersPermutive, setSelectedAdaptersPerutive] = useState(null);
    const [presetName, setPresetName] = useState(false);
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('supply_chain');
    const dispatch = useDispatch();

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            confirm(data);
        }
    })

    const [PUBMATIC_UPDATE_MUTATION_CONTAINER] = useMutation(PUBMATICHUB_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(pushMessage("Identity Hub", "Identity hub updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    const [SUPPLY_CHAIN_UPDATE_MUTATION_CONTAINER] = useMutation(SUPPLY_CHAIN_UPDATE_MUTATION, {
        onCompleted() {
            dispatch(pushMessage("Supply Chain", "Supply Chain Data updated"));
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            });
        }
    }, [currentCustomer, currentDomain]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
        //console.log("enable_ats_analytics: ",temp_data.enable_ats_analytics )
        if (temp_data.permutive_adapter_list) {
            const permutiveAdapters = JSON.parse(temp_data.permutive_adapter_list)
            setSelectedAdaptersPerutive(permutiveAdapters.bidders);
            setAdaptersPermutive(JSON.stringify({"bidders":permutiveAdapters.bidders}))
            //setAdaptersPermutive(permutiveAdapters.bidders)
        }

        setPresetName(temp_data.adv_preset.name);
        setSchainGlobal(temp_data.schain_global);
        setSchainBidder(temp_data.schain_bidder);
        setShowCheckBox(temp_data.override_pubmatic_hub);
        setShowCheckBoxID5(temp_data.override_id5);
        setShowCheckBoxRamp(temp_data.override_liveramp);
        setShowCheckBoxUnified(temp_data.override_unified);
        setShowCheckBoxUnified2(temp_data.override_uid2);
        setShowCheckBoxEUID(temp_data.override_euid);
        setShowCheckBoxSharedId(temp_data.override_sharedid);
        setShowCheckBoxPermutive(temp_data.override_permutive)
        setOptionsPermutive(temp_data.adapter_list_choices)
        setPublisherId(temp_data.pubmatic_publisher_id);
        setProfileId(temp_data.pubmatic_profile_id);
        setid5Partner(temp_data.id5_partner);
        setRampPlacementId(temp_data.liveramp_pi);
        setUnifiedPartner(temp_data.unified_pid);
        setUnified2PubKey(temp_data.uid2_public_key);
        setUnified2SubscriptionID(temp_data.uid2_sid);
        seteUIDPubKey(temp_data.euid_public_key);
        seteUIDSubscriptionID(temp_data.euid_sid);
        setEnableSharedId(temp_data.enable_sharedid)
        setEnableAtsAnalytics(temp_data.enable_ats_analytics)
        setMutationDataCompleted(true);
    };

    const overrideCheckBox = () => {
        setShowCheckBox(!showCheckBox);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxID5 = () => {
        setShowCheckBoxID5(!showCheckBoxID5);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxRamp = () => {
        setShowCheckBoxRamp(!showCheckBoxRamp);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxUnified = () => {
        setShowCheckBoxUnified(!showCheckBoxUnified);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxUnified2 = () => {
        setShowCheckBoxUnified2(!showCheckBoxUnified2);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxEUID = () => {
        setShowCheckBoxEUID(!showCheckBoxEUID);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const overrideCheckBoxSharedId = () => {
        setShowCheckBoxSharedId(!showCheckBoxSharedId);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const setCheckBoxEnableSharedId = () => {
        setEnableSharedId(!enableSharedId);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const setCheckBoxEnableAtsAnalytics = () => {
        setEnableAtsAnalytics(!enableAtsAnalytics);
        dispatch(registerScreenChanges("__Identity Hub"));
    };

    const overrideCheckBoxPermutive = () => {
        setShowCheckBoxPermutive(!showCheckBoxPermutive);
        dispatch(registerScreenChanges("Identity Hub"));
    };

    const handleChangeAdapters = (params) => {

        let {target: {name, value}} = params;
        const obj = {bidders: value};
        const jsonString = JSON.stringify(obj);

        setSelectedAdaptersPerutive(value)
        setAdaptersPermutive(jsonString)
    }

    const savePubmaticInfo = async () => {
        await PUBMATIC_UPDATE_MUTATION_CONTAINER({
            variables: {
                id: `${currentDomain}`,
                publisherId: publisherId,
                profileId: profileId,
                override: showCheckBox,
                id5Partner: id5Partner,
                overrideID5: showCheckBoxID5,
                rampPlacementId: rampPlacementId,
                unifiedPartner: unifiedPartner,
                unified2PubKey: unified2PubKey,
                unified2SubscriptionID: unified2SubscriptionID,
                eUIDPubKey: eUIDPubKey,
                eUIDSubscriptionID: eUIDSubscriptionID,
                permutiveAdapters: adaptersPermutive,
                overrideRamp: showCheckBoxRamp,
                overrideUnified: showCheckBoxUnified,
                overrideUnified2: showCheckBoxUnified2,
                overrideEUID: showCheckBoxEUID,
                overridePermutive: showCheckBoxPermutive,
                enableSharedId: Boolean(enableSharedId),
                overrideSharedId: Boolean(showCheckBoxSharedId),
                enableAtsAnalytics: Boolean(enableAtsAnalytics)
            },

        });

        var isValidJSON = true
        if(schainBidder) {try { JSON.parse(schainBidder) } catch(e) { isValidJSON = false }}
        if(schainGlobal) {try { JSON.parse(schainGlobal) } catch(e) { isValidJSON = false }}

        if (!isValidJSON) {
            dispatch(pushMessage("Supply Chain", "Please enter valid Json for the Schain fields", "error"));
            throw new Error("Please enter valid Json");
        }
        else await SUPPLY_CHAIN_UPDATE_MUTATION_CONTAINER({
            variables: { id: `${currentDomain}`, schainGlobal: schainGlobal, schainBidder: schainBidder },
        });
    };

    return (
        <LayoutWrapper model="domain" saveCallback={savePubmaticInfo}>
            {mutationDataCompleted && (
                <>
                {/* Main section starts here */}
                <div className="p-grid">

                    <div className="small-full-width p-col p-col-content contact-form">
                        <div className="p-panel p-component p-mb-5">
                            <SectionHeader screenName="Consent Management">
                                <div className="p-text-bold">General modules</div>
                            </SectionHeader>

                            <div className="p-panel-content p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 small-margin-top-0 small-padding-bottom-0">
                                        {/**/}
                                        <SectionHeader screenName="Supply Chain">
                                            <div className="p-d-flex p-ai-center f-width">
                                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                    Supply Chain
                                                </label>
                                            </div>
                                        </SectionHeader>
                                        <div>
                                            <div className="p-panel-content p-fluid border-bottom-none">
                                                <div className="p-grid small-margin-bottom-10">
                                                    <Tooltip target=".adpositiontooltip" />
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip('global', true)} htmlFor="schain_global">Global</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea
                                                            id=""
                                                            name="schain_global"
                                                            value={schainGlobal}
                                                            onChange={(e) => {
                                                                setSchainGlobal(e.target.value);
                                                                dispatch(registerScreenChanges("Supply Chain"));
                                                            }}
                                                            rows={10} cols={20}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-panel-content p-fluid">
                                                <div className="p-grid small-margin-bottom-10">

                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label {...tooltip('bidder_specific', true)} htmlFor="schain_bidder">Bidder Specific</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputTextarea
                                                            id=""
                                                            name="schain_bidder"
                                                            value={schainBidder}
                                                            onChange={(e) => {
                                                                setSchainBidder(e.target.value);
                                                                dispatch(registerScreenChanges("Supply Chain"));
                                                            }}
                                                            rows={10} cols={20}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/**/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-panel p-component p-mb-5">
                            <SectionHeader screenName="Consent Management">
                                <div className="p-text-bold">User modules</div>
                            </SectionHeader>

                            <div className="p-panel-content p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 small-margin-top-0 small-padding-bottom-0">
                                        <div className={`p-panel p-component ${!showCheckBox ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBox} onChange={overrideCheckBox} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override Pubmatic Identity Hub [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid border-bottom-none">
                                                            <div className="p-grid small-margin-bottom-10 ">
                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="publisher_id">Publisher Id</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="publisher_id"
                                                                        value={publisherId}
                                                                        onChange={(e) => {
                                                                            setPublisherId(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="profile_id">Profile Id</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="profile_id"
                                                                        value={profileId}
                                                                        onChange={(e) => {
                                                                            setProfileId(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*    */}

                                        <div className={`p-panel p-component ${!showCheckBoxID5 ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxID5} onChange={overrideCheckBoxID5} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override ID5 Identity Hub [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="id5_partner">Partner</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="id5_partner"
                                                                        value={id5Partner}
                                                                        onChange={(e) => {
                                                                            setid5Partner(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* LiveRamp */}

                                        <div className={`p-panel p-component ${!showCheckBoxRamp ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxRamp} onChange={overrideCheckBoxRamp} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override LiveRamp Identity Hub [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="liveramp_pi">Placement ID</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="liveramp_pi"
                                                                        value={rampPlacementId}
                                                                        onChange={(e) => {
                                                                            setRampPlacementId(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="liveramp_pi">Enable ATS Analytics</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputSwitch checked={enableAtsAnalytics} onChange={setCheckBoxEnableAtsAnalytics} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Unified Identity Hub */}
                                        <div className={`p-panel p-component ${!showCheckBoxUnified ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxUnified} onChange={overrideCheckBoxUnified} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override Unified Identity Hub [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="id5_partner">Partner ID</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="id5_partner"
                                                                        value={unifiedPartner}
                                                                        onChange={(e) => {
                                                                            setUnifiedPartner(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Unified Identity Hub2(UID2) */}
                                        <div className={`p-panel p-component ${!showCheckBoxUnified2 ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxUnified2} onChange={overrideCheckBoxUnified2} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override Unified Identity (UID2) Hub [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="uid2_public_key">Public Key</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputTextarea
                                                                        id=""
                                                                        name="uid2_public_key"
                                                                        value={unified2PubKey}
                                                                        onChange={(e) => {
                                                                            setUnified2PubKey(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                        rows={10}
                                                                        cols={30}
                                                                    />
                                                                </div>

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="uid2_sid">Subscription ID</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="uid2_sid"
                                                                        value={unified2SubscriptionID}
                                                                        onChange={(e) => {
                                                                            setUnified2SubscriptionID(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* EUID */}
                                        <div className={`p-panel p-component ${!showCheckBoxEUID ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxEUID} onChange={overrideCheckBoxEUID} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override European Unified ID (EUID) [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="uid2_public_key">Public Key</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputTextarea
                                                                        id=""
                                                                        name="uid2_public_key"
                                                                        value={eUIDPubKey}
                                                                        onChange={(e) => {
                                                                            seteUIDPubKey(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                        rows={10}
                                                                        cols={30}
                                                                    />
                                                                </div>

                                                                <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                                    <label htmlFor="uid2_sid">Subscription ID</label>
                                                                </div>
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <InputText
                                                                        id=""
                                                                        name="uid2_sid"
                                                                        value={eUIDSubscriptionID}
                                                                        onChange={(e) => {
                                                                            seteUIDSubscriptionID(e.target.value);
                                                                            dispatch(registerScreenChanges("Identity Hub"));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*    */}
                                        {/* SharedId Module */}
                                        <div className={`p-panel p-component ${!showCheckBoxSharedId ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxSharedId} onChange={overrideCheckBoxSharedId} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override SharedId  [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                                    <div className="p-d-flex p-ai-center f-width">
                                                                    <InputSwitch checked={enableSharedId} onChange={setCheckBoxEnableSharedId} />
                                                                    <label htmlFor="cb1" className="p-checkbox-label p-ml-2" onClick={setCheckBoxEnableSharedId}>Enable SharedId</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="p-panel p-component p-mb-5">
                            <SectionHeader screenName="Consent Management">
                                <div className="p-text-bold">Vendor specific modules</div>
                            </SectionHeader>

                            <div className="p-panel-content p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-sm-12 small-margin-top-0 small-padding-bottom-0">
                                        <div className={`p-panel p-component ${!showCheckBoxPermutive ? "disabled-content" : "enabled-content"}`}>
                                            <div className="p-grid">
                                                <div className="small-full-width p-col p-col-content contact-form">
                                                    <SectionHeader screenName="Identity hub">
                                                        <div className="p-d-flex p-ai-center f-width">
                                                            <InputSwitch checked={showCheckBoxPermutive} onChange={overrideCheckBoxPermutive} />
                                                            <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                                                Override Permutive RTD Provider [{" "}
                                                                {presetName && (
                                                                    <span
                                                                        style={{
                                                                            paddingBottom: "2px",
                                                                            color: "#0388e5",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            history.push("/presetidentityhub");
                                                                        }}
                                                                    >
                                                                        {`${presetName} `}
                                                                    </span>
                                                                )}
                                                                ]
                                                            </label>
                                                        </div>
                                                    </SectionHeader>
                                                    <div className="p-panel-content disable-item">
                                                        <div className="p-fluid">
                                                            <div className="p-grid small-margin-bottom-10">
                                                                <div className="p-col-12 p-sm-12 p-md-9 p-lg-9 p-fluid">
                                                                    <div className="p-inputgroup p-fluid">
                                                                        <MultiSelect
                                                                            value={selectedAdaptersPermutive}
                                                                            onChange={handleChangeAdapters}
                                                                            name="permutive_adapter_list"
                                                                            options={optionsPermutive}
                                                                            placeholder="Select Adapters for Permutive Module"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader title="Description">
                                <div className="p-d-flex p-ai-center f-width">
                                    <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold">
                                        Description
                                    </label>
                                </div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Global</h6>
                                <p>{helpText('help_text____global')}</p>
                                {/*<pre>
                                    {JSON.stringify(jsonData, null, 2)}
                                </pre>*/}
                                <h6 className="p-text-bold p-mb-0">Bidder Specific</h6>
                                <p>{helpText('help_text____bidder_specific')}</p>
                                {/*<pre>
                                    {JSON.stringify(jsonDataBidder, null, 2)}
                                </pre>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
        </LayoutWrapper>
    );
};

export default IdentityHub;
