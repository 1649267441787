import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import VideoAdSettings from './VideoAdSettings'

const VIDEO_SETTINGS_MUTATION = gql`
  mutation callVideoSettingsMutation($id: String, $settingsData: String) {
    videoSettingsMutation(input: {id: $id, settingsData: $settingsData}) {
        networkId
    }
  }
`

const DomainVideoAdSettings = () => {
	const { currentDomain } = useSelector(state => state.userInformation)
	const [currentPreset, setCurrentPreset] = useState('')
	const [videoSettingsMutation] = useMutation(VIDEO_SETTINGS_MUTATION)

	const toSnakeCase = (str) => str.split('-').join('_')

	return (
		<>
			<VideoAdSettings
			    overridable={true}
			    menuType='domain'
				saveSettingsCallback={(state) => {
					const config = Object.keys(state).reduce((acc, k) => {
						acc[toSnakeCase(k)] = state[k]
						return acc
					}, {})

					return videoSettingsMutation({
						variables: {
							id: state.isPresetOverriden 
									? `${currentDomain}`
									: currentPreset,
							settingsData: JSON.stringify({
								...config,
								domainId: currentDomain
							})
						}
					}).then(() => {
						return true
					}).catch(() => {
						return false
					})
				}}
				registerCurrentPreset={presetId => {
					setCurrentPreset(presetId)
				}}
			/>
		</>
	)
}

export default DomainVideoAdSettings
