import { gql } from "@apollo/client";

const DOMAIN_MUTATION = gql`
    mutation callDomainInfoMutation($id: String, $userId: String, $adminRole: Boolean) {
        domainInfoMutation(input: { id: $id, userId: $userId, adminRole: $adminRole }) {
            clientMutationId
            customerInfo
            presetInfo
            industryInfo
            stBuildNonceKey
        }
    }
`;

const DOMAIN_BUILD_MUTATION = gql`
    mutation domainBuildMutation($id: String, $clientId: String, $nonceKey: String, $logMessage: String) {
        domainBuildMutation(input: { id: $id, clientId: $clientId, nonceKey: $nonceKey, logMessage: $logMessage }) {
            networkId
            clientMutationId
            success
            message
        }
    }
`;

const DOMAIN_STATUS_MUTATION = gql`
    mutation domainStatusMutation($pid: String, $buildId: String) {
        domainStatusMutation(input: { pid: $pid, buildId: $buildId }) {
            networkId
            clientMutationId
            success
            message
        }
    }
`;

const CF_PURGE_MUTATION = gql`
    mutation callPurgeCloudflareCacheMutation($id: String) {
        purgeCloudflareCacheMutation(input: { id: $id }) {
            response
        }
    }
`;

const CACHE_CLEAR_MUTATION = gql`
    mutation callPurgeBuildCacheMutation($domainId: String) {
        purgeBuildCacheMutation(input: { domainId: $domainId }) {
            response
        }
    }
`;

export {
    DOMAIN_MUTATION,
    DOMAIN_BUILD_MUTATION,
    DOMAIN_STATUS_MUTATION,
    CF_PURGE_MUTATION,
    CACHE_CLEAR_MUTATION
}