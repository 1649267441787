import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearScreenChanges } from "./reducers/screenChangesReducer";
import { setUserToEdit } from "./reducers/userLoginReducer";

export const AppTopbar = () => {
    const { userID, isAgency, isAdmin } = useSelector((state) => state.userInformation);
    const screenState = useSelector((state) => state.screenStateWatcher);
    const [addClassValue, addClass] = useState(false);
    const [navLinks, setNavLinks] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const [profileLinks, setProfileLinks] = useState([])
    let assignedCustomers;

    try {
        assignedCustomers = JSON.parse(localStorage.getItem("assignedClients"));
        assignedCustomers = assignedCustomers ? assignedCustomers : [];
    } catch (err) {
        assignedCustomers = [];
    }

    const userProfile = () => {
        dispatch(setUserToEdit(userID));
        history.push(`/userprofile`);
    };

    const logOut = () => {
        localStorage.clear();
        window.location.href = "./login";
    };

    useEffect(() => {
        let defaultLinks = [
            { name: "Profile", value: userProfile },
            { name: "Billing", value: () => history.push("/billing") },
            { name: "Logout", value: logOut },
        ]

        if (isAdmin) {
            defaultLinks.splice(2, 0, {name: "Audience Settings", value: () => history.push('/census-settings')})
        }
        setProfileLinks(defaultLinks)
    }, [isAdmin])

    useEffect(() => {
        let defaultLinks = [
            { linkName: "Dashboard", href: `/` },
            { linkName: "Presets", href: `/presetinfo` },
            { linkName: "Users", href: `/usersinfo` },
            { linkName: "Domains", href: `/domains?top-menu=yes` },
            { linkName: "Creatives", href: `/creative-orders` },
            { linkName: "MarketPlace", href: "/marketplace" },
            // { linkName: "Documentation", href: `/documentation/${userID}` },
            { linkName: "Documentation", href: `/documentationcontent/9032` },
        ];
        let agencyLinks = [
            { linkName: "Creatives", href: `/creative-orders` },
            { linkName: "MarketPlace", href: "/marketplace" },
        ];

        if (isAgency) {
            setNavLinks(agencyLinks);
        } else {
            setNavLinks(defaultLinks);
        }
    }, [isAgency, userID]);

    return (
        <div className={`${addClassValue ? "small-menu-open" : ""}`}>
            <div className="layout-topbar clearfix">
                <div className="p-d-flex p-jc-between">
                    <div className="p-d-flex p-ai-center links-container">
                        <button onClick={() => addClass(!addClassValue)} type="button" className="p-d-none tablet-show small-show p-link small-menu-button color-white p-mr-4">
                            <i className={`pi ${addClassValue ? "pi-times" : "pi-bars"}`}></i>
                        </button>
                        <div className="nav-links">
                            {(localStorage.getItem("role") === "administrator" || assignedCustomers.length >= 2) && !isAgency && (
                                <a
                                    href="/customer"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        if (screenState.isModified) {
                                            let response = window.confirm("You haven't saved your changes. Do you want to leave without saving?");
                                            if (response) {
                                                history.push("/customer");
                                                dispatch(clearScreenChanges());
                                            }
                                        } else {
                                            history.push("/customer");
                                        }
                                    }}
                                >
                                    Customer
                                </a>
                            )}

                            {navLinks.map(({ linkName, href }) => {
                                return (
                                    <a
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                        key={href}
                                        href={href}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            if (screenState.isModified) {
                                                let response = window.confirm("You haven't saved your changes. Do you want to leave without saving?");
                                                if (response) {
                                                    history.push(href);
                                                    dispatch(clearScreenChanges());
                                                }
                                            } else {
                                                history.push(href);
                                            }
                                        }}
                                    >
                                        {linkName}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    <div className="layout-topbar-icons">
                        <Dropdown
                            value="User profile"
                            options={profileLinks}
                            onChange={(e) => {
                                e.value();
                            }}
                            optionLabel="name"
                            placeholder="User profile"
                            valueTemplate={() => {
                                return <span className="pi pi-user"></span>;
                            }}
                            itemTemplate={(option) => {
                                return <span className="bordered-black">{option.name}</span>;
                            }}
                        />
                    </div>
                </div>
                {addClassValue && (
                    <div className="p-dialog-mask p-component-overlay for-menu" onClick={() => addClass(!addClassValue)}>
                        test
                    </div>
                )}
            </div>
            <div className="layout-main breadcrumb"></div>
        </div>
    );
};
