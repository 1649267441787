import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, MOBILE_TAKEOVER_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.MOBILE_TAKEOVER;

export default function MobileTakeover({
    adhesionBanner,
    desktopCreativeURL,
    container,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag
}) {
    return (
        <>
            <BaseCreativeControls>
                <AssetInputOrUploadSection
                    inputValue={desktopCreativeURL}
                    inputLabel="Mobile Parallax Image (320x700)"
                    inputName={MOBILE_TAKEOVER_AD_FIELDS.desktopCreativeURL}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={adhesionBanner}
                    inputLabel="Mobile Adhesion Banner (320x100)"
                    inputName={MOBILE_TAKEOVER_AD_FIELDS.adhesionBanner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <InputTextSection
                    label={"Container selector"}
                    name={MOBILE_TAKEOVER_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={true}
                    uriEncodeValue={false}
                />
            </BaseCreativeControls>

        </>
    );
}
