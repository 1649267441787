import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import ColorPicker from "./ColorPicker";
import { CREATIVE_NAMES, MOBILE_FOOTER_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.MOBILE_FOOTER;

export default function MobileFooter({
    displayCreativeUrl,
    videoCreativeUrl,
    wording,
    expandedWording,
    ctaWording,
    ctaTextColor,
    ctaBackgroundColor,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={displayCreativeUrl}
                    inputLabel="Creative banner (4:3 ratio image)"
                    inputName={MOBILE_FOOTER_AD_FIELDS.displayCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={videoCreativeUrl}
                    inputLabel="Creative video (16:9 ratio video)"
                    inputName={MOBILE_FOOTER_AD_FIELDS.videoCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="video/*"
                    defaultExtension="mp4"
                />
                <InputTextSection
                    label="Swipe-up message (optional)"
                    name={MOBILE_FOOTER_AD_FIELDS.wording}
                    value={wording}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
                <InputTextSection
                    label="Swipe-down message (optional)"
                    name={MOBILE_FOOTER_AD_FIELDS.expandedWording}
                    value={expandedWording}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
                <InputTextSection
                    label="CTA text (optional)"
                    name={MOBILE_FOOTER_AD_FIELDS.ctaWording}
                    value={ctaWording}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
                <ColorPicker
                    label="CTA text color (optional)"
                    name={MOBILE_FOOTER_AD_FIELDS.ctaTextColor}
                    value={ctaTextColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <ColorPicker
                    label="CTA background color (optional)"
                    name={MOBILE_FOOTER_AD_FIELDS.ctaBackgroundColor}
                    value={ctaBackgroundColor}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
            </BaseCreativeControls>

        </>
    );
}
