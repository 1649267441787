import { Dialog } from 'primereact/dialog';
import Cta from './CTA';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../../reducers/messagingReducer';


export default function OrderSettingsOverlay({
    isVisible = false,
    dismissOrder,
    order,
    acceptOrder,
    hideOverlay,
    render
}) {
    const [selectedSizes, setSelectedSizes] = useState([])
    const [currentFormat, setOrderFormat] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (order) {
            setOrderFormat(order.format)
            setSelectedSizes(order.displaySizes || [])
        }
    }, [order])

    return (
        <Dialog
            visible={isVisible}
            onHide={() => {
                setOrderFormat('')
                setSelectedSizes([])
                hideOverlay()
            }}
            header={
                <h3>Campaign setup</h3>
            }
            style={{
                width: '50vw',
            }}
            contentStyle={{
                height: currentFormat === 'display' ? '60vh' : 'auto'
            }}
            footer={
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <Cta
                        ctaText="OK"
                        themeClasses='outlined marketplace-cta default'
                        tooltip='Set order settings'
                        onClick={() => {
                            if (currentFormat === 'display' && selectedSizes.length === 0) {
                                dispatch(pushMessage('Marketplace', 'You must select at least one ad size', 'error'))
                                return;
                            }
                            acceptOrder({format: currentFormat, displaySizes: selectedSizes})
                        }}
                    />
                    <Cta
                        ctaText="Cancel"
                        themeClasses='outlined marketplace-cta danger'
                        tooltip='Dismiss this order'
                        onClick={dismissOrder}
                    />
                </div>
            }
        >
            <div>
                {typeof render === 'function' && render({selectedSizes, setSelectedSizes, setOrderFormat, currentFormat})}
            </div>
        </Dialog>
    )
}
