import { gql, useMutation } from '@apollo/client';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { MultiSelect } from 'primereact/multiselect';
import { Password } from 'primereact/password';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetCurrentDomain, resetCurrentPreset, setNavigationMode, setUserToEdit } from "../reducers/userLoginReducer";
import Client from './Client';
import { getIn } from '../utilities/utils';
import SelectOptionNotice from '../SelectOptionNotice';
import { pushMessage } from '../reducers/messagingReducer';

const USER_MUTATION = gql`
  mutation callUserInfoMutation($id: String) {
    userInfoMutation(input: {id: $id}) {
        clientMutationId
        customerInfo
        allRoles
    }
  }
`

const SAVE_NEW_USER_SETTINGS_MUTATION = gql`
    mutation saveNewUserSettingsMutation(
        $newUserData: [String],
    ) {
        saveNewUserSettingsMutation(input: {
            newUserData: $newUserData,
        }) {
          networkId
          clientMutationId
          success
          message
        }
    }
`

const WrapperRow = ({children, label, style}) => {
  return (
    <div className="p-grid small-margin-bottom-10" style={style}>
      <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
        <label htmlFor="last_name">{label}</label>
      </div>
      <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
        {children}
      </div>
    </div>
  )
}

const UsersInfo = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const messages = useRef(null);
  const [userInfo, setUserInfo] = useState(null);
  const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
  const [newUserInfo, setNewUserInfo] = useState({});
  const {  currentCustomer } = useSelector(state => state.userInformation)
  const [activeIndex, setActiveIndex] = useState(-1)
  const userRoles = [
    { name: 'Customer Admin', code: 'admin' },
    { name: 'Customer User', code: 'user' },
  ]

  const [USER_MUTATION_CONTAINER] = useMutation(
    USER_MUTATION,
    {
      onCompleted(data) {
        confirm(data);
      }
    }
  )

  const [user_roles, setUser_roles] = useState([]);

  const handleChange = (param) => {
    const {target: {name, value}} = param
    setNewUserInfo({...newUserInfo, [name]: value});
  }

  const [SAVE_NEW_USER_SETTINGS_MUTATION_CONTAINER] = useMutation(
    SAVE_NEW_USER_SETTINGS_MUTATION,
    {
      onCompleted(dataUpdate) {
        confirmUpdate(dataUpdate)
        loadUsersInformation()
        setNewUserInfo({})
        setActiveIndex(-1)
      }
    }
  )

  const confirmUpdate = data => {
    const userSettingsSaved = getIn(data, 'saveNewUserSettingsMutation', 'success')
    const errorMessage = getIn(data, 'saveNewUserSettingsMutation', 'message')

    userSettingsSaved === 'true'
      ? dispatch(pushMessage('Users', 'User info updated'))
      : dispatch(pushMessage('Users', 'User info update failed: ' + errorMessage, 'error'))
  }

  const loadUsersInformation = () => {
    dispatch(resetCurrentPreset())
    dispatch(setNavigationMode('domain'))
    if (currentCustomer) {
      dispatch(resetCurrentDomain())
      USER_MUTATION_CONTAINER({
        variables: { id: currentCustomer }
      })
    }
  }

  useEffect(loadUsersInformation, [currentCustomer])

  const confirm = async data => {
    const allUserRoles = JSON.parse(data.userInfoMutation.allRoles)
    const allUsersInfo = JSON.parse(data.userInfoMutation.customerInfo)
    
    setUser_roles(allUserRoles)
    setUserInfo(allUsersInfo)
    setMutationDataCompleted(true)
  }

  if (userInfo != null) {
    userInfo.forEach(element => {
      if (element.data.user_status === '0' || 'Blocked') {
        element.data.user_status = 'Blocked'
      } else {
        element.data.user_status = 'Active'
      }
    })
  }

  const f_nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">First Name</span>
        {rowData.data.first_name}
      </React.Fragment>
    );
  }

  const l_nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Last Name</span>
        {rowData.data.last_name}
      </React.Fragment>
    );
  }

  const emailBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Email</span>
        <div className="actions" style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          textAlign: 'left !important'
        }}>
            <span style={{ marginRight: '35px' }}>{rowData.data.user_email}</span>
        </div>
      </React.Fragment>
    );
  }

    const userControlsTemplate = (rowData) => {
	const isAdmin = localStorage.getItem('admin_role') !== 'false'
	return isAdmin 
            ? <Button
		  icon="pi pi-pencil"
		  className="p-button-rounded p-button-success"
		  onClick={() => {
		      dispatch(setUserToEdit(`${rowData.ID}`))
		      history.push("/usereditscreen");
		  }}
              />
	: null
    }

    const userRoleTemplate = (userInfo) => {
	const role = userInfo.roles[0]
	return (
	    <React.Fragment>
		<span className={`user-role ${role}`}>
		    {userInfo.roles[0]}
		</span>
	    </React.Fragment>
	)
    }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const saveNewUserInfo = () => {
    if (newUserInfo.length === 0) {
      dispatch(pushMessage('Users', 'No data', 'error'))
    } else if (!newUserInfo['user_name']) {
      dispatch(pushMessage('Users', 'Please enter a username', 'error'))
    } else if (!newUserInfo['user_email']) {
      dispatch(pushMessage('Users', 'Please enter your email', 'error'))
    } else if (!newUserInfo['first_name']) {
      dispatch(pushMessage('Users', 'Please enter your first name', 'error'))
    } else if (!newUserInfo['last_name']) {
      dispatch(pushMessage('Users', 'Please enter your last name', 'error'))
    } else if (!newUserInfo['user_password']) {
      dispatch(pushMessage('Users', 'Please enter a password', 'error'))
    } else if (!newUserInfo['user_role']) {
      dispatch(pushMessage('Users', 'Please specify a user role', 'error'))
    }
    else {
      if (!validateEmail(newUserInfo['user_email'])) {
        dispatch(pushMessage('Users', 'Please enter a valid email address', 'error'))
      } else {
        if (!currentCustomer) {
          dispatch(pushMessage('Users', 'Client/customerId unavailable. User could not be created', 'error'))
        } else {
            const toSend = { ...newUserInfo, clientID: `${currentCustomer}` }
          SAVE_NEW_USER_SETTINGS_MUTATION_CONTAINER({
            variables: {
              newUserData: JSON.stringify(toSend),
            },
          })
        }
      }
    }
  }

  return (
    <>
      <Client {...props} />
      <div className="layout-main">
        {
          !currentCustomer && localStorage.getItem('admin_role') !== 'false'
            ? <SelectOptionNotice optionName="customer" />
            : <>
              {mutationDataCompleted && userInfo && (
                <div className="p-grid crud-demo">
                  <div className="p-col-12 p-md-12 p-lg-12 contact-form">
                    <div className="datatable-responsive">
                      <DataTable value={userInfo} className="p-datatable-responsive p-datatable-striped">
			  <Column field="data.user_email" header="Email" body={emailBodyTemplate}></Column>
                          <Column field="data.user_nicename" header="First Name" body={f_nameBodyTemplate}></Column>
                          <Column field="data.user_nicename" header="Last Name" body={l_nameBodyTemplate}></Column>
                          <Column header="User Role" body={userRoleTemplate}></Column>
			  {
			      localStorage.getItem('admin_role') !== 'false'
				  ? <Column header="Edit User" body={userControlsTemplate}></Column>
			          : null
			  }
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {localStorage.getItem('admin_role') !== 'false' && mutationDataCompleted && (
                <div>
                  <Messages ref={messages} />
                  <Accordion className="p-mt-2" 
                    activeIndex={activeIndex}
                    onTabChange={e => setActiveIndex(e.index)}
                  >
                    <AccordionTab header="Add New User">
                      <div className="">
                        <div className="p-panel-header p-mb-3">
                          <div className="p-text-bold text-left">Enter user Details Below to Create New User</div>
                          <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => saveNewUserInfo()} />
                        </div>

                        <div className="p-panel-content p-fluid">
                          {
                            [
                              {label: 'Username', name: 'user_name'},
                              {label: 'Email', name: 'user_email'},
                              {label: 'First Name', name: 'first_name'},
                              {label: 'Last Name', name: 'last_name'},
                              {label: 'Website', name: 'user_website'},
                            ].map(({label, name}) => {
                              return (
                                <WrapperRow label={label} key={name}>
                                  {
                                    <InputText 
                                        id={name}
                                        name={name}
                                        value={newUserInfo[name]} 
                                        onChange={handleChange} 
                                    />
                                  }
                                </WrapperRow>
                              )
                            })
                          }
                          <WrapperRow label="Password">
                            <Password 
                              id="user_password" 
                              name="user_password" 
                              value={newUserInfo['user_password']}
                              onChange={handleChange}
                            />
                          </WrapperRow>
                          <WrapperRow label="Send user notification" style={{display: 'none'}}>
                            <InputSwitch 
                              id="send_user_notification" 
                              name="send_user_notification" 
                              checked={newUserInfo['send_user_notification']} 
                              onChange={handleChange} 
                            />
                          </WrapperRow>
                          <WrapperRow label="Role">
                              <Dropdown
                                value={newUserInfo['user_role']}
                                name="user_role"
                                options={userRoles}
                                onChange={handleChange}
                                optionLabel="name"
                                placeholder={"Select user role"}
                              />
                          </WrapperRow>
                          <WrapperRow label="Other Roles" style={{display: 'none'}}>
                              <MultiSelect
                                value={newUserInfo['user_other_roles']}
                                name="user_other_roles"
                                options={user_roles}
                                onChange={handleChange}
                                optionLabel="name"
                                placeholder="Select other roles"
                              />
                          </WrapperRow>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              )}
            </>
        }
      </div>
    </>
  );
}

export default UsersInfo
