import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addToPresetBuildList } from '../../reducers/buildReducer';
import { getIn, noop } from '../../utilities/utils';
import AdPosition from '../AdPosition';
import LayoutWrapper from '../LayoutWrapper';
import saveAdPositionList, { handleInputChange, handleMultipleInput } from '../utils/functions';

const DOMAIN_EDIT_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    presetAllInfoMutation(input: {id: $id, cid: $cid}) {
        presetInfo
    }
  }
`

const AD_POSITION_UPDATE_MUTATION = gql`
  mutation callAdPositionUpdateMutation($id: String, $positionList: String, $cfToUpdate: String) {
    updatePresetAdPositionMutation(input: {id: $id, positionList: $positionList, cfToUpdate: $cfToUpdate}) {
        clientMutationId
        networkId
    }
  }
`

const MODE = 'preset'
const CUSTOM_FIELD_TO_UPDATE = 'global_ad_positions'

const PresetAdPosition = () => {
  const { currentCustomer, currentPreset } = useSelector(state => state.userInformation)
  const [queryInfoFinished, setQueryInfoFinished] = useState(false)
  const [positioningData, setPositioningData] = useState({})
  const [domainMutation] = useMutation(DOMAIN_EDIT_MUTATION)
  const [saveAdPositionMutation] = useMutation(AD_POSITION_UPDATE_MUTATION)
  const dispatch = useDispatch()

  const saveAdPositions = (presetid) => (inputList, adPositionsAlertList) => {
    const mutationVariables = {
      id: presetid,
      cfToUpdate: CUSTOM_FIELD_TO_UPDATE,
    }

    dispatch(addToPresetBuildList(currentPreset))

    return saveAdPositionList({
      inputList,
      adPositionsAlertList,
      saveMutation: saveAdPositionMutation,
      mutationVariables,
      mode: MODE,
      setPositioningData: noop,
      overriden: false,
      domainPositioningData: null,
      adtype: 'display'
    })
  }

  useEffect(() => {
    (async () => {
      if (currentCustomer && currentPreset) {
        try {
          const response = await domainMutation({ variables: { id: `${currentPreset}`, cid: `${currentCustomer}` } })
          const positionData = JSON.parse(getIn(response, 'data', 'presetAllInfoMutation', 'presetInfo'))
          setPositioningData(positionData)
          setQueryInfoFinished(true)
        } catch (err) {
          console.log('err: ', err.message)
        }
      }
    })()
  }, [currentCustomer, currentPreset])



  return (
    <LayoutWrapper model={MODE} renderOpsBar={false}>
      {
        queryInfoFinished &&
        <AdPosition
          model={MODE}
          positioningData={positioningData}
          overridable={false}
          savePositionsFunc={saveAdPositions(currentPreset)}
          handleInputChange={handleInputChange}
          handleMultipleInput={handleMultipleInput}
          currentPreset={currentPreset}
          adtype='display'
        />
      }
    </LayoutWrapper>
  )
}

export default PresetAdPosition;
