/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { MARKETPLACE_DOMAIN_STATUS_WORDINGS, MARKETPLACE_DOMAIN_STATUS, TABLE_STYLES } from "./constants";
import { PauseCta, PlayCta as ResumeCta, RemoveDomainCta, RejectDomainCta, ApproveDomainCta } from "./CTA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPercent, faEye, faCircleInfo, faCoins, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { dequeueMarketplaceAction, enqueueMarketplaceAction } from "../../../reducers/marketplaceActionsQueueReducer";
import { pushMessage } from "../../../reducers/messagingReducer";
import { editMarketplaceOrder } from "../../../reducers/marketplaceOrdersReducer";

const BuyerDomainCtas = ({ orderStatus, domain, pauseDomainLineItem, resumeDomainLineItem, order, orderIndex, currentCustomer, hasReachedDailyCap = false }) => {
    let isApproved = orderStatus === MARKETPLACE_DOMAIN_STATUS.APPROVED;
    let isPending = orderStatus === MARKETPLACE_DOMAIN_STATUS.PENDING;
    let isPausedByBuyer = orderStatus === MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_BUYER;
    let isResumed = orderStatus === MARKETPLACE_DOMAIN_STATUS.RESUMED;
    let isRejected = orderStatus === MARKETPLACE_DOMAIN_STATUS.REJECTED;
    const [isPausing, setIsPausing] = useState(false);
    const [isResuming, setIsResuming] = useState(false);
    const dispatch = useDispatch();

    if (isApproved || isResumed) {
        return (
            <div className="domain-ctas">
                <PauseCta
                    disabled={isPausing || hasReachedDailyCap}
                    showLoader={isPausing}
                    themeClasses="marketplace-cta outlined warning"
                    onClick={() => {
                        setIsPausing(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await pauseDomainLineItem(domain);
                                dispatch(dequeueMarketplaceAction());
                                setIsPausing(false);
                            })
                        );
                    }}
                />
            </div>
        );
    } else if (isPending || isRejected) {
        return (
            <div className="domain-ctas">
                <RemoveDomainCta
                    themeClasses="marketplace-cta outlined danger"
                    onClick={() => {
                        if (order.networks?.length === 1) {
                            dispatch(pushMessage("Marketplace", "There must be at least one domain selected for a marketplace order", "error"));
                            return;
                        }
                        let copy = { ...order };
                        copy.networks = copy.networks.filter((i) => i !== domain);
                        delete copy.networkStatuses[domain];
                        dispatch(editMarketplaceOrder(copy, orderIndex, currentCustomer));
                    }}
                />
            </div>
        );
    } else if (isPausedByBuyer) {
        return (
            <div className="domain-ctas">
                <ResumeCta
                    disabled={isResuming || hasReachedDailyCap}
                    showLoader={isResuming}
                    themeClasses="marketplace-cta outlined success"
                    onClick={() => {
                        setIsResuming(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await resumeDomainLineItem(domain);
                                dispatch(dequeueMarketplaceAction());
                                setIsResuming(false);
                            })
                        );
                    }}
                />
            </div>
        );
    } else {
        return null;
    }
};

const SellerDomainCtas = ({ orderStatus, domain, rejectDomainHandler, approveDomainHandler, pauseDomainLineItem, resumeDomainLineItem, hasReachedDailyCap = false }) => {
    let isApproved = orderStatus === MARKETPLACE_DOMAIN_STATUS.APPROVED;
    let isPending = orderStatus === MARKETPLACE_DOMAIN_STATUS.PENDING;
    let isRejected = orderStatus === MARKETPLACE_DOMAIN_STATUS.REJECTED;
    let isPausedBySeller = orderStatus === MARKETPLACE_DOMAIN_STATUS.PAUSED_BY_SELLER;
    let isResumed = orderStatus === MARKETPLACE_DOMAIN_STATUS.RESUMED;
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const [isPausing, setIsPausing] = useState(false);
    const [isResuming, setIsResuming] = useState(false);
    const dispatch = useDispatch();

    const resetState = () => {
        setIsApproving(false);
        setIsRejecting(false);
        setIsPausing(false);
        setIsResuming(false);
    };

    if (isApproved) {
        return (
            <div className="domain-ctas">
                <PauseCta
                    showLoader={isPausing}
                    disabled={isPausing || hasReachedDailyCap}
                    themeClasses="outlined marketplace-cta warning"
                    onClick={() => {
                        setIsPausing(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await pauseDomainLineItem(domain);
                                dispatch(dequeueMarketplaceAction());
                                resetState();
                            })
                        );
                    }}
                />
            </div>
        );
    } else if (isPending) {
        return (
            <div className="domain-ctas">
                <ApproveDomainCta
                    disabled={isRejecting || isApproving || hasReachedDailyCap}
                    showLoader={isApproving}
                    themeClasses="marketplace-cta outlined success"
                    onClick={() => {
                        setIsApproving(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await approveDomainHandler(domain);
                                dispatch(dequeueMarketplaceAction());
                                resetState();
                            })
                        );
                    }}
                />
                <RejectDomainCta
                    disabled={isRejecting || isApproving || hasReachedDailyCap}
                    themeClasses="marketplace-cta outlined danger"
                    showLoader={isRejecting}
                    onClick={() => {
                        setIsRejecting(true);
                        rejectDomainHandler(domain).then(resetState).catch(resetState);
                    }}
                />
            </div>
        );
    } else if (isRejected) {
        return (
            <div className="domain-ctas">
                <ApproveDomainCta
                    disabled={isRejecting || isApproving || hasReachedDailyCap}
                    showLoader={isApproving}
                    themeClasses="marketplace-cta outlined success"
                    onClick={() => {
                        setIsApproving(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await approveDomainHandler(domain);
                                dispatch(dequeueMarketplaceAction());
                                resetState();
                            })
                        );
                    }}
                />
            </div>
        );
    } else if (isResumed) {
        return (
            <div className="domain-ctas">
                <PauseCta
                    showLoader={isPausing}
                    disabled={isPausing || hasReachedDailyCap}
                    themeClasses="outlined marketplace-cta warning"
                    onClick={() => {
                        setIsPausing(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await pauseDomainLineItem(domain);
                                dispatch(dequeueMarketplaceAction());
                                resetState();
                            })
                        );
                    }}
                />
            </div>
        );
    } else if (isPausedBySeller) {
        return (
            <div className="domain-ctas">
                <ResumeCta
                    showLoader={isResuming}
                    disabled={isResuming || hasReachedDailyCap}
                    themeClasses="outlined marketplace-cta success"
                    onClick={() => {
                        setIsResuming(true);
                        dispatch(
                            enqueueMarketplaceAction(async () => {
                                await resumeDomainLineItem(domain);
                                dispatch(dequeueMarketplaceAction());
                                resetState();
                            })
                        );
                    }}
                />
            </div>
        );
    } else {
        return null;
    }
};

const DomainStatusTable = ({ domainList, domainToNetworkMappings, marketplaceOrder, isBuyer = true, rejectDomainHandler, approveDomainHandler, affectedDomains, pauseDomainLineItem, resumeDomainLineItem, orderIdx, currentCustomer }) => {
    const lineItemStats = useSelector((state) => state.lineItemStatsReducer);
    const [stats, setStats] = useState({});
    const [hasFinishedRequestingStats, setHasFinishedRequestingStats] = useState(false);
    const [hasImpressions, setHasImpressions] = useState(false);

    useEffect(() => {
        let lineItemId = marketplaceOrder.lineItemId;
        let v = lineItemStats[lineItemId];
        setStats(v || {});
        setHasFinishedRequestingStats(true);
    }, [lineItemStats, marketplaceOrder]);

    useEffect(() => {
        if (Object.keys(stats).length) {
            setHasImpressions(
                domainList.some(domain => 
                    stats?.[domain]?.impressions
                )
            )
        }
    }, [stats])

    return marketplaceOrder?.networkStatuses ? (
        <table css={TABLE_STYLES}>
            <thead>
                <tr>
                    <th colSpan={1}>
                        <FontAwesomeIcon icon={faLink} />
                        &nbsp;&nbsp;Domain
                    </th>
                    <th colSpan={1}>
                        <FontAwesomeIcon icon={faPercent} />
                        &nbsp;&nbsp;CTR
                    </th>
                    <th colSpan={1}>
                        <FontAwesomeIcon icon={faEye} />
                        &nbsp;&nbsp;Impressions
                    </th>
                    {!isBuyer && hasImpressions && (
                        <th colSpan={1}>
                            <FontAwesomeIcon icon={faCoins} /> &nbsp;&nbsp;CPM
                        </th>
                    )}
                    {!isBuyer && (
                        <th colSpan={1}>
                            <FontAwesomeIcon icon={faDollarSign} /> &nbsp;&nbsp;Revenue
                        </th>
                    )}
                    <th colSpan={1}>
                        <FontAwesomeIcon icon={faCircleInfo} />
                        &nbsp;&nbsp;Status
                    </th>
                    <th colSpan={1}></th>
                </tr>
            </thead>
            <tbody>
                {domainList?.map((domain) => {
                    let status = marketplaceOrder?.networkStatuses?.[domain]?.status;
                    let impressions = stats?.[domain]?.impressions || "N/A";
                    let formattedImpressions = impressions === "N/A" ? impressions : parseInt(impressions).toLocaleString();
                    let ctr = stats?.[domain]?.ctr || "N/A";
                    let costPerUnit = stats?.[domain]?.ecpm;
                    let revenue = stats?.[domain]?.revenue;
                    let hasReachedDailyCap = !!marketplaceOrder?.orderPausedByAgentScript

                    return (
                        <tr key={domain}>
                            <td>{domainToNetworkMappings?.[domain]?.domainName}</td>
                            <td>{hasFinishedRequestingStats ? ctr : <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.5em" }}></i>}</td>
                            <td>{hasFinishedRequestingStats ? formattedImpressions : <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.5em" }}></i>}</td>
                            {!isBuyer && hasImpressions && (
                                <td className="cpm">
                                    <b>{costPerUnit || marketplaceOrder.CPM}</b>
                                </td>
                            )}
                            {!isBuyer && (
                                <td className="revenue">
                                    <b>{revenue}</b>
                                </td>
                            )}
                            {
                                hasReachedDailyCap
                                    ? <td>Order reached daily limit</td>
                                    : <td className={status}>{MARKETPLACE_DOMAIN_STATUS_WORDINGS?.[status]} </td>
                            }
                            
                            <td>
                                {isBuyer ? (
                                    <BuyerDomainCtas orderStatus={status} pauseDomainLineItem={pauseDomainLineItem} resumeDomainLineItem={resumeDomainLineItem} domain={domain} order={marketplaceOrder} orderIndex={orderIdx} currentCustomer={currentCustomer} hasReachedDailyCap={hasReachedDailyCap} />
                                ) : (
                                    <SellerDomainCtas
                                        domain={domain}
                                        orderStatus={status}
                                        affectedDomains={affectedDomains}
                                        hasReachedDailyCap={hasReachedDailyCap}
                                        approveDomainHandler={async () => {
                                            await approveDomainHandler(domain);
                                        }}
                                        rejectDomainHandler={rejectDomainHandler}
                                        pauseDomainLineItem={async () => {
                                            await pauseDomainLineItem(domain);
                                        }}
                                        resumeDomainLineItem={async () => {
                                            await resumeDomainLineItem(domain);
                                        }}
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    ) : null;
};

export default DomainStatusTable;
