/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dropdown } from "primereact/dropdown";
import { ItemTemplate, ValueTemplate } from "../utilityComponents";
import OrderRow from "./OrderRow";
import { Link } from "react-router-dom";

const PaymentMethodOrderRowDropdown = ({ dropdownChangeHandler, idx, name, value, options, placeholder, icon, disabled = false }) => {
    return (
        <OrderRow idx={idx} name={name} icon={icon}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .p-inputgroup {
                        width: 70%;
                    }
                    .billing-link {
                        color: #2196f3;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                `}
            >
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className={`pi ${icon}`}></i>
                    </span>
                    <Dropdown
                        value={value}
                        idx={name + idx}
                        name={name}
                        disabled={!options.length || disabled}
                        options={options}
                        optionLabel="data"
                        onChange={(e) => dropdownChangeHandler(e, idx)}
                        placeholder={placeholder}
                        itemTemplate={(v) => <ItemTemplate cardData={v} />}
                        valueTemplate={(v) => <ValueTemplate cardData={v} />}
                    />
                </div>
                <div>
                    <Link className="billing-link" to="/billing">
                        Add a payment method
                    </Link>
                </div>
            </div>
        </OrderRow>
    );
};

export default PaymentMethodOrderRowDropdown;
