const DEFAULT_STATE = {};

const ACTIONS = {
    REGISTER_STATS: "REGISTER_STATS",
};

export default function lineItemStatsReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case ACTIONS.REGISTER_STATS:
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export function registerOrderStats(stats) {
    return {
        type: ACTIONS.REGISTER_STATS,
        data: stats || {},
    };
}
