/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { marketplaceTargetingDemographics, marketplaceTargetingEducationLevel, marketplaceTargetingPolitical, marketplaceTargetingReligion } from "./constants";
import Cta from "./CTA";
import { faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { pushMessage } from "../../../reducers/messagingReducer";
import deepcopy from 'deepcopy'

const dropdownSectionStyles = css`
    display: flex;
    align-items: flex-start;
    border: 1px solid #000;
    padding: 1em;
    .label {
        font-weight: bold;
        margin-right: 2em;
        padding-top: 0.25em;
    }
    .dropdown {
        width: 200px;
        .p-dropdown {
            width: 100%;
            &:not(:first-of-type) {
                margin-top: 1em;
            }
        }
    }
`

const targetingOptions = [
    { name: 'Ethnicity', value: 'ethnicity' },
    { name: 'Education', value: 'education' },
    { name: 'Political', value: 'political' },
    { name: 'Religion', value: 'religion' },
    { name: 'Household Income', value: 'income' },
]

const keyMappings = {
    religion: "adgt_rlg",
    ethnicity: "adgt_eth",
    income: "adgt_inc",
    education: "adgt_edu",
    political: "adgt_ppol"
}

function DropdownSection({
    targetingData,
    label,
    changeHandler,
    options
}) {
    let controls = null
    let defaultProps = {
        optionLabel: 'name',
        optionValue: 'value',
        onChange: ev => {
            changeHandler({ key: ev.target.name, value: ev.value })
        }
    }
    let defaultDropdownProps = {
        ethnicity: {
            placeholder: 'Select an ethnicity',
            value: targetingData.ethnicity || '',
            options: marketplaceTargetingDemographics
        },
        education: {
            placeholder: 'Select an education level',
            value: targetingData.education || '',
            options: marketplaceTargetingEducationLevel
        },
        political: {
            placeholder: 'Select political targeting',
            value: targetingData.political || '',
            options: marketplaceTargetingPolitical
        },
        religion: {
            placeholder: 'Select religion targeting',
            value: targetingData.religion || '',
            options: marketplaceTargetingReligion
        },
        income: {
            placeholder: 'Select household income',
            value: targetingData.income || '',
            options: options.income['median_household_income']
        }
    }

    let targeting = targetingData.targeting

    switch (targeting) {
        case 'ethnicity':
        case 'education':
        case 'political':
        case 'religion':
        case 'income':
            controls = (
                <Dropdown
                    {...defaultProps}
                    {...defaultDropdownProps[targeting]}
                    name={targeting}
                    optionValue="machineName"
                />
            )
            break
        default:
            controls = null
    }



    return (
        <section css={dropdownSectionStyles}>
            <div className="label">{label}</div>
            <div className="dropdown">
                <Dropdown
                    {...defaultProps}
                    placeholder="Select a metric"
                    name="targeting"
                    value={targeting || ''}
                    options={targetingOptions}
                />
                {controls}
            </div>
        </section>
    )
}

function TargetingSection({ children, removeSection, sectionIdx }) {
    return (
        <section css={css`
            display: flex;
            margin-top: 1em;
            justify-content: space-between;
            .calcs {
                visibility: hidden;
                margin-left: 4em;
            }
            .remove-btn {
                margin-left: 4em;
                cursor: pointer;
            }
        `}>
            <div className="controls">
                {children}
            </div>
            <div className="calcs">
                <h5>
                    <b>EST. US TARGET</b>
                </h5>
                <p>TBD</p>
            </div>
            <div className="remove-btn">
                <Button
                    onClick={() => removeSection(sectionIdx)}
                    icon="pi pi-times"
                    className="p-button-icon p-button-rounded p-button-outlined p-button-icon-only p-button-danger"
                />
            </div>
        </section>
    )
}

const CensusTargetingOverlay = ({
    onHide,
    isVisible,
    orderIndex,
    marketplaceOrders,
    censusData,
    audienceTargetingPricingData,
    saveChanges
}) => {
    const [censusTargetingState, setCensusTargetingState] = useState([])
    const [options, setOptions] = useState({})
    const [notice, setNotice] = useState('')
    const dispatch = useDispatch()

    function extractOptions(dataset, formatter) {
        return Object
            .keys(dataset)
            .reduce((acc, curr) => {
                let { vh, h, m, l, vl } = dataset[curr]
                acc[curr] = [
                    { name: `1. Very high ${formatter(vh)}`, machineName: 'vh' },
                    { name: `2. High ${formatter(h)}`, machineName: 'h' },
                    { name: `3. Medium ${formatter(m)}`, machineName: 'm' },
                    { name: `4. Low ${formatter(l)}`, machineName: 'l' },
                    { name: `5. Very low ${formatter(vl)}`, machineName: 'vl' }
                ]
                return acc
            }, {})
    }

    function thousandsFormatter(v) {
        v = parseInt(v)
        return '($' + v.toLocaleString() + ')'
    }

    function handleRuleChange(setting, idx) {
        setCensusTargetingState(
            censusTargetingState.map((rule, ruleIdx) => {
                if (idx === ruleIdx) {
                    let ruleCopy = { ...rule }
                    ruleCopy[setting.key] = setting.value
                    if (setting.value === 'income' || setting.value === 'education' || setting.value === 'ethnicity') {
                        delete ruleCopy.score
                    }
                    return ruleCopy
                }
                return rule
            })
        )
    }

    function removeSection(sectionIndex) {
        setCensusTargetingState(censusTargetingState.filter((_, idx) => idx !== sectionIndex))
    }

    function saveTargetingSettings() {
        try {
            let censusTargetingCopy = deepcopy(censusTargetingState)
            let idx = 0;
            for (let { targeting } of Object.values(censusTargetingCopy)) {
                if (!targeting) {
                    dispatch(pushMessage("Error", "A targeting must be selected", "error"))
                    return
                }
                if (!censusTargetingCopy[idx][targeting]) {
                    dispatch(pushMessage("Error", `A vaue for ${targeting} must be selected`, "error"))
                    return
                }
                ['ethnicity', 'education', 'religion', 'political', 'income'].forEach(prop => {
                    if (targeting !== prop) {
                        delete censusTargetingCopy[idx][prop]
                    }
                })
                censusTargetingCopy[idx].key = keyMappings[targeting]
                censusTargetingCopy[idx].cpm = audienceTargetingPricingData?.[keyMappings[targeting]]
                idx = idx + 1;
            }

            saveChanges(censusTargetingCopy)
            onHide()
        } catch (err) {
            onHide()
        }
    }

    useEffect(() => {
        let { adgt_edu: education, adgt_inc: income, adgt_eth: ethnicity, adgt_ppol: political, adgt_rlg: religion } = censusData

        let incomeOptions = extractOptions(income, thousandsFormatter)

        setOptions({
            income: incomeOptions,
            education: education || {},
            ethnicity: ethnicity || {},
            political: political || {},
            religion: religion || {}
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let order = marketplaceOrders[orderIndex]
        setCensusTargetingState(order.audienceTargeting?.length ? order.audienceTargeting : [{ targeting: '', score: '' }])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (censusTargetingState.length && censusTargetingState[0].targeting) {
            let v = censusTargetingState[0].targeting
            let k = keyMappings[v]
            let pricing = audienceTargetingPricingData[k]
            setNotice(
                `Targeted campaigns require a fee of $${Number((pricing / 1e3) * 1e6).toLocaleString()} per one million impressions`
            )
        }
    }, [censusTargetingState])

    return (
        <Dialog
            visible={isVisible}
            style={{ width: "40%" }}
            onHide={onHide}
            header="Marketplace Audience Targeting"
            css={css`
                .p-dialog-title {
                    font-size: 32px !important;
                    font-weight: normal !important;
                }
                .p-dialog-content {
                    min-height: 60vh;
                }
            `}
            footer={
                <section className="ctas-section" css={css`
                    display: flex;
                    justify-content: space-between;
                `}>
                    <Cta
                        cssStyles="margin-top: 1em; visibility: hidden;"
                        ctaText="Add to campaign"
                        themeClasses="cta outlined success"
                        icon={faPlusCircle}
                        onClick={() => {
                            setCensusTargetingState([
                                ...censusTargetingState,
                                { targeting: '', score: '' }
                            ])
                        }}
                    />
                    <div className="save-cta">
                        <Cta
                            ctaText="Save"
                            icon={faSave}
                            themeClasses="marketplace-cta outlined success"
                            onClick={saveTargetingSettings}
                        />
                    </div>
                </section>
            }
        >
            {
                censusTargetingState.length && censusTargetingState[0]?.targeting
                    ? (
                        <section className="title-section">
                            <h5>
                                <b>Targeting Parameters</b>
                            </h5>
                            <p>{notice}</p>
                        </section>
                    )
                    : null
            }

            {
                censusTargetingState.map((targetingData, idx) => {
                    return (
                        <TargetingSection
                            key={idx}
                            sectionIdx={idx}
                            removeSection={i => removeSection(i)}
                        >
                            <DropdownSection
                                label="Dataset"
                                targetingData={targetingData}
                                options={options}
                                changeHandler={(setting) => {
                                    handleRuleChange(setting, idx)
                                }}
                            />
                        </TargetingSection>
                    )
                })
            }
        </Dialog>
    );
};

export default CensusTargetingOverlay;
