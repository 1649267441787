const DEFAULT_STATE = {
    customerToStripeAccountMappings: {},
    infoAvailable: false,
}

const ACTIONS = {
    SET_STRIPE_ACCOUNT_TO_CUSTOMER_MAPPINGS: 'SET_STRIPE_ACCOUNT_TO_CUSTOMER_MAPPINGS',
    REGISTER_ACCOUNT_INFO: 'REGISTER_ACCOUNT_INFO'
}

function stripeInfoReducer( state = DEFAULT_STATE, action ) {
    switch (action.type) {
        case ACTIONS.SET_STRIPE_ACCOUNT_TO_CUSTOMER_MAPPINGS:
            return {...state, [action.data.property]: action.data.mappings, infoAvailable: true}
        case ACTIONS.REGISTER_ACCOUNT_INFO:
            return {[action.data.property]: {...state[action.data.property], [action.data.customerId]: action.data.stripeAccountId, infoAvailable: true}}
        default:
            return state
    }
}

function setStripeAccountInfo(mappings) {
    return {
        type: ACTIONS.SET_STRIPE_ACCOUNT_TO_CUSTOMER_MAPPINGS,
        data: {mappings, property: 'customerToStripeAccountMappings'}
    }
}

function registerAccountInfo(customerId, stripeAccountId) {
    return {
        type: ACTIONS.REGISTER_ACCOUNT_INFO,
        data: {stripeAccountId, customerId, property: 'customerToStripeAccountMappings'}
    }
}

export default stripeInfoReducer
export {
    setStripeAccountInfo,
    registerAccountInfo
}