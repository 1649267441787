/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useContext, useState } from "react";
import { MarketplaceGamDataContext } from "../../../context/marketplaceGamDataContext";
import MarketplaceOrderDataContext from "../../../context/marketplaceOrderDataContext";
import { CSV_DATA_DOWNLOAD, TABLE_STYLES } from "./constants";
import { faCalendar, faDollarSign, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cta from "./CTA";
import { useMutation } from "@apollo/client";

function CampaignStatsPanelComponent(props, downloadRef) {
    const { individualStats, gamStats } = useContext(MarketplaceGamDataContext)
    const { currentOrder: order } = useContext(MarketplaceOrderDataContext)
    const { lineItemId, name } = order

    const hasStats
        = lineItemId && lineItemId in gamStats
    const hasIndividualStats
        = individualStats[lineItemId]

    const [isDownloadInProgress, setIsDownloadInProgress] = useState(false)
    const [csvDataDownloadMutation] = useMutation(CSV_DATA_DOWNLOAD);

    const { setDownloadFileName, setDownloadUrl } = props

    async function downloadCampaignStatsBreakdown(lineItemId, filename) {
        try {
            setIsDownloadInProgress(true)
            setDownloadFileName(filename)
            let csvContents = 'Date,Impressions,Revenue\n';
            let contents = individualStats[lineItemId].reduce((acc, curr) => {
                let { formattedDate, stats: { impressions, revenue } } = curr
                acc.push(`${formattedDate},${impressions},${revenue}`)
                return acc
            }, [])
            csvContents += contents.join('\n')
            let response = await csvDataDownloadMutation({ variables: { csv: csvContents, filename } })
            let parsedResponse = JSON.parse(response.data.downloadCsvDataMutation.downloadUrl)
            setDownloadUrl(parsedResponse.url)
            setTimeout(() => {
                downloadRef.current.click()
                setIsDownloadInProgress(false)
            }, 1000)
        } catch (err) {
            setIsDownloadInProgress(false)
            setDownloadFileName('')
        }
    }

    if (hasStats && hasIndividualStats) {
        return (
            <div>
                <table css={TABLE_STYLES}>
                    <thead>
                        <tr>
                            <th colSpan={1}>
                                <FontAwesomeIcon icon={faCalendar} />
                                &nbsp;&nbsp;Date
                            </th>
                            <th colSpan={1}>
                                <FontAwesomeIcon icon={faEye} />
                                &nbsp;&nbsp;Impressions
                            </th>
                            <th colSpan={1}>
                                <FontAwesomeIcon icon={faDollarSign} /> &nbsp;&nbsp;Revenue
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            individualStats[lineItemId]?.slice(0, 7)?.map((data, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            {data.formattedDate}
                                        </td>
                                        <td>
                                            {Number(data.stats.impressions).toLocaleString()}
                                        </td>
                                        <td>
                                            {Number(data.stats.revenue).toLocaleString()}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 1em;
                    `}
                >
                    <Cta
                        themeClasses="marketplace-cta outlined success"
                        ctaText="Download CSV"
                        icon={faDownload}
                        disabled={isDownloadInProgress}
                        showLoader={isDownloadInProgress}
                        onClick={async () => {
                            await downloadCampaignStatsBreakdown(
                                lineItemId,
                                name.split(' ').join('_')
                            )
                        }}
                    />
                </div>
            </div>
        )
    }

    return <div className="notice">No progress information available yet for this campaign.</div>
}

const CampaignStatsPanel = forwardRef(CampaignStatsPanelComponent)
export default CampaignStatsPanel