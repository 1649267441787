import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../layout/flags/flags.css";
import "../layout/layout.scss";
import "../App.scss";
import { gql, useMutation } from "@apollo/client";
import { Messages } from "primereact/messages";
import { Tooltip } from "primereact/tooltip";
import SectionHeader from "./helper/SectionHeader";
import CInputSwitch from "./helper/CInputSwitch";
import brace from "brace";
import AceEditor from "react-ace";
import "brace/mode/css";
import "brace/mode/javascript";
import "brace/theme/github";
import "brace/theme/twilight";
import "brace/theme/monokai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromBuiltDomains } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import LayoutWrapper from "./LayoutWrapper";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import {InputText} from "primereact/inputtext";
import { useDomainSettings } from "../hooks/useDomainSettings";

const AD_CMP_SETTINGS_MUTATION = gql`
    mutation adCmpSettingsMutation($id: String, $adEnableDefaultCmp: Boolean, $adEnableCcpa: Boolean, $adEnableGdpr: Boolean, $adPassNetworkCode: Boolean, $adTagNetworkCode: String, $adPrebidGdprScript: String) {
        adCmpSettingsMutation(input: { id: $id, adEnableDefaultCmp: $adEnableDefaultCmp, adEnableCcpa: $adEnableCcpa, adEnableGdpr: $adEnableGdpr, adPassNetworkCode: $adPassNetworkCode, adTagNetworkCode: $adTagNetworkCode, adPrebidGdprScript: $adPrebidGdprScript }) {
            networkId
            clientMutationId
        }
    }
`;

const AdCmpConf = (props) => {
    const { currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [settingsData, setSettingsData] = useState(null);
    const [showSettingsInfo, setShowSettingsInfo] = useState(false);
    const messages = useRef(null);
    const dispatch = useDispatch();
    const {toolTipTextWithAttributes: tooltip, toolTipText: helpText} = useAdgridToolTipManager('consent_management');

    const handleChange = (param) => {
        let name = param.target.name,
            value = param.target.value,
            temp = Object.assign({}, settingsData);
        temp[name] = value;
        setSettingsData(temp);
        dispatch(registerScreenChanges("Consent Management"));
    };

    const codeEditorHandleChange = (param, code) => {
        let name = param,
            value = code,
            temp = Object.assign({}, settingsData);
        temp[name] = value;
        setSettingsData(temp);
        dispatch(registerScreenChanges("Consent Management"));
    };

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
            setSettingsData(temp_data);
            setMutationDataCompleted(true);
        }
    })

    const [AD_CMP_SETTINGS_MUTATION_CONTAINER] = useMutation(AD_CMP_SETTINGS_MUTATION, {
        onCompleted() {
            confirmUpdate();
            removeFromBuiltDomains(`${currentDomain}`);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            setShowSettingsInfo(true);
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            })
        }
    }, [currentCustomer, currentDomain]);

    const confirmUpdate = () => {
        dispatch(pushMessage("AdCmpConf", "CMP Settings updated"));
    };

    const saveAdSettings = () => {
        if (settingsData.pass_network_code && !settingsData.ad_tag_network_code) {
            dispatch(pushMessage("Consent Management (CMP)", "GAM Network Code", "error"));
            throw new Error("Please enter GAM Network Code");
        }

        return AD_CMP_SETTINGS_MUTATION_CONTAINER({
            variables: {
                id: `${currentDomain}`,
                adEnableDefaultCmp: settingsData.enable_default_cmp,
                adEnableCcpa: settingsData.enable_ccpa,
                adEnableGdpr: settingsData.enable_gdpr,

                adPassNetworkCode: settingsData.pass_network_code,
                adTagNetworkCode: settingsData.ad_tag_network_code,

                adPrebidGdprScript: settingsData.prebid_gdpr_script,
            },
        });
    };

    return (
        <LayoutWrapper model="domain" saveCallback={saveAdSettings}>
            {mutationDataCompleted && showSettingsInfo && (
                <div className="p-grid">
                    <div className="small-full-width p-col p-col-content contact-form">
                        <Messages ref={messages} />
                        <div className="p-panel p-component">
                            <SectionHeader screenName="Consent Management">
                                <div className="p-text-bold">Consent Management (CMP)</div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <Tooltip target=".adpositiontooltip" />
                                <div className="p-grid p-mt-2">
                                    <CInputSwitch
                                        name="enable_default_cmp"
                                        checked={settingsData.enable_default_cmp}
                                        changeEvent={handleChange}
                                        label_text="Enable Default CMP"
                                        {...tooltip('enable_default_cmp')}
                                    />
                                    <CInputSwitch
                                        name="enable_ccpa"
                                        checked={settingsData.enable_ccpa}
                                        changeEvent={handleChange}
                                        label_text="Enable Prebid CCPA Module."
                                        tooltip="Turinng the toogle on will enable Prebid CCPA module"
                                        {...tooltip('enable_prebid_ccpa_module')}
                                    />
                                    <CInputSwitch
                                        name="enable_gdpr"
                                        checked={settingsData.enable_gdpr}
                                        changeEvent={handleChange}
                                        label_text="Enable Prebid GDPR Module"
                                        {...tooltip('enable_prebid_gdpr_module')}
                                    />

                                    <CInputSwitch
                                        name="pass_network_code"
                                        checked={settingsData.pass_network_code}
                                        changeEvent={handleChange}
                                        label_text="Declare GAM Network Code responsible for messaging"
                                        {...tooltip('declare_gam_network_code_responsible_for_messaging')}
                                    />

                                    {settingsData.pass_network_code && <div className="p-col-12" style={{display: props.display}}>
                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                            {/*{!props.tooltip &&
                                                <label htmlFor={props.labelfor}>{props.label}</label>
                                            }
                                            {props.tooltip &&
                                                <label className="adpositiontooltip after" data-pr-tooltip={props.tooltip} htmlFor={props.labelfor}>{props.label}</label>
                                            }
                                            {props.required &&
                                                <label style={{ color: 'red' }}>*</label>
                                            }*/}
                                            <label>GAM Network Code</label>
                                            <label style={{color: 'red'}}>*</label>

                                        </div>
                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                            <InputText
                                                id={props.labelfor}
                                                name="ad_tag_network_code"
                                                value={settingsData.ad_tag_network_code}
                                                // placeholder={props.placeholder}
                                                onChange={handleChange}
                                                //disabled={!!props.disabled}
                                                /*style={{
                                                    backgroundColor: !!props.disabled ? '#ccc' : 'unset'
                                                }}*/
                                            />
                                        </div>
                                    </div>}



                                    <div className="p-col-12 p-mt-3">
                                        <div className="p-text-bold p-mb-2">
                                            <Tooltip target=".adpositiontooltip" />
                                            <label
                                                {...tooltip('cmp_embed_code', true)}
                                            >
                                                CMP Embed Code
                                            </label>
                                        </div>
                                        <AceEditor name="prebid_gdpr_script" mode="javascript" theme="monokai" value={settingsData.prebid_gdpr_script} onChange={(e) => codeEditorHandleChange("prebid_gdpr_script", e)} editorProps={{ $blockScrolling: true }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="small-full-width p-col p-col-sidebar">
                        <div className="p-panel p-component">
                            <SectionHeader>
                                <div className="p-text-bold">Descriptions</div>
                            </SectionHeader>
                            <div className="p-panel-content p-fluid">
                                <h6 className="p-text-bold p-mb-0">Enable Default CMP</h6>
                                <p>{helpText('help_text____enable_default_cmp')}</p>
                                <h6 className="p-text-bold p-mb-0">Enable Prebid CCPA Module</h6>
                                <p>{helpText('help_text____enable_prebid_ccpa_module')}</p>
                                <h6 className="p-text-bold p-mb-0">Enable Prebid GDPR Module</h6>
                                <p>{helpText('help_text____enable_prebid_gdpr_module')}</p>
                                <h6 className="p-text-bold p-mb-0">Declare GAM Network Code responsible for messaging</h6>
                                <p>{helpText('help_text____declare_gam_network_code_responsible_for_messaging')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default AdCmpConf;
