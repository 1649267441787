/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation } from "@apollo/client";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { videoAdaptersConfig } from "../constants";
import { pushMessage } from "../reducers/messagingReducer";
import { setCurrentPreset } from "../reducers/userLoginReducer";
import { getIn } from "../utilities/utils";
import BaseMultiparameterAdapter from "./adapters/components/BaseMultiparameterAdapter";
import MultiparameterInputs from "./adapters/components/MultiparameterInputs";
import SectionHeader from "./helper/SectionHeader";
import LayoutWrapper from "./LayoutWrapper";
import { getSspConfigFromAdunits, mergeVideoSspData } from "./adapters/utils/functions";
import { MULTI_FIELD_UPDATE_MUTATION } from "./adapters/utils/constants";
import { useDomainSettings } from "../hooks/useDomainSettings";

const DomainVideoPlacementID = () => {
    const { currentDomain, currentCustomer } = useSelector((state) => state.userInformation);

    const [domainlevelMutationData, setDomainlevelMutationData] = useState({});
    const [presetlevelMutationData, setPresetLevelMutationData] = useState({});

    const [adposition, setAdposition] = useState(false);
    const [presetLevelAdaptersAvailable, setPresetLevelAdaptersAvailable] = useState([]);
    const [domainLevelAdaptersAvailable, setDomainLevelAdaptersAvailable] = useState([])

    const [mutationDataCompleted, setMutationDataCompleted] = useState({ completed: false });
    const [isPresetOverriden, setPresetOverridenState] = useState(false);
    const [presetName, setPresetName] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const adaptersState = useSelector(state => state.adapterStateMutationReducer)
    const [multiFieldUpdateMutation] = useMutation(MULTI_FIELD_UPDATE_MUTATION)

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            confirm(data);
        }
    })


    useEffect(() => {
        if (currentCustomer && currentDomain) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain
            });
        }
    }, [currentDomain, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
        dispatch(setCurrentPreset(getIn(temp_data, "adv_preset", "code")));

        let presetName = getIn(temp_data, "adv_preset", "name");

        if (presetName) {
            setPresetName(presetName);
        }

        setAdposition(temp_data.video_ad_positions_for_adapters || []);
        const tempAdapterList = temp_data.enabled_video_ssps;
        const overrideSettings = temp_data.overrideVideohbIdList;
        let domainLevelData = temp_data.domainlevelSSPData ? JSON.parse(temp_data.domainlevelSSPData) : {};
        let presetLevelData = temp_data.presetlevelSSPData ? JSON.parse(temp_data.presetlevelSSPData) : {};

        setPresetLevelAdaptersAvailable(tempAdapterList ? tempAdapterList.sort() : []);
        setDomainLevelAdaptersAvailable(
            temp_data.domain_video_adapter_list && Array.isArray(temp_data.domain_video_adapter_list)
                ? temp_data.domain_video_adapter_list.sort()
                : []
        )
        setPresetOverridenState(overrideSettings);
        
        domainLevelData = getSspConfigFromAdunits(domainLevelData, 'video')
        presetLevelData = getSspConfigFromAdunits(presetLevelData, 'video')
        setDomainlevelMutationData(domainLevelData);
        setPresetLevelMutationData(presetLevelData);
        setMutationDataCompleted({ completed: true });
    };

    const toggleAdapterSelection = (adapterName) => {
        if (domainLevelAdaptersAvailable.includes(adapterName)) {
            setDomainLevelAdaptersAvailable(domainLevelAdaptersAvailable.filter(adapter => adapter !== adapterName))
        } else {
            setDomainLevelAdaptersAvailable([...domainLevelAdaptersAvailable, adapterName])
        }
    }

    const saveAdapterOverrideState = async () => {
        const defaultFields = {
            post_id: String(currentDomain),
            value: String(isPresetOverriden),
            parse_value: true
        }
        const fields = [
            { ...defaultFields, field_name: 'override_video_adapter_settings' },
            { ...defaultFields, field_name: 'override_video_slot_placement_ids' }
        ]
        const response = await multiFieldUpdateMutation({
            variables: {
                rules: JSON.stringify(fields)
            }
        })
        return response
    };

    const saveAdapterChanges = async () => {
        let {global, ...adpsState} = adaptersState
        let sspData = mergeVideoSspData(domainlevelMutationData, adpsState);
        const fields = [
            {
                post_id: String(currentDomain),
                field_name: 'video_adapter_list',
                value: JSON.stringify(domainLevelAdaptersAvailable),
                parse_value: true
            },
            {
                post_id: String(currentDomain),
                field_name: 'video_slot_placement_ids',
                value: JSON.stringify(sspData),
                parse_value: false
            }
        ]
        const response = await multiFieldUpdateMutation({
            variables: {
                rules: JSON.stringify(fields)
            }
        })
        return response
    }

    return (
        <LayoutWrapper model="domain" saveCallback={async () => {
            await saveAdapterOverrideState()
            if (isPresetOverriden) {
                await saveAdapterChanges()
            }
            dispatch(pushMessage("Creative Orders", "Saving override configuration"));
        }}>
            {mutationDataCompleted.completed && (
                <div className={`p-panel p-component ${!isPresetOverriden ? "disabled-content" : "enabled-content"}`}>
                    <SectionHeader screenName="Video SSP Adapters: ">
                        <div className="p-d-flex p-ai-center f-width">
                            <InputSwitch
                                checked={isPresetOverriden}
                                onChange={() => {
                                    setPresetOverridenState(!isPresetOverriden);
                                }}
                            />
                            <label
                                htmlFor="cb1"
                                className="p-checkbox-label p-pl-2 p-text-bold"
                                onClick={() => {
                                    setPresetOverridenState(!isPresetOverriden);
                                }}
                            >
                                Override Video Slot PlacementID Preset [
                                <span
                                    style={{
                                        paddingBottom: "2px",
                                        color: "#0388e5",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        history.push("/presetslotplacementid");
                                    }}
                                >
                                    {presetName && ` ${presetName} `}
                                </span>
                                ]
                            </label>
                        </div>
                    </SectionHeader>

                    <div className="p-panel-content slot-placement disable-item">
                        {presetLevelAdaptersAvailable.map((adapterName) => {
                            let adapterConfig = videoAdaptersConfig[adapterName];

                            return (
                                adapterConfig && (
                                    <BaseMultiparameterAdapter
                                        adapterName={adapterName}
                                        key={adapterName}
                                        settings={
                                            isPresetOverriden
                                                ? domainlevelMutationData[adapterName] || presetlevelMutationData[adapterName]
                                                : presetlevelMutationData[adapterName] || {}
                                        }
                                        adposition={adposition}
                                        postId={`${currentDomain}`}
                                        adtype="video"
                                        isPresetAdapter={false}
                                        render={(props) => <MultiparameterInputs {...props} />}
                                        toggleAdapterSelection={toggleAdapterSelection}
                                        isAdapterEnabled={
                                            isPresetOverriden
                                                ? domainLevelAdaptersAvailable.includes(adapterName)
                                                : presetLevelAdaptersAvailable.includes(adapterName)
                                        }
                                        {...adapterConfig}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default DomainVideoPlacementID;
