import { gql } from '@apollo/client'

const CUSTOM_FIELD_UPDATE_MUTATION = gql`
    mutation callFieldUpdateMutation($post_id: String, $field_name: String, $value: String, $parse_value: Boolean) { 
        fieldUpdateMutation(input: {post_id: $post_id, field_name: $field_name, value: $value, parse_value: $parse_value}) { 
            response 
        }
    }
`

const MULTI_FIELD_UPDATE_MUTATION = gql`
    mutation callMultiFieldUpdateMutation($rules: String) {
        multiFieldUpdateMutation(input: {rules: $rules}) {
            response
        }
    }
`

const MULTI_FIELD_READ_MUTATION = gql`
    mutation callMultiFieldRetrieveMutation($fieldDefinitions: String) {
        multiFieldRetrieveMutation(input: {fieldDefinitions: $fieldDefinitions}) {
            response
        }
    }
`

const SSP_UPDATE_MUTATION = gql`
    mutation callUpdateSlotPlacementID($customer_id: String, $preset_id: String, $enabled_adapters_list: String, $is_video_adapters: Boolean) { 
        updateSlotPlacementID(input: {customer_id: $customer_id, preset_id: $preset_id, enabled_adapters_list: $enabled_adapters_list, is_video_adapters: $is_video_adapters}) { 
            response 
        }
    }
`

const AMAZON_ADAPTER_UPDATE = gql`
  mutation calladpAmazonUpdateMutation($id: String, $enableA9: Boolean, $publisherID: String) {
    adpAmazonUpdateMutation(input: {id: $id, enableA9: $enableA9, publisherID: $publisherID}) {
        clientMutationId
        networkId
    }
  }
`

const AUDIENCE_TARGETING_API_UPDATE = gql`
  mutation callAudienceTargetingUpdatesMutation($audienceData: String) {
    audienceTargetingUpdatesMutation(input: {audienceData: $audienceData}) {
        response
    }
  }
`

export {
    CUSTOM_FIELD_UPDATE_MUTATION,
    SSP_UPDATE_MUTATION,
    AMAZON_ADAPTER_UPDATE,
    MULTI_FIELD_UPDATE_MUTATION,
    MULTI_FIELD_READ_MUTATION,
    AUDIENCE_TARGETING_API_UPDATE
}