import React from "react";
import BaseCreativeControls from "./BaseCreativeControls";
import { CREATIVE_ASSET_TYPES, CREATIVE_NAMES, DEFAULT_CLOSE_BTN_POSITIONS, VIDEO_POPUP_AD_FIELDS } from "./definitions";
import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection";
import VideoAssetCreativeType from "./VideoAssetCreativeType";

const CLOSE_BUTTON_POSITIONS = [
    { label: "Top left", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT },
    { label: "Top left in", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT_IN },
    { label: "Top right", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT },
    { label: "Top right in", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT_IN },
];

const CREATIVE_TYPE = CREATIVE_NAMES.VIDEO_INTERSTITIAL;

export default function VideoInterstitial({
    interstitialVideo,
    closeBtnWord,
    closeBtnPos,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    vastUrl,
    vastDuration,
    videoCreativeType,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <VideoAssetCreativeType
                    dropdownLabel="Video Creative Type"
                    types={CREATIVE_ASSET_TYPES}
                    videoCreativeTypePropertyName={VIDEO_POPUP_AD_FIELDS.videoCreativeType}
                    videoCreativeType={videoCreativeType}
                    handleChange={handleChange}
                    CREATIVE_TYPE={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    videoUrlInputLabel="Video URL"
                    videoUrlInputName={VIDEO_POPUP_AD_FIELDS.interstitialVideo}
                    videoUrl={interstitialVideo}
                    vastInputName={VIDEO_POPUP_AD_FIELDS.vastUrl}
                    vastUrl={vastUrl}
                    vastDurationInputName={VIDEO_POPUP_AD_FIELDS.vastDuration}
                    vastDuration={vastDuration}
                />
                <DropdownSection
                    label="Close button position"
                    options={CLOSE_BUTTON_POSITIONS}
                    name={VIDEO_POPUP_AD_FIELDS.closeBtnPos}
                    value={closeBtnPos}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <InputTextSection
                    label="Close button text"
                    name={VIDEO_POPUP_AD_FIELDS.closeBtnWord}
                    value={closeBtnWord}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                    required={true}
                />
            </BaseCreativeControls>
        </>
    );
}
