import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import LayoutWrapper from "./LayoutWrapper";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import { pushMessage } from "../reducers/messagingReducer";
import { isEmpty } from "../utilities/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const CUSTOM_CREATIVE_ORDERS_MUTATION = gql`
    mutation callCustomOrdersMutation($id: String) {
        customOrdersMutation(input: { id: $id }) {
            ordersInfo
        }
    }
`;

const STRIPE_TRANSACTIONS_MUTATION = gql`
    mutation callStripeTransactionsMutation($stripeCustomerId: String) {
        stripeTransactionsMutation(input: { stripeCustomerId: $stripeCustomerId }) {
            transactionListingResult
        }
    }
`;

const NoInvoicingDataSection = () => (
    <div
        css={css`
            padding: 2em 10px;
            border-left: 6px solid #000;
            margin-bottom: 1em;
            background-color: #ffc107;
            border-radius: 3px;
            > p {
                font-size: 1.15em;
            }
            a {
                color: red;
                border-bottom: 2px solid red;
            }
        `}
    >
        <p>
            <i class="pi pi-exclamation-triangle"></i>&nbsp; No invoicing data available
        </p>
    </div>
);

const LoaderSection = ({ timeout = 3000, children, data }) => {
    const [showLoader, setShowLoader] = useState(true);
    const [timeoutReached, setTimeoutReached] = useState(false);
    useEffect(() => {
        let empty = isEmpty(data);
        if (!empty) {
            setShowLoader(false);
            setTimeoutReached(false);
        } else {
            setShowLoader(true);
            setTimeout(() => setTimeoutReached(true), timeout);
        }
        return () => {
            setShowLoader(true);
            setTimeoutReached(false);
        };
    }, [data]);
    return (
        <>
            {showLoader ? (
                <div className="invoicing-data-section">
                    {timeoutReached ? (
                        <NoInvoicingDataSection />
                    ) : (
                        <div
                            css={css`
                                border-left: 3px solid #0fc000;
                                background-color: #f4fff7;
                                padding: 1.5em;
                                color: #000;
                                display: flex;
                                justify-content: space-between;
                                font-size: 12px;
                            `}
                        >
                            <span>Retrieving invoicing data... </span>
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.25em", color: "#0e70a0" }}></i>
                        </div>
                    )}
                </div>
            ) : (
                children
            )}
        </>
    );
};

const Invoices = () => {
    const { currentCustomer } = useSelector((state) => state.userInformation);
    const { customerToStripeAccountMappings } = useSelector((state) => state.stripeInfo);
    const [customOrdersMutationContainer] = useMutation(CUSTOM_CREATIVE_ORDERS_MUTATION);
    const [transactionsMutationContainer] = useMutation(STRIPE_TRANSACTIONS_MUTATION);
    const [stripeCustomerId, setStripeCustomerId] = useState(null);
    const [transactionsData, setTransactionsData] = useState([]);
    const [creativeOrders, setCreativeOrders] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCustomer) {
            setTransactionsData([]);
            setStripeCustomerId((customerToStripeAccountMappings || {})[currentCustomer]);
            customOrdersMutationContainer({ variables: { id: currentCustomer } })
                .then((response) => {
                    let orders = JSON.parse(response.data?.customOrdersMutation?.ordersInfo || "[]");
                    setCreativeOrders(
                        orders.posts_info.reduce((acc, order) => {
                            let {
                                id,
                                title,
                                fields: { customer_id: customerId, end_date: endDate, start_date: startDate, order_name: orderName, order_id: orderId, line_item_id: lineItemId },
                            } = order;
                            acc[id] = { customerId, endDate, startDate, orderName, orderId, lineItemId, id, title };
                            return acc;
                        }, {})
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [currentCustomer]);

    useEffect(() => {
        if (stripeCustomerId) {
            transactionsMutationContainer({ variables: { stripeCustomerId } })
                .then((response) => {
                    let transactionsList = JSON.parse(response.data?.stripeTransactionsMutation?.transactionListingResult || "{}");
                    let transactionsData = transactionsList?.paymentIntents?.data?.length ? transactionsList?.paymentIntents?.data : [];
                    let aggregate = transactionsData.reduce((acc, curr) => {
                        let {
                            metadata: { creative_id, order_id },
                            status,
                        } = curr;
                        if (status !== "succeeded") {
                            return acc;
                        } else if (acc[creative_id]) {
                            acc[creative_id].push(curr);
                        } else {
                            acc[creative_id] = [curr];
                        }
                        return acc;
                    }, {});
                    console.log(aggregate);
                    setTransactionsData(Object.values(aggregate));
                })
                .catch((err) => {
                    dispatch(pushMessage("Invoices: ", err.message, "error"));
                    console.log(err);
                });
        }
    }, [stripeCustomerId]);

    const amountBodyTemplate = (rowData) => {
        let amount = rowData.amount / 100;
        return (
            <span>
                <i className="pi pi-dollar"></i>
                &nbsp;{amount}
            </span>
        );
    };

    const dateBodyTemplate = (rowData) => {
        let created = new Date(rowData.created * 1000).toLocaleDateString();
        return (
            <span>
                <i className="pi pi-calendar"></i>
                &nbsp;{created}
            </span>
        );
    };

    const transactionIdBodyTemplate = (rowData) => {
        let id = rowData.id;
        return (
            <span>
                <i className="pi pi-info-circle"></i>
                &nbsp;{id}
            </span>
        );
    };

    const metadataTemplate = (rowData, fieldName, iconClass, fn) => {
        let f = (fn || ((v) => v))(rowData?.metadata?.[fieldName]);
        return (
            <span>
                <i className={iconClass}></i>
                &nbsp;{f}
            </span>
        );
    };

    const creativeIdBodyTemplate = (rowData) => metadataTemplate(rowData, "creative_id", "pi pi-info-circle");
    const orderIdBodyTemplate = (rowData) => metadataTemplate(rowData, "order_id", "pi pi-info-circle");
    const impressionBodyTemplate = (rowData) => metadataTemplate(rowData, "total_impression", "pi pi-eye", (impressions) => Number.parseInt(impressions).toLocaleString());

    return (
        <>
            <LayoutWrapper model="invoices" renderOpsBar={false}>
                <h1>Invoices</h1>
                {stripeCustomerId ? (
                    <LoaderSection data={transactionsData}>
                        <Accordion multiple>
                            {transactionsData
                                .filter((transactionData) => {
                                    let [
                                        {
                                            metadata: { creative_id },
                                        },
                                    ] = transactionData;
                                    let { lineItemId, title } = creativeOrders[creative_id] || {};
                                    return title && lineItemId;
                                })
                                .map((transaction, tid) => {
                                    let [
                                        {
                                            metadata: { creative_id },
                                            status,
                                        },
                                    ] = transaction;
                                    let { lineItemId, orderId, orderName, title } = creativeOrders[creative_id] || {};
                                    // console.log(lineItemId, orderId, orderName, title);
                                    console.log(transaction);
                                    return (
                                        <AccordionTab
                                            key={tid}
                                            headerTemplate={
                                                <div
                                                    css={css`
                                                        .order-title {
                                                            margin-bottom: 0;
                                                            font-weight: bold;
                                                        }
                                                        .header-item {
                                                            display: inline-block;
                                                            margin-right: 0.5em;
                                                            color: #84878b;
                                                            font-weight: 400;
                                                            font-size: 12px;
                                                            > span {
                                                                color: #495057;
                                                            }
                                                        }
                                                    `}
                                                >
                                                    <h6 className="order-title">{title}</h6>
                                                </div>
                                            }
                                        >
                                            <DataTable
                                                value={transaction}
                                                className="p-datatable-striped"
                                                css={css`
                                                    table {
                                                        table-layout: auto;
                                                    }
                                                    th {
                                                        background-color: #0288d1;
                                                        color: #fff;
                                                    }
                                                `}
                                            >
                                                <Column header="Amount" body={amountBodyTemplate}></Column>
                                                <Column header="Date" body={dateBodyTemplate}></Column>
                                                <Column header="Transaction ID" body={transactionIdBodyTemplate}></Column>
                                                <Column header="Creative ID" body={creativeIdBodyTemplate}></Column>
                                                <Column header="Order ID" body={orderIdBodyTemplate}></Column>
                                                <Column header="Impressions Delivered" body={impressionBodyTemplate}></Column>
                                            </DataTable>
                                            {/* {transaction.map((t, index) => {
                                                console.log(t);
                                                return (
                                                    <div
                                                        key={index}
                                                        css={css`
                                                            margin: 0.5em;
                                                            padding: 0.5em;
                                                            border-bottom: 2px inset #d8d8d8;
                                                            span {
                                                                display: inline-block;
                                                                min-width: 150px;
                                                                font-weight: bold;
                                                            }
                                                        `}
                                                    >
                                                        <div>
                                                            <span>Amount:</span> ${(t.amount / 100).toLocaleString()}
                                                        </div>
                                                        <div>
                                                            <span>Created:</span> {new Date(t.created * 1000).toLocaleDateString()}
                                                        </div>
                                                        {t.description && (
                                                            <div>
                                                                <span>Description:</span>
                                                                {t.description}
                                                            </div>
                                                        )}
                                                        {t?.metadata?.total_impression && (
                                                            <div>
                                                                <span>Total impressions:</span>
                                                                {parseInt(t?.metadata?.total_impression).toLocaleString()}
                                                            </div>
                                                        )}
                                                        <div>
                                                            <span>Payment ID:</span> {t.id}
                                                        </div>
                                                        <div>
                                                            <span>Customer ID:</span> {t.customer}
                                                        </div>
                                                    </div>
                                                );
                                            })} */}
                                        </AccordionTab>
                                    );
                                })}
                        </Accordion>
                    </LoaderSection>
                ) : (
                    <NoInvoicingDataSection />
                )}
            </LayoutWrapper>
        </>
    );
};

export default Invoices;
