const ACTIONS = {
    REGISTER_DOMAINS: 1,
    ADD_DOMAIN: 2,
    REMOVE_DOMAIN: 3,
    CLEAR_BUILD_LIST: 4,
    MARK_DOMAINS_AS_FINISHED: 5,
    SET_TO_BUILDING: 6,
    SET_DOMAIN_STATUS: 7,
    SET_POLLING_SERVER_STATE: 8,
    SET_LATEST_BUILD_ID: 9,
    SET_BUILD_LOG_MESSAGE: 10,
    ADD_DOMAINS: 11,
    SET_MODIFIED_DOMAINS: 12,
    SET_SORTED_DOMAINS: 13,
    SET_CACHE_CLEAR_STATE: 14
}

export default function multiBuildSystemReducer(state = {}, {type, data}) {
    let queued_a_domain = false;
    switch (type) {
        case ACTIONS.REGISTER_DOMAINS: {
            let {customerId, domains, buildNonceKey} = data
            let customerState = state[customerId] || {}
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    domains,
                    buildNonceKey,
                    buildList: customerState?.buildList || [],
                    domainsMappings: customerState?.domainsMappings || {},
                    isBuilding: false,
                    pollingServer: false,
                    isClearingCache: false,
                    logMessage: '',
                }
            }
        }
        case ACTIONS.ADD_DOMAIN:
        case ACTIONS.REMOVE_DOMAIN: {
            let {domainId, customerId} = data
            let customerState = state[customerId]
            let customerBuildList = customerState?.buildList || []
            let customerDomainsMappings = customerState?.domainsMappings || {}
            let buildList = type === ACTIONS.ADD_DOMAIN
                ? [...customerBuildList, domainId]
                : [...customerBuildList].filter(domain => domain !== domainId)
            let domainsMappings = {...customerDomainsMappings}

            if (type === ACTIONS.ADD_DOMAIN) {
                domainsMappings[domainId] = 'Queued'
                queued_a_domain = true;
            } else {
                delete domainsMappings[domainId]
            }

            return {
                ...state,
                queued_a_domain,
                [customerId]: {
                    ...customerState,
                    buildList,
                    domainsMappings
                }
            }
        }
        case ACTIONS.CLEAR_BUILD_LIST: {
            let customerState = state[data.customerId]
            return {
                ...state,
                [data.customerId]: {
                    ...customerState,
                    buildList: [],
                    domainsMappings: {},
                    isBuilding: false,
                }
            }
        }
        case ACTIONS.ADD_DOMAINS: {
            let {customerId, domainsIds = [], presetId} = data
            let customerState = state[customerId] || {}
            let customerStateClone = {...customerState}
            customerStateClone.domains = customerStateClone.domains || []

            customerStateClone.buildList =
                domainsIds.length
                    ? domainsIds.map(domain => domain.domain_id)
                    : customerStateClone.domains
                        .filter(domain => {

                            if (!presetId) {
                                return true
                            }
                            return domain.presetId === presetId
                        })
                        .map(domain => domain.domain_id)

            customerStateClone.domainsMappings = {}
            customerStateClone.buildList.forEach(domain => {
                customerStateClone.domainsMappings[domain] = 'Queued'
                queued_a_domain = true;
            })

            return {
                ...state,
                queued_a_domain,
                [customerId]: customerStateClone
            }
        }
        case ACTIONS.MARK_DOMAINS_AS_FINISHED: {
            let customerState = state[data.customerId]
            let domainsMappings = {...customerState.domainsMappings}

            for (let [d] of Object.entries(domainsMappings)) {
                domainsMappings[d] = 'Finished'
            }

            return {
                ...state,
                [data.customerId]: {
                    ...customerState,
                    isBuilding: false,
                    domainsMappings
                }
            }
        }
        case ACTIONS.SET_TO_BUILDING: {
            let {customerId, isBuilding} = data
            let customerState = state[customerId]
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    isBuilding
                }
            }
        }
        case ACTIONS.SET_DOMAIN_STATUS: {
            let { customerId, domainId, status } = data
            let customerState = state[customerId]
            let domainMappings = customerState?.domainsMappings || {}
            let customerDomainMappings = {...domainMappings, [domainId]: status}
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    domainsMappings: customerDomainMappings
                }
            }
        }
        case ACTIONS.SET_POLLING_SERVER_STATE: {
            let {customerId, isPollingServer} = data
            let customerState = state[customerId]
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    pollingServer: isPollingServer
                }
            }
        }
        case ACTIONS.SET_LATEST_BUILD_ID: {
            let {customerId, buildId, domainId, lastBuildWrapperVersion} = data
            let customerState = state[customerId]
            let domains = customerState.domains.map(domainData => {
                if (domainData.domain_id !== domainId) {
                    return domainData
                }
                return {...domainData, latestBuildId: buildId, lastBuildWrapperVersion}
            })
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    domains
                }
            }
        }
        case ACTIONS.SET_BUILD_LOG_MESSAGE: {
            let {customerId, logMessage} = data
            let customerState = state[customerId]
            let buildList = new Set(customerState.buildList)

            let domains = customerState.domains.map(domainData => {
                if (buildList.has(domainData.domain_id)) {
                    return {...domainData, logMessage}
                }
                return domainData
            })
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    domains,
                    logMessage
                }
            }
        }
        case ACTIONS.SET_MODIFIED_DOMAINS: {
            let {customerId, domainsIds} = data
            let customerState = state[customerId] || {}

            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    modifiedDomains: new Set(domainsIds)
                }
            }
        }
        case ACTIONS.SET_SORTED_DOMAINS: {
            let {customerId, domains} = data
            let customerState = state[customerId] || {}
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    domains
                }
            }
        }
        case ACTIONS.SET_CACHE_CLEAR_STATE: {
            const {customerId, isClearingCache} = data
            let customerState = state[customerId] || {}
            return {
                ...state,
                [customerId]: {
                    ...customerState,
                    isClearingCache
                }
            }
        }
        default:
            return state
    }
}

function registerCustomerBuildInfo({
    customerId,
    domains,
    buildNonceKey,
}) {
    domains = domains || []
    return {
        type: ACTIONS.REGISTER_DOMAINS,
        data: {
            customerId,
            domains,
            buildNonceKey
        }
    }
}


function addDomain({domainId, customerId}) {
    return {
        type: ACTIONS.ADD_DOMAIN,
        data: {
            domainId,
            customerId
        }
    }
}

function removeDomain({domainId, customerId}) {
    return {
        type: ACTIONS.REMOVE_DOMAIN,
        data: {
            domainId,
            customerId
        }
    }
}

function clearBuildList({customerId}) {
    return {
        type: ACTIONS.CLEAR_BUILD_LIST,
        data: {
            customerId
        }
    }
}

function markDomainsAsFinished({customerId}) {
    return {
        type: ACTIONS.MARK_DOMAINS_AS_FINISHED,
        data: {
            customerId
        }
    }
}

function setBuildingState({customerId, isBuilding = false}) {
    return {
        type: ACTIONS.SET_TO_BUILDING,
        data: {
            customerId,
            isBuilding
        }
    }
}

function setDomainStatus({customerId, domainId, status}) {
    return {
        type: ACTIONS.SET_DOMAIN_STATUS,
        data: {
            customerId,
            domainId,
            status
        }
    }
}

function setDomainToInProgress({customerId, domainId}) {
    return setDomainStatus({customerId, domainId, status: 'Building'})
}

function setDomainToFinished({customerId, domainId}) {
    return setDomainStatus({customerId, domainId, status: 'Finished'})
}

function setPollingServerState({customerId, isPollingServer}) {
    return {
        type: ACTIONS.SET_POLLING_SERVER_STATE,
        data: {
            customerId,
            isPollingServer
        }
    }
}

function setLatestBuildId({customerId, buildId, domainId, lastBuildWrapperVersion}) {
    return {
        type: ACTIONS.SET_LATEST_BUILD_ID,
        data: {
            customerId,
            buildId,
            domainId,
            lastBuildWrapperVersion
        }
    }
}

function setBuildMessage({customerId, logMessage}) {
    return {
        type: ACTIONS.SET_BUILD_LOG_MESSAGE,
        data: {
            customerId,
            logMessage
        }
    }
}

function addDomainsToBuildList({customerId, domainsIds, presetId}) {
    return {
        type: ACTIONS.ADD_DOMAINS,
        data: {
            customerId,
            domainsIds,
            presetId
        }
    }
}

function setModifiedDomainsList({customerId, domainsIds}) {
    return {
        type: ACTIONS.SET_MODIFIED_DOMAINS,
        data: {
            customerId,
            domainsIds
        }
    }
}

function setSortedDomains({customerId, domains}) {
    return {
        type: ACTIONS.SET_SORTED_DOMAINS,
        data: {
            customerId,
            domains
        }
    }
}

function setCloudflareCacheClearState({customerId, isClearingCache}) {
    return {
        type: ACTIONS.SET_CACHE_CLEAR_STATE,
        data: {
            customerId,
            isClearingCache
        }
    }
}

export {
    registerCustomerBuildInfo,
    addDomain,
    removeDomain,
    clearBuildList,
    markDomainsAsFinished,
    setBuildingState,
    setDomainToInProgress,
    setDomainToFinished,
    setPollingServerState,
    setLatestBuildId,
    setBuildMessage,
    addDomainsToBuildList,
    setModifiedDomainsList,
    setSortedDomains,
    setCloudflareCacheClearState,
}
