/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setCurrentPreset } from "../reducers/userLoginReducer";
import LayoutWrapper from "./LayoutWrapper";
import { first } from "../utilities/utils";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { pushMessage } from "../reducers/messagingReducer";
import SectionHeader from "./helper/SectionHeader";
import SaveButton from "./SaveButton";
import BuilDomainButton from "./BuildDomainButton";
import { registerScreenChanges } from "../reducers/screenChangesReducer";

const headerInfoBitStyle = css`
    margin-bottom: 0;
    display: inline-block;
    margin-right: 1em;
    .infobit-name {
        display: inline-block;
        margin-right: 0.5em;
        color: #84878b;
        font-weight: 400;
        font-size: 12px;
    }
    .infobit-value {
        color: #495057;
        font-weight: 400;
    }
`;

const DROPDOWNS = {
    playerType: [
        { name: "JW player", value: "jw_player" },
        { name: "Brid", value: "brid" },
    ],
};

const defaultNewVideoPlayerPosition = {
    playerSlot: "",
    description: "",
    playerType: first(DROPDOWNS.playerType).value,
    playerId: "",
    playlistId: "",
    feedUrl: "",
    displayName: "",
};

function recurseNames(name, idx, allNames) {
    let newName = name + idx; // append number to name
    if (allNames.has(newName)) return recurseNames(name, idx + 1, allNames);
    return newName;
}

const InputSection = ({ sectionName, children }) => (
    <section className="p-grid p-fluid">
        <div className="position-tag p-col-12 p-sm-12 p-md-3 p-lg-2 p-mt-2 small-margin-top-0 small-padding-bottom-0">{sectionName}</div>
        <div className="position-controls p-col-12 p-sm-12 p-md-9 p-lg-9 no-pad-left no-pad-right">{children}</div>
    </section>
);

const VideoPlayerPositions = ({ mode, presetName, presetId, videoPlayerPositions, isOverridingPreset = false, overridable = false, setPresetVideoPlayerPositions, setDomainVideoPlayerPositions, setOverridingStatus, saveHandler, usedMachineNames = new Set(), mutationCompleted }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [rowIdx, setRowIdx] = useState(0);

    const handleInputChange = (name, value, idx) => {
        let upstreamHandler = mode === "preset" ? setPresetVideoPlayerPositions : setDomainVideoPlayerPositions;
        let copy = videoPlayerPositions.map((p, i) => {
            if (i === idx) {
                if (name === "displayName") {
                    if (usedMachineNames.has(value)) {
                        value = recurseNames(value, 1, usedMachineNames);
                    }
                    return { ...p, [name]: value, playerSlot: value.split(" ").join("_") };
                }
                return { ...p, [name]: value };
            }
            return p;
        });
        upstreamHandler(copy);
        dispatch(registerScreenChanges("Video Player Positions"));
    };

    const renderFooter = () => (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => {
                    let upstreamHandler = mode === "preset" ? setPresetVideoPlayerPositions : setDomainVideoPlayerPositions;
                    setDisplayConfirmation(false);
                    upstreamHandler(videoPlayerPositions.filter((_, idx) => idx !== rowIdx));
                }}
                autoFocus
            />
        </div>
    );

    return (
        <LayoutWrapper model={mode} renderOpsBar={false}>
            <div className={`p-panel p-component ${!isOverridingPreset ? "enabled-content" : "enabled-content"}`}>
                <SectionHeader screenName="Video player ad positions">
                    <div className="p-d-flex p-ai-center f-width">
                        {overridable && (
                            <>
                                <InputSwitch
                                    checked={isOverridingPreset}
                                    onChange={() => {
                                        setOverridingStatus(!isOverridingPreset);
                                        dispatch(registerScreenChanges("Video Player Positions"));
                                    }}
                                />
                                <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold" onClick={() => {}}>
                                    {presetName && (
                                        <span
                                            onClick={() => {
                                                dispatch(setCurrentPreset(presetId));
                                                history.push("/preset-video-player-positions");
                                            }}
                                        >
                                            {" "}
                                            Override Video Player Positions [
                                            <span
                                                style={{
                                                    paddingBottom: "2px",
                                                    color: "#0388e5",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {` ${presetName} `}
                                            </span>
                                            ]
                                        </span>
                                    )}
                                </label>
                            </>
                        )}
                    </div>
                </SectionHeader>
                {mutationCompleted ? (
                    <div className="card p-panel-content disable-item">
                        <div className="p-datatable p-fluid p-component p-datatable-striped">
                            <div className="p-datatable-wrapper">
                                <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter()} onHide={() => setDisplayConfirmation(false)}>
                                    <div className="confirmation-content p-d-flex p-ai-center">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to delete this item?</span>
                                    </div>
                                </Dialog>
                                <Accordion multiple>
                                    {videoPlayerPositions.map((position, idx) => {
                                        let { playerSlot, playerType, playerId, playlistId, feedUrl, displayName, description } = position;
                                        return (
                                            <AccordionTab
                                                key={idx}
                                                header={
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            align-items: center;
                                                            .remove-row-btn {
                                                                margin-left: auto;
                                                            }
                                                        `}
                                                    >
                                                        <div>
                                                            <p style={{ marginBottom: 0 }}>
                                                                {displayName}
                                                                <span style={{ fontWeight: 400 }}>{description && [" - [" + description + "]"]}</span>
                                                            </p>
                                                            <p css={headerInfoBitStyle}>
                                                                <span className="infobit-name">Player type:</span>
                                                                <span className="infobit-value">{playerType}</span>
                                                            </p>
                                                            <p css={headerInfoBitStyle}>
                                                                <span className="infobit-name">Player ID:</span>
                                                                <span className="infobit-value">{playerId}</span>
                                                            </p>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-times"
                                                            className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only remove-row-btn"
                                                            tooltip="Remove row"
                                                            tooltipOptions={{ position: "top" }}
                                                            disabled={mode === "domain" && !isOverridingPreset}
                                                            onClick={() => {
                                                                setDisplayConfirmation(true);
                                                                setRowIdx(idx);
                                                                dispatch(registerScreenChanges("Video Player Positions"));
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            >
                                                {[
                                                    { sectionName: "Display Name", value: displayName, name: "displayName" },
                                                    { sectionName: "Description", value: description, name: "description" },
                                                    { sectionName: "Player Slot", value: playerSlot, name: "playerSlot" },
                                                    { sectionName: "Player Type", value: playerType, name: "playerType", options: DROPDOWNS.playerType },
                                                    { sectionName: "Player ID", value: playerId, name: "playerId" },
                                                    { sectionName: "playlist ID", value: playlistId, name: "playlistId" },
                                                    { sectionName: "Feed URL", value: feedUrl, name: "feedUrl" },
                                                ].map(({ sectionName, value, name, options }, sectionIndex) => {
                                                    if (options) {
                                                        return (
                                                            <InputSection sectionName={sectionName} key={sectionIndex}>
                                                                <Dropdown name={name} options={options} disabled={mode === "domain" && !isOverridingPreset} value={value} optionLabel="name" onChange={(e) => handleInputChange(name, e.target.value, idx)} />
                                                            </InputSection>
                                                        );
                                                    } else
                                                        return (
                                                            <InputSection sectionName={sectionName} key={sectionIndex}>
                                                                <InputText
                                                                    value={value}
                                                                    disabled={name === "playerSlot" || (mode === "domain" && !isOverridingPreset)}
                                                                    onChange={(e) => {
                                                                        handleInputChange(name, e.target.value, idx);
                                                                    }}
                                                                />
                                                            </InputSection>
                                                        );
                                                })}
                                            </AccordionTab>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                        {(mode === "preset" || isOverridingPreset) && (
                            <div className="p-d-flex p-jc-end p-mt-3">
                                <Button
                                    className="hide-item"
                                    label="Add Row"
                                    icon="pi pi-plus"
                                    onClick={() => {
                                        let upstreamHandler = mode === "preset" ? setPresetVideoPlayerPositions : setDomainVideoPlayerPositions;
                                        upstreamHandler([...videoPlayerPositions, { ...defaultNewVideoPlayerPosition }]);
                                        dispatch(registerScreenChanges("Video Player Positions"));
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <ProgressSpinner />
                )}
                <div className="ops-bar">
                    <SaveButton
                        saveCallback={async () => {
                            return saveHandler()
                                .then(() => {
                                    dispatch(pushMessage("Video Player Positions", "Video player positions configuration have been saved!"));
                                })
                                .catch((err) => {
                                    dispatch(pushMessage("Video Player Positions", `There has been an error while trying to update the video player positions: ${err.message}`, "error"));
                                });
                        }}
                    />
                    {mode === "domain" && <BuilDomainButton />}
                </div>
            </div>
        </LayoutWrapper>
    );
};

export default VideoPlayerPositions;
