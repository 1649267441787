/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tree } from "primereact/tree";
import { useContext, useEffect, useState } from "react";
import { MarketplaceGamDataContext } from "../../../context/marketplaceGamDataContext";
import MarketplaceOrderDataContext from "../../../context/marketplaceOrderDataContext";

const iconStyles = css`
    .pi {
        margin: 0 0.5em;
        border: 1px solid #939393;
        padding: 2px;
        box-shadow: 1px 1px 3px #ccc;
        cursor: pointer;
        &:hover {
            color: #4e4eea;
            border-color: #4e4eea;
        }
    }
`;

const treeStyles = css`
    display: flex;
    .selection-column,
    .results-column {
        flex-basis: 50%;
        padding: 1em;
        min-height: 150px;
        max-height: 300px;
        overflow-y: scroll;
    }
    .selection-column {
        border-right: 1px inset #ccc;
    }
    .top-controls {
        display: flex;
        justify-content: space-between;
        .control {
            color: #2196f3;
            cursor: pointer;
        }
    }
    .p-tree {
        border: none !important;
    }
    .p-treenode-label {
        width: 100%;
    }
    .p-treenode-content {
        padding: 0 0.5rem !important;
        &:hover {
            background-color: #ececec;
        }
        .p-tree-toggler {
            order: 2;
            &:hover,
            &:focus {
                box-shadow: none !important;
                outline: none !important;
            }
        }
    }
    .row-control {
        display: flex;
        align-items: center;
        width: 100%;
        .cta {
            display: none;
            ${iconStyles}
        }
        .cta {
            margin-left: auto;
        }
        &:hover {
            .cta {
                display: inline-block;
            }
        }
    }
    .dma-badge {
        background-color: #b2deb2;
        color: #000;
        padding: 0.25em;
        border-radius: 3px;
        font-size: 10px;
        display: inline-block;
        margin-left: 1em;
    }
`;

const SelectionItem = ({ onClick, label }) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                p {
                    margin-bottom: 0;
                }
                ${iconStyles}
                margin-bottom: 1em;
            `}
        >
            <p>{label}</p>
            <i className="pi pi-times" onClick={onClick}></i>
        </div>
    );
};

const DEFAULT_SELECTION_STATE = {
    isInclusionMode: false,
    isInInitialState: true,
};

export default function OptionList({ handleSelection, allowSearch = false }) {
    const { geoTargetsInfo } = useContext(MarketplaceGamDataContext)
    const { currentOrder: order, currentOrderIdx: idx } = useContext(MarketplaceOrderDataContext)

    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [selectionCount, setSelectionCount] = useState(0);
    const [selectionRules, setSelectionRules] = useState({ ...DEFAULT_SELECTION_STATE });
    const [inclusionMappings, setInclusionMappings] = useState({});

    useEffect(() => {
        setSelectionCount((order.includedGeographies?.length || 0) + (order.excludedGeographies?.length || 0));
        setIncluded(order.includedGeographies || []);
        setExcluded(order.excludedGeographies || []);
    }, [order]);

    useEffect(() => {
        if (!included.length && !excluded.length) {
            setSelectionRules({ ...DEFAULT_SELECTION_STATE });
            setInclusionMappings({});
            return;
        }
        if (included.length) {
            setInclusionMappings(
                included.reduce((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {})
            );
            setSelectionRules({ ...selectionRules, isInInitialState: false, isInclusionMode: true });
        }
        if (excluded.length) {
            setSelectionRules({ ...selectionRules, isInInitialState: false, isInclusionMode: false });
        }
    }, [included, excluded]);

    const handleOperation = (node, operation, targetProperty = "") => handleSelection(order, idx, operation, node, targetProperty);
    const handleInclusion = (node) => handleOperation(node, "select");
    const handleExclusion = (node) => handleOperation(node, "remove");
    const handleRemove = (node, targetProperty) => handleOperation(node, "clear", targetProperty);
    const handleRemoveAll = (node) => handleOperation(node, "clear-all");

    return (
        <div css={treeStyles}>
            <div className="selection-column">
                <Tree
                    value={geoTargetsInfo}
                    filter
                    filterBy="name"
                    selectable={false}
                    draggable={false}
                    nodeTemplate={(node) => {
                        let parentId = node.canonicalParentId;
                        let isDma = node.dma
                        
                        return (
                            <span key={node.id} className="row-control">
                                {node.name}
                                {isDma ? <span className="dma-badge">DMA</span> : null}
                                <div className="cta">
                                    {(selectionRules.isInInitialState || (selectionRules.isInclusionMode && !(parentId in inclusionMappings))) && (
                                        <span
                                            className="pi pi-check"
                                            onClick={() => {
                                                setSelectionRules({ ...selectionRules, isInInitialState: false, isInclusionMode: true });
                                                handleInclusion(node);
                                            }}
                                        ></span>
                                    )}
                                    {(selectionRules.isInInitialState || !selectionRules.isInclusionMode || (selectionRules.isInclusionMode && parentId in inclusionMappings)) && (
                                        <span
                                            className="pi pi-ban"
                                            onClick={() => {
                                                setSelectionRules({ ...selectionRules, isInInitialState: false, isInclusionMode: false });
                                                handleExclusion(node);
                                            }}
                                        ></span>
                                    )}
                                </div>
                            </span>
                        );
                    }}
                />
            </div>
            <div className="results-column">
                <div className="top-controls" style={{ marginTop: allowSearch ? "4em" : 0 }}>
                    <span>{selectionCount} items selected</span>
                    <span className="control" onClick={handleRemoveAll}>
                        Clear all
                    </span>
                </div>
                {included.length ? (
                    <>
                        <h4 style={{ color: "#4caf50" }}>Included</h4>
                        {included.map((item) => (
                            <SelectionItem label={item.name} key={item.id} onClick={() => handleRemove(item, "includedGeographies")} />
                        ))}
                    </>
                ) : null}
                {excluded.length ? (
                    <>
                        <h4 style={{ color: "red" }}>Excluded</h4>
                        {excluded.map((item) => (
                            <SelectionItem label={item.name} key={item.id} onClick={() => handleRemove(item, "excludedGeographies")} />
                        ))}
                    </>
                ) : null}
            </div>
        </div>
    );
}
