import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import BaseCreativeControls from "./BaseCreativeControls";
import { CREATIVE_NAMES, LEADERBOARD_PARALLAX_CONFIG_AD_FIELDS } from "./definitions";

const CREATIVE_TYPE = CREATIVE_NAMES.LEADERBOARD_PARALLAX;

export default function LeaderboardParallax({
    banner,
    clickTag,
    handleChange,
    impressionTag,
    currentCustomer,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={banner}
                    inputLabel="Banner (1600x400)"
                    inputName={LEADERBOARD_PARALLAX_CONFIG_AD_FIELDS.banner}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
            </BaseCreativeControls>
        </>
    );
}
