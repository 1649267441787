import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_ASSET_TYPES, CREATIVE_NAMES, IN_BANNER_VIDEO_AD_FIELDS } from "./definitions";
import InputTextSection from "./InputTextSection";
import VideoAssetCreativeType from "./VideoAssetCreativeType";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.IN_BANNER_VIDEO;

export default function InBannerVideo({
    displayCreativeUrl,
    collapsedCreaiveUrl,
    mobileCreativeUrl,
    videoCreativeUrl,
    handleChange,
    currentCustomer,
    topOffset,
    mobileTopOffset,
    impressionTag,
    clickTag,
    poster,
    vastUrl,
    vastDuration,
    videoCreativeType,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={displayCreativeUrl}
                    inputLabel="Desktop Banner Creative URL"
                    inputName={IN_BANNER_VIDEO_AD_FIELDS.displayCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={collapsedCreaiveUrl}
                    inputLabel="Collapsed Banner Creative URL"
                    inputName={IN_BANNER_VIDEO_AD_FIELDS.collapsedCreaiveUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileCreativeUrl}
                    inputLabel="Mobile Banner Creative URL"
                    inputName={IN_BANNER_VIDEO_AD_FIELDS.mobileCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <VideoAssetCreativeType
                    dropdownLabel="Video Creative Type"
                    types={CREATIVE_ASSET_TYPES}
                    videoCreativeTypePropertyName={IN_BANNER_VIDEO_AD_FIELDS.videoCreativeType}
                    videoCreativeType={videoCreativeType || 'video'}
                    handleChange={handleChange}
                    CREATIVE_TYPE={CREATIVE_TYPE}
                    currentCustomer={currentCustomer}
                    videoUrlInputLabel="Video URL"
                    videoUrlInputName={IN_BANNER_VIDEO_AD_FIELDS.videoCreativeUrl}
                    videoUrl={videoCreativeUrl}
                    vastUrl={vastUrl}
                    vastInputName={IN_BANNER_VIDEO_AD_FIELDS.vastUrl}
                    vastDurationInputName={IN_BANNER_VIDEO_AD_FIELDS.vastDuration}
                    vastDuration={vastDuration}
                />
                <AssetInputOrUploadSection
                    inputValue={poster}
                    inputLabel="Video poster"
                    inputName={IN_BANNER_VIDEO_AD_FIELDS.poster}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <InputTextSection label="Top Offset (optional)" name={IN_BANNER_VIDEO_AD_FIELDS.topOffset} value={topOffset || 0} changeHandler={handleChange} adtype={CREATIVE_TYPE} isNumericInput={true} required={false} />
                <InputTextSection label="Mobile Top Offset (optional)" name={IN_BANNER_VIDEO_AD_FIELDS.mobileTopOffset} value={mobileTopOffset || 0} changeHandler={handleChange} adtype={CREATIVE_TYPE} isNumericInput={true} required={false} />
            </BaseCreativeControls>

        </>
    );
}