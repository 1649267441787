const defaultState = {
    pendingCompletion: []
}

function adapterReducer(state = defaultState, action) {
    const { pristine, mutationCallback, adapterName } = action
    const { pendingCompletion } = state
    switch (action.type) {
        case 'REGISTER_ADP_MUTATION_FN':
            return {...state, [adapterName]: { pristine, mutationCallback }}
        case 'ADD_TO_MUTATION_QUEUE':
            if (pendingCompletion.find(adpName => adpName === adapterName)) {
                return state
            } else {
                return {...state, pendingCompletion: [...pendingCompletion, adapterName]}
            }
        case 'REMOVE_FROM_MUTATION_QUEUE':
            return {...state, pendingCompletion: pendingCompletion.filter(adapter => adapter !== adapterName)}
        default:
            return state
    }
}

/**
 * 
 * @param {string} apdaterName 
 * @param {function} callback 
 * @param {boolean} pristine 
 * @param {boolean} completed
 */

function registerAdapterMutationFunction(adapterName, callback, pristine = true) {
    return {
        type: 'REGISTER_ADP_MUTATION_FN',
        adapterName, 
        mutationCallback: callback,
        pristine,
    }
}

/**
 * 
 * @param {string} adapterName 
 * @returns void
 */

function addToMutationQueue(adapterName) {
    return {
        type: 'ADD_TO_MUTATION_QUEUE',
        adapterName
    }
}

/**
 * 
 * @param {string} adapterName 
 * @returns void
 */

function removeFromMutationQueue(adapterName) {
    return {
        type: 'REMOVE_FROM_MUTATION_QUEUE',
        adapterName
    }
}

export default adapterReducer
export {
    registerAdapterMutationFunction,
    addToMutationQueue,
    removeFromMutationQueue
}