import { gql, useMutation } from "@apollo/client";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Tooltip } from "primereact/tooltip";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../App.scss";
import { ADSIZE_SUGGESTIONS } from "../constants";
import useAdgridToolTipManager from "../customHooks/useAdgridToolTipManager";
import { addToPresetBuildList } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { setCurrentPreset } from "../reducers/userLoginReducer";
import { noop } from "../utilities/utils";
import BuilDomainButton from "./BuildDomainButton";
import SaveButton from "./SaveButton";
import AdpositionInput from "./common/AdpositionInput";
import AdsizeAutocomplete from "./common/AdsizeAutocomplete";
import SectionSwitch from "./common/SectionSwitch";
import AdPositionInput from "./helper/AdPositionInput";
import SectionHeader from "./helper/SectionHeader";
import { setAdpositionData } from "./utils/functions";

const THIRD_PARTY_INTEGRATION_MUTATION = gql`
    mutation callCatapultXInfoMutation($domainId: String, $isEnabled: Boolean, $isInstareadEnabled: Boolean, $operation: String, $groupId: String) {
        catapultXInfoMutation(input: { domainId: $domainId, isEnabled: $isEnabled, isInstareadEnabled: $isInstareadEnabled, operation: $operation, groupId: $groupId }) {
            catapultXInfo
        }
    }
`;

const DEFAULT_INPUT_LIST = [
    {
        display_name: "",
        description: "",
        slot_name: "",
        desktop_viewport: "",
        desktop_adsize: "",
        tablet_viewport: "",
        tablet_adsize: "",
        mobile_viewport: "",
        mobile_adsize: "",
        playerID: "",
        playerSize: "",
        context: "instream",
        catapultx_type: "single",
        linearity: 1,
        max_duration: "",
        min_duration: "",
        skip_after: "",
        skip: false,
        placement: 1,
        catapultx_target: "",
        catapultx: false,
        instaread_content: "",
        instaread: false,
        playback_method: 1,
        delivery: 1,
        plcmt: 1
    },
];

const DEFAULT_MUTATION_DATA = {};

const DEFAULT_NEW_ADPOSITION_SLOT = {
    network_id: "",
    display_name: "",
    description: "",
    slot_name: "",
    deskViewPort: "",
    deskAdsize: "",
    tabViewPort: "",
    tabAdsize: "",
    mobViewPort: "",
    mobAdsize: "",
    new: true,
    playerID: "",
    playerSize: "",
    catapultx_type: "single",
    context: "instream",
    linearity: 1,
    max_duration: "",
    min_duration: "",
    skip_after: "",
    skip: false,
    placement: 1,
    catapultx_target: "",
    catapultx: false,
    instaread_content: "",
    instaread: false,
    playback_method: 1,
    delivery: 1,
    plcmt: 1
};

const DROPDOWNS = {
    context: [
        { context: "Instream Video", value: "instream" },
        { context: "Outstream Video", value: "outstream" },
    ],
    linearity: [
        { linearity: "Linear (in-stream ad)", value: 1 },
        { linearity: "Non-linear (overlay ad)", value: 2 },
    ],
    skip: [
        { skip: "Yes", value: true },
        { skip: "No", value: false },
    ],
    plcmt: [
        { plcmt: "Instream", value: 1 },
        { plcmt: "Accompanying Content", value: 2 },
        { plcmt: "Interstitial", value: 3 },
        { plcmt: "No Content/Standalone", value: 4 },
    ],
    catapultx_type: [
        { catapultx_type: "Single", value: "single" },
        { catapultx_type: "Slideshow", value: "slideshow" },
    ],
    playback_method: [
        { playback_method: "Initiates on Page Load with Sound On", value: 1 },
        { playback_method: "Initiates on Page Load with Sound Off by Default", value: 2 },
        { playback_method: "Initiates on Click with Sound On", value: 3 },
        { playback_method: "Initiates on Mouse-Over with Sound On", value: 4 },
        { playback_method: "Initiates on Entering Viewport with Sound On", value: 5 },
        { playback_method: "Initiates on Entering Viewport with Sound Off by Default", value: 6 },
    ],
    delivery: [
        { delivery: "Streaming", value: 1 },
        { delivery: "Progressive", value: 2 },
        { delivery: "Download", value: 3 },
    ],
};

const InputSection = ({ sectionName, children }) => (
    <section className="p-grid p-fluid">
        <div className="position-tag p-col-12 p-sm-12 p-md-3 p-lg-2 p-mt-2 small-margin-top-0 small-padding-bottom-0">{sectionName}</div>
        <div className="position-controls p-col-12 p-sm-12 p-md-9 p-lg-9 no-pad-left no-pad-right">{children}</div>
    </section>
);

const AdPosition = ({ overridable, model, positioningData, savePositionsFunc, presetName, handleInputChange, handleMultipleInput, currentPreset, defaultPresetPositions, headingLabel, adtype }) => {
    const { currentDomain, mode } = useSelector((state) => state.userInformation);
    const commonDomainInfo = useSelector((state) => state.commonDomainInfo);
    const [showCheckBox, setShowCheckBox] = useState(false);
    const [mutationData, setMutationData] = useState(DEFAULT_MUTATION_DATA);
    const [inputListExist, setInputListExist] = useState(true);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [inputList, setInputList] = useState(DEFAULT_INPUT_LIST);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [positionFinal, setPositionFinal] = useState(null);
    const [presetAdPositions, setPresetAdPositions] = useState([]);
    const [adPositionsAlertList, setAdPositionsAlertList] = useState(null);
    const [changedListArray, setChangedListArray] = useState(false);
    const [adSizes, setAdSizes] = useState(null);
    const [domainSlug, setDomainSlug] = useState("slug-error");
    const [filteredAdSizes, setFilteredAdSizes] = useState([]);
    const [adSizesAvailable, setAvailableAdSizes] = useState(ADSIZE_SUGGESTIONS);
    const [positionInfo, setPositionInfo] = useState(positioningData);
    const [machineNames, setMachinesNames] = useState(new Set());
    const history = useHistory();
    const dispatch = useDispatch();
    const [catapultXInfoMutation] = useMutation(THIRD_PARTY_INTEGRATION_MUTATION);
    const [isCatapultXEnabled, setCatapultXStatus] = useState(false);
    const [isInstareadEnabled, setInstareadStatus] = useState(false);
    const [isEnableHB_nativeToggle, setIsEnableHB_nativeToggle] = useState(false);
    const {toolTipTextWithAttributes: tooltip} = useAdgridToolTipManager('ad_positions');


    let inputListArray = [];
    let changedListArray_temp = [];

    useEffect(() => {
        if (currentDomain) {
            catapultXInfoMutation({
                variables: {
                    domainId: currentDomain,
                    operation: "read",
                },
            })
                .then((response) => {
                    let { isEnabled, isInstareadEnabled } = JSON.parse(response.data?.catapultXInfoMutation.catapultXInfo || null) || {};
                    setCatapultXStatus(isEnabled);
                    setInstareadStatus(isInstareadEnabled);
                })
                .catch((err) => {
                    dispatch(pushMessage("Ad Positions", err.message, "error"));
                });
        }
    }, [currentDomain]);

    useEffect(() => {
        const machineNames = new Set(inputList.map((p) => p.slot_name));
        setMachinesNames(machineNames);
    }, [inputList]);

    const searchAdSizes = (event) => {
        let filteredSizes = null;
        let query = event.query.trim();
        if (!query.length) {
            filteredSizes = [...adSizesAvailable];
        } else if (query.match(/^\d+x\d+$/)) {
            setAvailableAdSizes([...adSizesAvailable, query]);
            filteredSizes = [...adSizesAvailable.filter((l) => l.includes(query)), query];
        } else {
            filteredSizes = [...adSizesAvailable].filter((list) => {
                return list.includes(event.query);
            });
        }
        setFilteredAdSizes(filteredSizes);

        if (!!isEnableHB_nativeToggle) {
            setFilteredAdSizes(['fluid']);
        }
    };

    useEffect(() => {
        let position = "";
        let fallbackPosition = "";

        switch (adtype) {
            case "display":
                position = showCheckBox || model === "preset" ? "ad_positions" : "ad_positions_for_preset_temp";
                fallbackPosition = position === "ad_positions" ? "ad_positions_for_preset_temp" : "ad_positions";
                break;
            case "video":
                position = showCheckBox || model === "preset" ? "video_ad_positions" : "video_ad_positions_for_preset_temp";
                fallbackPosition = position === "video_ad_positions" ? "video_ad_positions_for_preset_temp" : "video_ad_positions";
                break;
            default:
                noop();
        }

        if (showCheckBox) {
        }

        setAdpositionData(
            positionInfo,
            model,
            setDomainSlug,
            changedListArray_temp,
            inputListArray,
            setPresetAdPositions,
            setShowCheckBox,
            setMutationData,
            setInputListExist,
            setAdPositionsAlertList,
            setAdSizes,
            setChangedListArray,
            setInputList,
            setMutationDataCompleted,
            position,
            fallbackPosition
        );
    }, [showCheckBox, positionInfo]);

    useEffect(() => {
        switch (adtype) {
            case "video":
                setShowCheckBox(positionInfo.override_video_ad_positions);
                break;
            default:
                setShowCheckBox(positionInfo.override_ad_positions);
        }
    }, [positionInfo]);

    const overrideCheckBox = () => {
        dispatch(registerScreenChanges("Ad Position"));
        setShowCheckBox(!showCheckBox);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { ...DEFAULT_NEW_ADPOSITION_SLOT }]);
        dispatch(registerScreenChanges("Ad Position"));
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index, slotName) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        if (slotName !== "") {
            delete mutationData[slotName];
        }
        dispatch(registerScreenChanges("Ad Position"));
    };

    const onClick = (i) => {
        setDisplayConfirmation(true);
        setPositionFinal(i);
    };

    const renderFooter = () => (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => {
                    setDisplayConfirmation(false);
                    handleRemoveClick(positionFinal);
                }}
                autoFocus
            />
        </div>
    );

    const handleChange = (ev, idx, name) => {
        let allConfigs = [...inputList];
        let config = { ...allConfigs[idx] };

        if (name === 'hb_native') {
            let copy_inputList = inputList.map((x, idx_map)=>{

                const keepFluidSize = adSize => x[adSize] !== 'fluid' ? x[adSize] = '' : null;

                const keepCopySize = adSize => {
                    if (x[adSize] !== 'fluid') {
                        x[adSize] = x['copy_' + adSize];
                    }
                };

                if (!x.hb_native && idx === idx_map) {
                    x.copy_deskAdsize = x.deskAdsize;
                    x.copy_tabAdsize = x.tabAdsize;
                    x.copy_mobAdsize = x.mobAdsize;

                    keepFluidSize('deskAdsize');
                    keepFluidSize('tabAdsize');
                    keepFluidSize('mobAdsize');
                } else if (x.hb_native && idx === idx_map) {
                    keepCopySize('deskAdsize');
                    keepCopySize('tabAdsize');
                    keepCopySize('mobAdsize');
                }
                return x;
            });

            allConfigs = [...copy_inputList];
            config = { ...allConfigs[idx] };

            setInputList([...copy_inputList]);

            setIsEnableHB_nativeToggle(ev.value);
        }

        if (name === "catapultx" || ev.target.name === "catapultx") {
            if (ev.target.value) {
                config.sticky = false;
                config.out_of_page = false;
                config.interstitial = false;
                config.sticky_refresh_size = false;
                config.disable_refresh = false;
                config.hbid_list_exclude = false;
                config.syndication = false;
                config.catapultx_type = config.catapultx_type || "single";
            }
        }
        if (name === "out_of_page" || ev.target.name === "out_of_page") {
            if (ev.target.value) {
                config.disable_refresh = true;
            } else {
                config.disable_refresh = false;
            }
        }
        allConfigs.splice(idx, 1, config);
        handleInputChange(ev, idx, allConfigs, setInputList, presetAdPositions, changedListArray, machineNames);
        dispatch(registerScreenChanges("Ad Position"));
    };

    const handleSizeChange = (ev, idx) => {

        let fluidFound = ev.value.includes('fluid');

        //console.log({ev, fluidFound, isEnableHB_nativeToggle})

        if (isEnableHB_nativeToggle && !fluidFound) {
            dispatch(pushMessage("Fluid not added", '', "error"));
            //throw new Error();
        }

        switch (model) {
            case "domain":
            case "preset":
                handleMultipleInput(ev, idx, inputList, setInputList, model, presetAdPositions, changedListArray);
                dispatch(registerScreenChanges("Ad Position"));
                break;
            default:
                noop();
        }
    };

    const saveCallback = async () => {
        if (model === "domain") {
            return savePositionsFunc(inputList, adPositionsAlertList, showCheckBox, changedListArray, showCheckBox)
                .then(() => dispatch(pushMessage("Ad Position", "Ad position list updated")))
                .then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                })
                .catch(() => {
                    dispatch(pushMessage("Ad Position", "There was an error while trying to save the ad positions", "error"));
                    throw new Error("There was an error while trying to save the ad positions");
                });
        }
        if (model === "preset") {
            return savePositionsFunc(inputList, adPositionsAlertList)
                .then(() => dispatch(pushMessage("Ad Position", "List updated")))
                .then(() => dispatch(addToPresetBuildList(currentPreset)))
                .catch(() => {
                    dispatch(pushMessage("Ad Position", "There was an error while trying to save the ad positions", "error"));
                    throw new Error("There was an error while trying to save the ad positions");
                });
        }
        throw new Error("Unsupported operation");
    };

    return (
        mutationDataCompleted && (
            <>
                <div className={`p-panel p-component ${!showCheckBox ? "enabled-content" : "enabled-content"}`}>
                    <SectionHeader screenName="Display ads positions">
                        <div className="p-d-flex p-ai-center f-width">
                            {overridable && (
                                <>
                                    <InputSwitch checked={showCheckBox} onChange={overrideCheckBox} />
                                    <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold" onClick={overrideCheckBox}>
                                        {presetName ? (
                                            <span
                                                onClick={() => {
                                                    dispatch(setCurrentPreset(currentPreset));
                                                    history.push("/presetadpositions");
                                                }}
                                            >
                                                {" "}
                                                {headingLabel} [
                                                <span
                                                    style={{
                                                        paddingBottom: "2px",
                                                        color: "#0388e5",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {` ${presetName} `}
                                                </span>
                                                ]
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </label>
                                </>
                            )}
                        </div>
                    </SectionHeader>
                    <div className="card p-panel-content disable-item">
                        <div className="p-datatable p-fluid p-component p-datatable-striped">
                            <div className="p-datatable-wrapper">
                                <Dialog header="Confirmation" visible={displayConfirmation} modal style={{ width: "350px" }} footer={renderFooter()} onHide={() => setDisplayConfirmation(false)}>
                                    <div className="confirmation-content p-d-flex p-ai-center">
                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to delete this item?</span>
                                    </div>
                                </Dialog>

                                <div>
                                    <Accordion multiple>
                                        {inputListExist &&
                                            inputList.map((x, i) => {
                                                const {
                                                    deskAdsize,
                                                    deskViewPort,
                                                    disable_refresh,
                                                    display_name,
                                                    description,
                                                    hbid_list_exclude,
                                                    hb_native,
                                                    syndication,
                                                    interstitial,
                                                    oop_refresh_for_infinite_scroll,
                                                    mobAdsize,
                                                    mobViewPort,
                                                    network_id,
                                                    out_of_page,
                                                    sticky_refresh_size,
                                                    slot_name,
                                                    sticky,
                                                    tabAdsize,
                                                    tabViewPort,
                                                    playerSize,
                                                    playerID,
                                                    context,
                                                    catapultx_type,
                                                    linearity,
                                                    max_duration,
                                                    min_duration,
                                                    skip_after,
                                                    skip,
                                                    playback_method,
                                                    delivery,
                                                    catapultx = false,
                                                    catapultx_target = "",
                                                    instaread = false,
                                                    instaread_content = "",
                                                    plcmt = 1
                                                } = x;

                                                const defaultPositions = defaultPresetPositions || new Set([]);
                                                const isInputDisabled = !showCheckBox;

                                                return (
                                                    <AccordionTab
                                                        key={i}
                                                        header={
                                                            <div className="ad-position-container">
                                                                <div className="position-name">
                                                                    <p className="font-weight-400">
                                                                        <strong>{display_name}</strong> {description && <> - [{description}]</>}
                                                                    </p>
                                                                    {adtype === "display" && (
                                                                        <>
                                                                            <p>
                                                                                {deskAdsize && deskAdsize.length && (
                                                                                    <span className="desksize header-info">
                                                                                        <span className="posname">Desktop: </span>
                                                                                        {deskAdsize.split(",").join(", ")}
                                                                                    </span>
                                                                                )}
                                                                                {tabAdsize && tabAdsize.length && (
                                                                                    <span className="tabsize header-info">
                                                                                        <span className="posname">Tablet: </span>
                                                                                        {tabAdsize.split(",").join(", ")}
                                                                                    </span>
                                                                                )}
                                                                                {mobAdsize && mobAdsize.length && (
                                                                                    <span className="mobsize header-info">
                                                                                        <span className="posname">Mobile: </span>
                                                                                        {mobAdsize.split(",").join(", ")}
                                                                                    </span>
                                                                                )}
                                                                                {[
                                                                                    ["Adhesion", sticky],
                                                                                    ["Out of page", out_of_page],
                                                                                    ["Interstitial", interstitial],
                                                                                    ["Refresh OOP on AJAX URL change", oop_refresh_for_infinite_scroll],
                                                                                    ["Sticky Refresh Size", sticky_refresh_size],
                                                                                    ["Disable refresh", disable_refresh],
                                                                                    ["Exclude HB", hbid_list_exclude],
                                                                                    ["HB Native", hb_native],
                                                                                    ["CatapultX", catapultx],
                                                                                    ["Instaread", instaread],
                                                                                    ["Syndication Creative", syndication],
                                                                                ].map(([name, item], idx) => {
                                                                                    return (
                                                                                        item && (
                                                                                            <span key={idx} className="switch-indicator">
                                                                                                {" "}
                                                                                                {name}{" "}
                                                                                            </span>
                                                                                        )
                                                                                    );
                                                                                })}
                                                                            </p>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <Dialog
                                                                    header="Confirmation"
                                                                    visible={displayConfirmation}
                                                                    modal
                                                                    style={{
                                                                        width: "350px",
                                                                    }}
                                                                    footer={renderFooter("displayConfirmation")}
                                                                    onHide={() => setDisplayConfirmation(false)}
                                                                >
                                                                    <div className="confirmation-content p-d-flex p-ai-center">
                                                                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                                                                        <span>Are you sure you want to delete this item?</span>
                                                                    </div>
                                                                </Dialog>
                                                                {showCheckBox && !defaultPositions.has(slot_name) && (
                                                                    <Button type="button" icon="pi pi-times" className="p-button-small p-button-danger p-button-rounded p-button-text p-button-icon-only" tooltip="Remove row" tooltipOptions={{ position: "top" }} onClick={() => onClick(i)} />
                                                                )}
                                                            </div>
                                                        }
                                                    >
                                                        <InputSection sectionName="Display Name">
                                                            <AdPositionInput
                                                                tooltipText="Display Name"
                                                                title="Display Name"
                                                                className={presetAdPositions.includes(slot_name) ? "has-disabled" : ""}
                                                                disable={presetAdPositions.includes(slot_name)}
                                                                name="display_name"
                                                                value={display_name}
                                                                changeEvent={(e) => handleChange(e, i)}
                                                            />
                                                        </InputSection>

                                                        <InputSection sectionName="Description">
                                                            <InputTextarea name="description" value={description} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                        </InputSection>

                                                        <InputSection sectionName="Slot Name">
                                                            <InputText className="has-disabled" disabled={true} name="slot_name" value={slot_name} onChange={(e) => handleChange(e, i)} />
                                                        </InputSection>

                                                        <InputSection sectionName="Network ID">
                                                            <InputText name="network_id" value={network_id} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                        </InputSection>

                                                        {isCatapultXEnabled && catapultx && (
                                                            <>
                                                                <InputSection sectionName="CatapultX Div Target">
                                                                    <InputText
                                                                        name="catapultx_target"
                                                                        value={catapultx_target}
                                                                        onChange={(ev) => {
                                                                            handleChange(ev, i);
                                                                        }}
                                                                    />
                                                                </InputSection>
                                                                <InputSection sectionName="Player Type">
                                                                    <Dropdown name="catapultx_type" options={DROPDOWNS.catapultx_type} value={catapultx_type} optionLabel="catapultx_type" onChange={(e) => handleChange(e, i)} placeholder="Select a player type" />
                                                                </InputSection>
                                                            </>
                                                        )}

                                                        {instaread && isInstareadEnabled && (
                                                            <InputSection sectionName="Instaread Content">
                                                                <InputTextarea
                                                                    name="instaread_content"
                                                                    value={instaread_content}
                                                                    onChange={(ev) => {
                                                                        handleChange(ev, i);
                                                                    }}
                                                                />
                                                            </InputSection>
                                                        )}

                                                        {adtype === "video" && (
                                                            <>
                                                                <InputSection sectionName="Player Size">
                                                                    <InputText name="playerSize" value={playerSize} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Player Id">
                                                                    <InputText name="playerID" value={playerID} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Context">
                                                                    <Dropdown name="context" options={DROPDOWNS.context} disabled={isInputDisabled} value={context} optionLabel="context" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Linearity">
                                                                    <Dropdown name="linearity" options={DROPDOWNS.linearity} disabled={isInputDisabled} value={linearity} optionLabel="linearity" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Max Duration">
                                                                    <InputText name="max_duration" value={max_duration} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Min Duration">
                                                                    <InputText name="min_duration" value={min_duration} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Skippable ad">
                                                                    <Dropdown name="skip" options={DROPDOWNS.skip} disabled={isInputDisabled} value={skip} optionLabel="skip" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Skip After">
                                                                    <InputText name="skip_after" value={skip_after} disabled={isInputDisabled} onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Placement">
                                                                    <Dropdown name="plcmt" options={DROPDOWNS.plcmt} disabled={isInputDisabled} value={plcmt} optionLabel="plcmt" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Playback Method">
                                                                    <Dropdown name="playback_method" options={DROPDOWNS.playback_method} disabled={isInputDisabled} value={playback_method} optionLabel="playback_method" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>

                                                                <InputSection sectionName="Delivery">
                                                                    <Dropdown name="delivery" options={DROPDOWNS.delivery} disabled={isInputDisabled} value={delivery} optionLabel="delivery" onChange={(e) => handleChange(e, i)} />
                                                                </InputSection>
                                                            </>
                                                        )}

                                                        {adtype === "display" && (
                                                            <>
                                                                {!isCatapultXEnabled || !catapultx ? (
                                                                    <div className="adapter-parameters adpos">
                                                                        <div className="sizing-row all-sizes"></div>
                                                                        <div className="controls-wrapper all-sizes">
                                                                            <div className="adapter-config">
                                                                                <div className="empty"></div>
                                                                                {["desktop", "tablet", "mobile"].map((dev, key) => (
                                                                                    <div key={key} className={`device ${dev}`}>
                                                                                        <h6 className="adapter-device-name">{dev}</h6>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="values display-ad">
                                                                                <div className="param-names">
                                                                                    <p>
                                                                                        <strong class="param">Viewport</strong>
                                                                                    </p>
                                                                                </div>

                                                                                <div className="adapter-parameter-values desk" data-index="0" style={{ order: 0 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-desktop`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Desktop Viewport</h6>
                                                                                    </div>
                                                                                    <AdpositionInput bgColorClass="light-green-bg" label="" name="deskViewPort" value={deskViewPort} showCheckBox={showCheckBox} handleChange={handleChange} idx={i} />
                                                                                </div>
                                                                                <div className="adapter-parameter-values tab" data-index="1" style={{ order: 2 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-tablet`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Tablet Viewport</h6>
                                                                                    </div>
                                                                                    <AdpositionInput bgColorClass="light-purple-bg" label="" name="tabViewPort" value={tabViewPort} showCheckBox={showCheckBox} handleChange={handleChange} idx={i} />
                                                                                </div>
                                                                                <div className="adapter-parameter-values mob" data-index="2" style={{ order: 4 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-mobile`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Mobile Viewport</h6>
                                                                                    </div>
                                                                                    <AdpositionInput bgColorClass="light-yellow-bg" label="" name="mobViewPort" value={mobViewPort} showCheckBox={showCheckBox} handleChange={handleChange} idx={i} />
                                                                                </div>
                                                                                <div className="param-names">
                                                                                    <p>
                                                                                        <strong class="param">Adsize</strong>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="adapter-parameter-values desk" data-index="0" style={{ order: -1 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-desktop`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Desktop Adsize</h6>
                                                                                    </div>
                                                                                    <AdsizeAutocomplete
                                                                                        bgColorClass="light-green-bg"
                                                                                        name="deskAdsize"
                                                                                        filteredAdSizes={filteredAdSizes}
                                                                                        searchAdSizes={searchAdSizes}
                                                                                        value={deskAdsize ? deskAdsize.split(",") : ""}
                                                                                        showCheckBox={showCheckBox}
                                                                                        handleSizeChange={handleSizeChange}
                                                                                        idx={i}
                                                                                    />
                                                                                </div>
                                                                                <div className="adapter-parameter-values tab" data-index="1" style={{ order: 1 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-tablet`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Tablet Adsize</h6>
                                                                                    </div>
                                                                                    <AdsizeAutocomplete
                                                                                        bgColorClass="light-purple-bg"
                                                                                        name="tabAdsize"
                                                                                        filteredAdSizes={filteredAdSizes}
                                                                                        searchAdSizes={searchAdSizes}
                                                                                        value={tabAdsize ? tabAdsize.split(",") : ""}
                                                                                        showCheckBox={showCheckBox}
                                                                                        handleSizeChange={handleSizeChange}
                                                                                        idx={i}
                                                                                    />
                                                                                </div>
                                                                                <div className="adapter-parameter-values mob" data-index="2" style={{ order: 3 }}>
                                                                                    <div className={`p-d-none sm-pad small-show dark-mobile`}>
                                                                                        <h6 className="bold text-center capitalize mb-0 text-black">Mobile Adsize</h6>
                                                                                    </div>
                                                                                    <AdsizeAutocomplete
                                                                                        bgColorClass="light-yellow-bg"
                                                                                        name="mobAdsize"
                                                                                        filteredAdSizes={filteredAdSizes}
                                                                                        searchAdSizes={searchAdSizes}
                                                                                        value={mobAdsize ? mobAdsize.split(",") : ""}
                                                                                        showCheckBox={showCheckBox}
                                                                                        handleSizeChange={handleSizeChange}
                                                                                        idx={i}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <InputSection sectionName="Ad size">
                                                                            <AdsizeAutocomplete
                                                                                bgColorClass="light-green-bg"
                                                                                name="deskAdsize"
                                                                                filteredAdSizes={filteredAdSizes}
                                                                                searchAdSizes={searchAdSizes}
                                                                                value={deskAdsize ? deskAdsize.split(",") : ""}
                                                                                showCheckBox={showCheckBox}
                                                                                handleSizeChange={handleSizeChange}
                                                                                idx={i}
                                                                            />
                                                                        </InputSection>
                                                                    </>
                                                                )}
                                                                <>
                                                                    <div className="adposition-switches">
                                                                        <Tooltip target=".adpositiontooltip" />

                                                                        <SectionSwitch
                                                                            label="Adhesion"
                                                                            name="sticky"
                                                                            {...tooltip('adhesion')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={sticky}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        <SectionSwitch
                                                                            label="Interstitial"
                                                                            name="interstitial"
                                                                            {...tooltip('interstitial')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={interstitial}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        <SectionSwitch
                                                                            label="Sticky Refresh Size"
                                                                            name="sticky_refresh_size"
                                                                            {...tooltip('sticky_refresh')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={sticky_refresh_size}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        <SectionSwitch
                                                                            label="Disable Refresh"
                                                                            name="disable_refresh"
                                                                            {...tooltip('disable_refresh')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={disable_refresh}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        <SectionSwitch
                                                                            label="Exclude HB"
                                                                            name="hbid_list_exclude"
                                                                            {...tooltip('exclude_hb')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={hbid_list_exclude}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        <SectionSwitch
                                                                            label="HB Native"
                                                                            name="hb_native"
                                                                            {...tooltip('hb_native')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={hb_native}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        {isCatapultXEnabled && <SectionSwitch
                                                                            label="CatapultX"
                                                                            name="catapultx"
                                                                            {...tooltip('catapultx')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={catapultx}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />
                                                                        }
                                                                        {isInstareadEnabled && <SectionSwitch
                                                                            label="Instaread"
                                                                            name="instaread"
                                                                            {...tooltip('instaread')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={instaread}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />
                                                                        }
                                                                        <SectionSwitch
                                                                            label="Syndication Creative"
                                                                            name="syndication"
                                                                            {...tooltip('syndication_creative')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={syndication}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />


                                                                        <SectionSwitch
                                                                            label="Out of Page"
                                                                            name="out_of_page"
                                                                            {...tooltip('out_of_page')}
                                                                            showCheckBox={showCheckBox}
                                                                            checked={out_of_page}
                                                                            handleChange={handleChange}
                                                                            idx={i}
                                                                        />

                                                                        {out_of_page && (
                                                                            <>
                                                                                <Tooltip target=".adpositiontooltip" />
                                                                                <SectionSwitch
                                                                                    label="Refresh OOP on AJAX URL change"
                                                                                    name="oop_refresh_for_infinite_scroll"
                                                                                    {...tooltip('oop_refresh_for_infinite_scroll')}
                                                                                    showCheckBox={showCheckBox}
                                                                                    checked={oop_refresh_for_infinite_scroll}
                                                                                    handleChange={handleChange}
                                                                                    idx={i}
                                                                                />
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </>
                                                            </>
                                                        )}

                                                        {adtype === "video" && (
                                                            <>
                                                                <div className="adposition-switches">
                                                                    <SectionSwitch label="Exclude HB" name="hbid_list_exclude" showCheckBox={showCheckBox} checked={hbid_list_exclude} handleChange={handleChange} idx={i} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </AccordionTab>
                                                );
                                            })}
                                    </Accordion>
                                </div>

                                <Tooltip target=".adpositiontooltip" mouseTrack mouseTrackLeft={10} />
                            </div>
                        </div>
                        {(model === "preset" || (model === "domain" && showCheckBox)) && (
                            <div className="p-d-flex p-jc-end p-mt-3">
                                <Button className="hide-item" label="Add Row" icon="pi pi-plus" onClick={handleAddClick} />
                            </div>
                        )}
                    </div>
                    {model === "domain" && (
                        <>
                            <div className="p-panel-header">
                                <div className="p-d-flex p-ai-center">
                                    <label htmlFor="cb1" className="p-text-bold">
                                        Ad Code
                                    </label>
                                </div>
                            </div>
                            <div className="p-panel-content">
                                <div className="ad-code-metabox">
                                    <h5 className="p-text-bold">Step 1:</h5>
                                    <div className="pre-wrapper">
                                        {commonDomainInfo?.[currentDomain]?.adgrid_version === "v5" ? (
                                            <>
                                                <pre>
                                                    <span>&lt;!-- adGrid Ad Begin Header Tag --&gt;</span>
                                                    <br></br>
                                                    <span>&lt;script async src="{"https://" + window.location.hostname + "/adgrid-build/" + domainSlug + "/revenue-grid.js"}"&gt;&lt;/script&gt;</span>
                                                    <br></br>
                                                    <span>&lt;!-- adGrid Ad End Header Tag --&gt;</span>
                                                    <br></br>
                                                    <br></br>
                                                </pre>
                                            </>
                                        ) : (
                                            <>
                                                <pre>
                                                    <span>&lt;!-- adGrid Ad Begin Header Tag --&gt;</span>
                                                    <span>
                                                        <br></br>
                                                        &lt;script&gt;
                                                        <br></br>
                                                        {"  window.top.adGrid = window.top.adGrid || {};"}
                                                        <br></br>
                                                        {"  window.top.adGrid.cmd = window.top.adGrid.cmd || [];"}
                                                        <br></br>
                                                        <br></br>
                                                        {"  var adGridInitiate = function() {"}
                                                        <br></br>
                                                        {"    this.adGridCall(function () {"}
                                                        <br></br>
                                                        {"      var pageMeta = {};"}
                                                        <br></br>
                                                        {"      this.setMetaDataFromPage(pageMeta);"}
                                                        <br></br>
                                                        {"    })"}
                                                        <br></br>
                                                        {"  };"}
                                                        <br></br>
                                                        <br></br>
                                                        {"  window.top.adGrid.cmd.push([adGridInitiate]);"}
                                                        <br></br>
                                                        &lt;/script&gt;
                                                        <br></br>
                                                    </span>
                                                    <span>&lt;!-- adGrid Ad End Header Tag --&gt;</span>
                                                    <br></br>
                                                    <br></br>
                                                </pre>
                                            </>
                                        )}
                                        <pre>
                                            <span className="green">Put the above code between the &lt;head&gt;&lt;/head&gt; of your HTML document.</span>
                                            <br></br>
                                            <br></br>
                                        </pre>
                                    </div>
                                </div>
                                {adSizes && <h5 className="p-text-bold">Step 2:</h5>}
                                <div className="pre-wrapper">
                                    {adSizes &&
                                        adSizes.map((x, i) => {
                                            return (
                                                <div key={i}>
                                                    <pre>
                                                        <span>&lt;!-- adGrid Ad Begin {x.name} Ad --&gt;</span>
                                                        <br></br>
                                                        &lt;div data-position="{x.code}" data-ad-grid-is-processed="false"&gt;&lt;/div&gt;
                                                        <br></br>
                                                        <span>&lt;!-- adGrid Ad End {x.name} Ad --&gt;</span>
                                                    </pre>
                                                </div>
                                            );
                                        })}
                                    {adSizes && (
                                        <pre>
                                            <span className="green">
                                                <b>Note:</b> If you want to put a specific ad multiple times in a page, then simply just copy and paste the specific ad div.
                                            </span>
                                            <br></br>
                                            <span className="green">For example, if you want to put rightrail_ad two times in a page, then you will have to put the code two times:</span>
                                            <br></br>
                                            <span className="blue">&lt;div data-position="righttrail_ad" data-ad-grid-is-processed="false"&gt;&lt;/div&gt;</span>
                                            <br></br>
                                            <span className="blue">&lt;div data-position="righttrail_ad" data-ad-grid-is-processed="false"&gt;&lt;/div&gt;</span>
                                        </pre>
                                    )}
                                </div>
                                {commonDomainInfo?.[currentDomain]?.adgrid_version === "v5" ? null : (
                                    <>
                                        <h5 className="p-text-bold">Step 3:</h5>
                                        <div className="pre-wrapper">
                                            <strong>Version Four (v4)</strong>
                                            <pre>
                                                <span>&lt;!-- adGrid Ad Begin Footer Tag --&gt;</span>
                                                <br></br>
                                                <span>&lt;script async src="{"https://" + window.location.hostname + "/adgrid-build/ad-grid-" + domainSlug + ".js"}"&gt;&lt;/script&gt;</span>
                                                <br></br>
                                                <span>&lt;!-- adGrid Ad End Footer Tag --&gt;</span>
                                                <br></br>
                                                <br></br>
                                            </pre>
                                            <pre>
                                                <span className="green">
                                                    Put the adGrid library js just before <b>&lt;/body&gt;</b> of your HTML document.
                                                </span>
                                                <br></br>
                                            </pre>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="ops-bar">
                    <SaveButton saveCallback={saveCallback} />
                    {mode === "domain" && <BuilDomainButton />}
                </div>
            </>
        )
    );
};

export default AdPosition;
