import React from 'react';
import SaveButton from '../SaveButton';

const SectionHeader = (props) => {
  return (
    <div className="p-panel-header">
      { props.children }
      { 
        props.saveCallback && 
        <SaveButton
          saveCallback={props.saveCallback}
        />
      }
    </div>
  );
}
export default SectionHeader;
