import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, EBONY_SKIN_CONFIG_AD_FIELDS } from "./definitions";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.SKIN_CREATIVE;

export default function SkinCreative({
    left,
    right,
    topDesktopLeaderboard,
    mobileTopImage,
    mobileBottomImage,
    handleChange,
    impressionTag,
    currentCustomer,
    adServingType,
    clickTag
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={topDesktopLeaderboard}
                    inputLabel="Top banner image (1600x250)"
                    inputName={EBONY_SKIN_CONFIG_AD_FIELDS.topDesktopLeaderboard}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={left}
                    inputLabel="Left-side image (300x1400)"
                    inputName={EBONY_SKIN_CONFIG_AD_FIELDS.left}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={right}
                    inputLabel="Right-side image (300x1400)"
                    inputName={EBONY_SKIN_CONFIG_AD_FIELDS.right}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileTopImage}
                    inputLabel="Mobile top banner (360x120)"
                    inputName={EBONY_SKIN_CONFIG_AD_FIELDS.mobileTopImage}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={mobileBottomImage}
                    inputLabel="Mobile bottom banner (360x120)"
                    inputName={EBONY_SKIN_CONFIG_AD_FIELDS.mobileBottomImage}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
            </BaseCreativeControls>



        </>
    );
}
