/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { gql, useMutation } from "@apollo/client";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { displayAdapters } from "../../constants";
import { pushMessage } from "../../reducers/messagingReducer";
import { getIn } from "../../utilities/utils";
import SectionHeader from "../helper/SectionHeader";
import LayoutWrapper from "../LayoutWrapper";
import ADP_amazon from "./ADP_amazon";
import BaseMultiparameterAdapter from "./components/BaseMultiparameterAdapter";
import MultiparameterInputs from "./components/MultiparameterInputs";
import { ProgressSpinner } from "primereact/progressspinner";
import { AMAZON_ADAPTER_UPDATE, CUSTOM_FIELD_UPDATE_MUTATION } from "./utils/constants";
import { enforceGlobalParameters, getSspConfigFromAdunits, mergeUpdatedAdapterData, shapeSspData } from "./utils/functions";
import { clearAdapterMutations } from "../../reducers/adapterMutationReducer";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String) {
        domainEditMutation(input: { id: $id }) {
            clientMutationId
            customerInfo
        }
    }
`;

const CUSTOM_ADAPTER_SETTINGS_MUTATION = gql`
    mutation callAdapterCustomSettingsMutation($id: String, $customAdapterSettings: String, $isPreset: Boolean, $customAdapterBids: String) {
        adapterCustomSettingsMutation(input: { id: $id, customAdapterSettings: $customAdapterSettings, isPreset: $isPreset, customAdapterBids: $customAdapterBids }) {
            presetInfo
        }
    }
`;

const SlotPlacementID = () => {
    const { currentDomain, currentCustomer } = useSelector((state) => state.userInformation);
    const [adposition, setAdposition] = useState(false);

    /* ssp configuration state variables */
    const [mutationData, setMutationData] = useState({});

    /* available ssps state variables */

    const [domainSspAdapterList, setDomainSspAdapterList] = useState([]);
    const [presetSspAdapterList, setPresetSspAdapterList] = useState([]);

    const [mutationDataCompleted, setMutationDataCompleted] = useState({ completed: false });
    const [isPresetOverriden, setIsPresetOverriden] = useState(false);
    const [presetName, setPresetName] = useState(false);
    const [amazonPublisherID, setAmazonPublisherID] = useState(false);
    const [amazonA9, setAmazonA9] = useState(false);
    const [rawSSPPositions, setRawSSPPositions] = useState({});
    const [rawPresetPositions, setRawPresetPositions] = useState({});
    const [customAdapterSettings, setCustomAdapterSettings] = useState({});
    const [customAdapterSettingsMutation] = useMutation(CUSTOM_ADAPTER_SETTINGS_MUTATION);
    const [adaptersBidAdjustment, setAdaptersBidAdjustments] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const [newCreatedDomain, setNewCreatedDomain] = useState(false);
    const {global, ...adaptersState} = useSelector(state => state.adapterStateMutationReducer)
    const [fieldUpdateMutation] = useMutation(CUSTOM_FIELD_UPDATE_MUTATION)
    const [amazonAdapterUpdateMutation] = useMutation(AMAZON_ADAPTER_UPDATE)

    function resetState() {
        setMutationData({})
        setAdposition(false)
        setDomainSspAdapterList([])
        setPresetSspAdapterList([])
        setMutationDataCompleted({ completed: false })
        setIsPresetOverriden(false)
        setPresetName(false)
        setAmazonPublisherID(false)
        setAmazonA9(false)
        setRawSSPPositions({})
        setRawPresetPositions({})
        setCustomAdapterSettings({})
        setAdaptersBidAdjustments({})
        dispatch(clearAdapterMutations())
    }

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            resetState()
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentDomain}` },
            });
        }
        return () => {
            dispatch(clearAdapterMutations())
        }
    }, [currentDomain, currentCustomer]);


    const removeUnusedPropertyFromGumGum = (mainObj) => {
        if ('gumgum' in mainObj) {
            let gumGumObj = mainObj['gumgum'];

            Object.keys(gumGumObj).forEach(elm => {
                let gumGumDevice = gumGumObj[elm];
                Object.keys(gumGumDevice).forEach(devElm => {
                    Object.keys(gumGumDevice[devElm]).forEach(indexTypeObj=>{
                        Object.keys(gumGumDevice[devElm][indexTypeObj]).forEach(objType=>{
                            if (!gumGumDevice[devElm][indexTypeObj][objType]) {
                                delete gumGumDevice[devElm][indexTypeObj][objType];
                            }
                        })
                    })
                });
            });
        }

        return mainObj;
    }

    const resetDigitalAddZone = (mainObj) => {
        if ('resetdigital' in mainObj) {
            let resetdigitalObj = mainObj['resetdigital'];
            Object.keys(resetdigitalObj).forEach(elm => {
                let gumGumDevice = resetdigitalObj[elm];
                Object.keys(gumGumDevice).forEach(devElm => {
                    Object.keys(gumGumDevice[devElm]).forEach(indexTypeObj=>{
                        Object.keys(gumGumDevice[devElm][indexTypeObj]).forEach(objType=>{
                            if (objType === 'placementId') {
                                let placementIdValue = gumGumDevice[devElm][indexTypeObj][objType];
                                gumGumDevice[devElm][indexTypeObj].zoneId = {placementId: placementIdValue}
                                delete gumGumDevice[devElm][indexTypeObj][objType];
                            }
                        })
                    })
                });
            });
        }
        return mainObj;
    }

    const confirm = (data) => {
        let temp_data = JSON.parse(data.domainEditMutation.customerInfo)
        let customAdapterSettings = temp_data.custom_adapter_settings || {};
        let presetCustomAdapterSettings = temp_data.preset_custom_adapter_settings || {};

        setCustomAdapterSettings(Object.keys(customAdapterSettings).length ? customAdapterSettings : presetCustomAdapterSettings);

        setRawSSPPositions(temp_data.ad_positions || {});
        setRawPresetPositions(temp_data.ad_positions_for_preset_temp || {});

        setAmazonA9(temp_data.amazon_a9_checkbox);
        setAmazonPublisherID(temp_data.amazon_publisher_id);

        let presetName = getIn(temp_data, "adv_preset", "name");

        if (presetName) {
            setPresetName(presetName);
        }

        setAdposition(temp_data.ad_positions_for_adapters || []);

        if (!temp_data.overridehbIdList && !temp_data.override_ad_adapterselection_list) {
            setNewCreatedDomain(true);
        }

        const domainSspAdapterList = temp_data.domain_ssp_adapter_list;
        const presetSspAdapterList = temp_data.preset_ssp_adapter_list;

        setPresetSspAdapterList(
            Array.isArray(presetSspAdapterList)
                ? presetSspAdapterList.sort()
                : Object.values(presetSspAdapterList || {})
        );

        setDomainSspAdapterList(
            Array.isArray(domainSspAdapterList)
                ? domainSspAdapterList.sort()
                : Object.values(domainSspAdapterList || {})

        )


        setIsPresetOverriden(temp_data.overridehbIdList);

        try {
            setAdaptersBidAdjustments(JSON.parse(temp_data.overridehbIdList ? temp_data.adjust_bid_price : temp_data.preset_adjust_bid_price) || {});
        } catch {
            setAdaptersBidAdjustments({});
        }


        let sspConfig = !temp_data.hbIdList ? {} : JSON.parse(temp_data.hbIdList) || {};

        setMutationData(getSspConfigFromAdunits(sspConfig))
        setMutationDataCompleted({ completed: true });
    };

    const saveCustomAdapterSettings = async () => {
        const response = await customAdapterSettingsMutation({
            variables: {
                id: `${currentDomain}`,
                isPreset: false,
                customAdapterSettings: JSON.stringify(customAdapterSettings),
                customAdapterBids: JSON.stringify(adaptersBidAdjustment),
            },
        })
        return response
    }

    const updatePresetOverrideState = async () => {
        await fieldUpdateMutation({
            variables: {
                post_id: String(currentDomain),
                field_name: 'override_adapter_settings',
                value: String(isPresetOverriden),
                parse_value: true
            }
        })
        await fieldUpdateMutation({
            variables: {
                post_id: String(currentDomain),
                field_name: 'override_slotplacement_id',
                value: String(isPresetOverriden),
                parse_value: true
            }
        })
    }

    const saveAdapterSelectionList = async () => {
        await fieldUpdateMutation({
            variables: {
                post_id: String(currentDomain),
                field_name: 'adapter_list',
                value: JSON.stringify(domainSspAdapterList),
                parse_value: true
            }
        })
    }

    const toggleAdapterSelection = (adapterName) => {
        if (domainSspAdapterList.includes(adapterName)) {
            setDomainSspAdapterList(domainSspAdapterList.filter(ssp => ssp !== adapterName))
        } else {
            setDomainSspAdapterList([...domainSspAdapterList, adapterName])
        }
    }

    const updateSSPConfiguration = async (adaptersConf) => {
        try {
            let adapterData = mergeUpdatedAdapterData(mutationData, adaptersConf);
            
            adapterData = removeUnusedPropertyFromGumGum(adapterData);
            adapterData = resetDigitalAddZone(adapterData);
            adapterData = enforceGlobalParameters(adapterData, global)
            let shapedData = shapeSspData(adapterData, adposition)

            fieldUpdateMutation({
                variables: {
                    post_id: String(currentDomain),
                    field_name: 'header_bidder_configuration',
                    value: JSON.stringify(shapedData)
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <LayoutWrapper model="domain" saveCallback={async () => {
            dispatch(pushMessage("SSP Configuration", "Saving SSPs configuration"));
            await saveCustomAdapterSettings()
            await updatePresetOverrideState()
            if (isPresetOverriden) {
                await saveAdapterSelectionList()
                let {amazon: {isEnabled, publisherID}, ...remainingAdaptersState} = adaptersState
                await updateSSPConfiguration(remainingAdaptersState)
                await amazonAdapterUpdateMutation({
                    variables: {
                        id: String(currentDomain),
                        enableA9: isEnabled,
                        publisherID
                    }
                })
            }
        }}>
            {
                !mutationDataCompleted && (
                    <ProgressSpinner
                        animationDuration="0.5s"
                    />
                )
            }

            {mutationDataCompleted.completed && (
                <div className={`p-panel p-component ${!isPresetOverriden ? "disabled-content" : "enabled-content"}`}>
                    <SectionHeader screenName="Display SSP Adapters: ">
                        <div className="p-d-flex p-ai-center f-width">
                            <InputSwitch
                                checked={isPresetOverriden}
                                onChange={() => {
                                    setIsPresetOverriden(!isPresetOverriden);
                                }}
                            />
                            <label
                                htmlFor="cb1"
                                className="p-checkbox-label p-pl-2 p-text-bold"
                                onClick={() => {
                                    setIsPresetOverriden(!isPresetOverriden);
                                }}
                            >
                                Override Slot PlacementID Preset [
                                <span
                                    style={{
                                        paddingBottom: "2px",
                                        color: "#0388e5",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        history.push("/presetslotplacementid");
                                    }}
                                >
                                    {presetName && ` ${presetName} `}
                                </span>
                                ]
                            </label>
                        </div>
                    </SectionHeader>
                    <div className="p-panel-content slot-placement disable-item">
                        <ADP_amazon publisherID={amazonPublisherID} enableA9={amazonA9} postId={currentDomain} />
                        {presetSspAdapterList.map((adapterName) => {
                            let adapterConfig = displayAdapters[adapterName];

                            return (
                                adapterConfig && (
                                    <BaseMultiparameterAdapter
                                        adapterName={adapterName}
                                        key={adapterName}
                                        settings={mutationData[adapterName] || {}}
                                        adposition={adposition}
                                        postId={`${currentDomain}`}
                                        isPresetAdapter={false}
                                        rawPositionsInfo={isPresetOverriden ? rawSSPPositions : rawPresetPositions}
                                        overrideShowCheckBox={isPresetOverriden}
                                        newCreatedDomain={newCreatedDomain}
                                        customAdapterSettings={customAdapterSettings}
                                        updateCustomAdapterSettings={setCustomAdapterSettings}
                                        adaptersBidAdjustment={adaptersBidAdjustment}
                                        setAdaptersBidAdjustments={setAdaptersBidAdjustments}
                                        render={(props) => <MultiparameterInputs {...props} />}
                                        toggleAdapterSelection={toggleAdapterSelection}
                                        isAdapterEnabled={isPresetOverriden
                                            ? domainSspAdapterList.includes(adapterName)
                                            : presetSspAdapterList.includes(adapterName)
                                        }
                                        {...adapterConfig}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default SlotPlacementID;
