import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AppMenu } from '../AppMenu';
import { AppTopbar } from '../AppTopbar';
import { DomainsDropdown } from '../DomainsDropdown';
import { sidebarClassName } from '../constants';
import { noop } from '../utilities/utils';
import AppLogo from './AppLogo';

const Partner = (props) => {
    const sidebar = useRef();
    
    return (
        <>
            <AppTopbar {...props} />
            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={true} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} >
                    <AppLogo />
                    <DomainsDropdown />
                    <AppMenu {...props} model='partner' onMenuItemClick={noop} clientInfo={props.clientInfo} sourceData={props.sourceData} />
                </div>
            </CSSTransition>
        </>
    )
}

export default Partner;
