import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Cta from "./CTA";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { LINEITEM_STATUS } from "./constants";

const DOMAIN_SELECTION_MUTATION = gql`
    mutation callMarketplaceNotificationMutation($from: String, $to: String, $subject: String, $action: String, $domainsToReview: String, $domainIds: String, $buyer: String, $campaignName: String, $campaignDetails: String) {
        marketplaceNotificationMutation(input: { from: $from, to: $to, subject: $subject, action: $action, domainsToReview: $domainsToReview, domainIds: $domainIds, buyer: $buyer, campaignName: $campaignName, campaignDetails: $campaignDetails }) {
            response
        }
    }
`;

const SaveSection = ({
    order,
    saveMarketplaceOrderHandler,
    selectSizesHandler,
    switchTabHandler,
    customerName,
    domainIdMappings,
    setMarketplaceOrderStats
}) => {
    let { activeTab = 0 } = order;
    const [showDraftLoader, setShowDraftLoader] = useState(false);
    const [showPublishLoader, setShowPublishLoader] = useState(false);
    const [domainSelectionNotificationMutation] = useMutation(DOMAIN_SELECTION_MUTATION);
    const ctaStyles = "min-width: unset !important; width: auto !important; margin-right: 1em;"

    async function sendNotificationEmail(order, notify = false) {
        const { name: campaignName, description, startDate, endDate, impressionGoals, networks = [] } = order;
        const domainsToReview = networks.map((id) => {
            return domainIdMappings[id].domainName;
        });
        const variables = {
            subject: "Marketplace Domain Selection",
            from: "no-reply@adgrid.io",
            to: "",
            domainsToReview: JSON.stringify(domainsToReview),
            domainIds: JSON.stringify(networks.map((n) => parseInt(n))),
            action: "SEND_NETWORK_SELECTION_EMAIL",
            buyer: customerName,
            campaignName: order.name,
            campaignDetails: JSON.stringify({
                "Campaign name": campaignName,
                "Campaign start date": new Date(startDate).toLocaleDateString(),
                "Campaign end date": new Date(endDate).toLocaleDateString(),
                "Campaign notes": description || "n/a",
                "Impression goal": Number(impressionGoals).toLocaleString(),
            }),
        };

        if (!order.lineItemId || notify) {
            await domainSelectionNotificationMutation({
                variables,
            });
        }
    }

    return (
        <div className="p-grid p-fluid">
            <div className={`p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0`}></div>
            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                <div className="flex">
                    {
                        order.format === 'display' && (
                            <Cta
                                cssStyles={ctaStyles}
                                onClick={() => selectSizesHandler()}
                                ctaText="Expected Creative Sizes"
                                themeClasses="marketplace-cta default outlined"
                            />
                        )
                    }
                    <Cta
                        cssStyles={ctaStyles}
                        onClick={async () => {
                            setShowDraftLoader(true);
                            await saveMarketplaceOrderHandler(true);
                            setShowDraftLoader(false);
                        }}
                        ctaText="Save as draft"
                        themeClasses="marketplace-cta default outlined"
                        showLoader={showDraftLoader}
                        disabled={showPublishLoader}
                    />
                    <Cta
                        cssStyles={ctaStyles}
                        onClick={async () => {
                            try {
                                let notifyCustomers = order.notifyCustomers;
                                setShowPublishLoader(true);
                                let [succeeded, marketplaceOrder] = await saveMarketplaceOrderHandler(false);
                                setShowPublishLoader(false);
                                if (succeeded) {
                                    setMarketplaceOrderStats({
                                        [marketplaceOrder.lineItemId]: {
                                            clicksDelivered: 0,
                                            impressionsDelivered: 0,
                                            status: LINEITEM_STATUS.READY,
                                            viewableImpressionsDelivered: 0
                                        }
                                    })
                                    await sendNotificationEmail(order, notifyCustomers);
                                }
                            } catch (err) {
                                setShowPublishLoader(false);
                            }
                        }}
                        ctaText="Publish"
                        themeClasses="marketplace-cta success outlined"
                        showLoader={showPublishLoader}
                        disabled={showDraftLoader}
                    />
                    {
                        activeTab === 0 && (
                            <Cta
                                cssStyles={ctaStyles}
                                onClick={switchTabHandler}
                                ctaText="Next"
                                icon={faCaretRight}
                                iconPos="right"
                                themeClasses="marketplace-cta success outlined"
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default SaveSection;
