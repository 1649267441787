/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef } from "react";

const styles = css`
    position: absolute;
    left: -99999px;
    visibility: hidden;
    z-index: -99999;
`

const CampaignReportDownloadButton = forwardRef(function DownloadButton(props, ref) {
    
    const { filename, href } = props
    return (
        <a css={styles} rel="noreferrer" ref={ref} href={href} download={filename + "_campaign_breakdown.csv"}></a>
    )
})

export default CampaignReportDownloadButton