/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCirclePause, faCircleXmark, faCircleStop, faCircleCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";

const Cta = ({ 
    ctaText, 
    onClick, 
    icon, 
    disabled = false, 
    showLoader = false, 
    themeClasses = "", 
    iconPos = "right", 
    tooltip = "", 
    cssStyles = "",
    tooltipPosition = 'top'

}) => {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            className={themeClasses}
            tooltip={tooltip}
            tooltipOptions={{position: tooltipPosition}}
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 150px;
                .p-button-label {
                    display: none;
                }
                ${cssStyles}
            `}
        >
            <div
                css={css`
                    &.left {
                        .cta-icon {
                            order: -1;
                        }
                    }
                    .cta-icon {
                        padding: 0 5px;
                        order: 1;
                    }
                `}
                className={iconPos}
            >
                <span>{ctaText}</span>
                <span className="cta-icon">{showLoader ? <FontAwesomeIcon icon={faCircleNotch} spin={true} /> : icon ? <FontAwesomeIcon icon={icon} spin={false} /> : null}</span>
            </div>
        </Button>
    );
};

export const PauseCta = ({ onClick, disabled, showLoader, ctaText, themeClasses = "", tooltip = null }) => {
    return <Cta ctaText={ctaText || "Pause"} icon={faCirclePause} onClick={onClick} disabled={disabled} showLoader={showLoader} themeClasses={themeClasses} tooltip={tooltip} />;
};

export const PlayCta = ({ onClick, disabled, showLoader, ctaText, themeClasses = "", tooltip = null }) => {
    return <Cta ctaText={ctaText || "Resume"} icon={faCirclePlay} onClick={onClick} disabled={disabled} showLoader={showLoader} themeClasses={themeClasses} tooltip={tooltip} />;
};

export const RemoveDomainCta = ({ onClick, disabled, ctaText, showLoader, themeClasses = "", tooltip = null }) => {
    return <Cta ctaText={ctaText || "Remove"} icon={faCircleXmark} onClick={onClick} disabled={disabled} showLoader={showLoader} themeClasses={themeClasses} tooltip={tooltip} />;
};

export const RejectDomainCta = ({ onClick, disabled, ctaText, showLoader, themeClasses = "", tooltip = null }) => {
    return <Cta ctaText={ctaText || "Reject"} icon={faCircleStop} onClick={onClick} disabled={disabled} showLoader={showLoader} themeClasses={themeClasses} tooltip={tooltip} />;
};

export const ApproveDomainCta = ({ onClick, disabled, ctaText, showLoader, themeClasses = "", tooltip = null }) => {
    return <Cta ctaText={ctaText || "Approve"} icon={faCircleCheck} onClick={onClick} disabled={disabled} showLoader={showLoader} themeClasses={themeClasses} tooltip={tooltip} />;
};

export default Cta;
