import { Button } from 'primereact/button'
import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const OrderRow = ({ idx, name, children, label, description, lg = false }) => {
    return (
        <div
            className="p-grid p-fluid"
            css={css`
                .lg-margin {
                    margin-right: 2em;
                }
            `}
        >
            <div className={`p-col-12 p-sm-12 ${lg ? 'p-md-2 p-lg-2 lg-margin' : 'p-md-3 p-lg-3'} p-mt-2 small-margin-top-0 small-padding-bottom-0`}>
                <label 
                    htmlFor={name + idx} 
                    className="capitalize bold"
                    css={css`
                        display: flex;
                        align-items: center;
                        .label {
                            display: inline-block;
                            margin-left: 0.5em;
                        }
                        .p-button {
                            height: 1.75em !important;
                            width: 1.75em !important;
                            background-color: #bdbdbd;
                            border-color: #ccc;
                        }
                    `}
                >
                    <Button 
                        label="Proceed" 
                        icon="pi pi-info-circle" 
                        className="p-button p-component p-button-rounded p-button-icon-only" 
                        tooltip={description || label || name}
                    />
                    <span className="label">{label || name}</span>
                </label>
            </div>
            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                { children }
            </div>
        </div>
    )
}

export default OrderRow