import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ADP_SIZES_ALL } from "../../../constants";
import { first, getIn } from "../../../utilities/utils";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const DROPDOWNS = {
    boolean: [
        { name: "True", value: true },
        { name: "False", value: false },
    ],
};

const positionNameStyle = {
    color: "#495057",
    fontWeight: 400,
    fontSize: "12px",
};

const positionInfo = {
    display: "inline-block",
    marginRight: "0.5em",
    color: "#84878b",
    fontWeight: 400,
    fontSize: "12px",
};

const headerDetailsStyle = {
    fontWeight: "400",
    paddingLeft: "3px",
};

const MultiparameterInputs = ({
    mutationData,
    adapterName,
    adapterParametersNames,
    rowLevelParameters,
    handleChange,
    onClick,
    handleAddClick,
    adapterSizes,
    globalParams,
    setGlobalParams,
    setIsPristine,
    sizeKey,
    adtype,
    rawPositionsInfo = {},
    allowRefresh,
    customAdapterSettings,
    updateCustomAdapterSettings,
    bidAdjustmentPercent,
    setBidAdjustmentPercent,
}) => {
    const tableColumns = [
        { name: "", background: "#D6D6D6", className: "empty" },
        { name: `Desktop`, background: "#A8BBA9", className: "device desktop" },
        { name: `Tablet`, background: "#BA9EC5", className: "device tablet" },
        { name: `Mobile`, background: "#D1C294", className: "device mobile" },
    ];

    const devicesNames = [
        ["desktop", "desk"],
        ["tablet", "tab"],
        ["mobile", "mob"],
    ];
    let counter = -1;

    return (
        <>
            <div className="global-parameters">
                {adtype !== "video" && (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            margin-right: 1em;
                            .icon {
                                display: inline-block;
                                padding-left: 0.5em;
                                padding-right: 0.5em;
                                font-weight: bold;
                            }
                        `}
                    >
                        <span className="icon">Bid adjustment percent %</span>
                        <InputText value={bidAdjustmentPercent} onChange={(e) => setBidAdjustmentPercent(e.target.value)} />
                        {/* <InputNumber value={parseFloat(bidAdjustmentPercent)} onValueChange={(e) => setBidAdjustmentPercent(e.target.value)} mode="decimal" minFractionDigits={2} maxFracionDigits={2} /> */}
                    </div>
                )}

                {globalParams &&
                    Object.keys(globalParams).map((p) => (
                        <div className="g-param-wrapper" key={p}>
                            <span className="bold g-param-name">{globalParams[p][1] || p}</span>
                            <InputText
                                placeholder={p}
                                name={p}
                                id={p}
                                value={globalParams[p][0] || ""}
                                onChange={(ev) => {
                                    let o = { ...globalParams };
                                    let parameterDataType = globalParams[p]?.[2];
                                    for (let globalParameterName in globalParams) {
                                        o[globalParameterName] = first(globalParams[globalParameterName]);
                                    }
                                    o[p] = parameterDataType === "number" ? parseFloat(ev.target.value) : ev.target.value;
                                    setGlobalParams(o);
                                    setIsPristine(false);
                                }}
                            />
                        </div>
                    ))}
                {allowRefresh && (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;

                            .dropdown-label {
                                display: inline-block;
                                margin-right: 1em;
                            }
                            .allow-refresh-dropdown .pi-chevron-down {
                                font-size: 1.25em;
                            }
                        `}
                    >
                        <span className="dropdown-label">Allow refresh</span>
                        <Dropdown
                            name="allow_refresh"
                            className="allow-refresh-dropdown"
                            value={customAdapterSettings?.[adapterName]?.allow_refresh || false}
                            optionLabel="name"
                            optionValue="value"
                            options={DROPDOWNS.boolean}
                            onChange={(e) => {
                                let newCustomSettings = { ...customAdapterSettings };
                                newCustomSettings[adapterName] = newCustomSettings[adapterName] || {};
                                newCustomSettings[adapterName][e.target.name] = e.target.value;
                                updateCustomAdapterSettings(newCustomSettings);
                                setIsPristine(false);
                            }}
                        />
                    </div>
                )}
            </div>
            {mutationData.length ? (
                mutationData.map((adPosition, index) => {
                    const { name } = adPosition;
                    const rawPositionInfo = rawPositionsInfo[name];
                    const { desk, tab, mob, description, sticky, disable_refresh, interstitial, hbid_list_exclude, out_of_page, sticky_refresh_size } = rawPositionsInfo[name] || {};

                    return (
                        <Accordion key={index}>
                            <AccordionTab
                                headerClassName="top-accordion"
                                header={
                                    <React.Fragment>
                                        <div
                                            className="p-d-flex p-jc-between"
                                            style={{
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div className="p-field-checkbox p-mb-2">
                                                <div>
                                                    <span className="p-text-bold">{adPosition.display_name}</span>
                                                    {description && <span style={headerDetailsStyle}>{` [ ${description} ] `}</span>}
                                                </div>
                                            </div>
                                            {rawPositionInfo && (
                                                <div>
                                                    {desk && (
                                                        <span style={positionNameStyle}>
                                                            Desktop: <span style={positionInfo}>{desk?.adsize}</span>
                                                        </span>
                                                    )}
                                                    {tab && (
                                                        <span style={positionNameStyle}>
                                                            Tablet: <span style={positionInfo}>{tab?.adsize}</span>
                                                        </span>
                                                    )}
                                                    {mob && (
                                                        <span style={positionNameStyle}>
                                                            Mobile: <span style={positionInfo}>{mob?.adsize}</span>
                                                        </span>
                                                    )}
                                                    {sticky && <span style={headerDetailsStyle}>[ sticky ]</span>}
                                                    {disable_refresh && <span style={headerDetailsStyle}>[ disable refresh ]</span>}
                                                    {interstitial && <span style={headerDetailsStyle}>[ interstitial ]</span>}
                                                    {hbid_list_exclude && <span style={headerDetailsStyle}>[ exclude hb ]</span>}
                                                    {out_of_page && <span style={headerDetailsStyle}>[ out of page ]</span>}
                                                    {sticky_refresh_size && <span style={headerDetailsStyle}>[ sticky refresh size ]</span>}
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                }
                            >
                                <div className="adapter-wrapper mb-1">
                                    <div className="config-wrapper">
                                        {adPosition.size.map((devices, j) => {
                                            return (
                                                <div key={index + j} className="adapter-parameters">
                                                    <div className="row-level-params" style={{ flexBasis: "100%" }}>
                                                        {rowLevelParameters && rowLevelParameters.length
                                                            ? rowLevelParameters.map(([parameterName, parameterType, parameterPrettyName], idx) => {
                                                                  let deviceSize = devices.desk || devices.tab || devices.mob;
                                                                  return (
                                                                      <div className="label">
                                                                          <span
                                                                              style={{
                                                                                  display: "inline-block",
                                                                                  margin: "0 1em 1em 0",
                                                                                  fontWeight: "bold",
                                                                              }}
                                                                          >
                                                                              {parameterPrettyName}
                                                                          </span>
                                                                          {parameterType === "boolean" ? (
                                                                              <Dropdown
                                                                                  name={parameterName}
                                                                                  className="row-level-dropdown"
                                                                                  value={deviceSize?.[parameterName] || false}
                                                                                  optionLabel="name"
                                                                                  optionValue="value"
                                                                                  options={DROPDOWNS.boolean}
                                                                                  onChange={(e) => {
                                                                                      handleChange({
                                                                                          param: e,
                                                                                          key: index,
                                                                                          id: j,
                                                                                          rowLevelParameter: true,
                                                                                      });
                                                                                  }}
                                                                              />
                                                                          ) : (
                                                                              <InputText />
                                                                          )}
                                                                      </div>
                                                                  );
                                                              })
                                                            : null}
                                                    </div>
                                                    <div className={`sizing-row ${adapterSizes === "ALL_SIZES" ? "all-sizes" : "custom-sizes"}`}>
                                                        {adtype !== "video" && (
                                                            <div className={`adapter-size-wrapper ${adapterSizes === "ALL_SIZES" ? "all-sizes" : "custom-sizes"}`}>
                                                                <span className="dropdown-label">{sizeKey ? sizeKey.toUpperCase() : "Size"}</span>
                                                                {adapterSizes === "ALL_SIZES" ? (
                                                                    <Dropdown name="adp_size" options={ADP_SIZES_ALL} value={ADP_SIZES_ALL[0]} optionLabel="name" placeholder="Select Size" />
                                                                ) : (
                                                                    <Dropdown
                                                                        name="adp_size"
                                                                        options={adapterSizes}
                                                                        value={sizeKey ? devices[sizeKey] : devices.size}
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                param: e,
                                                                                key: index,
                                                                                id: j,
                                                                            });
                                                                        }}
                                                                        optionLabel="name"
                                                                        placeholder="Select Size"
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-times"
                                                            className="hide-item remove-row-btn p-button-danger p-button-rounded p-button-text p-button-icon-only"
                                                            tooltip="Remove row"
                                                            tooltipOptions={{ position: "top" }}
                                                            onClick={() => onClick("displayConfirmation", index, j)}
                                                        />
                                                    </div>
                                                    <div className={`controls-wrapper ${adapterSizes === "ALL_SIZES" ? "all-sizes" : "w-100"}`}>
                                                        {adtype !== "video" && (
                                                            <div className="adapter-config">
                                                                {tableColumns.map(({ name, background, className }) => (
                                                                    <div key={name + index + j} className={className}>
                                                                        <h6 className="adapter-device-name">{name}</h6>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className={`values ${adtype === "video" ? "video-ad" : "display-ad"}`}>
                                                            {adapterParametersNames.map(([param, _, paramPrettyname], adapterIndex) => {
                                                                ++counter;
                                                                return (
                                                                    <>
                                                                        <div className="param-names" key={counter}>
                                                                            <p>
                                                                                <strong className="param">{paramPrettyname || param}</strong>
                                                                            </p>
                                                                        </div>
                                                                        {(function () {
                                                                            if (adtype === "video") {
                                                                                return (
                                                                                    <div className="param-input">
                                                                                        <p className="mobile-parameter-label text-center bold mb-0 text-black">{paramPrettyname || param}</p>
                                                                                        <InputText
                                                                                            placeholder={param || paramPrettyname}
                                                                                            name={param}
                                                                                            value={getIn(adPosition, "size", j, param) || ""}
                                                                                            onChange={(e) => {
                                                                                                handleChange({
                                                                                                    param: e,
                                                                                                    key: index,
                                                                                                    id: j,
                                                                                                    isVideoAdapter: true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            } else {
                                                                                return devicesNames.map(([deviceName, deviceSize], i) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={deviceSize}
                                                                                            className={`adapter-parameter-values ${deviceSize}`}
                                                                                            style={{
                                                                                                order: i * 2 - counter,
                                                                                            }}
                                                                                            data-index={i}
                                                                                        >
                                                                                            <div className={`p-d-none sm-pad small-show dark-${deviceName}`}>
                                                                                                <h6 className="bold text-center capitalize mb-0 text-black">{deviceName}</h6>
                                                                                            </div>
                                                                                            <div className="param-input">
                                                                                                <p className="mobile-parameter-label text-center bold mb-0 text-black">{paramPrettyname || param}</p>
                                                                                                <InputText
                                                                                                    placeholder={param || paramPrettyname}
                                                                                                    name={param}
                                                                                                    id={deviceSize}
                                                                                                    value={getIn(devices, deviceSize, param) || ""}
                                                                                                    onChange={(e) => {
                                                                                                        handleChange({ param: e, key: index, id: j });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                });
                                                                            }
                                                                        })()}
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-end add-row">
                                    <Button
                                        type="button"
                                        label="Add Row"
                                        icon="pi pi-plus"
                                        tooltip="Add row"
                                        tooltipOptions={{ position: "top" }}
                                        onClick={() => {
                                            handleAddClick({
                                                i: index,
                                                isVideoAdapter: adtype === "video",
                                                params: adapterParametersNames.reduce((acc, [parameterName]) => {
                                                    acc[parameterName] = "";
                                                    return acc;
                                                }, {}),
                                            });
                                        }}
                                    />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    );
                })
            ) : (
                <div className="ssp-notice">
                    <p>You must create at least one ad position before you can configure this SSP adapter</p>
                </div>
            )}
        </>
    );
};

export default MultiparameterInputs;
