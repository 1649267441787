const DEFAULT_STATE = {
    screenName: "",
    isModified: false,
};

const ACTIONS = {
    REGISTER_CHANGES: "register_changes",
    CLEAR_CHANGES: "clear_changes",
};

function screenChangesReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case ACTIONS.REGISTER_CHANGES:
        case ACTIONS.CLEAR_CHANGES:
            return { ...state, ...action.data };
        default:
            return state;
    }
}

export function registerScreenChanges(screenName) {
    return {
        type: ACTIONS.REGISTER_CHANGES,
        data: {
            screenName,
            isModified: true,
        },
    };
}

export function clearScreenChanges() {
    return {
        type: ACTIONS.CLEAR_CHANGES,
        data: { ...DEFAULT_STATE },
    };
}

export default screenChangesReducer;
