import { useMutation } from "@apollo/client";
import { OPTIMIZE_BIDDING_UPDATE_MUTATION } from "../mutations/mutations";

export function useOptimizeBiddingSave(options = {}) {
    const [container] = useMutation(OPTIMIZE_BIDDING_UPDATE_MUTATION, options)

    return ({
        currentDomain,
        optimizeBiddingList,
        showCheckBox,
        overrideTrafficShapingSettings,
        domainTrafficShapingSettings,
    }) => {

        let variables = {
            id: currentDomain && String(currentDomain),
            optimizeBiddingList,
            showCheckBox,
            overrideTrafficShapingSettings,
            domainTrafficShapingSettings,
        }

        for (let p in variables) {
            let v = variables[p]
            if (v === null || v === undefined) {
                delete variables[p]
            }
        }

        return container({
            variables
        })

    }
}