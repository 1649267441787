import React from "react";
import InputTextSection from "./InputTextSection";
import DropdownSection from "./DropdownSection";
import { CREATIVE_NAMES, DEFAULT_CLOSE_BTN_POSITIONS, DISPLAY_INTERSTITIAL_AD_FIELDS } from "./definitions";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CLOSE_BUTTON_POSITIONS = [
    { label: "Top left", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT },
    { label: "Top left in", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT_IN },
    { label: "Top right", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT },
    { label: "Top right in", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT_IN },
];

const CREATIVE_TYPE = CREATIVE_NAMES.DISPLAY_INTERSTITIAL;

export default function DisplayInterstitial({
    interstitialImg,
    interstitialImgMobile,
    closeBtnWord,
    closeBtnPos,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                changeHandler={handleChange}
                clickTag={clickTag}
                creativeType={CREATIVE_TYPE}
                impressionTag={impressionTag}
                adServingType={adServingType}
            >
                <AssetInputOrUploadSection
                    inputValue={interstitialImg}
                    inputLabel="Desktop creative URL"
                    inputName={DISPLAY_INTERSTITIAL_AD_FIELDS.interstitialImg}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={interstitialImgMobile}
                    inputLabel="Mobile creative URL"
                    inputName={DISPLAY_INTERSTITIAL_AD_FIELDS.interstitialImgMobile}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />

                <DropdownSection
                    label="Close button position"
                    options={CLOSE_BUTTON_POSITIONS}
                    name={DISPLAY_INTERSTITIAL_AD_FIELDS.closeBtnPos}
                    value={closeBtnPos}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                />
                <InputTextSection
                    label={"Close button text"}
                    name={DISPLAY_INTERSTITIAL_AD_FIELDS.closeBtnWord}
                    value={closeBtnWord}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    uriEncodeValue={false}
                />
            </BaseCreativeControls>
        </>
    );
}