const DEFAULT_STATE = {
    isAdmin: false,
    isAgency: false,
    userID: null,
    domains: [],
    currentDomain: null,
    presets: [],
    currentPreset: null,
    customers: [],
    currentCustomer: null,
    mode: "domain",
    domainsToCustomersMappings: {},
    customerToDomainsMappings: {},
    customerToAdminsMappings: {},
    userToEdit: null,
    activeMenuIndex: 0,
    stripeCustomerId: null,
    currentCustomerLogo: null,
};

function userLoginReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case "SET_USER_DOMAINS":
            return { ...state, domains: action.data.userDomains };
        case "SET_CURRENT_DOMAIN":
            return { ...state, currentDomain: !action.data.domain ? action.data.domain : String(action.data.domain) };
        case "SET_USER_ID":
            return { ...state, userID: action.data.userID };
        case "SET_USER_TYPE":
            return { ...state, isAdmin: action.data.isAdmin };
        case "SET_NAV_MODE":
            return { ...state, mode: action.data.mode };
        case "SET_PRESETS":
            return { ...state, presets: action.data.presets };
        case "SET_CURRENT_PRESET":
            return { ...state, currentPreset: !action.data.preset ? action.data.preset : String(action.data.preset) };
        case "SET_CUSTOMERS":
            return { ...state, customers: action.data.customers };
        case "SET_CURRENT_CUSTOMER":
            return { ...state, currentCustomer: action.data.customer };
        case "RESET_DROPDOWNS":
            return { ...state, currentCustomer: null, currentDomain: null, currentPreset: null };
        case "SET_DT_MAPPINGS":
            return { ...state, domainsToCustomersMappings: { ...state.domainsToCustomersMappings, ...action.data.mappings } };
        case "SET_CTD_MAPPINGS":
            return { ...state, customerToDomainsMappings: { ...state.customerToDomainsMappings, [action.data.customer]: action.data.domains } };
        case "SET_USER_TO_EDIT":
            return { ...state, userToEdit: action.data.id };
        case "SET_ACTIVE_MENU_INDEX":
            return { ...state, activeMenuIndex: action.data.idx };
        case "SET_STRIPE_CUSTOMER_ID":
            return { ...state, stripeCustomerId: action.data.stripeCustomerId };
        case "SET_CUSTOMER_ROLE":
            return { ...state, isAgency: action.data.isAgency };
        case "SET_CUSTOMER_LOGO":
            return { ...state, currentCustomerLogo: action.data.logo };
        case "SET_CUSTOMER_TO_ADMIN_MAPPINGS":
            return { ...state, customerToAdminsMappings: action.data.mappings };
        default:
            return state;
    }
}

function setStripeCustomerId(stripeCustomerId) {
    return {
        type: "SET_STRIPE_CUSTOMER_ID",
        data: {
            stripeCustomerId,
        },
    };
}

function setActiveMenuIndex(idx) {
    return {
        type: "SET_ACTIVE_MENU_INDEX",
        data: {
            idx,
        },
    };
}

function setAvailableDomains(userDomains) {
    return {
        type: "SET_USER_DOMAINS",
        data: {
            userDomains,
        },
    };
}

function setAvailablePresets(presets) {
    return {
        type: "SET_PRESETS",
        data: {
            presets,
        },
    };
}

function setAvailableCustomers(customers) {
    return {
        type: "SET_CUSTOMERS",
        data: {
            customers,
        },
    };
}

function setCurrentCustomer(customer) {
    return {
        type: "SET_CURRENT_CUSTOMER",
        data: {
            customer,
        },
    };
}

function resetCurrentCustomer() {
    return {
        type: "SET_CURRENT_CUSTOMER",
        data: {
            customer: null,
        },
    };
}

function setCurrentDomain(domain) {
    return {
        type: "SET_CURRENT_DOMAIN",
        data: {
            domain,
        },
    };
}

function setCurrentPreset(preset) {
    return {
        type: "SET_CURRENT_PRESET",
        data: {
            preset,
        },
    };
}

function resetCurrentDomain() {
    return setCurrentDomain(null);
}

function resetCurrentPreset() {
    return setCurrentPreset(null);
}

function setUserID(userID) {
    return {
        type: "SET_USER_ID",
        data: {
            userID,
        },
    };
}

function setUserType(isAdmin = false) {
    return {
        type: "SET_USER_TYPE",
        data: {
            isAdmin,
        },
    };
}

function setNavigationMode(mode) {
    return {
        type: "SET_NAV_MODE",
        data: {
            mode,
        },
    };
}

function resetDropdownStates() {
    return {
        type: "RESET_DROPDOWNS",
    };
}

function setDomainsToCustomersMappings(mappings) {
    return {
        type: "SET_DT_MAPPINGS",
        data: {
            mappings,
        },
    };
}

function setCustomerToDomainsMapppings(customer, domains = []) {
    domains.sort((d1, d2) => d1.name.localeCompare(d2.name));
    return {
        type: "SET_CTD_MAPPINGS",
        data: {
            customer,
            domains,
        },
    };
}

function setUserToEdit(id) {
    return {
        type: "SET_USER_TO_EDIT",
        data: {
            id,
        },
    };
}

function setCustomerRole(isAgency) {
    return {
        type: "SET_CUSTOMER_ROLE",
        data: {
            isAgency,
        },
    };
}

function setCustomerLogo(logo) {
    return {
        type: "SET_CUSTOMER_LOGO",
        data: {
            logo,
        },
    };
}

function setCustomerToAdminsMappings(mappings) {
    return {
        type: "SET_CUSTOMER_TO_ADMIN_MAPPINGS",
        data: {
            mappings,
        },
    };
}

export default userLoginReducer;

export {
    setAvailableDomains,
    setCurrentDomain,
    setUserID,
    resetCurrentDomain,
    setUserType,
    setNavigationMode,
    setAvailablePresets,
    setCurrentPreset,
    resetCurrentPreset,
    setAvailableCustomers,
    setCurrentCustomer,
    resetCurrentCustomer,
    resetDropdownStates,
    setDomainsToCustomersMappings,
    setCustomerToDomainsMapppings,
    setUserToEdit,
    setActiveMenuIndex,
    setStripeCustomerId,
    setCustomerRole,
    setCustomerLogo,
    setCustomerToAdminsMappings,
};
