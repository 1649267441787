import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'prismjs/themes/prism-coy.css';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../App.scss';
import { AppMenu } from '../AppMenu';
import { AppTopbar } from '../AppTopbar';
import { DomainsDropdown } from '../DomainsDropdown';
import { sidebarClassName, wrapperClass } from '../constants';
import '../layout/flags/flags.css';
import '../layout/layout.scss';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TabPanel, TabView } from 'primereact/tabview';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AppLogo from './AppLogo';

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, {
  retries: 10,
  retryDelay: 1000
});

const getBarConfig = (bidder, cpm) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: 'CPM Summary'
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: bidder,
    title: {
      text: null
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'CPM Summary',
      align: 'high'
    },
    labels: {
      overflow: 'justify'
    }
  },
  tooltip: {
    valueSuffix: ' USD'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    name: 'CPM',
    data: cpm
  },
  ]
});

const getLineConfig = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Bidder CPM'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const getApiLineConfig = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'API Responses'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const getParticipationConfig = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Participation Rates'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});


const getTimeoutConfig = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Timeout Rates'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },

  series: data
});


const getBidderDetailsLine = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Bidder Performance'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}'
    }
  },

  yAxis: [{ // Primary yAxis

    labels: {
      format: '{value}',
      style: {
        color: Highcharts.getOptions().colors[0]
      },
      formatter: function () {
        return this.value.toLocaleString();
      }
    },
    title: {
      text: 'Bid Won',
      style: {
        color: Highcharts.getOptions().colors[0]
      }
    },
    opposite: true

  }, { // Secondary yAxis
    gridLineWidth: 0,
    title: {
      text: 'Estimated Revenue',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    labels: {
      format: '${value}',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    }

  }],
  credits: {
    enabled: false
  },
  series: data
});



const getParticipationBar = (data, list) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: list,
    title: {
      text: null
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Participation Rate',
      align: 'high'
    },
    labels: {
      overflow: 'justify'
    }
  },
  tooltip: {
    valueSuffix: ' %'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    name: 'Rate',
    data: data
  },
  ]
});

const getTimeoutBar = (data, list) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: list,
    title: {
      text: null
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Participation Rate',
      align: 'high'
    },
    labels: {
      overflow: 'justify'
    }
  },
  tooltip: {
    valueSuffix: ' %'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    name: 'Rate',
    data: data
  },
  ]
});

const getTotalRevenueLine = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Total Revenue, CPM and Impressions'
  },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}'
    }
  },

  yAxis: [{ // Primary yAxis

    labels: {
      format: '{value}',
      style: {
        color: Highcharts.getOptions().colors[1]
      },
      formatter: function () {
        return this.value.toLocaleString();
      }
    },
    title: {
      text: 'Revenue and CPM Values',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    opposite: true

  }, { // Secondary yAxis
    gridLineWidth: 0,
    title: {
      text: 'Impression Values',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    },
    labels: {
      format: '{value}',
      style: {
        color: Highcharts.getOptions().colors[1]
      }
    }

  }],

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const getRevenueLine = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Bidder Revenue'
  },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}'
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const getRevenueBar = (bidder, data) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: 'Revenue Summary'
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: bidder,
    title: {
      text: null
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Revenue Summary',
      align: 'high'
    },
    labels: {
      overflow: 'justify'
    }
  },
  tooltip: {
    valueSuffix: ' USD'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    name: 'Revenue',
    data: data
  },
  ]
});


const getImpressionBar = (bidder, data) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    categories: bidder,
    title: {
      text: null
    },
    labels: {
      // overflow: 'justify', 
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Impression Distribution By Bidder',
      align: 'high'
    },
    labels: {
      // overflow: 'justify', 
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },
  tooltip: {
    valueSuffix: ' ',
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  series: [{
    name: 'Impressions',
    data: data
  },
  ]
});

const getImpressionLine = (startDate, data) => ({

  lang: {
    decimalPoint: '.',
    thousandsSep: ','
  },
  chart: {
    type: 'spline'
  },
  title: {
    text: 'Impression Distribution'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  tooltip: {
    pointFormat: "{point.y:,.0f}"
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const getImpressionBidderLine = (startDate, data) => ({

  chart: {
    type: 'spline'
  },
  title: {
    text: 'Bidder Impression'
  },

  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%m-%d-%Y}',
    }
  },

  yAxis: {
    labels: {
      formatter: function () {
        return this.value.toLocaleString();
      }
    }
  },

  plotOptions: {
    series: {
      pointStart: Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
        startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds()),
      pointInterval: 24 * 3600 * 1000 // one day
    }
  },
  credits: {
    enabled: false
  },
  series: data
});

const API_URL = process.env.REACT_APP_REPORTING_API_URL;

export const Reports = (props) => {
  const [bidderIndex, setBidderIndex] = useState(0);
  let today = new Date();
  let prevDate = today.setDate(today.getDate() - 7);
  today = new Date();
  let nextDate = today.setDate(today.getDate() - 1);

  const [currentDateRange, onChange] = useState([new Date(prevDate), new Date(nextDate)]);
  const { currentDomain, currentCustomer } = useSelector(state => state.userInformation)
  const { modifiedDomains, modifiedPresets, builtDomains } = useSelector(state => state.buildState)
  const history = useHistory()
  const [bidderList, setBidderList] = useState(null);
  const [bidderCpm, setBidderCpm] = useState(null);
  const [cpmLineData, setCpmLineData] = useState([]);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [timeoutData, setTimeoutData] = useState([]);
  const [participationData, setParticipationData] = useState([]);
  const [bidderDetails, setBidderDetails] = useState([]);
  const [bidderLineChart, setBidderLineChart] = useState({});
  const [participationBarData, setParticipationBarData] = useState({});
  const [participationList, setParticipationList] = useState(null);
  const [timeoutBarData, setTimeoutBarData] = useState({});
  const [timeoutList, setTimeoutList] = useState(null);
  const [revenueChartData, setRevenueChartData] = useState(null);
  const [totalRevenueChartData, setTotalRevenueChartData] = useState(null);
  const [revenueList, setRevenueList] = useState(null);
  const [reveneuData, setRevenueData] = useState(null);
  const [revenueSummary, setRevenueSummary] = useState({});
  const [impressionLineData, setImpressionLineData] = useState({});
  const [totalImpressionCount, setTotalImpressionCount] = useState(0);
  const [bidderImpression, setBidderImpression] = useState({});
  const [impressionBarList, setImpressionBarList] = useState({});
  const [impressionBarData, setImpressionBarData] = useState({});

  const cpmBarConfig = getBarConfig(bidderList, bidderCpm);
  const cpmLineConfig = getLineConfig(new Date(currentDateRange[0]), cpmLineData);
  const apiLineConfig = getApiLineConfig(new Date(currentDateRange[0]), apiResponseData);
  const partLineConfig = getParticipationConfig(new Date(currentDateRange[0]), participationData);
  const timeoutLineConfig = getTimeoutConfig(new Date(currentDateRange[0]), timeoutData);
  let performanceData = []; performanceData[0] = bidderLineChart[bidderIndex];
  const bidderDetailsConfig = getBidderDetailsLine(new Date(currentDateRange[0]), performanceData[0]);
  const participationBar = getParticipationBar(participationBarData, participationList)
  const timeoutBar = getTimeoutBar(timeoutBarData, timeoutList)
  const revenueLineChart = getRevenueLine(new Date(currentDateRange[0]), revenueChartData);
  const totalRevenueLineChart = getTotalRevenueLine(new Date(currentDateRange[0]), totalRevenueChartData);
  const revenueBarChart = getRevenueBar(revenueList, reveneuData);
  const impressionLine = getImpressionLine(new Date(currentDateRange[0]), impressionLineData);
  const impressionBidderLine = getImpressionBidderLine(new Date(currentDateRange[0]), bidderImpression);
  const impressionBar = getImpressionBar(impressionBarList, impressionBarData);
  const dispatch = useDispatch()

  // read domain id from url.
  // let currentDomain;

  const [showSettingsInfo, setShowSettingsInfo] = useState(false);
  const [cId, setCId] = useState(false);
  const sidebar = useRef();


  useEffect(() => {
    setCId(currentCustomer)
    // reading the correct date, due to timezone issue
    let startDate = new Date(currentDateRange[0]);
    let endDate = new Date(currentDateRange[1]);

    var tempDate = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    startDate = new Date(tempDate);

    let startMonth = startDate.getUTCMonth() + 1;
    if (startMonth < 10)
      startMonth = '0' + startMonth;

    let startDay = startDate.getUTCDate();
    if (startDay < 10)
      startDay = '0' + startDay;

    tempDate = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate = new Date(tempDate);

    let endMonth = endDate.getUTCMonth() + 1;

    if (endMonth < 10)
      endMonth = '0' + endMonth;

    let endDay = endDate.getUTCDate();
    if (endDay < 10)
      endDay = '0' + endDay;

    startDate = startDate.getUTCFullYear() + '-' + startMonth + '-' + startDay;
    endDate = endDate.getUTCFullYear() + '-' + endMonth + '-' + endDay;

    fetch(API_URL + "/getRevenueBar/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setRevenueList(Object.keys(response));
        setRevenueData(Object.values(response));
      })
      .catch(err => {
        console.log(err);
      });

    fetch(API_URL + "/getCpmBar/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setBidderList(Object.keys(response));
        setBidderCpm(Object.values(response));
      })
      .catch(err => {
        console.log(err);
      });

    fetch(API_URL + "/getRevenueSummary/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setRevenueSummary(response);
      })
      .catch(err => {
        console.log(err);
      });



    fetch(API_URL + "/getTimeoutBar/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setTimeoutList(Object.keys(response));
        setTimeoutBarData(Object.values(response));
      })
      .catch(err => {
        console.log(err);
      });




    fetch(API_URL + "/getParticipationBar/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setParticipationList(Object.keys(response));
        setParticipationBarData(Object.values(response));
      })
      .catch(err => {
        console.log(err);
      });



    fetch(API_URL + "/getImpressionTotal/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {


        let adapters = Object.keys(response.lineChart);
        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response.lineChart[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1);
          series.push(data);
        }

        setImpressionLineData(series);
        setTotalImpressionCount(response.totalCount)
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getBidderImpression/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {


        let adapters = Object.keys(response.lineChart);
        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response.lineChart[adapters[i]];
          series.push(data);
        }

        setBidderImpression(series);
        setImpressionBarList(Object.keys(response.barChart));
        setImpressionBarData(Object.values(response.barChart));
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getCpmLine/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",
    })
      .then(response => response.json())
      .then(response => {


        let adapters = Object.keys(response);
        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }

        setCpmLineData(series);
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getRevenueLine/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {


        let adapters = Object.keys(response);
        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }

        setRevenueChartData(series);
      })
      .catch(err => {
        console.log(err);
      });

    fetch(API_URL + "/getTotalRevenueLine/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {

        let axisOne = response.axisOne
        let adapters = Object.keys(axisOne);
        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['yAxis'] = 0;
          data['name'] = adapters[i];
          data['data'] = axisOne[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }

        let axisTwo = response.axisTwo;
        adapters = Object.keys(axisTwo);
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['yAxis'] = 1;
          data['name'] = adapters[i];
          data['data'] = axisTwo[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }


        setTotalRevenueChartData(series);
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getApiResponseCount/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {

        let adapters = Object.keys(response);

        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }
        setApiResponseData(series);
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getBidderDetails/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {
        setBidderDetails(response);
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getPerformanceChart/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {

        let adapters = Object.keys(response.axisOne);

        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = []; let data2 = [];

          let tmp = [];

          data['yAxis'] = 0;
          data['type'] = 'spline'
          data['name'] = 'Bid Won Rate';
          data['data'] = response.axisOne[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)

          tmp.push(data);

          data2['yAxis'] = 1;
          data2['type'] = 'spline'
          data2['name'] = 'Estimated Revenue';
          data2['data'] = response.axisTwo[adapters[i]];
          if (data2['data'])
            data2['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)

          tmp.push(data2);

          series.push(tmp);
        }

        setBidderLineChart(series);
      })
      .catch(err => {
        console.log(err);
      });


    fetch(API_URL + "/getTimeoutLine/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {

        let adapters = Object.keys(response);

        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1)
          series.push(data);
        }
        setTimeoutData(series);
      })
      .catch(err => {
        console.log(err);
      });

    fetch(API_URL + "/getParticipationLine/" + currentDomain + "/?start=" + startDate + "&end=" + endDate, {
      "method": "GET",

    })
      .then(response => response.json())
      .then(response => {

        let adapters = Object.keys(response);

        let series = [];
        for (var i = 0; i < adapters.length; i++) {
          let data = [];
          data['name'] = adapters[i];
          data['data'] = response[adapters[i]];
          if (data['data'])
            data['data'].sort((a, b) => (a.x > b.x) ? 1 : -1);
          series.push(data);
        }
        setParticipationData(series);
      })
      .catch(err => {
        console.log(err);
      });
  }, [currentDateRange, bidderIndex, currentDomain, currentCustomer]);

  return (
    <div className="center w100">

      <div className={wrapperClass} >
        {cId !== false && (
          <AppTopbar {...props} domain_name={true} />
        )}


        <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={true} unmountOnExit>
          <div ref={sidebar} className={sidebarClassName} >
            <AppLogo />
            <DomainsDropdown />
            {cId !== false && (
              <AppMenu {...props} model={'domain'} />
            )}

          </div>
        </CSSTransition>

        <div className="layout-main">

          <div className="p-d-flex p-jc-end">
            <div className="datePicker-wrapper"><DateRangePicker id="datePicker" onChange={onChange} value={currentDateRange ? currentDateRange : ""} /></div>
          </div>


          <div className="tabview-demo">

            <div className="card">


              <TabView>
                <TabPanel header="Network">






                  <br /><br /><br /><br />
                  <div className="p-grid p-align-center">

                    <div className="p-col-12 p-md-4  p-align-center">
                      <center><h1>${revenueSummary.estimated_revenue}</h1>
                        <h3>Estimated Revenue</h3></center>
                    </div>

                    <div className="p-col-12 p-md-4 p-align-center">
                      <center><h1>{(totalImpressionCount).toLocaleString()}</h1>
                        <h3>Estimated Impressions</h3></center>
                    </div>

                    <div className="p-col-12 p-md-4 p-align-center">
                      <center> <h1>${revenueSummary.delivered_cpm}</h1>
                        <h3>Average Delivered CPM</h3></center>
                    </div>

                  </div>
                  <br /><br /><br /><br />
                  <div className="p-grid">
                    <div className="p-col-12 p-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={totalRevenueLineChart}
                      />
                    </div>

                  </div>
                  <br /><br /><br /><br />

                  <div className="p-grid">
                    <div className="p-col-12 p-md-8">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={revenueLineChart}
                      />
                    </div>
                    <div className="p-col-12 p-md-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={revenueBarChart}
                      />
                    </div>

                  </div>

                  <br /><br /><br /><br />


                  <div className="p-grid">

                    <div className="p-col-12 p-md-8">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={cpmLineConfig}
                      />
                    </div>
                    <div className="p-col-12 p-md-4">

                      <HighchartsReact
                        highcharts={Highcharts}
                        options={cpmBarConfig}
                      />
                    </div>

                  </div>

                  <br /><br /><br /><br />

                  <div className="p-grid">
                    <div className="p-col-12 p-md-8">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={impressionBidderLine}
                      />
                    </div>

                    <div className="p-col-12 p-md-4">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={impressionBar}
                      />
                    </div>
                  </div>



                </TabPanel>
                <TabPanel header="Bidder">

                  <TabView activeIndex={bidderIndex} onTabChange={(e) => setBidderIndex(e.index)} >

                    {Object.keys(bidderDetails).map((index) =>
                    (
                      <TabPanel key={index} header={index}>
                        <div className="p-grid">
                          <div className="p-col-12 p-md-4">

                            <div class="p-datatable p-component" data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body">
                              <div class="p-datatable-wrapper">
                                <table role="grid">
                                  <thead class="p-datatable-thead">
                                    <tr><th class=""><span class="p-column-title">Bids</span></th>
                                      <th class=""><span class="p-column-title"></span></th></tr>
                                  </thead>
                                  <tbody class="p-datatable-tbody">
                                    <tr><td>Participation Rate</td><td>{bidderDetails[index].participation_rate}%</td></tr>
                                  </tbody>

                                </table>
                              </div>
                            </div>

                            <div class="p-datatable p-component" data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body">
                              <div class="p-datatable-wrapper">
                                <table role="grid">
                                  <thead class="p-datatable-thead">
                                    <tr><th class=""><span class="p-column-title">Wins</span></th>
                                      <th class=""><span class="p-column-title"></span></th></tr>
                                  </thead>
                                  <tbody class="p-datatable-tbody">
                                    <tr><td>Win Rate</td><td>{bidderDetails[index].win_rate}%</td></tr>
                                    <tr><td>Estimated Revenue</td><td>${bidderDetails[index].estimated_revenue}</td></tr>
                                    <tr><td>Avg Delivered CPM</td><td>${bidderDetails[index].avg_won_cpm}</td></tr>
                                  </tbody>

                                </table>
                              </div>
                            </div>


                            <div class="p-datatable p-component" data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-scrollable-body">
                              <div class="p-datatable-wrapper">
                                <table role="grid">
                                  <thead class="p-datatable-thead">
                                    <tr><th class=""><span class="p-column-title">Diagnostics</span></th>
                                      <th class=""><span class="p-column-title"></span></th></tr>
                                  </thead>
                                  <tbody class="p-datatable-tbody">
                                    <tr><td>No Bid Rate</td><td>{bidderDetails[index].empty_rate}%</td></tr>
                                    <tr><td>Timeout Rate</td><td>{bidderDetails[index].timeout_rate}%</td></tr>
                                  </tbody>

                                </table>
                              </div>
                            </div>

                          </div>

                          <div className="p-col-12 p-md-8">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={bidderDetailsConfig}
                            />
                          </div>
                        </div>
                      </TabPanel>
                    )
                    )}



                  </TabView>



                </TabPanel>

              </TabView>
            </div>


          </div>







        </div>



      </div>


    </div>
  );

}

export default Reports;
