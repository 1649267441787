/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DEVICE_TARGETING_OPTIONS, rowNoticeBlockStyles, rowNoticeStyle } from "./constants"
import { deliveringStatusBadgeStyle, marketplaceSellerHeaderStyle, pendingOrderViewStyle, utilityStyles } from "./styles"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"


export default function MarketplaceSellerAccordionTab({ order, currentCustomer, domainToCustomerMappings }) {
    let {
        name,
        description,
        orderPausedByAgentScript,
        audienceTargeting,
        thirdPartytagCreatives,
        targetDevices = [],
        isDelivering,
        creatives,
        networks
    } = order

    let totalCreatives = (thirdPartytagCreatives?.length || 0) + (creatives?.length || 0);
    let cid = parseInt(currentCustomer)

    let isOrderExpectingReview = networks
        .filter((networkId) => {
            return domainToCustomerMappings[networkId] === cid;
        })
        .some((networkId) => {
            return order.networkStatuses[networkId]?.status === "PENDING";
        });

    let targetedDevices = targetDevices.length
        ? targetDevices?.map((td) => td.name)?.join(", ")
        : DEVICE_TARGETING_OPTIONS.map((sd) => sd.name)?.join(", ")

    return (
        <div>
            <div css={marketplaceSellerHeaderStyle}>
                <span className="mrktplc-order">
                    {name} {description ? " - [" + description + "]" : ""}
                </span>
                {isDelivering && (
                    <span css={deliveringStatusBadgeStyle}>
                        Order status: Delivering
                    </span>
                )}
                {(orderPausedByAgentScript) && (
                    <span css={css`
                        ${rowNoticeStyle}
                        ${rowNoticeBlockStyles}
                    `}>
                        <FontAwesomeIcon icon={faExclamationCircle} fontSize={"0.9em"} />
                        &nbsp;
                        Order reached its daily cap
                    </span>
                )}
                {(audienceTargeting?.length) && (
                    <span css={css`
                        ${rowNoticeStyle}
                        ${rowNoticeBlockStyles}
                        background-color: #689f38;
                    `}>
                        <FontAwesomeIcon icon={faExclamationCircle} fontSize={"0.9em"} />
                        &nbsp;
                        This order uses advanced targeting
                    </span>
                )}
                {isOrderExpectingReview && (
                    <div css={pendingOrderViewStyle}>
                        <FontAwesomeIcon icon={faExclamationCircle} fontSize={"1.5em"} />
                        &nbsp;This order has domains pending your review
                    </div>
                )}
            </div>
            <div css={utilityStyles}>
                <p className="item">
                    <span className="bold">Total Associated Creatives: </span>
                    {totalCreatives}
                </p>
                <p className="item">
                    <span className="bold">Devices Included: </span>
                    {targetedDevices}
                </p>
            </div>
        </div>
    )
}