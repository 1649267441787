import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { registerAmazonAdapterChanges } from '../../reducers/adapterMutationReducer';

const ADP_amazon = (props) => {
  const [publisherID, setPublisherID] = useState(null);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const messages = useRef(null);
  const dispatch = useDispatch()

  useEffect(() => {
    setPublisherID(props.publisherID);
    setShowCheckBox(props.enableA9);
  }, []);

  useEffect(() => {
    dispatch(registerAmazonAdapterChanges(publisherID, showCheckBox))
  }, [publisherID, showCheckBox])

  return (
    <div >
      <Messages ref={messages} />
      <Accordion className="adapter-accordion">
        <AccordionTab
          headerClassName="adapter-accordion-tab"
          contentClassName="adapter-accordion-body"
          header={<React.Fragment>
            <div className="p-d-flex p-jc-between">
              <div className="p-d-flex p-ai-center">
                <InputSwitch checked={showCheckBox} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowCheckBox(!showCheckBox)
                }} /> <span className="p-ml-2 p-text-uppercase"> Amazon </span>
              </div>

            </div>
          </React.Fragment>}>
          <div>
            <div className="p-d-inline-block p-mr-4 p-mb-3">
              <label className="p-mr-2" for="publisherID"><strong>Publisher ID</strong></label>
              <InputText id="publisherID" name="publisherID" value={publisherID} onChange={(e) => {
                setPublisherID(e.target.value)
              }} />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>

  );
}

export default ADP_amazon;
