import React from 'react'
import { InputText } from 'primereact/inputtext'

const AdpositionInput = ({
  bgColorClass,
  label,
  name,
  value,
  showCheckBox,
  handleChange,
  idx
}) => {
  return (
    <div className="param-input adpos">
      <InputText name={name} value={value} disabled={!showCheckBox} onChange={e => handleChange(e, idx)} />
    </div>
  )
  /* return (
    <div className={`p-col-4 ${bgColorClass}`}>
      <div className="p-col-12">
        <div className="p-mb-2 small-show p-text-nowrap p-text-truncate"
          data-pr-tooltip="Ex. 1024x800, 1440x1024">
          <strong>{label}</strong>
          <InputText name={name} value={value} disabled={!showCheckBox} onChange={e => handleChange(e, idx)} />
        </div>
      </div>
    </div>
  ) */
}

export default AdpositionInput
