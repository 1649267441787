/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { useMutation } from "@apollo/client";
import { css } from "@emotion/react";
import deepcopy from "deepcopy";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pushMessage } from "../reducers/messagingReducer";
import LayoutWrapper from "./LayoutWrapper";
import { AUDIENCE_TARGETING_API_UPDATE, MULTI_FIELD_READ_MUTATION, MULTI_FIELD_UPDATE_MUTATION } from "./adapters/utils/constants";
import Cta from "./common/marketplace/CTA";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

import { AUDIENCE_TARGETING_KEYS, MAIN_AUDIENCE_KEY_GROUP, marketplaceTargetingDemographics, marketplaceTargetingEducationLevel, marketplaceTargetingIncomeLevel, marketplaceTargetingPolitical, marketplaceTargetingReligion } from "./common/marketplace/constants";

const PricingSection = ({ onChange, name, label, value }) => {
    return (
        <div className="pricing-input-section">
            <span className="label">{label || "Pricing"}</span>
            <InputNumber
                currency="USD"
                local="en-US"
                currencyDisplay="symbol"
                mode="currency"
                value={value}
                name={name}
                onChange={ev => {
                    onChange(name, ev.value)
                }}
            />
        </div>
    )
}

export default function CensusTargeting() {
    const [fieldsUpdateMutation] = useMutation(MULTI_FIELD_UPDATE_MUTATION)
    const [fieldsRetrieveMutation] = useMutation(MULTI_FIELD_READ_MUTATION)
    const [audienceTargetingApiUpdateMutation] = useMutation(AUDIENCE_TARGETING_API_UPDATE)
    const [isSaving, setSavingState] = useState(false)
    const dispatch = useDispatch();
    const [targetingSettings, setTargetingSettings] = useState({})
    const [targetingPricing, setTargetingPricing] = useState({})

    useEffect(() => {
        let fields = [
            { field_name: 'audience_targeting_settings', post_id: 'options', parse: true },
            ...AUDIENCE_TARGETING_KEYS.map(key => ({ field_name: MAIN_AUDIENCE_KEY_GROUP + "_" + key, post_id: 'options', parse: true}))
        ]
        fieldsRetrieveMutation({
            variables: {
                fieldDefinitions: JSON.stringify(fields)
            }
        }).then(response => {
            let data = response.data
            let json = JSON.parse(data.multiFieldRetrieveMutation.response)
            let {audience_targeting_settings = {}, ...rest} = json

            setTargetingSettings(audience_targeting_settings || {})
            setTargetingPricing(
                AUDIENCE_TARGETING_KEYS.reduce((acc, curr) => {
                    acc[curr] = rest[MAIN_AUDIENCE_KEY_GROUP + "_" + curr]
                    return acc
                }, {})
            )
        }).catch(err => {
            console.log({ err })
        })
    }, [])

    function handleMetricPricingChange(metric, value) {
        setTargetingPricing({ ...targetingPricing, [metric]: value })
    }

    function handleScoreChange(metric, machineKey, scoreKey, scoreValue) {
        let targetingSettingsClone = deepcopy(targetingSettings)
        targetingSettingsClone[metric] = targetingSettingsClone[metric] || {}
        let targetingMetric = targetingSettingsClone[metric]
        targetingMetric[machineKey] = targetingMetric[machineKey] || {}
        targetingMetric[machineKey][scoreKey] = scoreValue
        setTargetingSettings(targetingSettingsClone)
    }

    const scoreInputBodyTemplate = (metric, key) => (rowData) => {
        return (
            <InputNumber
                value={targetingSettings?.[metric]?.[rowData.machineName]?.[key] || ''}
                onChange={ev => {
                    handleScoreChange(metric, rowData.machineName, key, ev.value)
                }}
            />
        )
    }

    const dbl_scoreInputBodyTemplate = (metric, key) => (rowData) => {
        return (
            <>
                <b>min</b> <InputNumber
                    value={targetingSettings?.[metric]?.[rowData.machineName]?.[key] || ''}
                    onChange={ev => {
                        handleScoreChange(metric, rowData.machineName, key, ev.value)
                    }}
                />

                <div className="mt-1"/>

                <b>max</b> <InputText
                    value={targetingSettings?.[metric + '_max']?.[rowData.machineName+ '_max']?.['mx_'+key] || ''}
                    onChange={ev => {
                        handleScoreChange(metric + '_max', rowData.machineName+ '_max', 'mx_'+key, ev.target.value)
                    }}
                />
            </>
        )
    }

    async function saveTargetingSettings(targetingSettings) {
        let fields = []

        if (AUDIENCE_TARGETING_KEYS.every(key => key in targetingPricing && targetingPricing[key] !== null)) {
            fields.push({ post_id: 'options', field_name: 'audience_targeting_settings', value: JSON.stringify(targetingSettings) })

            AUDIENCE_TARGETING_KEYS.forEach(key => {
                fields.push({
                    post_id: 'options',
                    field_name: MAIN_AUDIENCE_KEY_GROUP + "_" + key,
                    value: targetingPricing[key]
                })
            })

            await fieldsUpdateMutation({
                variables: {
                    rules: JSON.stringify(fields)
                }
            })
        } else {
            throw new Error("Pricing must be set for each audience setting section")
        }
    }

    async function registerTargetingSettings(targetingSettings) {
        //delete targetingSettings?.adgt_inc_max;
        let { adgt_inc, ...restProps } = targetingSettings

        let res = {
            adgt_inc: adgt_inc.median_household_income
        }

        //delete res.adgt_inc?.median_household_income?.mx_vh;
        //delete res.adgt_inc?.median_household_income?.mx_h;
        //delete res.adgt_inc?.median_household_income?.mx_m;
        //delete res.adgt_inc?.median_household_income?.mx_l;


        for (let [k, v] of Object.entries(restProps)) {
            res[k] = Object
                .keys(v)
                .reduce((acc, curr) => {
                    acc[curr] = v[curr].score
                    return acc
                }, {})
        }
        let newRes = {...res}

        delete newRes.adgt_inc_max;

        await audienceTargetingApiUpdateMutation({
            variables: {
                audienceData: JSON.stringify(newRes)
            }
        })
    }

    async function saveCallback() {
        try {
            await saveTargetingSettings(targetingSettings)
            await registerTargetingSettings(targetingSettings)
            dispatch(pushMessage('Census targeting', 'Census targeting settings saved', 'success'))
            dispatch(pushMessage('Census targeting', 'Aggregation API update requested', 'success'))
        } catch (err) {
            dispatch(pushMessage('Census targeting', err.message, 'error'))
        }
    }

    return (
        <>
            <div css={css`
                    .p-datatable-header {
                        background-color: #dee2e6 !important;
                    }
                    .pricing-input-section {
                        display: flex;
                        align-items: center;
                        .label {
                            display: block;
                            min-width: 100px;
                            margin-right: 1em;
                        }
                    }
                `}
            >
                <LayoutWrapper renderOpsBar={false} model="domain">
                    {
                        [
                            { name: "Ethnicity", key: "adgt_eth", dataPoints: marketplaceTargetingDemographics },
                            { name: "Education", key: "adgt_edu", dataPoints: marketplaceTargetingEducationLevel },
                            { name: "Political", key: "adgt_ppol", dataPoints: marketplaceTargetingPolitical },
                            { name: "Religion", key: "adgt_rlg", dataPoints: marketplaceTargetingReligion }
                        ].map(item => {
                            let { name: header, key, dataPoints } = item
                            return (
                                <DataTable
                                    className="mt-1"
                                    value={dataPoints}
                                    key={key}
                                    header={<PricingSection label="CPM" name={key} onChange={handleMetricPricingChange} value={targetingPricing[key] || ''} />}
                                >
                                    <Column field="name" header={header}></Column>
                                    <Column field="machineName" header="Machine Name"></Column>
                                    <Column header="Score" body={scoreInputBodyTemplate(key, "score")}></Column>
                                </DataTable>
                            )
                        })
                    }
                    <DataTable
                        value={marketplaceTargetingIncomeLevel}
                        className="mt-1"
                        header={<PricingSection label="CPM" name={"adgt_inc"} onChange={handleMetricPricingChange} value={targetingPricing['adgt_inc'] || ''} />}
                    >
                        <Column field="name" header="Income"></Column>
                        <Column field="machineName" header="Machine Name"></Column>
                        <Column header="Very high" body={dbl_scoreInputBodyTemplate("adgt_inc", 'vh')}></Column>
                        <Column header="High" body={dbl_scoreInputBodyTemplate("adgt_inc", 'h')}></Column>
                        <Column header="Medium" body={dbl_scoreInputBodyTemplate("adgt_inc", 'm')}></Column>
                        <Column header="Low" body={dbl_scoreInputBodyTemplate("adgt_inc", 'l')}></Column>
                        <Column header="Very Low" body={dbl_scoreInputBodyTemplate("adgt_inc", 'vl')}></Column>
                    </DataTable>


                </LayoutWrapper>
            </div>

            <div className="ops-bar" css={css`
                position: fixed;
                left: 250px;
                right: 0;
                bottom: 0;
                z-index: 99;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                background-color: #f4f4f4;
                border: 1px solid #dee2e6;
                border-radius: 6px;
                padding: 0 2em;
                button {
                    margin: 0 1em;
                }
            `}>
                <Cta
                    ctaText="Save"
                    themeClasses="cta outlined success"
                    disabled={isSaving}
                    showLoader={isSaving}
                    onClick={async () => {
                        setSavingState(true)
                        await saveCallback()
                        setTimeout(() => {
                            setSavingState(false)
                        }, 500)
                    }}
                />
            </div>
        </>
    );
}
