import { gql, useMutation } from '@apollo/client';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromBuiltDomains } from '../reducers/buildReducer';
import { pushMessage } from '../reducers/messagingReducer';
import { getIn, noop } from '../utilities/utils';
import LayoutWrapper from './LayoutWrapper';
import SectionHeader from './helper/SectionHeader';

const DOMAIN_EDIT_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    domainEditMutation(input: {id: $id, cid: $cid}) {
		customerInfo
    }
  }
`
const VIDEO_SETTINGS_INFO_MUTATION = gql`
  mutation callVideoSettingsInfoMutation($id: String, $cid: String) {
    videoSettingsInfoMutation(input: {id: $id, cid: $cid}) {
		customerInfo
    }
  }
`
const VIDEO_SETTINGS_MUTATION = gql`
  mutation callVideoSettingsMutation($id: String, $settingsData: String) {
    videoSettingsMutation(input: {id: $id, settingsData: $settingsData}) {
        networkId
    }
  }
`

const EditableSection = ({
	children,
	switchLabel,
	onSwitch,
	enabled
}) => {
	return (
		<div className={`editable-section ${enabled ? "enabled" : "disabled"}`}>
			<div className="p-d-flex p-ai-center f-width switch">
				<InputSwitch
					checked={enabled}
					onChange={() => {
						onSwitch(!enabled)
					}}
				/>
				<label
					className="p-checkbox-label p-pl-2 p-text-bold"
					onClick={() => { onSwitch(!enabled) }}>
					{switchLabel}
				</label>
			</div>
			<div className={`editable-content ${enabled ? "enabled" : "disabled"}`}>
				{!enabled && <div className="overlay"></div>}
				{children}
			</div>
		</div>
	)
}

const Input = ({ name, value, onChange, placeholder, className }) => (
	<InputText
		id={name}
		name={name}
		value={value}
		className={className || ''}
		placeholder={placeholder || ''}
		onChange={(ev) => onChange(ev)}
	/>
);

const RadioBtn = ({ name, label, checked, onChange }) => (
	<span className="radio-input-container">
		<RadioButton
			name={name}
			id={name}
			checked={checked}
			onChange={ev => onChange(ev)}
			className="radio"
		/>
		<label htmlFor={name}>{label}</label>
	</span>
)

const InputRow = ({ name, label, onChange, value }) => {
	return (
		<div className="input-row">
			<label htmlFor={name}>{label}</label>
			<Input name={name} value={value} onChange={onChange} />
		</div>
	)
}

const INITIAL_CONFIG_STATE = {
	'enable-preroll': false,
	'enable-midroll': false,
	'enable-postroll': false,
	'preroll-ad-tag-from-player': true,
	'preroll-ad-tag-custom': false,
	'midroll-ad-tag-from-player': true,
	'midroll-ad-tag-custom': false,
	'midroll-total-error-retries': '',
	'midroll-chapter-breaks': true,
	'midroll-defined-intervals': false,
	'postroll-ad-tag-from-player': true,
	'postroll-ad-tag-custom': false,
	'preroll-custom-ad-tag': '',
	'preroll-total-ad-calls': '',
	'preroll-total-error-retries': '',
	'midroll-custom-ad-tag': '',
	'midroll-total-ad-calls': '',
	'midroll-min-time': '',
	'midroll-intervals': '',
	'postroll-custom-ad-tag': '',
	'postroll-total-ad-calls': '',
	'postroll-total-error-retries': '',
}

let IS_CONFIG_OVERRIDEN = false

const VideoAdSettings = (props) => {
	const { currentDomain, currentCustomer, currentPreset } = useSelector(state => state.userInformation)
	const [showCheckBox, setShowCheckBox] = useState(IS_CONFIG_OVERRIDEN)
	const [state, setState] = useState(INITIAL_CONFIG_STATE)
	const [presetVideoConfig, setPresetVideoConfig] = useState({})
	const [domainVideoConfig, setDomainVideoConfig] = useState({})
	const [isMutationFinished, setIsMutationFinished] = useState(false)
	const [domainVideoSettingsMutation] = useMutation(DOMAIN_EDIT_MUTATION)
	const [presetVideoSettingsMutation] = useMutation(VIDEO_SETTINGS_MUTATION)
	const [presetVideoSettingsInfoMutation] = useMutation(VIDEO_SETTINGS_INFO_MUTATION)
	const dispatch = useDispatch()
	const toSnakeCase = (str) => str.split('-').join('_')

	const handleRadioChange = ev => {
		const { target: { name } } = ev

		switch (name) {
			case 'preroll-ad-tag-from-player':
				return setState({ ...state, [name]: true, 'preroll-ad-tag-custom': false })
			case 'preroll-ad-tag-custom':
				return setState({ ...state, [name]: true, 'preroll-ad-tag-from-player': false })
			case 'midroll-ad-tag-from-player':
				return setState({ ...state, [name]: true, 'midroll-ad-tag-custom': false })
			case 'midroll-ad-tag-custom':
				return setState({ ...state, [name]: true, 'midroll-ad-tag-from-player': false })
			case 'midroll-chapter-breaks':
				return setState({ ...state, [name]: true, 'midroll-defined-intervals': false })
			case 'midroll-defined-intervals':
				return setState({ ...state, [name]: true, 'midroll-chapter-breaks': false })
			case 'postroll-ad-tag-from-player':
				return setState({ ...state, [name]: true, 'postroll-ad-tag-custom': false })
			case 'postroll-ad-tag-custom':
				return setState({ ...state, [name]: true, 'postroll-ad-tag-from-player': false })
			default:
				noop()
		}
	}

	const handleInputChange = ev => {
		setState({ ...state, [ev.target.name]: ev.target.value })
	}

	useEffect(() => {
		if (isMutationFinished) {
			showCheckBox
				? populateSettings(domainVideoConfig)
				: populateSettings(presetVideoConfig)
			return
		}
	}, [domainVideoConfig, presetVideoConfig, isMutationFinished, showCheckBox])

	useEffect(() => {
		// if (!currentCustomer) return history.push('/domains')

		let mutationContainer = props.overridable
			? domainVideoSettingsMutation
			: presetVideoSettingsInfoMutation

		let mutationVariables = props.overridable
			? {id: `${currentDomain}`, cid: `${currentCustomer}`}
			: {id: `${currentPreset}`, cid: `${currentCustomer}`}

		mutationContainer({
			variables: mutationVariables
		}).then(response => {

			let customerInfo = props.overridable
				? getIn(response, 'data', 'domainEditMutation', 'customerInfo')
				: getIn(response, 'data', 'videoSettingsInfoMutation', 'customerInfo')

			customerInfo = JSON.parse(customerInfo)

			let {
				video_config: {
					preroll,
					midroll,
					postroll,
					presetOverriden
				},
				override_video_config
			} = customerInfo


			if (props.overridable) {
				!!presetOverriden
					? setShowCheckBox(true)
					: setShowCheckBox(false)

				props.registerCurrentPreset(customerInfo.adv_preset.code)

				setDomainVideoConfig({
					preroll,
					midroll,
					postroll,
					presetOverriden
				})

				setPresetVideoConfig({
					preroll: override_video_config.preroll,
					midroll: override_video_config.midroll,
					postroll: override_video_config.postroll
				})
			} else {
				setPresetVideoConfig({
					preroll,
					midroll,
					postroll
				})
			}

			setIsMutationFinished(true)

		}).catch(() => {
			dispatch(pushMessage('Video Ad Settings', 'There was an error while trying to gather video ad configurations', 'error'))
		})
	}, [currentDomain, currentCustomer, currentPreset]);

	function populateSettings ({preroll, midroll, postroll}) {
		if (Object.keys(preroll).some(k => preroll[k] === null)) {
			setState(INITIAL_CONFIG_STATE)
			return
		}

		const prerollEnabled = preroll.prerollEnabled && preroll.prerollEnabled !== '0'
		const midrollEnabled = midroll.midrollEnabled && midroll.midrollEnabled !== '0'
		const postrollEnabled = postroll.postrollEnabled && postroll.postrollEnabled !== '0'
		const prerollFromPlayer = preroll.prerollAdTag === 'player'
		const midrollFromPlayer = midroll.midrollAdTag === 'player'
		const postrollFromPlayer = postroll.postrollAdTag === 'player'
		const midrollChapterBreaks = midroll.midrollAdBreaks === 'chapter_break'

		setState({
			'enable-preroll': prerollEnabled,
			'enable-midroll': midrollEnabled,
			'enable-postroll': postrollEnabled,
			'preroll-ad-tag-from-player': prerollFromPlayer,
			'preroll-ad-tag-custom': !prerollFromPlayer,
			'midroll-ad-tag-from-player': midrollFromPlayer,
			'midroll-ad-tag-custom': !midrollFromPlayer,
			'postroll-ad-tag-from-player': postrollFromPlayer,
			'postroll-ad-tag-custom': !postrollFromPlayer,
			'midroll-chapter-breaks': midrollChapterBreaks,
			'midroll-defined-intervals': !midrollChapterBreaks,
			'preroll-custom-ad-tag': preroll.prerollCustomAdTag,
			'preroll-total-ad-calls': preroll.prerollNumAdCalls,
			'preroll-total-error-retries': preroll.prerollNumRetries,
			'midroll-custom-ad-tag': midroll.midrollCustomAdTag,
			'midroll-total-ad-calls': midroll.midrollNumAdCalls,
			'midroll-total-error-retries': midroll.midrollNumRetries,
			'midroll-min-time': midroll.midrollMinInterval,
			'midroll-intervals': midroll.midrollIntervals,
			'postroll-custom-ad-tag': postroll.postrollCustomAdTag,
			'postroll-total-ad-calls': postroll.postrollNumAdCalls,
			'postroll-total-error-retries': postroll.postrollNumRetries,
		})
	}

	function handleSave (state) {
		if (props.saveSettingsCallback) {
			setDomainVideoConfig({
				preroll: {
					prerollEnabled: state['enable-preroll'],
					prerollCustomAdTag: state['preroll-custom-ad-tag'],
					prerollAdTag: state['preroll-ad-tag-custom'] ? 'custom' : 'player',
					prerollNumAdCalls: state['preroll-total-ad-calls'],
					prerollNumRetries: state['preroll-total-error-retries'],
				},
				midroll: {
					midrollEnabled: state['enable-midroll'],
					midrollCustomAdTag: state['midroll-custom-ad-tag'],
					midrollAdTag: state['midroll-ad-tag-custom'] ? 'custom' : 'player',
					midrollNumAdCalls: state['midroll-total-ad-calls'],
					midrollNumRetries: state['midroll-total-error-retries'],
					midrollAdBreaks: state['midroll-chapter-breaks'] ? 'chapter_break' : 'defined_intervals',
					midrollMinInterval: state['midroll-min-time'],
					midrollIntervals: state['midroll-intervals']
				},
				postroll: {
					postrollEnabled: state['enable-postroll'],
					postrollCustomAdTag: state['postroll-custom-ad-tag'],
					postrollAdTag: state['postroll-ad-tag-custom'] ? 'custom' : 'player',
					postrollNumAdCalls: state['postroll-total-ad-calls'],
					postrollNumRetries: state['postroll-total-error-retries']
				}
			})
			return props.saveSettingsCallback({...state, isPresetOverriden: showCheckBox})
				.then(() => {
					dispatch(pushMessage('Video Ad Settings', 'Video settings updated correctly'))
					removeFromBuiltDomains(`${currentDomain}`)
				})
				.catch(() => {
					dispatch(pushMessage('Video Ad Settings', 'There was an error while trying to update the video settings', 'error'))
					throw new Error("There was an error while trying to update the video settings")
				})
		} else {
			// handle case when saving value for preset configuration
			const config = Object.keys(state).reduce((acc, k) => {
				acc[toSnakeCase(k)] = state[k]
				return acc
			}, {})
			return presetVideoSettingsMutation({
				variables: {
					id: `${currentPreset}`,
					settingsData: JSON.stringify(config)
				}
			}).then(() => {
				dispatch(pushMessage('Video Ad Settings', 'Video settings were set for this preset'))
			}).catch(() => {
				dispatch(pushMessage('Video Ad Settings', 'There was an error while trying to set your preset video ad settings. Try again, please', 'error'))
				throw new Error("There was an error while trying to set your preset video ad settings")
			})
		}
	}

	return (
		<LayoutWrapper model="domain" saveCallback={async () => handleSave(state)}>
			<div className="" id="video-ad-settings">
					<div className={`p-panel p-component ${!showCheckBox && props.overridable ? "disabled-content" : "enabled-content"}`}>
						<SectionHeader screenName="Video Ad Settings">
							<div className="p-d-flex p-ai-center f-width">
								{
									props.overridable
										? <>
											<InputSwitch checked={showCheckBox} onChange={() => { setShowCheckBox(!showCheckBox) }} />
											<label htmlFor="cb1" className="p-checkbox-label p-pl-2 p-text-bold" onClick={() => { setShowCheckBox(!showCheckBox) }}>Override Video Ad Settings</label>
										</>
										: <p><strong>Preset Video Ad Settings</strong></p>
								}
							</div>
						</SectionHeader>
						<div className="disable-item">
							<EditableSection
								switchLabel="Preroll"
								enabled={state['enable-preroll']}
								onSwitch={v => {
									setState({...state, 'enable-preroll': v})
								}}
							>
								<div className="input-row multiple">
									<label>Ad Tag</label>
									<div className="inputs">
										<RadioBtn
											name="preroll-ad-tag-from-player"
											label="From Player"
											checked={state['preroll-ad-tag-from-player']}
											onChange={handleRadioChange}
										/>
										<RadioBtn
											name="preroll-ad-tag-custom"
											label="Custom"
											checked={state['preroll-ad-tag-custom']}
											onChange={handleRadioChange}
										/>
										{
											state['preroll-ad-tag-custom'] &&
											<div>
												<Input
													name="preroll-custom-ad-tag"
													value={state['preroll-custom-ad-tag']}
													className="custom-ad-tag"
													placeholder="Enter a custom ad tag"
													onChange={ev => handleInputChange(ev)}
												/>
											</div>
										}
									</div>
								</div>
								<InputRow
									label="Number of Ad Calls"
									name="preroll-total-ad-calls"
									onChange={handleInputChange}
									value={state["preroll-total-ad-calls"]}
								/>
								<InputRow
									label="Number of Retries on Error"
									name="preroll-total-error-retries"
									onChange={handleInputChange}
									value={state["preroll-total-error-retries"]}
								/>
							</EditableSection>
							<EditableSection
								switchLabel="Midroll"
								enabled={state['enable-midroll']}
								onSwitch={v => setState({...state, 'enable-midroll': v})}
							>
								<div className="input-row multiple">
									<label>Ad Tag</label>
									<div className="inputs">
										<RadioBtn
											name="midroll-ad-tag-from-player"
											label="From Player"
											checked={state['midroll-ad-tag-from-player']}
											onChange={handleRadioChange}
										/>
										<RadioBtn
											name="midroll-ad-tag-custom"
											label="Custom"
											checked={state['midroll-ad-tag-custom']}
											onChange={handleRadioChange}
										/>
										{
											state['midroll-ad-tag-custom'] &&
											<div>
												<Input
													name="midroll-custom-ad-tag"
													value={state['midroll-custom-ad-tag']}
													className="custom-ad-tag"
													placeholder="Enter a custom ad tag"
													onChange={ev => handleInputChange(ev)}
												/>
											</div>
										}
									</div>
								</div>
								<InputRow
									label="Number of Ad Calls"
									name="midroll-total-ad-calls"
									onChange={handleInputChange}
									value={state["midroll-total-ad-calls"]}
								/>
								<InputRow
									label="Number of Retries on Error"
									name="midroll-total-error-retries"
									onChange={handleInputChange}
									value={state["midroll-total-error-retries"]}
								/>
								<div className="input-row multiple">
									<label>Ad Breaks</label>
									<div className="inputs">
										<RadioBtn
											name="midroll-chapter-breaks"
											label="On Chapter Breaks"
											checked={state['midroll-chapter-breaks']}
											onChange={handleRadioChange}
										/>
										<RadioBtn
											name="midroll-defined-intervals"
											label="Defined Intervals"
											checked={state['midroll-defined-intervals']}
											onChange={handleRadioChange}
										/>
										{
											state['midroll-defined-intervals'] &&
											<div>
												<Input
													name="midroll-min-time"
													value={state['midroll-min-time']}
													className="custom-ad-tag"
													placeholder="Minimum Time Between Ads (seconds)"
													onChange={ev => handleInputChange(ev)}
												/>
												<Input
													name="midroll-intervals"
													value={state['midroll-intervals']}
													className="custom-ad-tag"
													placeholder="Intervals (by seconds or percent length of video)"
													onChange={ev => handleInputChange(ev)}
												/>
											</div>
										}
									</div>
								</div>
							</EditableSection>
							<EditableSection
								enabled={state['enable-postroll']}
								switchLabel="Postroll"
								onSwitch={v => setState({...state, 'enable-postroll': v})}
							>
								<div className="input-row multiple">
									<label>Ad Tag</label>
									<div className="inputs">
										<RadioBtn
											name="postroll-ad-tag-from-player"
											label="From Player"
											checked={state['postroll-ad-tag-from-player']}
											onChange={handleRadioChange}
										/>
										<RadioBtn
											name="postroll-ad-tag-custom"
											label="Custom"
											checked={state['postroll-ad-tag-custom']}
											onChange={handleRadioChange}
										/>
										{
											state['postroll-ad-tag-custom'] &&
											<div>
												<Input
													name="postroll-custom-ad-tag"
													value={state['postroll-custom-ad-tag']}
													className="custom-ad-tag"
													placeholder="Enter a custom ad tag"
													onChange={ev => handleInputChange(ev)}
												/>
											</div>
										}
									</div>
								</div>
								<InputRow
									label="Number of Ad Calls"
									name="postroll-total-ad-calls"
									onChange={handleInputChange}
									value={state["postroll-total-ad-calls"]}
								/>
								<InputRow
									label="Number of Retries on Error"
									name="postroll-total-error-retries"
									onChange={handleInputChange}
									value={state["postroll-total-error-retries"]}
								/>
							</EditableSection>
						</div>
					</div>
				</div>
		</LayoutWrapper>
	);

}

export default VideoAdSettings
