const MODIFIED_DOMAINS = 'modifiedDomains'
const MODIFIED_PRESETS = 'modifiedPresets'
const BUILT_DOMAINS = 'builtDomains'

const ACTIONS = {
    'REGISTER_BUILD_LOG': 1,
    'ADD_TO_BUILD_LIST': 2,
    'REMOVE_FROM_BUILD_LIST': 3,
    'ADD_TO_PRESET_BUILD_LIST': 4,
    'REMOVE_FROM_PRESET_BUILD_LIST': 5,
    'BUILD_CURRENT_DOMAIN': 6
}

function getLocallyModifiedItems (k) {
    return new Set(JSON.parse(localStorage.getItem(k)) || [])
}

function getLocallyModifiedDomainsList () {
    return getLocallyModifiedItems(MODIFIED_DOMAINS)
}

function getLocallyModifiedPresetsList() {
    return getLocallyModifiedItems(MODIFIED_PRESETS)
}

function getLocallyBuiltDomains() {
    return getLocallyModifiedItems(BUILT_DOMAINS)
}

function insertToLocallyModifiedItemList (k, itemId) {
    const items = getLocallyModifiedItems(k)
    if (items.has(itemId)) return 
    else {
        localStorage.setItem(k, JSON.stringify([...items, itemId]))
    }
}

function removeFromLocallyModifiedItemList (k, id) {
    const items = getLocallyModifiedItems(k)
    items.delete(id)
    localStorage.setItem(k, JSON.stringify([...items]))
}

function removeFromLocallyModifiedDomainsList(domainId) {
    removeFromLocallyModifiedItemList(MODIFIED_DOMAINS, domainId)
}

function removeFromBuiltDomains(domainId) {
    removeFromLocallyModifiedItemList(BUILT_DOMAINS, domainId)
}

const BUILD_STATE = {
    logs: {},
    modifiedDomains: getLocallyModifiedDomainsList(),
    modifiedPresets: getLocallyModifiedPresetsList(),
    builtDomains: getLocallyBuiltDomains(),
    currentDomainToBuild: null
}

function buildReducer(state = BUILD_STATE, { type, data }) {
    let modifiedDomains = null
    let modifiedPresets = null
    let builtDomains = null
    let logs = null
    switch (type) {
        case ACTIONS.REGISTER_BUILD_LOG:
            logs = { ...state.logs, [data.domainId]: data.messages }
            return { ...state, logs }
        case ACTIONS.ADD_TO_BUILD_LIST:
            modifiedDomains = new Set([...state.modifiedDomains, data.domainId])
            return { ...state, modifiedDomains }
        case ACTIONS.REMOVE_FROM_BUILD_LIST:
            modifiedDomains = new Set([...state.modifiedDomains])
            modifiedDomains.delete(data.domainId)
            builtDomains = new Set([...state.builtDomains])
            builtDomains.add(data.domainId)
            return { ...state, modifiedDomains, builtDomains }
        case ACTIONS.ADD_TO_PRESET_BUILD_LIST:
            modifiedPresets = new Set([...state.modifiedPresets, data.presetId])
            return { ...state, modifiedPresets }
        case ACTIONS.REMOVE_FROM_PRESET_BUILD_LIST:
            modifiedPresets = new Set([...state.modifiedPresets])
            modifiedPresets.delete(data.presetId)
            return { ...state, modifiedPresets }
        case ACTIONS.BUILD_CURRENT_DOMAIN:
            return { ...state, currentDomainToBuild: data.domainId }
        default:
            return state
    }
}

function registerBuildLog(domainId, messages) {
    return {
        type: ACTIONS.REGISTER_BUILD_LOG,
        data: {
            domainId,
            messages
        }
    }
}

function addToPresetBuildList(presetId) {
    insertToLocallyModifiedItemList(MODIFIED_PRESETS, presetId)
    return {
        type: ACTIONS.ADD_TO_PRESET_BUILD_LIST,
        data: { presetId }
    }
}

function addToBuildList(domainId) {
    insertToLocallyModifiedItemList(MODIFIED_DOMAINS, domainId)
    return {
        type: ACTIONS.ADD_TO_BUILD_LIST,
        data: { domainId }
    }
}

function removeFromBuildList(domainId) {
    removeFromLocallyModifiedDomainsList(domainId)
    insertToLocallyModifiedItemList(BUILT_DOMAINS, domainId)
    return {
        type: ACTIONS.REMOVE_FROM_BUILD_LIST,
        data: { domainId }
    }
}

function removeFromPresetBuildList(presetId) {
    return {
        type: ACTIONS.REMOVE_FROM_PRESET_BUILD_LIST,
        data: { presetId }
    }
}

function buildCurrentDomain(domainId) {
    return {
        type: ACTIONS.BUILD_CURRENT_DOMAIN,
        data: { domainId }
    }
}

function clearCurrentDomain() {
    return buildCurrentDomain(null)
}

export {
    registerBuildLog,
    addToBuildList,
    removeFromBuildList,
    addToPresetBuildList,
    removeFromPresetBuildList,
    removeFromBuiltDomains,
    buildCurrentDomain,
    clearCurrentDomain
}

export default buildReducer
