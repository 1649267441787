/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import VideoPlayerPositions from './VideoPlayerPositions'
import { gql, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { getIn } from '../utilities/utils';
import { pushMessage } from '../reducers/messagingReducer';
import { useDomainSettings } from '../hooks/useDomainSettings';

const VIDEO_POSITIONS_UPDATE_MUTATION = gql`
    mutation callUpdateVideoPlayerPositionsMutation($id: String, $positionList: String, $isPreset: Boolean, $isOverridingPreset: Boolean) {
        updateVideoPlayerPositionsMutation(input: {id: $id, positionList: $positionList, isPreset: $isPreset, isOverridingPreset: $isOverridingPreset}) {
            response
        }
    }
`

const DomainVideoPlayerPositions = () => {
    const dispatch = useDispatch()
    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings()
    const [videoPositionUpdateMutationContainer] = useMutation(VIDEO_POSITIONS_UPDATE_MUTATION)
    const [isOverridingPreset, setIsOverridingPreset] = useState(false)
    const { currentDomain, currentCustomer } = useSelector(state => state.userInformation)
    const [presetVideoPlayerPositions, setPresetVideoPlayerPositions] = useState([])
    const [domainVideoPlayerPositions, setDomainVideoPlayerPositions] = useState([])
    const [hasMutationCompleted, setMutationStatus] = useState(false)
    const [machineNames, setUsedMachineNames] = useState(new Set())
    const [presetId, setPresetId] = useState('')
    const [presetName, setPresetName] = useState('')

    useEffect(() => {
        if (currentCustomer && currentDomain) {
            DOMAIN_SETTINGS_MUTATION_CONTAINER({
                domainId: currentDomain,
                customerId: currentCustomer
            }).then(response => {
                let domainInfo = JSON.parse(getIn(response, 'data', 'domainEditMutation', 'customerInfo'))
                let presetPositions = domainInfo.preset_video_player_positions || []
                let domainPositions = domainInfo.domain_video_player_positions && domainInfo.domain_video_player_positions.length
                    ? domainInfo.domain_video_player_positions
                    : domainInfo.preset_video_player_positions
                setPresetVideoPlayerPositions(presetPositions)
                setDomainVideoPlayerPositions(domainPositions)
                setIsOverridingPreset(!!domainInfo.override_video_player_positions)
                setUsedMachineNames(new Set(Object.values(domainPositions).map(i => i.displayName)))
                setPresetId(domainInfo?.adv_preset?.code)
                setPresetName(domainInfo?.adv_preset?.name)
                setMutationStatus(true)
            }).catch(err => {
                setMutationStatus(true)
                dispatch(pushMessage('Video player positions', 'Error querying domain information: ' + err.message, 'error'))
            })
        }
    }, [currentCustomer, currentDomain])

    return (
        <VideoPlayerPositions
            mode="domain"
            videoPlayerPositions={isOverridingPreset ? domainVideoPlayerPositions : presetVideoPlayerPositions}
            overridable={true}
            isOverridingPreset={isOverridingPreset}
            setOverridingStatus={status => setIsOverridingPreset(status)}
            setDomainVideoPlayerPositions={setDomainVideoPlayerPositions}
            presetName={presetName}
            usedMachineNames={machineNames}
            presetId={presetId}
            mutationCompleted={hasMutationCompleted}
            saveHandler={() => {
                return videoPositionUpdateMutationContainer({
                    variables: {
                        id: `${currentDomain}`,
                        positionList: JSON.stringify(domainVideoPlayerPositions),
                        isPreset: false,
                        isOverridingPreset
                    }
                }).then(response => {
                    return response
                }).catch(err => {
                    console.log('err: ', err.message)
                })
            }}
        />
    )
}

export default DomainVideoPlayerPositions