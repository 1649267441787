import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputSwitch } from "primereact/inputswitch";

const SWITCH_SECTION_STYLES = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5em;
    padding: 0 6px ;
    .label {
        font-weight: bold;
        flex-basis: 80%;
    }
`;

export default function InputSwitchSection({ label, isChecked, switchName, changeHandler, creativeType, children }) {
    return (
        <>
            <div css={SWITCH_SECTION_STYLES}>
                <span className="label">{label}</span>
                <InputSwitch
                    checked={isChecked}
                    name={switchName}
                    onChange={(e) => {
                        changeHandler(e, e.value, creativeType);
                    }}
                />
            </div>
            <div>{isChecked && children}</div>
        </>
    );
}
