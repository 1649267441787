import { gql, useMutation } from "@apollo/client";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { pushMessage } from "../reducers/messagingReducer";
import SectionHeader from "./helper/SectionHeader";
import LayoutWrapper from "./LayoutWrapper";
import { registerScreenChanges } from "../reducers/screenChangesReducer";

const ECLIENT_MUTATION = gql`
    mutation callEclientInfoMutation($id: String, $clientId: String) {
        eclientInfoMutation(input: { id: $id, clientId: $clientId }) {
            clientInfo
        }
    }
`;
const DOMAIN_MUTATION = gql`
    mutation callDomainInfoMutation($id: String, $userId: String, $adminRole: Boolean) {
        domainInfoMutation(input: { id: $id, userId: $userId, adminRole: $adminRole }) {
            clientMutationId
            customerInfo
            presetInfo
        }
    }
`;

const SAVE_USER_SETTINGS_MUTATION = gql`
    mutation saveUserSettingsMutation($userData: [String], $originalDomainData: String) {
        saveUserSettingsMutation(input: { userData: $userData, originalDomainData: $originalDomainData }) {
            logout
            clientMutationId
            success
            message
        }
    }
`;

const UserEditScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const messages = useRef(null);
    const userRoles = [
        { name: "Customer Admin", code: "admin" },
        { name: "Customer User", code: "user" },
    ];
    const [domainInfo, setDomainInfo] = useState(null);
    const [domainOriginalInfo, setDomainOriginalInfo] = useState(null);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState("");
    const { userToEdit, currentCustomer } = useSelector((state) => state.userInformation);
    const [userInfo, setUserInfo] = useState({ userId: userToEdit });
    const [ECLIENT_MUTATION_CONTAINER] = useMutation(ECLIENT_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });
    const [DOMAIN_MUTATION_CONTAINER] = useMutation(DOMAIN_MUTATION, {
        onCompleted(data2) {
            confirmDomainInfo(data2);
        },
    });

    const handleChange = (param) => {
        let name = param.target.name,
            value = param.target.value,
            temp = Object.assign({}, userInfo);
        temp[name] = value;
        setUserInfo(temp);
        dispatch(registerScreenChanges("User edit screen"));
    };

    const saveExistingUserInfo = async () => {
        if (userInfo.length === 0) {
            dispatch(pushMessage("User Edit", "No data", "error"));
            throw new Error("No data");
        }
        if (!userInfo["first_name"]) {
            dispatch(pushMessage("User Edit", "Please enter First Name", "error"));
            throw new Error("Please enter First Name");
        }
        if (!userInfo["last_name"]) {
            dispatch(pushMessage("User Edit", "Please enter Last Name", "error"));
            throw new Error("Please enter Last Name");
        }
        if (!userInfo["user_email"]) {
            dispatch(pushMessage("User Edit", "Please enter Email", "error"));
            throw new Error("Please enter Email");
        }
        if (userInfo["old_password"] && (!userInfo["new_password"] || !userInfo["confirm_new_password"])) {
            dispatch(pushMessage("User Edit", "If you want to update password then fill all password fields. Or clear Old Password.", "error"));
            throw new Error("If you want to update password then fill all password fields. Or clear Old Password");
        }
        if ((userInfo["new_password"] || userInfo["confirm_new_password"]) && !userInfo["old_password"]) {
            dispatch(pushMessage("User Edit", "Enter old password or clear new passwords", "error"));
            throw new Error("Enter old password or clear new passwords");
        }
        if (userInfo["new_password"] !== userInfo["confirm_new_password"]) {
            dispatch(pushMessage("User Edit", "Passwords do not match", "error"));
            throw new Error("Passwords do not match");
        }
        if (userInfo["old_password"] && userInfo["new_password"] && userInfo["old_password"] === userInfo["new_password"]) {
            dispatch(pushMessage("User Edit", "Old password and new password are the same", "error"));
            throw new Error("Old password and new password are the same");
        }
        // Passed current customer id
        if (currentCustomer) {
            userInfo["clientID"] = currentCustomer;
        }

        return SAVE_USER_SETTINGS_MUTATION_CONTAINER({
            variables: {
                userData: JSON.stringify(userInfo),
                originalDomainData: JSON.stringify(domainOriginalInfo),
            },
        });
    };

    const [SAVE_USER_SETTINGS_MUTATION_CONTAINER] = useMutation(SAVE_USER_SETTINGS_MUTATION, {
        onCompleted(dataUpdate) {
            confirmUpdate(dataUpdate);
        },
    });

    const confirmUpdate = /*async*/ (data) => {
        let sms = data["saveUserSettingsMutation"]["message"];
        if (data["saveUserSettingsMutation"]["success"] === "true") {
            dispatch(pushMessage("User Edit", "User information was updated successfully"));
            if (data["saveUserSettingsMutation"]["logout"] === "logout") {
                setTimeout(logOut, 3000);
            }
        } else {
            dispatch(pushMessage("User Edit", sms, "error"));
        }
    };

    const logOut = () => {
        localStorage.clear();
        window.location.href = "./login";
    };

    useEffect(() => {
        ECLIENT_MUTATION_CONTAINER({
            variables: { id: userToEdit, clientId: `${currentCustomer}` },
        });
        DOMAIN_MUTATION_CONTAINER({
            variables: { id: currentCustomer, userId: localStorage.getItem("userID"), adminRole: localStorage.getItem("admin_role") === "true" ? true : false },
        });
    }, []);

    const confirm = (data) => {
        if (!userToEdit) {
            return history.push("/usersinfo");
        }

        let userData = JSON.parse(data.eclientInfoMutation.clientInfo);
        let userRole = userData.user_role;
        const role = localStorage.getItem("role");

        if (userRole === "Customer User") {
            setCurrentUserRole({ name: userRole, code: "user" });
        } else {
            setCurrentUserRole({ name: userRole, code: "admin" });
        }

        userData.userId = userToEdit;
        setUserInfo({ ...userData, role });
    };

    const confirmDomainInfo = (object) => {
        let temp_data = object.domainInfoMutation.customerInfo;
        temp_data = JSON.parse(temp_data);
        let temp_data2 = JSON.parse(object.domainInfoMutation.customerInfo);
        let user_current_domains = temp_data2.map(function (item) {
            if (item.user_mapping_list) {
                for (var i = item.user_mapping_list.length - 1; i >= 0; i--) {
                    if (item.user_mapping_list[i].user_name_with_domain.ID == userToEdit) {
                        return { code: item.id, name: item.title };
                    } else {
                        return false;
                    }
                }
            }
        });
        //remove false and undefined values
        user_current_domains = user_current_domains.filter(function (element) {
            return element !== undefined && element !== false;
        });
        let temp = { ...userInfo };
        temp["user_domains"] = user_current_domains;
        setDomainOriginalInfo(user_current_domains);
        setUserInfo(temp);
        temp_data = temp_data.map(function (item) {
            return { code: item.id, name: item.title };
        });
        setDomainInfo(temp_data);
        setMutationDataCompleted(true);
    };

    return (
        <>
            <LayoutWrapper saveCallback={saveExistingUserInfo}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-8 contact-form">
                        {userInfo && mutationDataCompleted && (
                            <div>
                                <Messages ref={messages} />
                                <div className="p-panel p-component">
                                    <div>
                                        <div className="">
                                            <SectionHeader title="User Profile" saveClicklickEvent={() => saveExistingUserInfo()} />
                                            <div className="p-panel-content p-fluid">
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="user_name">Username</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText disabled id="user_name" name="user_name" value={userInfo["user_name"]} />
                                                    </div>
                                                </div>
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="user_email">Email</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id="user_email" name="user_email" value={userInfo["user_email"]} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="first_name">First Name</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id="first_name" name="first_name" value={userInfo["first_name"]} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="last_name">Last Name</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <InputText id="last_name" name="last_name" value={userInfo["last_name"]} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                {localStorage.getItem("admin_role") !== "false" && (
                                                    <div className="p-grid small-margin-bottom-10">
                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                            <label htmlFor="user_role">User Role</label>
                                                        </div>
                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                            <Dropdown
                                                                value={currentUserRole}
                                                                name="user_role"
                                                                options={userRoles}
                                                                onChange={(ev) => {
                                                                    setCurrentUserRole(ev.value);
                                                                    setUserInfo({
                                                                        ...userInfo,
                                                                        role: ev.value.code,
                                                                    });
                                                                }}
                                                                optionLabel="name"
                                                                placeholder="Select user role"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="user_domains">Select Domain </label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <MultiSelect name={"user_domains"} value={userInfo["user_domains"]} options={domainInfo} onChange={handleChange} optionLabel="name" display="chip" placeholder="Select Domains for Customer" scrollHeight="800" filter />
                                                    </div>
                                                </div>
                                                <div className="p-grid small-margin-bottom-10">
                                                    <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                        <label htmlFor="old_password">Old Password</label>
                                                    </div>
                                                    <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                        <Password id="old_password" name="old_password" value={userInfo["old_password"]} onChange={handleChange} feedback={false} />
                                                    </div>
                                                </div>
                                                <>
                                                    <div className="p-grid small-margin-bottom-10">
                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                            <label htmlFor={"new_password"}>New Password</label>
                                                        </div>
                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                            <Password id={"new_password"} name="new_password" value={userInfo.new_password} onChange={(e) => handleChange(e)} promptLabel={"Enter New Password"} />
                                                        </div>
                                                    </div>
                                                    <div className="p-grid small-margin-bottom-10">
                                                        <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                            <label htmlFor={"confirm_new_password"}>Confirm New Password</label>
                                                        </div>
                                                        <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                            <Password id={"confirm_new_password"} name="confirm_new_password" value={userInfo.confirm_new_password} onChange={(e) => handleChange(e)} promptLabel={"Confirm New Password"} feedback={false} />
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </LayoutWrapper>
        </>
    );
};

export default UserEditScreen;
