import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { InputText } from 'primereact/inputtext'
import { SketchPicker } from 'react-color'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faEyeDropper, faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faExclamationCircle, faEyeDropper, faSquareXmark)

const styles = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 10px;
    .required {
        color: #ff0000;
        font-size: 16px;
        padding-left: 3px;
    }
    .controls-label {
        font-weight: 600;
        margin-bottom: 5px;
        position: relative;
    }
    .indicator {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
        margin-right: 0.5em;
        border: 1px solid #000;
    }
    .picker-container {
        display: flex;
        align-items: flex-start;
        margin-top: 0.5em;
        margin-bottom: 1em;
        input {
            padding: 0;
            border: none;
            border-bottom: 1px solid #ccc;
            width: 80%;
            border-radius: 0;
            display: inline-block !important;;
            &:focus,
            &:focus-within,
            &:hover {
                outline: none;
                box-shadow: none;
                border-bottom: 1px solid #ccc;
            }
        }
    }
    .color-picker-close,
    .color-picker-show {
        display: inline-block;
        margin-left: auto;
        cursor: pointer;
        color: rgb(33, 150, 243);
        user-select: none;
    }
    .sketch-picker {
        box-shadow: none !important;
        border: 1px solid #ccc !important;
        width: calc(100% - 10px) !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
`

export default function ColorPicker({ 
    label, 
    name, 
    value, 
    changeHandler,
    adtype,
    required = false,
    disabled = false,
}) {
    const [showPicker, setShowPicker] = useState(false)

    function togglePicker() {
        setShowPicker(!showPicker)
    }

    return (
        <div
            css={styles}
            onFocus={() => setShowPicker(true)}
        >
            <label htmlFor={name} className="controls-label">
                { label }
                { required && <span className="required">*</span> }
            </label>
            <div className="picker-container">
                <div className="indicator" style={{backgroundColor: value}}></div>
                <InputText 
                    name={name} 
                    value={value} 
                    required={required}
                    disabled={disabled}
                    onChange={ev => {
                        changeHandler(ev, ev.target.value, adtype)
                    }} 
                />
                <span
                    className="color-picker-show"
                    onClick={togglePicker}
                >
                    <FontAwesomeIcon icon={showPicker ? "square-xmark" : "eye-dropper"} color={showPicker ? '#ce2424' : '#2196f3'} fontSize="1.25em" />
                </span>
            </div>
            {
                showPicker &&
                <SketchPicker
                    disableAlpha={true}
                    color={value || '#fff'}
                    onChangeComplete={color => {
                        changeHandler({target: {name}}, color.hex, adtype)
                    }}

                />
            }
        </div>
    )
}
