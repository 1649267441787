/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { buyReviewSectionStyles } from "./styles";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { DEFAULT_MARKETPLACE_ORDER } from "./constants";
import { createMarketplaceOrders } from "../../../reducers/marketplaceOrdersReducer";
import Cta from "./CTA";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

export default function MarketplaceBuyReviewSection({
    isAgency = false,
    showBuyingScreen,
    setShowBuyingScreen,
    setOrderSetupOverlaySettings,
    currentCustomer
}) {
    const dispatch = useDispatch()

    return (
        <div className="p-grid">
            <div
                className="p-col-12 p-sm-12 p-mt-2 small-margin-top-0 small-padding-bottom-0 p-grid"
                css={css`
                    ${buyReviewSectionStyles}
                    justify-content: ${isAgency ? "flex-end" : "center"};
                    .marketplace-new-orders {
                        position: ${isAgency ? "relative" : "absolute"};
                    }
                `}
            >
                {!isAgency && (
                    <div className="marketplace-nav p-grid">
                        <Button
                            label="Buy Inventory"
                            className={`p-button p-component p-button-text p-button-plain font-weight-700 ${showBuyingScreen ? "text-black" : "text-faded"}`}
                            onClick={() => setShowBuyingScreen(true)}
                        />
                        <Button
                            label="Review & Approve deals"
                            className={`p-button p-component p-button-text p-button-plain font-weight-700 ${showBuyingScreen ? "text-faded" : "text-black"}`}
                            onClick={() => setShowBuyingScreen(false)}
                        />
                    </div>
                )}
                <div className="marketplace-new-orders">
                    {showBuyingScreen && (
                        <Cta
                            iconPos="left"
                            ctaText="New Order"
                            icon={faCirclePlus}
                            themeClasses="marketplace-cta outlined default"
                            onClick={() => {
                                setOrderSetupOverlaySettings({
                                    isSettingsOverlayVisible: true,
                                    isDismissOperation: false,
                                    isAddOperation: false,
                                    orderIdx: 0
                                })
                                dispatch(createMarketplaceOrders([{ ...DEFAULT_MARKETPLACE_ORDER }], currentCustomer))
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}