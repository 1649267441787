import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import DropdownSection from "./DropdownSection"
import InputTextSection from "./InputTextSection";

export default function VideoAssetCreativeType({
    dropdownLabel,
    types,
    videoCreativeTypePropertyName,
    videoCreativeType,
    handleChange,
    CREATIVE_TYPE,
    currentCustomer,
    videoUrlInputLabel,
    videoUrlInputName,
    videoUrl,
    vastInputName,
    vastUrl,
    vastDurationInputName,
    vastDuration
}) {
    let controls = null;

    if (videoCreativeType === 'video') {
        controls = (
            <AssetInputOrUploadSection
                inputValue={videoUrl}
                inputLabel={videoUrlInputLabel || "Video Creative URL"}
                inputName={videoUrlInputName}
                inputChangeHandler={handleChange}
                inputAdtype={CREATIVE_TYPE}
                isRequired={true}
                currentCustomer={currentCustomer}
                accept='video/*'
                defaultExtension='mp4'
            />
        )
    } else if (videoCreativeType === 'vast') {
        controls = (
            <>
                <InputTextSection
                    label={`Vast Tag`}
                    name={vastInputName}
                    value={vastUrl}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                    uriEncodeValue={false}
                />
                <InputTextSection
                    label={`Approx. duration`}
                    name={vastDurationInputName}
                    value={vastDuration}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                />
            </>
        )
    }

    return (
        <>
            <DropdownSection
                label={dropdownLabel}
                options={types}
                name={videoCreativeTypePropertyName}
                value={videoCreativeType}
                changeHandler={handleChange}
                adtype={CREATIVE_TYPE}
            />
            {controls}
        </>
    )
}