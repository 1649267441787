/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeFromBuildList } from "../reducers/buildReducer";
import { pushMessage } from "../reducers/messagingReducer";
import LayoutWrapper from "./LayoutWrapper";
import Cta from "./common/marketplace/CTA";
import { CACHE_CLEAR_MUTATION } from "./common/multiBuild/constants";
import { useDomainSettings } from "../hooks/useDomainSettings";
import { DOMAIN_RESTORE_MUTATION } from "../mutations/mutations";

const DomainRestoreSystem = () => {
    const { currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const history = useHistory();
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [settingsData, setSettingsData] = useState(null);
    const [showSettingsInfo, setShowSettingsInfo] = useState(false);
    const messages = useRef(null);
    const dispatch = useDispatch();
    const [cacheClearMutationContainer] = useMutation(CACHE_CLEAR_MUTATION);
    const [isClearingCache, setIsClearingCache] = useState(false);

    const DOMAIN_SETTINGS_MUTATION_CONTAINER = useDomainSettings({
        onCompleted(data) {
            let temp_data = JSON.parse(data.domainEditMutation.customerInfo);
            temp_data = temp_data["restore_data"];

            setSettingsData(temp_data);
            setMutationDataCompleted(true);
        }
    })
    
    const [DOMAIN_RESTORE_MUTATION_CONTAINER] = useMutation(DOMAIN_RESTORE_MUTATION, {
        onCompleted(dataUpdate) {
            dispatch(removeFromBuildList(currentDomain));
            confirmRestoreUpdate(dataUpdate);
        },
    });

    useEffect(() => {
        if (!currentCustomer || !currentDomain) {
            return history.push("/domains");
        }
        setShowSettingsInfo(true);
        DOMAIN_SETTINGS_MUTATION_CONTAINER({
            domainId: currentDomain,
            customerId: currentCustomer
        })
    }, [currentCustomer, currentDomain]);

    const confirmRestoreUpdate = /*async*/ (data) => {
        if (data.restore_status) {
            dispatch(pushMessage("Domain Build", "Restore completed"));
        } else {
            dispatch(pushMessage("Domain Build", "Restore failed. Try again", "error"));
        }
    };

    const callRestoreSystem = (ri) => {
        if (window.confirm("Are you sure you want to restore!")) {
            let restoreInfo = {
                id: ri.restore_id,
                post_id: `${currentDomain}`,
                post_date: ri.date,
                domain: ri.domain,
                slug: ri.slug,
                restore_uid: localStorage.getItem("userID"),
            };

            DOMAIN_RESTORE_MUTATION_CONTAINER({
                variables: {
                    restoreData: JSON.stringify(restoreInfo),
                },
            });
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const buildInfoPerPage = 25;
    const pagesVisited = pageNumber * buildInfoPerPage;

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    function handleCacheClear() {
        setIsClearingCache(true);
        cacheClearMutationContainer({
            variables: {
                domainId: `${currentDomain}`,
            },
        })
            .then((response) => {
                let mutationResponse = JSON.parse(response.data?.purgeBuildCacheMutation?.response || null);
                console.log({mutationResponse})
                if (!mutationResponse || mutationResponse?.errorMessage || !mutationResponse.cleared) {
                    dispatch(pushMessage("Build: ", "Clearing the cache has failed", "error"));
                } else {
                    dispatch(pushMessage("Build: ", "Cache has been cleared"));
                }
                setIsClearingCache(false);
            })
            .catch((err) => {
                setIsClearingCache(false);
                dispatch(pushMessage("Build: ", err.message, "error"));
            });
    }

    return (
        <LayoutWrapper renderOpsBar={false} model="domain">
            {mutationDataCompleted && showSettingsInfo && (
                <div className="p-grid">
                    <div className="p-col-12 p-md-12 p-lg-12 contact-form">
                        <Messages ref={messages} />
                        <div className="p-panel p-component">

                            {settingsData && settingsData.length > 0 && (
                                <Panel header="History">
                                    <div className="datatable-responsive">
                                        <div className="p-datatable p-component p-datatable-responsive p-datatable-striped">
                                            <table style={{ tableLayout: "auto" }}>
                                                <thead className="p-datatable-thead">
                                                    <tr>
                                                        <th>Date (UTC Time)</th>
                                                        <th className="p-d-none">Slug</th>
                                                        <th>Build By</th>
                                                        <th>Restore By</th>
                                                        <th>Active Status</th>
                                                        <th>Build Number</th>
                                                        <th>Log Message</th>
                                                        <th style={{ width: "110px" }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="p-datatable-tbody">
                                                    {settingsData.slice(pagesVisited, pagesVisited + buildInfoPerPage).map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className="p-column-title">Date</span> {item.date}
                                                            </td>
                                                            <td className="p-d-none">
                                                                <span className="p-column-title">Slug</span> {item.slug}
                                                            </td>
                                                            <td>
                                                                <span className="p-column-title">Build By</span> {item.build_by}
                                                            </td>
                                                            <td>
                                                                <span className="p-column-title">Restore By</span> {item.restore_by}
                                                            </td>
                                                            <td>
                                                                <span className="p-column-title">Active Status</span> {item.active !== "Active" ? "" : <Button className="p-button-rounded p-button-icon-only p-button-success p-button-small" icon="pi pi-check" />}
                                                            </td>
                                                            <td>
                                                                <span className="p-column-title">Build Number</span>
                                                                <a href={item.wrapper_ul} target="_blank"style={{ pointerEvents: !item.wrapper_ul ? 'none' : 'auto' }}>
                                                                    {" "}
                                                                    {item.restore_id}{" "}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <span className="p-column-title">Log Message</span>
                                                                {item.log_message}
                                                            </td>
                                                            <td>{pageNumber < 2 ? <Cta disabled={item.active === "Active"} ctaText="Restore" onClick={() => callRestoreSystem(item)} themeClasses="marketplace-cta default outlined" /> : null}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <br />
                                            <ReactPaginate
                                                previousLabel={"<<Previous"}
                                                nextLabel={"Next>>"}
                                                pageCount={Math.ceil(settingsData.length / buildInfoPerPage)}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div>
                                    </div>
                                </Panel>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="ops-bar">
                <Cta ctaText="Clear cache" icon={faRefresh} showLoader={isClearingCache} disabled={isClearingCache} themeClasses="marketplace-cta default outlined" onClick={handleCacheClear} />
            </div>
        </LayoutWrapper>
    );
};

export default DomainRestoreSystem;
