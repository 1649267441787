/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";
import { AppMenu } from "../AppMenu";
import { AppTopbar } from "../AppTopbar";
import { DomainsDropdown } from "../DomainsDropdown";
import { sidebarClassName, wrapperClass } from "../constants";
import { noop } from "../utilities/utils";
import AppLogo from "./AppLogo";
import BuilDomainButton from "./BuildDomainButton";
import SaveButton from "./SaveButton";
import Cta from "./common/marketplace/CTA";

const staticMenuInactive = false;

const styles = css`
    .build-status {
        position: fixed;
        right: 1em;
        bottom: 60px;
        z-index: 9999;
    }
    .ops-bar {
        position: fixed;
        left: 250px;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: #f4f4f4;
        border: 1px solid #dee2e6;
        border-radius: 6px;
        padding: 0 2em;
        button {
            margin: 0 1em;
        }
    }
    .screen-container {
        padding-bottom: 80px;
    }
`

const LayoutWrapper = ({
    model,
    children,
    renderOpsBar = true,
    saveCallback,
    hideNavigationDropdown = false,
    buildControls,
    logUserInteraction = true,
}) => {
    const history  = useHistory()
    const { modifiedPresets = new Set() } = useSelector((state) => state.buildState);
    const { currentPreset, currentCustomer, currentDomain } = useSelector((state) => state.userInformation);
    const buildMessageRef = useRef(null)
    const buildState = useSelector(state => state.multiBuildSystemInfo)
    const [isBuildingCurrentDomain, setIsBuildingCurrentDomain] = useState(false)

    useEffect(() => {
        if (currentDomain && currentCustomer) {
            try {
                let domains = buildState[currentCustomer]?.buildList || []
                let d = parseInt(currentDomain)

                setIsBuildingCurrentDomain(buildState[currentCustomer]?.isBuilding && domains.includes(d))
            } catch {
                noop()
            }
        }
    }, [buildState])

    return (
        <div className="center w100">
            <div
                className={wrapperClass}
                css={css`${styles}`}
            >
                <AppTopbar />
                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
                    <div className={sidebarClassName}>
                        <AppLogo />
                        <DomainsDropdown hideDropdown={model === "marketplace" || hideNavigationDropdown} />
                        <AppMenu model={model} onMenuItemClick={noop} />
                    </div>
                </CSSTransition>
                <div className="layout-main p-rel">
                    <div className="screen-container">{children}</div>
                </div>
                <div className="build-status">
                    <Messages ref={buildMessageRef}></Messages>
                </div>
                {renderOpsBar && (
                    <div className="ops-bar">
                        {
                            typeof saveCallback === "function" && <SaveButton saveCallback={saveCallback} disableSave={isBuildingCurrentDomain} logUserInteraction={logUserInteraction} />
                        }
                        {model === "preset" && (
                            <Cta
                                ctaText={"Build Domains"}
                                themeClasses={`outlined marketplace-cta ${modifiedPresets.has(currentPreset) ? 'danger' : 'default'}`}
                                icon={faCogs}
                                onClick={() => {
                                    history.push('/domains')
                                }}
                            />
                        )}
                        {
                            model === "domain" && (
                                <>
                                    <BuilDomainButton />
                                </>
                            )
                        }
                        {
                            buildControls
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default LayoutWrapper;
