import { gql } from "@apollo/client";
import React, { useRef, useState } from "react";
import { Mutation } from "react-apollo";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AUTH_TOKEN, AVATAR_LINK, USER_NAME, client } from "../constants";
import { pushMessage } from "../reducers/messagingReducer";
import { setCurrentCustomer, setUserID } from "../reducers/userLoginReducer";
import { first } from "../utilities/utils";
import AppLogo from "./AppLogo";

const LOGIN_MUTATION = gql`
    mutation callLoginMutation($email: String, $password: String) {
        loginMutation(input: { email: $email, password: $password }) {
            jwtData
        }
    }
`;

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [login, setLogin] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const loginBtnRef = useRef();

    const saveUserData = (token, userName, clientName, avatar, clientID, role, userID, userDomainCount, userCount, userFname, userLname, actualUserName, adminRole, userInfo, clients) => {
        dispatch(setUserID(userID));
        localStorage.setItem("userID", userID);
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(USER_NAME, userName);
        localStorage.setItem("actualUserName", actualUserName);
        localStorage.setItem("customer-name", clientName);
        localStorage.setItem("role", role);
        localStorage.setItem("userDomainCount", userDomainCount);
        localStorage.setItem("userCount", userCount);
        localStorage.setItem("assignedClients", JSON.stringify(clients));

        if (role === "client") {
            dispatch(setCurrentCustomer(clientID));
            localStorage.setItem("clientID", clientID);
            localStorage.setItem("admin_role", adminRole);
        } else {
            localStorage.setItem("partnerID", clientID);
            localStorage.setItem("admin_role", String(true));
        }
        localStorage.setItem(AVATAR_LINK, avatar);
    };

    const confirm = (data) => {
        if (data[0] === undefined) {
            dispatch(pushMessage("Login", "Login failed. Please check your credentials", "error"));
        } else {
            const allData = first(data);
            saveUserData(
                allData.token,
                allData.userName,
                allData.customer,
                allData.avatar,
                allData.clientID,
                allData.role,
                allData.userId,
                allData.userDomainCount,
                allData.userCount,
                allData.userFirstName,
                allData.userLastName,
                allData.actualUserName,
                allData.adminCapability,
                allData.userinfo,
                allData.clients
            );
            history.push("/");
        }
    };

    return (
        <div className="p-d-flex p-jc-center p-ai-center p-flex-column" style={{ backgroundColor: "#007cba", position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}>
            <div className="p-mb-6">
                <a href="http://adgrid.io/">
                    <AppLogo />
                </a>
            </div>
            <div
                className="p-shadow-10"
                style={{
                    maxWidth: "320px",
                    backgroundColor: "white",
                    padding: "30px 25px 23px",
                    width: "100%",
                }}
            >
                <div className="p-d-flex p-flex-column p-mb-2">
                    <input className="p-inputtext p-component p-d-block p-mb-2" value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Your username/login" />
                    <input
                        className="p-inputtext p-component p-d-block p-mb-2"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") loginBtnRef.current.click();
                        }}
                        type="password"
                        placeholder="Choose a safe password"
                    />
                </div>
                <div className="p-grid p-justify-between p-align-center">
                    <div className="p-col">
                        <a
                            href="#"
                            onClick={function () {
                                window.location = "/forgotpassword";
                            }}
                        >
                            Lost your password?
                        </a>
                    </div>
                    <div className="p-col p-text-right">
                        <Mutation client={client} mutation={LOGIN_MUTATION} variables={{ email, password }} onCompleted={(data) => confirm(data)}>
                            {(mutation) => (
                                <button className="p-button p-component" onClick={mutation} ref={loginBtnRef}>
                                    {login ? "Log In" : "create account"}
                                </button>
                            )}
                        </Mutation>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
