import { gql } from "@apollo/client";

export const CREATIVE_NAMES = {
    DISPLAY_INTERSTITIAL: "display_interstitial" /* this is the popup ad */,
    VIDEO_INTERSTITIAL: "video-interstitial",
    DISPLAY_ADHESION: "adhesion" /* collapsible ad  */,
    VIDEO_ADHESION: "video_collapsible" /* collapsible video ad */,
    DISPLAY_PARALLAX: "display_parallax",
    VIDEO_PARALLAX: "video_parallax",
    IN_BANNER_VIDEO: "in_banner_video",
    DESKTOP_INTERACTIVE_BILLBOARD: "desktop_interactive_billboard",
    MOBILE_STICKY_VIDEO: "mobile_sticky_video",
    CROSS_SCREEN_TAKEOVER: "cross_screen_takeover",
    CUBE: "cube",
    MOBILE_EXPANDABLE: "mobile_expandable",
    DESKTOP_SHOPPABLE_BILLBOARD: "desktop_shoppable_billboard",
    DESKTOP_FOOTER: "desktop_footer",
    MOBILE_FOOTER: "mobile_footer",
    DISPLAY_SKYBOX: "display_skybox",
    SYNDICATION_CREATIVE: "syndication_creative",
    FOOTER_GALLERY: "gallery_footer",
    ONE_SYNDICATION: "one_syndication",
    SKIN_CREATIVE: 'skin_creative',
    LEADERBOARD_PARALLAX: 'leaderboard_parallax',
    MOBILE_TAKEOVER: 'mobile_takeover'
};

export const CREATIVES_DISPLAY_NAMES = {
    display_interstitial: "Display Popup" /* this is the popup ad */,
    "video-interstitial": "Video Popup",
    adhesion: "Display Adhesion/Collapsible" /* collapsible ad  */,
    video_collapsible: "Video Adhesion/Collapsible" /* collapsible video ad */,
    display_parallax: "Display Parallax",
    video_parallax: "Video Parallax",
    in_banner_video: "Video Skybox",
    desktop_interactive_billboard: "Desktop Interactive Billboard",
    mobile_sticky_video: "Mobile Sticky Video",
    cross_screen_takeover: "Cross Screen Takeover",
    cube: "3D Cube",
    mobile_expandable: "Mobile Expandable",
    desktop_shoppable_billboard: "Desktop Shoppable Billboard",
    desktop_footer: "Desktop Footer",
    mobile_footer: "Mobile Footer",
    display_skybox: "Display Skybox",
    syndication_creative: "Syndication Creative",
    gallery_footer: "Footer Gallery",
    skin_creative: "Ebony Skin Creative",
    one_syndication: "One Syndication",
    leaderboard_parallax: "Leaderboard Parallax",
    mobile_takeover: "Mobile TakeOver"
};

export const CREATIVE_TYPES = [
    { label: "Display Popup", value: CREATIVE_NAMES.DISPLAY_INTERSTITIAL, adminOnly: false },
    { label: "Video Popup", value: CREATIVE_NAMES.VIDEO_INTERSTITIAL, adminOnly: false },
    { label: "Display Adhesion/Collapsible", value: CREATIVE_NAMES.DISPLAY_ADHESION, adminOnly: false },
    { label: "Video Adhesion/Collapsible", value: CREATIVE_NAMES.VIDEO_ADHESION, adminOnly: false },
    { label: "Display Parallax", value: CREATIVE_NAMES.DISPLAY_PARALLAX, adminOnly: false },
    { label: "Video Parallax", value: CREATIVE_NAMES.VIDEO_PARALLAX, adminOnly: false },
    { label: "Video Skybox", value: CREATIVE_NAMES.IN_BANNER_VIDEO, adminOnly: false },
    { label: "Desktop Interactive Billboard", value: CREATIVE_NAMES.DESKTOP_INTERACTIVE_BILLBOARD, adminOnly: false },
    { label: "Mobile Sticky Video", value: CREATIVE_NAMES.MOBILE_STICKY_VIDEO, adminOnly: false },
    { label: "Cross Screen Takeover", value: CREATIVE_NAMES.CROSS_SCREEN_TAKEOVER, adminOnly: false },
    { label: "Cube", value: CREATIVE_NAMES.CUBE, adminOnly: false },
    { label: "Mobile Expandable", value: CREATIVE_NAMES.MOBILE_EXPANDABLE, adminOnly: false },
    { label: "Desktop Shoppable Billboard", value: CREATIVE_NAMES.DESKTOP_SHOPPABLE_BILLBOARD, adminOnly: false },
    { label: "Desktop Footer", value: CREATIVE_NAMES.DESKTOP_FOOTER, adminOnly: false },
    { label: "Mobile Footer", value: CREATIVE_NAMES.MOBILE_FOOTER, adminOnly: false },
    { label: "Display Skybox", value: CREATIVE_NAMES.DISPLAY_SKYBOX, adminOnly: false },
    { label: "One Syndication", value: CREATIVE_NAMES.ONE_SYNDICATION },
    { label: "Syndication Creative", value: CREATIVE_NAMES.SYNDICATION_CREATIVE, adminOnly: false },
    { label: "Footer Gallery", value: CREATIVE_NAMES.FOOTER_GALLERY, adminOnly: false },
    { label: "Ebony Skin Creative", value: CREATIVE_NAMES.SKIN_CREATIVE, adminOnly: true },
    { label: "Leaderboard Parallax", value: CREATIVE_NAMES.LEADERBOARD_PARALLAX, adminOnly: false },
    { label: "Mobile TakeOver", value: CREATIVE_NAMES.MOBILE_TAKEOVER }
];

export const DEFAULT_CLOSE_BTN_POSITIONS = {
    TOP_LEFT: "top_left",
    TOP_RIGHT: "top_right",
    TOP_LEFT_IN: "top_left_in",
    TOP_RIGHT_IN: "top_right_in",
    BOTTOM_LEFT: "bottom_left",
    BOTTOM_RIGHT: "bottom_right",
};

export const CONTAINER_INSERTIION_MODE = {
    APPEND: "append",
    INSERT_BEFORE: "insert_before",
    INSERT_AFTER: "insert_after",
    PREPEND: "prepend",
};

export const CLOSE_BTN_DROPDOWN = [
    { label: "Top left", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT },
    { label: "Top Right", value: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT },
    { label: "Bottom left", value: DEFAULT_CLOSE_BTN_POSITIONS.BOTTOM_LEFT },
    { label: "Bottom right", value: DEFAULT_CLOSE_BTN_POSITIONS.BOTTOM_RIGHT },
];

export const DEFAULT_ADHESION_CONFIG = {
    closeBtnPos: DEFAULT_CLOSE_BTN_POSITIONS.TOP_LEFT,
    desktopCreativeURL: "",
    desktopCollapsedCreativeURL: "",
    mobileCollapsedCreativeURL: "",
    collapseDelay: 4000,
    closeBtnBg: "#000",
    closeBtnWordColor: "#fff",
    autoCollapseAd: true,
    collapseBtnWording: "Collapse",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "desktopCreativeURL",
        "desktopCollapsedCreativeURL",
        "mobileCollapsedCreativeURL",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        desktopCreativeURL: "Desktop creative image URL",
        desktopCollapsedCreativeURL: "Desktop collapsed image URL",
        mobileCollapsedCreativeURL: "Mobile collapsed image URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const DISPLAY_ADHESION_AD_FIELDS = {
    desktopCreativeURL: "desktopCreativeURL",
    desktopCollapsedCreativeURL: "desktopCollapsedCreativeURL",
    mobileCollapsedCreativeURL: "mobileCollapsedCreativeURL",
    collapseDelay: "collapseDelay",
    collapseBtnWording: "collapseBtnWording",
    autoCollapseAd: "autoCollapseAd",
    closeBtnPos: "closeBtnPos",
    closeBtnBg: "closeBtnBg",
    closeBtnWordColor: "closeBtnWordColor",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType",
};

export const VIDEO_ADHESION_CONFIG = {
    collapsibleVideoUrl: "",
    collapsibleDesktopImageUrl: "",
    collapsibleTabletImageUrl: "",
    collapsibleMobileImageUrl: "",
    collapseDelay: 4000,
    collapseBtnWording: "Collapse Ad",
    autoCollapseAd: false,
    closeBtnPos: "",
    closeBtnBg: "",
    closeBtnWordColor: "",
    impressionTag: "",
    clickTag: "",
    videoCreativeType: "video",
    vastUrl: '',
    vastDuration: 0,
    adServingType: "",
    required: [
        "collapsibleVideoUrl",
        "collapsibleDesktopImageUrl",
        "collapsibleTabletImageUrl",
        "collapsibleMobileImageUrl",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "collapsibleVideoUrl": "Video creative URL",
        "collapsibleDesktopImageUrl": "Desktop image creative URL",
        "collapsibleTabletImageUrl": "Tablet image creative URL",
        "collapsibleMobileImageUrl": "Mobile image creative URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const VIDEO_ADHESION_AD_FIELDS = {
    collapsibleVideoUrl: "collapsibleVideoUrl",
    collapsibleDesktopImageUrl: "collapsibleDesktopImageUrl",
    collapsibleTabletImageUrl: "collapsibleTabletImageUrl",
    collapsibleMobileImageUrl: "collapsibleMobileImageUrl",
    collapseDelay: "collapseDelay",
    collapseBtnWording: "collapseBtnWording",
    autoCollapseAd: "autoCollapseAd",
    closeBtnPos: "closeBtnPos",
    closeBtnBg: "closeBtnBg",
    closeBtnWordColor: "closeBtnWordColor",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    videoCreativeType: "videoCreativeType",
    vastUrl: 'vastUrl',
    vastDuration: 'vastDuration',
    adServingType: "adServingType",
};

export const DEFAULT_VIDEO_PARALLAX_CONFIG = {
    videoCreativeURL: "",
    container: "",
    insertionMode: CONTAINER_INSERTIION_MODE.APPEND,
    impressionTag: "",
    clickTag: "",
    vastUrl: "",
    topOffset: 0,
    vastDuration: 0,
    videoCreativeType: 'video',
    adServingType: "",
    required: [
        "container",
        "videoCreativeURL",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "videoCreativeURL": "Video creative URL",
        "container": "Div/wrapper CSS selector",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const VIDEO_PARALLAX_AD_FIELDS = {
    insertionMode: "insertionMode",
    videoCreativeURL: "videoCreativeURL",
    container: "container",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    topOffset: "topOffset",
    vastUrl: "vastUrl",
    vastDuration: "vastDuration",
    videoCreativeType: 'videoCreativeType',
    adServingType: "adServingType"
};

export const DISPLAY_PARALLAX_CONFIG = {
    desktopCreativeURL: "",
    insertionMode: CONTAINER_INSERTIION_MODE.APPEND,
    container: "",
    impressionTag: "",
    offsetBreakpoint: 0,
    mobileTopOffset: 0,
    topOffset: 0,
    clickTag: "",
    adServingType: "",
    required: [
        "container",
        "desktopCreativeURL",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "desktopCreativeURL": "Image creative URL",
        "container": "Div/wrapper CSS selector",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const DISPLAY_PARALLAX_AD_FIELDS = {
    desktopCreativeURL: "desktopCreativeURL",
    insertionMode: "insertionMode",
    container: "container",
    impressionTag: "impressionTag",
    offsetBreakpoint: "offsetBreakpoint",
    mobileTopOffset: "mobileTopOffset",
    topOffset: "topOffset",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const DISPLAY_INTERSTITIAL_CONFIG = {
    interstitialImg: "",
    interstitialImgMobile: "",
    closeBtnWord: "X",
    closeBtnBg: "#000",
    closeBtnWordColor: "#fff",
    closeBtnPos: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT,
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "interstitialImg",
        "interstitialImgMobile",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        interstitialImg: "Desktop creative image URL",
        interstitialImgMobile: "Mobile creative image URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const DISPLAY_INTERSTITIAL_AD_FIELDS = {
    interstitialImg: "interstitialImg",
    interstitialImgMobile: "interstitialImgMobile",
    closeBtnWord: "closeBtnWord",
    closeBtnBg: "closeBtnBg",
    closeBtnWordColor: "closeBtnWordColor",
    closeBtnPos: "closeBtnPos",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const VIDEO_POPUP_CONFIG = {
    interstitialVideo: "",
    closeBtnPos: DEFAULT_CLOSE_BTN_POSITIONS.TOP_RIGHT,
    closeBtnWord: "X",
    impressionTag: "",
    clickTag: "",
    vastUrl: "",
    vastDuration: "",
    videoCreativeType: 'video',
    adServingType: "",
    required: [
        "interstitialVideo",
        "closeBtnWord",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        interstitialVideo: "Video creative URL",
        closeBtnWord: "Close button wording",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const VIDEO_POPUP_AD_FIELDS = {
    interstitialVideo: "interstitialVideo",
    closeBtnPos: "closeBtnPos",
    closeBtnWord: "closeBtnWord",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    vastUrl: "vastUrl",
    vastDuration: "vastDuration",
    videoCreativeType: 'videoCreativeType',
    adServingType: "adServingType"
};

export const IN_BANNER_VIDEO_CONFIG = {
    displayCreativeUrl: "",
    collapsedCreaiveUrl: "",
    mobileCreativeUrl: "",
    videoCreativeUrl: "",
    topOffset: 0,
    mobileTopOffset: 0,
    impressionTag: "",
    clickTag: "",
    poster: "",
    vastUrl: "",
    vastDuration: '',
    videoCreativeType: 'video',
    adServingType: "",
    required: [
        "displayCreativeUrl",
        "collapsedCreaiveUrl",
        "mobileCreativeUrl",
        "videoCreativeUrl",
        "poster",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "displayCreativeUrl": "Desktop banner creative URL",
        "collapsedCreaiveUrl": "Collapsed banner creative URL",
        "mobileCreativeUrl": "Mobile banner creative URL",
        "videoCreativeUrl": "Video creative URL",
        "poster": "Static video poster",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const IN_BANNER_VIDEO_AD_FIELDS = {
    displayCreativeUrl: "displayCreativeUrl",
    collapsedCreaiveUrl: "collapsedCreaiveUrl",
    mobileCreativeUrl: "mobileCreativeUrl",
    videoCreativeUrl: "videoCreativeUrl",
    topOffset: "topOffset",
    mobileTopOffset: "mobileTopOffset",
    impressionTag: "impressionTag",
    poster: "poster",
    clickTag: "clickTag",
    vastUrl: "vastUrl",
    vastDuration: 'vastDuration',
    videoCreativeType: 'videoCreativeType',
    adServingType: "adServingType"
};

export const DESKTOP_INTERACTIVE_BILLBOARD_CONFIG = {
    container: "",
    bannerUrl: "",
    videoCreativeUrl: "",
    bgColor: "#0d3446",
    hoverBgColor: "#ff0000",
    btnText: "Learn More",
    color: "#ffffff",
    hoverColor: "#ffffff",
    impressionTag: "",
    clickTag: "",
    left: "",
    bottom: "",
    vastUrl: "",
    vastDuration: "",
    videoCreativeType: "video",
    adServingType: "",
    required: [
        "bannerUrl",
        "videoCreativeUrl",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "bannerUrl": "Creative banner URL",
        "videoCreativeUrl": "Video creative URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const DESKTOP_INTERACTIVE_BILLBOARD_AD_FIELDS = {
    container: "container",
    bannerUrl: "bannerUrl",
    videoCreativeUrl: "videoCreativeUrl",
    bgColor: "bgColor",
    color: "color",
    hoverBgColor: "hoverBgColor",
    hoverColor: "hoverColor",
    btnText: "btnText",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    left: "left",
    bottom: "bottom",
    vastUrl: "vastUrl",
    vastDuration: "vastDuration",
    videoCreativeType: "videoCreativeType",
    adServingType: "adServingType"
};

export const MOBILE_STICKY_VIDEO_CONFIG = {
    bannerUrl: "",
    videoCreativeUrl: "",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "bannerUrl",
        "videoCreativeUrl",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "bannerUrl": "Creative banner URL",
        "videoCreativeUrl": "Video creative URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const MOBILE_STICKY_VIDEO_AD_FIELDS = {
    bannerUrl: "bannerUrl",
    videoCreativeUrl: "videoCreativeUrl",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const CROSS_SCREEN_TAKEOVER_CONFIG = {
    cstBanner: "",
    rightBanner: "",
    leftBanner: "",
    videoCreativeUrl: "",
    mobileTopbanner: "",
    mobileBottombanner: "",
    mobileScroller: "",
    dynamicFit: false,
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "cstBanner",
        "rightBanner",
        "leftBanner",
        "videoCreativeUrl",
        "mobileTopbanner",
        "mobileBottombanner",
        "mobileScroller",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "cstBanner": "Desktop top banner URL",
        "rightBanner": "Desktop right banner URL",
        "leftBanner": "Desktop left banner URL",
        "videoCreativeUrl": "Video creative URL",
        "mobileTopbanner": "Mobile top banner URL",
        "mobileBottombanner": "Mobile bottom banner URL",
        "mobileScroller": "Mobile scroller banner URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const CROSS_SCREEN_TAKEOVER_AD_FIELDS = {
    cstBanner: "cstBanner",
    rightBanner: "rightBanner",
    leftBanner: "leftBanner",
    videoCreativeUrl: "videoCreativeUrl",
    mobileTopbanner: "mobileTopbanner",
    mobileBottombanner: "mobileBottombanner",
    mobileScroller: "mobileScroller",
    impressionTag: "impressionTag",
    dynamicFit: "dynamicFit",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const CUBE_CONFIG = {
    side1: "",
    side2: "",
    side3: "",
    side4: "",
    vast1Duration: '',
    vast2Duration: '',
    vast3Duration: '',
    vast4Duration: '',
    side1Type: false,
    side2Type: false,
    side3Type: false,
    side4Type: false,
    container: "",
    autoRotate: true,
    renderOnlyVastPlayer: false,
    autoRotateInterval: 3500,
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "side1",
        "side2",
        "side3",
        "side4",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        side1: "Cube side 1",
        side2: "Cube side 2",
        side3: "Cube side 3",
        side4: "Cube side 4",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const CUBE_AD_FIELDS = {
    side1: "side1",
    side2: "side2",
    side3: "side3",
    side4: "side4",
    vast1Duration: "vast1Duration",
    vast2Duration: "vast2Duration",
    vast3Duration: "vast3Duration",
    vast4Duration: "vast4Duration",
    side2Type: "side2Type",
    side1Type: "side1Type",
    side3Type: "side3Type",
    side4Type: "side4Type",
    container: "container",
    autoRotate: "autoRotate",
    renderOnlyVastPlayer: "renderOnlyVastPlayer",
    autoRotateInterval: "autoRotateInterval",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const MOBILE_EXPANDABLE_CONFIG = {
    container: "",
    smBanner: "",
    mdBanner: "",
    expandedBanner: "",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "smBanner",
        "mdBanner",
        "expandedBanner",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "smBanner": "Collapsed creative banner URL",
        "mdBanner": "Semi-expanded creative banner URL",
        "expandedBanner": "Expanded creative banner URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const MOBILE_EXPANDABLE_AD_FIELDS = {
    container: "container",
    smBanner: "smBanner",
    mdBanner: "mdBanner",
    expandedBanner: "expandedBanner",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const DESKTOP_SHOPPABLE_BILLBOARD_CONFIG = {
    container: "",
    bannerUrl: "",
    videoCreativeUrl: "",
    thumbImageCreative: "",
    impressionTag: "",
    clickTag: "",
    enableShopButton: false,
    adServingType: "",
    required: [
        "bannerUrl",
        "videoCreativeUrl",
        "thumbImageCreative",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        bannerUrl: "Static banner creative URL",
        videoCreativeUrl: "Video creative URL",
        thumbImageCreative: "Product thumb creative URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS = {
    container: "container",
    bannerUrl: "bannerUrl",
    videoCreativeUrl: "videoCreativeUrl",
    thumbImageCreative: "thumbImageCreative",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    enableShopButton: "enableShopButton",
    adServingType: "adServingType"
};

export const DESKTOP_FOOTER_CONFIG = {
    creativeBg: "#e4deea",
    leftImage: "",
    centerImage: "",
    ctaBgColor: "#000",
    ctaText: "Learn More",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "leftImage",
        "centerImage",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        leftImage: "Left side creative banner URL",
        centerImage: "Center creative banner URL",
        adServingType: "Ad serving type"
    }
};

export const DESKTOP_FOOTER_AD_FIELDS = {
    creativeBg: "creativeBg",
    leftImage: "leftImage",
    centerImage: "centerImage",
    ctaBgColor: "ctaBgColor",
    ctaText: "ctaText",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const MOBILE_FOOTER_CONFIG = {
    displayCreativeUrl: "",
    videoCreativeUrl: "",
    wording: "Swipe up to See More",
    expandedWording: "Swipe down to See Less",
    ctaWording: "",
    ctaTextColor: "#fff",
    ctaBackgroundColor: "#ccc",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "displayCreativeUrl",
        "videoCreativeUrl",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        displayCreativeUrl: "Creative banner URL",
        videoCreativeUrl: "Video creative URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const MOBILE_FOOTER_AD_FIELDS = {
    displayCreativeUrl: "displayCreativeUrl",
    videoCreativeUrl: "videoCreativeUrl",
    wording: "wording",
    expandedWording: "expandedWording",
    ctaWording: "ctaWording",
    ctaTextColor: "ctaTextColor",
    ctaBackgroundColor: "ctaBackgroundColor",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const DISPLAY_SKYBOX_CONFIG = {
    displayCreativeUrl: "",
    collapsedCreaiveUrl: "",
    mobileCreativeUrl: "",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "displayCreativeUrl",
        "collapsedCreaiveUrl",
        "mobileCreativeUrl",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        displayCreativeUrl: "Expanded banner creative URL",
        collapsedCreaiveUrl: "Collapsed banner creative URL",
        mobileCreativeUrl: "Mobile banner creative URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
};

export const DISPLAY_SKYBOX_AD_FIELDS = {
    displayCreativeUrl: "displayCreativeUrl",
    collapsedCreaiveUrl: "collapsedCreaiveUrl",
    mobileCreativeUrl: "mobileCreativeUrl",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const SYNDICATION_CREATIVE_CONFIG = {
    site: "",
    logo: "",
    highContrastLogo: "",
    container: "",
    rssFeedUrl: "",
    syndicationTitle: "",
    tagline: "",
    externalLink: "",
    layout: "horizontal",
    slots: 5,
    impressionTag: "",
    lazyload: false,
    refreshEnabled: true,
    refreshInterval: 30000,
    viewports: 1,
    fetchMarginPercent: 100,
    renderMarginPercent: 10,
    mobileScaling: 1.0,
    syndicationBackgroundColor: "#fff",
    navigationBackgroundColor: "#00000080",
    navigationHoverColor: "#ff0000",
    borderColor: "#ccc",
    format: "default",
    vast: "",
    wrapperUrl: "",
    gamAdUnitPath: "",
    hideArticleTitle: false,
    adServingType: "",
    required: [
        "rssFeedUrl",
        "logo",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        rssFeedUrl: "RSS feed URL",
        logo: "Logo/brand URL",
        adServingType: "Ad serving type"
    }
};

export const SYNDICATION_CREATIVE_AD_FIELDS = {
    site: "site",
    logo: "logo",
    highContrastLogo: "highContrastLogo",
    container: "container",
    rssFeedUrl: "rssFeedUrl",
    syndicationTitle: "syndicationTitle",
    tagline: "tagline",
    externalLink: "externalLink",
    layout: "layout",
    slots: "slots",
    impressionTag: "impressionTag",
    lazyload: "lazyload",
    refreshEnabled: "refreshEnabled",
    refreshInterval: "refreshInterval",
    viewports: "viewports",
    fetchMarginPercent: "fetchMarginPercent",
    renderMarginPercent: "renderMarginPercent",
    mobileScaling: "mobileScaling",
    format: "format",
    vast: "vast",
    wrapperUrl: "wrapperUrl",
    gamAdUnitPath: "gamAdUnitPath",
    syndicationBackgroundColor: "syndicationBackgroundColor",
    navigationBackgroundColor: "navigationBackgroundColor",
    navigationHoverColor: "navigationHoverColor",
    borderColor: "borderColor",
    hideArticleTitle: "hideArticleTitle",
    adServingType: "adServingType"
};

export const ONE_SYNDICATION_CREATIVE_CONFIG = {
    syndicationCreatives: [],
    customers: [],
    defaultSettings: {},
    settings: {},
    format: 'default',
    required: ["syndicationCreatives"],
};

export const ONE_SYNDICATION_CREATIVE_AD_FIELDS = {
    format: "format",
    customers: "customers",
    defaultSettings: "defaultSettings",
    settings: "settings",
    syndicationCreatives: "syndicationCreatives",
};

export const FOOTER_GALLERY_CONFIG = {
    logo1: "",
    logo2: "",
    logo3: "",
    logo4: "",
    slide1: "",
    slide2: "",
    slide3: "",
    slide4: "",
    rightPanelLogo: "",
    rightPanelSecondLogo: "",
    impressionTag: "",
    ctaText: "",
    clickTag: "",
    adServingType: "",
    required: [
        "logo1",
        "logo2",
        "logo3",
        "logo4",
        "slide1",
        "slide2",
        "slide3",
        "slide4",
        "rightPanelLogo",
        "rightPanelSecondLogo",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "logo1": "Logo #1 URL",
        "logo2": "Logo #2 URL",
        "logo3": "Logo #3 URL",
        "logo4": "Logo #4 URL",
        "slide1": "Slide #1 creative URL",
        "slide2": "Slide #2 creative URL",
        "slide3": "Slide #3 creative URL",
        "slide4": "Slide #4 creative URL",
        "rightPanelLogo": "Right panel logo URL",
        "rightPanelSecondLogo": "2nd right panel logo URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const FOOTER_GALLERY_CONFIG_AD_FIELDS = {
    logo1: "logo1",
    logo2: "logo2",
    logo3: "logo3",
    logo4: "logo4",
    slide1: "slide1",
    slide2: "slide2",
    slide3: "slide3",
    slide4: "slide4",
    rightPanelLogo: "rightPanelLogo",
    rightPanelSecondLogo: "rightPanelSecondLogo",
    impressionTag: "impressionTag",
    ctaText: "ctaText",
    clickTag: "clickTag",
    adServingType: "adServingType"
};

export const EBONY_SKIN_CONFIG = {
    right: "",
    left: "",
    topDesktopLeaderboard: "",
    mobileTopImage: "",
    mobileBottomImage: "",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "right", 
        "left", 
        "topDesktopLeaderboard", 
        "mobileTopImage", 
        "mobileBottomImage",
        "adServingType",
        "clickTag"
    ],
    requiredPropertyNameMappings: {
        topDesktopLeaderboard: "Top banner creative URL",
        left: "Left banner creative URL",
        right: "Right banner creative URL",
        mobileTopImage: "Mobile top banner creative URL",
        mobileBottomImage: "mobile bottom banner creative URL",
        adServingType: "Ad serving type",
        clickTag: "Click URL"
    }
};

export const EBONY_SKIN_CONFIG_AD_FIELDS = {
    right: "right",
    left: "left",
    topDesktopLeaderboard: "topDesktopLeaderboard",
    mobileTopImage: "mobileTopImage",
    mobileBottomImage: "mobileBottomImage",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
};

export const LEADERBOARD_PARALLAX_CONFIG = {
    banner: "",
    impressionTag: "",
    clickTag: "",
    adServingType: "",
    required: [
        "banner", 
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        "banner": "Parallax banner creative URL",
        "clickTag": "Click URL",
        "adServingType": "Ad serving type"
    }
};

export const LEADERBOARD_PARALLAX_CONFIG_AD_FIELDS = {
    banner: "banner",
    impressionTag: "impressionTag",
    clickTag: "clickTag",
    adServingType: "adServingType"
}

export const MOBILE_TAKEOVER_CONFIG = {
    adhesionBanner: "",
    container: "",
    desktopCreativeURL: "",
    clickTag: "",
    impressionTag: "",
    vastUrl: "",
    adServingType: "",
    required: [
        "adhesionBanner",
        "container",
        "desktopCreativeURL",
        "clickTag",
        "adServingType"
    ],
    requiredPropertyNameMappings: {
        adhesionBanner: "Mobile adhesion banner URL",
        container: "div/wrapper CSS selector",
        desktopCreativeURL: "Mobile parallax banner URL",
        clickTag: "Click URL",
        adServingType: "Ad serving type"
    }
}

export const MOBILE_TAKEOVER_AD_FIELDS = {
    adhesionBanner: 'adhesionBanner',
    container: 'container',
    desktopCreativeURL: 'desktopCreativeURL',
    clickTag: 'clickTag',
    impressionTag: 'impressionTag',
    vastUrl: "vastUrl",
    adServingType: "adServingType"
}

export const VIDEO_PROPERTY_MAPPINGS = {
    [CREATIVE_NAMES.VIDEO_INTERSTITIAL]: {
        videoProperty: "interstitialVideo",
        tau: "crmc_video",
    },
    [CREATIVE_NAMES.VIDEO_ADHESION]: {
        videoProperty: "collapsibleVideoUrl",
        tau: "crmc_video",
    },
    [CREATIVE_NAMES.VIDEO_PARALLAX]: {
        videoProperty: "videoCreativeURL",
        tau: "crmc_video",
    },
    [CREATIVE_NAMES.IN_BANNER_VIDEO]: {
        tau: "crmc_video",
        videoProperty: "videoCreativeUrl",
    },
    [CREATIVE_NAMES.DESKTOP_INTERACTIVE_BILLBOARD]: {
        tau: "crmc_video",
        videoProperty: "videoCreativeUrl",
    },
    [CREATIVE_NAMES.MOBILE_STICKY_VIDEO]: {
        tau: "crmc_video",
        videoProperty: "videoCreativeUrl",
    },
    [CREATIVE_NAMES.CUBE]: {
        tau1: "crmc_video_cube_1",
        tau2: "crmc_video_cube_2",
        tau3: "crmc_video_cube_3",
        tau4: "crmc_video_cube_4",
    },
    [CREATIVE_NAMES.DESKTOP_SHOPPABLE_BILLBOARD]: {
        tau: "crmc_video",
        videoProperty: "videoCreativeUrl",
    },
};

export const CREATIVES_DEFAULT_DEFINITIONS = {
    [CREATIVE_NAMES.DISPLAY_ADHESION]: DEFAULT_ADHESION_CONFIG,
    [CREATIVE_NAMES.VIDEO_ADHESION]: VIDEO_ADHESION_CONFIG,
    [CREATIVE_NAMES.VIDEO_PARALLAX]: DEFAULT_VIDEO_PARALLAX_CONFIG,
    [CREATIVE_NAMES.DISPLAY_PARALLAX]: DISPLAY_PARALLAX_CONFIG,
    [CREATIVE_NAMES.DISPLAY_INTERSTITIAL]: DISPLAY_INTERSTITIAL_CONFIG,
    [CREATIVE_NAMES.VIDEO_INTERSTITIAL]: VIDEO_POPUP_CONFIG,
    [CREATIVE_NAMES.IN_BANNER_VIDEO]: IN_BANNER_VIDEO_CONFIG,
    [CREATIVE_NAMES.DESKTOP_INTERACTIVE_BILLBOARD]: DESKTOP_INTERACTIVE_BILLBOARD_CONFIG,
    [CREATIVE_NAMES.MOBILE_STICKY_VIDEO]: MOBILE_STICKY_VIDEO_CONFIG,
    [CREATIVE_NAMES.CROSS_SCREEN_TAKEOVER]: CROSS_SCREEN_TAKEOVER_CONFIG,
    [CREATIVE_NAMES.CUBE]: CUBE_CONFIG,
    [CREATIVE_NAMES.MOBILE_EXPANDABLE]: MOBILE_EXPANDABLE_CONFIG,
    [CREATIVE_NAMES.DESKTOP_SHOPPABLE_BILLBOARD]: DESKTOP_SHOPPABLE_BILLBOARD_CONFIG,
    [CREATIVE_NAMES.DESKTOP_FOOTER]: DESKTOP_FOOTER_CONFIG,
    [CREATIVE_NAMES.MOBILE_FOOTER]: MOBILE_FOOTER_CONFIG,
    [CREATIVE_NAMES.DISPLAY_SKYBOX]: DISPLAY_SKYBOX_CONFIG,
    [CREATIVE_NAMES.SYNDICATION_CREATIVE]: SYNDICATION_CREATIVE_CONFIG,
    [CREATIVE_NAMES.FOOTER_GALLERY]: FOOTER_GALLERY_CONFIG,
    [CREATIVE_NAMES.SKIN_CREATIVE]: EBONY_SKIN_CONFIG,
    [CREATIVE_NAMES.MOBILE_TAKEOVER]: MOBILE_TAKEOVER_CONFIG,
    [CREATIVE_NAMES.LEADERBOARD_PARALLAX]: LEADERBOARD_PARALLAX_CONFIG
};

export const GAM_API_MUTATION = gql`
    mutation callCreateGamLineItemMutation(
        $id: String
        $customerName: String
        $campaignName: String
        $creativeType: String
        $startDate: String
        $endDate: String
        $creativeJson: String
        $gamStartDate: String
        $gamEndDate: String
        $impressionGoals: String
        $isTestOrder: Boolean
        $creativeTypeDisplayName: String
        $paymentMethodId: String,
        $isVastCreative: Boolean
    ) {
        createGamLineItemMutation(
            input: {
                id: $id
                customerName: $customerName
                campaignName: $campaignName
                creativeType: $creativeType
                startDate: $startDate
                endDate: $endDate
                creativeJson: $creativeJson
                gamStartDate: $gamStartDate
                gamEndDate: $gamEndDate
                impressionGoals: $impressionGoals
                isTestOrder: $isTestOrder
                creativeTypeDisplayName: $creativeTypeDisplayName
                paymentMethodId: $paymentMethodId
                isVastCreative: $isVastCreative
            }
        ) {
            gamApiResponse
        }
    }
`;

export const EDIT_ORDERS_MUTATION = gql`
    mutation callEditCustomOrdersMutation($id: String, $customerId: String, $data: String, $lineItemId: String, $orderName: String, $startDate: String, $endDate: String, $impressionGoals: String, $paymentMethodId: String, $isVastCreative: Boolean) {
        editCustomOrdersMutation(input: { id: $id, customerId: $customerId, data: $data, lineItemId: $lineItemId, orderName: $orderName, startDate: $startDate, endDate: $endDate, impressionGoals: $impressionGoals, paymentMethodId: $paymentMethodId, isVastCreative: $isVastCreative }) {
            mutationResult
        }
    }
`;

export const STRIPE_SUBSCRIPTION_MUTATION = gql`
    mutation callStripeSubscriptionMutation($customerId: String, $stripeCustomerId: String, $customerName: String) {
        stripeSubscriptionMutation(input: { customerId: $customerId, stripeCustomerId: $stripeCustomerId, customerName: $customerName }) {
            subscriptionResult
        }
    }
`;

export const LINEITEM_STATS_MUTATION = gql`
    mutation callGetLineItemStatsMutation($lineItemIdsList: String) {
        getLineItemStatsMutation(input: { lineItemIdsList: $lineItemIdsList }) {
            gamApiStats
        }
    }
`;

export const SYNDICATION_MUTATION = gql`
    mutation callSyndicationUpdateMutation($feedName: String, $feedUrl: String, $addToFeedList: Boolean) {
        syndicationUpdateMutation(input: { feedName: $feedName, feedUrl: $feedUrl, addToFeedList: $addToFeedList }) {
            syndicationResult
        }
    }
`;

export const STRIPE_CARD_LISTING_MUTATION = gql`
    mutation callStripeCardListMutation($stripeCustomerId: String) {
        stripeCardListMutation(input: { stripeCustomerId: $stripeCustomerId }) {
            cardListingResult
        }
    }
`;

export const SAVE_REVISION_MUTATION = gql`
    mutation callCreateRevisionMutation($postId: String, $userId: String) {
        createRevisionMutation(input: { postId: $postId, userId: $userId }) {
            revisionResult
        }
    }
`;

export const COMMON_CUSTOMER_DATA_UPDATE_MUTATION = gql`
    mutation callUpdateCustomerCommonInfoMutation($customerId: String, $domainId: String, $operation: String) {
        updateCustomerCommonInfoMutation(input: { customerId: $customerId, domainId: $domainId, operation: $operation }) {
            response
        }
    }
`;

export const COMMON_CUSTOMER_DATA_MUTATION = gql`
    mutation callCustomerCommonInfoMutation($customerId: String) {
        customerCommonInfoMutation(input: { customerId: $customerId }) {
            customerInfo
        }
    }
`;

export const EVENT_LOGGER_MUTATION = gql`
    mutation callEventLoggerMutation($post_id: String, $customer_id: String, $url: String, $user_id: String, $data_stream: String, $errors: String) { 
        eventLoggerMutation(input: { post_id: $post_id, customer_id: $customer_id, url: $url, user_id: $user_id, data_stream: $data_stream, errors: $errors }) { 
            response 
        } 
    }
`

export const INSERTION_MODES = [
    { label: "Append", value: CONTAINER_INSERTIION_MODE.APPEND },
    { label: "Insert Before", value: CONTAINER_INSERTIION_MODE.INSERT_BEFORE },
    { label: "Insert After", value: CONTAINER_INSERTIION_MODE.INSERT_AFTER }
];

export const CREATIVE_ASSET_TYPES = [
    { label: 'Video Upload/URL', value: 'video' },
    { label: 'VAST Tag', value: 'vast' }
]