import React from "react";
import AssetInputOrUploadSection from "./AssetInputOrUploadSection";
import { CREATIVE_NAMES, DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS } from "./definitions";
import InputSwitchSection from "./InputSwitchSection";
import InputTextSection from "./InputTextSection";
import BaseCreativeControls from "./BaseCreativeControls";

const CREATIVE_TYPE = CREATIVE_NAMES.DESKTOP_SHOPPABLE_BILLBOARD;

export default function DesktopShoppableBillboard({
    bannerUrl,
    container,
    videoCreativeUrl,
    thumbImageCreative,
    handleChange,
    currentCustomer,
    impressionTag,
    clickTag,
    enableShopButton,
    adServingType
}) {
    return (
        <>
            <BaseCreativeControls
                adServingType={adServingType}
                changeHandler={handleChange}
                clickTag={clickTag}
                impressionTag={impressionTag}
                creativeType={CREATIVE_TYPE}
            >
                <AssetInputOrUploadSection
                    inputValue={bannerUrl}
                    inputLabel="A 525x250 static banner"
                    inputName={DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS.bannerUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={thumbImageCreative}
                    inputLabel="Product thumb image (120x120)"
                    inputName={DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS.thumbImageCreative}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="image/*"
                    defaultExtension="jpeg"
                />
                <AssetInputOrUploadSection
                    inputValue={videoCreativeUrl}
                    inputLabel="Video Creative URL"
                    inputName={DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS.videoCreativeUrl}
                    inputChangeHandler={handleChange}
                    inputAdtype={CREATIVE_TYPE}
                    isRequired={true}
                    currentCustomer={currentCustomer}
                    accept="video/*"
                    defaultExtension="mp4"
                    alternateActionLabel="Or upload a video file"
                />
                <InputSwitchSection
                    label="Display 'Shop' Button?"
                    changeHandler={handleChange}
                    isChecked={enableShopButton}
                    creativeType={CREATIVE_TYPE}
                    switchName={DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS.enableShopButton}
                />
                <InputTextSection
                    label="Billboard container selector (optional)"
                    name={DESKTOP_SHOPPABLE_BILLBOARD_AD_FIELDS.container}
                    value={container}
                    changeHandler={handleChange}
                    adtype={CREATIVE_TYPE}
                    required={false}
                    uriEncodeValue={false}
                />
            </BaseCreativeControls>

        </>
    );
}
