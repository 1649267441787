import React, {Fragment, useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {clearScreenChanges} from "../reducers/screenChangesReducer";
import {Link, NavLink, useLocation, useParams} from "react-router-dom";
import {gql, useMutation} from "@apollo/client";

const DOCUMENTATION_MUTATION = gql`
  mutation callDocumentationMutation($id: String) {
    documentationMutation(input: {id: $id}) {
        clientMutationId
        info
        orders
    }
  }
`

const sortByOrder = (arr, order) => {
    const sortArr = arr.sort((a, b)=>{
        const indexA = order.indexOf(a.id);
        const indexB = order.indexOf(b.id);
        return indexA - indexB;
    });

    return sortArr;
}

const DocumentationListMenu = () => {
    const { userID } = useSelector((state) => state.userInformation);
    const [isDocPath, setDocPath] = useState(false);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [mutationData, setMutationData] = useState(false);
    const [loading, setLoading] = useState(false);

    const { pathname, key} = useLocation();
    const { postId } = useParams();

    const [DOCUMENTATION_MUTATION_CONTAINER, { loading: mutationLoading, data }] = useMutation(DOCUMENTATION_MUTATION, {
            onCompleted(data) {
                confirm(data);
            }
        }
    );


    useEffect(() => {

        let isDocPathFound = pathname.match('documentation')?.length;

        isDocPathFound && DOCUMENTATION_MUTATION_CONTAINER({
            variables: { id: '' }
        });

        setDocPath(isDocPathFound);
        setLoading(isDocPathFound);

    }, [pathname, postId]);

    const confirm = data => {
        const temp_data = JSON.parse(data.documentationMutation.info);
        const order = data.documentationMutation.orders.split(',');

        const sorted_temp_data = sortByOrder(temp_data.slice(), order)

        //console.log({temp_data, sorted_temp_data});

        setMutationData(sorted_temp_data);
        setMutationDataCompleted(true);
        setLoading(false);
    }

    return (
        <ul className="layout-menu">
            {
                mutationDataCompleted &&
                isDocPath &&
                mutationData !== null &&
                mutationData.map((item) => {
                    return (
                        <li key={item.id} className={postId === item.id ? `active-menuitem` : ""}>
                            <NavLink to={`/documentationcontent/${item.id}`}>
                                <span>
                                    {item.title} {loading && postId === item.id ? <FontAwesomeIcon icon={faSpinner} spin/>: <>&nbsp;&nbsp;&nbsp;</>}
                                </span>
                            </NavLink>
                        </li>
                    )
                })
            }

        </ul>
    );
};

export default DocumentationListMenu;
