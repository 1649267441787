import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { gql, useMutation } from '@apollo/client';
import Partner from './Partner';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentDomain, resetCurrentPreset, setCurrentCustomer, setNavigationMode } from '../reducers/userLoginReducer';
import { getIn } from '../utilities/utils';

const PARTNER_MUTATION = gql`
  mutation callpartnerInfoMutation($id: String, $role: String) {
    partnerInfoMutation(input: {id: $id, role: $role}) {
        clientMutationId
        partnerName
        customerInfo
    }
  }
`

const CustomerInfo = (props) => {
    const dispatch = useDispatch()
    const { currentCustomer } = useSelector(state => state.userInformation)
    const [mutationData, setMutationData] = useState(null)
    const [PARTNER_MUTATION_CONTAINER] = useMutation(
        PARTNER_MUTATION,
        {
            onCompleted(data) {
		confirm(data)
            }
        }
    );

    useEffect(() => {
        dispatch(resetCurrentPreset())
        dispatch(resetCurrentDomain())
        dispatch(setNavigationMode('domain'))
        PARTNER_MUTATION_CONTAINER({
            variables: { id: currentCustomer, role: 'administrator' }
        })
    }, [currentCustomer])


    const confirm = data => {
	let customerInfo = JSON.parse(getIn(data, 'partnerInfoMutation', 'customerInfo'))
	customerInfo.sort((c1, c2) => c1.title.localeCompare(c2.title))
	const assignedClients = JSON.parse(localStorage.getItem('assignedClients')) || []
	if (assignedClients.length >= 2) {
	    customerInfo = customerInfo.filter(({id}) => assignedClients.includes(id))
	}
        setMutationData(customerInfo);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-globe" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} label="Domains" />
            </div>
        );
    }

    const editProduct = (product) => {
        dispatch(setCurrentCustomer(product.id))
        props.history.push("/domains")
    }

    return (
        <>
            <Partner {...props} />
            <div className="layout-main">
                {mutationData && (
                    <DataTable value={mutationData}>
                        <Column field="id" header="ID" className='p-d-none'></Column>
                        <Column field="title" header="Customer"></Column>
                        <Column field="type" header="Type"></Column>
                        <Column field="domains" header="Domains"></Column>
                        <Column field="users" header="Users"></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                )}
            </div>
        </>
    )
}

export default CustomerInfo;
