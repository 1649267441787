import { useMutation } from "@apollo/client";
import { SAVE_NATIVE_AD_INJECTION_MUTATION } from "../mutations/mutations";

export function useNativeInjectionSave(options = {}) {
    const [container] = useMutation(SAVE_NATIVE_AD_INJECTION_MUTATION, options)

    return ({
        domainId,
        injectionType,
        injectionValueList,
        mode
    }) => {
        let variables = {
            id: domainId && String(domainId),
            injectionType,
            injectionValueList,
            mode
        }

        for (let p in variables) {
            let v = variables[p]
            if (v === null || v === undefined) {
                delete variables[p]
            }
        }

        return container({
            variables
        })
    }
}