/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext } from "react";
import MarketplaceOrderDataContext from "../../../context/marketplaceOrderDataContext";
import OptionList from "./OptionList";
import OrderRow from "./OrderRow";

const accordionStyles = css`
    .targeting-tab > a {
        background-color: #fff !important;
        padding-left: 0.5em;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        min-height: 36px !important;
        border-top: 1px solid rgb(222, 226, 230) !important;
    }
    .targeting-tab > a > .p-accordion-header-text {
        font-weight: 400;
        color: #1b1e20;
    }
`;

const GeoTargeting = ({
    handleSelection,
    orderRowName,
    orderRowLabel,
    orderRowDescription
}) => {
    const { currentOrderIdx: idx } = useContext(MarketplaceOrderDataContext)

    return (
        <OrderRow idx={idx} name={orderRowName} label={orderRowLabel} description={orderRowDescription}>
            <Accordion css={accordionStyles}>
                <AccordionTab headerClassName="targeting-tab" header={"Geo Targets"} disabled={false}>
                    <OptionList handleSelection={handleSelection} />
                </AccordionTab>
            </Accordion>
        </OrderRow>
    );
};

export default GeoTargeting;
