import { InputText } from 'primereact/inputtext';
const AdSettingsInput = (props) => {
  return (
    <div className="p-grid small-margin-bottom-10" style={{display: props.display}}>
      <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
        {!props.tooltip &&
          <label htmlFor={props.labelfor}>{props.label}</label>
        }
        {props.tooltip &&
          <label className="adpositiontooltip after" data-pr-tooltip={props.tooltip} htmlFor={props.labelfor}>{props.label}</label>
        }
        {props.required &&
          <label style={{ color: 'red' }}>*</label>
        }
        
      </div>
      <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
        <InputText 
          id={props.labelfor} 
          name={props.labelfor} 
          value={props.value} 
          placeholder={props.placeholder}
          onChange={props.changeEvent}
          disabled={!!props.disabled}
          style={{
            backgroundColor: !!props.disabled ? '#ccc' : 'unset'
          }}
        />
      </div>
    </div>
  );
}
export default AdSettingsInput;
