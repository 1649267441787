import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "./helper/SectionHeader";
import LayoutWrapper from "./LayoutWrapper";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { InputText } from "primereact/inputtext";
import { registerScreenChanges } from "../reducers/screenChangesReducer";
import { gql, useMutation } from "@apollo/client";
import { pushMessage } from "../reducers/messagingReducer";

const THIRD_PARTY_INTEGRATION_MUTATION = gql`
    mutation callCatapultXInfoMutation(
        $domainId: String,
        $isEnabled: Boolean,
        $isInstareadEnabled: Boolean,
        $operation: String,
        $groupId: String,
        $isComscoreEnabled: Boolean,
        $comscorePath: String,
        $isLyticsEnabled: Boolean,
        $lyticsAccountId: String,
        $isOptimeraEnabled: Boolean,
        $optimeraClientId: String,
        $isIasEnabled: Boolean,
        $iasClientId: String
    ) {
        catapultXInfoMutation(
            input: {
                domainId: $domainId,
                isEnabled: $isEnabled,
                isInstareadEnabled: $isInstareadEnabled,
                operation: $operation,
                groupId: $groupId,
                isComscoreEnabled: $isComscoreEnabled,
                comscorePath: $comscorePath,
                isLyticsEnabled: $isLyticsEnabled,
                lyticsAccountId: $lyticsAccountId,
                isOptimeraEnabled: $isOptimeraEnabled,
                optimeraClientId: $optimeraClientId,
                isIasEnabled: $isIasEnabled,
                iasClientId: $iasClientId
            }
        ) {
            catapultXInfo
        }
    }
`;

export default function CatapultX({ }) {
    const dispatch = useDispatch();
    const [isCatapultXEnabled, setCatapultXStatus] = useState(false);
    const [isInstareadEnabled, setInstareadStatus] = useState(false);
    const [isComscoreEnabled, setComscoreStatus] = useState(false);
    const [isLyticsEnabled, setLyticsStatus] = useState(false);
    const [lyticsAccountId, setLyticsAccountId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [comscorePath, setComscorePath] = useState("");
    const [isOptimeraEnabled, setOptimeraStatus] = useState(false);
    const [optimeraClientId, setOptimeraClientId] = useState("");
    const [isIasEnabled, setIasStatus] = useState(false);
    const [iasClientId, setIasClientId] = useState("");
    const [catapultXInfoMutation] = useMutation(THIRD_PARTY_INTEGRATION_MUTATION);
    const { currentDomain } = useSelector((state) => state.userInformation);


    useEffect(() => {
        if (currentDomain) {
            catapultXInfoMutation({
                variables: {
                    domainId: currentDomain,
                    operation: "read",
                },
            })
                .then((response) => {
                    let data = JSON.parse(response.data?.catapultXInfoMutation.catapultXInfo || null) || {};
                    let { isEnabled, groupId, isInstareadEnabled, isComscoreEnabled, comscorePath } = data;
                    setCatapultXStatus(isEnabled);
                    setInstareadStatus(isInstareadEnabled);
                    setComscoreStatus(isComscoreEnabled);
                    setGroupId(groupId);
                    setComscorePath(comscorePath);
                    setLyticsStatus(data.isLyticsEnabled);
                    setLyticsAccountId(data.lytics_accid);
                    setOptimeraStatus(data.isOptimeraEnabled);
                    setOptimeraClientId(data.optimera_client_id);
                    setIasStatus(data.isIasEnabled);
                    setIasClientId(data.iasClientId);
                })
                .catch((err) => {
                    dispatch(pushMessage("Third party integrations", err.message, "error"));
                });
        }
    }, [currentDomain]);

    async function saveCatapultXInfo() {
        if (isCatapultXEnabled && !groupId.trim().length) {
            dispatch(pushMessage("Third party integrations", "Group ID for CatapultX must be specified", "error"));
            throw new Error("Group ID for CatapultX must be specified");
        }
        if (isComscoreEnabled && !comscorePath.trim().length) {
            dispatch(pushMessage("Third party integrations", "Path Id for Comscore must be specified", "error"));
            throw new Error("Path Id for Comscore must be specified");
        }
        if (isOptimeraEnabled && !optimeraClientId.trim().length) {
            dispatch(pushMessage("Third party integrations", "Client ID for Optimera must be specified", "error"));
            throw new Error("Client Id for Optimera must be specified");
        }
        if (isIasEnabled && !iasClientId.trim().length) {
            dispatch(pushMessage("Third party integrations", "Client ID for IAS must be specified", "error"));
            throw new Error("Client Id for IAS must be specified");
        }
        dispatch(pushMessage("Third party integrations", "Saving Extensions info"));
        return catapultXInfoMutation({ variables: { domainId: currentDomain, operation: "write", isEnabled: isCatapultXEnabled, isInstareadEnabled: isInstareadEnabled, groupId, comscorePath, isComscoreEnabled: isComscoreEnabled, isLyticsEnabled, lyticsAccountId, isOptimeraEnabled, optimeraClientId, isIasEnabled, iasClientId } });
    }

    return (
        <LayoutWrapper model="domain" saveCallback={saveCatapultXInfo}>
            <div className="p-grid">
                <div className="small-full-width p-col p-col-content">
                    <div className="card">
                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>CatapultX</strong>
                                    <InputSwitch
                                        checked={isCatapultXEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setCatapultXStatus(!isCatapultXEnabled);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                            {isCatapultXEnabled && (
                                <div className="card p-panel-content">
                                    <div className="p-fluid border-bottom-none">
                                        <div className="p-grid small-margin-bottom-10">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                <label htmlFor="group_id">Group ID</label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                <InputText
                                                    id=""
                                                    name="group_id"
                                                    value={groupId}
                                                    onChange={(e) => {
                                                        setGroupId(e.target.value);
                                                        dispatch(registerScreenChanges("CatapultX"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>{isInstareadEnabled ? "Disable Instaread" : "Enable Instaread"}</strong>
                                    <InputSwitch
                                        checked={isInstareadEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setInstareadStatus(!isInstareadEnabled);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                        </div>
                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>Comscore</strong>
                                    <InputSwitch
                                        checked={isComscoreEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setComscoreStatus(!isComscoreEnabled);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                            {isComscoreEnabled && (
                                <div className="card p-panel-content">
                                    <div className="p-fluid border-bottom-none">
                                        <div className="p-grid small-margin-bottom-10">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                <label htmlFor="group_id">Path Id</label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                <InputText
                                                    id=""
                                                    name="comscore_path"
                                                    value={comscorePath}
                                                    onChange={(e) => {
                                                        setComscorePath(e.target.value);
                                                        dispatch(registerScreenChanges("CatapultX"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>Lytics</strong>
                                    <InputSwitch
                                        checked={isLyticsEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setLyticsStatus(!isLyticsEnabled);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                            {isLyticsEnabled && (
                                <div className="card p-panel-content">
                                    <div className="p-fluid border-bottom-none">
                                        <div className="p-grid small-margin-bottom-10">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                <label htmlFor="group_id">Lytics Account Id</label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                <InputText
                                                    id=""
                                                    name="lytics_account_id"
                                                    value={lyticsAccountId}
                                                    onChange={(e) => {
                                                        setLyticsAccountId(e.target.value);
                                                        dispatch(registerScreenChanges("CatapultX"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>Optimera</strong>
                                    <InputSwitch
                                        checked={isOptimeraEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setOptimeraStatus(!isOptimeraEnabled);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                            {isOptimeraEnabled && (
                                <div className="card p-panel-content">
                                    <div className="p-fluid border-bottom-none">
                                        <div className="p-grid small-margin-bottom-10">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                <label htmlFor="optimera_client_id">Optimera Client Id</label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                <InputText
                                                    id=""
                                                    name="optimera_client_id"
                                                    value={optimeraClientId}
                                                    onChange={(e) => {
                                                        setOptimeraClientId(e.target.value);
                                                        dispatch(registerScreenChanges("CatapultX"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-panel p-component mb-1">
                            <SectionHeader>
                                <div
                                    css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        flex-grow: 1;
                                    `}
                                >
                                    <strong>Integral Ad Science (IAS)</strong>
                                    <InputSwitch
                                        checked={isIasEnabled}
                                        onChange={() => {
                                            dispatch(registerScreenChanges("CatapultX"));
                                            setIasStatus(prevState => !prevState);
                                        }}
                                    />
                                </div>
                            </SectionHeader>
                            {isIasEnabled && (
                                <div className="card p-panel-content">
                                    <div className="p-fluid border-bottom-none">
                                        <div className="p-grid small-margin-bottom-10">
                                            <div className="p-col-12 p-sm-12 p-md-3 p-lg-3 p-mt-2 small-margin-top-0 small-padding-bottom-0">
                                                <label htmlFor="optimera_client_id">Client Id</label>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-9 p-lg-9">
                                                <InputText
                                                    name="ias_client_id"
                                                    value={iasClientId}
                                                    onChange={(e) => {
                                                        setIasClientId(e.target.value);
                                                        dispatch(registerScreenChanges("CatapultX"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </LayoutWrapper>
    );
}
