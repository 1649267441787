import React, { useState, useEffect } from "react";
import { CREATIVE_NAMES, ONE_SYNDICATION_CREATIVE_AD_FIELDS } from "./definitions";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection"
import { useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog';
import Cta from "../marketplace/CTA";
import { faCircleCheck, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { gql, useMutation } from "@apollo/client";
import {InputSwitch} from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SYNDICATION_CREATIVES_MUTATION = gql`
    mutation callSyndicationCreativesMutation($customerIds: String, $syndicationFormat: String) {
        syndicationCreativesMutation(input: { customerIds: $customerIds, syndicationFormat: $syndicationFormat }) {
            syndicationCreatives
        }
    }
`;

export const ONE_SYNDICATION_MUTATION = gql`
    mutation callGetOneSyndicationMutation($id: String) {
        getOneSyndicationMutation(input: { id: $id }) {
            oneSyndicationDataPoint
        }
    }
`;

const CREATIVE_TYPE = CREATIVE_NAMES.ONE_SYNDICATION;

const FORMAT_OPTIONS = [
    { name: "Responsive", value: "default" },
    { name: "970x250 banner", value: "banner" },
    { name: "300x600 right-rail", value: "rail" },
];

const DEMOGRAPHICS_OPTIONS = [
    {name: 'Black', value: 'black_or_african_american'},
    {name: 'White', value: 'white'},
    {name: 'Asian', value: 'asian'},
    {name: "Hispanic/latino", value: 'hispanic_or_latino'}
];

const EDUCATION_LEVEL_OPTIONS = [
    {name: "High school graduate", value: "high_school_graduate"},
    {name: "Bachelors degree", value: "bachelors_degree"},
    {name: "Graduate", value: "graduate"}
]

const selectionPopupStyles = css`
    display: flex;
    align-items: center;
    .flex-align-center {
        align-items: center;
    }
    .title {
        margin-bottom: 0;
    }
    .search {
        display: flex;
        align-items: flex-bottom;
        margin-left: 3em;
        .search-label {
            font-size: 13px;
            font-weight: normal;

        }
        .input-search {
            margin-left: 1em;
            border: none;
            border-bottom: 1px solid #ddd;
            font-size: 13px;
            padding: 0;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    .toggle-selected {
        span.title {
            font-size: 12px; 
            font-weight: 500;
            margin-left: 2em;
            margin-right: 1em;
            color: #666;   
        }
    }
`

const accordionStyles = css`
    .flex-align-center {
        align-items: center;
    }
    .header-info {
        display: flex;
        flex-direction: column;
        .selector {
            margin-left: auto;
        }
        .creative-name {
            margin-bottom: 0.25em;
        }
        .item {
            margin-right: 1em;
            font-size: 14px;
            &.pill {
                background-color: #333;
                padding: 3px;
                color: #FFF;
                border-radius: 3px;
            }
        }
        .item-value {
            display: inline-block;
            margin-left: 0.25em;
            font-weight: normal;
        }
    }
    .creative-details {
        display: flex;
        align-items: center;
        .item {
            font-size: 12px;
        }
    }
    .selector {
        margin-left: auto;
    }
    .p-accordion-tab {
        margin-bottom: 0.75em;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        border-color: none !important;
        box-shadow: none !important;
    }
`

const CustomerControls = ({
    customers,
    customerMappings,
    handleChange,
    defaultSettings,
    creativeId = '',
    disabled = false,
    isGlobalSetting = true
}) => {
    if (customers?.length) {
        return (
            <div css={css`
                .p-dropdown.p-component {
                    width: 100%;
                }
                .customer-targeting-section {
                    padding: 0.5em;
                    background-color: #f4f4f4;
                    border: 1px solid #ccc;
                    border-bottom: 1px solid #000;
                    margin-bottom: 1em;
                }
            `}>
                {
                    customers.map(customerId => {
                        let lookupProp = isGlobalSetting ? customerId : creativeId
                        return (
                            <div key={customerId} className="customer-targeting-section">
                                <DropdownSection 
                                    disabled={disabled}
                                    label={customerMappings[customerId] + " demographics"}
                                    placeholder="Select a demographic"
                                    optionLabel="name"
                                    optionValue="value"
                                    options={DEMOGRAPHICS_OPTIONS}
                                    adtype={CREATIVE_TYPE}
                                    value={defaultSettings?.[lookupProp]?.demographics || ''}
                                    changeHandler={ev => {
                                        isGlobalSetting 
                                            ? handleChange({customerId, settingName: "demographics", settingValue: ev.value})
                                            : handleChange({creativeId, settingName: "demographics", settingValue: ev.value})
                                        
                                    }}
                                />
                                <InputTextSection 
                                    label="Demographics percentage" 
                                    placeHolder="Enter a demographics percentage"
                                    value={defaultSettings?.[lookupProp]?.percentage}
                                    max={100}
                                    disabled={disabled}
                                    isNumericInput={true} changeHandler={ev => {
                                        isGlobalSetting
                                            ? handleChange({customerId, settingName: "percentage", settingValue: ev.value})
                                            : handleChange({creativeId, settingName: "percentage", settingValue: ev.value})
                                    }} 
                                />
                                <DropdownSection 
                                    disabled={disabled}
                                    label={"Level of education"}
                                    placeholder="Select a level of education"
                                    optionLabel="name"
                                    optionValue="value"
                                    options={EDUCATION_LEVEL_OPTIONS}
                                    adtype={CREATIVE_TYPE}
                                    value={defaultSettings?.[lookupProp]?.education || ''}
                                    changeHandler={ev => {
                                        isGlobalSetting 
                                            ? handleChange({customerId, settingName: "education", settingValue: ev.value})
                                            : handleChange({creativeId, settingName: "education", settingValue: ev.value})
                                        
                                    }}
                                />
                                <InputTextSection 
                                    label="Education percentage"
                                    placeHolder="Enter an education percentage"
                                    value={defaultSettings?.[lookupProp]?.educationPercentage}
                                    max={100}
                                    disabled={disabled}
                                    isNumericInput={true}
                                    changeHandler={ev => {
                                        isGlobalSetting
                                            ? handleChange({customerId, settingName: "educationPercentage", settingValue: ev.value})
                                            : handleChange({creativeId, settingName: "educationPercentage", settingValue: ev.value})
                                    }} 
                                />
                                <InputTextSection 
                                    label="Median income"
                                    placeHolder="Enter a median income"
                                    disabled={disabled}
                                    value={defaultSettings?.[lookupProp]?.median_household_income}
                                    isNumericInput={true} 
                                    changeHandler={ev => {
                                        isGlobalSetting
                                            ? handleChange({customerId, settingName: "median_household_income", settingValue: ev.value})
                                            : handleChange({creativeId, settingName: "median_household_income", settingValue: ev.value})
                                    }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return null;
}
export default function OneSyndicationCreative({
    format,
    syndicationCreatives,
    customers,
    currentCustomer,
    defaultSettings,
    settings,
    handleChange,
    handleChanges
}) {
    const { customers: existingCustomers } = useSelector((state) => state.userInformation);
    const [existingCreatives, setExistingCreatives] = useState([])
    const [isRequestingCreatives, setIsRequestingCreatives] = useState(false);
    const [isVisible, setIsVisible] = useState(false)
    const [requestCreatives] = useMutation(SYNDICATION_CREATIVES_MUTATION);
    const [oneSyndicationMutation] = useMutation(ONE_SYNDICATION_MUTATION)
    const [currentlySelectedCreatives, setCurrentlySelectedCreatives] = useState(new Set())
    const [customerMappings, setCustomerMappings] = useState({})
    const [searchString, setSearchString] = useState('')
    const [searchCriteria, setSearchCriteria] = useState('')
    const [timerId, setTimerId] = useState(undefined)
    const [showOnlySelected, setShowOnlySelected] = useState(false)

    function handleGlobalSettingChange({
        customerId,
        settingName,
        settingValue
    }) {
        let settingsCopy = {...defaultSettings}
        settingsCopy[customerId] = settingsCopy[customerId] || {}
        settingsCopy[customerId][settingName] = settingValue
        handleChange({target: {name: ONE_SYNDICATION_CREATIVE_AD_FIELDS.defaultSettings}}, settingsCopy)
    }

    function handleLocalSettingOverride({
        creativeId,
        settingName,
        settingValue,
    }) {
        let settingsCopy = {...settings}
        settingsCopy[creativeId] = settingsCopy[creativeId] || {}
        settingsCopy[creativeId][settingName] = settingValue
        handleChange({target: {name: ONE_SYNDICATION_CREATIVE_AD_FIELDS.settings}}, settingsCopy)
    }

    useEffect(() => {
        oneSyndicationMutation({
            variables: {id: ''}
        }).then(response => {
            let data = response.data
            let dataPoints = JSON.parse(data.getOneSyndicationMutation.oneSyndicationDataPoint)
            setCustomerMappings(dataPoints.customer_ids_mappings)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (customers && customers.length) {
            let selectedCustomers = new Set(customers.map(item => parseInt(item)));
            if (syndicationCreatives && syndicationCreatives.length) {
                let creatives = syndicationCreatives.filter(item => selectedCustomers.has(item.customerId))
                handleChange(
                    {target: {name: ONE_SYNDICATION_CREATIVE_AD_FIELDS.syndicationCreatives}},
                    creatives
                )
            }
            let defaultSettingsCopy = {...defaultSettings}
            customers.forEach(customer => {
                if (!defaultSettingsCopy[customer]) {
                    delete defaultSettingsCopy[customer]
                }
            })
            handleChange({target: {name: ONE_SYNDICATION_CREATIVE_AD_FIELDS.defaultSettings}}, defaultSettingsCopy)
        } else {
            setCurrentlySelectedCreatives(new Set())
            handleChanges({
                [ONE_SYNDICATION_CREATIVE_AD_FIELDS.syndicationCreatives]: [],
                [ONE_SYNDICATION_CREATIVE_AD_FIELDS.defaultSettings]: {},
                [ONE_SYNDICATION_CREATIVE_AD_FIELDS.settings]: {}
            })
        }
    }, [customers])

    async function requestSyndicationCreatives() {
        try {
            let previouslySelectedCreatives = new Set();
            let cs = syndicationCreatives || [];
            for (let {id} of cs.values()) {
                previouslySelectedCreatives.add(id)
            }
            setCurrentlySelectedCreatives(previouslySelectedCreatives)

            setIsRequestingCreatives(true)
            let response = await requestCreatives({
                variables: {
                    customerIds: JSON.stringify(customers),
                    syndicationFormat: format || 'default'
                }
            })
            let creatives = JSON.parse(response.data.syndicationCreativesMutation.syndicationCreatives)
            let creativesList = creatives.creatives || []
            setIsVisible(true)
            setExistingCreatives(creativesList)
        } catch (err) {
            console.log(err)
            setIsVisible(false)
            setIsRequestingCreatives(false)
            setExistingCreatives([])
        }
    }

    function handleCreativeSelection(target, value, selection) {
        if (selection) {
            setCurrentlySelectedCreatives(new Set([...currentlySelectedCreatives, value.id]))
            handleChange(
                target,
                !syndicationCreatives ? [value] : [...syndicationCreatives, value]
            )
        } else {
            let copy = new Set([...currentlySelectedCreatives])
            let settingsCopy = {...settings}
            copy.delete(value.id)
            setCurrentlySelectedCreatives(copy)
            
            if (value.id in settingsCopy) {
                delete settingsCopy[value.id]
            }

            handleChanges({
                [ONE_SYNDICATION_CREATIVE_AD_FIELDS.syndicationCreatives]: syndicationCreatives?.length === 1 ? [] : syndicationCreatives?.filter(item => item.id !== value.id),
                [ONE_SYNDICATION_CREATIVE_AD_FIELDS.settings]: settingsCopy
            })
        }
    }

    function handleSearchCriteriaChange() {
        if (timerId) {
            window.clearTimeout(timerId)
        }
        let id = setTimeout(() => {
            setSearchCriteria(searchString)
        }, 750)
        setTimerId(id)
    }

    useEffect(() => {
        handleSearchCriteriaChange()
    }, [searchString])

    function criteriaSearchPredicate(...args) {
        return searchCriteria.length >= 3
            ? args.some(arg => arg.includes(searchCriteria))
            : true
    }

    return (
        <>
            <Dialog 
                header={
                    <div css={selectionPopupStyles}>
                        <h3 className="title">Syndication creatives</h3>
                        <div className="flex">
                            <section className="search">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                <InputText
                                    className="input-search"
                                    value={searchString}
                                    placeholder="Search creative list"
                                    onChange={ev => {
                                        setSearchString(ev.target.value)
                                    }}
                                />
                            </section>
                            <section className="toggle-selected flex flex-align-center">
                                <span className="title">Show selected creatives:</span>
                                <InputSwitch checked={showOnlySelected} onChange={() => setShowOnlySelected(!showOnlySelected)} />
                            </section>
                        </div>
                    </div>
                }
                visible={isVisible} 
                style={{width: '75vw'}} 
                onHide={() => {
                    setSearchString('')
                    setSearchCriteria('')
                    setIsVisible(false)
                    setIsRequestingCreatives(false)
                }}
            >
                <Accordion css={accordionStyles}>
                    {
                        existingCreatives
                            .filter(creativeItem => {
                                try {
                                    let {name = '', customerName = '', id = ''} = creativeItem;
                                    if (showOnlySelected) {
                                        return currentlySelectedCreatives.has(id) && criteriaSearchPredicate(name, customerName, id)
                                    }
                                    return criteriaSearchPredicate(name, customerName, id)
                                } catch {
                                    return true
                                }
                            })
                            .map(creativeData => {
                                let {id, customerId, customerName, format, name: creativeName} = creativeData
                                
                                return (
                                    <AccordionTab key={id} headerClassName="creative-row" contentClassName="creative-row" header={
                                        <div className="flex flex-align-center">
                                            <div className="header-info">
                                                <div class="creative-name">
                                                    <span className="item-name">Creative name:</span>
                                                    <span className="item-value">{creativeName}</span>
                                                </div>
                                                <div className="creative-details">
                                                    <div class="item">
                                                        <span className="item-name">Creative ID:</span>
                                                        <span className="item-value">{id}</span>
                                                    </div>
                                                    <div class="item">
                                                        <span className="item-name">Creative format:</span>
                                                        <span className="item-value">{format}</span>
                                                    </div>
                                                    <div class="item pill">
                                                        <span className="item-name">Customer name:</span>
                                                        <span className="item-value">{customerName}</span>    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="selector">
                                                <InputSwitch
                                                    checked={currentlySelectedCreatives.has(id)}
                                                    onChange={(ev) => {
                                                        ev.originalEvent.stopPropagation()
                                                        handleCreativeSelection(
                                                            {target: {name: ONE_SYNDICATION_CREATIVE_AD_FIELDS.syndicationCreatives}},
                                                            {customerId, id},
                                                            !currentlySelectedCreatives.has(id)
                                                        )
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    }>
                                        <CustomerControls
                                            disabled={!currentlySelectedCreatives.has(id)}
                                            customers={[customerId]}
                                            customerMappings={customerMappings}
                                            defaultSettings={settings}
                                            isGlobalSetting={false}
                                            handleChange={handleLocalSettingOverride}
                                            creativeId={id}
                                        />
                                    </AccordionTab>
                                )
                            })
                    }
                </Accordion>
            </Dialog>
            <div css={css`
                label {
                    font-weight: 600;
                    margin-bottom: 5px;
                    display: block;
                    position: relative;
                }
                .p-dropdown.p-component {
                    width: 100%;
                }
                margin-bottom: 10px;
            `}>
                <label>Format</label>
                <Dropdown
                    optionLabel="name"
                    optionValue="value"
                    value={format || FORMAT_OPTIONS[0].value}
                    options={FORMAT_OPTIONS}
                    onChange={ev => {
                        handleChanges({
                            [ONE_SYNDICATION_CREATIVE_AD_FIELDS.settings]: {},
                            [ONE_SYNDICATION_CREATIVE_AD_FIELDS.syndicationCreatives]: [],
                            [ONE_SYNDICATION_CREATIVE_AD_FIELDS.format]: ev.value
                        })
                    }}
                />
            </div>
            <DropdownSection 
                adtype={CREATIVE_TYPE} 
                label="Customers" 
                name={ONE_SYNDICATION_CREATIVE_AD_FIELDS.customers} 
                changeHandler={handleChange} 
                value={customers} 
                options={existingCustomers} 
                optionLabel="name"
                optionValue="value"
                multiSelect={true}
            />
            <div css={css`
                button {
                    width: 100%;
                    margin-bottom: 10px;
                }
                label {
                    font-weight: 600;
                    margin-bottom: 5px;
                    display: block;
                    position: relative;
                }
            `}>
                <CustomerControls
                    customers={customers}
                    customerMappings={customerMappings}
                    defaultSettings={defaultSettings}
                    handleChange={handleGlobalSettingChange}
                />
                <Cta 
                    ctaText={isRequestingCreatives 
                        ? "Requesting creatives"
                        : "Select creatives"
                    }
                    themeClasses="marketplace-cta outlined default" 
                    icon={faCircleCheck}
                    showLoader={isRequestingCreatives}
                    disabled={isRequestingCreatives || !customers || !customers.length}
                    onClick={async () => {
                        await requestSyndicationCreatives(currentCustomer)
                    }}
                />
            </div>
            
            
        </>
    );
}
