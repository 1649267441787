/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import OrderRow from "./OrderRow";
import { InputTextarea } from "primereact/inputtextarea";
import MarketplacePreviewLink from "./MarketplacePreviewLink";
import { Base64 } from "js-base64";
import { colors } from "./constants";

let ALLOWED_SIZES = [
    { name: "970x250", value: "970x250" },
    { name: "970x90", value: "970x90" },
    { name: "728x90", value: "728x90" },
    { name: "320x50", value: "320x50" },
    { name: "300x250", value: "300x250" },
    { name: "300x600", value: "300x600" },
    { name: "160x600", value: "160x600" },
    { name: "1x1", value: "1x1" },
];

const noticeStyles = css`
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #037708;
    background-color: #fffbdc;
    padding: 3px 6px;
    border-radius: 3px;
`;

const orderRowStyles = css`
    margin-top: 1em;
    margin-bottom: 1em;
    .add-3pt {
        background-color: #2196f3;
        border-color: #2196f3;
        margin-top: 1em;
        max-width: 200px;
        display: block;
    }
    .remove-creative {
        cursor: pointer;
        background-color: #ff0000;
        color: #fff;
        top: 0;
        right: 0;
        padding: 0.5em;
        font-size: 1em;
        font-weight: bold;
        margin-left: auto;
    }
`;

const creativeContainerStyles = css`
    position: relative;
    display: flex;
    margin-top: 0.5em;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 2px dashed #ccc;
    .controls {
        flex-basis: 95%;
        margin-bottom: 1em;
    }
    .select-buttons {
        margin-bottom: 1em;
        .p-selectbutton > .p-button {
            margin-left: 0.5em;
            margin-right: 0.5em;
            border: 1px solid #666;
            border-radius: 12px;
            max-width: 100px;
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    iframe {
        border: none;
    }
`;

const ThirdPartyCreativesRow = ({
    changeHandler,
    idx,
    label,
    icon,
    addNewThirdPartyTagRow,
    removeThirdPartyTagRow,
    creativesCollection = [],
    allowedSizes,
    disabled = false
}) => {
    const options = Array.isArray(allowedSizes)
        ? allowedSizes.map(size => ({name: size, value: size}))
        : ALLOWED_SIZES
    return (
        <OrderRow idx={idx} label={label} name="Third Party Creative Type" icon={icon} lg={true}>
            <div css={orderRowStyles}>
                {creativesCollection.length
                    ? creativesCollection.map((thirdPartyTag, i) => {
                        let { code, size, creativeId, encoded } = thirdPartyTag;

                        try {
                            code = encoded ? Base64.atob(code) : code
                        } catch {
                            code = code
                        }

                        return (
                            <div key={i} css={creativeContainerStyles}>
                                <div className="controls">
                                    <div className="select-buttons">
                                        <SelectButton
                                            disabled={!!creativeId}
                                            optionLabel="name"
                                            optionValue="value"
                                            value={size}
                                            options={options}
                                            onChange={(e) => {
                                                changeHandler(idx, i, { size: e.target.value });
                                            }}
                                        ></SelectButton>
                                        {creativeId ? (
                                            <>
                                                <div css={noticeStyles}>
                                                    <span className="pi pi-exclamation-circle"></span> &nbsp; Once created, the size of a third-party creative cannot be changed
                                                </div>
                                                <MarketplacePreviewLink creative={thirdPartyTag} />
                                            </>
                                        ) : (
                                            <div
                                                css={css`
                                                      display: inline-block;
                                                      margin-top: 1em;
                                                      padding: 0.25em;
                                                      background-color: ${colors.salmon};
                                                      color: #fff;
                                                      border-radius: 3px;
                                                  `}
                                            >
                                                <span className="pi pi-exclamation-circle"></span> &nbsp; Please make sure to include all needed GAM macros into Third party creative before uploading
                                            </div>
                                        )}
                                    </div>
                                    {!disabled && (
                                        <InputTextarea
                                            css={css`
                                                  max-width: 768px;
                                              `}
                                            value={code}
                                            rows={10}
                                            cols={20}
                                            onChange={(ev) => {
                                                changeHandler(idx, i, { code: Base64.btoa(ev.target.value), encoded: true });
                                            }}
                                        />
                                    )}
                                </div>
                                {!disabled && (
                                    <span
                                        className="pi pi-times remove-creative"
                                        onClick={() => {
                                            removeThirdPartyTagRow(idx, i);
                                        }}
                                    ></span>
                                )}
                            </div>
                        );
                    })
                    : null}
                <Button
                    icon="pi pi-plus-circle"
                    label="New 3rd party tag creative"
                    className="p-button-secondary add-3pt"
                    onClick={() => {
                        addNewThirdPartyTagRow(idx);
                    }}
                    disabled={disabled}
                />
            </div>
        </OrderRow>
    );
};

export default ThirdPartyCreativesRow;
