import { useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushMessage } from "../reducers/messagingReducer";
import { clearScreenChanges } from "../reducers/screenChangesReducer";
import Cta from "./common/marketplace/CTA";
import { faSave, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { COMMON_CUSTOMER_DATA_MUTATION, COMMON_CUSTOMER_DATA_UPDATE_MUTATION, EVENT_LOGGER_MUTATION, SAVE_REVISION_MUTATION } from "./common/creatives/definitions";
import { setModifiedDomainsList } from "../reducers/multiBuildSystemReducer";
import { detailedDiff } from 'deep-object-diff'

export default function SaveButton({
    saveCallback,
    disableSave = false,
    logUserInteraction = true
}) {
    const [saveRevisionMutation] = useMutation(SAVE_REVISION_MUTATION);
    const [commonCustomerDataUpdateMutation] = useMutation(COMMON_CUSTOMER_DATA_UPDATE_MUTATION)
    const [commonCustomerDataMutation] = useMutation(COMMON_CUSTOMER_DATA_MUTATION)
    const [eventLoggerMutation] = useMutation(EVENT_LOGGER_MUTATION)
    const { currentDomain, currentCustomer, currentPreset, mode, userID: userId } = useSelector((state) => state.userInformation);
    const [isSaving, setSavingState] = useState(false);
    const dispatch = useDispatch();
    let originalDataStreamLog = {}
    let mutatedDataStreamLog = {}
    let errors = []

    async function handleSave() {
        let saveFailed = false
        if (disableSave) {
            dispatch(pushMessage('Error', "Can't save changes. Domain is still in build queue.", "error"))
        } else {
            setSavingState(true);
            dispatch(clearScreenChanges());

            try {
                if (logUserInteraction) {
                    let result = await saveCallback()
                    let {originalData, mutatedData} = result || {} 
                    originalDataStreamLog = originalData
                    mutatedDataStreamLog = mutatedData
                } else {
                    await saveCallback()
                }
            } catch (err) {
                setSavingState(false);
                errors.push({message: 'Error saving user data'})
                errors.push({message: err.message})
                errors.push({message: 'Skipping revision mutation'})
            }

            if (!saveFailed) {
                if (mode === 'domain') {
                    await commonCustomerDataUpdateMutation({
                        variables: {
                            customerId: String(currentCustomer),
                            domainId: String(currentDomain),
                            operation: 'ADD'
                        }
                    })
                }
                if (mode === 'domain') {
                    let commonCustomerData = await commonCustomerDataMutation({ variables: { customerId: String(currentCustomer) } })
                    let jsonData = JSON.parse(commonCustomerData.data.customerCommonInfoMutation.customerInfo)
                    let modifiedDomains = jsonData.modifiedDomains
                    dispatch(setModifiedDomainsList({ customerId: currentCustomer, domainsIds: modifiedDomains }))
                }

                try {
                    await saveRevisionMutation({
                        variables: {
                            postId: String(mode === "domain" ? currentDomain : currentPreset),
                            userId,
                        },
                    });
    
                    setSavingState(false)
                        
                } catch (err) {
                    setSavingState(false);
                    dispatch(pushMessage("Error", err.message, "error"));
                    errors.push({message: 'Revision failed to saved changes'})
                    errors.push({error: err.message})
                }
            }
            
        }
    }

    return (
        <Cta
            ctaText="Save"
            icon={disableSave ? faStopCircle : faSave}
            disabled={isSaving}
            showLoader={isSaving}
            onClick={async () => {
                await handleSave()
                await eventLoggerMutation({
                    variables: {
                        post_id: String(currentDomain || currentPreset),
                        url: window.location.pathname,
                        user_id: String(userId),
                        customer_id: String(currentCustomer),
                        data_stream: logUserInteraction 
                            ? JSON.stringify(detailedDiff(
                                originalDataStreamLog, mutatedDataStreamLog
                            ))
                            : JSON.stringify([]),
                        errors: JSON.stringify(errors)
                    }
                })
            }}
            themeClasses="marketplace-cta default outlined"
        />
    )
}
