import DropdownSection from "./DropdownSection";
import InputTextSection from "./InputTextSection";

const AD_SERVING_TYPE_OPTIONS = [
    { name: 'GAM Custom Creative', value: 'cc' },
    { name: 'GAM 3rd Party Type', value: '3p' },
    { name: 'Hardcoded', value: 'hdc' }
]

export default function BaseCreativeControls({
    children,
    adServingType,
    changeHandler,
    clickTag,
    creativeType,
    impressionTag,
    isClickTagRequired = true,
    isImpressionTagRequired = false,
    renderClickUrlInput = true
}) {
    return (
        <>
            <DropdownSection
                label={<span>Ad serving type <i style={{ color: "#ff0000" }}>*</i></span>}
                options={AD_SERVING_TYPE_OPTIONS}
                name="adServingType"
                value={adServingType}
                optionLabel="name"
                optionValue="value"
                changeHandler={changeHandler}
                adtype={creativeType}

            />
            {
                children
            }
            {
                renderClickUrlInput && (
                    <InputTextSection
                        label="Click URL"
                        name="clickTag"
                        value={clickTag}
                        changeHandler={changeHandler}
                        adType={creativeType}
                        textInput={false}
                        required={isClickTagRequired}
                    />
                )
            }

            <InputTextSection
                label="Impression tag (optional)"
                name="impressionTag"
                value={impressionTag}
                changeHandler={changeHandler}
                adtype={creativeType}
                textInput={isImpressionTagRequired}
            />
        </>
    )
}