import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useRef, useState } from 'react';
import EditableList from 'react-list-editable';
import 'react-list-editable/lib/react-list-editable.css';
import { CSSTransition } from 'react-transition-group';

import { AppMenu } from '../AppMenu';
import { AppTopbar } from '../AppTopbar';
import { DomainsDropdown } from '../DomainsDropdown';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { AutoComplete } from 'primereact/autocomplete';
import { Messages } from 'primereact/messages';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'prismjs/themes/prism-coy.css';
import '../App.scss';
import '../layout/flags/flags.css';
import '../layout/layout.scss';

import { gql, useMutation } from '@apollo/client';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { sidebarClassName, wrapperClass } from '../constants';
import { pushMessage } from '../reducers/messagingReducer';
import AppLogo from './AppLogo';


const TAXONOMY_MUTATION = gql`
  mutation taxonomyInfoMutation {
    taxonomyInfoMutation(input: {}) {
        taxonomyList
    }
  }
`

const DOMAIN_Edit_MUTATION = gql`
  mutation callDomainEditMutation($id: String, $cid: String) {
    domainEditMutation(input: {id: $id, cid: $cid}) {
        clientMutationId
        customerInfo
    }
  }
`
const AD_EXCLUSION_UPDATE_MUTATION = gql`
  mutation callAdExclusionListUpdateMutation($id: String, $presetid: String, $exclusionList: String, $overrideExclusionList: String) {
    adExclusionListUpdateMutation(input: {id: $id, presetid: $presetid, exclusionList: $exclusionList, overrideExclusionList: $overrideExclusionList}) {
        clientMutationId
        networkId
    }
  }
`


const AdExclusion = (props) => {
  const { currentDomain, currentCustomer } = useSelector(state => state.userInformation)
  const { modifiedDomains, modifiedPresets } = useSelector(state => state.buildState)
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [listData, setListData] = useState([]);
  const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
  const [presetName, setPresetName] = useState(false);
  const [presetId, setPresetId] = useState(null);
  const sidebar = useRef();
  const messages = useRef(null);
  const history = useHistory()
  const [suggestionKeywords, setSuggestionKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch()

  const searchKeywords = (event) => {
    let filteredSizes = null;

    if (!event.query.trim().length) {
      filteredSizes = [...suggestionKeywords];
    } else {
      filteredSizes = [...suggestionKeywords].filter((list) => {
        return list.includes(event.query);
      });
    }
    setFilteredKeywords(filteredSizes);
  }

  const handleSearchFieldChange = (event) => {
    setSearchQuery(event.target.value);
  }


  const [TAXONOMY_CONTAINER] = useMutation(
    TAXONOMY_MUTATION,
    {
      onCompleted(dataInsert) {
        confirmTaxonomy(dataInsert);
      }
    }
  );

  const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(
    DOMAIN_Edit_MUTATION,
    {
      onCompleted(data) {
        confirm(data);
      }
    }
  );

  const [AD_EXCLUSION_UPDATE_MUTATION_CONTAINER] = useMutation(
    AD_EXCLUSION_UPDATE_MUTATION,
    {
      onCompleted() {
        dispatch(pushMessage('Ad Exclusion', 'Ad exclusion list updated'))
      }
    }
  );

  useEffect(() => {
    if (!currentCustomer || !currentDomain) {
      return history.push('/domains')
    }
    setTimeout(removeDefaultInputField, 2000);
    DOMAIN_EDIT_MUTATION_CONTAINER({
      variables: { id: `${currentDomain}`, cid: currentCustomer }
    })
    TAXONOMY_CONTAINER({});
  }, [currentCustomer, currentDomain]);


  const removeDefaultInputField = () => {
    let selectedElement = document.getElementsByName("newInput")[0];
    if (selectedElement != null)
      selectedElement.style.display = "none";
  }

  const confirmTaxonomy = data => {
    let received_data = (data.taxonomyInfoMutation.taxonomyList);
    let keywords = JSON.parse(received_data);
    setSuggestionKeywords(keywords);
  }

  const confirm = data => {
    let temp_data = JSON.parse(data.domainEditMutation.customerInfo)

    if (temp_data.adv_preset && temp_data.adv_preset.name) {
      setPresetName(temp_data.adv_preset.name)
      setPresetId(temp_data.adv_preset.code)
    }
    if (temp_data.ad_exclusion_list !== null) {
      setListData(temp_data.ad_exclusion_list.map(function (item) {
        return item.title;
      }))
    }

    setShowCheckBox(temp_data.override_ad_exclusion_list)
    setMutationDataCompleted(true)
  }

  const overrideCheckBox = () => {
    setShowCheckBox(!showCheckBox);

  }

  const onMenuItemClick = () => {

  }

  const saveAdExclusionList = () => {
    AD_EXCLUSION_UPDATE_MUTATION_CONTAINER({
      variables: { id: `${currentDomain}`, presetid: presetId, exclusionList: JSON.stringify(listData), overrideExclusionList: showCheckBox ? 'true' : 'false' }
    });
  }

  const listChanged = (newList) => {
    setListData(newList)
  }

  const handleAddEvent = (event) => {

    // if user press enter try to add the element to table
    if (event.key === "Enter") {
      // first need to check, if suggestion has been modified or already present in
      if (suggestionKeywords.indexOf(event.target.value) > -1 && listData.indexOf(event.target.value) < 0) {
        listData.push(event.target.value);
        setSearchQuery("")
      } else {
        setSearchQuery("")
      }
    }

  }

  return (
    <div className="center w100">
      <div className={wrapperClass} >
        <AppTopbar {...props} domain_name={true} />
        <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={!staticMenuInactive} unmountOnExit>
          <div ref={sidebar} className={sidebarClassName} >
            <AppLogo />
            <DomainsDropdown />
            <AppMenu {...props} model={'domain'} onMenuItemClick={onMenuItemClick} />
          </div>
        </CSSTransition>

        <div className="layout-main">
          {mutationDataCompleted && (
            <div className={`p-panel p-component ${!showCheckBox ? "disabled-content" : "enabled-content"}`}>
              <Messages ref={messages} />
              <div className="p-panel-header">
                <div className="p-d-flex p-ai-center f-width">
                  <InputSwitch checked={showCheckBox} onChange={overrideCheckBox} />
                  <label htmlFor="cb1" className="p-checkbox-label p-ml-2 p-text-bold" onClick={overrideCheckBox}>Override Ad Exclusion{presetName ? " (" + presetName + ")" : ""}</label>
                </div>
                <Button type="button" label="Save" icon="pi pi-envelope" onClick={() => saveAdExclusionList()} />
              </div>

              <div className="p-panel-content disable-item">
                <EditableList
                  list={listData}
                  onListChange={listChanged}
                  placeholder='Enter a value'
                />
                <AutoComplete placeholder="Start typing to Add" suggestions={filteredKeywords} completeMethod={searchKeywords} value={searchQuery ? searchQuery : ""} onChange={handleSearchFieldChange} onKeyPress={handleAddEvent} />
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
  );

}

export default AdExclusion;
