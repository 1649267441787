import { gql, useMutation } from "@apollo/client";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoAdaptersConfig } from "../../constants";
import { pushMessage } from "../../reducers/messagingReducer";
import BaseMultiparameterAdapter from "../adapters/components/BaseMultiparameterAdapter";
import MultiparameterInputs from "../adapters/components/MultiparameterInputs";
import LayoutWrapper from "../LayoutWrapper";
import { getSspConfigFromAdunits, mergeVideoSspData } from "../adapters/utils/functions";
import { CUSTOM_FIELD_UPDATE_MUTATION, SSP_UPDATE_MUTATION } from "../adapters/utils/constants";

const DOMAIN_Edit_MUTATION = gql`
    mutation callDomainEditMutation($id: String, $isVideo: Boolean) {
        presetAllInfoMutation(input: { id: $id, isVideo: $isVideo }) {
            clientMutationId
            presetInfo
        }
    }
`;

const PresetVideoPlacementID = () => {
    const dispatch = useDispatch();
    const [videoSspData, setVideoSspData] = useState({});
    const [adposition, setAdposition] = useState(false);
    const [mutationDataCompleted, setMutationDataCompleted] = useState(false);
    const [userSelectedAdapters, setUserSelectedAdapters] = useState([]);
    const [listDataOptions, setListDataOptions] = useState([]);
    const { currentPreset, currentCustomer } = useSelector((state) => state.userInformation);
    const adaptersState = useSelector(state => state.adapterStateMutationReducer)
    const [fieldUpdateMutation] = useMutation(CUSTOM_FIELD_UPDATE_MUTATION)
    const [domainLevelVideoSspAdatersMutation] = useMutation(SSP_UPDATE_MUTATION)

    const [DOMAIN_EDIT_MUTATION_CONTAINER] = useMutation(DOMAIN_Edit_MUTATION, {
        onCompleted(data) {
            confirm(data);
        },
    });

    useEffect(() => {
        if (currentCustomer && currentPreset) {
            DOMAIN_EDIT_MUTATION_CONTAINER({
                variables: { id: `${currentPreset}`, isVideo: true },
            });
        }
    }, [currentPreset, currentCustomer]);

    const confirm = (data) => {
        let temp_data = JSON.parse(data.presetAllInfoMutation.presetInfo)
        let enabledAdapters = temp_data.enabled_video_ssps || [];

        setAdposition(typeof temp_data.ad_positions_for_video_adapters !== "object" ? [] : temp_data.ad_positions_for_video_adapters);
        setUserSelectedAdapters(enabledAdapters);
        setListDataOptions(temp_data.adapter_list_choices.sort());

        let sspData = !temp_data.presetVideoSSPs ? {} : JSON.parse(temp_data.presetVideoSSPs) || {}

        setVideoSspData(getSspConfigFromAdunits(sspData))
        setMutationDataCompleted(true);
    };

    const saveAdapterSelectionList = async () => {
        let jsonAdaptersList = JSON.stringify(userSelectedAdapters)

        await fieldUpdateMutation({
            variables: {
                post_id: String(currentPreset),
                field_name: 'global_video_adapter_selection',
                value: jsonAdaptersList,
                parse_value: true
            }
        })
        await domainLevelVideoSspAdatersMutation({
            variables: {
                customer_id: String(currentCustomer),
                preset_id: String(currentPreset),
                enabled_adapters_list: jsonAdaptersList,
                is_video_adapters: true
            }
        })
    }

    const saveAdapterChanges = async () => {
        const response = await fieldUpdateMutation({
            variables: {
                post_id: String(currentPreset),
                field_name: 'global_video_header_bidder_configuration',
                value: JSON.stringify(mergeVideoSspData(videoSspData, adaptersState))
            }
        })
        return response
    }

   const toggleAdapterSelection = (adapterName) => {
        setUserSelectedAdapters(
            userSelectedAdapters.includes(adapterName)
                ? userSelectedAdapters.filter(v => v !== adapterName)
                : [...userSelectedAdapters, adapterName]
        )
   }

    return (
        <LayoutWrapper model="preset" saveCallback={async () => {
            dispatch(pushMessage("Preset VideoPlacementId", "Saving preset configuration"));
            await saveAdapterSelectionList()
            if (Object.keys(adaptersState).length) {
                await saveAdapterChanges()
            }
        }}>
            {mutationDataCompleted && (
                <div>
                    <div className="child-margin-1">
                        {listDataOptions.map((adapterName) => {
                            let adapterConfig = videoAdaptersConfig[adapterName];
                            return (
                                adapterConfig && (
                                    <BaseMultiparameterAdapter
                                        adapterName={adapterName}
                                        key={adapterName}
                                        settings={videoSspData[adapterName] || {}}
                                        adposition={adposition}
                                        postId={`${currentPreset}`}
                                        adtype="video"
                                        render={(props) => <MultiparameterInputs {...props} />}
                                        toggleAdapterSelection={toggleAdapterSelection}
                                        isAdapterEnabled={userSelectedAdapters.includes(adapterName)}
                                        {...adapterConfig}
                                    />
                                )
                            );
                        })}
                    </div>
                </div>
            )}
        </LayoutWrapper>
    );
};

export default PresetVideoPlacementID;
