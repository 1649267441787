import { getIn, noop } from "../../utilities/utils";

const saveAdPositionList = ({ inputList, adPositionsAlertList, saveMutation, mutationVariables, mode = "domain", setPositioningData, overriden, domainPositioningData, adtype }) => {
    let positions_slot_name_changed = false;
    let temp = {};

    const getDeviceConfig = (device, viewport, adsize) => ({ [device]: { viewport, adsize: adsize || "0x0" } });

    inputList.forEach((list) => {
        let {
            slot_name,
            deskViewPort,
            tabViewPort,
            mobViewPort,
            display_name,
            description,
            network_id,
            hbid_list_exclude,
            hb_native,
            syndication,
            interstitial,
            sticky,
            oop_refresh_for_infinite_scroll,
            out_of_page,
            sticky_refresh_size,
            disable_refresh,
            deskAdsize,
            tabAdsize,
            mobAdsize,
            playerID,
            playerSize,
            catapultx_type,
            context,
            linearity,
            max_duration,
            min_duration,
            skip_after,
            skip,
            placement,
            playback_method,
            delivery,
            catapultx_target,
            catapultx,
            instaread_content,
            instaread,
            plcmt
        } = list;

        if (catapultx) {
            catapultx_target = typeof catapultx_target === "string" ? catapultx_target.trim() : catapultx_target;
            deskAdsize = typeof deskAdsize === "string" ? deskAdsize.trim() : deskAdsize;
            if (!catapultx_target) {
                throw new Error("CatapultX target wrapper must be specified");
            }
            if (!deskAdsize) {
                throw new Error("CatapultX ad size must be specified");
            }
        }

        if (instaread) {
            instaread_content = typeof instaread_content === "string" ? instaread_content.trim().replaceAll(`"`, `'`) : instaread_content;
            if (!instaread_content) {
                throw new Error("Instaread content must be specified");
            }
        }

        if (slot_name) {
            temp[slot_name] = {};

            if (!adPositionsAlertList.includes(slot_name) && list.new !== true) {
                positions_slot_name_changed = true;
            }

            if (adtype === "display") {
                if (deskViewPort) {
                    temp[slot_name] = getDeviceConfig("desk", deskViewPort, deskAdsize);
                }
                if (tabViewPort) {
                    temp[slot_name] = Object.assign(temp[slot_name], getDeviceConfig("tab", tabViewPort, tabAdsize));
                }
                if (mobViewPort) {
                    temp[slot_name] = Object.assign(temp[slot_name], getDeviceConfig("mob", mobViewPort, mobAdsize));
                }
            }

            const baseConfigKeys = [
                ["display_name", display_name],
                ["description", description],
                ["slot_name", slot_name],
                ["network_id", network_id],
                ["hbid_list_exclude", hbid_list_exclude],
                ["hb_native", hb_native],
                ["catapultx_type", catapultx_type],
                ["catapultx_target", catapultx_target],
                ["instaread_content", instaread_content],
            ];

            const displayConfigKeys = [
                ["sticky", sticky],
                ["out_of_page", out_of_page],
                ["oop_refresh_for_infinite_scroll", oop_refresh_for_infinite_scroll],
                ["sticky_refresh_size", sticky_refresh_size],
                ["disable_refresh", disable_refresh],
                ["interstitial", interstitial],
                ["catapultx", catapultx],
                ["instaread", instaread],
                ["syndication", syndication],
            ];

            const videoConfigKeys = [
                ["playerID", playerID],
                ["playerSize", playerSize],
                ["context", context],
                ["linearity", linearity],
                ["max_duration", max_duration],
                ["min_duration", min_duration],
                ["skip_after", skip_after],
                ["skip", skip],
                ["placement", placement],
                ["playback_method", playback_method],
                ["delivery", delivery],
                ["plcmt", plcmt]
            ];

            let config = (adtype === "display" ? [...baseConfigKeys, ...displayConfigKeys] : [...baseConfigKeys, ...videoConfigKeys]).reduce((acc, [k, v]) => {
                if (v) acc[k] = v;
                return acc;
            }, {});

            temp[slot_name] = Object.assign(temp[slot_name], config);
        }
    });

    if (mode === "domain") {
        setPositioningData(overriden ? { ...domainPositioningData, ad_positions: temp } : domainPositioningData);

        return saveMutation({
            variables: {
                ...mutationVariables,
                updateSlotPlacementID: positions_slot_name_changed,
                isOverridingPreset: overriden,
                positionList: JSON.stringify(overriden ? temp : domainPositioningData.ad_positions),
            },
        });
    }
    if (mode === "preset") {
        return saveMutation({ variables: { ...mutationVariables, positionList: JSON.stringify(temp) } });
    }
};

function recurseNames(name, idx, allNames) {
    let newName = name + idx; // append number to name
    if (allNames.has(newName)) return recurseNames(name, idx + 1, allNames);
    return newName;
}

const handleInputChange = (e, index, inputList, setInputList, presetAdPositions, changedListArray, machineNames) => {
    const { name, value } = e.target;
    const list = [...inputList];
    const listSlotName = getIn(list, index, "slot_name");
    const listOriginalSlotName = getIn(list, index, "original_slot_name");
    const listName = getIn(list, index, name);

    switch (name) {
        case "display_name": {
            let slotName = value
                .split(" ")
                .map((word) => word.toLowerCase().trim())
                .join("_");

            if (machineNames.has(slotName)) {
                slotName = recurseNames(slotName, 1, machineNames);
            }

            list[index][name] = value;
            list[index]["slot_name"] = slotName;

            if (!list[index].new && !presetAdPositions.includes(listSlotName)) {
                changedListArray[index][listOriginalSlotName] = value;
            }
            break;
        }
        case "hbid_list_exclude":
        case "hb_native":
        case "syndication":
        case "disable_refresh": {
            list[index][name] = !listName;
            list[index]["catapultx"] = false;
            break;
        }
        case "sticky_refresh_size":
        case "interstitial":
        case "oop_refresh_for_infinite_scroll":    
        case "out_of_page":
        case "sticky": {
            const isSticky = getIn(list, index, "sticky");
            const isOOP = getIn(list, index, "out_of_page");
            const isInterstitial = getIn(list, index, "interstitial");
            const isStickyRefreshSize = getIn(list, index, "sticky_refresh_size");
            const isInstaread = getIn(list, index, "instaread");
            list[index][name] = !listName;
            if (name === "sticky" && !listName && isOOP) {
                list[index]["out_of_page"] = listName;
            }
            if (name === "out_of_page" && !listName && isSticky) {
                list[index]["sticky"] = listName;
            }
            if (name === "interstitial" && !listName && isInterstitial) {
                list[index]["interstitial"] = listName;
            }
            if (name === "sticky_refresh_size" && !listName && isStickyRefreshSize) {
                list[index]["sticky_refresh_size"] = listName;
            }
            if (name === "instaread" && !listName && isInstaread) {
                list[index]["instaread"] = listName;
            }
            list[index]["catapultx"] = false;
            break;
        }
        default:
            list[index][name] = value;
    }

    setInputList(list);
};

const handleMultipleInput = (e, index, inputList, setInputList, mode, presetAdPositions, changedListArray) => {
    const { name, value } = e.target;
    const list = [...inputList];
    let valueArray = value.join(",");
    if (!valueArray.length) valueArray = false;

    const listSlotName = getIn(list, index, "slot_name");
    const listOriginalSlotName = getIn(list, index, "original_slot_name");
    const listName = getIn(list, index, name);

    switch (name) {
        case "display_name":
        case "slot_name": {
            list[index][name] = valueArray;
            if (mode === "domain" && !list[index].new && !presetAdPositions.includes(listSlotName)) {
                changedListArray[index][listOriginalSlotName] = valueArray;
            }
            break;
        }
        case "hbid_list_exclude":
        case "hb_native":
        case "interstitial":
        case "sticky":
        case "oop_refresh_for_infinite_scroll":
        case "out_of_page":
        case "sticky_refresh_size":
        case "syndication":
        case "disable_refresh":
            list[index][name] = !listName;
            break;
        case "deskAdsize":
            list[index][name] = valueArray;
            if (list[index].catapultx) {
                list[index]["deskViewPort"] = "1024x50";
            }
            break;
        default:
            list[index][name] = valueArray;
    }
    setInputList(list);
};

const setAdpositionData = (
    data,
    model,
    setDomainSlug,
    changedListArray_temp,
    inputListArray,
    setPresetAdPositions,
    setShowCheckBox,
    setMutationData,
    setInputListExist,
    setAdPositionsAlertList,
    setAdSizes,
    setChangedListArray,
    setInputList,
    setMutationDataCompleted,
    sourceObject = "ad_positions",
    fallBackSourceObject
) => {
    let temp_data = data;
    let keys = Object.keys(temp_data[sourceObject] || []);
    let codeData = [];

    temp_data.domain_slug ? setDomainSlug(temp_data.domain_slug) : noop();

    keys.forEach((element, i) => {
        const adPositions = getIn(temp_data, sourceObject, element) || {};
        let {
            display_name,
            description,
            slot_name,
            network_id,
            desk,
            tab,
            mob,
            hbid_list_exclude,
            hb_native,
            syndication,
            interstitial,
            sticky,
            oop_refresh_for_infinite_scroll,
            out_of_page,
            sticky_refresh_size,
            disable_refresh,
            playerSize,
            playerID,
            catapultx_type,
            context = "instream",
            linearity = 1,
            max_duration = "",
            min_duration = "",
            skip_after = "",
            skip = false,
            placement,
            playback_method = 1,
            delivery = 1,
            catapultx_target,
            catapultx,
            instaread ,
            instaread_content,
            plcmt = 1
        } = adPositions;
        let deskAdsize = null;
        let tabAdsize = null;
        let mobAdsize = null;
        let deskViewPort = "";
        let tabViewPort = "";
        let mobViewPort = "";

        if (desk) {
            deskAdsize = desk.adsize === "0x0" ? "" : desk.adsize;
            deskViewPort = desk.viewport;
        }

        if (tab) {
            tabAdsize = tab.adsize === "0x0" ? "" : tab.adsize;
            tabViewPort = tab.viewport;
        }

        if (mob) {
            mobAdsize = mob.adsize === "0x0" ? "" : mob.adsize;
            mobViewPort = mob.viewport;
        }

        changedListArray_temp[i] = { [slot_name]: slot_name };

        inputListArray[i] = {
            display_name,
            description,
            slot_name,
            network_id,
            deskAdsize,
            deskViewPort,
            tabAdsize,
            tabViewPort,
            mobAdsize,
            mobViewPort,
            original_slot_name: slot_name,
            hbid_list_exclude: !!hbid_list_exclude,
            hb_native: !!hb_native,
            interstitial: !!interstitial,
            syndication: !!syndication,
            sticky: !!sticky,
            oop_refresh_for_infinite_scroll: !!oop_refresh_for_infinite_scroll,
            out_of_page: !!out_of_page,
            sticky_refresh_size: !!sticky_refresh_size,
            disable_refresh: !!disable_refresh,
            playerID,
            playerSize,
            catapultx_type,
            context,
            linearity,
            max_duration,
            min_duration,
            skip_after,
            skip,
            placement,
            playback_method,
            delivery,
            catapultx_target,
            catapultx,
            instaread ,
            instaread_content,
            plcmt
        };

        codeData[i] = { name: display_name, code: slot_name };
    });

    if (model === "preset") {
        setPresetAdPositions([]);
        setShowCheckBox(true);
    } else {
        setPresetAdPositions(temp_data.ad_positions_for_preset || []);
    }

    setMutationData(temp_data[sourceObject] || temp_data[fallBackSourceObject]);
    setInputListExist(!!temp_data[sourceObject]);
    setAdPositionsAlertList(keys);
    setAdSizes(codeData);
    setChangedListArray(changedListArray_temp);
    setInputList(inputListArray);
    setMutationDataCompleted(true);
};

export default saveAdPositionList;

export { handleInputChange, handleMultipleInput, setAdpositionData };
