import React from 'react'

const SelectOptionNotice = ({ optionName, message = '' }) => {
	return (
		<div className="select-opt-notice">
			<i className="pi pi-chevron-left p-mr-2"></i>
			<h3 style={{display: 'inline-block'}}>
				{message || `Please select a ${optionName} from the left navigation dropdown`}
			</h3>
		</div>
	)
}

export default SelectOptionNotice